import { Redirect } from 'react-router';
import { useCheckUserPermissions } from '../../hooks/useCheckUserPermissions';

interface Props {
  functionality: string | string[]; // At least one of this permissions is required
  children: React.ReactNode;
  redirectRoute?: string;
}

const CanRoute: React.FC<Props> = ({
  children,
  functionality,
  redirectRoute = '/dashboard/home',
}) => {
  const { hasPermission } = useCheckUserPermissions();
  return hasPermission(functionality) ? (
    <>{children}</>
  ) : (
    <Redirect to={redirectRoute} />
  );
};
export default CanRoute;
