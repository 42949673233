export enum reservationsTypesEnum {
  SET_WORKROOM_RESERVATION = 'SET_WORKROOM_RESERVATION',
  SET_CANTEEN_RESERVATION = 'SET_CANTEEN_RESERVATION',
  SET_LOCKER_RESERVATION = 'SET_LOCKER_RESERVATION',
  SET_WORKSTATION_RESERVATION = 'SET_WORKSTATION_RESERVATION',
  SET_HOMEOFFICE = 'SET_HOMEOFFICE',
  SET_PARKING_RESERVATION = 'SET_PARKING_RESERVATION',
  SET_WORKSTATION_RAFFLES = 'SET_WORKSTATION_RAFFLES',
  SET_PARKING_RAFFLES = 'SET_PARKING_RAFFLES',

  DELETE_PK_RAFFLE_COMPANION = 'DELETE_PK_RAFFLE_COMPANION',
  ADD_PK_RAFFLE_COMPANIONS = 'ADD_PK_RAFFLE_COMPANIONS',

  SET_RESERVATIONS = 'SET_RESERVATIONS',
  ADD_RESERVATION = 'ADD_RESERVATION',

  CANCEL_RESERVED_WORKROOM = 'CANCEL_RESERVED_WORKROOM',
  CANCEL_RESERVED_CANTEEN = 'CANCEL_RESERVED_CANTEEN',
  CANCEL_RESERVED_WORKSTATION = 'CANCEL_RESERVED_WORKSTATION',
  CANCEL_RESERVED_PARKING = 'CANCEL_RESERVED_PARKING',

  REQUEST = 'FETCH_RESERVATIONS_REQUEST',
  FAILURE_RESERVATIONS = 'FETCH_RESERVATIONS_FAILURE',
  MSG_RESERVATION = 'MSG_RESERVATION',
  RESET_MESSAGE = 'RESET_MESSAGE',
  SET_DATES_WS_RESERVED = 'SET_DATES_WS_RESERVED',
  SET_DATES_PK_RESERVED = 'SET_DATES_PK_RESERVED',
  SET_PERMANENT_PARKINGS = 'SET_PERMANENT_PARKINGS',
  SET_PERMANENT_DESKS = 'SET_PERMANENT_DESKS',

  SET_PERMANENT_PARKING_RELEASED_DAYS = 'SET_PERMANENT_PARKING_RELEASED_DAYS',
  RESET_LOADING = 'RESET_LOADING',

  SET_DATES_WS_RESERVED_AND_HOMEOFFICE = 'SET_DATES_WS_RESERVED_AND_HOMEOFFICE',
  SET_DATES_PK_RESERVED_AND_HOMEOFFICE = 'SET_DATES_PK_RESERVED_AND_HOMEOFFICE',
  RESET_ERROR = 'RESET_ERROR',

  SET_LAST_UPDATE = 'SET_LAST_UPDATE',
  SET_CONFIGURATION_DESK_RESERVATIONFORTODAY = 'SET_CONFIGURATION_DESK_RESERVATIONFORTODAY',
  SET_IS_USER_IN_CAMPUS = 'SET_IS_USER_IN_CAMPUS',
}
