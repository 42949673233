import { Icon, Item } from '@acciona/ui-ionic-kit';
import { IonSpinner, IonText } from '@ionic/react';
import { Props } from './types';
import styles from './styles.module.scss';

export const SearchItem: React.FC<Props> = ({ ...props }) => {
  const { className, loading, ...res } = props;
  return (
    <Item
      lines={res.lines || 'none'}
      disabled={loading}
      className={`${styles.item} ${className} ${
        res.readonly && styles.defaultCursor
      }`}
      {...res}
    >
      <div onClick={res.actionItem} className={styles.contentItem}>
        {res.startIcon && (
          <Icon
            slot="start"
            color={res.startIconColor}
            className={`${res.startIcon} ${styles.startIcon}`}
          />
        )}

        {res.title && (
          <div className={`ion-text-wrap ${styles.columnList}`}>
            <IonText
              color={res.titleColor}
              className={`${styles.body} ion-text-wrap  ${styles.block}`}
            >
              {res.title}
            </IonText>
          </div>
        )}
      </div>

      {res.endIcon &&
        (loading ? (
          <IonSpinner />
        ) : (
          <Icon
            slot="end"
            color={res.endIconColor}
            className={`${res.endIcon} ${styles.endIcon}`}
            onClick={res.actionEndIcon}
            role="button"
            aria-label={res?.ariaEndIcon}
          />
        ))}
    </Item>
  );
};
