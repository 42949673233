import { Parking } from '../../models/parking.model';
import { Vehicle } from '../../models/vehicles.model';
import dayjs from 'dayjs';
import muleSoftInstance from '../../utils/httpCommon';
import { isEmpty } from '../../../utils/functions';
import { IVehicleCutOffs } from './types';
import { Raffle } from '../../models/reservation.model';

/**
 * Get information on a single reservation
 * @param filters
 * @returns
 */

const searchReservableParking = async (
  selectedDate: string[],
  type: string,
  startHour: string,
  endHour: string,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v2/parking/requestParkingLastMinute`;

    const params = {
      dates: selectedDate.join(),
      type: type,
      startHour: startHour,
      endHour: endHour,
    };
    const resp = await muleSoftInstance.get(path, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const createParkingReserve = async (
  parkings: Parking[],
  companions,
  vehicle,
  type: string,
  startHour: string,
  endHour: string,
) => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/createParkingReservation`;

    const newParking = parkings.map(p => {
      return isEmpty(companions)
        ? {
            ...p,
            reservationType: type,
            startHour: startHour,
            endHour: endHour,
            vehicle: vehicle,
          }
        : {
            ...p,
            reservationType: type,
            startHour: startHour,
            endHour: endHour,
            vehicle: vehicle,
            companions: companions,
          };
    });

    const resp = await muleSoftInstance.post(path, newParking);
    return resp.data;
  } catch (error) {
    if (error.status === 409) {
      //If the system suggested different places or a place is not  available for one day or more
      return [];
    } else if (error.status === 404) {
      //If there is no place available
      return null;
    } else {
      throw new Error(error.message);
    }
  }
};
const createParkingReservePlanned = async (
  dates: Date[],
  vehicle: Vehicle,
  companions,
) => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/createPlannedParkingRequest`;
    const dateString = dates.map(d =>
      dayjs(d).format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    );
    const resp = await muleSoftInstance.post(
      path,
      isEmpty(companions)
        ? {
            dates: dateString,
            vehicleId: vehicle.vehicleId,
          }
        : {
            dates: dateString,
            vehicleId: vehicle.vehicleId,
            companions: companions,
          },
    );
    const res = resp.data?.dates?.map(d => {
      return { ...resp.data, dates: [d] };
    });
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteComnpanionRaffleReserve = async (companionId, reserveId) => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companions/reservations/modify/${reserveId}?deletedCompanionId=${companionId}`;
    const params = [];

    const resp = await muleSoftInstance.put(path, params);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const addNewComnpanionsRaffleReserve = async (companions, reserveId) => {
  companions.forEach(item => {
    isEmpty(item.defaultCompanion) && (item.defaultCompanion = false);
  });

  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/companions/reservations/modify/${reserveId}`;
    const params = companions;

    const resp = await muleSoftInstance.put(path, params);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getRaffle = async (idSede: number): Promise<Raffle> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/lottery/isActive`;

    const resp = await muleSoftInstance.get(url, {
      headers: {
        Sedeemployee: idSede.toString(),
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getPkConfigurations = async (): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/configuration/parking/reservations`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getCutOffMarks = async (): Promise<IVehicleCutOffs[]> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/configuration/parking/scores`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const parkingServices = {
  searchReservableParking,
  createParkingReserve,
  createParkingReservePlanned,
  deleteComnpanionRaffleReserve,
  addNewComnpanionsRaffleReserve,
  getRaffle,
  getPkConfigurations,
  getCutOffMarks,
};
