import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonBackButton,
} from '@ionic/react';
import { TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

import dayjs from 'dayjs';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { useSelector } from 'react-redux';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { DetailList } from '../../../../components/Base/DetailList';
import { isEmpty } from '../../../../utils/functions';

const PassengersByReservation: React.FC = () => {
  const { t } = useTranslation();

  const { reservedParking } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/spaces/parking/detail"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('associated_companions', {
            multiple: 's',
          })}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {reservedParking?.reservations?.map(date => {
              return (
                <DetailList key={dayjs(date.from).format('DD MMM')}>
                  <DetailListItem
                    lines="full"
                    title={dayjs(date.from).format('DD MMM')}
                  />
                  {isEmpty(date.companions) ? (
                    <DetailListItem
                      lines="none"
                      titleLight={t('lbl_no_companions')}
                      className={styles.companion}
                    />
                  ) : (
                    date.companions.map(item => {
                      return (
                        <DetailListItem
                          companion
                          className={styles.companion}
                          key={item.employeeId}
                          startIcon="icon icon-client"
                          startIconColor="primary"
                          titleLight={item.fullName}
                          description={item.function}
                        />
                      );
                    })
                  )}
                </DetailList>
              );
            })}
          </div>
        </div>
      </IonContent>
      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default PassengersByReservation;
