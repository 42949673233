import dayjs from 'dayjs';
import { ICanteenSlot } from '../../models/canteen.model';
import muleSoftInstance from '../../utils/httpCommon';

const sortSlotsByDate = (slots: ICanteenSlot[]): ICanteenSlot[] => {
  return slots.sort((a: ICanteenSlot, b: ICanteenSlot) => {
    return dayjs(a.startHour).isAfter(b.startHour) ? 1 : -1;
  });
};

export const getAvailableCanteenSlots = async () => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/canteen/availableSlots`;

    const resp = await muleSoftInstance.get(url);
    const data = { ...resp.data };
    data.availableCanteenSlots = sortSlotsByDate(data.availableCanteenSlots);
    return data;
  } catch (error) {
    return undefined;
  }
};

export const reserveCanteen = async (data: ICanteenSlot) => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/canteen/reservation`;
    const response = await muleSoftInstance.post(url, {
      startDate: data.startHour,
      endDate: data.endHour,
    });
    return {
      status: 201,
      data: response.data,
    };
  } catch (error) {
    return { status: error.status, data: undefined };
  }
};
