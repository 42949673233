import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import store from './_redux/utils/store.config';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18nextConf';
import { errorInterceptor } from './_redux/utils/error.interceptor';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { FirebaseAnalyticsContextProvider } from './components/FirebaseAnalyticsContextProvider/FirebaseAnalyticsContextProvider';
import NotificationContext from './context/NotificationContext';
import NotificationService from './_redux/services/notifications/notifications.service';
import { injectStore } from './_redux/utils/httpCommon';

defineCustomElements(window);
injectStore(store);
errorInterceptor(store);

const container = document.getElementById('root');
const root = createRoot(container!); // "!" is required by TypeScript

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FirebaseAnalyticsContextProvider>
        <NotificationContext.Provider
          value={{ notificationService: new NotificationService() }}
        >
          <App />
        </NotificationContext.Provider>
      </FirebaseAnalyticsContextProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
