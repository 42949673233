import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import RestaurantList from './RestaurantList/RestaurantList';
import RestaurantInformation from './RestaurantInformation/RestaurantInformation';

const Restaurants: React.FC<any> = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/services/restaurants/restaurantList">
          <RestaurantList />
        </Route>
        <Route exact path="/services/restaurants/restaurantInformation">
          <RestaurantInformation />
        </Route>
        <Route exact path="/services/restaurants">
          <Redirect to="/services/restaurants/restaurantList" />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Restaurants;
