import { Icon } from '@acciona/ui-ionic-kit';
import { formatDayAndMonth } from '../../../../utils/dateTime';
import styles from './styles.module.scss';

type Props = {
  date?: string;
  onDelete?: () => void;
};

export const ModifyParkingRequestItem = ({ date, onDelete }: Props) => {
  return (
    <div className={`${styles.modifyParkingRequestItem}`}>
      <div className={styles.resume}>
        <div>
          <h3>{formatDayAndMonth(date)}</h3>
          <Icon className="icon icon-delete" onClick={onDelete} />
        </div>
      </div>
    </div>
  );
};
