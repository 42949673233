import _ from 'lodash';
import { ParkingReservation } from '../../_redux/models/parking.model';
import { getSlotString } from '../../utils/functions';
import { formatDayAndMonth } from '../../utils/dateTime';
import dayjs from 'dayjs';

interface Props {
  className?: string;
  days: any[];
  reservations?: ParkingReservation[];
}
export const DaysSummary: React.FC<Props> = ({
  className,
  days,
  reservations,
}) => {
  if (reservations) {
    return (
      <ul className={className}>
        {_.orderBy(reservations, 'date').map(
          (reservation: ParkingReservation) => {
            const text = `${formatDayAndMonth(
              dayjs(
                `${dayjs(reservation.from)
                  .utc()
                  .format('YYYY-MM-DD')} 00:00:00`,
              ).toISOString(),
            )} - ${getSlotString(reservation)}`;
            return (
              <li key={`released_${reservation.reservationId}`}>{text}</li>
            );
          },
        )}
      </ul>
    );
  } else {
    return (
      <ul className={className}>
        {_.orderBy(days, 'date').map((day, index) => (
          <li key={`released_${index}`}>{formatDayAndMonth(day.date)}</li>
        ))}
      </ul>
    );
  }
};
