import { CardHeader, CardTitle, Chip, Icon } from '@acciona/ui-ionic-kit';
import { IonRow, IonCol, IonLabel, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const ReservationCardHeader = ({
  data,
  headerTranslucent,
  onDelete,
  endContent,
}) => {
  const { t } = useTranslation();

  const isOnRequest = [
    'Parking-Request',
    'Desk-Request',
    'PassengerInvitation',
  ].includes(data.type);
  const isParkingReservation = ['Parking', 'Passenger'].includes(data.type);

  const handleDelete = id => {
    return onDelete(id);
  };

  return (
    <CardHeader className={styles.cardHeader} translucent={headerTranslucent}>
      <CardTitle className={styles.cardTitle}>
        <IonRow>
          <IonCol
            size={
              isParkingReservation ? '7' : data.link && endContent ? '6' : '9'
            }
            className={styles.colTitleText}
          >
            {data.header}
          </IonCol>
          {isOnRequest && (
            <IonCol size="3" className={`ion-text-end ${styles.chip}`}>
              <Chip type="warning">
                <IonLabel>{t('lbl_pending')}</IonLabel>
              </Chip>
            </IonCol>
          )}
          {['Room-WaitingList'].includes(data.type) && (
            <IonCol size="3" className={`ion-text-end ${styles.chip}`}>
              <Chip type="light">
                <IonLabel>{t('lbl_waitingList')}</IonLabel>
              </Chip>
            </IonCol>
          )}
          {data.link && !endContent && (
            <IonCol
              size="5"
              className={styles.link}
              onClick={() => data.onLinkClick()}
            >
              {data.link}
            </IonCol>
          )}
          {data.link && endContent && (
            <>
              <IonCol
                size="5"
                className={styles.linkWithEndcontent}
                onClick={() => data.onLinkClick()}
              >
                {data.link}
              </IonCol>
              <IonCol size="1" className={styles.iconsTitle}>
                {endContent}
              </IonCol>
            </>
          )}
          {onDelete && (
            <IonCol size="3" className={styles.link}>
              <IonButton
                fill="clear"
                className={styles.btnDelete}
                onClick={() => handleDelete(data.id)}
              >
                <Icon
                  slot="icon-only"
                  className={`icon icon-delete ${styles.iconFontBlack}`}
                ></Icon>
              </IonButton>
            </IonCol>
          )}
          {endContent && !isOnRequest && !data.link && (
            <IonCol
              size={isParkingReservation ? '5' : '3'}
              className={styles.iconsTitle}
            >
              {endContent}
            </IonCol>
          )}
        </IonRow>
      </CardTitle>
    </CardHeader>
  );
};
