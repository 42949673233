import React, { useEffect, useState } from 'react';
import {
  IonHeader,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { Button, Modal, PickerCalendar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { Props } from './types';
import { AppStore, Locale } from '../../../../_redux/models/app.model';
import { useSelector } from 'react-redux';
import {
  getIsToday,
  getSortDateString,
  checkIfDateIsDisabled,
} from '../../../../utils/dateTime';
import styles from './styles.module.scss';

const CalendarModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date>(
    props.firstEnabledDate,
  );
  const [selectedPrevDate, setSelectedPrevDate] = useState<Date>(
    props.firstEnabledDate,
  );
  const [showPickerCalendar, setShowPickerCalendar] = useState<boolean>(false);
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  useEffect(() => {
    props.isOpen
      ? setTimeout(() => {
          setShowPickerCalendar(true);
        }, 250)
      : setShowPickerCalendar(false);
  }, [props.isOpen]);

  const handleConfirmaton = () => {
    setSelectedPrevDate(selectedDate);
    props.setDate(selectedDate);
    props.setShowCalendar(false);
  };

  const handleDateChange = (e: any) => {
    setSelectedDate(e);
  };

  const handleModalClose = () => {
    setSelectedDate(selectedPrevDate);
    props.setShowCalendar(false);
  };

  const modifiers = {
    today: (date: any) =>
      getIsToday(getSortDateString(date)) &&
      !getIsToday(getSortDateString(selectedDate)),
    todaySelected: (date: any) =>
      getIsToday(getSortDateString(date)) &&
      getIsToday(getSortDateString(selectedDate)),
    disabled: (date: any) =>
      checkIfDateIsDisabled(date, props.publicHolidays, [0, 6]),
  };
  const dateLanguage = (localLanguage: Locale) => {
    if (localLanguage === 'en') {
      return 'en-US';
    } else if (localLanguage === 'es') {
      return 'es-ES';
    } else if (localLanguage === 'pt') {
      return 'pt-BR';
    } else {
      return 'en-US';
    }
  };

  return (
    <Modal isOpen={props.isOpen} id={props.id} onDidDismiss={handleModalClose}>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButton fill="clear" onClick={handleModalClose}>
            <IonIcon className="icon icon-close"> </IonIcon>
            <IonTitle className={styles.title}>{t('calendar_title')}</IonTitle>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem lines="none" className={styles.headerDates}>
          <IonLabel className="ion-text-center">
            {new Intl.DateTimeFormat(dateLanguage(localLanguage), {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(selectedDate)}
          </IonLabel>
        </IonItem>
        {showPickerCalendar && (
          <div className={styles.calendarGrid}>
            <PickerCalendar
              minimumDate={props.firstEnabledDate}
              date={selectedDate}
              selectedDate={handleDateChange}
              moreModifiers={modifiers}
              locale={localLanguage}
            ></PickerCalendar>
          </div>
        )}
      </IonContent>
      <IonFooter className="ion-no-border">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleConfirmaton}>
              {t('btn_confirmation_calendar')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </Modal>
  );
};

export default CalendarModal;
