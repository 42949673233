export enum NotificationsTypesEnum {
  REQUEST = 'FETCH_NOTIFICATION_REQUEST',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  FAILURE_NOTIFICATIONS = 'FAILURE_NOTIFICATIONS',
  SET_NOTIFICATION_READ = 'SET_NOTIFICATION_READ',
  SET_ALL_NOTIFICATIONS_READ = 'SET_ALL_NOTIFICATIONS_READ',
  DEL_NOTIFICATION = 'DEL_NOTIFICATION',
  SET_NOTIFICATION_UNAUTHORIZED = 'SET_NOTIFICATION_UNAUTHORIZED',
  SET_NOTICES = 'SET_NOTICES',
  SET_NOTIFICATIONS_HOME = 'SET_NOTIFICATIONS_HOME',
  RESET_NOTICE_MESSAGE = 'RESET_NOTICE_MESSAGE',
  SET_NOTICE_MESSAGE = 'SET_NOTICE_MESSAGE',
  SUBSCRIBE_FIREBASE = 'SUBSCRIBE_FIREBASE',
  SET_NOTIFICATION_DETAIL = 'SET_NOTIFICATION_DETAIL',
  SET_GENERIC_TOAST = 'SET_GENERIC_TOAST',
  SET_GENERIC_ALERT = 'SET_GENERIC_ALERT',
}
