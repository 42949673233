import { Chip, CheckFav, Icon } from '@acciona/ui-ionic-kit';
import { IonButton, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { classes } from './classes';
import styles from './styles.module.scss';
import { Props } from './types';
import { PermanentIcon } from './../PermanentIcon/index';

export const DetailCard: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { icon, type, pending, waiting, visibleFavorite } = props;
  const labelsCard = {
    workstation: t('workstation'),
    locker: t('locker'),
    workroom: t('workroom'),
    parking: t('parking'),
    workplace: t('workplace'),
    permanentWorkstation: t('workstation'),
    permanentParking: t('permanent_parking'),
    canteen: t('canteen'),
    passengerInvitation: t('passenger'),
    passenger: t('passenger'),
  };
  return (
    <div
      id={props.id}
      className={`${styles.backgroundDetailCard} ${props.className}`}
    >
      <div
        className={`${styles.generalStyleCard} ${styles.cardReservationDetail}`}
      >
        {props.deleteFn && (
          <div className={styles.iconsContent}>
            <IonButton fill="clear" onClick={() => props.deleteFn()}>
              <Icon
                className={`icon icon-delete ${styles.handHover} ${styles.iconFontBlack}`}
              ></Icon>
            </IonButton>
          </div>
        )}
        <div className={styles.cardContain}>
          <div className={styles.favIconContain}>
            {visibleFavorite && (
              <CheckFav
                checked={props.favorite}
                onIonChange={e =>
                  props.onClickFavorite && props.onClickFavorite(e)
                }
              />
            )}
            <div className={`${classes[type]}`}>
              <Icon
                className={`${icon} ${styles.iconFont} ${
                  ['workplace', 'canteen'].includes(type) &&
                  styles.iconFontWhite
                }`}
              />
              {['permanentParking', 'permanentWorkstation', 'locker'].includes(
                type,
              ) && <PermanentIcon />}
            </div>
          </div>
          <div className={styles.verticalCenter}>
            <p className={styles.workstationText}>
              {[
                'workplace',
                'canteen',
                'workroom',
                'parkingRequest',
                'passenger',
              ].includes(type)
                ? props.subName
                : labelsCard[type]}
            </p>
            {pending && (
              <Chip type="warning">
                <IonLabel>{t('lbl_pendingRequest')}</IonLabel>
              </Chip>
            )}
            {waiting && (
              <Chip type="light">
                <IonLabel>{t('lbl_waitingList')}</IonLabel>
              </Chip>
            )}
            <p className={styles.workstationSubtext}>
              {![
                'workplace',
                'canteen',
                'workroom',
                'parkingRequest',
                'passenger',
              ].includes(type)
                ? props.subName
                : props.title}
            </p>
          </div>
          <div>{props.endContent}</div>
        </div>
      </div>
    </div>
  );
};
