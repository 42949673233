import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import useHandleAccess from './HandleAcces';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../_redux/actions';

const DoorError: React.FC = () => {
  const history = useHistory();
  const stateLocation: any = useLocation().state || {
    state: 'failure',
    action: '',
  };
  const action = stateLocation?.state;
  const cards = stateLocation?.cards;
  const handleAcces = useHandleAccess(cards, action);
  const dispatch = useDispatch();
  const handleClose = () => {
    history.replace('/dashboard/home');
  };

  useEffect(() => {
    dispatch(userActions.resetCard());
  }, []);
  return (
    <IonPage>
      <IonContent className="ion-no-border">
        <IonGrid className={`${styles.grid} ${styles.gridWeb}`}>
          <IonRow className="ion-justify-content-end">
            <IonCol size="auto">
              <IonButton onClick={handleClose}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto" className={styles.iconItem}>
              <IonIcon className={styles.checkIcon} icon={handleAcces.icon} />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonText color="light">
                <h5>{handleAcces.msg}</h5>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <p className={styles.bottonText}>
                <IonText color="light">{handleAcces.welcome}</IonText>
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DoorError;
