import { MenuConfig, MoreActions } from './models';
import { moreServices } from './services';
import { moreTypes } from './types';

const setConfig = (config: MenuConfig): MoreActions => ({
  type: moreTypes.SET_CONFIG,
  config: config,
});

const getMenuConfig = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      const config: MenuConfig = await moreServices.getMoreMenuConfig();
      dispatch(setConfig(config));
    } catch (e) {
      console.log(e);
    }
  };
};

export const moreActions = { getMenuConfig };
