import {
  IonHeader,
  IonContent,
  IonButton,
  IonIcon,
  IonFooter,
} from '@ionic/react';

import { Button, Modal, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { RadioList } from '../../../../components/Base/RadioList';
import { Props } from './types';
import styles from './styles.module.scss';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { useState } from 'react';
import { DetailList } from '../../../../components/Base/DetailList';

const IncidentBuildingModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [buildingName, setBuildingName] = useState(props.value);

  const buildingOptions = props.buildingList.map(building => {
    return {
      name: building.buildingName,
      value: building.buildingName,
    };
  });
  const handleSelectOption = value => {
    setBuildingName(value);
  };
  const handleConfirmBuilding = () => {
    props.setValue(buildingName);
    props.showModal(false);
  };
  const handleBackButton = () => {
    props.showModal(false);
    setBuildingName(props.value);
  };

  return (
    <Modal isOpen={props.isOpen} id={props.id} onDidDismiss={handleBackButton}>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonButton
              className={styles.btnBack}
              fill="clear"
              onClick={() => props.showModal(false)}
            >
              <IonIcon className="icon icon-chevron-left" />
            </IonButton>
          }
          title={t('building_workroom')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.listModal}>
          <DetailList className={styles.list}>
            <DetailListItem
              mode="ios"
              lines="full"
              button
              title={t('lbl_building')}
            />

            <RadioList
              options={buildingOptions}
              selectedOption={buildingName}
              onChange={handleSelectOption}
            />
          </DetailList>
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleConfirmBuilding} disabled={false}>
              {t('button_apply')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </Modal>
  );
};
export default IncidentBuildingModal;
