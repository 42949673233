import { List, Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonContent,
  IonLoading,
  IonItem,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { UserStore } from '../../../_redux/models/user.model';
import { useContext, useEffect } from 'react';
import { userActions } from '../../../_redux/actions';
import { openHtmlLink } from '../../../utils/functions';
import _ from 'lodash';
import { OtherDocuments } from './OtherDocuments';
import { useExternalLink } from '../../../hooks/useExternalLink';
import styles from './styles.module.scss';
import FirebaseAnalyticsContext from '../../../context/FirebaseAnalyticsContext';

const Legal: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    downloading: linkDownloading,
    error: pdfError,
    openLink,
  } = useExternalLink();
  const { isLoading, error, policies, reservationPolicies, otherPolicies } =
    useSelector((store: UserStore) => store.user);

  const { logScreenView } = useContext(FirebaseAnalyticsContext);

  useEffect(() => {
    dispatch(userActions.loadPolicies());
    dispatch(userActions.getReservationPolicies());
    dispatch(userActions.getOtherPolicies());
  }, []);

  const handleOnClick = (
    document: {
      name: string;
      url: string;
      external: boolean;
    },
    category: string,
  ) => {
    externalLog(document.name, category);
    document.external ? openHtmlLink(document.url) : openLink(document.url);
  };

  const externalLog = (name: string, category: string) => {
    const logTag = `Mi Perfil > Legal > ${category} > ${name}`;
    logScreenView(logTag);
  };

  const generalPlusPolicies = [
    {
      title: 'general_title',
      items: policies,
      titleLight: {
        pre: '',
        itemKey: 'docType',
      },
      linkFn: openLink,
      icon: 'icon-pdf',
    },
    {
      title: 'policy_reservation',
      items: reservationPolicies,
      titleLight: {
        pre: 'policies_reservation_titles.',
        itemKey: 'type',
      },
      linkFn: openHtmlLink,
      icon: 'icon-extern',
    },
  ];

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title="Legal"
        />
      </IonHeader>
      <IonContent>
        {generalPlusPolicies.map(
          ({ title, items, titleLight, linkFn, icon }) => {
            return (
              <div key={title} className={styles.gridWeb}>
                <div className={styles.listGrid}>
                  <IonItem className={styles.titlePolicy} lines="inset">
                    {t(title)}
                  </IonItem>
                  <List className={styles.bgWhitesmoke}>
                    {items?.map((legal, index) => {
                      return (
                        <DetailListItem
                          key={index}
                          onClick={() => {
                            externalLog(
                              t(
                                `${titleLight.pre}${legal[titleLight.itemKey]}`,
                              ),
                              t(title, { lng: 'es' }),
                            );
                            linkFn(legal.url);
                          }}
                          className={styles.profileList}
                          mode="ios"
                          lines="inset"
                          startIcon={`icon ${icon}`}
                          titleLight={t(
                            `${titleLight.pre}${legal[titleLight.itemKey]}`,
                          )}
                        />
                      );
                    })}
                  </List>
                </div>
              </div>
            );
          },
        )}
        {!_.isEmpty(otherPolicies) && (
          <OtherDocuments data={otherPolicies} onClick={handleOnClick} />
        )}
        <Toast
          isOpen={!!error || !!pdfError}
          message={error || pdfError}
          position="bottom"
          type="error"
        />
        <IonLoading
          isOpen={isLoading || linkDownloading}
          message={t('msg_loading')}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};
export default Legal;
