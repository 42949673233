import { useMap } from 'react-leaflet';
import { IonFabButton, IonIcon } from '@ionic/react';
import { disableClickEventPropagation } from '../../utils/map';
import { LatLngExpression } from 'leaflet';
import styles from './styles.module.scss';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  center: LatLngExpression;
  zoomLevel?: number;
  cssClass?: string;
};

export const DistrictFocusControl: React.FC<Props> = (props: Props) => {
  const map = useMap();
  const zoomLevel = props.zoomLevel ?? -1.25;

  const handleClick = () => {
    disableClickEventPropagation('district-focus-control');
    props.onClick && props.onClick();
    !props.disabled && props.center && map.setView(props.center, zoomLevel);
  };

  return (
    <IonFabButton
      id={'district-focus-control'}
      className={`${styles.fabBtn} ${props.disabled ? styles.greyBtn : ''} ${
        props.cssClass ?? ''
      }`}
      color={props.disabled ? 'dark' : 'primary'}
      onClick={handleClick}
    >
      <IonIcon
        className={`icon icon-neighbourhood ${
          props.disabled ? styles.greyIcon : ''
        }`}
      />
    </IonFabButton>
  );
};
