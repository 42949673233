import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonSpinner,
  IonItem,
  IonLabel,
} from '@ionic/react';
import { Alert, Button, TopBar } from '@acciona/ui-ionic-kit';
import dayjs from 'dayjs';
import {
  ReleasedDeskDays,
  ReleasedDeskDaysDate,
  ReservationStore,
} from '../../../../_redux/models/reservation.model';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { DetailList } from '../../../../components/Base/DetailList';
import styles from './styles.module.scss';
import { WorkstationStore } from '../../../../_redux/models/workstation.model';
import { POLICIES_SCHEDULES } from '../../../../utils/constants';
import {
  reservationActions,
  workstationActions,
} from '../../../../_redux/actions';
import {
  calcOneHourMoreOrLess,
  groupDatesByMonth,
} from '../../../../utils/dateTime';
import { AppStore } from '../../../../_redux/models/app.model';
import _ from 'lodash';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import { DateTime } from '../../../../components/DateTime/DateTime';

const ModifyRelease: React.FC = () => {
  const { t } = useTranslation();
  const [reservationConfig, setReservationConfig] = useState<
    ReleasedDeskDaysDate[]
  >([]);
  const [customScheduleHoraMin, setCustomScheduleMin] = useState<string>('');
  const [customScheduleHoraMax, setCustomScheduleMax] = useState<string>('');
  const [modified, setModified] = useState<ReleasedDeskDaysDate[]>();
  const [dates, setDates] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);

  const { reservedWorkstation, isLoading } = useSelector(
    (state: ReservationStore) => state.reservation,
  );
  const { configurations } = useSelector(
    (state: WorkstationStore) => state.workstation,
  );
  const { campusTimeZone } = useSelector(
    (store: AppStore) => store.app.globalSettings,
  );
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );
  const reservationsHistory = useReservationsHistory();
  const { history } = reservationsHistory;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workstationActions.getWsConfigurations());
  }, []);
  useEffect(() => {
    if (campusTimeZone && reservedWorkstation?.releasedDatesTypes) {
      const currentDay = dayjs().tz(campusTimeZone).format('YYYY-MM-DD');

      setReservationConfig(
        _.orderBy(reservedWorkstation?.releasedDatesTypes, 'date')
          .filter(d => d.date !== currentDay)
          .map(r => {
            return { ...r, deleted: false };
          }),
      );
    }
  }, [campusTimeZone, reservedWorkstation]);

  const getScheduleOptions = useMemo(() => {
    const scheduleType = [];

    if (configurations) {
      const customScheduleHoraMin = configurations.policies.find(
        p => p.nombre === 'Personalizada',
      )
        ? configurations.policies.find(p => p.nombre === 'Personalizada')
            ?.fullHoraMin
        : '08:00';

      setCustomScheduleMin(customScheduleHoraMin);
      const customScheduleHoraMax = configurations.policies.find(
        p => p.nombre === 'Personalizada',
      )
        ? configurations.policies.find(p => p.nombre === 'Personalizada')
            ?.fullHoraMax
        : '20:00';
      setCustomScheduleMax(customScheduleHoraMax);

      configurations.policies.forEach(conf => {
        const confValue =
          POLICIES_SCHEDULES?.find(e => e.id == conf.nombre) || null;

        if (!confValue) return;
        let literal = '';
        switch (confValue.value) {
          case 'complete_day':
            literal = t('complete_day');
            break;
          case 'morning_schedule':
            literal = `${t('morning_schedule', {
              endHour: conf?.fullHoraMax,
            })}`;
            break;
          case 'afternoon_schedule':
            literal = `${t('afternoon_schedule', {
              startHour: conf?.fullHoraMin,
            })}`;
            break;
          case 'custom_schedule':
            literal = `${t('custom_schedule')}`;
            break;
        }

        scheduleType.push(
          <IonSelectOption key={conf.id} value={conf.nombre}>
            {literal}
          </IonSelectOption>,
        );
      });
    }
    return scheduleType;
  }, [configurations]);

  const changeValues = (date, field, value) => {
    const configurationToChange = reservationConfig.map(conf => {
      if (conf.date === date) {
        if (field === 'type' && value !== 'Personalizada') {
          const start = configurations.policies.find(p => p.nombre === value)
            ? configurations.policies.find(p => p.nombre === value)?.fullHoraMin
            : '08:00';

          const end = configurations.policies.find(p => p.nombre === value)
            ? configurations.policies.find(p => p.nombre === value)?.fullHoraMax
            : '08:00';
          return { ...conf, [field]: value, startHour: start, endHour: end };
        } else {
          return { ...conf, [field]: value };
        }
      } else {
        return conf;
      }
    });

    setReservationConfig(configurationToChange);

    setModified(
      configurationToChange
        .map(e => {
          if (e.deleted) return e;

          const start = configurations.policies.find(p => p.nombre === e.type)
            ? configurations.policies.find(p => p.nombre === e.type)
                ?.fullHoraMin
            : '08:00';

          const end = configurations.policies.find(p => p.nombre === e.type)
            ? configurations.policies.find(p => p.nombre === e.type)
                ?.fullHoraMax
            : '08:00';

          let dateModify = {};
          if ([...dates, date].includes(e.date)) {
            if (e.startHour) {
              dateModify = {
                date: e.date,
                startHour: e?.startHour,
                endHour: e?.endHour,
                type: e.type,
              };
            } else {
              dateModify = {
                date: e.date,
                startHour: start,
                endHour: end,
                type: e.type,
              };
            }

            if (
              !_.isEqual(
                reservedWorkstation?.releasedDatesTypes.find(
                  re => re.date === e.date,
                ),
                dateModify,
              )
            ) {
              return e;
            }
          }
        })
        .filter(item => item),
    );

    setDates([...dates, date]);
  };

  const saveChanges = () => {
    const releasedDeskDays: ReleasedDeskDays = {
      spaceId: reservedWorkstation.id,
      type: reservedWorkstation.type.toString(),
      dates: modified,
    };

    dispatch(
      reservationActions.modifyPermanentDeskReleasedDays(releasedDeskDays),
    );
  };

  const getMessageAlert = () => {
    const liberate = t('unrelease_question', {
      days: modified
        ? groupDatesByMonth(
            modified
              .filter(m => m.deleted)
              .map(e => dayjs(e.date).format('YYYY-MM-DD')),
            localLanguage,
            true,
          )
        : '',
    });
    return liberate;
  };

  const handlerOnClickSave = () => {
    if (!_.isEmpty(modified.filter(m => m.deleted))) {
      setShowAlert(true);
    } else {
      saveChanges();
    }
  };

  return (
    <IonPage>
      <div className={styles.headerContainer}>
        <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
          <TopBar title={t('lbl_modify')} />
        </IonHeader>
        <div className={`${styles.narrowLine} ${styles.linePosition}`}></div>
      </div>
      <IonContent fullscreen>
        <div className={styles.snackbar}>
          <IonItem className={styles.headerDates}>
            <IonLabel>
              {t('lbl_date_selected', {
                number: reservationConfig && reservationConfig.length,
                multiple:
                  reservationConfig && reservationConfig.length > 1 ? 's' : '',
              })}
            </IonLabel>
          </IonItem>
        </div>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {reservationConfig &&
              reservationConfig.map(item => {
                return (
                  <DetailList key={dayjs(item.date).format('DD MMM')}>
                    <DetailListItem
                      lines="full"
                      title={dayjs(item.date).format('DD MMM')}
                    />
                    <DetailListItem
                      lines="inset"
                      titleLight={t('released_to')}
                      endTextColor="light"
                      endText={item.deleted ? t('complete_day') : ''}
                      endTextSelect={
                        !item.deleted && (
                          <IonSelect
                            interfaceOptions={{
                              header: t('change_schedule'),
                              translucent: true,
                            }}
                            className={styles.selectText}
                            mode="ios"
                            okText={t('ok_text')}
                            cancelText={t('cancel_text')}
                            value={item.type}
                            onIonChange={e =>
                              changeValues(item.date, 'type', e.detail.value)
                            }
                          >
                            {getScheduleOptions}
                          </IonSelect>
                        )
                      }
                    />
                    {item.type === 'Personalizada' && !item.deleted && (
                      <>
                        <DetailListItem
                          mode="ios"
                          lines="inset"
                          titleLight={t('init_time')}
                          endText={
                            <DateTime
                              name="initTime"
                              className={styles.dateTime}
                              display-format="HH:mm"
                              picker-format="HH:mm"
                              minuteValues="0"
                              min={customScheduleHoraMin}
                              max={calcOneHourMoreOrLess(item?.endHour, false)}
                              placeholder={t('workroom_placeholder')}
                              value={item.startHour}
                              onIonChange={e =>
                                changeValues(
                                  item.date,
                                  'startHour',
                                  e.detail.value,
                                )
                              }
                              cancelText={t('cancel_text')}
                              doneText={t('done_text')}
                              // displayTimezone={campusTimeZone} // FIX for Ionic 6
                            />
                          }
                        />
                        <DetailListItem
                          mode="ios"
                          lines="inset"
                          titleLight={t('end_time')}
                          endText={
                            <DateTime
                              name="endTime"
                              className={styles.dateTime}
                              display-format="HH:mm"
                              picker-format="HH:mm"
                              minuteValues="0"
                              min={calcOneHourMoreOrLess(item.startHour, true)}
                              max={customScheduleHoraMax}
                              value={item.endHour}
                              placeholder={t('workroom_placeholder')}
                              onIonChange={e =>
                                changeValues(
                                  item.date,
                                  'endHour',
                                  e.detail.value,
                                )
                              }
                              disabled={item.startHour == null}
                              cancelText={t('cancel_text')}
                              doneText={t('done_text')}
                            />
                          }
                        />
                      </>
                    )}
                    <DetailListItem
                      lines="inset"
                      titleLight={t('retrieve')}
                      endTextSelect={
                        <IonToggle
                          checked={item.deleted}
                          color="primary"
                          mode="ios"
                          onIonChange={e =>
                            changeValues(item.date, 'deleted', e.detail.checked)
                          }
                        />
                      }
                    />
                  </DetailList>
                );
              })}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              onClick={() =>
                history.replace('/spaces/workstation/reservationdetail')
              }
              disabled={isLoading}
            >
              {t('lbl_cancel')}
            </Button>
          </div>
          <div>
            <Button
              onClick={handlerOnClickSave}
              disabled={_.isEmpty(modified) || isLoading}
            >
              {t('lbl_save_changes')}{' '}
              {isLoading && (
                <IonSpinner
                  slot="end"
                  className={styles.color_primary}
                  name="lines-small"
                />
              )}
            </Button>
          </div>
        </div>
      </IonFooter>
      <Alert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('retrieve_reservation')}
        message={getMessageAlert()}
        buttons={[
          { text: 'No', role: 'cancel', handler: () => setShowAlert(false) },
          { text: t('lbl_affirmative'), handler: () => saveChanges() },
        ]}
        mode="ios"
      ></Alert>
    </IonPage>
  );
};

export default ModifyRelease;
