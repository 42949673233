import { IonLoading, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import VideoPlayer from '../../../../components/Base/VideoPlayer';
import { useTranslation } from 'react-i18next';

interface Props {
  videoId: string;
}

const ExternalVideoPlayer = () => {
  const { videoId } = useParams<Props>();
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoOpen, setIsVideoOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setVideoUrl(`https://video.ibm.com/embed/recorded/${videoId}`);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, [videoId]);

  const closeExternalVideoPlayer = () => {
    setIsVideoOpen(false);
    window.location.href += '/exit';
  };

  return (
    <IonPage>
      <VideoPlayer
        videoUrl={videoUrl}
        onClose={closeExternalVideoPlayer}
        isOpen={isVideoOpen}
      />
      <IonLoading isOpen={isLoading} message={t('msg_loading')} />
    </IonPage>
  );
};

export default ExternalVideoPlayer;
