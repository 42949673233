import { useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';

export const useKeyboardListener = () => {
  const [keyboardIsVisible, setKeyboardIsVisible] = useState(false);

  useEffect(() => {
    if (isPlatform('capacitor')) {
      Keyboard.addListener('keyboardDidShow', () => {
        setKeyboardIsVisible(true);
      });
      Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardIsVisible(false);
      });
    }
    return () => {
      isPlatform('capacitor') && Keyboard.removeAllListeners();
    };
  }, []);

  return {
    keyboardIsVisible,
  };
};
