import muleSoftInstance from '../../utils/httpCommon';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Device } from '@capacitor/device';
import { detectBrowser } from '../../../utils/functions';

const getNewslist = async (sessionId: string, page: number, lang: string) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/news/pages/${page}`;

    const resp = await muleSoftInstance.get(path, {
      headers: {
        'sessionId': sessionId,
        'accept-language': lang === 'es' ? 'es-ES' : 'en-US',
      },
    });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getNewslistByPannel = async (
  sessionId: string,
  pannels: string[],
  top: number,
  lang: string,
  daysNews: number,
  isPublishingPanelsDueDate: boolean = false,
) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/news?top=${top}`;

    const filter = {
      communicationType: ['Noticia', 'News'],
      publishing_date: [
        dayjs().subtract(daysNews, 'days').tz().toISOString(),
        dayjs().tz().toISOString(),
      ],
      publishingPanels: pannels,
      due_date: [dayjs().tz().toISOString(), '2099-12-31T00:00:00+00:00'],
    };

    const resp = await muleSoftInstance.post(
      path,
      {
        filter: isPublishingPanelsDueDate
          ? {
              ...filter,
              publishingPanelsDueDate: [
                dayjs().tz().toISOString(),
                '2099-12-31T00:00:00+00:00',
              ],
            }
          : filter,
      },
      {
        headers: {
          'sessionId': sessionId ?? '0',
          'content-type': 'application/json',
          'accept-language': lang === 'es' ? 'es-ES' : 'en-US',
        },
      },
    );

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getNewsSocial = async (contentId: string, sessionId: string) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/contents/socialStats/${contentId}`;

    const resp = await muleSoftInstance.get(path, {
      headers: {
        'sessionId': sessionId,
        'content-type': 'application/json',
      },
    });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getNewsImgById = async (
  id: string,
  sessionId: string,
  renditionId: number,
) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/contents/images/blob/${id}?renditionId=${renditionId}`;
    const resp = await muleSoftInstance.get(path, {
      headers: {
        'sessionId': sessionId,
        'content-type': 'application/json',
      },
      responseType: 'blob',
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getNewsComments = async (id: string, sessionId: string) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/comments/${id}`;

    const resp = await muleSoftInstance.get(path, {
      headers: {
        'sessionId': sessionId,
        'content-type': 'application/json',
      },
    });

    return _.sortBy(resp.data, ['published_date'], ['desc']);
  } catch (error) {
    throw new Error(error.message);
  }
};

const getNewsUserActions = async (id: string, sessionId: string) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/actions/${id}`;

    const resp = await muleSoftInstance.get(path, {
      headers: {
        'sessionId': sessionId,
        'content-type': 'application/json',
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const setLikeNews = async (id: string, sessionId: string) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/likes/${id}`;
    const resp = await muleSoftInstance.post(
      path,
      {},
      {
        headers: {
          'sessionId': sessionId,
          'content-type': 'application/json',
        },
      },
    );

    const isLike = _.has(resp.data, 'response')
      ? resp.data.response
      : resp.data;

    return isLike;
  } catch (error) {
    throw new Error(error.message);
  }
};

const createNewComment = async (
  idNew: string,
  comment: string,
  sessionId: string,
  title: string,
) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/comments`;
    const resp = await muleSoftInstance.post(
      path,
      {
        title: title,
        content_id: idNew,
        body: comment,
        published_date: dayjs().utc().toISOString(),
      },
      {
        headers: {
          'sessionId': sessionId,
          'content-type': 'application/json',
        },
      },
    );
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const createReplyComment = async (
  idNew: string,
  comment: string,
  uniqueId: string,
  sessionId: string,
  title: string,
) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/comments`;
    const resp = await muleSoftInstance.post(
      path,
      {
        content_id: idNew,
        body: comment,
        published_date: dayjs().utc().toISOString(),
        parent_content_id: uniqueId,
        title: title,
      },
      {
        headers: {
          'sessionId': sessionId,
          'content-type': 'application/json',
        },
      },
    );
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getNoticeById = async (id: string, sessionId: string, lang: string) => {
  const path = `${process.env.REACT_APP_API_TOGO}/v1/user/contents/${id}`;

  const notice = await muleSoftInstance.get(path, {
    headers: {
      'sessionId': sessionId,
      'accept-language': lang === 'es' ? 'es-ES' : 'en-US',
    },
  });
  return notice;
};

const getNewsById = async (id: string, sessionId: string, lang: string) => {
  const path = `${process.env.REACT_APP_API_TOGO}/v1/user/contents/${id}`;
  const news = await muleSoftInstance.get(path, {
    headers: {
      'sessionId': sessionId,
      'accept-language': lang === 'es' ? 'es-ES' : 'en-US',
    },
  });
  return news.data;
};

const getAnalyticCode = async () => {
  try {
    const info = await Device.getInfo();
    const params = {
      browser: detectBrowser(),
      device: info.model,
      ip: '',
      os: `${info.operatingSystem} ${info.osVersion}`,
      source: 'MESENA',
      timeStamp: dayjs().utc(false).toISOString(),
      userAgent: navigator.userAgent,
      ctrk: '',
    };
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/analytics/session`;

    const resp = await muleSoftInstance.post(path, params);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getCategories = async (
  sessionId: string,
  lang: string,
  daysNews: number,
) => {
  try {
    const path = `${process.env.REACT_APP_API_TOGO}/v1/user/contents/categoriesMultiLanguage`;

    const resp = await muleSoftInstance.post(
      path,
      {
        filter: {
          communicationType: ['Noticia', 'News'],
          publishing_date: [
            dayjs().subtract(daysNews, 'days').tz().toISOString(),
            dayjs().tz().toISOString(),
          ],
          due_date: [dayjs().tz().toISOString(), '2099-12-31T00:00:00+00:00'],
        },
      },
      {
        headers: {
          'sessionId': sessionId ?? '0',
          'content-type': 'application/json',
          'accept-language': lang === 'es' ? 'es-ES' : 'en-US',
        },
      },
    );

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getNewsByCategory = async (
  sessionId: string,
  page: number,
  lang: string,
  category: string,
) => {
  try {
    const path = `${
      process.env.REACT_APP_API_TOGO
    }/v1/user/news/categories/pages/${page}?categoryId=${encodeURI(category)}`;

    const resp = await muleSoftInstance.get(path, {
      headers: {
        'sessionId': sessionId,
        'accept-language': lang === 'es' ? 'es-ES' : 'en-US',
      },
    });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const newsServices = {
  getNewslist,
  setLikeNews,
  getNewsImgById,
  getNewsSocial,
  getNewsComments,
  createNewComment,
  createReplyComment,
  getNewsById,
  getNoticeById,
  getAnalyticCode,
  getNewslistByPannel,
  getNewsUserActions,
  getCategories,
  getNewsByCategory,
};
