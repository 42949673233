export type FilterType = {
  id: string;
  text?: string;
  checked?: boolean;
  icon?: string;
};

export const filterTypes: FilterType[] = [
  {
    id: 'all',
    text: 'lbl_filter_all',
    checked: true,
  },
  {
    id: 'Assigned',
    text: 'space_assigned',
    checked: false,
  },
  {
    id: 'Pending',
    text: 'lbl_pendings',
    checked: false,
  },
  {
    id: 'Desk',
    text: 'workstations',
    checked: false,
    icon: 'icon icon-seat',
  },
  {
    id: 'Parking',
    text: 'parking',
    checked: false,
    icon: 'icon icon-parking',
  },
  {
    id: 'Room',
    text: 'workrooms',
    checked: false,
    icon: 'icon icon-room',
  },
  {
    id: 'Room-WaitingList',
    text: 'space_unallocated',
    checked: false,
    icon: 'icon icon-room',
  },
  {
    id: 'Parking-Request',
    text: 'space_unallocated',
    checked: false,
    icon: 'icon icon-parking',
  },
  {
    id: 'Desk-Request',
    text: 'space_unallocated',
    checked: false,
    icon: 'icon icon-seat',
  },
  {
    id: 'PermanentParking',
    text: 'parking',
    checked: false,
    icon: 'icon icon-parking',
  },
  {
    id: 'PermanentDesk',
    text: 'workstations',
    checked: false,
    icon: 'icon icon-seat',
  },
  {
    id: 'Locker',
    text: 'locker',
    checked: false,
    icon: 'icon icon-lock',
  },
  {
    id: 'Canteen',
    text: 'canteen',
    checked: false,
    icon: 'icon icon-plate',
  },
  {
    id: 'PassengerInvitation',
    text: 'passenger_invitation',
    checked: false,
    icon: 'icon icon-occupation',
  },
  {
    id: 'Passenger',
    text: 'passenger',
    checked: false,
    icon: 'icon icon-occupation',
  },
];
