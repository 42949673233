import { Button, SearchBar, Snackbar, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
} from '@ionic/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStore } from '../../../../_redux/models/auth.model';
import {
  PassengerData,
  PassengersStore,
} from '../../../../_redux/models/passengers.model';
import { passengersActions } from '../../../../_redux/actions/passengers.actions';
import { useLocation } from 'react-router';
import { parkingActions } from '../../../../_redux/actions';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { LocationState } from '../PassengersByRequest/types';
import { SpinnerFullSize } from './../../../../components/Base/SpinnerFullSize/SpinnerFullSize';
import { useDelayedRender } from './../../../../hooks/useDelayedRender';
import { includesQueryString, isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';

const Companions: React.FC = () => {
  const { delayed } = useDelayedRender(2000);
  const { t } = useTranslation();
  const contentPage = useRef<any>();
  const dispatch = useDispatch();
  const myUserId = useSelector((state: AuthStore) => state.auth.user.owner);
  const { availablePassengers: passengersData } = useSelector(
    (state: PassengersStore) => state.passengers,
  );
  const defaultCompanions = useSelector(
    (state: PassengersStore) => state.passengers.parkingCompanions,
  );
  const raffleReservationCompanions = useSelector(
    (state: ReservationStore) => state.reservation.parkingRaffleCompanions,
  );
  const parkingRaffle = useSelector(
    (state: ReservationStore) => state.reservation.parkingRaffles,
  );
  const location = useLocation().state as LocationState;
  const [companionName, setCompanionName] = useState('');
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [selectedCompanions, setSelectedCompanions] = useState([]);
  const maxCapPassengers = 4;
  const [maxPassengers, setMaxPassengers] = useState<number>();

  const canAddMoreCompanions = useMemo(
    () => maxPassengers - selectedCompanions.length > 0,
    [maxPassengers, selectedCompanions],
  );

  useEffect(() => {
    dispatch(passengersActions.getAvailablePassengers());

    let tempMaxPassengers = maxCapPassengers;

    if (location?.originRaffle) {
      tempMaxPassengers = maxCapPassengers - (location?.companionsCounter ?? 0);
    } else if (defaultCompanions !== undefined) {
      tempMaxPassengers = maxCapPassengers - defaultCompanions.length;
    }

    setMaxPassengers(tempMaxPassengers);
  }, [defaultCompanions, raffleReservationCompanions]);

  useEffect(() => {
    if (companionName.length < 3) {
      setFilteredSearch([]);
      return;
    }

    const isNotCurrentUser = (passenger: PassengerData, myUserId: string) =>
      passenger.employeeId.toString() !== myUserId;

    const isNotDefaultCompanion = (
      passenger: PassengerData,
      defaultCompanions: PassengerData[] = [],
    ) =>
      isEmpty(defaultCompanions) ||
      !defaultCompanions
        .map(companion => companion.employeeId)
        .includes(passenger.employeeId);

    const matchesQuery = (passenger: PassengerData, query: string) =>
      includesQueryString(passenger.fullName, query);

    const newFilteredSearch = !isEmpty(passengersData)
      ? passengersData.filter(
          passenger =>
            isNotCurrentUser(passenger, myUserId) &&
            isNotDefaultCompanion(passenger, defaultCompanions) &&
            matchesQuery(passenger, companionName),
        )
      : [];

    setFilteredSearch(newFilteredSearch);
  }, [companionName]);

  const handleSelectCompanion = companion => {
    const isDuplicated = selectedCompanions.some(
      selectedCompanion =>
        selectedCompanion.employeeId === companion.employeeId,
    );

    if (!isDuplicated && selectedCompanions.length < maxPassengers) {
      const newSelectedCompanions = [
        ...selectedCompanions,
        companion,
      ].reverse();
      setSelectedCompanions(newSelectedCompanions);

      setFilteredSearch(prev =>
        prev.filter(
          prevCompanion => prevCompanion.employeeId !== companion.employeeId,
        ),
      );

      dispatch(passengersActions.setPassengers(newSelectedCompanions));
      contentPage.current?.scrollToTop();
    }
  };
  const deleteCompanion = companion => {
    setSelectedCompanions(prev =>
      prev.filter(
        prevSelectedCompanion =>
          prevSelectedCompanion.employeeId !== companion.employeeId,
      ),
    );
    setCompanionName('');
  };

  useEffect(() => {
    dispatch(passengersActions.setPassengers(selectedCompanions));
  }, [selectedCompanions]);

  const addReservationCompanions = companions => {
    dispatch(passengersActions.addParkingCompanions(companions));
  };

  const modifyRaffleCompanions = companions => {
    const reserveId =
      location?.requestId ?? parkingRaffle[0].dates[0].requestId;
    dispatch(
      parkingActions.addNewComnpanionsRaffleReserve(companions, reserveId),
    );
  };

  const handleSearchChange = e => {
    setCompanionName(e.detail.value!);
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        className={`ion-no-border ${styles.backgroundLight} ${styles.toolbarGrid}`}
      >
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('add_passengers')}
        />
        {!delayed && (
          <>
            <div className={`${styles.gridWeb}`}>
              <div className={styles.listGrid}>
                <SearchBar
                  autoCapitalize="sentences"
                  onIonInput={handleSearchChange}
                  className={styles.searchBar}
                  cancelButtonText="cancel-button-text"
                  placeholder={t('plholder_search_passengers')}
                  disabled={isEmpty(passengersData) || maxPassengers < 1}
                  debounce={800}
                />
              </div>
            </div>
            <Snackbar
              icon={
                canAddMoreCompanions ? 'icon icon-info' : 'icon icon-incident'
              }
              type={canAddMoreCompanions ? 'info' : 'warning'}
              text={
                canAddMoreCompanions
                  ? t('lbl_maximun_passengers')
                  : t('lbl_max_passengers_warning')
              }
              align="left"
            />
          </>
        )}
      </IonHeader>
      <IonContent ref={contentPage}>
        {delayed ? (
          <SpinnerFullSize />
        ) : (
          <>
            <div className={styles.gridWeb}>
              <div className={styles.listGrid}>
                <>
                  {!isEmpty(selectedCompanions) && (
                    <DetailListItem
                      className={`${styles.headerList} ${styles.itemTitle}`}
                      title={t('selected_passengers')}
                      titleColor="primary"
                      lines="full"
                    />
                  )}
                  {!isEmpty(selectedCompanions) &&
                    selectedCompanions.map(companion => {
                      return (
                        <DetailListItem
                          companion
                          key={companion.employeeId}
                          mode="ios"
                          type="button"
                          startIcon="icon icon-client"
                          startIconColor="primary"
                          titleLight={companion.fullName}
                          titleColor="primary"
                          footnote={companion.function}
                          endIconAction="icon icon-delete"
                          endIconColor="primary"
                          actionEndIcon={() => deleteCompanion(companion)}
                        />
                      );
                    })}
                </>
                <>
                  {!isEmpty(selectedCompanions) && companionName.length > 0 && (
                    <DetailListItem
                      className={`${styles.headerList} ${styles.itemTitle}`}
                      title={t('results')}
                      titleColor="primary"
                      lines="full"
                    />
                  )}
                  {companionName.length > 2 &&
                    filteredSearch.map(companion => {
                      return (
                        <DetailListItem
                          companion
                          className={
                            selectedCompanions.length >= maxPassengers
                              ? styles.colorList
                              : ''
                          }
                          key={companion.employeeId}
                          mode="ios"
                          type="button"
                          onClick={() => handleSelectCompanion(companion)}
                          startIcon="icon icon-client"
                          startIconColor="primary"
                          titleLight={companion.fullName}
                          titleColor="primary"
                          footnote={companion.function}
                          footnoteColor={
                            selectedCompanions.length >= maxPassengers
                              ? styles.colorList
                              : styles.vehiclesNote
                          }
                        />
                      );
                    })}
                </>
              </div>
            </div>
          </>
        )}
      </IonContent>
      {!isEmpty(selectedCompanions) && (
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              {location?.originRaffle === true ? (
                <Button
                  onClick={() => modifyRaffleCompanions(selectedCompanions)}
                >
                  {t('add_passengers')}
                </Button>
              ) : (
                <Button
                  onClick={() => addReservationCompanions(selectedCompanions)}
                >
                  {t('add_passengers')}
                </Button>
              )}
            </div>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Companions;
