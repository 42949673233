import { NewsAction, NewsState } from '../models/news.model';
import { NewsTypesEnum } from '../types/news.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  isLoadingDetail: false,
  isLoadedCategories: [],
  news: null,
  notice: null,
  newslist: [],
  home: {
    newslistOther: [],
    newslistCarrousel: [],
  },
  newslistCarrousel: [],
  newslistOther: [],
  newslistHighlights: [],
  newsByCategories: [],
  categories: [],
  newsSearch: [],
  socialStats: [],
  sessionId: null,
  moreNews: true,
  tabPosition: null,
};

export const newsReducer = (
  state: NewsState = initialState,
  action: NewsAction,
): NewsState => {
  switch (action.type) {
    case NewsTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case NewsTypesEnum.REQUEST_DETAIL:
      return {
        ...state,
        error: null,
        isLoadingDetail: true,
      };
    case NewsTypesEnum.SET_NEWS:
      return {
        ...state,
        news: {
          ...action.news,
          comment: action.comments,
        },
        isLoading: false,
        isLoadingDetail: false,
        error: null,
      };
    case NewsTypesEnum.SET_LIKE_NEWS:
      return {
        ...state,
        socialStats: state.socialStats.map(social =>
          social.idNews === action.idNews
            ? {
                ...social,
                like: !social.like,
                likes: action.likes,
              }
            : social,
        ),
        news:
          state.news && state.news.id === action.idNews
            ? {
                ...state.news,
                socialStats: {
                  ...state.news.socialStats,
                  like: action.like,
                  likes: action.likes,
                },
              }
            : state.news,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_COMMENT_NEWS:
      return {
        ...state,
        news: {
          ...state.news,
          socialStats: {
            ...state.news.socialStats,
            comments: action.commentsCount,
          },
          comment: [...state.news.comment, action.newComment],
        },
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_IMG_NEWS:
      return {
        ...state,
        newslist: state.newslist.map(news =>
          news.id === action.idNews
            ? {
                ...news,
                image_base_64: action.image,
              }
            : news,
        ),
        news:
          state.news && state.news.id === action.idNews
            ? {
                ...state.news,
                image: action.image,
              }
            : state.news,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_NEWSLIST:
      return {
        ...state,
        newslist: action.newslist,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_NEWSLIST_HOME_CARROUSEL:
      return {
        ...state,
        home: {
          ...state.home,
          newslistCarrousel: action.newslistCarrousel,
        },
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_NEWSLIST_HOME_OTHER:
      return {
        ...state,
        home: {
          ...state.home,
          newslistOther: action.newslistOther,
        },
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_NEWSLIST_OTHER:
      return {
        ...state,
        newslistOther: action.newslistOther,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_NEWSLIST_HIGHLIGHTS:
      return {
        ...state,
        newslistHighlights: action.newslistHighlights,
        isLoading: false,
        error: null,
      };

    case NewsTypesEnum.SET_NEWSLIST_CARROUSEL:
      return {
        ...state,
        newslistCarrousel: action.newslistCarrousel,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_NEWSLIST_SEARCH:
      return {
        ...state,
        newsSearch: action.newsSearch,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_MORE_NEWS:
      return {
        ...state,
        moreNews: action.moreNews,
      };
    case NewsTypesEnum.SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_TAB_POSITION:
      return {
        ...state,
        tabPosition: action.tabPosition,
        error: null,
      };
    case NewsTypesEnum.SET_SOCIAL_NEWS:
      return {
        ...state,
        socialStats: [
          ...state.socialStats.filter(e => e.idNews !== action.idNews),
          action.socialStats,
        ],
        error: null,
      };
    case NewsTypesEnum.FAILURE_NEWS:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isLoadingDetail: false,
      };
    case NewsTypesEnum.RESET_NEWS:
      return initialState;
    case NewsTypesEnum.SET_NOTICE:
      return {
        ...state,
        notice: action.notice,
        isLoading: false,
        error: null,
      };
    case NewsTypesEnum.SET_CATEGORIES_NEWS:
      return {
        ...state,
        categories: action.categories,
        error: null,
      };
    case NewsTypesEnum.SET_LOADED_CATEGORIES:
      return {
        ...state,
        isLoadedCategories: action.categories as unknown as string[],
        error: null,
      };
    case NewsTypesEnum.SET_NEWS_CATEGORIES:
      return {
        ...state,
        newsByCategories: action.newsByCategories,
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
};
