import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
} from '@ionic/react';
import { TopBar } from '@acciona/ui-ionic-kit';
import { FormatedText } from '../../../../../components/Base/FormattedText';
import styles from './styles.module.scss';

type Props = {
  title: string;
  info: {
    name: string;
    details: string;
  };
};

export const ServiceDetail = (props: Props) => {
  const { title, info } = props;
  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={title}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={`${styles.gridWeb} ${styles.templateDetailContent}`}>
          {info && (
            <>
              <IonText className={`${styles.titleSection} ion-text-wrap`}>
                {info.name}
              </IonText>
              <FormatedText
                className={styles.formatedText}
                content={info.details}
              />
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
