import {
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonText,
} from '@ionic/react';
import { TopBar, Toast, Snackbar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import styles from './styles.module.scss';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vehicleActions } from '../../../../_redux/actions/vehicles.actions';
import { VehicleStore } from '../../../../_redux/models/vehicles.model';
import { useEffect } from 'react';
import {
  NotificationsActions,
  parkingActions,
} from '../../../../_redux/actions';
import { ParkingStore } from '../../../../_redux/models/parking.model';
import { isEmpty, openHtmlLink } from '../../../../utils/functions';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { UserStore } from '../../../../_redux/models/user.model';
import { BackButton } from '../../../../components/Base/BackButton';
import { useHistory } from 'react-router';
import { AppStore } from '../../../../_redux/models/app.model';

dayjs.extend(weekday);

const ParkingType: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [reservableDates, setReservableDates] = useState([]);
  const [plannedReservableDates, setPlannedReservableDates] = useState([]);
  const msgState = useSelector((store: VehicleStore) => store.vehicle.msg);
  const raffle = useSelector((state: ParkingStore) => state.parking.raffle);
  const { reservableDays, plannedReservableDays } = useSelector(
    (state: ParkingStore) => state.parking,
  );
  const {
    user: { defaultSede },
    reservationPolicies,
  } = useSelector((store: UserStore) => store.user);
  const { dayParkingPlannedTurningPoint, plannedReservation } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  const documentReservation = useMemo(
    () =>
      reservationPolicies
        ? reservationPolicies.find(e => e.type === 'policyParking')?.url
        : '',
    [reservationPolicies],
  );

  useEffect(() => {
    setReservableDates(reservableDays);
  }, [reservableDays]);

  useEffect(() => {
    setPlannedReservableDates(plannedReservableDays);
  }, [plannedReservableDays]);

  useEffect(() => {
    dispatch(parkingActions.getRaffle());
    dispatch(vehicleActions.getListAttributes());
    dispatch(parkingActions.getPkConfigurations());
    dispatch(parkingActions.getPkReservableDays());
  }, []);

  useEffect(() => {
    msgState && setShowToast(true);
  }, [msgState]);

  const handleDismissToast = () => {
    setShowToast(null);
    dispatch(vehicleActions.resetMsgVehicle());
  };
  const handleClick = item => {
    if (item.checkAvailableDates && isEmpty(plannedReservableDates)) {
      dispatch(
        NotificationsActions.setGenericAlert(
          t('lbl_alert_noReservableDays_title'),
          t('lbl_alert_noReservableDays_desc', {
            day: t(
              `dateFormats.dayIndexedByNum.${dayParkingPlannedTurningPoint}`,
            ).toLocaleLowerCase(),
          }),
        ),
      );
    } else {
      history.push(item.routerLink);
    }
  };
  const data = [
    {
      text: t('reservation_last_minute'),
      description: t('reservation_parking_last_minute_description'),
      routerLink: '../parking/calendar',
      icon: 'icon icon-last-minute',
      disabled: isEmpty(raffle.startHour),
      show: !isEmpty(reservableDates),
    },
    {
      text: t('reservation_planned'),
      description: t('reservation_parking_planned_description', {
        date:
          raffle.weekDay !== null
            ? dayjs()
                .weekday(raffle.weekDay)
                .format(
                  `D [${t('lbl_of_date')}] MMMM [${t('lbl_at_date')}] YYYY`,
                )
            : '',
      }),
      routerLink: '../parking/calendarPlanned',
      icon: 'icon icon-booking',
      disabled: isEmpty(raffle.startHour) || raffle.inProgress,
      show: plannedReservation,
      checkAvailableDates: true,
    },
  ];
  return (
    <IonPage>
      {isEmpty(reservableDates) && isEmpty(plannedReservableDates) ? (
        <>
          <IonHeader
            mode="ios"
            className={`${styles.toolbarGrid} ion-no-border`}
          >
            <TopBar
              primaryActions={
                <BackButton replace router="/dashboard/actions" />
              }
              title={t('reservation_title')}
            />
          </IonHeader>
          <IonContent>
            <div className={styles.fullscreenMessage}>
              {t('no_reservation_date_available')}
            </div>
          </IonContent>
        </>
      ) : (
        <>
          <IonHeader
            mode="ios"
            className={`${styles.toolbarGrid} ion-no-border`}
          >
            <TopBar
              primaryActions={
                <BackButton replace router="/dashboard/actions" />
              }
              title={t('reservation_title')}
            />
          </IonHeader>
          <IonContent fullscreen>
            <div className={styles.gridWeb}>
              <DetailList>
                {data
                  .filter(e => e.show)
                  .map((item, index) => {
                    return (
                      <DetailListItem
                        key={index}
                        mode="ios"
                        lines="inset"
                        type="button"
                        disabled={item.disabled}
                        startIcon={item.icon}
                        startIconColor="primary"
                        titleLight={item.text}
                        footnote={item.description}
                        endIconAction="icon icon-chevron-right"
                        className={styles.parkingTypeItem}
                        onClick={() => handleClick(item)}
                      />
                    );
                  })}
              </DetailList>
            </div>
            <Toast
              isOpen={raffle.inProgress}
              message={t('lbl_raffle_inprogress')}
              position="bottom"
              type="info"
            ></Toast>
            {msgState && (
              <Toast
                isOpen={showToast}
                message={msgState.description}
                onDidDismiss={handleDismissToast}
                position="bottom"
                type={msgState.type}
              ></Toast>
            )}
          </IonContent>
          <IonFooter className={styles.heightFooter}>
            {!isEmpty(documentReservation) && (
              <Snackbar
                type="white"
                text={
                  <IonText className={styles.messageFooter}>
                    {t('msg_you_are_booking')}
                    <b>
                      {t('msg_this_office', {
                        name: defaultSede.description,
                      })}
                    </b>
                    {'. '}
                    {t('msg_policies_workstation')}{' '}
                    <a
                      className={styles.defaultLink}
                      onClick={() => openHtmlLink(documentReservation)}
                    >
                      {t('lbl_policy_parking')}
                    </a>
                  </IonText>
                }
                icon="icon icon-info"
                align="left"
              ></Snackbar>
            )}
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};

export default ParkingType;
