import {
  IonBackButton,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
} from '@ionic/react';

import styles from './styles.module.scss';
import { Button, TopBar, Toast, Alert, Snackbar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { WorkstationStore } from '../../../_redux/models/workstation.model';
import { workstationActions } from '../../../_redux/actions';
import { isEmpty } from '../../../utils/functions';
import { ReservationCard } from '../../../components/Base/ReservationCard';
import { AppStore } from '../../../_redux/models/app.model';
import { UserStore } from '../../../_redux/models/user.model';

const FavoriteWorkstations: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [msgError, setMsgError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [idFavSelected, setFavSelected] = useState(null);
  const { districtName } = useSelector((state: UserStore) => state.user.user);
  const workstations = useSelector(
    (state: WorkstationStore) => state.workstation,
  );
  const userLockerMode = useSelector(
    (store: AppStore) => store.app.globalSettings.lockerEmployeeKey,
  );

  const settings = useSelector((state: AppStore) => state.app.globalSettings);

  useEffect(() => {
    dispatch(workstationActions.getFavoriteWorkstations());
  }, []);

  useEffect(() => {
    setMsgError(workstations.error);
    workstations.error && setShowToast(true);
  }, [workstations.error]);

  const handleAddFavorite = () => {
    history.push('/profile/user/favworkstations/mapSelector');
  };

  const handleDeleteAlert = id => {
    setFavSelected(id);
    setShowAlert(true);
  };

  const handleDelete = () => {
    const wr = workstations.favWorkstations.find(
      ws => ws.spaceId == idFavSelected,
    );
    dispatch(workstationActions.deleteFavorite(wr));
  };

  const handleDismissToast = () => {
    setShowToast(false);
    dispatch(workstationActions.resetErrorWorkstation());
  };
  return (
    <IonPage>
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/user"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_personal_favorite_spots')}
        />
        <Snackbar
          type="info"
          text={
            settings &&
            t('wr_info_max_favorite', { max: settings.maxFavoriteDesks })
          }
          icon="icon icon-info"
          align="left"
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {!workstations.isLoading &&
            isEmpty(workstations.favWorkstations) ? (
              <>
                <IonRow className="ion-justify-content-center">
                  <IonCol className={styles.center}>
                    <IonLabel className={styles.title_2}>
                      {t('not_available_fav_workstation')}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              workstations.favWorkstations.map(ws => {
                return (
                  <IonItem key={ws.spaceId}>
                    <ReservationCard
                      headerTranslucent={false}
                      data={{
                        id: ws.spaceId.toString(),
                        type: ws.useType,
                        header: `${t('workstation')} ${ws.name}`,
                        title: `${
                          districtName === ws.location?.district
                            ? t('lbl_your_district')
                            : ws.location?.district
                        }`,
                        subtitle: `${ws.equipment}`,
                        neighborhood:
                          districtName === ws.location?.district ? true : false,
                        lockerId: userLockerMode ? '' : ws.lockerId,
                      }}
                      icon="icon icon-seat"
                      displayLockerId={userLockerMode ? false : true}
                      onDelete={handleDeleteAlert}
                    ></ReservationCard>
                  </IonItem>
                );
              })
            )}
            <Toast
              isOpen={showToast}
              onDidDismiss={() => handleDismissToast()}
              message={msgError}
              position="bottom"
              type="error"
            />
            <IonLoading
              isOpen={workstations.isLoading}
              message={t('msg_loading')}
              duration={2000}
            />
            <Alert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              header={t('header_delete_workstation_favorite')}
              message={t('lbl_position_favorite_delete')}
              buttons={[
                { text: 'No', role: 'cancel' },
                { text: t('lbl_affirmative'), handler: handleDelete },
              ]}
              mode="ios"
            ></Alert>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleAddFavorite} color="primary">
              {t('wr_add_favorite')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default FavoriteWorkstations;
