import {
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from '@ionic/react';
import { Props } from './types';
import styles from './styles.module.scss';
import { Icon } from '@acciona/ui-ionic-kit';

export const RadioList: React.FC<Props> = props => {
  return (
    <IonList>
      <IonRadioGroup
        value={props.selectedOption}
        onIonChange={e => props.onChange(e.detail.value)}
        allowEmptySelection={props.allowEmptySelection}
      >
        {props.options.map(option => (
          <IonItem key={option.value} className={styles.item}>
            <IonRadio
              value={option.value}
              className={styles.radioIcon}
              aria-label={option.name}
            >
              <IonRow>
                {option.icon && (
                  <IonCol size="auto">
                    <IonIcon className={option.icon} />
                  </IonCol>
                )}
                <IonCol>
                  <IonLabel
                    className={`${styles.name} ${
                      props.whiteSpaceNormal && styles.whiteSpaceNormal
                    }`}
                  >
                    {option.name}
                  </IonLabel>
                  <IonNote>{option.description}</IonNote>
                </IonCol>
              </IonRow>
            </IonRadio>
            {props.selectedOption === option.value && (
              <Icon
                className={`icon icon-check ${styles.iconSize}`}
                slot="end"
              />
            )}
          </IonItem>
        ))}
      </IonRadioGroup>
    </IonList>
  );
};
