export enum incidentTypesEnum {
  REQUEST = 'FETCH_INCIDENT_REQUEST',
  FAILURE_INCIDENT = 'FETCH_INCIDENT_FAILURE',
  SET_INCIDENT = 'SET_INCIDENT',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_SUBCATEGORIES = 'SET_SUBCATEGORIES',
  SET_USER_INCIDENTS = 'SET_USER_INCIDENTS',
  SET_BUILDINGS_FLOORS = 'SET_BUILDS_FLOORS',
  SET_INCIDENTS_TO_SHOW = 'SET_INCIDENTS_TO_SHOW',
}
