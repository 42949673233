import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonBackButton,
} from '@ionic/react';
import { TopBar, Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { ReservationStore } from '../../../../../_redux/models/reservation.model';
import { DetailList } from '../../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../../components/Base/DetailListItem';
import { UserStore } from '../../../../../_redux/models/user.model';
import { MapReservation } from '../../../../../components/MapReservation/MapReservation';
import { PermanentIcon } from './../../../../../components/Base/PermanentIcon';
import styles from './styles.module.scss';
import { POLICIES_SCHEDULES } from '../../../../../utils/constants';
dayjs.extend(isToday);

const ReservationDetail: React.FC = () => {
  const { t } = useTranslation();
  const { districtName } = useSelector((state: UserStore) => state.user.user);
  const { reservedWorkstation } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const getTypeLabel = (type, startHour, endHour) => {
    switch (type) {
      case 'Dia completo':
        return t('complete_day');
      case 'Morning':
        return `${t('morning_schedule', { endHour: endHour })}`;
      case 'Tarde':
        return `${t('afternoon_schedule', { startHour: startHour })}`;
      case 'Personalizada':
        return `${t('custom_schedule')} (${startHour}-${endHour})`;
    }

    return '';
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.gridWeb} ion-no-border`}
      >
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              defaultHref="/profile/search"
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('detail_reservation')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.backgroundDetailCard}>
          <div
            className={`${styles.generalStyleCard} ${styles.cardReservationDetail}`}
          >
            <div className={styles.cardContain}>
              <div className={styles.favIconContain}>
                <div className={`${styles.divIcon} ${styles.workroom}`}>
                  <Icon className={`icon icon-seat ${styles.iconFont}`} />
                  {reservedWorkstation.permanent === true && <PermanentIcon />}
                </div>
              </div>
              <div className={styles.verticalCenter}>
                <p className={styles.workstationText}>{t('workstation')}</p>
                <p className={styles.workstationName}>
                  {reservedWorkstation.name}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-booking"
                startIconColor="primary"
                title={dayjs(reservedWorkstation.todayDate).format(
                  'dddd D MMM',
                )}
                readonly
              />
              {!reservedWorkstation?.permanent &&
                !_.isEmpty(reservedWorkstation?.reservations) && (
                  <DetailListItem
                    className={styles.itemList}
                    startIcon={`icon icon-${
                      POLICIES_SCHEDULES.find(
                        e =>
                          e.id ==
                          reservedWorkstation.reservations[0]?.reservationType,
                      )?.icon
                    }`}
                    startIconColor="primary"
                    title={t('reservation_schedule')}
                    description={getTypeLabel(
                      reservedWorkstation.reservations[0]?.reservationType,
                      reservedWorkstation.reservations[0]?.startHour,
                      reservedWorkstation.reservations[0]?.endHour,
                    )}
                    readonly
                  />
                )}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-seat"
                startIconColor="primary"
                title={t('lbl_type_of_post')}
                description={reservedWorkstation.useType}
                readonly
              />
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-campus"
                startIconColor="primary"
                readonly
                title={`${t('building_filter_workroom')} ${
                  reservedWorkstation.location?.buildingName
                } , ${reservedWorkstation.location?.floorName}`}
                description={
                  districtName === reservedWorkstation.location?.district ? (
                    <div className={styles.locatdivs}>
                      <Icon
                        color="primary"
                        className={`icon icon-neighbourhood ${styles.iconNeighborhood} icon-20`}
                      />
                      {t('lbl_your_district')}
                    </div>
                  ) : (
                    `${t('lbl_district')}: ${
                      reservedWorkstation.location?.district
                    }`
                  )
                }
              />
            </DetailList>
            {reservedWorkstation.mapImage && (
              <>
                <div className={styles.separatorList} />
                <MapReservation
                  x={reservedWorkstation.x}
                  y={reservedWorkstation.y}
                  mapImage={reservedWorkstation.mapImage}
                />
              </>
            )}
          </div>
        </div>
      </IonContent>
      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default ReservationDetail;
