import i18n from '../../i18nextConf';

const errorStatusCode = {
  400: 'error_400',
  401: 'error_401',
  403: 'error_403',
  404: 'error_404',
  406: 'error_406',
  409: 'error_409',
  412: 'error_412',
  418: 'error_418',
  500: 'error_500',
  503: 'error_503',
  default: 'error_default',
};

/**
 * Retrieves an error message based on the error code and optional error message.
 * @param {number} errorCode - The error code.
 * @param {string} errorMessage - The error message (can be undefined).
 * @returns {string} The corresponding error message.
 */
export const getErrorMessage = (
  errorCode: number,
  errorMessage: string,
): string => {
  if (errorCode === 404 && errorMessage !== undefined) {
    return errorMessage;
  }
  return i18n.t(errorStatusCode[errorCode] || errorStatusCode.default);
};
