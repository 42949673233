import dayjs from 'dayjs';
import {
  getCanteenReservationHours,
  getRoomReservationHours,
  isEmpty,
} from '../../../utils/functions';
import { isToday, utcDateIsToday } from './../../../utils/dateTime';
import { ReservationList } from '../../../_redux/models/reservation.model';
import i18n from '../../../i18nextConf';

export const getTodayReservations = (reservations: ReservationList[]) => {
  if (isEmpty(reservations)) {
    return [];
  }

  const roomsAndCanteen = reservations.filter(r =>
    ['Room', 'Canteen'].includes(r.useType),
  );
  const otherReservations = reservations.filter(
    r => !['Room', 'Canteen'].includes(r.useType),
  );

  const todayReservations = [];

  roomsAndCanteen.forEach(r => {
    if (isEmpty(r?.dates)) {
      return;
    }

    const todayRoomAndCanteenReservationDates = r.dates.filter(d =>
      utcDateIsToday(d.reservationDateFrom),
    );

    if (!isEmpty(todayRoomAndCanteenReservationDates)) {
      todayReservations.push({
        ...r,
        dates: todayRoomAndCanteenReservationDates,
      });
    }
  });

  otherReservations.forEach(reservation => {
    if (isEmpty(reservation?.dates)) {
      return;
    }

    const todayReservationDates = reservation.dates.filter(d =>
      isToday(d.reservationDate),
    );

    if (!isEmpty(todayReservationDates)) {
      todayReservations.push({
        ...reservation,
        dates: todayReservationDates,
      });
    }
  });

  return todayReservations;
};

export const getReservCardHeaderText = (space: any): string => {
  switch (space.useType) {
    case 'Room-WaitingList':
    case 'Parking-Request':
    case 'Desk-Request':
      return i18n.t('space_unallocated');
    case 'Room':
      return `${i18n.t('workroom')} ${space.name}`;
    case 'Desk':
    case 'PermanentDesk':
      return `${i18n.t('workstation')} ${space.name}`;
    case 'Parking':
    case 'PermanentParking':
      return `${i18n.t('parking')} ${space.name}`;
    case 'Locker':
      return `${i18n.t('lbl_locker', { number: space.name })}`;
    case 'Canteen':
      return `${i18n.t('canteen')} ${space.districtName}`;
    case 'Passenger':
      return i18n.t('passenger');
    default:
      return '';
  }
};

export const getReservCardTitleText = (
  userDistrict: string,
  space: any,
): string => {
  switch (space.useType) {
    case 'Room-WaitingList':
      return `${dayjs(space.dates[0].reservationDateFrom)
        .tz()
        .format('D MMM [|] HH:mm')} - ${dayjs(space.dates[0].reservationDateTo)
        .tz()
        .format('HH:mm')}`;
    case 'Desk':
    case 'PermanentDesk':
      return space.districtName;
    case 'Room':
      return userDistrict === space.district?.toString()
        ? ` | ${space.buildingName} ${space.floorName}`
        : `${space.districtName} | ${space.buildingName} ${
            space.floorName ?? ''
          }`;
    case 'Parking-Request':
    case 'Desk-Request':
      return `${space.dates
        .map(date => dayjs(date.reservationDate).tz().format('D MMM'))
        .join(',')}`;
    case 'Parking':
    case 'PermanentParking':
      return space.location;
    case 'Canteen':
      return `${space.buildingName} | ${space.floorName}`;
    case 'Passenger':
      return `${i18n.t('driver')}: ${space.driverName}`;
    default:
      return '';
  }
};

export const getReservCardSubtitleText = (
  space: any,
  raffleDay: string,
  timezone: string,
): string => {
  switch (space.useType) {
    case 'Parking-Request':
    case 'Desk-Request':
      return raffleDay;
    case 'Desk':
    case 'PermanentDesk':
      return space?.equipment ?? '';
    case 'Room':
      return getRoomReservationHours(
        space.dates[0].reservationDateFrom,
        space.dates[0].reservationDateTo,
        timezone,
      );
    case 'Canteen':
      return getCanteenReservationHours(
        space.dates[0].reservationDateFrom,
        space.dates[0].reservationDateTo,
        timezone,
      );
    case 'Room-WaitingList':
    case 'Parking':
    case 'PermanentParking':
    default:
      return '';
  }
};
