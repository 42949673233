import { Browser } from '@capacitor/browser';
import { openHtmlLink, openExternalAppLink } from '../../../../utils/functions';
import { MenuItem } from '../_redux/models';

export const getItemLink = (item: MenuItem, openLink?: any) => {
  if (item.translations[0].externalLink) {
    if (item.translations[0].externalLink.inAppBrowser) {
      return {
        onClick: () =>
          openHtmlLink(item.translations[0].externalLink.url, false),
      };
    }
    return {
      onClick: () => {
        Browser.open({
          url: item.translations[0].externalLink.url,
          windowName: '_blank',
          presentationStyle: 'popover',
        });
      },
    };
  }

  if (item.translations[0].externalAppLink) {
    return {
      onClick: () => openExternalAppLink(item.translations[0].externalAppLink),
    };
  }

  if (item.translations[0].internalLink) {
    return { routerLink: item.translations[0].internalLink.url };
  }
  if (item.translations[0].file && openLink) {
    return {
      onClick: () => {
        openLink(item.translations[0].file.url);
      },
    };
  }
  return {};
};
