import {
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonPage,
} from '@ionic/react';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { Toast, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { IncidentStore } from '../../../_redux/models/incident.model';
import { useDispatch, useSelector } from 'react-redux';
import { incidentActions } from '../../../_redux/actions';
import { useHistory } from 'react-router';
import { AppStore } from '../../../_redux/models/app.model';
import { BackButton } from '../../../components/Base/BackButton';
import { useLocationState } from '../../../hooks/useLocationState';

const CategorySelection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useLocationState(true);
  const backButtonRouter = state ? '/profile/incidence' : '/dashboard/actions';

  const { categories, isLoading, error } = useSelector(
    (store: IncidentStore) => store.incident,
  );
  const { localLanguage } = useSelector(
    (store: AppStore) => store.app.localSettings,
  );

  useEffect(() => {
    dispatch(incidentActions.getCategories(localLanguage));
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={<BackButton replace router={backButtonRouter} />}
          title={t('incident_title')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {categories &&
                categories.map(item => {
                  return (
                    <DetailListItem
                      className={styles.itemList}
                      key={item.categoryId}
                      mode="ios"
                      lines="inset"
                      onClick={() =>
                        history.push('/incidents/reporttype', {
                          categoryId: item.categoryId,
                          categoryName: item.name,
                        })
                      }
                      startIcon={`icon icon-${item.icon}`}
                      endIcon="icon icon-chevron-right"
                      titleLight={item.name}
                      button
                    />
                  );
                })}
            </DetailList>
            <IonLoading
              isOpen={isLoading}
              message={t('msg_loading')}
              duration={1500}
            />
          </div>
        </div>
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
      </IonContent>
      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default CategorySelection;
