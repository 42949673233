import { isEmpty } from '../../utils/functions';
import {
  Message,
  Vehicle,
  VehicleAction,
  VehicleFieldsConfiguration,
} from '../models/vehicles.model';
import { vehicleServices } from '../services/vehicle/vehicle.services';
import { vehicleTypesEnum } from '../types/vehicle.types.enum';
import { history } from '../../_helpers/history';
import i18n from '../../i18nextConf';
import { parkingActions } from './parking.actions';
const setVehicleDefault = (vehicle: Vehicle): VehicleAction => ({
  type: vehicleTypesEnum.SET_VEHICLE,
  vehicle: vehicle,
});
const setVehicles = (vehicles: Vehicle[]): VehicleAction => ({
  type: vehicleTypesEnum.SET_VEHICLES,
  vehicles: vehicles,
});
const addVehicle = (vehicle: Vehicle): VehicleAction => ({
  type: vehicleTypesEnum.ADD_VEHICLE,
  vehicle: vehicle,
});
const deleteVehicle = (vehicle: Vehicle): VehicleAction => ({
  type: vehicleTypesEnum.DELETE_VEHICLE,
  vehicle: vehicle,
});

const vehicleRequest = (): VehicleAction => {
  return {
    type: vehicleTypesEnum.REQUEST,
  };
};

const listAttributesRequest = (attributes: any) => ({
  type: vehicleTypesEnum.VEHICLE_ATRIBUTES,
  attributes: attributes,
});

const attributesFieldsRequest = (
  configuration: VehicleFieldsConfiguration,
) => ({
  type: vehicleTypesEnum.SET_CONFIGURATION,
  configuration: configuration,
});

const fetchVehicleFailure = (error: string): VehicleAction => {
  return {
    type: vehicleTypesEnum.FAILURE_VEHICLE,
    error: error,
  };
};

const msgVehicle = (msgAction: Message): VehicleAction => ({
  type: vehicleTypesEnum.MSG_VEHICLE,
  msg: msgAction,
});
const resetMsgVehicle = (): VehicleAction => ({
  type: vehicleTypesEnum.RESET_MESSAGE,
});

const resetLoading = (): VehicleAction => {
  return {
    type: vehicleTypesEnum.RESET_LOADING,
  };
};

const addNewVehicle = (vehicle: Vehicle) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(vehicleRequest());
    try {
      const settedVehicle = await vehicleServices.setVehicle(vehicle);
      dispatch(setVehicleDefault(settedVehicle));
      dispatch(addVehicle(settedVehicle));
      dispatch(
        msgVehicle({
          type: 'success',
          description: `${i18n.t('lbl_create_vehicle', {
            brand: vehicle.brand,
            model: vehicle.model,
            plate: vehicle.plate,
          })} `,
        }),
      );
      dispatch(getListVehicle());
    } catch (e) {
      dispatch(fetchVehicleFailure(e.messag));
      dispatch(
        msgVehicle({
          type: 'error',
          description: `${i18n.t('lbl_create_vehicle_error', {
            error: e.message,
          })} `,
        }),
      );
    }
  };
};

const modifyParkingVehicle = (vehicle: Vehicle) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(vehicleRequest());
    try {
      dispatch(setVehicleDefault(vehicle));
      history.goBack();
    } catch (e) {
      dispatch(fetchVehicleFailure(e.message));
    }
  };
};
const modifyVehicle = (vehicle: Vehicle) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(vehicleRequest());

    try {
      const modifyVehicle = await vehicleServices.modifyVehicle(vehicle);
      dispatch(setVehicleDefault(modifyVehicle));
      dispatch(
        msgVehicle({
          type: 'success',
          description: `${i18n.t('lbl_vehicle_modify', {
            brand: vehicle.brand,
            model: vehicle.model,
            plate: vehicle.plate,
          })} `,
        }),
      );
      dispatch(getListVehicle());
      history.goBack();
    } catch (e) {
      dispatch(fetchVehicleFailure(e.message));
    }
  };
};

const changeFavouriteVehicle = (
  vehicle: Vehicle,
  updateStore: boolean = false,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(vehicleRequest());

    try {
      await vehicleServices.modifyVehicle({
        ...vehicle,
        favourite: true,
      });
      dispatch(resetLoading());
      updateStore && dispatch(setVehicleDefault(vehicle));
    } catch (e) {
      dispatch(fetchVehicleFailure(e.message));
    }
  };
};

const changeVehicleLastMinute = (
  selectedVehicle: Vehicle,
  selectedDates: string[],
  vehiclePrev: Vehicle,
  type: string,
  startHour: string,
  endHour: string,
  textHeader: string,
) => {
  return async (dispatch: any): Promise<any> => {
    await vehicleServices.modifyVehicle({
      ...selectedVehicle,
      favourite: true,
    });
    dispatch(
      parkingActions.searchParkingLastMinute(
        selectedDates,
        null,
        vehiclePrev,
        type,
        startHour,
        endHour,
        textHeader,
      ),
    );

    dispatch(vehicleActions.setVehicleDefault(selectedVehicle));
  };
};

const getListVehicle = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(vehicleRequest());
    try {
      const listVehicles = await vehicleServices.getListVehicles();
      dispatch(setVehicles(listVehicles));
    } catch (e) {
      dispatch(fetchVehicleFailure(e.message));
    }
  };
};

const deleteListVehicle = (vehicleId, vehicles) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(vehicleRequest());
    try {
      const delVehicles = await vehicleServices.deleteVehicle(
        vehicleId,
        vehicles,
      );
      if (!isEmpty(delVehicles)) {
        dispatch(getListVehicle());
        dispatch(
          msgVehicle({
            type: 'success',
            description: `${i18n.t('lbl_delete_vehicle_toast', {
              brand: delVehicles.brand,
              model: delVehicles.model,
              plate: delVehicles.plate,
            })} `,
          }),
        );
      }
      dispatch(deleteVehicle(delVehicles));
      history.replace('/profile/user/vehicles-passengers');
    } catch (e) {
      dispatch(
        msgVehicle({
          type: 'error',
          description: i18n.t('lbl_vehicle_delete'),
        }),
      );
      dispatch(fetchVehicleFailure(e.message));
    }
  };
};

const getListAttributes = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(vehicleRequest());
    try {
      const listAttributes = await vehicleServices.getListAttributes();
      dispatch(listAttributesRequest(listAttributes));
      const attributesFields = await vehicleServices.getFieldsBySede();
      dispatch(attributesFieldsRequest(attributesFields));
    } catch (e) {
      dispatch(fetchVehicleFailure(e.message));
    }
  };
};

export const vehicleActions = {
  addVehicle,
  addNewVehicle,
  getListVehicle,
  setVehicleDefault,
  resetMsgVehicle,
  modifyVehicle,
  deleteListVehicle,
  getListAttributes,
  modifyParkingVehicle,
  changeFavouriteVehicle,
  changeVehicleLastMinute,
};
