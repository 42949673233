import { Item } from '@acciona/ui-ionic-kit';
import { IonSpinner, IonText } from '@ionic/react';
import { ItemsHeader } from '../types';
import styles from './styles.module.scss';

export const SearchHeader = props => {
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    actionSubtitle,
    lines,
    loading,
  }: ItemsHeader = props;
  return (
    <Item lines={lines} className={`${styles.item} ${styles.headerContainer} `}>
      <div className={`ion-text-wrap ${styles.columnList}`}>
        <IonText
          color={titleColor}
          className={`${styles.headline} ion-text-wrap ${styles.block}`}
        >
          {title}
        </IonText>

        {subtitle &&
          (loading ? (
            <IonSpinner />
          ) : (
            <IonText
              color={subtitleColor}
              className={`${styles.subhead} ion-text-wrap ${styles.block}`}
              onClick={actionSubtitle}
            >
              {subtitle}
            </IonText>
          ))}
      </div>
    </Item>
  );
};
