import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { listFormat, isEmpty } from '../../utils/functions';
import styles from './styles.module.scss';

export const HomeOfficeDates = ({ type, dates }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng');
  const homeOfficeDates = dates.filter(e => e.homeOffice);

  const allDaysHomeOfficeText = (
    <>
      {t('lbl_reservation_you_are_assigned')} <b>{t('lbl_homeOffice')}</b>{' '}
      {t('lbl_reservation_all_days')}.
    </>
  );

  const onlyOneDayHomeOfficeText = (
    <>
      {t('lbl_reservation_homeOffice_1', {
        date: `${homeOfficeDates.map(e => dayjs(e.date).format('D MMM'))}`,
      })}{' '}
      <b>{t('lbl_homeOffice')}</b>.
    </>
  );

  const multipleDaysHomeOfficeText = (
    <>
      {t('lbl_reservations_homeOffice_1', {
        date: listFormat(
          homeOfficeDates.map(e => dayjs(e.date).format('D MMM')),
          language,
        ),
      })}{' '}
      <b>{t('lbl_homeOffice')}</b>.
    </>
  );

  const text1 =
    homeOfficeDates.length === 1
      ? onlyOneDayHomeOfficeText
      : homeOfficeDates.length > 1 && homeOfficeDates.length === dates.length
      ? allDaysHomeOfficeText
      : multipleDaysHomeOfficeText;

  const text2 =
    homeOfficeDates.length === 1
      ? t('lbl_reservation_homeOffice_2')
      : t('lbl_reservations_homeOffice_2');

  const text3 =
    homeOfficeDates.length === 1
      ? t('lbl_reservation_pk_homeOffice')
      : t('lbl_reservations_pk_homeOffice');

  return !isEmpty(homeOfficeDates) ? (
    <div className={styles.paddingHomeOffice}>
      <div className={styles.subhead}>
        {text1}
        {type?.useType === `Parking` || type?.useType === `Parking-Request`
          ? ` ${text3}`
          : ` ${text2}`}
      </div>
    </div>
  ) : (
    <></>
  );
};
