import {
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import styles from './styles.module.scss';
import { Props } from './types';
import { classes } from './classes';
import {
  Card,
  CardTitle,
  CardHeader,
  CardContent,
  Chip,
} from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { PermanentIcon } from '../PermanentIcon';
export const DashboardReservCard: React.FC<Props> = props => {
  const { data, headerTranslucent, icon, endContent, ...res } = props;
  const { t } = useTranslation();
  const showNeighbourhoodIcon =
    data.neighborhood &&
    ![
      'Room-WaitingList',
      'Room',
      'Desk-Request',
      'Parking-Request',
      'Parking',
      'Permanent-Parking',
      'Passenger',
    ].includes(data.type);
  const isParkingReservation = ['Parking'].includes(data.type);
  const YourNeighbourhoodContent = () => (
    <>
      <IonIcon
        className={`icon icon-neighbourhood ${styles.iconNeighborhood}`}
      />
      {t('lbl_your_district')}
    </>
  );

  return (
    <Card
      className={styles.positionCardWrapper}
      {...res}
      onClick={props.onClick}
    >
      <IonGrid className={styles.generalStyleCard}>
        <IonRow className={styles.generalStyleCardPadding}>
          <IonCol size="auto" className={styles.generalStyleCardColPadding}>
            <div
              className={`${classes[data.type.replace('-', '')]} ${
                data.label && styles.divIconLabel
              } `}
            >
              {data.label ? (
                <IonLabel className={styles.label}>{data.label}</IonLabel>
              ) : (
                <IonIcon className={`${icon} ${styles.iconFont}`} />
              )}
              {['PermanentParking', 'PermanentDesk', 'Locker'].includes(
                data.type,
              ) && <PermanentIcon />}
            </div>
          </IonCol>
          <IonCol className={styles.colCard}>
            <CardHeader
              className={styles.cardHeader}
              translucent={headerTranslucent}
            >
              <CardTitle className={styles.cardTitle}>
                <IonRow>
                  <IonCol
                    size={isParkingReservation ? '7' : '9'}
                    className={styles.cardStyleColTitle}
                  >
                    {data.header}
                  </IonCol>
                  {['Desk', 'Parking', 'Room'].includes(data.type) &&
                    endContent && (
                      <>
                        <IonCol
                          size={isParkingReservation ? '5' : '3'}
                          className={styles.iconsTitle}
                        >
                          {endContent}
                        </IonCol>
                      </>
                    )}
                  {['Parking-Request', 'Desk-Request'].includes(data.type) && (
                    <IonCol
                      size="3"
                      className={`ion-text-end ${styles.chip} ${styles.cardStyleColTitleChip}`}
                    >
                      <Chip type="warning">
                        <IonLabel>{t('lbl_pending')}</IonLabel>
                      </Chip>
                    </IonCol>
                  )}
                  {['Room-WaitingList'].includes(data.type) && (
                    <IonCol
                      size="3"
                      className={`ion-text-end ${styles.chip} ${styles.cardStyleColTitleChip}`}
                    >
                      <Chip type="light" className={styles.dashboardChip}>
                        <IonLabel>{t('lbl_waitingList')}</IonLabel>
                      </Chip>
                    </IonCol>
                  )}
                  {data.link && (
                    <IonCol size="3" className={styles.link}>
                      {data.link}
                    </IonCol>
                  )}
                </IonRow>
              </CardTitle>
            </CardHeader>
            <CardContent className={styles.cardContent}>
              {data.title && (
                <div className={styles.locatdivs}>
                  {showNeighbourhoodIcon ? (
                    <YourNeighbourhoodContent />
                  ) : data.lockerId ? (
                    `${data.title} | ${t('lbl_locker')} ${data.lockerId}`
                  ) : (
                    data.title
                  )}
                </div>
              )}

              {data?.descriptionSede && data?.type !== 'Locker' && (
                <IonRow>
                  <IonText className={styles.descriptionSede}>
                    {t('lbl_head_office_name', {
                      descriptionSede: data.descriptionSede,
                    })}
                  </IonText>
                </IonRow>
              )}
              {data?.buildingName &&
                data?.floorName &&
                data?.type === 'Locker' && (
                  <>
                    <IonRow>
                      <IonText className={styles.descriptionSede}>
                        {data.buildingName}, {data.floorName}
                      </IonText>
                    </IonRow>
                    <IonRow>
                      <IonText className={styles.descriptionSede}>
                        {t('lbl_head_office_name', {
                          descriptionSede: data.descriptionSede,
                        })}
                      </IonText>
                    </IonRow>
                  </>
                )}
              <div className={styles.itemDescription}>{data.subtitle}</div>
            </CardContent>
          </IonCol>
        </IonRow>
        {props.children && (
          <IonRow className={styles.contentDayPicker}>{props.children}</IonRow>
        )}
      </IonGrid>
    </Card>
  );
};
