import { Button, Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonAlert,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonText,
} from '@ionic/react';
import _ from 'lodash';
import styles from './styles.module.scss';
import { useHistory } from 'react-router';
import { DetailList } from '../../../components/Base/DetailList';
import { useTranslation } from 'react-i18next';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { RadioList } from '../../../components/Base/RadioList';
import { useDispatch, useSelector } from 'react-redux';
import { POLICIES_SCHEDULES } from '../../../utils/constants';
import { showPolicies } from '../../../utils/functions';
import { AppStore } from '../../../_redux/models/app.model';
import { useState } from 'react';
import { UserStore } from '../../../_redux/models/user.model';
import {
  ParkingStore,
  UserParkingPreferences,
} from '../../../_redux/models/parking.model';
import { userActions } from '../../../_redux/actions/user.actions';
import { DateTime } from '../../../components/DateTime/DateTime';
import { BackButton } from '../../../components/Base/BackButton';
import {
  extractHourFromDate,
  hourToDateFormat,
  addHoursToDate,
} from '../../../_helpers/datetime';

const ParkingPreferences = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user: { favouriteParkingPolicy },
  } = useSelector((state: UserStore) => state.user);
  const {
    configurations: { policies },
  } = useSelector((state: ParkingStore) => state.parking);
  const initialParkingPrefs = {
    nombre: favouriteParkingPolicy.nombre,
    horaMin: hourToDateFormat(
      favouriteParkingPolicy.nombre === 'Personalizada'
        ? favouriteParkingPolicy.fullHoraMin
        : policies.find(p => p.nombre === 'Personalizada')?.fullHoraMin ??
            '08:00',
    ),
    horaMax: hourToDateFormat(
      favouriteParkingPolicy.nombre === 'Personalizada'
        ? favouriteParkingPolicy.fullHoraMax
        : policies.find(p => p.nombre === 'Personalizada')?.fullHoraMax ??
            '23:00',
    ),
  };
  const { campusTimeZone } = useSelector(
    (store: AppStore) => store.app.globalSettings,
  );
  const customScheduleHoraMin =
    policies.find(p => p.nombre === 'Personalizada')?.fullHoraMin ?? '08:00';
  const customScheduleHoraMax =
    policies.find(p => p.nombre === 'Personalizada')?.fullHoraMax ?? '23:00';
  const [openNoPrefsSavedModal, setOpenNoPrefsSavedModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [prefs, setPrefs] = useState(initialParkingPrefs);

  const handleDismissToast = () => {
    setShowToast(false);
  };
  const handleSavePrefs = () => {
    const newParkingPreferences: UserParkingPreferences = {
      favouriteParkingPolicy: policies.find(policy => {
        return policy.nombre === prefs.nombre;
      }),
    };
    if (
      newParkingPreferences.favouriteParkingPolicy.nombre === 'Personalizada'
    ) {
      const customParkingPreferences: UserParkingPreferences = {
        ...newParkingPreferences,
        favouriteParkingPolicy: {
          ...newParkingPreferences.favouriteParkingPolicy,
          fullHoraMin: extractHourFromDate(prefs.horaMin),
          fullHoraMax: extractHourFromDate(prefs.horaMax),
        },
      };
      dispatch(
        userActions.saveUserParkingPreferences(customParkingPreferences),
      );
    } else {
      dispatch(userActions.saveUserParkingPreferences(newParkingPreferences));
    }
    setShowToast(true);
    setIsEdited(false);
  };

  const checkIsEdited = newPrefs => {
    setIsEdited(!_.isEqual(initialParkingPrefs, newPrefs));
  };

  const handlePrefsChange = (key, value) => {
    setPrefs(oldPrefs => {
      const newPrefs = { ...oldPrefs, [key]: value };
      checkIsEdited(newPrefs);
      return newPrefs;
    });
  };

  const handlePrefsChangesUnsaved = () => {
    if (isEdited) {
      setOpenNoPrefsSavedModal(true);
      return;
    }
    history.replace('/profile/user/parking-menu');
  };

  const getScheduleOptions = () => {
    const scheduleType = [];
    policies &&
      policies.map(conf => {
        const confValue =
          POLICIES_SCHEDULES?.find(e => e.id == conf.nombre) || null;

        if (!confValue) return;

        switch (confValue.value) {
          case 'complete_day':
            scheduleType.push({
              name: t('complete_day'),
              value: 'Dia completo',
            });
            break;
          case 'morning_schedule':
            scheduleType.push({
              name: `${t('morning_schedule', {
                endHour: conf.fullHoraMax,
              })}`,
              value: 'Morning',
            });
            break;
          case 'afternoon_schedule':
            scheduleType.push({
              name: `${t('afternoon_schedule', {
                startHour: conf.fullHoraMin,
              })}`,
              value: 'Tarde',
            });
            break;
          case 'custom_schedule':
            scheduleType.push({
              name: `${t('custom_schedule')}`,
              value: 'Personalizada',
            });
        }
      });
    return scheduleType;
  };
  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={<BackButton onClick={handlePrefsChangesUnsaved} />}
          title={t('lbl_personal_parking_preferences')}
        />
      </IonHeader>
      <IonContent>
        <IonAlert
          mode="ios"
          header={t('header_unsaved_changes')}
          message={t('msg_unsaved_changes')}
          isOpen={openNoPrefsSavedModal}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
              handler: () => {
                setOpenNoPrefsSavedModal(false);
              },
            },
            {
              text: 'Si',
              role: 'confirm',
              handler: () => {
                history.replace('/profile/user/parking-menu');
              },
            },
          ]}
        ></IonAlert>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              <DetailListItem
                lines="full"
                title={t('reservation_schedule')}
                readonly
              />
              {showPolicies(policies) && (
                <RadioList
                  options={getScheduleOptions()}
                  selectedOption={prefs.nombre}
                  onChange={value => {
                    handlePrefsChange('nombre', value);
                  }}
                />
              )}
              {prefs.nombre === 'Personalizada' && (
                <>
                  <DetailListItem
                    lines="full"
                    title={t('select_schedule')}
                    readonly
                  />
                  <DetailListItem
                    mode="ios"
                    lines="none"
                    titleLight={t('init_time')}
                    className={styles.hourText}
                    endText={
                      <DateTime
                        name="horaMin"
                        className={styles.dateTime}
                        display-format="HH:mm"
                        picker-format="HH:mm"
                        minuteValues="0"
                        min={customScheduleHoraMin}
                        max={extractHourFromDate(
                          addHoursToDate(prefs.horaMax, -1),
                        )}
                        placeholder={t('workroom_placeholder')}
                        value={prefs.horaMin}
                        onIonChange={e => {
                          handlePrefsChange('horaMin', e.detail.value!);
                        }}
                        cancelText={t('cancel_text')}
                        doneText={t('done_text')}
                        displayTimezone={campusTimeZone}
                      />
                    }
                  />
                  <div className={styles.lineInsetSeparator}>
                    <div></div>
                  </div>
                  <DetailListItem
                    mode="ios"
                    lines="none"
                    titleLight={t('end_time')}
                    className={styles.hourText}
                    endText={
                      <DateTime
                        name="horaMax"
                        className={styles.dateTime}
                        display-format="HH:mm"
                        picker-format="HH:mm"
                        minuteValues="0"
                        min={extractHourFromDate(
                          addHoursToDate(prefs.horaMin, 1),
                        )}
                        max={customScheduleHoraMax}
                        value={prefs.horaMax}
                        placeholder={t('workroom_placeholder')}
                        onIonChange={e => {
                          handlePrefsChange('horaMax', e.detail.value!);
                        }}
                        disabled={prefs.horaMin == null}
                        cancelText={t('cancel_text')}
                        doneText={t('done_text')}
                        displayTimezone={campusTimeZone}
                      />
                    }
                  />
                  <div className={styles.lineInsetSeparator}>
                    <div></div>
                  </div>
                </>
              )}
            </DetailList>
            <IonText className={styles.hintText}>
              {t('edit_parking_preferences_hint')}
            </IonText>
            <Toast
              isOpen={showToast}
              onDidDismiss={() => handleDismissToast()}
              message={t('preferences_saved_toast')}
              position="bottom"
              type="success"
            />
          </div>
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleSavePrefs} disabled={!isEdited}>
              {t('preferences_button')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default ParkingPreferences;
