import { FilterType } from '../../../Dashboard/Home/types';
export const filterTypes: FilterType[] = [
  {
    id: 'all',
    text: 'lbl_all',
    checked: true,
  },
  {
    id: 'favorite',
    text: 'lbl_favorite',
    checked: false,
  },
  {
    id: 'historic',
    text: 'lbl_historic',
    checked: false,
  },
];
