import { IonLoading } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Toast } from '@acciona/ui-ionic-kit';
import { shuttlesAndRoutesActions } from '../../../../../../_redux/actions';
import { ShuttlesAndRoutesStore } from '../../../../../../_redux/models/shuttlesAndRoutes.model';
import { Cover } from '../../../templates/Cover/Cover';

const RoutesAndShuttle: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { shuttles, isLoading, error } = useSelector(
    (store: ShuttlesAndRoutesStore) => store.shuttles,
  );

  return (
    <Cover
      backgroundImageDesktop="./assets/images/routeAndShuttleXXL.jpg"
      title={t('lbl_shuttles')}
      subtitle={t('lbl_subtitle_routesAndShuttle')}
      menuTitle={t('lbl_shuttles')}
      menuIcon="bus"
      menuItems={shuttles}
      itemDetailUrl="/services/howtoget/shuttleinfotmation"
      onOpenMenu={() => dispatch(shuttlesAndRoutesActions.getShuttles())}
    >
      <Toast isOpen={!!error} message={error} position="bottom" type="error" />
      <IonLoading
        isOpen={isLoading}
        message={t('msg_loading')}
        duration={6000}
      />
    </Cover>
  );
};

export default RoutesAndShuttle;
