import {
  Message,
  PassengerData,
  PassengersAction,
} from '../models/passengers.model';
import { passengersServices } from '../services/passengers/passengers.services';
import { passengersTypesEnum } from '../types/passengers.types.enum';
import { history } from '../../_helpers/history';
import {
  convertListToText,
  getCompanionsFilteredByParkingPermission,
  isEmpty,
} from '../../utils/functions';
import i18n from '../../i18nextConf';
import { NotificationsActions } from './notifications.actions';

const setAvailablePassengers = (
  availablePassengers: PassengerData[],
): PassengersAction => ({
  type: passengersTypesEnum.SET_AVAILABLE_PASSENGERS,
  availablePassengers: availablePassengers,
});

const setDefaultPassengers = (
  defaultPassengers: PassengerData[],
): PassengersAction => ({
  type: passengersTypesEnum.SET_DEFAULT_PASSENGERS,
  defaultPassengers: defaultPassengers,
});

const setParkingCompanions = (
  parkingCompanions: PassengerData[],
): PassengersAction => ({
  type: passengersTypesEnum.SET_DEFAULT_PARKING_COMPANIONS,
  parkingCompanions: parkingCompanions,
});

const addNewDefaultPassengers = (
  defaultPassengers: PassengerData[],
): PassengersAction => ({
  type: passengersTypesEnum.ADD_PASSENGERS,
  defaultPassengers: defaultPassengers,
});

const deletePassenger = (
  defaultPassengers: PassengerData[],
): PassengersAction => ({
  type: passengersTypesEnum.DELETE_PASSENGER,
  defaultPassengers: defaultPassengers,
});

const setPassengers = (
  passengersSelected: PassengerData[],
): PassengersAction => ({
  type: passengersTypesEnum.SET_PASSENGERS,
  passengersSelected: passengersSelected,
});

const deleteParkingCompanion = (
  parkingCompanion: PassengerData,
): PassengersAction => ({
  type: passengersTypesEnum.DELETE_PARKING_COMPANION,
  passenger: parkingCompanion,
});

const addCompanions = (
  parkingCompanions: PassengerData[],
): PassengersAction => ({
  type: passengersTypesEnum.ADD_PARKING_COMPANION,
  parkingCompanions: parkingCompanions,
});

const passengersRequest = (): PassengersAction => {
  return {
    type: passengersTypesEnum.REQUEST,
  };
};

const fetchPassengersFailure = (error: string): PassengersAction => {
  return {
    type: passengersTypesEnum.FAILURE_PASSENGERS,
    error: error,
  };
};

const setMsgPassengers = (msgAction: Message): PassengersAction => ({
  type: passengersTypesEnum.MSG_PASSENGERS,
  msg: msgAction,
});

const resetMsgPassengers = (): PassengersAction => ({
  type: passengersTypesEnum.RESET_MESSAGE,
});

const resetParkingCompanions = (): PassengersAction => ({
  type: passengersTypesEnum.RESET_PARKING_COMPANIONS,
});

const getAvailablePassengers = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(passengersRequest());
    try {
      const passengersDetails =
        await passengersServices.getAvaliablePassengers();
      dispatch(setAvailablePassengers(passengersDetails));
    } catch (e) {
      dispatch(fetchPassengersFailure(e.message));
    }
  };
};

const getDefaultPassengers = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(passengersRequest());
    try {
      const passengersDetails = await passengersServices.getDefaultPassengers();
      dispatch(setDefaultPassengers(passengersDetails));
    } catch (e) {
      dispatch(fetchPassengersFailure(e.message));
    }
  };
};

const addDefaultPassengers = passengers => {
  return async (dispatch: any): Promise<any> => {
    dispatch(passengersRequest());
    try {
      const addPassengers = await passengersServices.addDefaultPassengers(
        passengers,
      );
      dispatch(addNewDefaultPassengers(addPassengers));
      dispatch(setParkingCompanions([]));
      dispatch(
        setMsgPassengers({
          type: 'success',
          description: `${i18n.t('lbl_add_passengers')} `,
        }),
      );
      history.replace('/profile/user/vehicles-passengers/');
    } catch (e) {
      dispatch(fetchPassengersFailure(e.message));
    }
  };
};

const deleteDefaultPassengers = (passenger: PassengerData[]) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(passengersRequest());
    try {
      const deletePassengers = await passengersServices.deleteDefaultPassengers(
        passenger,
      );
      dispatch(deletePassenger(passenger));
      dispatch(setParkingCompanions([]));
      if (!isEmpty(deletePassengers)) {
        dispatch(getDefaultPassengers());
        dispatch(
          setMsgPassengers({
            type: 'success',
            description: `${i18n.t('lbl_delete_passenger_toast')} `,
          }),
        );
      }
    } catch (e) {
      dispatch(fetchPassengersFailure(e.message));
    }
  };
};

const getDefaultParkingCompanions = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(passengersRequest());
    try {
      const passengersDetails = await passengersServices.getDefaultPassengers();
      const { withParkingPermissions, withoutParkingPermissions } =
        getCompanionsFilteredByParkingPermission(passengersDetails);
      dispatch(setDefaultPassengers(passengersDetails));
      dispatch(setParkingCompanions(withParkingPermissions));
      if (withoutParkingPermissions.length > 0) {
        dispatch(
          NotificationsActions.setGenericAlert(
            `${i18n.t('lbl_passenger_restriction_title')}`,
            getCompanionWithoutPermissionAlertMessage(
              withoutParkingPermissions,
            ),
          ),
        );
      }
    } catch (e) {
      dispatch(fetchPassengersFailure(e.message));
    }
  };
};

const addParkingCompanions = array => {
  return async (dispatch: any): Promise<any> => {
    try {
      array.forEach(obj => {
        obj.defaultCompanion === undefined && (obj.defaultCompanion = false);
      });
      dispatch(
        setMsgPassengers({
          type: 'success',
          description: `${i18n.t('lbl_add_passengers')} `,
        }),
      );
      dispatch(addCompanions(array));
      history.goBack();
    } catch (e) {
      dispatch(fetchPassengersFailure(e.message));
    }
  };
};

const deleteAcceptedPassengerInvitation = (ids: string[]) => {
  return async (dispatch: any): Promise<any> => {
    try {
      await passengersServices.deleteAcceptedPassengerInvitation({
        requestIds: ids.map(v => parseInt(v)),
      });
      dispatch(
        NotificationsActions.setGenericSuccessToast(
          i18n.t('msg_invitation_deleted'),
        ),
      );
      history.replace('/dashboard/reservations');
    } catch (e) {
      dispatch(NotificationsActions.setGenericErrorToast(e.message));
      dispatch(fetchPassengersFailure(e.message));
    }
  };
};

const getCompanionWithoutPermissionAlertMessage = (
  companions: PassengerData[],
): string => {
  const names = companions.map(companion => companion.fullName);
  const namesConvertedToText = convertListToText(
    names,
    ', ',
    ` ${i18n.t('and')}`,
  );
  return `${namesConvertedToText} ${i18n.t(
    names.length > 1
      ? 'lbl_passenger_restriction_message_plural'
      : 'lbl_passenger_restriction_message_singular',
  )}`;
};

export const passengersActions = {
  getAvailablePassengers,
  getDefaultPassengers,
  addDefaultPassengers,
  deleteDefaultPassengers,
  setPassengers,
  getDefaultParkingCompanions,
  deleteParkingCompanion,
  addParkingCompanions,
  resetMsgPassengers,
  resetParkingCompanions,
  setMsgPassengers,
  deleteAcceptedPassengerInvitation,
};
