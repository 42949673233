import { Icon } from '@acciona/ui-ionic-kit';
import { IonCol, IonLabel } from '@ionic/react';
import { PermanentIcon } from '../PermanentIcon';
import { classes } from './classes';
import styles from './styles.module.scss';

export const ReservationCardIcon = ({ type, icon, infoSize, label }) => {
  return (
    <IonCol size="auto">
      <div
        className={`${styles.divInfo} ${classes[type.replace('-', '')]} ${
          styles[infoSize]
        } `}
      >
        {label ? (
          <IonLabel className={`${styles.subhead} ${styles.color_primary}`}>
            {label}
          </IonLabel>
        ) : (
          <Icon className={`${icon} ${styles.iconFont}`} />
        )}
        {['PermanentParking', 'PermanentDesk', 'Locker'].includes(type) && (
          <PermanentIcon isListReservation />
        )}
      </div>
    </IonCol>
  );
};
