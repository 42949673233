import {
  Route,
  RoutesAction,
  Shuttle,
  ShuttlesAction,
} from '../models/shuttlesAndRoutes.model';
import { shuttlesAndRoutesServices } from '../services/shuttlesAndRoutes/shuttlesAndRoutes.services';
import { shuttlesAndRoutesTypesEnum } from '../types/shuttlesAndRoutes.types.enum';

const shuttlesRequest = (): ShuttlesAction => {
  return {
    type: shuttlesAndRoutesTypesEnum.SHUTTLES_REQUEST,
  };
};

const fetchShuttlesFailure = (error: string): ShuttlesAction => {
  return {
    type: shuttlesAndRoutesTypesEnum.FAILURE_SHUTTLES,
    error,
  };
};

const setShuttles = (shuttles: Shuttle[]): ShuttlesAction => ({
  type: shuttlesAndRoutesTypesEnum.SET_SHUTTLES,
  shuttles,
});

const getShuttles = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(shuttlesRequest());
    try {
      const shuttles = await shuttlesAndRoutesServices.getShuttles();
      dispatch(setShuttles(shuttles));
    } catch (e) {
      dispatch(fetchShuttlesFailure(e.message));
    }
  };
};

const fetchRoutesFailure = (error: string): ShuttlesAction => {
  return {
    type: shuttlesAndRoutesTypesEnum.FAILURE_SHUTTLES,
    error,
  };
};

const setRoutes = (routes: Route[]): RoutesAction => ({
  type: shuttlesAndRoutesTypesEnum.SET_ROUTES,
  routes,
});

const routesRequest = (): ShuttlesAction => {
  return {
    type: shuttlesAndRoutesTypesEnum.ROUTES_REQUEST,
  };
};

const getRoutes = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(routesRequest());
    try {
      const routes = await shuttlesAndRoutesServices.getRoutes();
      dispatch(setRoutes(routes));
    } catch (e) {
      dispatch(fetchRoutesFailure(e.message));
    }
  };
};

export const shuttlesAndRoutesActions = {
  getShuttles,
  getRoutes,
};
