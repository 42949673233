import {
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  // IonLoading,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Toast, Button } from '@acciona/ui-ionic-kit';
import { useDispatch, useSelector } from 'react-redux';
import { NewsStore } from '../../../../_redux/models/news.model';
import { NewsActions } from '../../../../_redux/actions/news.actions';
import styles from './styles.module.scss';
import { useEffect, useRef, useState } from 'react';
import { ListCard } from '../../../../components/Base/ListCard';
import { NewsSecondaryCard } from '../../../../components/Base/NewsSecondaryCard';
import Comments from '../Comments/Comments';
import { Modal } from '@acciona/ui-ionic-kit';
import { isEmpty } from '../../../../utils/functions';
import _ from 'lodash';
import useDataNews from '../../../../hooks/useDataNews';
import { useHistory } from 'react-router';
import { NewsCarrousel } from '../../../../components/Base/NewsCarrousel';
import ListNewsCatPagination from '../components/ListNewsCatPagination/ListNewsCatPagination';

const News: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    newslistCarrousel,
    newslistOther,
    newslistHighlights,
    newslist,
    moreNews,
    isLoading,
  } = useSelector((store: NewsStore) => store.news);
  const tabPosition = useSelector((store: NewsStore) => store.news.tabPosition);
  const { error, categories } = useSelector((store: NewsStore) => store.news);
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const [showComments, setShowComments] = useState(false);
  const [topNews, setTopNews] = useState(1);
  const contentNews = useRef<any>();
  const newsCardCarrousel = useDataNews(newslistCarrousel);
  const newsCardOther = useDataNews(newslistOther);
  const newsCardHigh = useDataNews(newslistHighlights);
  const newsCard = useDataNews(newslist);
  const buttonRef = useRef<any>();
  const topParam = 1;
  const history = useHistory();

  const filterCategory = [
    { id: 'highlights', text: t('lbl_highlights') },
    { id: 'all', text: t('all_news') },
    // { id: 'saved', text: t('lbl_filter_saved') },
  ];

  const handleSelected = id => {
    dispatch(NewsActions.selectNews(id, history.location.pathname));

    const offSetPosition =
      selectedFilter === 'highlights'
        ? 0
        : document.getElementById(id).offsetTop;
    dispatch(
      NewsActions.selectedTabPosition(selectedFilter, offSetPosition - 30),
    );
  };

  useIonViewDidEnter(() => {
    contentNews.current?.scrollToTop();
    dispatch(NewsActions.getCategories());
    dispatch(NewsActions.getNewsCarrousel());
    dispatch(NewsActions.getNewsHighlights());
    dispatch(NewsActions.getNewsOther(false));
    dispatch(NewsActions.getNewslist(1, false));
  });

  useIonViewDidLeave(() => {
    setTopNews(1);
  });

  useEffect(() => {
    if (!isEmpty(tabPosition)) {
      setSelectedFilter(tabPosition.tab);
    } else {
      setSelectedFilter('highlights');
    }
  }, [tabPosition]);

  const handleLoadNews = () => {
    setTopNews(topNews + topParam);
    dispatch(NewsActions.getNewslist(topNews + topParam, true));
  };

  const goToButtonMoreNews = () => {
    const btnPos = document.getElementById('buttonMoreNews')?.offsetTop;
    contentNews.current?.scrollToPoint(0, btnPos - 30);
  };

  useEffect(() => {
    topNews > 1 && goToButtonMoreNews();
  }, [newslist]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className={styles.header}>
          <IonTitle>{t('tab_news')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentNews} fullscreen scrollEvents>
        <IonHeader collapse="condense">
          <IonToolbar className={styles.headerIOS}>
            <IonTitle size="large" className={styles.largeTitleBoldNews}>
              {t('tab_news')}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className={`${styles.gridWeb} ${styles.filtersFixed}`}>
          <div className={styles.filters}>
            <div className={styles.gridScroll}>
              {filterCategory.map(filter => {
                return (
                  <IonChip
                    outline={true}
                    onClick={() => setSelectedFilter(filter.id)}
                    key={filter.id}
                    className={`${styles.chipBtn} ${
                      selectedFilter == filter.id ? styles.chipActive : null
                    }`}
                  >
                    <IonLabel className={styles.segmentedLabel}>
                      {filter.text}
                    </IonLabel>
                  </IonChip>
                );
              })}
              {categories &&
                categories.map(e => {
                  return (
                    <IonChip
                      outline={true}
                      onClick={() => {
                        setSelectedFilter(e.id);
                      }}
                      key={e.id}
                      className={`${styles.chipBtn} ${
                        selectedFilter == e.id ? styles.chipActive : null
                      }`}
                    >
                      <IonLabel className={styles.segmentedLabel}>
                        {e.name}
                      </IonLabel>
                    </IonChip>
                  );
                })}
            </div>
          </div>
          <div className={styles.separator} />
        </div>

        {newsCardCarrousel && !isEmpty(newsCardCarrousel) && (
          <div
            style={selectedFilter !== 'highlights' ? { display: 'none' } : null}
          >
            <NewsCarrousel
              data={newsCardCarrousel}
              slideClass={styles.content}
              imageClass={styles.imgCard}
              skeletonClass={styles.skeleton}
              overlayClass={styles.imgOverlay}
              onClick={e => handleSelected(e)}
              fullImg={true}
              dashboardClass={false}
            />
          </div>
        )}

        {newsCard && !isEmpty(newsCard) && selectedFilter === 'all' ? (
          <IonGrid className={`${styles.newsWrapper} ${styles.gridWeb}`}>
            <IonRow className={styles.listCards}>
              {newsCard.map(data => {
                return (
                  <IonCol
                    id={data.id}
                    key={data.id}
                    size="12"
                    sizeSm="6"
                    sizeMd="6"
                    sizeLg="6"
                    sizeXl="4"
                    className={styles.colAlignCenter}
                  >
                    <NewsSecondaryCard
                      data={data}
                      onClick={() => handleSelected(data.nav_id)}
                      mode="ios"
                    />
                  </IonCol>
                );
              })}
            </IonRow>
            {isLoading ? (
              <div className="ion-text-center">
                <IonSpinner name="dots"></IonSpinner>
              </div>
            ) : (
              moreNews && (
                <div
                  className={`${styles.buttonMoreContainer}`}
                  ref={buttonRef}
                >
                  <Button
                    id="buttonMoreNews"
                    className={styles.moreNews}
                    color="lighter"
                    onClick={handleLoadNews}
                  >
                    {t('lbl_load_more_news')}
                  </Button>
                </div>
              )
            )}
          </IonGrid>
        ) : null}

        {!_.includes(['highlights', 'all', 'saved'], selectedFilter) && (
          <ListNewsCatPagination
            handleSelected={handleSelected}
            category={selectedFilter}
          />
        )}

        {selectedFilter === 'highlights' && isLoading && (
          <div className="ion-text-center">
            <IonSpinner name="dots"></IonSpinner>
          </div>
        )}

        {newsCardHigh &&
        selectedFilter === 'highlights' &&
        !isEmpty(newsCardHigh) ? (
          <IonGrid className={`${styles.newsWrapper} ${styles.gridWeb}`}>
            <div className={styles.lineSeparation}></div>
            <IonRow className={styles.listCards}>
              {newsCardHigh &&
                newsCardHigh.map(data => {
                  return (
                    <IonCol
                      id={data.id}
                      key={data.id}
                      size="12"
                      sizeSm="6"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXl="4"
                      className={styles.colAlignCenter}
                    >
                      <ListCard
                        className={`${
                          _.last(newsCardHigh).id === data.id
                            ? styles.listCardResponsiveLastType
                            : styles.listCardResponsive
                        } ${styles.cardImageNews}`}
                        data={data}
                        onClick={() => handleSelected(data.nav_id)}
                        isNews={true}
                      />
                    </IonCol>
                  );
                })}
            </IonRow>
          </IonGrid>
        ) : null}
        <div className={styles.separatorNews}></div>
        {selectedFilter === 'highlights' && !isEmpty(newsCardOther) ? (
          <IonGrid className={`${styles.newsWrapper} ${styles.gridWeb}`}>
            <IonRow className={styles.newsCardOther}>
              <div className={`${styles.otherNews}`}>
                <IonLabel>
                  {!isEmpty(newsCardOther) && t('lbl_other_news')}
                </IonLabel>
              </div>
              {newsCardOther &&
                newsCardOther.map(data => {
                  return (
                    <IonCol
                      id={data.id}
                      key={data.id}
                      size="12"
                      sizeSm="6"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXl="4"
                      className={styles.colAlignCenter}
                    >
                      <NewsSecondaryCard
                        data={data}
                        onClick={() => handleSelected(data.nav_id)}
                        mode="ios"
                        displaySocial={false}
                      />
                    </IonCol>
                  );
                })}
            </IonRow>
          </IonGrid>
        ) : null}
        <Modal isOpen={showComments}>
          <Comments setShowComments={setShowComments} />
        </Modal>
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
        {/* <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={0}
        /> */}
      </IonContent>
    </IonPage>
  );
};

export default News;
