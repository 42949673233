import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import UserDetail from './UserDetail/UserDetail';
import Profile from './Profile';
import User from './User/User';
import VehiclesPassengers from './VehiclesPassengers/VehiclesPassengers';
import FavoriteWorkstations from './FavoriteWorkstations/FavoriteWorkstations';
import FavoriteMapSelector from './FavoriteMapSelector/FavoriteMapSelector';
import Passengers from './VehiclesPassengers/Passengers/Passengers';
import VehicleDetail from './VehiclesPassengers/Vehicles/VehicleDetail/VehicleDetail';
import Card from './Card/Card';
import CardActivation from './Card/CardActivation/CardActivation';
import Search from './Search/Search';
import PersonDetail from './Search/PersonDetail/PersonDetail';
import Settings from './Settings/Settings';
import Language from './Settings/Language/Language';
import HeadOffice from './Settings/HeadOffice/HeadOffice';
import PersonalIncidence from './PersonalIncidence/PersonalIncidence';
import WorkPlace from './WorkPlace/WorkPlace';
import Legal from './Legal/Legal';
import Wallet from './Wallet/Wallet';
import ReservationDetail from './Search/PersonDetail/ReservationDetail/ReservationDetail';
import CanRoute from '../../components/CanRoute/CanRoute';
import WorkPlaceMenu from './WorkPlaceMenu/WorkPlaceMenu';
import WorkEditPreferences from './WorkPreferences/WorkEditPreferences';
import MobileOnlyRoute from '../../components/MobileOnlyRoute/MobileOnlyRoute';
import ParkingMenu from './ParkingMenu/ParkingMenu';
import ParkingPreferences from './ParkingPreferences/ParkingPreferences';
import ParkingCutOff from './ParkingCutOff/ParkingCutOff';

const ProfileRouter: React.FC<any> = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/profile/home">
          <Profile />
        </Route>
        <Route exact path="/profile/user">
          <User />
        </Route>
        <Route exact path="/profile/user/work-place-menu">
          <WorkPlaceMenu />
        </Route>
        <Route exact path="/profile/user/work-preferences">
          <WorkEditPreferences />
        </Route>
        <Route exact path="/profile/search">
          <CanRoute functionality="search">
            <Search />
          </CanRoute>
        </Route>
        <Route exact path="/profile/legal">
          <Legal />
        </Route>
        <Route exact path="/profile/search/detail-person">
          <PersonDetail />
        </Route>
        <Route exact path="/profile/user/detail">
          <UserDetail />
        </Route>
        <Route exact path="/profile/user/parking-menu">
          <ParkingMenu />
        </Route>
        <Route exact path="/profile/user/parking-preferences">
          <ParkingPreferences />
        </Route>
        <Route exact path="/profile/user/parking-cut-off">
          <ParkingCutOff />
        </Route>
        <Route exact path="/profile/user/vehicles-passengers">
          <VehiclesPassengers />
        </Route>
        <Route exact path="/profile/user/vehicles-passengers/vehicle-detail">
          <VehicleDetail />
        </Route>
        <Route exact path="/profile/user/work-place">
          <WorkPlace />
        </Route>
        <Route exact path="/profile/user/favworkstations">
          <FavoriteWorkstations />
        </Route>
        <Route exact path="/profile/user/favworkstations/mapSelector">
          <FavoriteMapSelector />
        </Route>
        <Route exact path="/profile/user/vehicles-passengers/passengers-detail">
          <Passengers />
        </Route>
        <MobileOnlyRoute exact path="/profile/user/card">
          <Card />
        </MobileOnlyRoute>
        <MobileOnlyRoute exact path="/profile/user/card/card-activation">
          <CardActivation />
        </MobileOnlyRoute>
        <Route exact path="/profile/settings">
          <Settings />
        </Route>
        <Route exact path="/profile/settings/language">
          <Language />
        </Route>
        <Route exact path="/profile/settings/head-office">
          <HeadOffice />
        </Route>
        <Route exact path="/profile/wallet">
          <CanRoute functionality="wallet">
            <Wallet />
          </CanRoute>
        </Route>
        <Route exact path="/profile">
          <Redirect to="/profile/home" />
        </Route>
        <Route exact path="/profile/incidence">
          <PersonalIncidence />
        </Route>
        <Route exact path="/profile/search/reservationdetail">
          <ReservationDetail />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ProfileRouter;
