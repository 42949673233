import { ReserveVehicles } from '../../../_redux/models/parking.model';
import { Vehicle } from '../../../_redux/models/vehicles.model';
import _ from 'lodash';

export const getAllDatesMachWithVehicle = (
  selectedDates: Date[],
  reserveVehicles: ReserveVehicles[],
  suggestedVehicle: Vehicle,
) => {
  const selectedDateToStrings = selectedDates?.map(
    date => new Date(date).toISOString().split('T')[0],
  );

  return selectedDateToStrings?.every(date => {
    return (
      _.isEmpty(reserveVehicles) ||
      reserveVehicles.some(v => {
        const dateIncludedInVehicleReservation = v.day.includes(date);
        if (!dateIncludedInVehicleReservation) return true;
        return v.plate === suggestedVehicle.plate;
      })
    );
  });
};
