import {
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { useLocationState } from '../../../hooks/useLocationState';

const Confirmation: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [space, setSpace] = useState({
    title: '',
    code: '',
    msg: '',
    date: new Date().toDateString(),
    router: '/',
    parmsRouter: {},
  });

  const state = useLocationState() as any;

  useEffect(() => {
    const backFunction = ev => {
      ev.detail.register(10, () => {
        history.replace(state.router, state.parmsRouter);
      });
    };

    document.addEventListener('ionBackButton', backFunction);

    setSpace(prevSpace => {
      return {
        ...prevSpace,
        title: t(state.title),
        msg: t(state.msg),
        code: t(state.code),
        router: state.router,
        parmsRouter: state.parmsRouter,
      };
    });

    return function cleanup() {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(
      () => history.replace(state.router, state.parmsRouter),
      5000,
    );
    return function cleanup() {
      clearTimeout(timer);
    };
  }, [state.router]);

  useEffect(() => {
    return () => {
      if (!isPlatform('capacitor') && history.action === 'POP') {
        history.replace(state.router, state.parmsRouter);
      }
    };
  }, [history]);

  return (
    <IonPage>
      <IonContent
        className={`${styles.generalContent} ion-no-border`}
        onClick={() => history.replace(state.router, state.parmsRouter)}
      >
        <IonGrid className={styles.grid}>
          <IonRow className={`${styles.imgContent} ion-justify-content-center`}>
            <div className={styles.iconItem}>
              <IonIcon
                className={`${styles.checkIcon} icon icon-confirmation icon-250`}
              />
            </div>
          </IonRow>
          <IonRow className={`${styles.txtContent} ion-justify-content-center`}>
            <IonText className={styles.title_2} color="light">
              {space.title}
            </IonText>
            <IonText className={styles.subhead} color="light">
              {space.msg}
            </IonText>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Confirmation;
