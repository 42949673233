import _ from 'lodash';
import { Configurations } from '../../_redux/models/parking.model';
import { reservedDay } from '../../_redux/models/reservation.model';
import i18n from '../../i18nextConf';
import { POLICIES_SCHEDULES } from '../../utils/constants';
import { settingsReservations } from './types';
import dayjs from 'dayjs';
import { DateTime, Settings } from 'luxon';
import { isEmpty } from '../../utils/functions';
Settings.defaultZoneName = 'local';

export const filterReservationsFunction = (
  reservation: any,
  type: string,
  start: string,
  end: string,
  datesSelected: string[],
) => {
  const reservationType = reservation?.reservationType ?? null;

  if (!reservationType || !type) {
    return false;
  }

  const dates = datesSelected
    ? datesSelected.map(d => d.substring(0, 10))
    : null;

  if (!dates) {
    return (
      reservationType === type &&
      reservation?.startHour?.substring(0, 5) === start &&
      reservation?.endHour?.substring(0, 5) === end
    );
  } else {
    const dateReservation =
      reservation?.reservationDate?.substring(0, 10) ??
      reservation?.from?.substring(0, 10) ??
      '';

    if (!dates.includes(dateReservation)) {
      return false;
    } else if (type === 'Personalizada' && reservationType) {
      return (
        reservationType === type &&
        reservation?.startHour?.substring(0, 5) === start &&
        reservation?.endHour?.substring(0, 5) === end
      );
    } else {
      return type == reservationType;
    }
  }
};

export const getFilteredReservations = (
  reservations: any[],
  datesSelected: string[],
  typeReservation: string,
  startHour: string,
  endHour: string,
) => {
  if (_.isEmpty(reservations)) {
    return reservations;
  }

  const type = typeReservation ?? reservations[0]?.reservationType;
  const start = startHour ?? reservations[0]?.startHour;
  const end = endHour ?? reservations[0]?.endHour;
  const filteredDates = reservations.filter(reservation =>
    filterReservationsFunction(reservation, type, start, end, datesSelected),
  );

  const reservationFiltered = _.isEmpty(filteredDates)
    ? reservations
    : filteredDates;

  return reservationFiltered;
};

export const getParkingReservations = (reservations: any[]) => {
  if (isEmpty(reservations)) {
    return [];
  }

  return reservations.map(date => ({
    id: date.reservationId,
    status: false,
    date: dayjs(
      `${dayjs(date.from).utc().format('YYYY-MM-DD')} 00:00:00`,
    ).toDate(),
    homeOffice: date.homeOffice,
  }));
};

export const getParkingFilteredReservations = (
  reservations: any[],
  datesSelected: string[],
  typeReservation: string,
  startHour: string,
  endHour: string,
) => {
  const reservationFiltered = getFilteredReservations(
    reservations,
    datesSelected,
    typeReservation,
    startHour,
    endHour,
  );

  if (_.isEmpty(reservationFiltered)) {
    return [];
  }

  return reservationFiltered.map(date => ({
    id: date.reservationId,
    status: false,
    date: new Date(
      dayjs(
        `${dayjs(date.from).utc().format('YYYY-MM-DD')} 00:00:00`,
      ).toISOString(),
    ),
    homeOffice: date.homeOffice,
  }));
};

export const getWorkstationFilteredReservations = (
  reservations: any[],
  datesSelected: string[],
  typeReservation: string,
  startHour: string,
  endHour: string,
) => {
  const reservationFiltered = getFilteredReservations(
    reservations,
    datesSelected,
    typeReservation,
    startHour,
    endHour,
  );

  if (_.isEmpty(reservationFiltered)) {
    return [];
  }

  const filtered = [];

  reservationFiltered.forEach(date => {
    if (date?.reservationDate) {
      filtered.push({
        id: date.reservationId,
        status: false,
        date: `${dayjs(date.reservationDate)
          .utc()
          .format('YYYY-MM-DD')}T00:00:00Z`,
        homeOffice: date.homeOffice,
      });
    }
  });

  return filtered;
};

export const getTypeLabel = (space, configurations) => {
  const config =
    configurations?.policies?.find(e => space.type == e.nombre) || null;
  switch (space.type) {
    case 'Dia completo':
      return i18n.t('complete_day');
    case 'Morning':
      return `${i18n.t('morning_schedule', { endHour: config?.fullHoraMax })}`;
    case 'Tarde':
      return `${i18n.t('afternoon_schedule', {
        startHour: config?.fullHoraMin,
      })}`;
    case 'Personalizada':
      return `${i18n.t('custom_schedule')} (${space.hours.start}-${
        space.hours.end
      })`;
  }

  return '';
};

export const checkReservations = (
  settings: settingsReservations,
  reservedDays: reservedDay[],
  campusTimeZone: string,
  configurations: Configurations,
) => {
  const reservedGrouped = _.groupBy(reservedDays, 'date');
  const reservationsNonClickable = [];

  const hourCustomMin = settings?.customStart;
  const hourCustomMax = settings?.customEnd;

  if (!hourCustomMin || !hourCustomMax) {
    return [];
  }
  const currentDay = dayjs().tz(campusTimeZone).format('YYYY-MM-DD');

  const formattedTime = dayjs().tz(campusTimeZone).format('HH:mm');

  const startConfig = createDateUTC(currentDay, hourCustomMin, campusTimeZone);
  const endConfig = createDateUTC(currentDay, hourCustomMax, campusTimeZone);

  const confId =
    POLICIES_SCHEDULES?.find(e => e.value == settings?.schedule).id || null;
  const config =
    configurations?.policies?.find(e => confId == e?.nombre) || null;

  if (!confId || !config) {
    return [];
  }
  const configHourEnd = config?.fullHoraMax.split(':');
  const nowTime = formattedTime.split(':');

  const hourNow = parseInt(nowTime[0], 10);
  const minutesHour = parseInt(nowTime[1], 10);

  const confEndHour = parseInt(configHourEnd[0], 10);
  const confEndMinutes = parseInt(configHourEnd[1], 10);

  if (
    settings.schedule === 'morning_schedule' ||
    settings.schedule === 'afternoon_schedule'
  ) {
    if (
      hourNow > confEndHour ||
      (hourNow === confEndHour && minutesHour > confEndMinutes)
    ) {
      reservationsNonClickable.push(currentDay);
    }
  }
  if (settings.schedule === 'custom_schedule') {
    const customConfigEnd = hourCustomMax.split(':');
    const customEndHour = parseInt(customConfigEnd[0], 10);
    const customEndMinute = parseInt(customConfigEnd[1], 10);
    if (
      hourNow > customEndHour ||
      (hourNow === customEndHour && minutesHour > customEndMinute)
    ) {
      reservationsNonClickable.push(currentDay);
    }
  }
  const startHourConfig =
    createDateUTC(currentDay, config?.fullHoraMin, campusTimeZone) ||
    new Date();
  const endHourConfig =
    createDateUTC(currentDay, config?.fullHoraMax, campusTimeZone) ||
    new Date();

  for (const reservation of Object.keys(reservedGrouped)) {
    const objetosAgrupados = reservedGrouped[reservation];

    objetosAgrupados.forEach(re => {
      if (settings.schedule === 'complete_day') {
        reservationsNonClickable.push(re.date);
        return;
      }

      const reservationTimezone =
        re?.headOffice?.campusTimeZone || campusTimeZone;
      const startReservationHour =
        createDateUTC(currentDay, re?.hours?.start, reservationTimezone) ||
        new Date();
      const endReservationHour =
        createDateUTC(currentDay, re?.hours?.end, reservationTimezone) ||
        new Date();

      switch (re.type) {
        case 'Dia completo':
          reservationsNonClickable.push(re.date);
          return;
        case 'Morning':
        case 'Tarde':
        case 'Personalizada':
          if (settings.schedule == 'custom_schedule') {
            if (
              startReservationHour < endConfig &&
              endReservationHour > startConfig
            ) {
              reservationsNonClickable.push(re.date);
              return;
            }
          } else {
            if (
              startReservationHour < endHourConfig &&
              startHourConfig < endReservationHour
            ) {
              reservationsNonClickable.push(re.date);
              return;
            }
          }
      }
    });
  }

  return _.uniq(reservationsNonClickable);
};

export const getIconSchedule = type => {
  return POLICIES_SCHEDULES.find(e => e.id == type)?.icon;
};

export const getReservationDetailIconSchedule = (
  config: {
    typeReservation: string;
    startHour: string;
    endHour: string;
  },
  reservations: any[],
) => {
  if (!config.typeReservation) {
    return getIconSchedule(reservations[0]?.reservationType);
  }

  const type =
    reservations.find(
      reservation =>
        reservation?.reservationType === config.typeReservation &&
        reservation?.startHour === config.startHour &&
        reservation?.endHour === config.endHour,
    )?.reservationType ?? reservations[0]?.reservationType;

  return getIconSchedule(type);
};

export const createDateUTC = (date: string, hour: string, timezone: string) => {
  const fullDate = `${date}T${hour}`;
  const localDate = DateTime.fromISO(fullDate, { zone: timezone });
  const dateUTC = localDate.toUTC().toJSDate();

  return dateUTC;
};

export const getDetailTypeReservationLabel = reservation => {
  if (!reservation) {
    return '';
  }

  const type = reservation.reservationType || '';
  const startHour = reservation.startHour || '';
  const endHour = reservation.endHour || '';

  switch (type) {
    case 'Dia completo':
      return i18n.t('complete_day');
    case 'Morning':
      return `${i18n.t('morning_schedule', { endHour: endHour })}`;
    case 'Tarde':
      return `${i18n.t('afternoon_schedule', { startHour: startHour })}`;
    case 'Personalizada':
      return `${i18n.t('custom_schedule')} (${startHour}-${endHour})`;
  }

  return '';
};

export const findFirstReservationOfType = (
  typeReservation: string,
  reservations: any[],
  startHour: string,
  endHour: string,
) => {
  if (isEmpty(reservations)) {
    return undefined;
  }

  if (!typeReservation) {
    return reservations[0];
  }

  return (
    reservations.find(
      w =>
        w?.reservationType === typeReservation &&
        w?.startHour === startHour &&
        w?.endHour === endHour,
    ) ?? reservations[0]
  );
};

export const getParkingRoute = (availableVehicles, planned, lastMinute) => {
  if (!availableVehicles) return '/parking/noAvailableVehicle';

  if (planned && lastMinute) return '/parking/patype';

  if (!planned && lastMinute) return '/parking/calendar';

  if (planned && !lastMinute) return '/parking/calendarPlanned';

  return '';
};
