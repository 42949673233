import { IonText } from '@ionic/react';
import styles from './styles.module.scss';
import { openHtmlLink } from '../../utils/functions';

type TProps = {
  text: string;
  link: string;
  textClassName?: string;
  anchorClassName?: string;
};

export const TextWithInterpolatedAnchor: React.FC<TProps> = ({
  text,
  link,
  textClassName = '',
  anchorClassName = '',
}) => {
  const tagMatch = text.match(/{{[^}]+}}/);

  if (!tagMatch) return <IonText className={textClassName}>{text}</IonText>;

  const { 0: wrapper } = tagMatch;
  const tag = wrapper.substring(2, wrapper.length - 2);

  const anchor = (
    <a
      className={`${styles.defaultLink} ${anchorClassName}`}
      onClick={() => {
        openHtmlLink(link);
      }}
    >
      {tag}
    </a>
  );
  const splittedText = text.split(wrapper);

  if (splittedText.length === 2) {
    return (
      <IonText className={textClassName}>
        {splittedText[0]}
        {anchor}
        {splittedText[1]}
      </IonText>
    );
  }
  return <IonText className={textClassName}>{text}</IonText>;
};
