export const weekdaysEn = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const weekdaysEs = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];

export const weekdaysPt = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const monthsEn = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthsEs = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const monthsPt = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const monthsShortEn = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthsShortEs = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export const monthsShortPt = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Oct',
  'Nov',
  'Dez',
];

export const TEMPORAL_VISITS_DISTRICT = '0';

export const VEHICLE_TYPES = {
  car: 1,
  motorcycle: 2,
  motoSilence: 3,
  microcar: 4,
};

export const PARKING_PERMISSIONS = {
  parking: 'parking',
  pooler_moto: 'pooler_moto',
  pooler_microcar: 'pooler_microcar',
};

export const ENVIRONMENTAL_BADGES = {
  ECO: 1,
  C: 2,
  B: 3,
  ZERO: 4,
  NO_LABEL: 5,
};

export const PLUGGABLE_VEHICLE_OPTION_ENABLED = true;

export const OFFLINE_SEDES = [
  {
    id: 1,
    description: 'Campus ACCIONA (Madrid)',
    campusTimeZone: 'Europe/Madrid',
  },
  {
    id: 2,
    description: 'Campus ACCIONA (Chicago)',
    campusTimeZone: 'America/Chicago',
  },
  {
    id: 3,
    description: 'Campus Acciona (Guadalajara)',
    campusTimeZone: 'Europe/Madrid',
  },
  {
    id: 67,
    description: 'Campus Acciona (São Paulo)',
    campusTimeZone: 'America/Sao_Paulo',
  },
];

export const FALLBACK_WELCOME_TEXT = {
  es: 'Bienvenido',
  en: 'Welcome',
  pt: 'Bem-vindo',
};

export const FALLBACK_DASHBOARD_IMAGE = {
  es: '/assets/images/bgXXL.jpg',
  en: '/assets/images/bgXXL.jpg',
  pt: '/assets/images/bgXXL.jpg',
};

export const POLICIES_SCHEDULES = [
  {
    id: 'Dia completo',
    value: 'complete_day',
    icon: 'recaderia',
  },
  {
    id: 'Morning',
    value: 'morning_schedule',
    icon: 'morning',
  },
  {
    id: 'Tarde',
    value: 'afternoon_schedule',
    icon: 'afternoon',
  },
  {
    id: 'Personalizada',
    value: 'custom_schedule',
    icon: 'hours',
  },
];

export const POLICIES_SCHEDULES_INDEXED_BY_DESCRIPTION = {
  'Dia completo': 'complete_day',
  'Morning': 'morning_schedule',
  'Tarde': 'afternoon_schedule',
  'Personalizada': 'custom_schedule',
};

export const POLICIES_SCHEDULES_INDEXED_BY_KEY = {
  complete_day: 'Dia completo',
  morning_schedule: 'Morning',
  afternoon_schedule: 'Tarde',
  custom_schedule: 'Personalizada',
};

export const USER_LOCKER_MODE = { DESK_LOCKER: '0', USER_LOCKER: '1' };

export const MORE_PERMISSIONS = [
  'howtoget',
  'services',
  'restaurants',
  'gym',
  'acciona_today',
  'ethical_channel',
  'recaderia',
  'medicalservices',
];

export const ALLOWED_IMAGE_FORMATS = ['png', 'jpg', 'jpeg'];

export const ALLOWED_FILE_FORMATS = [
  'pdf',
  'doc',
  'docx',
  'jpg',
  'png',
  'xls',
  'xlsx',
  'csv',
  'tar.gz',
  'rtf',
  'ods',
  'odt',
  'odp',
  'zip',
  'txt',
  'mp3',
  'msg',
  'eml',
  'pptx',
  'ppt',
  'tgz',
  'html',
  'mkv',
  'jpeg',
];

// TODO: refactor permissions
export const RESERVATION_PERMISSIONS = [
  'room',
  'desks',
  'reservation_restaturant',
  PARKING_PERMISSIONS.parking,
  PARKING_PERMISSIONS.pooler_moto,
  PARKING_PERMISSIONS.pooler_microcar,
];
