import { IonFabButton, IonIcon } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
  cssClass?: string;
};

export const LegendControl: React.FC<Props> = (props: Props) => {
  return (
    <IonFabButton
      id="legend-control"
      className={`${styles.fabBtnSm} ${styles.legendControl} ${
        props.cssClass ?? ''
      }`}
      color="primary"
      onClick={props.onClick}
    >
      <IonIcon className={`icon icon-info ${styles.legendIcon}`} />
    </IonFabButton>
  );
};
