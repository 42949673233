import { FilePicker, PickFilesResult } from '@robingenz/capacitor-file-picker';
import { ALLOWED_FILE_FORMATS } from '../../../utils/constants';

const addDocFromDocsDirectory = async (): Promise<PickFilesResult> => {
  const options = {
    types: ALLOWED_FILE_FORMATS.map(format => `.${format}`),
    multiple: true,
  };

  try {
    const files = await FilePicker.pickFiles(options);

    return files;
  } catch {
    return {
      files: [],
    };
  }
};

export const docServices = {
  addDocFromDocsDirectory,
};
