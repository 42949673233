import {
  IonContent,
  IonHeader,
  IonPage,
  IonLabel,
  IonRow,
  IonCol,
  IonBackButton,
} from '@ionic/react';
import styles from './styles.module.scss';
import { TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';

const CanceledDesk: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('desk_availability')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <IonRow className="ion-justify-content-center">
          <IonCol className={styles.center}>
            <IonLabel className={styles.title_2}>
              {t('sorry_desk_canceled')}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default CanceledDesk;
