import { Button, SearchBar, Snackbar, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { AuthStore } from '../../../../_redux/models/auth.model';
import { PassengersStore } from '../../../../_redux/models/passengers.model';
import { passengersActions } from '../../../../_redux/actions/passengers.actions';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { SpinnerFullSize } from '../../../../components/Base/SpinnerFullSize/SpinnerFullSize';
import { useDelayedRender } from '../../../../hooks/useDelayedRender';
import { includesQueryString, isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';

const Passengers: React.FC = () => {
  const { delayed } = useDelayedRender(2000);
  const contentPage = useRef<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myUserId = useSelector((state: AuthStore) => state.auth.user.owner);
  const passengersData = useSelector(
    (state: PassengersStore) => state.passengers.availablePassengers,
  );
  const totalDefaultPassengers = useSelector(
    (state: PassengersStore) => state.passengers.defaultPassengers.length,
  );
  const [allPassengers, setAllPassengers] = useState([]);
  const [companionName, setCompanionName] = useState('');
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [selectedCompanions, setSelectedCompanions] = useState([]);
  const maxCapPassengers = 4;
  const maxPassengers = maxCapPassengers - totalDefaultPassengers;
  const totalPassengers = totalDefaultPassengers + selectedCompanions.length;

  useEffect(() => {
    dispatch(passengersActions.getAvailablePassengers());
  }, []);

  useEffect(() => {
    if (passengersData && !isEmpty(passengersData)) {
      setAllPassengers(passengersData);
    }
  }, [passengersData]);

  useEffect(() => {
    if (companionName.length < 3) {
      setFilteredSearch([]);
      return;
    }
    const matchesQuery = (passenger, query) => {
      return (
        includesQueryString(passenger.fullName, query) &&
        passenger.defaultCompanion !== true &&
        passenger.employeeId !== myUserId
      );
    };
    setFilteredSearch(
      passengersData.filter(passenger =>
        matchesQuery(passenger, companionName),
      ),
    );
  }, [companionName]);

  const handleSelectCompanion = companion => {
    const isDuplicated = selectedCompanions.some(
      selectedCompanion =>
        selectedCompanion.employeeId === companion.employeeId,
    );

    if (!isDuplicated && selectedCompanions.length < maxPassengers) {
      setSelectedCompanions(prev => [...prev, companion]);
      setFilteredSearch(prev =>
        prev.filter(
          prevCompanion => prevCompanion.employeeId !== companion.employeeId,
        ),
      );
      const selectedCompanionsCopy = _.cloneDeep(selectedCompanions);
      const reversedCompanions = selectedCompanionsCopy.reverse();
      dispatch(passengersActions.setPassengers(reversedCompanions));
      contentPage.current?.scrollToTop();
    }
  };

  const deletePassenger = companion => {
    setSelectedCompanions(prev =>
      prev.filter(
        prevSelectedCompanion =>
          prevSelectedCompanion.employeeId !== companion.employeeId,
      ),
    );
    setCompanionName('');
  };

  useEffect(() => {
    dispatch(passengersActions.setPassengers(selectedCompanions));
  }, [selectedCompanions]);

  const handleAddPassengers = companions => {
    dispatch(passengersActions.addDefaultPassengers(companions));
  };

  const handleSearchChange = e => {
    setCompanionName(e.detail.value!);
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        className={`ion-no-border  ${styles.backgroundLight} ${styles.toolbarGrid}`}
      >
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/user/vehicles-passengers"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('add_passengers')}
        />
        {!delayed && (
          <>
            <div className={`${styles.gridWeb}`}>
              <div className={styles.listGrid}>
                <SearchBar
                  autoCapitalize="sentences"
                  onIonInput={handleSearchChange}
                  className={styles.searchBar}
                  cancelButtonText="cancel-button-text"
                  placeholder={t('plholder_search_passengers')}
                  disabled={isEmpty(allPassengers)}
                  debounce={800}
                />
              </div>
            </div>
            <Snackbar
              icon={
                totalPassengers === maxCapPassengers
                  ? 'icon icon-incident'
                  : 'icon icon-info'
              }
              type={totalPassengers === maxCapPassengers ? 'warning' : 'info'}
              text={
                totalPassengers === maxCapPassengers
                  ? t('lbl_max_passengers_warning')
                  : t('lbl_maximun_passengers')
              }
              align="left"
            />
          </>
        )}
      </IonHeader>
      <IonContent ref={contentPage}>
        {delayed ? (
          <SpinnerFullSize />
        ) : (
          <>
            <div className={styles.gridWeb}>
              <div className={styles.listGrid}>
                <>
                  {!isEmpty(selectedCompanions) && (
                    <DetailListItem
                      className={`${styles.headerList} ${styles.itemTitle}`}
                      lines="full"
                      title={t('selected_passengers')}
                      titleColor="primary"
                    ></DetailListItem>
                  )}
                  {!isEmpty(selectedCompanions) &&
                    selectedCompanions.map(companion => {
                      return (
                        <DetailListItem
                          className={styles.listVehicles}
                          key={companion.employeeId}
                          mode="ios"
                          lines="inset"
                          type="button"
                          startIcon="icon icon-client"
                          footnote={companion.function}
                          startIconColor="primary"
                          titleLight={companion.fullName}
                          titleColor="primary"
                          descriptionColor="dark"
                          endIconAction="icon icon-delete"
                          actionEndIcon={() => deletePassenger(companion)}
                          endIconColor="primary"
                        />
                      );
                    })}
                </>
                <>
                  {!isEmpty(selectedCompanions) && companionName.length > 0 && (
                    <DetailListItem
                      className={`${styles.headerList} ${styles.itemTitle}`}
                      lines="full"
                      title={t('results')}
                      titleColor="primary"
                    />
                  )}
                  {companionName.length > 2 &&
                    filteredSearch.map(companion => {
                      return (
                        <DetailListItem
                          className={
                            selectedCompanions.length >= maxPassengers
                              ? styles.colorList
                              : ''
                          }
                          key={companion.employeeId}
                          mode="ios"
                          lines="inset"
                          type="button"
                          onClick={() => handleSelectCompanion(companion)}
                          startIcon="icon icon-client"
                          startIconColor="primary"
                          titleLight={companion.fullName}
                          titleColor="primary"
                          footnote={companion.function}
                          descriptionColor="dark"
                        ></DetailListItem>
                      );
                    })}
                </>
              </div>
            </div>
          </>
        )}
      </IonContent>
      {!isEmpty(selectedCompanions) && (
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              <Button onClick={() => handleAddPassengers(selectedCompanions)}>
                {t('add_passengers')}
              </Button>
            </div>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Passengers;
