import { IonPage, IonRouterOutlet } from '@ionic/react';
import CanRoute from '../../../components/CanRoute/CanRoute';
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import HowToGet from './pages/HowToGet/HowToGet';
import MedicalServices from './pages/MedicalServices/MedicalServices';
import Restaurants from './pages/Restaurants/Restaurants';

const ServicesRouter: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <PrivateRoute path="/services/howtoget">
          <CanRoute functionality="howtoget">
            <HowToGet />
          </CanRoute>
        </PrivateRoute>
        <PrivateRoute path="/services/restaurants">
          <CanRoute functionality="restaurants">
            <Restaurants />
          </CanRoute>
        </PrivateRoute>
        <PrivateRoute path="/services/medicalservices">
          <CanRoute functionality="medicalservices">
            <MedicalServices />
          </CanRoute>
        </PrivateRoute>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ServicesRouter;
