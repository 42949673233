import { Button, Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonPage,
  IonRow,
} from '@ionic/react';

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewVehicleModal } from './NewVehicleModal/NewVehicleModal';
import { VehicleStore } from '../../../_redux/models/vehicles.model';
import { history } from '../../../_helpers/history';
import { PassengersStore } from '../../../_redux/models/passengers.model';
import { passengersActions } from '../../../_redux/actions/passengers.actions';
import { isEmpty } from '../../../utils/functions';
import { reservationActions } from '../../../_redux/actions';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { ENVIRONMENTAL_BADGES } from '../../../utils/constants';
import { getVehicleIcon } from '../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';

const VehiclesPassengers: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicles = useSelector((state: VehicleStore) => state.vehicle.vehicles);
  const passengersData = useSelector(
    (state: PassengersStore) => state.passengers.defaultPassengers,
  );
  const [defaultPassengers, setDefaultPassengers] = useState([]);

  useEffect(() => {
    dispatch(passengersActions.getDefaultPassengers());
  }, []);

  useEffect(() => {
    if (passengersData) {
      setDefaultPassengers(passengersData);
    }
  }, [passengersData]);

  const msgStateVehicle = useSelector(
    (store: VehicleStore) => store.vehicle.msg,
  );
  const msgStatePassengers = useSelector(
    (store: PassengersStore) => store.passengers.msg,
  );

  const [showModalAddVehicle, setShowModalAddVehicle] = useState(false);
  const handleShowModalAdd = () => {
    setShowModalAddVehicle(true);
  };

  const handleCloseModal = () => {
    setShowModalAddVehicle(false);
  };

  const handleGoEditPage = item => {
    history.push({
      pathname: '/profile/user/vehicles-passengers/vehicle-detail',
      state: { item },
    });
  };

  const handleDismissToast = () => {
    dispatch(reservationActions.resetMsgReservation());
  };

  const handleDeletePassenger = item => {
    dispatch(passengersActions.deleteDefaultPassengers(item));
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/user"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_personal_vehicle_passengers')}
        />
      </IonHeader>
      <IonContent>
        <NewVehicleModal
          showModal={showModalAddVehicle}
          onClose={() => setShowModalAddVehicle(false)}
          onCloseWithSave={() => handleCloseModal()}
        />
        <div className={`${styles.gridWeb} ${styles.contentAlign}`}>
          {isEmpty(vehicles) ? (
            <>
              <IonGrid className={styles.contentSty}>
                <IonRow className="ion-justify-content-center">
                  <IonCol className={styles.center}>
                    <IonLabel className={styles.title_2}>
                      {t('not_available_vehicle')}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </>
          ) : (
            <div className={styles.listGrid}>
              <DetailListItem
                lines="full"
                title={t('lbl_user_vehicles')}
                className={styles.headerList}
              />
              {vehicles.map((item, index, arr) => {
                return (
                  <DetailListItem
                    key={index}
                    mode="ios"
                    lines={index + 1 < arr.length ? 'inset' : 'none'}
                    onClick={() => handleGoEditPage(item)}
                    type="button"
                    className={styles.listVehicles}
                    detail={true}
                    startIcon={getVehicleIcon(item.type)}
                    titleLight={`${item.brand} ${item.model} `}
                    titleColor="primary"
                    startChip={
                      item.environmentalBadge === ENVIRONMENTAL_BADGES.ECO && {
                        type: 'success',
                        text: 'ECO',
                      }
                    }
                    middleIcon={
                      item.favourite && `icon icon-star-filled  ${styles.star}`
                    }
                    footnote={`${item.plate} | ${item.color}`}
                    endIcon="icon icon-chevron-right"
                    endIconColor="primary"
                  ></DetailListItem>
                );
              })}
              <IonRow className={styles.space}></IonRow>

              <DetailListItem
                className={styles.headerList}
                lines="full"
                title={t('lbl_user_vehicles_passangers')}
              />
              <DetailListItem
                className={styles.listVehicles}
                mode="ios"
                lines="inset"
                type="button"
                routerLink="/profile/user/vehicles-passengers/passengers-detail"
                startIcon="icon icon-occupation"
                titleLight={t('lbl_add_companions')}
                footnote={t('lbl_add_companions_points')}
                endIconAction="icon icon-chevron-right"
              />
              {defaultPassengers.map((item, index) => {
                return (
                  <DetailListItem
                    key={index}
                    mode="ios"
                    lines="inset"
                    type="button"
                    startIcon="icon icon-client"
                    // PENDING BACK: username + firtsSurname from Habitat services ->
                    // initials={(item.name.charAt(0) + item.firstSurname.charAt(0)).toUpperCase()}
                    titleLight={item.fullName}
                    footnote={item.function}
                    endIconAction="icon icon-delete"
                    actionEndIcon={() => handleDeletePassenger(item)}
                  />
                );
              })}
            </div>
          )}
        </div>
        {msgStateVehicle && (
          <Toast
            isOpen={!!msgStateVehicle}
            message={msgStateVehicle.description}
            onDidDismiss={handleDismissToast}
            position="bottom"
            type={msgStateVehicle.type}
          />
        )}
        {msgStatePassengers && (
          <Toast
            isOpen={!!msgStatePassengers}
            message={msgStatePassengers.description}
            onDidDismiss={handleDismissToast}
            position="bottom"
            type={msgStatePassengers.type}
          />
        )}
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleShowModalAdd}>{t('lbl_add_vehicle')}</Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default VehiclesPassengers;
