import {
  IonContent,
  IonHeader,
  IonPage,
  IonButton,
  IonFooter,
  IonBackButton,
} from '@ionic/react';
import { Snackbar, Toast, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import {
  WaitingList,
  WorkroomStore,
} from '../../../../_redux/models/workroom.model';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';

const WorkroomWaitingDetail: React.FC = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const waitingListWr: WaitingList = useSelector(
    (state: WorkroomStore) => state.workroom.waitingListWr,
  );
  const { reservations, msg } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const { isFromConfirmation, handleClose } = useReservationsHistory();

  useEffect(() => {
    msg && setShowToast(true);
  }, [msg, reservations]);

  const handlePlaceFavorite = e => {
    setChecked(e.detail.checked);
  };

  const handleDismissToast = () => {
    setShowToast(false);
  };

  return (
    <IonPage>
      <IonHeader className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            isFromConfirmation ? (
              <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
            ) : (
              <IonBackButton
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            )
          }
          title={t('aplication_detail')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <Snackbar
          type="info"
          text={t('msg_info_snackbar_waitingList', {
            hours: dayjs(waitingListWr.startDate)
              .hour(12)
              .diff(
                dayjs(waitingListWr.notificationLimitDate).hour(12),
                'hours',
              ),
          })}
          icon="icon icon-info"
          align="left"
        />
        <DetailCard
          onClickFavorite={e => handlePlaceFavorite(e)}
          favorite={checked}
          subName=""
          type="workroom"
          icon="icon icon-room"
          waiting
        ></DetailCard>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-booking"
                startIconColor="primary"
                title={dayjs(waitingListWr.startDate)
                  .tz()
                  .format('ddd DD MMM YYYY')}
                description={`${dayjs(waitingListWr.startDate)
                  .tz()
                  .format('HH:mm')} - ${dayjs(waitingListWr.endDate)
                  .tz()
                  .format('HH:mm')}`}
                descriptionColor="primary"
              />

              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-occupation"
                startIconColor="primary"
                title={t('lbl_capacity')}
                description={t('lbl_min_members', {
                  qty: waitingListWr.capacity,
                })}
                descriptionColor="primary"
              />

              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-room"
                startIconColor="primary"
                title={t('type_workroom')}
                description={waitingListWr.spaceType}
                descriptionColor="primary"
              />
            </DetailList>
          </div>
        </div>
      </IonContent>
      <IonFooter></IonFooter>
      {msg && (
        <Toast
          isOpen={showToast}
          onDidDismiss={() => handleDismissToast()}
          message={msg.description}
          position="bottom"
          type={msg.type}
        ></Toast>
      )}
    </IonPage>
  );
};

export default WorkroomWaitingDetail;
