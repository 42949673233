import { Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import CanteenCalendar from './CanteenCalendar';

const Canteen = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/canteen">
          <CanteenCalendar />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Canteen;
