import {
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewsStore } from '../../../../_redux/models/news.model';
import PlayImage from '../../../../assets/images/play-button.svg';
import styles from './styles.module.scss';
import dayjs from 'dayjs';
import {
  ProgressBar,
  Modal,
  SocialToolBar,
  SocialToolBarItem,
  TopBar,
  Toast,
  Button,
} from '@acciona/ui-ionic-kit';
import { useContext, useEffect, useRef, useState } from 'react';
import { NewsSecondaryCard } from '../../../../components/Base/NewsSecondaryCard';
import { NewsActions } from '../../../../_redux/actions';
import Comments from '../Comments/Comments';
import { backToDetail, isEmpty } from '../../../../utils/functions';
import useDataNews from '../../../../hooks/useDataNews';
import { useHistory, useLocation } from 'react-router';
import { NewsImg } from '../../../../components/Base/NewsImg';
import _ from 'lodash';
import { useNewsCaptureLinksOnClick } from '../../../../hooks/useNewsCaptureLinksOnClick';
import CategoryChip from '../components/CategoryChip';
import VideoPlayer from '../../../../components/Base/VideoPlayer';
import { useVideoPlayer } from '../../../../hooks/useVideoPlayer';
import FirebaseAnalyticsContext from '../../../../context/FirebaseAnalyticsContext';

const Detail: React.FC = () => {
  const { setProperty } = useContext(FirebaseAnalyticsContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { news } = useSelector((store: NewsStore) => store.news);
  const newslistOther = useSelector(
    (store: NewsStore) => store.news.newslistOther,
  );
  const socialStats = useSelector((store: NewsStore) => store.news.socialStats);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const contentPage = useRef<any>();
  const contentNews = useRef<any>();
  const [social, setSocial] = useState<any>();
  const history = useHistory();
  const { showVideo, playVideo, closeVideo, isLoading, playError } =
    useVideoPlayer(videoUrl);

  const location = useLocation().state as any;
  const playOnLoad = location?.playVideo ?? null;
  const backUrl = location?.backUrl ?? null;
  const idOriginNews = location?.origin_uuid ?? null;
  const pathOrigin = location?.pathOrigin ?? null;

  const calculateProgressBar = async position => {
    const totalContentHeight =
      contentNews.current && contentNews.current.offsetHeight;
    const viewportHeight =
      contentPage.current && contentPage.current.offsetHeight;
    const percentage = position / (totalContentHeight - viewportHeight);
    setScrollPosition(position == 0 ? 0 : percentage);
  };
  const { isLoadingDetail, error } = useSelector(
    (store: NewsStore) => store.news,
  );
  const newsCard = useDataNews(newslistOther);

  const handleSelected = async (id, origin) => {
    dispatch(
      NewsActions.selectNews(id, history.location.pathname, false, origin),
    );
    contentPage.current && contentPage.current.scrollToTop(800);
  };

  useNewsCaptureLinksOnClick('news');

  const detailNewsBack = () => {
    backToDetail(idOriginNews, backUrl, dispatch, pathOrigin);
  };

  useEffect(() => {
    setProperty('language_app_config', localStorage.getItem('i18nextLng'));

    return () => {
      dispatch(NewsActions.setNews(null, []));
    };
  }, []);

  useEffect(() => {
    if (news) {
      playOnLoad && playVideo();
      socialStats && setSocial(socialStats.find(s => s.idNews === news.id));
    }
  }, [news, socialStats]);

  useEffect(() => {
    if (news?.extended_props?.videoUrl) {
      setVideoUrl(news.extended_props.videoUrl);
    }
  }, [news?.extended_props?.videoUrl]);

  useEffect(() => {
    if (playError) {
      setErrorMessage(playError);
    }
    if (error) {
      setErrorMessage(error);
    }
  }, [error, playError]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <div className={styles.headerIcon}>
              <Button
                onClick={detailNewsBack}
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            </div>
          }
          title={t('tab_news')}
        />
        <ProgressBar color="secondary" value={scrollPosition}></ProgressBar>
      </IonHeader>
      <IonContent
        ref={contentPage}
        fullscreen
        scrollEvents
        onIonScroll={ev => {
          calculateProgressBar(ev.detail.scrollTop);
        }}
      >
        {news && _.has(news, 'id') ? (
          <IonGrid ref={contentNews} className={styles.noPadding}>
            {!isEmpty(news.image_id) && (
              <div className={styles.centerImgButton}>
                {!isEmpty(news.extended_props.videoUrl) && (
                  <button className={styles.videoButton} onClick={playVideo}>
                    <img src={PlayImage} alt="" />
                  </button>
                )}
                <NewsImg
                  image_id={news.image_id}
                  className={styles.imgHeader}
                />

                {news.extended_props.enableOverlay ||
                !isEmpty(news.extended_props.videoUrl) ? (
                  <div className={styles.imgOverlay} />
                ) : null}
                {!isEmpty(news.category) && (
                  <div className={styles.chipSty}>
                    <CategoryChip
                      category_id={news.extended_props?.category_id}
                      category={news.category}
                    />
                  </div>
                )}
                <div className={styles.responsiveContentHeader}>
                  <IonRow>
                    <IonText className={styles.largeTitleLight}>
                      {news.title}
                    </IonText>
                  </IonRow>
                  <IonRow>
                    <IonText className={styles.subhead}>
                      {isEmpty(news.author) ? '' : `${news.author} | `}
                      {dayjs(news.publishing_date).format('DD/MM/YYYY')}
                    </IonText>
                  </IonRow>
                  <div className={styles.responsiveSocialToolbar}>
                    <div>
                      {/* <SocialToolBarItem
                        className={styles.responsiveItem}
                        icon={`icon ${
                          news.socialStats.bookmarks
                            ? 'icon-save-filled'
                            : 'icon-save-line'
                        }`}
                        onClick={() => console.log('guardar')}
                      /> */}
                    </div>
                    <div>
                      {social && !_.isEmpty(social) && (
                        <>
                          <SocialToolBarItem
                            className={styles.responsiveItem}
                            icon={`icon ${
                              social.like
                                ? 'icon-like-filled'
                                : 'icon-like-line'
                            }`}
                            onClick={() =>
                              dispatch(NewsActions.likeNews(news.id))
                            }
                            text={social.likes.toString()}
                          />
                          <SocialToolBarItem
                            className={styles.responsiveItem}
                            icon={'icon icon-comment'}
                            text={social.comments.toString()}
                            onClick={() => setShowComments(true)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.contentGridContainer}>
              <div
                className={`${styles.responsiveContentDetail} ${styles.gridWeb}`}
              >
                <div className={styles.responsiveContentLeft}>
                  <div className={styles.responsiveContentImg}>
                    <div className={styles.responsiveChip}>
                      {!isEmpty(news.category) && (
                        <CategoryChip
                          category_id={news.extended_props?.category_id}
                          category={news.category}
                        />
                      )}

                      {/* TODO: waiting UX validation to remove */}
                      {/* <Chip type="info" className="icon icon-play-XXS" /> */}
                    </div>

                    <IonRow>
                      <IonText className={styles.title_1}>{news.title}</IonText>
                    </IonRow>
                    <IonRow>
                      <IonText className={styles.subhead}>
                        {isEmpty(news.author) ? '' : `${news.author} | `}
                        {dayjs(news.publishing_date).format('DD/MM/YYYY')}
                      </IonText>
                    </IonRow>
                    {social && !_.isEmpty(social) && (
                      <div className={styles.responsiveSocialToolbar}>
                        <div>
                          <SocialToolBarItem
                            className={styles.responsiveItem}
                            icon={`icon ${
                              social.bookmarks
                                ? 'icon-save-filled'
                                : 'icon-save-line'
                            }`}
                            onClick={() => console.log('guardar')}
                          />
                        </div>
                        <div>
                          <SocialToolBarItem
                            className={styles.responsiveItem}
                            icon={`icon ${
                              social.like
                                ? 'icon-like-filled'
                                : 'icon-like-line'
                            }`}
                            onClick={() =>
                              dispatch(NewsActions.likeNews(news.id))
                            }
                            text={social.likes.toString()}
                          />
                          <SocialToolBarItem
                            className={styles.responsiveItem}
                            icon={'icon icon-comment'}
                            text={social.comments.toString()}
                            onClick={() => setShowComments(true)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {news.summary && (
                    <IonRow>
                      <IonText className={styles.title_2}>
                        <IonLabel>{news.summary}</IonLabel>
                      </IonText>
                    </IonRow>
                  )}
                  <IonRow>
                    <div
                      className={styles.bodyContainer}
                      dangerouslySetInnerHTML={{ __html: news.body }}
                    ></div>
                  </IonRow>
                </div>
              </div>
              <div className={styles.separatorNews} />

              <div
                className={`${styles.responsiveContentDetail} ${styles.gridWeb}`}
              >
                {newsCard && !isEmpty(newsCard) ? (
                  <div className={styles.responsiveContentRight}>
                    <div className={styles.headline}>{t('lbl_other_news')}</div>
                    <div>
                      {newsCard.map(data => {
                        return (
                          <div key={data.id} className={styles.colAlignCenter}>
                            <NewsSecondaryCard
                              data={data}
                              onClick={() =>
                                handleSelected(data.nav_id, news.nav_id)
                              }
                              mode="ios"
                              displaySocial={false}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </IonGrid>
        ) : (
          <div className="ion-text-center">
            <IonSpinner name="dots"></IonSpinner>
          </div>
        )}
        <div className={styles.isWeb}>
          <VideoPlayer
            videoUrl={videoUrl}
            onClose={closeVideo}
            isOpen={showVideo}
            isLoading={isLoading}
          />
        </div>
        <Modal
          isOpen={showComments}
          onDidDismiss={() => setShowComments(false)}
        >
          <Comments setShowComments={setShowComments} />
        </Modal>
        <Toast
          isOpen={!!errorMessage}
          message={errorMessage}
          position="bottom"
          type="error"
        />
        <IonLoading
          isOpen={isLoadingDetail}
          message={t('msg_loading')}
          duration={5000}
        />
      </IonContent>
      <IonFooter className={styles.responsiveFooter}>
        {!_.isEmpty(social) && (
          <SocialToolBar>
            <SocialToolBarItem
              icon={`icon ${
                social.like ? 'icon-like-filled' : 'icon-like-line'
              }`}
              onClick={() => dispatch(NewsActions.likeNews(news.id))}
              text={social.likes.toString()}
            />
            <SocialToolBarItem
              icon={'icon icon-comment'}
              text={social.comments.toString()}
              onClick={() => setShowComments(true)}
            />
            {/* <SocialToolBarItem icon={'icon icon-share-1'} />
          <SocialToolBarItem
            icon={`icon ${
              news.socialStats.bookmarks ? 'icon-save-filled' : 'icon-save-line'
            }`}
            onClick={() => console.log('guardar')}
          />
          <SocialToolBarItem icon={'icon icon-ellipsis-vertical'} /> */}
          </SocialToolBar>
        )}
      </IonFooter>
    </IonPage>
  );
};

export default Detail;
