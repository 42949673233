import { useEffect, useMemo, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonFooter,
  IonTitle,
  IonCol,
  IonLabel,
  IonTextarea,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Props } from './types';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { NewsStore } from '../../../../_redux/models/news.model';
import { isEmpty } from '../../../../utils/functions';
import { Button, Icon, Item, List } from '@acciona/ui-ionic-kit';
import { useDispatch } from 'react-redux';
import { NewsActions } from '../../../../_redux/actions';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useFirebaseAnalytics } from '../../../../hooks/useFirebaseAnalytics';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

declare module 'dayjs' {
  interface Dayjs {
    fromNow(withoutSuffix?: boolean): string;
  }
}

const Comments: React.FC<Props> = props => {
  const logToFirebase = useFirebaseAnalytics();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState('');
  const [replyText, setReplyText] = useState('');
  const allComments = useSelector(
    (store: NewsStore) => store.news.news.comment,
  );
  const { nav_id, title } = useSelector((store: NewsStore) => store.news.news);
  const [replylabel, setReplyLabel] = useState('lbl_write_comment');
  const [replyingComment, setReplyingComment] = useState(false);
  const [statusResponsesArray, updateStatusResponseArray] = useState([]);
  const [uniqueId, setUniqueId] = useState('');

  useEffect(() => {
    logToFirebase('/dashboard/news/comments');
  }, []);

  const handleCloseComents = () => {
    props.setShowComments(false);
  };

  const handleNewComment = () => {
    if (!isEmpty(commentText)) {
      dispatch(NewsActions.createNewComment(nav_id, commentText, title));
      setCommentText('');
    }
  };

  const handleReplyComment = uniqueId => {
    setUniqueId(uniqueId);
    setCommentText('');
    setReplyLabel('reply_message_coment');
    setReplyingComment(true);
  };

  const replyComment = () => {
    if (!isEmpty(replyText)) {
      dispatch(
        NewsActions.createReplyComment(nav_id, replyText, uniqueId, title),
      );
      setReplyText('');
      setCommentText('');
      setReplyLabel('lbl_write_comment');
      setReplyingComment(false);
    }
  };

  const initialComments = useMemo(() => {
    const data = allComments
      .filter(comment => isEmpty(comment.parent_content_id))
      .reverse();
    const array = [];
    data.forEach(comment => {
      array.push({ id: comment.unique_id, value: false });
      updateStatusResponseArray(array);
    });
    return data;
  }, [allComments]);

  const replyComments = useMemo(
    () =>
      allComments
        .filter(comment => !isEmpty(comment.parent_content_id))
        .reverse(),
    [allComments],
  );

  const getFirstComment = comment => {
    return _.first(
      replyComments.filter(e => e.parent_content_id === comment.unique_id),
    );
  };

  const updateStatusArray = comment => {
    const data = [...statusResponsesArray];
    data.forEach(c => {
      if (c.id === comment.unique_id) {
        c.value = !c.value;
      }
    });
    updateStatusResponseArray(data);
  };

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <Button fill="clear" onClick={handleCloseComents}>
            <IonIcon className="icon icon-close"> </IonIcon>
            <IonTitle className={styles.title}>{`${t('lbl_comments')} ${
              !isEmpty(allComments) ? '(' + allComments.length + ')' : ' '
            }`}</IonTitle>
          </Button>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <List>
            {!isEmpty(allComments) &&
              initialComments.map(comment => {
                return (
                  <Item
                    key={comment.unique_id}
                    mode="ios"
                    lines="inset"
                    className={styles.groupComments}
                  >
                    <IonCol className={styles.newsCols}>
                      <div className={styles.line}>
                        <div>
                          <Icon
                            className={`icon icon-client ${styles.avatar}`}
                          />
                        </div>
                        <div>
                          {`${comment?.author?.name}`}{' '}
                          <span className={styles.pipe}>|</span>{' '}
                          {`${dayjs(comment.published_date).fromNow()}`}
                        </div>
                      </div>
                      <IonLabel className="ion-text-wrap">
                        {comment.body}
                      </IonLabel>
                      <IonLabel
                        onClick={() => handleReplyComment(comment.unique_id)}
                        className={styles.replyLabel}
                      >
                        <p className={styles.reply}>{t('lbl_response')}</p>
                      </IonLabel>
                      <div className={styles.commentContainer}>
                        {!_.isEmpty(getFirstComment(comment)) && (
                          <Item
                            color="ligth"
                            lines="none"
                            className={styles.itemContainer}
                          >
                            <div>
                              <div className={styles.line}>
                                <div>
                                  <Icon
                                    className={`icon icon-client ${styles.avatar}`}
                                  />
                                </div>
                                <div>
                                  {`${getFirstComment(comment).author.name}`}{' '}
                                  <span className={styles.pipe}>|</span>{' '}
                                  {`${dayjs(
                                    getFirstComment(comment).published_date,
                                  ).fromNow()}`}
                                </div>
                              </div>
                              <IonLabel
                                className={`ion-text-wrap ${styles.responseLabel}`}
                              >
                                {getFirstComment(comment).body}
                              </IonLabel>
                              {replyComments.filter(
                                e => e.parent_content_id === comment.unique_id,
                              ).length > 1 && (
                                <div
                                  className={`${styles.line} ${styles.replyResponses}`}
                                  onClick={() => updateStatusArray(comment)}
                                >
                                  {statusResponsesArray.length &&
                                  !statusResponsesArray?.find(
                                    e => e.id === comment.unique_id,
                                  ).value
                                    ? t('lbl_show_all_reply')
                                    : t('lbl_hide_all_reply')}{' '}
                                  (
                                  {replyComments.filter(
                                    e =>
                                      e.parent_content_id === comment.unique_id,
                                  ).length - 1}
                                  )
                                </div>
                              )}
                            </div>
                          </Item>
                        )}
                      </div>
                      {statusResponsesArray?.find(
                        e => e.id === comment.unique_id,
                      )?.value &&
                        replyComments.map(ele => {
                          if (
                            ele.parent_content_id === comment.unique_id &&
                            ele.unique_id !== getFirstComment(comment).unique_id
                          ) {
                            return (
                              <div
                                className={styles.commentContainer}
                                key={ele.unique_id}
                              >
                                <Item
                                  color="ligth"
                                  mode="ios"
                                  lines="none"
                                  className={styles.itemContainer}
                                >
                                  <div>
                                    <div className={styles.line}>
                                      <div>
                                        <Icon
                                          className={`icon icon-client ${styles.avatar}`}
                                        />
                                      </div>
                                      <div>
                                        {`${ele.author.name} `}{' '}
                                        <span className={styles.pipe}>|</span>{' '}
                                        {`${dayjs(
                                          ele.published_date,
                                        ).fromNow()}`}
                                      </div>
                                    </div>
                                    <IonLabel
                                      className={`ion-text-wrap ${styles.responseLabel}`}
                                    >
                                      {ele.body}
                                    </IonLabel>
                                  </div>
                                </Item>
                              </div>
                            );
                          }
                        })}
                    </IonCol>
                  </Item>
                );
              })}
          </List>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.footerContainer}>
          <IonTextarea
            autoCapitalize="sentences"
            autocapitalize="sentences"
            className={styles.textArea}
            rows={3}
            cols={20}
            placeholder={t(replylabel)}
            onIonInput={
              replyingComment
                ? e => setReplyText(e.detail.value!)
                : e => setCommentText(e.detail.value!)
            }
            value={replyingComment ? replyText : commentText}
          />
          <div className={styles.btnArea}>
            <Button
              className={styles.btnSend}
              color="tertiary"
              onClick={replyingComment ? replyComment : handleNewComment}
            >
              <Icon className={`icon icon-send ${styles.send}`}></Icon>
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Comments;
