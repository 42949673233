import { Icon, Item } from '@acciona/ui-ionic-kit';
import { IonNote, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Props } from './types';
import styles from './styles.module.scss';
import { groupDatesByMonth } from '../../utils/dateTime';
import { useSelector } from 'react-redux';
import { AppStore } from '../../_redux/models/app.model';
import Accordion from '../Base/Accordion/Accordion';

export const ReleasedDays: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  const notReleasedAnyDay = t(`not_released_any_day_${props.type}`);

  const oneToFiveDaysReleased = groupDatesByMonth(
    props.releasedDays,
    localLanguage,
    false,
  );

  const moreThanFiveDaysReleased = t('number_of_days_released', {
    number: props.releasedDays.length,
  });

  const getReleasedDaysString = (numberOfReleasedDays: number) => {
    if (numberOfReleasedDays === 0) {
      return notReleasedAnyDay;
    } else if (numberOfReleasedDays < 6) {
      return oneToFiveDaysReleased;
    } else {
      return moreThanFiveDaysReleased;
    }
  };

  const releasedDaysString = getReleasedDaysString(props.releasedDays.length);

  return (
    <Item lines="none" className={`${styles.item}`}>
      <Icon
        slot="start"
        color={'primary'}
        className={`icon icon-booking ${styles.startIcon}`}
      />

      <div className={`ion-text-wrap ${styles.columnList}`}>
        <div className={styles.header}>
          <IonText
            className={`${styles.headline} ion-text-wrap ${styles.block}`}
          >
            {t('released_days')}
          </IonText>
          {props.canModify && (
            <IonNote
              slot="end"
              className={`${styles.subhead} ${styles.color_secondary} ${styles.marginNote}`}
            >
              {props.modifyDisabled ? (
                <p className={styles.blockedButton}>{t('lbl_modify')}</p>
              ) : (
                <p className={styles.activeButton} onClick={props.onClick}>
                  {t('lbl_modify')}
                </p>
              )}
            </IonNote>
          )}
        </div>
        <Accordion
          className={styles.accordion}
          legend={
            <IonText
              className={`ion-text-wrap ${styles.subhead} ${styles.block} ${styles.color_dark}`}
            >
              {releasedDaysString}
            </IonText>
          }
          content={
            props.children && (
              <IonText
                className={`${styles.description} ion-text-wrap ${styles.subhead} ${styles.block} ${styles.color_dark}`}
              >
                {props.children}
              </IonText>
            )
          }
        />

        {props.canModify && (
          <IonText
            className={`${styles.footnote} ion-text-wrap ${styles.block} ${styles.color_primary}`}
          >
            {t(`not_forget_release_${props.type}`)}
          </IonText>
        )}
      </div>
    </Item>
  );
};
