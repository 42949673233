import {
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { RadioList } from '../../../../components/Base/RadioList';
import { Modal } from '@acciona/ui-ionic-kit';
import { Props } from './types';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';

const FloorModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [value, setValue] = useState<string>(null);
  const [floors, setFloors] = useState<any>(null);

  useEffect(() => {
    props.floors &&
      setData(
        props.floors.map(fl => {
          return { name: fl.name, value: fl.id.toString() };
        }),
      );
    props.floors && setFloors(props.floors);
  }, [props.floors]);

  useEffect(() => {
    props.value && setValue(props.value);
  }, [props.value]);

  const handleSelected = floorId => {
    setValue(floorId.toString());
    props.setSelectedFloor(floors.find(f => f.id == floorId));
    props.setOpenModal(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={() => props.setOpenModal(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButton fill="clear" onClick={() => props.setOpenModal(false)}>
            <IonIcon className="icon icon-chevron-left" />
            <IonTitle className={styles.title}>{t('floor_workroom')}</IonTitle>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <RadioList
          options={data}
          selectedOption={value}
          onChange={handleSelected}
        />
      </IonContent>
      <IonFooter></IonFooter>
    </Modal>
  );
};
export default FloorModal;
