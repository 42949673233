import _ from 'lodash';

export const groupDetailByTypes = (detail: any[]) => {
  const groupThisReservationUseTypesByScheduleType = ['Desk'];
  const detailGrouped = [];
  const detailNotPWS =
    detail.filter(
      d => !groupThisReservationUseTypesByScheduleType.includes(d.useType),
    ) || [];

  detail.filter(d => d.useType == 'Desk') &&
    detail
      .filter(d => d.useType == 'Desk')
      .map(d => {
        const datesForGroup = d.dates.map(e => {
          return {
            ...e,
            groupByAll: `${e?.reservationType}-${e?.startHour}-${e?.endHour}`,
          };
        });
        const groupType = _.groupBy(datesForGroup, 'groupByAll');

        detailGrouped.push(
          _.map(groupType, reser => {
            return {
              ...d,
              dates: reser,
            };
          }),
        );
      });

  return _.flattenDeep(_.union(detailNotPWS, detailGrouped));
};
