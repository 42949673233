import muleSoftInstance from '../../../../_redux/utils/httpCommon';
import { MenuConfig } from './models';

enum EMoreEndPoints {
  GET_CONFIG_MENU = '/v1/others/menu',
}

const getMoreMenuConfig = async (): Promise<MenuConfig> => {
  const path = `${process.env.REACT_APP_API_HABITAT}${EMoreEndPoints.GET_CONFIG_MENU}`;
  const res = (await muleSoftInstance.get(path)).data;
  return res;
};

export const moreServices = { getMoreMenuConfig };
