import { MapControlsProps as Props } from './types';
import { IonFab } from '@ionic/react';
import { Control } from '../../Base/Control';

import {
  DistrictFocusControl,
  ZoomInControl,
  ZoomOutControl,
  FullscreenControl,
  LegendControl,
} from '../../MapControls';

export const MapSelectorControls: React.FC<Props> = (props: Props) => {
  return (
    <IonFab>
      <Control position="bottomleft">
        <LegendControl onClick={props.onClickLegend} />
      </Control>
      {!props.districtFocusHidden && (
        <Control position="bottomright">
          <DistrictFocusControl
            onClick={props.onDistrictFocus}
            disabled={props.districtFocusDisabled}
            center={props.center}
          />
        </Control>
      )}
      <Control position="bottomright">
        <FullscreenControl />
      </Control>
      <Control position="bottomright">
        <ZoomInControl maxZoom={props.maxZoom} />
      </Control>
      <Control position="bottomright">
        <ZoomOutControl minZoom={props.minZoom} />
      </Control>
    </IonFab>
  );
};
