import { useState } from 'react';
import { TopBar } from '@acciona/ui-ionic-kit';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import CookiesModal from '../../../components/CookiesModal';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [showCookiesModal, setShowCookiesModal] = useState(false);

  const data = [
    {
      id: 1,
      titleLight: t('lbl_language'),
      routerLink: '/profile/settings/language',
    },
    {
      id: 2,
      titleLight: t('lbl_my_head_office'),
      routerLink: '/profile/settings/head-office',
    },
  ];

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_profile_settings')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList className={styles.bgWhitesmoke}>
              {data.map(({ id, ...item }) => {
                return (
                  <DetailListItem
                    {...item}
                    className={styles.profileList}
                    key={id}
                    mode="ios"
                    lines="inset"
                    endIcon="icon icon-chevron-right"
                  />
                );
              })}
              <DetailListItem
                className={styles.profileList}
                mode="ios"
                lines="inset"
                endIcon="icon icon-chevron-right"
                titleLight={t('lbl_configure_cookies')}
                onClick={() => setShowCookiesModal(true)}
              />
            </DetailList>
          </div>
        </div>
        <CookiesModal
          isOpen={showCookiesModal}
          onClose={() => setShowCookiesModal(false)}
          hasCloseButton
        />
      </IonContent>
    </IonPage>
  );
};

export default Settings;
