import i18n from '../../i18nextConf';

export const mandatoryCookies = [
  {
    name: 'nlbi_*',
    provider: 'Microsoft',
    purposeEn:
      'Incapsula DDoS Protection and Web Application Firewall: Load balancing cookie. To ensure requests by a client are sent to the same origin server.',
    purposeEs:
      'Incapsula DDoS Protection y Web Application Firewall: Cookie de equilibrio de carga. Para garantizar que las solicitudes de un cliente se envíen al mismo servidor de origen.',
    expiration: `2 ${i18n.t('lbl_years')}`,
    type: 'First Party',
  },
  {
    name: 'incap_ses_*',
    provider: 'Microsoft',
    purposeEn:
      'This cookie is set to allow a visitor to receive site content from one out of multiple servers as the visitor browses the site. This allows the visitor’s session to be maintained',
    purposeEs:
      'Esta cookie está configurada para permitir que un visitante reciba contenido del sitio desde uno de varios servidores mientras el visitante navega por el sitio. Esto permite mantener la sesión del visitante',
    expiration: i18n.t('lbl_end_session'),
    type: 'First Party',
  },
  {
    name: 'visid_incap_*',
    provider: 'Microsoft',
    purposeEn:
      'This cookie is from the incapsula CDN and helps us with reliability, security and the performance of our site.',
    purposeEs:
      'Esta cookie es de incapsula CDN y nos ayuda con la confiabilidad, la seguridad y el rendimiento de nuestro sitio.',
    expiration: `1 ${i18n.t('lbl_years')}`,
    type: 'First Party',
  },
];
export const analyticCookies = [
  {
    name: '_ga',
    provider: 'Google',
    purposeEn: 'It is used to distinguish users.',
    purposeEs: 'Se usa para distinguir a los usuarios.',
    expiration: `2 ${i18n.t('lbl_years')}`,
    type: 'HTML',
  },
  {
    name: '_ga_',
    provider: 'Google',
    purposeEn: 'It is used to maintain session state.',
    purposeEs: 'Se usa para manener el estado de la sesión.',
    expiration: `2 ${i18n.t('lbl_years')}`,
    type: 'HTML',
  },
];
