import { useState } from 'react';
import { Button, Item, Modal, Snackbar } from '@acciona/ui-ionic-kit';
import { Props } from './types';
import styles from './styles.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty } from './../../utils/functions';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from '@ionic/react';
import { analyticCookies, mandatoryCookies } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from './../../_redux/actions';
import { UserStore } from './../../_redux/models/user.model';

const CookiesModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('i18nextLng');
  const dispatch = useDispatch();
  const [showConfigInfo, setConfigInfo] = useState(false);
  const [showMandatoyCookies, setShowMandatoryCookies] = useState(false);
  const [showAnalyticCookies, setShowAnalyticCookies] = useState(false);
  const [analyticCookiesPref, setAnalyticCookiesPref] = useState<string>();
  const legalUrl = useSelector((store: UserStore) => store.user.policies);

  const showAnalyticTable = () => {
    showAnalyticCookies
      ? setShowAnalyticCookies(false)
      : setShowAnalyticCookies(true);
  };
  const showMandatoryTable = () => {
    showMandatoyCookies
      ? setShowMandatoryCookies(false)
      : setShowMandatoryCookies(true);
  };

  const openConfigCookies = () => {
    setConfigInfo(true);
  };

  const setCookiesClient = (preferences: string) => {
    setAnalyticCookiesPref(preferences);
  };

  const handleCookiesRejection = () => {
    setCookiesClient('reject');
    saveCookiesClient(false);
  };

  const handleSavePreferences = () => {
    analyticCookiesPref === 'allow' && saveCookiesClient(true);
    analyticCookiesPref === 'reject' && saveCookiesClient(false);
  };

  const saveCookiesClient = (preferences: boolean) => {
    dispatch(appActions.loadCookiePreferences(preferences));
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      animated={false}
      backdropDismiss={props.backdropDismiss ?? false}
      cssClass={styles.modalSty}
      onDidDismiss={() => props.onClose()}
    >
      <IonContent>
        {!showConfigInfo ? (
          <>
            {props.hasCloseButton && (
              <IonButton fill="clear" onClick={() => props.onClose()}>
                <IonIcon className="icon icon-close" />
              </IonButton>
            )}
            <div className={styles.headCookies}>
              <div className={styles.cookieLogo}></div>
            </div>
            <div className={styles.legalCookies}>
              <h3>{t('lbl_cookies_title')}</h3>
              <p>
                <Trans>{t('lbl_cookies_legal_one')}</Trans>
                {legalUrl && (
                  <a
                    className={styles.defaultLink}
                    href={legalUrl[2]?.url}
                    target="_blank"
                  >
                    {t('lgl_cookie_policy')}.
                  </a>
                )}
              </p>
              <p>
                <Trans>{t('lbl_cookies_legal_two')}</Trans>
              </p>
            </div>
            <div className={styles.cookiesOpt}>
              <Button
                color="lighter"
                className={styles.secondaryCookiesBtn}
                onClick={openConfigCookies}
              >
                {t('btn_config')}
              </Button>
              <Button
                color="lighter"
                className={styles.secondaryCookiesBtn}
                onClick={handleCookiesRejection}
              >
                {t('btn_reject_cookies')}
              </Button>
              <Button
                color="primary"
                className={styles.cookiesBtn}
                onClick={() => saveCookiesClient(true)}
              >
                {t('btn_accept_all')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.headCookies}>
              <div className={styles.cookieLogo}></div>
            </div>
            <div className={styles.legalCookies}>
              <p>
                <b>{t('title_cookies_control_title')}</b>
                <br></br>
                {t('lbl_cookies_control_description')}
              </p>
              <p>
                <b>{t('title_cookies_needed')}</b>
                <br></br>
                {t('lbl_cookies_needed')}
              </p>
              <div className={styles.cookiesRow} onClick={showMandatoryTable}>
                <p className={styles.showMoreCookies}>
                  {t('msg_cookies_show_more')}
                </p>
                <span
                  className={
                    showMandatoyCookies
                      ? 'icon icon-chevron-up'
                      : 'icon icon-chevron-down'
                  }
                ></span>
              </div>
              <hr className={styles.cookiesSeparator}></hr>
              {showMandatoyCookies && (
                <IonGrid className={styles.noPadding}>
                  <IonRow>
                    <IonCol>{t('lbl_cookies_name')}</IonCol>
                    <IonCol>{t('lbl_cookies_provider')}</IonCol>
                    <IonCol>{t('lbl_cookies_purpuse')}</IonCol>
                    <IonCol>{t('lbl_cookies_expiration')}</IonCol>
                    <IonCol>{t('lbl_cookies_type')}</IonCol>
                  </IonRow>
                  <hr className={styles.cookiesTableSeparator}></hr>
                  {mandatoryCookies.map((e, index) => {
                    return (
                      <IonRow key={index}>
                        <IonCol>{e.name}</IonCol>
                        <IonCol>{e.provider}</IonCol>
                        <IonCol>
                          {lang == 'en' ? e.purposeEn : e.purposeEs}
                        </IonCol>
                        <IonCol>{e.expiration}</IonCol>
                        <IonCol>{e.type}</IonCol>
                      </IonRow>
                    );
                  })}
                </IonGrid>
              )}
              <div className={styles.radioCookies}>
                <div>
                  <p>
                    <b>{t('title_analytic_cookies')}</b>
                    <br></br>
                    {t('lbl_analytic_cookies')}
                  </p>
                </div>
                <div className={styles.radioCookiesGroup}>
                  <IonRadioGroup
                    onIonChange={e => setAnalyticCookiesPref(e.detail.value)}
                    value={analyticCookiesPref}
                  >
                    <div className={styles.radioCookies}>
                      <div>
                        <Item lines="none">
                          <IonRadio mode="md" value="allow"></IonRadio>
                          <span>{t('btn_allow')}</span>
                        </Item>
                      </div>
                      <div>
                        <Item lines="none">
                          <IonRadio mode="md" value="reject"></IonRadio>
                          <span>{t('btn_reject')}</span>
                        </Item>
                      </div>
                    </div>
                  </IonRadioGroup>
                </div>
              </div>

              <div className={styles.cookiesRow} onClick={showAnalyticTable}>
                <p className={styles.showMoreCookies}>
                  {t('msg_cookies_show_more')}
                </p>
                <span
                  className={
                    showAnalyticCookies
                      ? 'icon icon-chevron-up'
                      : 'icon icon-chevron-down'
                  }
                ></span>
              </div>
              <hr className={styles.cookiesSeparator}></hr>
              {showAnalyticCookies && (
                <IonGrid className={styles.noPadding}>
                  <IonRow>
                    <IonCol>{t('lbl_cookies_name')}</IonCol>
                    <IonCol>{t('lbl_cookies_provider')}</IonCol>
                    <IonCol>{t('lbl_cookies_purpuse')}</IonCol>
                    <IonCol>{t('lbl_cookies_expiration')}</IonCol>
                    <IonCol>{t('lbl_cookies_type')}</IonCol>
                  </IonRow>
                  <hr className={styles.cookiesTableSeparator}></hr>
                  {analyticCookies.map((e, index) => {
                    return (
                      <IonRow key={index}>
                        <IonCol>{e.name}</IonCol>
                        <IonCol>{e.provider}</IonCol>
                        <IonCol>
                          {lang == 'en' ? e.purposeEn : e.purposeEs}
                        </IonCol>
                        <IonCol>{e.expiration}</IonCol>
                        <IonCol>{e.type}</IonCol>
                      </IonRow>
                    );
                  })}
                </IonGrid>
              )}
              {analyticCookiesPref === 'allow' && (
                <div className={styles.snackbarSuccess}>
                  <Snackbar
                    type="success"
                    text={t('msg_agree_cookies')}
                    icon="icon icon-check"
                  />
                </div>
              )}
              {analyticCookiesPref === 'reject' && (
                <div className={styles.snackbarWarning}>
                  <Snackbar
                    type="warning"
                    text={t('msg_reject_cookies')}
                    icon="icon icon-incident"
                  />
                </div>
              )}
            </div>
            <div className={styles.cookiesConfigOpt}>
              <div>
                <Button
                  color="lighter"
                  className={styles.cookiesBtnSmall}
                  onClick={() => setCookiesClient('allow')}
                >
                  {t('btn_allow_all')}
                </Button>
                <Button
                  color="lighter"
                  className={styles.cookiesBtnSmall}
                  onClick={() => setCookiesClient('reject')}
                >
                  {t('btn_reject_all')}
                </Button>
              </div>
              <Button
                disabled={isEmpty(analyticCookiesPref) && true}
                color="primary"
                className={styles.cookiesBtn}
                onClick={handleSavePreferences}
              >
                {t('btn_save_cookies_config')}
              </Button>
            </div>
          </>
        )}
      </IonContent>
    </Modal>
  );
};

export default CookiesModal;
