import { IonItem } from '@ionic/react';
import _ from 'lodash';
import { List } from '@acciona/ui-ionic-kit';
import { OtherPolicies } from '../../../_redux/models/user.model';
import Can from '../../../components/Can/Can';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { isEmpty } from '../../../utils/functions';
import styles from './styles.module.scss';

type Props = {
  data: OtherPolicies[];
  onClick: (
    document: { url: string; external: boolean; name: string },
    section: string,
  ) => void;
};

const getCategories = (data: OtherPolicies[]) => {
  const categoriesWithDocuments = data.filter(
    category => !_.isEmpty(category.documents.filter(doc => !doc?.disabled)),
  );

  if (isEmpty(categoriesWithDocuments)) {
    return [];
  }

  const categoriesWithOrdinal = categoriesWithDocuments.map(category => ({
    ...category,
    ordinal: category?.ordinal ?? category.categoryId,
  }));

  return _.orderBy(categoriesWithOrdinal, 'ordinal');
};

export const OtherDocuments = ({ data, onClick }: Props) => {
  const categories = getCategories(data);

  return (
    <div className={styles.gridWeb}>
      {categories.map(category => {
        return (
          <div key={category.categoryId} className={styles.listGrid}>
            <IonItem className={styles.titlePolicy} lines="inset">
              {category.categoryName}
            </IonItem>
            <List className={styles.bgWhitesmoke}>
              {category.documents
                .filter(doc => !doc?.disabled)
                .map(document => (
                  <Can key={document.id} functionality="public">
                    <DetailListItem
                      onClick={() => onClick(document, category.categoryName)}
                      className={styles.profileList}
                      mode="ios"
                      lines="inset"
                      startIcon={
                        document.external ? 'icon icon-extern' : 'icon icon-pdf'
                      }
                      titleLight={document.name}
                    />
                  </Can>
                ))}
            </List>
          </div>
        );
      })}
    </div>
  );
};
