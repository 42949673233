/* External dependencies */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { FilterChip, Snackbar, Toast } from '@acciona/ui-ionic-kit';
/* Redux */
import {
  ReservationList,
  ReservationStore,
} from '../../../_redux/models/reservation.model';
import { UserStore } from '../../../_redux/models/user.model';
import { AppStore, Visualization } from '../../../_redux/models/app.model';
import { appActions, reservationActions } from '../../../_redux/actions';
/* Types */
import { FilterType, filterTypes } from '../Home/types';
/* Components */
import ListReservations from './ListReservation/ListReservation';
import { CalendarAccordion } from './CalendarAccordion/CalendarAccordion';
import AccordionFilter from './AccordionFilter/AccordionFilter';
/* Helpers */
import { isEmpty } from '../../../utils/functions';
import { getDateParts } from '../../../utils/dateTime';
import {
  getReservationsOnly,
  getReservedDays,
  getSpacesList,
  updateSedesSelection,
  getRequestsOnly,
  getInitialSelectedSedes,
} from './helpers';
/* Styles */
import styles from './styles.module.scss';
import { RESERVATION_PERMISSIONS } from '../../../utils/constants';
import _ from 'lodash';
import { useLocationState } from '../../../hooks/useLocationState';

const viewOptions: Visualization[] = ['perDay', 'all'];

const Reservation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useLocationState() as any;
  const { viewReservations, sedeFilterReservations, localLanguage } =
    useSelector((state: AppStore) => state.app.localSettings);

  const { defaultSede, sedesList, permissionAllHeadOffices } = useSelector(
    (store: UserStore) => store.user.user,
  );

  const sedesWithReservationPermission = _.chain(permissionAllHeadOffices)
    .filter(
      item =>
        !_.isEmpty(_.intersection(item.permissions, RESERVATION_PERMISSIONS)),
    )
    .map('id')
    .value();

  const { offlineServices, offlineNetwork } = useSelector(
    (state: AppStore) => state.app,
  );

  const {
    homeOfficeDays,
    isLoading,
    msg,
    reservations,
    permanentParkings,
    permanentDesks,
    error,
    lastUpdate,
    reservationsServiceIsOffline,
  } = useSelector((store: ReservationStore) => store.reservation);

  const offlineMode =
    offlineServices || offlineNetwork || reservationsServiceIsOffline;

  const [viewMode, setViewMode] = useState<Visualization>(
    viewReservations ?? t('per_day'),
  );
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedChipFilter, setSelectedChipFilter] = useState('all');
  const [spaces, setSpaces] = useState<ReservationList[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [filterChips, setFilterChips] = useState<FilterType[]>(
    filterTypes.slice(0, 3),
  );
  const [minDate] = useState<Date>(new Date());
  const [reservedDays, setReservedDays] = useState<string[]>([]);
  const [selectedSedes, setSelectedSedes] = useState<string[]>(
    getInitialSelectedSedes(sedeFilterReservations, sedesList),
  );
  const [sedePermission, setSedePermission] = useState<boolean>(false);

  useIonViewWillEnter(() => {
    dispatch(reservationActions.getReservationsAndPermanentSpaces(true));
  });

  useIonViewDidEnter(() => {
    if (state !== undefined && state.filterSpace !== undefined) {
      dispatch(appActions.setViewReservations('all'));
      handleChipFilterChange({ id: state.filterSpace });
    }

    if (offlineMode) {
      dispatch(appActions.setViewReservations('all'));
    }
  });

  useEffect(() => {
    offlineMode && dispatch(appActions.setViewReservations('all'));
  }, [offlineMode]);

  useEffect(() => {
    const spacesList = getSpacesList(
      sedesList,
      selectedDate,
      viewReservations,
      reservations,
      permanentParkings,
      permanentDesks,
      dispatch,
    );
    setSpaces(spacesList);
  }, [
    selectedDate,
    viewReservations,
    reservations,
    permanentParkings,
    permanentDesks,
    dispatch,
  ]);

  const filteredReservations = useMemo(
    () => getReservationsOnly(spaces, selectedChipFilter, selectedSedes),
    [spaces, selectedChipFilter, selectedSedes],
  );

  const filteredRequests = useMemo(
    () => getRequestsOnly(spaces, selectedChipFilter, selectedSedes),
    [spaces, selectedChipFilter, selectedSedes],
  );

  useEffect(() => {
    setReservedDays(getReservedDays(reservations, selectedSedes, sedesList));

    setSedePermission(
      selectedSedes.some(item =>
        sedesWithReservationPermission.map(e => e.toString()).includes(item),
      ),
    );
  }, [reservations, selectedSedes]);

  useEffect(() => {
    if (msg !== null) setShowToast(true);
  }, [msg]);

  const handleChipFilterChange = (data: FilterType) => {
    const { id } = data;
    setSelectedChipFilter(id);
    setFilterChips(prev =>
      prev.map(chip => ({
        ...chip,
        checked: chip.id === id,
      })),
    );
  };
  const handleDaySelected = (date: Date) => {
    setSelectedDate(date);
  };

  const dataLegend = [
    { type: 'reserved', text: t('tab_reservations') },
    { type: 'homeOffice', text: t('lbl_homeOffice') },
  ];

  useEffect(() => {
    const minDate = dayjs().tz().utc().startOf('day');
    const maxDate = dayjs().tz().utc().add(1, 'year').endOf('year'); // December 31 of next year
    dispatch(
      reservationActions.getReservedDaysHomeOffice(
        minDate.toISOString(),
        maxDate.toISOString(),
      ),
    );
  }, []);

  const handleSelectedReservation = reserve => {
    if (offlineMode) {
      dispatch(appActions.setOfflineToastIsOpen(true));
      return;
    }

    switch (reserve.useType) {
      case 'Desk':
        dispatch(
          reservationActions.getReservationWorkstation(
            reserve.id,
            reserve.dates[0]?.reservationType,
            reserve.dates[0]?.startHour,
            reserve.dates[0]?.endHour,
          ),
        );
        break;
      case 'Room':
        dispatch(
          reservationActions.getReservationWorkroom(
            reserve.dates[0].reservationId,
          ),
        );
        break;
      case 'Canteen':
        dispatch(
          reservationActions.getReservationCanteen(
            reserve.dates[0].reservationId,
          ),
        );
        break;
      case 'Parking':
        dispatch(
          reservationActions.getReservationParking(
            reserve.id,
            reserve.dates[0]?.reservationType,
            reserve.dates[0]?.startHour,
            reserve.dates[0]?.endHour,
          ),
        );
        break;
      case 'Room-WaitingList':
        dispatch(reservationActions.getWaitingListWr(reserve.id));
        break;
      case 'Parking-Request':
        dispatch(reservationActions.getRequestParking(reserve.idSede));
        break;
      case 'Desk-Request':
        history.push('/spaces/workstation/requestdetail', {
          parmsRouter: { prevRouterConfirm: false },
        });
        break;
      case 'PermanentParking':
        dispatch(reservationActions.getPermanentParkingDetail(reserve));
        break;
      case 'PermanentDesk':
        dispatch(reservationActions.getPermanentDeskDetail(reserve));
        break;
      case 'Locker':
        dispatch(reservationActions.getReservationLocker(reserve.id));
        break;
      case 'PassengerInvitation':
        history.push('/parking/passengerInvitationDetail', {
          driverId: reserve.driverId,
        });
        break;
      case 'Passenger':
        history.push('/parking/passengerDetail', {
          driver: reserve.driverName,
          descriptionSede: reserve.descriptionSede,
          dates: reserve.dates,
        });
        break;
      default:
        break;
    }
  };

  const handleDismissToast = () => {
    setShowToast(null);
    dispatch(reservationActions.resetMsgReservation());
  };

  const userHasReservationsOrRequests =
    !isEmpty(filteredReservations) || !isEmpty(filteredRequests);

  const changeVisualizationHandler = (viewMode: Visualization) => {
    dispatch(appActions.setViewReservations(viewMode));
    setViewMode(viewMode);
  };

  const changeSedeHandler = (newSedeId: number) => {
    setSelectedSedes(prev => {
      const nextState = updateSedesSelection(prev, newSedeId);
      dispatch(appActions.setSedeFilterReservations(nextState));
      return nextState;
    });
  };

  const getLastUpdateText = useCallback(() => {
    if (!offlineServices && !offlineNetwork && !reservationsServiceIsOffline) {
      return '';
    }

    let offlineText = '';

    if (offlineNetwork) {
      offlineText = t('snackbar_offline_network_reservations');
    } else {
      offlineText = t('snackbar_offline_services_reservations');
    }

    let datesText = '';
    const dateParts = getDateParts(lastUpdate);

    if (dateParts) {
      datesText = `${t('last_update_reservation', {
        time: dateParts.time,
        day: dateParts.day,
        month: dateParts.month,
        year: dateParts.year,
      })}`;
    }

    return `${offlineText} ${datesText}`;
  }, [
    offlineNetwork,
    offlineServices,
    reservationsServiceIsOffline,
    lastUpdate,
  ]);

  const lastUpdateText = getLastUpdateText();
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className={styles.header}>
          <IonTitle>{t('tab_reservations')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className={styles.content}>
        {offlineMode && (
          <Snackbar
            type="error"
            text={lastUpdateText}
            icon="icon icon-close"
            align="left"
          />
        )}
        <IonHeader collapse="condense">
          <IonToolbar className={styles.toolbar}>
            <IonTitle size="large" className={styles.largeTitleBold}>
              {t('tab_reservations')}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <>
          {!offlineMode && (
            <div className={styles.headerItem}>
              <AccordionFilter
                selectedView={viewMode}
                selectedSedes={selectedSedes}
                onChangeViewType={changeVisualizationHandler}
                onChangeSede={changeSedeHandler}
                sedeOptions={sedesList}
                viewOptions={viewOptions}
                defaultSede={defaultSede}
              />
            </div>
          )}
          {viewReservations === 'perDay' && (
            <div className={styles.reservationsCalendarGrid}>
              <CalendarAccordion
                selectedDate={selectedDate}
                reservedDays={reservedDays}
                homeOfficeDays={homeOfficeDays}
                handleDaySelected={handleDaySelected}
                localLanguage={localLanguage}
                minDate={minDate}
                dataLegend={dataLegend}
              />
            </div>
          )}
          <div
            className={viewReservations === 'perDay' && styles.reservationLine}
          />

          {!sedePermission && !offlineMode && (
            <div className={`${styles.gridWeb} ${styles.contentAlign}`}>
              <div className={styles.center}>
                <div className={styles.center_subtext}>
                  <IonLabel className={'ion-text-center'}>
                    {t('no_headoffice_reservation_permission')}
                  </IonLabel>
                </div>
              </div>
            </div>
          )}

          {(sedePermission || offlineMode) && (
            <div className={`${styles.listReservations}`}>
              <IonItem className={styles.filters} lines="none">
                <div className={styles.gridscroll}>
                  {filterChips.map(item => (
                    <FilterChip
                      key={item.id}
                      id={item.id}
                      text={t(item.text)}
                      checked={item.checked}
                      onChange={handleChipFilterChange}
                    />
                  ))}
                </div>
              </IonItem>
              {userHasReservationsOrRequests ? (
                <>
                  <div
                    className={
                      viewReservations === 'perDay'
                        ? styles.separatorListBottom
                        : styles.separatorList
                    }
                  />
                  <ListReservations
                    type="reservation"
                    spacesState={filteredReservations}
                    selectedFilter={selectedChipFilter}
                    handleSelectedReservation={handleSelectedReservation}
                    visualization={viewReservations}
                  />
                  <ListReservations
                    type="request"
                    spacesState={filteredRequests}
                    selectedFilter={selectedChipFilter}
                    handleSelectedReservation={handleSelectedReservation}
                    visualization={viewReservations}
                  />
                </>
              ) : (
                <>
                  <IonRow className="ion-justify-content-center">
                    <IonCol className={styles.center}>
                      <IonLabel className={styles.title_2}>
                        {!isLoading &&
                          (error
                            ? t('msg_reservations_error')
                            : t('msg_no_reservations'))}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </div>
          )}
        </>

        {msg && (
          <Toast
            isOpen={showToast}
            onDidDismiss={() => handleDismissToast()}
            message={msg.description}
            position="bottom"
            type={msg.type}
          />
        )}
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Reservation;
