export const addMinutesAndRound = (
  time: string,
  minutes: number = 0,
  round: number = 15,
) => {
  let [h, m] = time.split(':').map(v => +v);

  m += minutes;
  m = Math.ceil(m / round) * round;

  while (m >= 60) {
    h += 1;
    m -= 60;
  }
  if (h >= 24) {
    return '23:45';
  }
  return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`;
};
