import { Locale } from '../../../_redux/models/app.model';
import muleSoftInstance from '../../../_redux/utils/httpCommon';
import { getSpaceTypes } from './data';

interface ISpace {
  id: number | string;
  type: string;
  description: string;
}

interface IDeparment {
  id?: number | string;
  abbr: string;
  description: string;
}

export interface IFloorData {
  spaces: ISpace[];
  departments: IDeparment[];
}

const requestBuildingFloorLegendData = async (
  building: { name: string; id: string },
  floor: { name: string; id: string },
  language: Locale,
): Promise<IDeparment[]> => {
  try {
    const url: string = `${process.env.REACT_APP_API_HABITAT}/v1/mapLegends`;
    const resp = await muleSoftInstance.get(url, {
      params: {
        buildingId: building.id,
        floorId: floor.id,
      },
    });
    const deparments = resp.data.map(d => {
      return {
        id: d.id,
        abbr: d.abbreviation,
        description: d.translations.find(t => t.language === language)
          ?.translation,
      };
    });
    return sortDepartments(deparments);
  } catch (err) {
    return [];
  }
};

const sortDepartments = (deparments: IDeparment[]): IDeparment[] => {
  return deparments.sort((a: IDeparment, b: IDeparment): number => {
    return a.abbr > b.abbr ? 1 : -1;
  });
};

export const getBuildingFloorLegendData = async (
  building: { name: string; id: string },
  floor: { name: string; id: string },
  language: Locale,
): Promise<IFloorData> => {
  const spaces = getSpaceTypes(language);
  const departments =
    building.id && floor.id
      ? await requestBuildingFloorLegendData(building, floor, language)
      : [];
  return { spaces, departments };
};
