import { Props } from './types';
import styles from './styles.module.scss';

export const FormatedText: React.FC<Props> = props => {
  const { className, content } = props;

  return (
    <div
      className={`${styles.content} ${className}`}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    ></div>
  );
};
