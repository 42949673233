import { useMap } from 'react-leaflet';
import { MapControlsProps as Props } from './types';
import { IonFab } from '@ionic/react';
import { Control } from '../../Base/Control';

import {
  DistrictFocusControl,
  ZoomInControl,
  ZoomOutControl,
  FullscreenControl,
} from '../../MapControls';

export const MapReservationControls: React.FC<Props> = (props: Props) => {
  const map = useMap();
  map.whenReady(() => props.center && map.setView(props.center, -1.25));

  return (
    <IonFab>
      <Control position="bottomright">
        <DistrictFocusControl center={props.center} />
      </Control>
      <Control position="bottomright">
        <FullscreenControl />
      </Control>
      <Control position="bottomright">
        <ZoomInControl maxZoom={props.maxZoom} />
      </Control>
      <Control position="bottomright">
        <ZoomOutControl minZoom={props.minZoom} />
      </Control>
    </IonFab>
  );
};
