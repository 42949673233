import { LatLngExpression } from 'leaflet';
import { IonSpinner } from '@ionic/react';
import { Props } from './types';
import { useMapConfig } from './../../hooks/useMapConfig';
import { MapWorkPlaceControls } from './MapWorkPlaceControls';
import { getCenter } from './functions';
import styles from './styles.module.scss';
import pointIcon from '../../assets/images/point.svg';
import { Map } from '@acciona/ui-ionic-kit';

export const MapWorkPlace: React.FC<Props> = props => {
  const mapImageUrl = props.mapImage.startsWith('data')
    ? props.mapImage
    : `data:image/jpeg;base64,${props.mapImage}`;

  const {
    isReady: mapIsReady,
    imageDimensions,
    mapContainerRef,
  } = useMapConfig(mapImageUrl);

  const iconPosition: [number, number] = [24, 40];

  const desks = props.points.map(point => ({
    y: imageDimensions.height - point.y,
    x: point.x,
    icon: {
      html: `<img src=${pointIcon} style="max-width: 100%!important" />`,
      className: styles.marker,
      width: 44,
      height: 44,
      position: iconPosition,
    },
  }));

  const center: LatLngExpression = getCenter(desks);

  const mapProps = {
    container: {
      tap: false,
      center: center,
      maxZoom: 0.5,
      minZoom: -3.5,
      zoomSnap: 0.25,
      zoomControl: false,
      transparent: false,
    },
    markers: props.hasPermanentDesk
      ? {
          points: desks,
        }
      : null,
    image: {
      width: imageDimensions.width,
      height: imageDimensions.height,
      url: mapImageUrl,
    },
    defaultControls: false,
  };

  return (
    <div
      id="map-detail-container"
      ref={mapContainerRef}
      className={`${styles.mapContainer} ${
        props.hasPermanentDesk && !props.hasDistrict
          ? styles.withPermanentDeskAndNoDistrict
          : props.hasPermanentDesk
          ? styles.withPermanentDesk
          : styles.withoutPermanentDesk
      }`}
    >
      {mapIsReady ? (
        <Map {...mapProps}>
          <MapWorkPlaceControls
            maxZoom={mapProps.container.maxZoom}
            minZoom={mapProps.container.minZoom}
            center={center}
          />
        </Map>
      ) : (
        <div className={`ion-text-center ${styles.spinnerContainer}`}>
          <IonSpinner />
        </div>
      )}
    </div>
  );
};
