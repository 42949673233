import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import List from './List/List';
import RoutesAndShuttle from './RoutesAndShuttle/RoutesAndShuttle';
import ShuttleInformation from './ShuttleInformation/ShuttleInformation';

const HowToGet: React.FC<any> = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/services/howtoget/list">
          <List />
        </Route>
        <Route exact path="/services/howtoget/routesandshuttle">
          <RoutesAndShuttle />
        </Route>
        <Route exact path="/services/howtoget/shuttleinfotmation">
          <ShuttleInformation />
        </Route>
        <Route exact path="/services/howtoget">
          <Redirect to="/services/howtoget/list" />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default HowToGet;
