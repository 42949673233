import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import translationEN from './assets/locales/en/translation.json';
import translationES from './assets/locales/es/translation.json';
import translationPT from './assets/locales/pt/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
};

const availableLanguages = ['en', 'es', 'pt'];

const options = {
  order: ['localStorage', 'querystring', 'navigator'],
};

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(detector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    detection: options,
    resources: resources,
    fallbackLng: 'pt', // fallback language is spanish.
    supportedLngs: ['es', 'en', 'pt'],
    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
