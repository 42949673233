import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FirebaseAnalyticsContext from '../../context/FirebaseAnalyticsContext';
import { appActions } from '../../_redux/actions';
import { AppStore } from '../../_redux/models/app.model';
import { AuthStore } from '../../_redux/models/auth.model';
import { UserStore } from '../../_redux/models/user.model';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const FirebaseAnalyticsContextProvider = props => {
  const [isFbLoaded, setIsFbLoaded] = useState(false);
  const dispatch = useDispatch();
  const gaCookiesAccepted = useSelector(
    (store: AppStore) => store.app.cookiePreferences.ga_,
  );
  const localLanguage = useSelector(
    (store: AppStore) => store.app.localSettings.localLanguage,
  );
  const defaultSede = useSelector(
    (store: UserStore) => store.user.user.defaultSede,
  );
  const { user } = useSelector((state: AuthStore) => state.auth);

  useEffect(() => {
    if (localStorage?.GaCookiesAccepted === 'true') {
      dispatch(appActions.setCookiePreferences({ ga_: true }));
    }
  }, []);

  useEffect(() => {
    const initializeUserSession = () => {
      FirebaseAnalytics.setUserId({
        userId: user.owner,
      }).then(() => {
        FirebaseAnalytics.logEvent({
          name: 'login',
          params: {
            init: 'accepted-cookies',
          },
        }).then(() => setIsFbLoaded(true));
      });
    };
    const initializeAnalyticsCookies = () => {
      if (isPlatform('capacitor')) {
        initializeUserSession();
      } else {
        FirebaseAnalytics.initializeFirebase(config).then(() => {
          initializeUserSession();
        });
      }
    };

    const enableAnalyticsCookies = () => {
      if (isFbLoaded) {
        FirebaseAnalytics.setCollectionEnabled({ enabled: true });
      } else {
        initializeAnalyticsCookies();
      }
    };

    const disableAnalyticsCookies = () => {
      if (isFbLoaded) {
        FirebaseAnalytics.setCollectionEnabled({ enabled: false });
      }
    };

    if (gaCookiesAccepted && user) {
      enableAnalyticsCookies();
    } else {
      disableAnalyticsCookies();
    }
  }, [gaCookiesAccepted, user]);

  /**
   * Platform: Web/Android/iOS
   * Sets the user ID property.
   * @param id
   */
  const setUser = async (id: string): Promise<any> => {
    //TODO: register user e.g. after login
    gaCookiesAccepted &&
      FirebaseAnalytics.setUserId({
        userId: id,
      });
  };

  /**
   * Platform: Web/Android/iOS
   * Sets a user property to a given value.
   * @param name
   * @param val
   */
  const setProperty = async (name: string, val: string): Promise<any> => {
    gaCookiesAccepted &&
      FirebaseAnalytics.setUserProperty({
        name: name,
        value: val,
      });
  };

  /**
   * Platform: Web/Android/iOS
   * Logs an app event.
   * @param name - name of the event to log
   * @param params - key/value pairs of properties (25 maximum per event)
   */
  const logEvent = (name: string, params: any) => {
    gaCookiesAccepted &&
      FirebaseAnalytics.logEvent({
        name: name,
        params: params,
      });
  };

  /**
   * Platform: Web/Android/iOS
   * Logs a screen view event.
   * @param screen - name of the screen
   */
  const logScreenView = (screen: string) => {
    if (isFbLoaded && gaCookiesAccepted) {
      isPlatform('capacitor')
        ? setScreen(screen)
        : logEvent('screen_view', {
            firebase_screen: screen,
          });
      setProperty('language_app_config', localLanguage);
      setProperty('app_config_sede', defaultSede?.description);
    }
  };

  /**
   * Platform: Android/iOS
   * Sets the current screen name, which specifies the current visual context in your app.
   * @param name - name of the current screen to track
   */
  const setScreen = (name: string) => {
    gaCookiesAccepted &&
      FirebaseAnalytics.setScreenName({
        screenName: name,
        nameOverride: `${name}Screen`,
      });
  };

  /**
   * Platform: Android/iOS
   * Retrieves the app instance id from the service.
   * @param none
   * @returns instanceId - individual instance id value
   */
  //   const getInstanceId = () => {
  //     return gaCookiesAccepted && FirebaseAnalytics.getAppInstanceId();
  //   };

  /**
   * Platform: Web/Android/iOS
   * Clears all analytics data for this app from the device and resets the app instance id.
   * @param none
   * @returns void
   */
  //   const resetAnalytics = () => {
  //     gaCookiesAccepted && FirebaseAnalytics.reset();
  //   };

  /**
   * Platform: Web/Android/iOS
   * Sets whether analytics collection is enabled for this app on this device.
   * @param name - enabled - boolean true/false
   * @returns void
   */
  //   const toggleAnalytics = (val: boolean) => {
  //     gaCookiesAccepted &&
  //       FirebaseAnalytics.setCollectionEnabled({
  //         enabled: val,
  //       });
  //   };

  /**
   * Platform: Web/Android/iOS
   * Sets the duration of inactivity that terminates the current session.
   * @param duration - duration in seconds (default - 18000)
   * @returns void
   */
  //   const setSessionTimeoutDuration = (sec: number) => {
  //     isLoaded && FirebaseAnalytics.setSessionTimeoutDuration({ duration: sec });
  //   };

  return (
    <FirebaseAnalyticsContext.Provider
      value={{
        isFbLoaded: isFbLoaded,
        setUser: setUser,
        setProperty: setProperty,
        logEvent: logEvent,
        setScreen: setScreen,
        logScreenView: logScreenView,
      }}
    >
      {props.children}
    </FirebaseAnalyticsContext.Provider>
  );
};
