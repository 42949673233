import { VehicleAction, VehicleState } from '../models/vehicles.model';
import { vehicleTypesEnum } from '../types/vehicle.types.enum';

const initialState: VehicleState = {
  error: null,
  isLoading: false,
  vehicle: null, // selected vehicle, initially favorite vehicle (if any) or default vehicle
  msg: null,
  vehicles: [],
  attributes: null,
  configuration: {
    fields: [],
    platesTypes: [],
    transportZoneText: { text: '', link: '' },
  },
};

export const vehicleReducer = (
  state: VehicleState = initialState,
  action: VehicleAction,
): VehicleState => {
  switch (action.type) {
    case vehicleTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case vehicleTypesEnum.SET_VEHICLE:
      return {
        ...state,
        vehicle: action.vehicle,
        isLoading: false,
      };
    case vehicleTypesEnum.MSG_VEHICLE:
      return {
        ...state,
        error: null,
        msg: action.msg,
        isLoading: false,
      };
    case vehicleTypesEnum.FAILURE_VEHICLE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case vehicleTypesEnum.RESET_MESSAGE:
      return {
        ...state,
        msg: null,
        isLoading: false,
      };
    case vehicleTypesEnum.SET_VEHICLES: {
      const favouriteVehicle = action.vehicles.find(v => v.favourite);
      const defaultVehicle = action.vehicles.find(v => v.defaultVehicle);

      return {
        ...state,
        vehicles: action.vehicles,
        isLoading: false,
        vehicle: favouriteVehicle ?? defaultVehicle,
      };
    }
    case vehicleTypesEnum.ADD_VEHICLE:
      return {
        ...state,
        vehicles: [...state.vehicles, action.vehicle],
        isLoading: false,
      };
    case vehicleTypesEnum.DELETE_VEHICLE:
      return {
        ...state,
        vehicles: state.vehicles.filter(e => {
          return action.vehicle.vehicleId !== e.vehicleId;
        }),
        isLoading: false,
      };
    case vehicleTypesEnum.VEHICLE_ATRIBUTES:
      return {
        ...state,
        attributes: action.attributes,
        isLoading: false,
      };
    case vehicleTypesEnum.SET_CONFIGURATION:
      return {
        ...state,
        configuration: action.configuration,
        isLoading: false,
      };
    case vehicleTypesEnum.RESET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
