import {
  RestaurantsAction,
  RestaurantsState,
} from '../models/restaurants.model';
import { restaurantsTypesEnum } from '../types/restaurants.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  restaurants: [],
};

export const restaurantsReducer = (
  state: RestaurantsState = initialState,
  action: RestaurantsAction,
): RestaurantsState => {
  switch (action.type) {
    case restaurantsTypesEnum.RESTAURANTS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case restaurantsTypesEnum.SET_RESTAURANTS:
      return {
        ...state,
        isLoading: false,
        error: null,
        restaurants: action.restaurants,
      };
    case restaurantsTypesEnum.RESTAURANTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
