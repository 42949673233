import { LatLngTuple } from 'leaflet';
import { Marker } from './types';
import styles from './styles.module.scss';

export const getCenter = (markers: Marker[]): LatLngTuple => {
  if (markers.length > 0) {
    const ys = markers.map(item => item.y);
    const xs = markers.map(item => item.x);
    const x = (Math.min(...xs) + Math.max(...xs)) / 2;
    const y = (Math.min(...ys) + Math.max(...ys)) / 2;
    return [y, x];
  } else {
    return null;
  }
};

export const getIconConfig = (ws: any) => {
  const classDefault = `icon-plus ${styles.pointerIcon}`;
  const classClicked = `icon-check ${styles.pointerIconClicked}`;
  const iconPosition: [number, number] = [16, 16];
  return {
    html: '',
    className: ws.selected ? classClicked : classDefault,
    width: 32,
    height: 32,
    position: iconPosition,
  };
};
