import { FC } from 'react';
import { Icon, Modal } from '@acciona/ui-ionic-kit';
import { VideoPlayerProps } from './types';
import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const VideoPlayer: FC<VideoPlayerProps> = ({
  videoUrl,
  onClose,
  isOpen,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal isOpen={isOpen} onDidDismiss={() => onClose()}>
        <div className={styles.iframeContain}>
          <div className={styles.iframeButton}>
            <Icon
              onClick={() => onClose()}
              className={`icon icon-close ${styles.color_dark}`}
            />
          </div>
          <iframe
            id="videoIframe"
            src={videoUrl}
            className={styles.iframeVideo}
            allowFullScreen
            frameBorder="no"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </Modal>
      <IonLoading isOpen={isLoading} message={t('msg_loading')} />
    </>
  );
};

export default VideoPlayer;
