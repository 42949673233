import { useEffect, useState } from 'react';
import { IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { Modal, Notification } from '@acciona/ui-ionic-kit';
import { NotifClass, Props } from './types';
import { useTranslation } from 'react-i18next';
import { Notification as NotificationModel } from '../../../../../_redux/models/notifications.model';
import _ from 'lodash';
import styles from './styles.module.scss';

const NotificationsModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible) {
      return;
    }
    const isVisibleTimeout = setTimeout(() => {
      props.setShowListNotifications(false);
    }, 1200);

    return () => clearTimeout(isVisibleTimeout);
  }, [isVisible]);

  return (
    <Modal
      isOpen={props.isOpen}
      cssClass={styles.modalNotif}
      animated={false}
      onDidDismiss={() => props.setShowListNotifications(false)}
    >
      <div className={styles.modalNotifContainer}>
        <div className={styles.containerCardNotif}>
          <div
            className={`${styles.contentCardNotif} ${
              !isVisible ? styles.closed : ''
            }`}
          >
            {_.orderBy(props.notifications, 'createData', 'desc').map(
              (not, index) => {
                const notification = not as NotificationModel;
                return (
                  <div key={index} className={styles.notifModalItem}>
                    <Notification
                      hidden={false}
                      text={not.title}
                      model={
                        props.styleNotification[
                          not.informationType
                        ] as NotifClass
                      }
                      icon={`icon icon-${not.icon}`}
                      onClick={() =>
                        props.handleSelectedNotification(notification)
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                );
              },
            )}
          </div>
        </div>
        {isVisible && (
          <div className={styles.modalFooter} style={{ paddingTop: '1%' }}>
            <IonGrid>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonLabel className={styles.labelClose} onClick={handleClose}>
                    {t('close_notifications_modal')}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NotificationsModal;
