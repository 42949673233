import muleSoftInstance from '../../utils/httpCommon';
import dayjs from 'dayjs';
import i18n from '../../../i18nextConf';

const fetchAvailableWorkrooms = async ({ ...searchFilter }): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/available`;

  if (searchFilter.spaceTypes)
    searchFilter.spaceTypes = searchFilter.spaceTypes.toString();

  if (searchFilter.buildings)
    searchFilter.buildings = searchFilter.buildings.toString();

  try {
    const resp = await muleSoftInstance.get(path, {
      params: searchFilter,
    });
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const reserveWorkroom = async (
  spaceId,
  date,
  startHour,
  endHour,
  hostUser,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservation`;
    const params = {
      spaceId: spaceId,
      date: dayjs(date)
        .startOf('day')
        .utcOffset(0, true)
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      startHour: startHour === '' ? '' : dayjs(startHour).tz().toISOString(),
      endHour: dayjs(endHour).subtract(1, 'seconds').tz().toISOString(),
      name: '', //Required by habitat
      description: '', //Required by habitat
      ...(hostUser && { hostUserId: hostUser }),
    };

    const resp = await muleSoftInstance.post(path, params);

    if (!resp.data) {
      throw new Error(i18n.t('error_default'));
    }

    resp.data.useType = 'Room';

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getListBuildings = async (): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/esite/habitat/v2/buildings`;
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getTypeRooms = async (): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/space/spaceType?useType=Room`;
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const checkinWorkroom = async (spaceIdQr, reservationId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservations/checkIn`;

    const resp = await muleSoftInstance.post(path, {
      reservationId: reservationId,
      spaceId: spaceIdQr,
    });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const checkinWorkroomQR = async (spaceIdQr, reservationId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservations/qr/checkIn`;

    const resp = await muleSoftInstance.post(path, {
      reservationId: reservationId,
      spaceId: spaceIdQr,
    });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const checkOutWorkroom = async (reservationId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservations/checkOut`;
    await muleSoftInstance.post(path, {
      reservationId: reservationId,
    });
    return true;
  } catch (error) {
    throw new Error(error.message);
  }
};

const joinWaitingList = async (
  waitingListFilters,
  notificationLimit: number,
): Promise<any> => {
  try {
    if (typeof waitingListFilters.spaceTypes === 'undefined') {
      waitingListFilters.spaceTypes = [];
    }
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/createRoomWaitingListRequest`;
    const resp = await muleSoftInstance.post(path, {
      building: waitingListFilters.buildingNames,
      capacity: waitingListFilters.capacity,
      startDate: dayjs(waitingListFilters.startHour).tz().toISOString(),
      endDate: dayjs(waitingListFilters.finalHour).tz().toISOString(),
      notificationLimit: notificationLimit,
      spaceType: waitingListFilters.spaceNames,
      spaces: waitingListFilters.spaceTypes,
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getAvailableHosts = async (): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/room/hosts`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getWorkroomDetailReservation = async (spaceId: number): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/room/qr/scan?spaceId=${spaceId}`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getWorkroomDetail = async (code: string): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/room/info/${code}`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getReservableWorkroomDetail = async (id: string): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservableRoomDetail/${id}`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const workroomServices = {
  fetchAvailableWorkrooms,
  reserveWorkroom,
  checkinWorkroom,
  checkOutWorkroom,
  getListBuildings,
  getTypeRooms,
  joinWaitingList,
  getAvailableHosts,
  getWorkroomDetailReservation,
  getWorkroomDetail,
  getReservableWorkroomDetail,
  checkinWorkroomQR,
};
