import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  IonPage,
  IonHeader,
  IonContent,
  IonFooter,
  IonItem,
  IonLoading,
  IonText,
  IonList,
  IonIcon,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  reservationActions,
  workstationActions,
} from '../../../../_redux/actions';
import {
  TopBar,
  Button,
  Toast,
  Snackbar,
  Icon,
  WeekCalendarMultiselector,
  DayCalendar,
} from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { WorkstationStore } from '../../../../_redux/models/workstation.model';
import styles from './styles.module.scss';
import { AppStore } from '../../../../_redux/models/app.model';
import {
  getNumberOfWeeks,
  isEmpty,
  openHtmlLink,
  showPolicies,
} from '../../../../utils/functions';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { BackButton } from '../../../../components/Base/BackButton';
import { Legend } from '../../../../components/Base/Legend';
import { LegendItem } from '../../../../components/Base/LegendItem';
import { UserStore } from '../../../../_redux/models/user.model';
import dayjs from 'dayjs';
import _ from 'lodash';
import SettingsModal from '../SettingsModal';
import { ReservationCard } from '../../../../components/Base/ReservationCard';
import {
  POLICIES_SCHEDULES,
  USER_LOCKER_MODE,
} from '../../../../utils/constants';
import {
  checkReservations,
  getIconSchedule,
  getTypeLabel,
} from '../../helpers';
import { settingsReservations } from '../../types';

const Calendar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datesSelected, setDates] = useState<string[]>([]);
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );
  const sedeList = useSelector((store: UserStore) => store.user.user.sedesList);

  const getuserLockerMode = idSede => {
    return (
      sedeList.find(e => e.id == idSede)?.lockerEmployeeKey ??
      USER_LOCKER_MODE.DESK_LOCKER
    );
  };
  const [minDate, setMinDate] = useState<Date>(
    new Date(dayjs(dayjs().tz().format('YYYY-MM-DD 00:00:00')).toISOString()),
  );
  const [maxDate, setMaxDate] = useState<Date>(
    new Date(dayjs(dayjs().tz().format('YYYY-MM-DD 23:59:00')).toISOString()),
  );
  const [showToastErrorWorkstation, setShowToastErrorWorkstation] =
    useState(false);
  const [msgErrorReservation, setMsgErrorReservation] = useState('');
  const [showToastHomeOffice, setShowToastHomeOffice] = useState(false);
  const [calendarIsDisabled, setCalendarIsDisabled] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const [nonSelectable, setNonSelectable] = useState<string[]>([]);
  const {
    error: errorWorkstation,
    isLoading: isLoadingWorkstation,
    reservableDays,
    configurations,
  } = useSelector((state: WorkstationStore) => state.workstation);
  const {
    user: { favouriteDeskPolicy, deskReservationConfiguration },
    reservationPolicies,
  } = useSelector((state: UserStore) => state.user);
  const { district, building, floor } = useSelector(
    (state: UserStore) => state.user.user,
  );
  const {
    homeOfficeDays: reservedHomeOfficeDays,
    reservations: reservationsByOwner,
    reservedWorkstationDays: reservedWsDays,
    isLoading: isLoadingReservation,
    error: errorReservation,
  } = useSelector((state: ReservationStore) => state.reservation);

  const {
    temporalVisitsDistrict,
    homeOfficeReservationAvailable,
    campusTimeZone,
  } = useSelector((state: AppStore) => state.app.globalSettings);

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [reservedDays, setReservedDay] = useState<string[]>([]);
  const [numWeeks, setNumWeeks] = useState<number>(1);
  const [settingsReservation, setSettingsReservation] =
    useState<settingsReservations>(null);
  const mounted = useRef(true);
  const documentReservation = useMemo(
    () =>
      reservationPolicies
        ? reservationPolicies.find(e => e.type === 'policyDesk')?.url
        : '',
    [reservationPolicies],
  );

  const handleLastMinuteSelectWs = () => {
    const typeReservation =
      POLICIES_SCHEDULES?.find(e => e.value == settingsReservation.schedule)
        ?.id || null;
    const configReservations =
      configurations?.policies?.find(e => typeReservation == e.nombre) || null;

    const hourStart =
      settingsReservation.schedule === 'custom_schedule'
        ? settingsReservation?.customStart
        : configReservations?.fullHoraMin ?? '00:00';
    const hourEnd =
      settingsReservation.schedule === 'custom_schedule'
        ? settingsReservation?.customEnd
        : configReservations?.fullHoraMax ?? '23:59';

    dispatch(
      workstationActions.getLastMinuteSelectWs(
        datesSelected.map(d => `${d}T00:00:00.000Z`),
        {
          isVisit: district === temporalVisitsDistrict,
          defaultBuilding: parseInt(building),
          defaultFloor: parseInt(floor),
        },
        typeReservation,
        hourStart,
        hourEnd,
        `${t('day_label', {
          number: datesSelected.length,
          multiple: datesSelected.length > 1 ? 's' : '',
        })} | ${getHeaderText} `,
        settingsReservation.config,
      ),
    );
  };

  const handleDismissToast = () => {
    setShowToastErrorWorkstation(false);
    setShowToastHomeOffice(false);
    dispatch(workstationActions.resetErrorWorkstation());
  };

  const handleDismissToastReservation = () => {
    setMsgErrorReservation('');
  };

  const handleSelectDate = dates => {
    if (
      !reservableDays.some(day => day === dayjs(dates).format('YYYY-MM-DD'))
    ) {
      return;
    }

    if (
      reservedHomeOfficeDays.includes(dayjs(dates).format('YYYY-MM-DD')) &&
      !nonSelectable.includes(dayjs(dates).format('YYYY-MM-DD'))
    ) {
      setShowToastHomeOffice(true);
      if (!homeOfficeReservationAvailable) return;

      setDates([...datesSelected, dayjs(dates).format('YYYY-MM-DD')]);
    }

    if (datesSelected.includes(dayjs(dates).format('YYYY-MM-DD'))) {
      setDates(
        datesSelected.filter(d => d != dayjs(dates).format('YYYY-MM-DD')),
      );
    } else if (!nonSelectable.includes(dayjs(dates).format('YYYY-MM-DD'))) {
      setDates([...datesSelected, dayjs(dates).format('YYYY-MM-DD')]);
    } else {
      setMsgErrorReservation(
        dayjs(dates).isToday()
          ? t('msg_error_incompatible_today')
          : t('msg_error_incompatible_schedule'),
      );
    }
  };

  const handleOnCloseOption = () => {
    setDates([]);
    setNonSelectable(
      checkReservations(
        settingsReservation,
        reservedWsDays,
        campusTimeZone,
        configurations,
      ),
    );
  };

  const dataLegend = [
    { type: 'desk', text: t('lbl_res_desk') },
    { type: 'homeOffice', text: t('lbl_homeOffice') },
  ];

  useEffect(() => {
    !showCalendar && setShowCalendar(true);

    dispatch(workstationActions.getReservableDays());
  }, []);

  useEffect(() => {
    errorWorkstation && setShowToastErrorWorkstation(true);
  }, [errorWorkstation]);

  useEffect(() => {
    if (errorReservation) {
      setMsgErrorReservation(errorReservation);
      setCalendarIsDisabled(true);
    }
    return () => {
      dispatch(reservationActions.resetErrorReservations());
    };
  }, [errorReservation]);

  useEffect(() => {
    if (
      reservedWsDays &&
      deskReservationConfiguration &&
      configurations &&
      settingsReservation
    ) {
      setNonSelectable(
        checkReservations(
          settingsReservation,
          reservedWsDays,
          campusTimeZone,
          configurations,
        ),
      );
      setReservedDay(reservedWsDays.map(e => e.date));
    }
  }, [
    reservedWsDays,
    deskReservationConfiguration,
    configurations,
    settingsReservation,
  ]);

  useEffect(() => {
    if (!_.isEmpty(reservableDays) && mounted.current) {
      const dates = [...reservableDays].sort();

      setMinDate(new Date(_.first(dates)));
      setMaxDate(new Date(_.last(dates)));

      const firstDate = dayjs(_.first(dates)).startOf('day').toISOString();
      const lastDate = dayjs(_.last(dates)).endOf('day').toISOString();

      setNumWeeks(getNumberOfWeeks(firstDate, lastDate));

      const dateIni = dayjs(_.first(dates))
        .subtract(1, 'day')
        .startOf('day')
        .toISOString();
      const dateEnd = dayjs(_.last(dates))
        .add(1, 'day')
        .endOf('day')
        .toISOString();

      dispatch(
        reservationActions.getReservedDaysWsAndHomeOffice(dateIni, dateEnd),
      );
      return () => {
        mounted.current = false;
      };
    }
  }, [reservableDays]);

  useEffect(() => {
    if (favouriteDeskPolicy && deskReservationConfiguration && configurations) {
      settingsReservation &&
        setNonSelectable(
          checkReservations(
            {
              ...settingsReservation,
              schedule: POLICIES_SCHEDULES?.find(
                e => e.id == favouriteDeskPolicy.nombre,
              ).value,
              customStart: favouriteDeskPolicy?.fullHoraMin ?? '00:00',
              customEnd: favouriteDeskPolicy?.fullHoraMax ?? '23:00',
              config: deskReservationConfiguration.configurationName,
            },
            reservedWsDays,
            campusTimeZone,
            configurations,
          ),
        );
      setSettingsReservation({
        ...settingsReservation,
        schedule: POLICIES_SCHEDULES?.find(
          e => e.id == favouriteDeskPolicy.nombre,
        ).value,
        customStart:
          favouriteDeskPolicy.nombre === 'Personalizada'
            ? favouriteDeskPolicy?.fullHoraMin
            : configurations?.policies.find(p => p.nombre === 'Personalizada')
                ?.fullHoraMin ?? '00:00',
        customEnd:
          favouriteDeskPolicy.nombre === 'Personalizada'
            ? favouriteDeskPolicy?.fullHoraMax
            : configurations?.policies.find(p => p.nombre === 'Personalizada')
                ?.fullHoraMax ?? '23:59',
        config: deskReservationConfiguration.configurationName,
      });
    }
  }, [favouriteDeskPolicy, deskReservationConfiguration, configurations]);

  const handleShowSettings = () => {
    setShowSettings(true);
  };

  const getHeaderText = useMemo(() => {
    if (settingsReservation) {
      const schedule =
        settingsReservation.schedule == 'custom_schedule'
          ? `${settingsReservation.customStart}-${settingsReservation.customEnd}`
          : t(settingsReservation.schedule + '_no_hours');
      return `${schedule} | ${t(settingsReservation.config)}`;
    } else {
      return '';
    }
  }, [settingsReservation]);

  return (
    <IonPage>
      {isEmpty(reservableDays) ? (
        <>
          <IonHeader
            mode="ios"
            className={`ion-no-border ${styles.headerColor} ${styles.toolbarGrid}`}
          >
            <TopBar
              primaryActions={
                <BackButton replace router="/dashboard/actions" />
              }
              title={t('calendar_title')}
            />
          </IonHeader>
          <IonContent>
            <div className={styles.fullscreenMessage}>
              {t('no_reservation_date_available')}
            </div>
          </IonContent>
        </>
      ) : (
        <>
          <IonHeader
            mode="ios"
            className={`ion-no-border ${styles.headerColor} ${styles.toolbarGrid}`}
          >
            <TopBar
              primaryActions={
                <BackButton replace router="/dashboard/actions" />
              }
              title={t('calendar_title')}
            />
          </IonHeader>

          <IonContent>
            {!isEmpty(documentReservation) && (
              <Snackbar
                type="info"
                text={
                  <IonText>
                    {t('check_our')}{' '}
                    <a
                      className={styles.defaultLink}
                      onClick={() => openHtmlLink(documentReservation)}
                    >
                      {t('lbl_policy_desk')}
                    </a>
                  </IonText>
                }
                icon="icon icon-info"
                align="center"
              />
            )}
            <div className={styles.snackbar}>
              <div className={styles.calendarSnackbarContent}>
                <div className={styles.firstText}>{getHeaderText}</div>

                <div className={styles.lastIcon}>
                  {configurations &&
                    (configurations?.deskReservationConfigurations?.length >
                      1 ||
                      showPolicies(configurations.policies)) && (
                      <Icon
                        slot="end"
                        className={`icon icon-settings icon-20`}
                        onClick={handleShowSettings}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className={styles.gridWeb}>
              <Toast
                isOpen={showToastErrorWorkstation}
                onDidDismiss={handleDismissToast}
                message={errorWorkstation}
                position="bottom"
                type="error"
              ></Toast>
              <Toast
                isOpen={
                  !!msgErrorReservation &&
                  msgErrorReservation !== 'error' &&
                  msgErrorReservation !== ''
                }
                onDidDismiss={handleDismissToastReservation}
                message={msgErrorReservation}
                position="bottom"
                type="error"
              ></Toast>
              <Toast
                isOpen={showToastHomeOffice}
                onDidDismiss={handleDismissToast}
                message={
                  homeOfficeReservationAvailable
                    ? t('msg_homeoffice')
                    : t('msg_not_reservation_homeoffice')
                }
                position="bottom"
                type={homeOfficeReservationAvailable ? 'homeOffice' : 'error'}
              />
              <div
                className={`${styles.calendarGrid} ${
                  isLoadingWorkstation ||
                  isLoadingReservation ||
                  calendarIsDisabled
                    ? styles.calendarDisabled
                    : ''
                }`}
              >
                {showCalendar && (
                  <>
                    <WeekCalendarMultiselector
                      selected={datesSelected}
                      reserved={reservedDays.filter(
                        e => !nonSelectable.includes(e),
                      )}
                      homeOffice={reservedHomeOfficeDays}
                      onChange={handleSelectDate}
                      locale={localLanguage}
                      weeks={numWeeks}
                      maxSelectedDays={20}
                      reservableDays={reservableDays.filter(
                        e =>
                          !nonSelectable.includes(e) ||
                          reservedDays.includes(e),
                      )}
                      reservedNonSelectable={nonSelectable.filter(e =>
                        reservedDays.includes(e),
                      )}
                      minDate={minDate}
                      maxDate={maxDate}
                      classNames={{
                        notAvailable: styles.notAvailable,
                        homeOffice: styles.homeOffice,
                        reserved: styles.reserved,
                        reservedNonSelectable: styles.reservedNonSelectable,
                        selected: styles.selected,
                        today: styles.today,
                        available: styles.available,
                      }}
                    />
                    <div className={styles.messageCalendar}>
                      {t('message_only_reservable')}
                    </div>
                  </>
                )}
              </div>
            </div>

            {!_.isEmpty(datesSelected) && (
              <IonList className={styles.listReservations}>
                {reservedWsDays
                  .filter(e => datesSelected.includes(e.date))
                  .map(item => {
                    const reservation = reservationsByOwner.find(
                      r => r.code === item.spaceCode,
                    );
                    return (
                      <IonItem
                        key={item.hours.start}
                        lines="full"
                        className={styles.itemList}
                      >
                        <ReservationCard
                          data={{
                            id: item.date,
                            type: 'Desk',
                            header: item.spaceCode,
                            title: reservation?.districtName,
                            descriptionSede: item?.headOffice?.description,
                            subtitle: getTypeLabel(item, configurations),
                            link: null,
                            neighborhood:
                              district === reservation?.district?.toString(),
                            lockerId:
                              getuserLockerMode(reservation?.idSede) ===
                              USER_LOCKER_MODE.DESK_LOCKER
                                ? reservation?.lockerId
                                : '',
                          }}
                          endContent={
                            <IonIcon
                              className={`icon icon-${getIconSchedule(
                                item?.type,
                              )}`}
                            />
                          }
                          headerTranslucent={false}
                          infoSize="large"
                          mode="ios"
                          icon="icon icon-seat"
                          displayLockerId={
                            getuserLockerMode(reservation?.idSede) ===
                            USER_LOCKER_MODE.DESK_LOCKER
                          }
                        >
                          <DayCalendar
                            locale={localLanguage}
                            date={
                              new Date(
                                dayjs(
                                  `${item.date
                                    .toString()
                                    .substring(0, 10)} 12:00:00`,
                                ).toISOString(),
                              )
                            }
                            selected={false}
                            id={item?.date}
                            disabled
                          ></DayCalendar>
                        </ReservationCard>
                      </IonItem>
                    );
                  })}
              </IonList>
            )}
            <IonLoading
              isOpen={isLoadingWorkstation || isLoadingReservation}
              message={t('msg_loading')}
              duration={0}
            />
          </IonContent>
          <IonFooter className="ion-no-border">
            <Legend className={styles.centerLegend}>
              {dataLegend.map((item, index) => {
                return (
                  <LegendItem key={index} type={item.type} text={item.text} />
                );
              })}
            </Legend>
            <div className={styles.btnGroupWeb}>
              <div>
                <Button
                  onClick={handleLastMinuteSelectWs}
                  disabled={
                    calendarIsDisabled ||
                    !datesSelected.length ||
                    !!errorWorkstation
                  }
                >
                  {t('btn_calendar_workstation_select')}
                </Button>
              </div>
            </div>
          </IonFooter>
        </>
      )}

      {settingsReservation && configurations && (
        <SettingsModal
          title={t('reservation_configuration')}
          id="settingsModal"
          isOpen={showSettings}
          onClose={handleOnCloseOption}
          setOpenModal={setShowSettings}
          value={settingsReservation.schedule}
          settingsReservation={settingsReservation}
          setValue={setSettingsReservation}
          configurations={configurations}
          favouriteDeskPolicies={favouriteDeskPolicy}
        />
      )}
    </IonPage>
  );
};

export default Calendar;
