import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { isPlatform } from '@ionic/react';

interface MobileOnlyRouteProps extends RouteProps {
  children?: any;
}
const MobileOnlyRoute: React.FC<MobileOnlyRouteProps> = ({
  children,
  ...rest
}) => {
  return isPlatform('capacitor') ? <Route {...rest}>{children}</Route> : null;
};
export default MobileOnlyRoute;
