import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import Confirmation from '../../Shared/Success/Confirmation';
import Calendar from './Calendar/Calendar';
import LastMinuteParking from './LastMinuteParking/LastMinuteParking';
import NotAvailable from '../../Shared/NotAvailable/NotAvailable';
import ParkingType from './ParkingType/ParkingType';
import CalendarPlanned from './CalendarPlanned/CalendarPlanned';
import CalendarRelease from './CalendarRelease/CalendarRelease';
import ReservationPlanned from './ReservationPlanned/ReservationPlanned';
import NotAvailableVehicle from './NotAvailableVehicle/NotAvailableVehicle';
import ChangeVehicle from './ChangeVehicle/ChangeVehicle';
import Companions from './Companions/Companions';
import NotAvailablePlace from './NotAvailablePlace/NotAvailablePlace';
import CanceledPlace from './CanceledPlace/CanceledPlace';
import ModifyParkingRequest from './ModifyParkingRequest/ModifyParkingRequest';
import ModifyParkingReservation from './ModifyParkingReservation/ModifyParkingReservation';
import PassengerInvitationDetail from './PassengerInvitationDetail/PassengerInvitationDetail';
import PassengerDetail from './PassengerDetail/PassengerDetail';

const Parking: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route path="/parking/patype" exact>
          <ParkingType />
        </Route>
        <Route path="/parking/lastminute" exact>
          <LastMinuteParking />
        </Route>
        <Route path="/parking/changeVehicle" exact>
          <ChangeVehicle />
        </Route>
        <Route path="/parking/noAvailable" exact>
          <NotAvailable />
        </Route>
        <Route path="/parking/noAvailableVehicle" exact>
          <NotAvailableVehicle />
        </Route>
        <Route path="/parking/NotAvailablePlace" exact>
          <NotAvailablePlace />
        </Route>
        <Route path="/parking/calendar" exact>
          <Calendar />
        </Route>
        <Route exact path="/parking/calendarPlanned">
          <CalendarPlanned />
        </Route>
        <Route exact path="/parking/calendarRelease">
          <CalendarRelease />
        </Route>
        <Route exact path="/parking">
          <Redirect to="/parking/patype" />
        </Route>
        <Route exact path="/parking/reservationPlanned">
          <ReservationPlanned />
        </Route>
        <Route exact path="/parking/confirmation">
          <Confirmation />
        </Route>
        <Route exact path="/parking/companions">
          <Companions />
        </Route>
        <Route exact path="/parking/CanceledPlace">
          <CanceledPlace />
        </Route>
        <Route exact path="/parking/modifyRequest">
          <ModifyParkingRequest />
        </Route>
        <Route exact path="/parking/modifyReservation">
          <ModifyParkingReservation />
        </Route>
        <Route exact path="/parking/passengerInvitation">
          <PassengerInvitationDetail />
        </Route>
        <Route exact path="/parking/passengerInvitationDetail">
          <PassengerInvitationDetail detail />
        </Route>
        <Route exact path="/parking/passengerDetail">
          <PassengerDetail />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Parking;
