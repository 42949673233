import { useMap } from 'react-leaflet';
import { MapControlsProps as Props } from './types';
import { IonFab } from '@ionic/react';
import { Control } from '../../Base/Control';
import {
  ZoomInControl,
  ZoomOutControl,
  FullscreenControl,
} from '../../MapControls';
import styles from './MapWorkRoomControls.module.scss';

export const MapWorkRoomControls: React.FC<Props> = (props: Props) => {
  const map = useMap();
  map.whenReady(() => props.center && map.setView(props.center, -1.2));

  return (
    <IonFab>
      <Control position="bottomright">
        <FullscreenControl />
      </Control>
      <Control position="bottomright">
        <ZoomInControl maxZoom={props.maxZoom} />
      </Control>
      <Control position="bottomright">
        <ZoomOutControl minZoom={props.minZoom} cssClass={styles.lastControl} />
      </Control>
    </IonFab>
  );
};
