import {
  WorkstationAction,
  WorkstationState,
} from '../models/workstation.model';

import { WorkstationTypesEnum } from '../types/workstation.types.enum';

const initialState = {
  error: null,
  msg: null,
  isLoading: false,
  isLoadingWorkstations: false,
  workstation: null,
  workstations: [],
  preSelectedWs: [],
  favWorkstations: [],
  floors: [],
  buildings: [],
  mapWorkstations: null,
  raffle: { startHour: null, inProgress: false, weekDay: null },
  config: null,
  reservableDays: [],
  schedulesAvailables: [],
  hasReservableDays: false,
};

export const workstationReducer = (
  state: WorkstationState = initialState,
  action: WorkstationAction,
): WorkstationState => {
  switch (action.type) {
    case WorkstationTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case WorkstationTypesEnum.SET_WORKSTATION:
      return {
        ...state,
        workstation: action.workstation,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_MAP_FLOOR:
      return {
        ...state,
        mapWorkstations: action.mapWorkstations,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_VISUAL_CONFIG:
      return {
        ...state,
        config: action.config,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_PRESELECTED_WORKSTATIONS:
      return {
        ...state,
        preSelectedWs: action.preSelectedWs,
        isLoading: false,
      };
    case WorkstationTypesEnum.FAILURE_WORKSTATION:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_WORKSTATIONS:
      return {
        ...state,
        workstations: action.workstations,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_FLOORS:
      return {
        ...state,
        floors: action.floors,
      };
    case WorkstationTypesEnum.SET_BUILDINGS:
      return {
        ...state,
        buildings: action.buildings,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_FAV_WORKSTATIONS:
      return {
        ...state,
        favWorkstations: action.favWorkstations,
        isLoading: false,
      };
    case WorkstationTypesEnum.ADD_FAV_WORKSTATION:
      return {
        ...state,
        isLoading: false,
        favWorkstations: [...state.favWorkstations, action.workstation],
      };
    case WorkstationTypesEnum.DELETE_FAV_WORKSTATION:
      return {
        ...state,
        isLoading: false,
        favWorkstations: state.favWorkstations.filter(e => {
          return action.workstation.spaceId != e.spaceId;
        }),
      };
    case WorkstationTypesEnum.SET_RAFFLE_STATE:
      return {
        ...state,
        raffle: action.raffle,
        isLoading: false,
      };
    case WorkstationTypesEnum.RESET_ERROR:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_MESSAGE:
      return {
        ...state,
        msg: action.msg,
      };
    case WorkstationTypesEnum.RESET_MESSAGE:
      return {
        ...state,
        msg: null,
      };
    case WorkstationTypesEnum.RESET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_RESERVABLE_DAYS:
      return {
        ...state,
        reservableDays: action.reservableDays,
        hasReservableDays: action.hasReservableDays,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_WORKSTATION_CONFIGURATION:
      return {
        ...state,
        configurations: action.configurations,
        isLoading: false,
      };
    case WorkstationTypesEnum.SET_ISLOADING_WORKSTATIONS:
      return {
        ...state,
        isLoadingWorkstations: true,
      };
    case WorkstationTypesEnum.RESET_ISLOADING_WORKSTATIONS:
      return {
        ...state,
        isLoadingWorkstations: false,
      };
    case WorkstationTypesEnum.RESET_WORKSTATIONS:
      return initialState;
    default:
      return state;
  }
};
