/* eslint-disable prettier/prettier */
enum doorAction {
   succes = "success"
   ,failureEndpoint = "failureEndpoint"
   ,failureInit = "failureInit"
   ,failureBluethothDisabled = "failureBluethothDisabled"
   ,failureLocation = "failureLocation"
   ,failureBluethothIncompatible = "failureBluethothIncompatible"
   ,failure = "failure"
   , none = "none"


};

export {doorAction};