import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { isPlatform } from '@ionic/react';
import { appActions, authActions, userActions } from '../_redux/actions';
import { cookiesServices } from '../_redux/services/cookies/cookies.services';
import { tokenServices } from '../_redux/services/token/token.services';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationService } from './useNotficationService';
import { UserStore } from '../_redux/models/user.model';

export const useLogout = () => {
  const dispatch = useDispatch();
  const { notificationService } = useNotificationService();
  const user = useSelector((state: UserStore) => state.user.user);

  const logOut = async () => {
    if (!isPlatform('capacitor')) {
      await tokenServices.removeAccount();
      cookiesServices.removeGaCookies();
      window.location.href = process.env.REACT_APP_COGNITO_WEB_LOGOUT_URI;
      return;
    }

    const logoutPage = InAppBrowser.create(
      process.env.REACT_APP_COGNITO_WEB_LOGOUT_URI,
      '_blank',
      'location=no,closebuttoncaption=OK,clearcache=yes',
    );

    logoutPage.on('loaderror').subscribe(async () => {
      logoutPage.close();
    });

    logoutPage.on('loadstart').subscribe(async event => {
      const url = event.url;

      if (url.includes(`signout`)) {
        logoutPage.close();
        dispatch(appActions.appStateReset());
        dispatch(authActions.logout());
        dispatch(userActions.resetUser());
        await notificationService.removeSubscribeTo(user.employeeId);
      }
    });
  };

  return {
    logOut,
  };
};
