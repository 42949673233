import { useMap } from 'react-leaflet';
import { IonFabButton, IonIcon } from '@ionic/react';
import { disableClickEventPropagation } from '../../utils/map';
import styles from './styles.module.scss';

type Props = {
  maxZoom: number;
  cssClass?: string;
};

export const ZoomInControl: React.FC<Props> = (props: Props) => {
  const map = useMap();

  const handleZoomIn = () => {
    disableClickEventPropagation('zoom-in-control');
    if (map.getZoom() < props.maxZoom) {
      map.setView(map.getCenter(), map.getZoom() + 1);
    }
  };

  return (
    <IonFabButton
      id="zoom-in-control"
      className={`${styles.fabBtn} ${props.cssClass ?? ''}`}
      color="primary"
      onClick={handleZoomIn}
    >
      <IonIcon className="icon icon-plus" />
    </IonFabButton>
  );
};
