import { useState } from 'react';
import { Browser } from '@capacitor/browser';
import { isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { FileReaderService } from '../_redux/services/fileReader/fileReader.service';

export const useExternalLink = () => {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const openLink = async (link: string) => {
    try {
      if (isPlatform('capacitor')) {
        setDownloading(true);
        const fileName = link.substring(link.lastIndexOf('/') + 1);
        await FileReaderService.openFileByRemoteUrl(link, fileName);
      } else {
        await Browser.open({
          url: link,
          presentationStyle: 'popover',
        });
      }
    } catch (error) {
      setError(t('pdfOpenError'));
    } finally {
      setDownloading(false);
    }
  };

  return { downloading, error, openLink };
};
