import { useSelector } from 'react-redux';
import { NewsStore } from '../../../../_redux/models/news.model';
import { Chip } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import { useMemo } from 'react';

export type Props = {
  category_id?: string;
  category?: string;
};

const CategoryChip: React.FC<Props> = props => {
  const { categories } = useSelector((store: NewsStore) => store.news);

  const categoryName = useMemo(() => {
    const categoryID = props.category_id ?? null;
    const categoryFiltered = categories.find(e => categoryID === e.id);
    const categoryName = !_.isEmpty(categoryFiltered)
      ? categoryFiltered?.name
      : null;
    return categoryName ?? props.category;
  }, [props.category_id, props.category, categories]);

  return (
    <>
      {props.category && !_.isEmpty(props.category) && (
        <Chip type="soft">{categoryName.toUpperCase()}</Chip>
      )}
    </>
  );
};

export default CategoryChip;
