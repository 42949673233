import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
  IonText,
} from '@ionic/react';

import styles from './styles.module.scss';
import { Button, TopBar, Toast, List, Item, Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkstationStore } from '../../../_redux/models/workstation.model';
import { workstationActions } from '../../../_redux/actions';
import { isEmpty } from '../../../utils/functions';
import { AppStore } from '../../../_redux/models/app.model';
import BuildingModal from '../../Spaces/Workstation/BuildingModal';
import { UserStore } from '../../../_redux/models/user.model';
import { SheetModal } from '../../../components/Base/SheetModal';
import FloorModal from '../../Spaces/Workstation/FloorModal';
import { MapSelector } from '../../../components/MapSelector';

let scrolling: any;

const FavoriteMapSelector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setOpenModal] = useState(false);
  const [workstations, setWr] = useState(null);
  const [mapWr, setMapWr] = useState(null);
  const [selectedWorkstations, setSelectedWr] = useState([]);
  const [msgError, setMsgError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [dynamicMaxFav, setDynamicFaxFav] = useState(0);
  const [userDistrictError, setUserDistrictError] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [unselectWs, setUnselectWs] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [loadFromOtherFloor, setLoadFromOtherFloor] = useState(false);
  const [districtFocus, setDistrictFocus] = useState(false);
  const [isModalFloorOpen, setModalFloorOpen] = useState(false);
  const defaultBuildingIndex = 0;
  const defaultFloorIndex = 0;
  const wrData = useSelector((state: WorkstationStore) => state.workstation);
  const {
    maxFavoriteDesks,
    temporalVisitsDistrict,
    favouriteConfiguration = 2,
  } = useSelector((state: AppStore) => state.app.globalSettings);

  const {
    district,
    districtName,
    building = '0',
    floor = '0',
  } = useSelector((state: UserStore) => state.user.user);

  const changeBuildingPermissionFav =
    favouriteConfiguration === 4 || wrData.config === 'none';

  const changeFloorPermissionFav =
    [3, 4].includes(favouriteConfiguration) || wrData.config === 'none';

  useEffect(() => {
    dispatch(workstationActions.getBuildings());
    return () => {
      dispatch(workstationActions.setBuildings([]));
      dispatch(workstationActions.setFloors([]));
    };
  }, []);

  useEffect(() => {
    setSelectedWr(wrData.favWorkstations);
    if (!isEmpty(wrData.buildings)) {
      const bl = wrData.buildings.find(bl => bl.id === parseInt(building));
      setSelectedBuilding(bl ? bl : wrData.buildings[defaultBuildingIndex]);
      dispatch(
        workstationActions.loadMapByBuilding(
          parseInt(building),
          parseInt(floor),
          undefined,
        ),
      );
    }
  }, [wrData.buildings]);

  useEffect(() => {
    if (!isEmpty(wrData.floors)) {
      const fl = wrData.floors.find(fl => fl.id === parseInt(floor));
      firstLoad || districtFocus
        ? setSelectedFloor(fl ? fl : wrData.floors[defaultFloorIndex])
        : setSelectedFloor(wrData.floors[defaultFloorIndex]);
      setFirstLoad(false);
      setDistrictFocus(false);
    }
  }, [wrData.floors]);

  useEffect(() => {
    setMapWr(wrData.mapWorkstations);
  }, [wrData.mapWorkstations]);

  useEffect(() => {
    const selectedIds = selectedWorkstations.map(item => item.spaceId);
    const countFavFloor = wrData.workstations.filter(wr =>
      selectedIds.includes(wr.spaceId),
    );
    wrData.workstations.forEach((wr: any) => {
      selectedIds.includes(wr.spaceId)
        ? (wr.selected = true)
        : (wr.selected = false);
    });
    setWr(wrData.workstations);
    setDynamicFaxFav(
      maxFavoriteDesks - selectedWorkstations.length + countFavFloor.length,
    );
    if (loadFromOtherFloor) {
      setLoadFromOtherFloor(false);
      if (
        !wrData.workstations.find(
          item => item.location.district === districtName,
        )
      ) {
        setUserDistrictError(true);
      }
    }
  }, [wrData.workstations]);

  useEffect(() => {
    if (wrData.error) {
      setMsgError(wrData.error);
      setShowToast(true);

      if (!isEmpty(wrData.buildings)) {
        setSelectedBuilding(wrData?.buildings[defaultBuildingIndex]);
        dispatch(
          workstationActions.loadMapByBuilding(
            wrData?.buildings[defaultBuildingIndex]?.id,
          ),
        );
      }
    }
  }, [wrData.error]);

  const handleSelectedWr = wr => {
    setUnselectWs(null);
    if (wr) {
      wr.selected
        ? setSelectedWr([
            ...selectedWorkstations,
            workstations.find(item => item.spaceId == wr.spaceId),
          ])
        : setSelectedWr(
            selectedWorkstations.filter(item => item.spaceId != wr.spaceId),
          );
    } else {
      setMsgError(t('max_fav_limited_allowed', { max: maxFavoriteDesks }));
      setShowToast(true);
    }
  };

  const handleSelectBuilding = buildingsSelected => {
    setSelectedBuilding(buildingsSelected);
    dispatch(workstationActions.loadMapByBuilding(buildingsSelected.id));
  };

  const handleSelectFloor = floor => {
    dispatch(workstationActions.resetDataMap());
    setSelectedFloor(floor);
    dispatch(workstationActions.getAvailableWsByFloor(floor.id));
  };

  const handleDismissToast = () => {
    setShowToast(false);
    dispatch(workstationActions.resetErrorWorkstation());
  };

  const handleAddFavorite = () => {
    dispatch(
      workstationActions.updateFavorites(
        selectedWorkstations.map(item => item.spaceId),
      ),
    );
  };

  const handleOnScroll = () => {
    clearTimeout(scrolling);
    setIsScrolling(true);
    scrolling = setTimeout(() => {
      setIsScrolling(false);
    }, 500);
  };

  const handleOnUserDistrictFocus = isFocused => {
    if (!isFocused && floor != selectedFloor?.id) {
      setDistrictFocus(true);
      dispatch(
        workstationActions.loadMapByBuilding(
          parseInt(building),
          parseInt(floor),
        ),
      );
      setSelectedBuilding(
        wrData.buildings.find(bl => bl.id === parseInt(building)),
      );
      setLoadFromOtherFloor(true);
    } else {
      setUserDistrictError(!isFocused);
    }
  };
  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-selector-header"
        className={`ion-no-border ${styles.headerSty} ${styles.toolbarGrid}`}
      >
        <div
          className={`${styles.toolbarSty} ${
            isScrolling ? styles.hiddenHeader : styles.showHeader
          }`}
        >
          <TopBar
            primaryActions={
              <IonBackButton
                defaultHref="/profile/user/favworkstations"
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            }
            title={t('wr_add_favorite')}
          />
        </div>
        <IonItem
          mode="ios"
          lines="none"
          onClick={() => setOpenModal(true)}
          type="button"
          className={styles.buildingItem}
          disabled={!changeBuildingPermissionFav}
        >
          <IonLabel>{t('building_filter_workroom')}</IonLabel>
          <IonNote className={styles.buidlingName} color={'primary'} slot="end">
            {selectedBuilding && selectedBuilding.name}
          </IonNote>
          <Icon
            slot="end"
            className={`icon icon-chevron-right ${styles.iconItem}`}
          />
        </IonItem>
        {wrData.floors && (
          <IonItem
            mode="ios"
            lines="inset"
            onClick={() => setModalFloorOpen(true)}
            type="button"
            className={styles.buildingItem}
            disabled={!changeFloorPermissionFav}
          >
            <IonLabel>{t('lbl_floor')}</IonLabel>
            <IonNote
              className={styles.buidlingName}
              color={'primary'}
              slot="end"
            >
              {selectedFloor && selectedFloor.name}
            </IonNote>
            <Icon
              slot="end"
              className={`icon icon-chevron-right ${styles.iconItem}`}
            />
          </IonItem>
        )}
      </IonHeader>
      <IonContent className={styles.contentSty} fullscreen>
        <IonLoading
          isOpen={wrData.isLoading}
          message={t('msg_loading')}
          duration={0}
        />
        {workstations && mapWr && (
          <div className={styles.fixedSectionContent}>
            <div id="map-selector-map" className={styles.mapContainer}>
              <MapSelector
                onScroll={handleOnScroll}
                source={mapWr}
                dataWorkstations={workstations}
                maximum={dynamicMaxFav}
                district={districtName}
                onChange={handleSelectedWr}
                onUserDistrictFocus={handleOnUserDistrictFocus}
                unselectWs={unselectWs}
                setUnselectWs={setUnselectWs}
                myFloor={floor == selectedFloor?.id}
                paddingTop={130}
                building={selectedBuilding || { name: '', id: '' }}
                floor={selectedFloor || { name: '', id: '' }}
                isTemporalVisit={district === temporalVisitsDistrict}
              />
            </div>
          </div>
        )}
        <SheetModal
          id="map-selector-sheet-modal"
          lineScroll
          className={styles.sheetModalPosition}
        >
          <div className={styles.sheetModalContain}>
            <Item lines="none">
              <IonLabel>
                <IonText className={styles.callout}>
                  {t('selected_seats')}
                </IonText>
              </IonLabel>
              <IonLabel
                slot="end"
                className={`ion-text-wrap  ${styles.actionsLabel}`}
              >
                <IonText
                  className={styles.callout}
                >{`${selectedWorkstations.length} / ${maxFavoriteDesks}`}</IonText>
              </IonLabel>
            </Item>
            <List>
              {selectedWorkstations?.map(selWs => (
                <Item key={selWs.spaceId} lines="full" text-wrap="break">
                  <IonLabel className={`ion-text-wrap`}>
                    <div className={styles.callout}>
                      {t('lbl_workstation', { workstation: selWs.name })}
                    </div>
                    <div className={styles.subtitle}>
                      <Icon
                        className={`icon icon-equipments icon-24  ${styles.iconInfo}`}
                      />
                      <h3 className={`${styles.callout}`}>{selWs.equipment}</h3>
                    </div>
                  </IonLabel>
                  <IonLabel
                    className={`ion-text-wrap  ${styles.actionsLabel}`}
                    slot="end"
                  >
                    <div
                      className={`${styles.removeLabel} ${styles.callout}`}
                      onClick={() => setUnselectWs(selWs)}
                    >
                      {t('lbl_remove')}
                    </div>
                    {districtName === selWs.location.district ? (
                      <div className={styles.subtitle}>
                        <Icon
                          className={`icon icon-neighbourhood icon-24  ${styles.iconInfo}`}
                        />
                        <h3 className={styles.callout}>
                          {t('lbl_your_district')}
                        </h3>
                      </div>
                    ) : (
                      <div className={styles.subtitle}>
                        <h3>{selWs.location.district}</h3>
                      </div>
                    )}
                  </IonLabel>
                </Item>
              ))}
            </List>
          </div>
        </SheetModal>
        <Toast
          isOpen={showToast}
          onDidDismiss={() => handleDismissToast()}
          message={msgError}
          position="bottom"
          type="error"
        />
        <Toast
          isOpen={!!userDistrictError}
          onDidDismiss={() => setUserDistrictError(false)}
          message={t('lbl_no_available_district')}
          position="bottom"
          type="error"
        />
        <BuildingModal
          id="buildingModal"
          isOpen={isModalOpen}
          setOpenModal={setOpenModal}
          buildings={wrData.buildings}
          setSelectedBuilding={handleSelectBuilding}
          value={selectedBuilding?.id.toString()}
        />
        <FloorModal
          id="floorModal"
          isOpen={isModalFloorOpen}
          setOpenModal={setModalFloorOpen}
          floors={wrData.floors}
          setSelectedFloor={handleSelectFloor}
          value={selectedFloor?.id.toString()}
        ></FloorModal>
      </IonContent>
      <IonFooter id="map-selector-footer">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              className={styles.large_btn}
              onClick={handleAddFavorite}
              color="primary"
              disabled={isEmpty(selectedWorkstations)}
            >
              {t('wr_add_favorite')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default FavoriteMapSelector;
