import { SearchData, SearchAction } from '../models/search.model';
import { searchServices } from '../services/search/search.services';
import { searchTypesEnum } from '../types/search.types.enum';
import { history } from '../../_helpers/history';

const searchRequest = (): SearchAction => {
  return {
    type: searchTypesEnum.REQUEST,
  };
};

const resetSearch = (): SearchAction => {
  return {
    type: searchTypesEnum.RESET_SEARCH,
  };
};

const resetLoading = (): SearchAction => {
  return {
    type: searchTypesEnum.RESET_LOADING,
  };
};

const setSearch = (search: SearchData, requestTime: number): SearchAction => ({
  type: searchTypesEnum.SET_SEARCH,
  search: search,
  requestTime: requestTime,
});

const fetchSearchFailure = (error: string): SearchAction => {
  return {
    type: searchTypesEnum.FAILURE_USER,
    error: error,
  };
};

const getSearch = (searchWords: string, requestTime: number) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(searchRequest());
    try {
      const searchResult = await searchServices.search(searchWords);
      dispatch(setSearch(searchResult, requestTime));
    } catch (e) {
      dispatch(fetchSearchFailure(e.message));
    }
  };
};

const getSearchPersonDetail = (id: number) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(searchRequest());
    try {
      const searchPersonDetail = await searchServices.getSearchPersonDetail(id);
      history.push('/profile/search/detail-person', searchPersonDetail);
      dispatch(resetLoading());
    } catch (e) {
      dispatch(fetchSearchFailure(e.message));
    }
  };
};

const deleteSearchHistory = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(searchRequest());
    try {
      await searchServices.deleteHistory();
      dispatch(resetSearch());
    } catch (e) {
      dispatch(fetchSearchFailure(e.message));
    }
  };
};

const deleteSearchItem = (historyItem: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(searchRequest());
    try {
      await searchServices.deleteHistoryItem(historyItem);
      dispatch(resetSearch());
    } catch (e) {
      dispatch(fetchSearchFailure(e.message));
    }
  };
};

export const searchActions = {
  getSearch,
  resetSearch,
  getSearchPersonDetail,
  deleteSearchHistory,
  deleteSearchItem,
};
