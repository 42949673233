import { Button, Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonAlert,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonText,
} from '@ionic/react';
import { isEmpty, showPolicies } from '../../../utils/functions';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
// import TypologyModal from './TypologyModal';
import PreferencesTypeModal from './PreferencesTypeModal';
import ReservationScheduleModal from './ReservationScheduleModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserStore,
  UserWorkPreferences,
} from '../../../_redux/models/user.model';
import { WorkstationStore } from '../../../_redux/models/workstation.model';
import { useHistory } from 'react-router';
import { userActions, workstationActions } from '../../../_redux/actions';
import { BackButton } from '../../../components/Base/BackButton';

export type customSchedule = {
  horaMin?: string;
  horaMax?: string;
};
type WorkPreferences = {
  schedule: string;
  customSchedule?: customSchedule;
  typologies: number[];
  preference: string;
};

const WorkEditPreferences: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user: {
      favouriteDeskPolicy,
      deskReservationConfiguration,
      deskFavouriteTypes,
    },
  } = useSelector((state: UserStore) => state.user);
  const { configurations, error } = useSelector(
    (state: WorkstationStore) => state.workstation,
  );
  const initialWorkPrefs: WorkPreferences = {
    schedule: favouriteDeskPolicy.nombre,
    typologies: isEmpty(deskFavouriteTypes)
      ? configurations?.desksTypes.map(desk => desk.id)
      : deskFavouriteTypes.map(desk => desk.id),
    preference: deskReservationConfiguration.configurationName,
    customSchedule: {
      horaMin:
        favouriteDeskPolicy.nombre === 'Personalizada'
          ? favouriteDeskPolicy?.fullHoraMin
          : configurations?.policies.find(p => p.nombre === 'Personalizada')
              ?.fullHoraMin ?? '00:00',
      horaMax:
        favouriteDeskPolicy.nombre === 'Personalizada'
          ? favouriteDeskPolicy?.fullHoraMax
          : configurations?.policies.find(p => p.nombre === 'Personalizada')
              ?.fullHoraMax ?? '23:59',
    },
  };
  const [showToast, setShowToast] = useState(false);
  // const [showTypology, setShowTypology] = useState(false);
  const [showPreferencesType, setShowPreferencesType] = useState(false);
  const [showReservationSchedule, setShowReservationSchedule] = useState(false);
  const [prefs, setPrefs] = useState(initialWorkPrefs);
  const [isEdited, setIsEdited] = useState(false);
  const [openNoPrefsSavedModal, setOpenNoPrefsSavedModal] = useState(false);

  // const handleTypology = () => {
  //   setShowTypology(true);
  // };
  const handlePreferencesType = () => {
    setShowPreferencesType(true);
  };
  const handleReservationSchedule = () => {
    setShowReservationSchedule(true);
  };
  const handleSavePrefs = () => {
    const newWorkstationPreferences: UserWorkPreferences = {
      favouriteDeskPolicy: configurations?.policies.find(policy => {
        if (
          prefs.schedule === 'Personalizada' &&
          policy.nombre === 'Personalizada'
        ) {
          policy.fullHoraMin = prefs.customSchedule.horaMin;
          policy.fullHoraMax = prefs.customSchedule.horaMax;
        }
        return policy.nombre === prefs.schedule;
      }),
      deskReservationConfiguration:
        configurations?.deskReservationConfigurations.find(deskReservation => {
          return deskReservation?.configurationName === prefs.preference;
        }) || deskReservationConfiguration,
      deskFavouriteTypes: configurations?.desksTypes.filter(desk =>
        prefs.typologies.includes(desk.id),
      ),
    };

    dispatch(
      userActions.saveUserDeskPreferences(newWorkstationPreferences, () => {
        if (prefs.schedule === 'Personalizada')
          dispatch(workstationActions.getWsConfigurations());
        setShowToast(true);
        setIsEdited(false);
      }),
    );
  };

  const handleDismissToast = () => {
    setShowToast(false);
  };
  const handlePrefsChangesUnsaved = () => {
    if (isEdited) {
      setOpenNoPrefsSavedModal(true);
      return;
    }
    history.replace('/profile/user/work-place-menu');
  };
  const getPreferencesTypeDetailListText = () => {
    if (!prefs.preference) {
      return 'Selecciona tipologia de puesto';
    } else if (prefs.preference === 'SAME_DESK_CONFIGURATION') {
      return t('SAME_DESK_CONFIGURATION');
    } else if (prefs.preference === 'SAME_ZONE_CONFIGURATION') {
      return t('SAME_ZONE_CONFIGURATION');
    } else {
      return t('not_selected');
    }
  };
  const getReservationScheduleDetailListText = () => {
    if (!prefs.schedule) {
      return t('not_schedule');
    } else if (prefs.schedule === 'Dia completo') {
      return t('complete_day');
    } else if (prefs.schedule === 'Morning') {
      return t('morning_schedule_no_hours');
    } else if (prefs.schedule === 'Tarde') {
      return t('afternoon_schedule_no_hours');
    } else if (prefs.schedule === 'Personalizada') {
      return t('custom_schedule');
    } else {
      return t('not_schedule');
    }
  };
  const updatePreferences = newPreference => {
    const nextPrefs = { ...prefs, preference: newPreference };
    setPrefs(nextPrefs);
    setIsEdited(!_.isEqual(initialWorkPrefs, nextPrefs));
  };

  const updateReservationSchedule = newSchedule => {
    const nextPrefs = { ...prefs, schedule: newSchedule };
    setPrefs(nextPrefs);
    setIsEdited(!_.isEqual(initialWorkPrefs, nextPrefs));
  };
  const updateCustomReservationSchedule = newCustomSchedule => {
    const nextPrefs = { ...prefs, customSchedule: newCustomSchedule };
    setPrefs(nextPrefs);
    setIsEdited(!_.isEqual(initialWorkPrefs, nextPrefs));
  };

  useEffect(() => {
    dispatch(workstationActions.getWsConfigurations());
  }, []);

  return (
    <IonPage>
      {/* <TypologyModal
        id="typologyModal"
        isOpen={showTypology}
        showModal={setShowTypology}
        value={prefs.typologies}
        setValue={newTypologies =>
          setPrefs({ ...prefs, typologies: newTypologies })
        }
      /> */}
      <PreferencesTypeModal
        id="preferencesTypeModal"
        isOpen={showPreferencesType}
        showModal={setShowPreferencesType}
        value={prefs.preference}
        setValue={updatePreferences}
      />
      {configurations?.policies && (
        <ReservationScheduleModal
          id="reservationScheduleModal"
          isOpen={showReservationSchedule}
          showModal={setShowReservationSchedule}
          value={prefs.schedule}
          customSchedule={prefs.customSchedule}
          setValue={updateReservationSchedule}
          setCustomSchedule={updateCustomReservationSchedule}
        />
      )}
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={<BackButton onClick={handlePrefsChangesUnsaved} />}
          title={t('lbl_personal_work_place_preferences')}
        />
      </IonHeader>
      <IonContent>
        <IonAlert
          mode="ios"
          header={t('header_unsaved_changes')}
          message={t('msg_unsaved_changes')}
          isOpen={openNoPrefsSavedModal}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
              handler: () => {
                setOpenNoPrefsSavedModal(false);
              },
            },
            {
              text: 'Si',
              role: 'confirm',
              handler: () => {
                history.replace('/profile/user/work-place-menu');
              },
            },
          ]}
        ></IonAlert>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {configurations?.policies &&
                showPolicies(configurations?.policies) && (
                  <DetailListItem
                    mode="ios"
                    lines="inset"
                    onClick={handleReservationSchedule}
                    titleLight={t('reservation_schedule')}
                    endText={getReservationScheduleDetailListText()}
                    endIcon={'icon-chevron-right'}
                    endTextClass={styles.noPaddingText}
                  />
                )}
              {/* <DetailListItem
                mode="ios"
                lines="inset"
                onClick={handleTypology}
                titleLight={t('work_typology')}
                endText={
                  isEmpty(prefs.typologies) ||
                  prefs.typologies.length === desksTypes.length
                    ? t('all')
                    : `${prefs.typologies.length} ${t('selected')}`
                }
              /> */}
              {configurations &&
                configurations?.deskReservationConfigurations.length > 1 && (
                  <DetailListItem
                    mode="ios"
                    lines="inset"
                    onClick={handlePreferencesType}
                    titleLight={t('preference')}
                    endText={getPreferencesTypeDetailListText()}
                    endIcon={'icon-chevron-right'}
                    endTextClass={styles.noPaddingText}
                  />
                )}
            </DetailList>
            <IonText className={styles.hintText}>
              {t('edit_preferences_hint')}
            </IonText>
            <Toast
              isOpen={showToast}
              onDidDismiss={() => handleDismissToast()}
              message={t('preferences_saved_toast')}
              position="bottom"
              type="success"
            />
          </div>
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleSavePrefs} disabled={!isEdited}>
              {t('preferences_button')}
            </Button>
          </div>
        </div>
      </IonFooter>
      <Toast
        isOpen={error !== null}
        message={error}
        type="error"
        position="bottom"
        onDidDismiss={() =>
          dispatch(workstationActions.resetErrorWorkstation())
        }
      ></Toast>
    </IonPage>
  );
};

export default WorkEditPreferences;
