import {
  MedicalServices,
  MedicalServicesAction,
} from '../models/medicalServices.model';
import { medicalServicesServices } from '../services/medicalServices/medicalServices.services';
import { medicalServicesTypesEnum } from '../types/medicalServices.types.enum';

const medicalServicesRequest = (): MedicalServicesAction => {
  return {
    type: medicalServicesTypesEnum.MEDICAL_SERVICES_REQUEST,
  };
};

const fetchMedicalServicesFailure = (error: string): MedicalServicesAction => {
  return {
    type: medicalServicesTypesEnum.MEDICAL_SERVICES_FAILURE,
    error,
  };
};

const setMedicalServices = (
  medicalServices: MedicalServices[],
): MedicalServicesAction => ({
  type: medicalServicesTypesEnum.SET_MEDICAL_SERVICES,
  medicalServices: medicalServices,
});

const getMedicalServices = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(medicalServicesRequest());
    try {
      const medicalServices =
        await medicalServicesServices.getMedicalServices();
      dispatch(setMedicalServices(medicalServices));
    } catch (e) {
      dispatch(fetchMedicalServicesFailure(e.message));
    }
  };
};

export const medicalServicesActions = {
  getMedicalServices,
};
