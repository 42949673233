import { LatLngTuple } from 'leaflet';
import { Marker } from './types';

export const getCenter = (markers: Marker[]): LatLngTuple => {
  if (markers.length > 0) {
    const ys = markers.map(item => item.y);
    const xs = markers.map(item => item.x);
    const x = (Math.min(...xs) + Math.max(...xs)) / 2;
    const y = (Math.min(...ys) + Math.max(...ys)) / 2;
    return [y, x];
  } else {
    return null;
  }
};
