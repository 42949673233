import { Button, Modal, TopBar } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import {
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { BackButton } from '../../../../../../components/Base/BackButton';
import { datesList } from '../../helpers';
import { DetailList } from '../../../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../../../components/Base/DetailListItem';
import { IPassengerResponse } from '../../../../../../_redux/services/passengers/types';
import { convertDateToWrittenFormat } from '../../../../../../utils/dateTime';

interface IProps {
  dates: any;
  isOpen: boolean;
  handleClose: () => void;
  driver: string;
  input: IPassengerResponse[];
  handleInput: (id: string | number, value: boolean) => void;
  isLoading: boolean;
  handleConfirmReject: () => void;
  handleConfirmCustom: () => void;
}

const Personalize = ({
  dates,
  isOpen,
  handleClose,
  driver,
  input,
  handleInput,
  isLoading,
  handleConfirmReject,
  handleConfirmCustom,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} backdropDismiss={false}>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={<BackButton onClick={handleClose} />}
          title={t('lbl_customize_passenger_invitation')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={`${styles.listGrid}`}>
            <IonItem lines="none" className={styles.text}>
              <span>
                {t('text_custom_passenger_invitation', {
                  driver,
                })}
                <b>
                  {datesList(dates, ', ', ` ${t('and')}`)}
                  {'. '}
                </b>
                {t('select_invitation_dates')}
              </span>
            </IonItem>
            <IonItem>
              <b>{t('dates')}</b>
            </IonItem>
            <DetailList>
              {[
                { id: 'all', status: false, name: t('select_all') },
                ...input,
              ].map(({ id, status, name }) => {
                const value =
                  id === 'all' ? input.every(i => i.status) : status;
                return (
                  <DetailListItem
                    key={id}
                    lines="inset"
                    type="button"
                    className={styles.workroomsContainer}
                  >
                    <IonCheckbox
                      slot={isPlatform('ios') ? 'start' : 'end'}
                      mode="ios"
                      onIonChange={(e: any) => {
                        handleInput(id, e.target.checked);
                      }}
                      checked={value}
                      className={styles.checkbox}
                    />

                    <IonLabel>
                      <div className={styles.row}>
                        {id !== 'all' ? convertDateToWrittenFormat(name) : name}
                      </div>
                    </IonLabel>
                  </DetailListItem>
                );
              })}
            </DetailList>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              onClick={handleConfirmReject}
              disabled={isLoading}
            >
              {t('reject_invitation_all')}
            </Button>
          </div>
          <div>
            <Button
              disabled={isLoading || input.every(i => !i.status)}
              onClick={handleConfirmCustom}
            >
              {t('btn_confirm_selection')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </Modal>
  );
};

export default Personalize;
