import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonButton,
  isPlatform,
} from '@ionic/react';
import {
  TopBar,
  Toast,
  Icon,
  Alert,
  DayCalendar,
  Button,
} from '@acciona/ui-ionic-kit';
import {
  ReleasedDeskDaysDate,
  ReservationStore,
} from '../../../../_redux/models/reservation.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { AppStore } from '../../../../_redux/models/app.model';
import {
  reservationActions,
  workstationActions,
} from '../../../../_redux/actions';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { MapReservation } from '../../../../components/MapReservation/MapReservation';
import { HomeOfficeDates } from '../../../../components/HomeOfficeDates/HomeOfficeDates';
import { ReleasedDays } from '../../../../components/ReleasedDays/ReleasedDays';
import { getDatesToReleaseText, isEmpty } from '../../../../utils/functions';
import {
  POLICIES_SCHEDULES_INDEXED_BY_DESCRIPTION,
  USER_LOCKER_MODE,
} from '../../../../utils/constants';
import { ReleasedDaysSummary } from './ReleasedDaysSummary/ReleasedDaysSummary';
import {
  findFirstReservationOfType,
  getDetailTypeReservationLabel,
  getFilteredReservations,
  getWorkstationFilteredReservations,
  getReservationDetailIconSchedule,
} from '../../helpers';
import styles from './styles.module.scss';
import { App } from '@capacitor/app';
import { BackButton } from '../../../../components/Base/BackButton';
import {
  formatDateToWeekDayAndMonth,
  getIsToday,
} from '../../../../utils/dateTime';
import { formatStringDateForDayCalendarComponent } from './helpers';

const WorkstationReservationDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [disableDays, setDisableDays] = useState(true);
  const [datesReservation, setDatesReservation] = useState([]);
  const [removalType, setRemovalType] = useState('liberate');
  const [deletingToday, setDeletingToday] = useState(false);
  const [liberateSlots, setLiberateSlots] = useState(false);

  const {
    districtName,
    canReleasePermanent,
    defaultSede,
    sedesList: sedeList,
    permissions,
    district,
    deskReservationConfiguration: { configurationName: deskReservationPolicy },
  } = useSelector((state: UserStore) => state.user.user);

  const getuserLockerMode = idSede => {
    const lock =
      sedeList.find(e => e.id == idSede)?.lockerEmployeeKey ??
      USER_LOCKER_MODE.DESK_LOCKER;
    return lock;
  };
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );
  const { campusTimeZone, temporalVisitsDistrict } = useSelector(
    (store: AppStore) => store.app.globalSettings,
  );
  const [releasedDays, setReleasedDays] = useState<ReleasedDeskDaysDate[]>([]);

  const { reservedWorkstation, msg } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const {
    history,
    locationState,
    datesSelected,
    typeReservation,
    startHour,
    endHour,
    isFromConfirmation,
    handleClose,
  } = useReservationsHistory();

  const isPermanentDesk = reservedWorkstation?.useType === 'PermanentDesk';
  useEffect(() => {
    !_.isUndefined(locationState) &&
      !_.isUndefined(locationState.deleteToday) &&
      locationState.deleteToday &&
      deleteTodayWorkstation();

    dispatch(workstationActions.getWsConfigurations());
  }, []);

  useEffect(() => {
    if (isEmpty(reservedWorkstation?.reservations)) {
      return;
    }

    try {
      const filteredReservations = getWorkstationFilteredReservations(
        reservedWorkstation.reservations,
        datesSelected,
        typeReservation,
        startHour,
        endHour,
      );
      setDatesReservation(_.orderBy(filteredReservations, 'date'));
    } catch {
      console.error('getWorkstationFilteredReservations');
    }
  }, [reservedWorkstation?.reservations]);

  const handleFavoriteWs = e => {
    !e.detail.checked
      ? dispatch(workstationActions.deleteFavorite(reservedWorkstation))
      : dispatch(workstationActions.setFavorite(reservedWorkstation));
  };

  const modify = () => {
    setDisableDays(false);
  };

  const redirectToSalto = async () => {
    let appInstalled = false;
    const handleAppStateChange = state => {
      if (!state.isActive) {
        appInstalled = true;
      }
    };
    const subscription = await App.addListener(
      'appStateChange',
      handleAppStateChange,
    );
    window.location.href = reservedWorkstation.saltoUrl;
    setTimeout(() => {
      subscription.remove();
      if (!appInstalled) {
        history.push('/spaces/locker/downloadApp');
      }
    }, 1000);
  };

  const resetDates = () => {
    let updatedDays = [...datesReservation];
    updatedDays = updatedDays.map(date => {
      date.status = false;
      return date;
    });
    setDatesReservation(updatedDays);
    setDeletingToday(false);
  };

  const cancelModify = () => {
    setDisableDays(true);
    resetDates();
  };

  const cancelWrReservation = useCallback(async () => {
    const datesToCancel = datesReservation.filter(date => date.status);

    if (isEmpty(datesToCancel)) {
      return;
    }

    const ids = datesToCancel.map(d => d.id);

    const reservationFiltered = getFilteredReservations(
      reservedWorkstation.reservations,
      datesSelected,
      typeReservation,
      startHour,
      endHour,
    );

    const reservedWorkstationFiltered = {
      ...reservedWorkstation,
      reservations: reservationFiltered,
    };

    dispatch(
      reservationActions.cancelWorkstationReservation(
        reservedWorkstationFiltered,
        ids,
        deletingToday,
      ),
    );

    setDeletingToday(false);
  }, [datesReservation, reservedWorkstation]);

  const checkSelectDays = sel => {
    const updatedDays = [...datesReservation];
    updatedDays.find(date => date.id === sel.id).status = sel.status;
    setDatesReservation(updatedDays);
  };

  const cancelWorkstationReservation = () => {
    const updatedDays = [...datesReservation];
    const days = updatedDays.map(date => ({ ...date, status: true }));
    setDatesReservation(days);
    setRemovalType('delete');
    setShowAlert(true);
  };

  const deleteSelectedWorkstation = () => {
    setRemovalType('liberate');
    setShowAlert(true);
  };

  const handleReleaseDaysOnClick = () => {
    const currentDay = dayjs().tz(campusTimeZone).format('YYYY-MM-DD');
    if (_.isEmpty(releasedDays.filter(e => e.date !== currentDay))) {
      history.push('/workstation/calendarRelease', {
        ...locationState,
        slots: true,
      });
    } else {
      setLiberateSlots(true);
    }
  };

  const handleAcceptLiberate = (value: 'liberate' | 'modify') => {
    if (value === 'liberate') {
      history.push('/workstation/calendarRelease', {
        ...locationState,
        slots: true,
      });
    } else {
      history.push('/workstation/modifyRelease');
    }
  };
  const deleteTodayWorkstation = () => {
    const updatedDays = [...datesReservation].map(item => {
      return dayjs(item.date).isToday() ? { ...item, status: true } : item;
    });
    setDatesReservation(updatedDays);
    setRemovalType('liberate');
    setDeletingToday(true);
    setShowAlert(true);
  };

  const handleModifyMyWorkstation = () => {
    // Generate Action Parameters
    const datesToId: { [key: string]: string } = {};
    const selectedDates = datesReservation.map(entry => {
      datesToId[entry.date] = entry.id;
      return entry.date;
    });

    const policy_tag =
      POLICIES_SCHEDULES_INDEXED_BY_DESCRIPTION[typeReservation];

    const header = [
      selectedDates.length === 1
        ? getIsToday(selectedDates[0])
          ? t('today')
          : t('day_label', { number: selectedDates.length })
        : t('day_label', { number: selectedDates.length, multiple: 's' }),
      policy_tag == 'custom_schedule'
        ? `${startHour}-${endHour}`
        : t(policy_tag + '_no_hours'),
      t(deskReservationPolicy),
    ].join(' | ');

    // Call action if only one date
    if (selectedDates.length === 1) {
      dispatch(
        workstationActions.getLastMinuteSelectWs(
          selectedDates,
          {
            isVisit: district === temporalVisitsDistrict,
            defaultBuilding: reservedWorkstation.location.building,
            defaultFloor: reservedWorkstation.location.floor,
            isWorkstationModification: true,
            datesToId,
          },
          typeReservation,
          reservedWorkstation.reservations[0].startHour,
          reservedWorkstation.reservations[0].endHour,
          header,
          deskReservationPolicy,
        ),
      );
      // Move to date selector if multiple dates
    } else {
      history.push('/workstation/modifyDatesSelector', {
        selectedDates,
        config: {
          isVisit: district === temporalVisitsDistrict,
          defaultBuilding: reservedWorkstation.location.building,
          defaultFloor: reservedWorkstation.location.floor,
          isWorkstationModification: true,
          datesToId,
        },
        typeReservation,
        startHour: reservedWorkstation.reservations[0].startHour,
        endHour: reservedWorkstation.reservations[0].endHour,
        header,
        deskReservationPolicy,
      });
    }
  };

  useEffect(() => {
    setReleasedDays(reservedWorkstation?.releasedDatesTypes ?? []);
  }, [reservedWorkstation?.releasedDatesTypes]);

  const daysToReleaseText = useMemo(
    () =>
      getDatesToReleaseText(
        datesReservation.map(d => {
          return { ...d, date: new Date(dayjs(d.date).toISOString()) };
        }),
      ),
    [datesReservation],
  );

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.toolbarGrid} ion-no-border`}
      >
        <TopBar
          primaryActions={
            isFromConfirmation ? (
              <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
            ) : (
              <BackButton
                onClick={() => history.push('/dashboard/reservations')}
              />
            )
          }
          title={t('detail_reservation')}
          endActions={
            reservedWorkstation.useType !== 'PermanentDesk' ? (
              <IonButton onClick={cancelWorkstationReservation}>
                {t('delete_text')}
              </IonButton>
            ) : null
          }
        />
      </IonHeader>
      <IonContent fullscreen>
        <DetailCard
          id="map-detail-card"
          onClickFavorite={handleFavoriteWs}
          favorite={reservedWorkstation.favorite}
          name="Puesto"
          subName={reservedWorkstation.name}
          type={
            reservedWorkstation.useType === 'PermanentDesk'
              ? 'permanentWorkstation'
              : 'workstation'
          }
          icon="icon icon-seat"
          visibleFavorite={
            reservedWorkstation.useType !== 'PermanentDesk' &&
            reservedWorkstation.canBeMarkedFavourite
          }
        ></DetailCard>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {isPermanentDesk &&
                ((canReleasePermanent ?? false) === true ||
                  !_.isEmpty(releasedDays)) && (
                  <>
                    <ReleasedDays
                      releasedDays={releasedDays.map(
                        releasedDay => releasedDay.date,
                      )}
                      onClick={handleReleaseDaysOnClick}
                      canModify={
                        canReleasePermanent &&
                        reservedWorkstation?.idSede === defaultSede.id
                      }
                      type="desk"
                    >
                      {!isEmpty(releasedDays) && (
                        <ReleasedDaysSummary releasedDays={releasedDays} />
                      )}
                    </ReleasedDays>
                  </>
                )}
              {!isEmpty(datesReservation) && (
                <DetailListItem
                  className={styles.itemList}
                  startIcon="icon icon-booking"
                  startIconColor="primary"
                  readonly
                  title={
                    datesReservation.length === 1
                      ? `${formatDateToWeekDayAndMonth(
                          datesReservation[0].date,
                          { fullday: true },
                        )} ${
                          datesReservation[0].homeOffice
                            ? '- ' + t('lbl_homeOffice')
                            : ''
                        }`
                      : t('lbl_multiple_days')
                  }
                  actionText={
                    disableDays
                      ? datesReservation.length > 1 && (
                          <p onClick={modify} className={styles.modifyLink}>
                            {t('lbl_modify')}
                          </p>
                        )
                      : datesReservation.length > 1 && (
                          <p
                            onClick={cancelModify}
                            className={styles.cancelModifyLink}
                          >
                            {t('lbl_cancel')}
                          </p>
                        )
                  }
                />
              )}
              {datesReservation &&
                !isEmpty(datesReservation) &&
                datesReservation.length > 1 && (
                  <div className={styles.paddingCalendar}>
                    {_.orderBy(datesReservation, 'date')?.map(date => (
                      <DayCalendar
                        locale={localLanguage}
                        key={date.id}
                        id={date.id}
                        date={formatStringDateForDayCalendarComponent(
                          date.date,
                        )}
                        selected={date.status}
                        onChange={e => checkSelectDays(e)}
                        disabled={disableDays}
                        homeOffice={date.homeOffice}
                      ></DayCalendar>
                    ))}
                    {!disableDays && (
                      <div className={`${styles.txtBtnLiberate}`}>
                        <div className={styles.bntListItem}>
                          <div className={`${styles.footnote}`}>
                            {t('btn_liberate_days')}
                          </div>
                          <div>
                            <Button
                              className={styles.small_btn}
                              onClick={deleteSelectedWorkstation}
                              disabled={
                                datesReservation?.findIndex(
                                  date => date.status === true,
                                ) == -1
                              }
                            >
                              {t('lbl_liberate')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {datesReservation && (
                <HomeOfficeDates
                  type={reservedWorkstation}
                  dates={datesReservation}
                />
              )}
              {!isPermanentDesk && (
                <>
                  <DetailListItem
                    className={styles.itemList}
                    startIcon={`icon icon-${getReservationDetailIconSchedule(
                      {
                        typeReservation,
                        startHour,
                        endHour,
                      },
                      reservedWorkstation.reservations,
                    )}`}
                    startIconColor="primary"
                    title={t('reservation_schedule')}
                    description={getDetailTypeReservationLabel(
                      findFirstReservationOfType(
                        typeReservation,
                        reservedWorkstation.reservations,
                        startHour,
                        endHour,
                      ),
                    )}
                    readonly
                  />
                </>
              )}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-equipments"
                startIconColor="primary"
                title={t('lbl_equipment')}
                description={reservedWorkstation.equipment}
                readonly
              />
              {getuserLockerMode(reservedWorkstation?.idSede) ===
              USER_LOCKER_MODE.USER_LOCKER ? null : (
                <div className="locker">
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-lock"
                    startIconColor="primary"
                    readonly
                    title={
                      reservedWorkstation?.lockerId
                        ? t('lbl_locker', {
                            number: reservedWorkstation.lockerId,
                          })
                        : `${t('lbl_locker')} - ${t('space_unallocated')}`
                    }
                    actionText={
                      isPlatform('capacitor') &&
                      reservedWorkstation?.saltoUrl && (
                        <p
                          onClick={redirectToSalto}
                          className={styles.deepLink}
                        >
                          <Icon className="icon icon-extern" />
                          {t('openApp')}
                        </p>
                      )
                    }
                  />
                </div>
              )}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-explore"
                startIconColor="primary"
                title={t('lbl_head_office')}
                description={reservedWorkstation?.descriptionSede}
                readonly
              />
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-campus"
                startIconColor="primary"
                title={`${t('building_filter_workroom')} ${
                  reservedWorkstation.location?.buildingName
                } , ${reservedWorkstation.location?.floorName}`}
                description={
                  districtName === reservedWorkstation.location?.district ? (
                    <div className={styles.locatdivs}>
                      <Icon
                        color="primary"
                        className={`icon icon-neighbourhood ${styles.iconNeighborhood} icon-20`}
                      />
                      {t('lbl_your_district')}
                    </div>
                  ) : (
                    `${t('lbl_district')}: ${
                      reservedWorkstation.location?.district
                    }`
                  )
                }
                readonly
              />
            </DetailList>
            {reservedWorkstation.mapImage && (
              <>
                <div className={styles.separatorMap} />
                <MapReservation
                  x={reservedWorkstation.x}
                  y={reservedWorkstation.y}
                  mapImage={reservedWorkstation.mapImage}
                />
              </>
            )}
          </div>
        </div>
        <Alert
          isOpen={liberateSlots}
          onDidDismiss={() => setLiberateSlots(false)}
          header={t('select_action')}
          buttons={[
            {
              text: t('cancel_text'),
              role: 'cancel',
              handler: () => setLiberateSlots(false),
            },
            {
              text: t('continue_text'),
              role: 'accept',
              handler: (value: 'liberate' | 'modify') =>
                handleAcceptLiberate(value),
            },
          ]}
          inputs={[
            {
              type: 'radio',
              label: t('lbl_liberate_new_days'),
              value: 'liberate',
              checked: true,
            },
            {
              type: 'radio',
              label: t('lbl_modify_liberated_days'),
              value: 'modify',
            },
          ]}
          mode="ios"
        />
        <Alert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={
            removalType === 'liberate'
              ? t('header_liberate_workstation_reserve')
              : t('header_delete_workstation_reserve')
          }
          message={
            datesReservation &&
            t(
              datesReservation.findIndex(date => date.status === false) == -1
                ? 'msg_delete_workstation_reserve'
                : datesReservation.some(date => date.status === true)
                ? 'lbl_position_reserve_liberate'
                : 'lbl_today_position_reserve_liberate',
              {
                workstation: reservedWorkstation.name,
                days: daysToReleaseText,
              },
            )
          }
          buttons={[
            { text: 'No', role: 'cancel', handler: () => resetDates() },
            {
              text: t('lbl_affirmative'),
              handler: cancelWrReservation,
            },
          ]}
          mode="ios"
        ></Alert>
      </IonContent>
      <IonFooter>
        {defaultSede.id === reservedWorkstation.idSede &&
          permissions.includes('desks') &&
          reservedWorkstation.useType !== 'PermanentDesk' && (
            <div className={styles.btnGroupWeb}>
              <div>
                <Button onClick={handleModifyMyWorkstation}>
                  {t('btn_modify_my_workstation')}
                </Button>
              </div>
            </div>
          )}
      </IonFooter>
      {msg && (
        <Toast
          isOpen={!!msg}
          onDidDismiss={() =>
            dispatch(reservationActions.resetMsgReservation())
          }
          message={msg.description}
          position="bottom"
          type={msg.type}
        ></Toast>
      )}
    </IonPage>
  );
};

export default WorkstationReservationDetail;
