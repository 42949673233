export enum vehicleTypesEnum {
  SET_VEHICLE = 'SET_VEHICLE',
  SET_VEHICLES = 'SET_VEHICLES',
  REQUEST = 'FETCH_VEHICLE_REQUEST',
  FAILURE_VEHICLE = 'FAILURE_VEHICLE',
  ADD_VEHICLE = 'ADD_VEHICLE',
  MSG_VEHICLE = 'MSG_VEHICLE',
  RESET_MESSAGE = 'RESET_MESSAGE',
  DELETE_VEHICLE = 'DELETE_VEHICLE',
  VEHICLE_ATRIBUTES = 'VEHICLE_ATRIBUTES',
  RESET_LOADING = 'RESET_LOADING',
  SET_CONFIGURATION = 'SET_CONFIGURATION',
}
