type CustomError = {
  status: number;
  url: string;
  message: string;
  data?: any;
  headers?: any;
};
export class APIRequestError extends Error {
  status: any;
  headers: any;
  url: any;
  constructor({ ...params }: CustomError) {
    super(params.message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIRequestError);
    }

    this.status = params.status;
    this.headers = params.headers;
    this.url = params.url;
  }
}
