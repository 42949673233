import { MoreActions, MoreState } from './models';
import { moreTypes } from './types';

const initialState = {
  config: [],
};

export const moreReducer = (
  state: MoreState = initialState,
  action: MoreActions,
) => {
  switch (action.type) {
    case moreTypes.SET_CONFIG:
      return {
        ...state,
        config: action.config,
      };
    default:
      return state;
  }
};
