import styles from './styles.module.scss';
export const classes = {
  Desk: styles.workstation,
  Parking: styles.parkingReservation,
  Room: styles.room,
  ParkingRequest: styles.parkingRequest,
  Passenger: styles.parkingReservation,
  DeskRequest: styles.workstation,
  RoomWaitingList: styles.room,
  notfound: styles.notfound,
  PermanentParking: styles.parkingReservation,
  PermanentDesk: styles.workstation,
  Locker: styles.locker,
  Canteen: styles.canteen,
  PassengerInvitation: styles.parkingRequest,
};
