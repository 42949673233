import { IonRow, IonText } from '@ionic/react';
import { CardContent, Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { NeighborhoodContent } from './NeighborhoodContent';
import styles from './styles.module.scss';

export const ReservationCardContent = ({ data, displayLockerId }) => {
  const { t } = useTranslation();

  const showNeighbourhoodIcon =
    data.neighborhood &&
    ![
      'Room-WaitingList',
      'Desk-Request',
      'Parking-Request',
      'Parking',
      'Permanent-Parking',
      'PassengerInvitation',
    ].includes(data.type);

  const showLockerId =
    displayLockerId &&
    ['Desk', 'Desk-Request', 'PermanentDesk'].includes(data.type);

  const lockerId = data.lockerId
    ? `${t('lbl_locker', { number: data.lockerId })}`
    : `${t('lbl_locker')} - ${t('space_unallocated')}`;

  return (
    <CardContent className={`${styles.cardContent}`}>
      {(showNeighbourhoodIcon ||
        (data?.title && data.title.length > 0) ||
        showLockerId) && (
        <div
          className={`${styles.subhead} ${styles.color_primary} ${styles.flex} ${styles.gap2}`}
        >
          {showNeighbourhoodIcon ? (
            <NeighborhoodContent data={data} />
          ) : (
            data.title
          )}
          {(showNeighbourhoodIcon || data.title) && showLockerId && ' | '}
          {showLockerId && lockerId}
        </div>
      )}
      {data.pluggable && (
        <div className={styles.electric}>{t('msg_electric_plug_parking')}</div>
      )}

      {data?.abbrdates && data.visualization === 'perDay' && (
        <IonRow>
          <IonText className={styles.abbrDates}>{data.abbrdates}</IonText>
        </IonRow>
      )}

      {data?.descriptionSede && (
        <IonRow>
          <IonText className={styles.descriptionSede}>
            {data.descriptionSede}
          </IonText>
        </IonRow>
      )}

      {data.subtitle && (
        <div className={`${styles.subtitleDiv}`}>{data.subtitle}</div>
      )}

      {data.host && (
        <div className={styles.locatdivs}>
          <Icon className={`icon icon-client ${styles.iconInfo}`} />
          {data.host}
        </div>
      )}
    </CardContent>
  );
};
