import type { AuthAction, AuthState } from '../models/auth.model';
import { authTypesEnum } from '../types';

const initialState = {
  error: null,
  isLoading: false,
  isAuth: false,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  expiresIn: null,
  user: null,
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthAction,
): AuthState => {
  switch (action.type) {
    case authTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case authTypesEnum.AUTH_SUCCESS:
      return {
        ...state,
        error: null,
        isAuth: true,
        isLoading: false,
        idToken: action.idToken,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        expiresIn: action.expiresIn,
        user: action.user,
      };
    case authTypesEnum.REFRESH_TOKEN:
      return {
        ...state,
        error: null,
        isLoading: false,
        idToken: action.idToken,
        accessToken: action.accessToken,
      };
    case authTypesEnum.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isAuth: false,
      };
    case authTypesEnum.LOGOUT:
      return {
        ...state,
        error: null,
        isAuth: false,
        idToken: null,
        accessToken: null,
        refreshToken: null,
        expiresIn: null,
        user: null,
        isLoading: false,
      };
    case authTypesEnum.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case authTypesEnum.RESET:
      return initialState;
    default:
      return state;
  }
};
