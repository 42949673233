import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Props } from './types';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { ReservationItem } from './ReservationItem';
import styles from '../styles.module.scss';
import { useSelector } from 'react-redux';
import { ParkingStore } from '../../../../_redux/models/parking.model';

const ListReservations: React.FC<Props> = props => {
  const {
    type,
    spacesState,
    selectedFilter,
    handleSelectedReservation,
    visualization,
  } = props;
  const { raffle } = useSelector((state: ParkingStore) => state.parking);
  const { t } = useTranslation();

  const raffleDay = dayjs()
    .day(raffle.weekDay)
    .format(`DD [${t('lbl_of_date')}] MMMM`);

  const showPendings = useMemo(
    () =>
      spacesState.filter(
        space =>
          selectedFilter === 'all' &&
          [
            'Room-WaitingList',
            'Parking-Request',
            'Desk-Request',
            'PassengerInvitation',
          ].includes(space.useType),
      ).length > 0 && type === 'request',
    [spacesState, selectedFilter],
  );

  return (
    <>
      {spacesState.length > 0 && showPendings && (
        <div className={styles.separatorList} />
      )}

      {showPendings && (
        <DetailListItem
          lines="full"
          title={t('lbl_pendings')}
          className={styles.titleSty}
        />
      )}

      {spacesState.map((space, index) => {
        return (
          <ReservationItem
            key={index}
            space={space}
            reservationType={type}
            isLastItem={spacesState.length - 1 === index}
            handleSelectedReservation={handleSelectedReservation}
            visualization={visualization}
            raffleDay={raffleDay}
          />
        );
      })}
    </>
  );
};

export default ListReservations;
