import { useSelector } from 'react-redux';
import { UserStore } from '../_redux/models/user.model';
import { isEmpty } from '../utils/functions';

export const useCheckUserPermissions = () => {
  const userPermissions = useSelector(
    (state: UserStore) => state.user.user.permissions,
  );

  const hasPermission = (functionality: string | string[]) => {
    if (functionality === 'public') {
      return true;
    }

    const permissionsRequired = Array.isArray(functionality)
      ? functionality
      : [functionality];

    if (isEmpty(userPermissions)) {
      return false;
    }

    const userHasPermission = permissionsRequired.some(permissionRequired =>
      userPermissions.includes(permissionRequired),
    );

    return userHasPermission;
  };

  return { hasPermission };
};
