import dayjs from 'dayjs';

export enum EToastMessages {
  slotEnded = 'lbl_canteen_slot_ended',
  previousReservation = 'lbl_canteen_previous_reservation',
  slotUnavailable = 'lbl_canteen_slot_unavailable',
  unknownError = 'error_400',
}

export const checkInTime = (timeLimit: string, margin: number): boolean => {
  const minuteDiff = dayjs(timeLimit).diff(dayjs(), 'minute');
  return minuteDiff >= margin;
};
