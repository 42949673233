import { IonImg } from '@ionic/react';
import { useState } from 'react';

type Props = {
  src: string;
  fallback?: string;
  className?: string;
};
export const SafeIonImage: React.FC<Props> = ({
  src,
  fallback = '/assets/images/noImage.png',
  className,
}) => {
  const [imageUrl, setImageUrl] = useState(src);
  return (
    <IonImg
      className={className}
      src={imageUrl}
      onIonError={() => {
        setImageUrl(fallback);
      }}
    />
  );
};
