import { Chip, Icon, Item } from '@acciona/ui-ionic-kit';
import { IonInput, IonLabel, IonNote, IonText } from '@ionic/react';
import { Props } from './types';
import styles from './styles.module.scss';
import { classes } from './classes';
import { FormatedText } from '../FormattedText';
import { useTranslation } from 'react-i18next';
export const DetailListItem: React.FC<Props> = ({
  color = 'readonly',
  ...props
}) => {
  const { t } = useTranslation();
  const { className, ...res } = props;
  return (
    <Item
      lines={res.lines || 'none'}
      className={`${styles.item} ${className} ${
        res?.readonly ? styles.defaultCursor : ''
      } ${res?.companion ? styles.companion : ''} `}
      {...res}
    >
      {res.children}
      {res.startIcon && (
        <Icon
          slot="start"
          color={res.startIconColor}
          className={`${res.startIcon} ${styles.startIcon}`}
        />
      )}
      {res.initials && <div className={styles.initials}> {res.initials} </div>}
      {!res.startChip &&
        (res.title || res.titleLight || res.description || res.callout) && (
          <div className={`ion-text-wrap ${styles.columnList}`}>
            {res.title && (
              <IonText
                color={res.titleColor}
                className={`${styles.headline} ion-text-wrap ${styles.block} ${res.titleClass}`}
              >
                {res.title}
              </IonText>
            )}
            {res.middleIcon ? (
              <div className={styles.labelAndChip}>
                {res.titleLight && (
                  <IonText
                    color={res.titleColor}
                    className={`${styles.body} ion-text-wrap  ${styles.block}`}
                  >
                    {res.titleLight}
                  </IonText>
                )}
                {res.middleIcon && <Icon className={res.middleIcon} />}
              </div>
            ) : (
              <IonText
                color={res.titleColor}
                className={`${styles.body} ion-text-wrap  ${styles.block}`}
              >
                {res.titleLight}
              </IonText>
            )}

            {res.callout && (
              <IonText
                color={res.titleColor}
                className={`${styles.callout} ion-text-wrap  ${styles.block}`}
              >
                {res.callout}
              </IonText>
            )}
            {res.description && (
              <IonText
                color={res.descriptionColor}
                className={`${styles.description} ion-text-wrap ${styles.subhead} ${styles.block}`}
              >
                {res.description}
              </IonText>
            )}
            {res.formatedText && (
              <IonText
                color={res.descriptionColor}
                className={`${styles.description} ion-text-wrap ${styles.subhead} ${styles.block}`}
              >
                <FormatedText
                  className={styles.formatedText}
                  content={res.formatedText}
                />
              </IonText>
            )}
            {res.descriptionLink && (
              <IonText
                className={`${styles.descriptionLink} ion-text-wrap ${styles.subhead} ${styles.color_secondary} ${styles.block}`}
              >
                {res.descriptionLink}
              </IonText>
            )}
            {res.footnote && (
              <IonText
                className={`${styles.footnote} ion-text-wrap ${styles.color_dark} ${styles.block}`}
              >
                {res.footnote}
              </IonText>
            )}
          </div>
        )}
      {res.startChip && (
        <div className={styles.columnList}>
          <div className={styles.labelAndChip}>
            <div>
              {res.titleLight && (
                <IonText
                  color={res.titleColor}
                  className={`${styles.body} ion-text-wrap ${styles.block}`}
                >
                  {res.titleLight}
                </IonText>
              )}
              {res.startChip && (
                <Chip type={res.startChip.type} className={styles.chipSty}>
                  <IonLabel>{res.startChip.text}</IonLabel>
                </Chip>
              )}
              {res.middleIcon && <Icon className={res.middleIcon} />}
            </div>
          </div>
          {res.description && (
            <IonText
              color={res.descriptionColor}
              className={`${styles.description} ion-text-wrap ${styles.subhead} ${styles.block}`}
            >
              {res.description}
            </IonText>
          )}
          {res.footnote && (
            <IonText
              className={`${styles.footnote} ion-text-wrap ${styles.block}`}
            >
              {res.footnote}
            </IonText>
          )}
        </div>
      )}

      {res.endIconTop && (
        <Icon
          slot="end"
          color={res.endIconColor}
          className={`${res.endIconTop} ${styles.endIconTop}`}
        />
      )}
      {res.pending && (
        <Chip type="warning" className={styles.pending} slot="end">
          <IonLabel>{t('lbl_pending')}</IonLabel>
        </Chip>
      )}
      {res.endIconAction && (
        <Icon
          slot="end"
          color={res.endIconActionColor}
          className={`${res.endIconAction} ${styles.endIconAction}`}
          onClick={res.actionEndIcon}
        />
      )}
      {res.actionText && (
        <IonNote
          slot="end"
          className={`${styles.subhead} ${styles.color_secondary} ${styles.marginNote}`}
        >
          {res.actionText}
        </IonNote>
      )}
      {res.endText && (
        <IonText
          slot="end"
          color={res.endTextColor}
          className={`${styles.titleLight} ${styles.marginText} ${res.endTextClass}`}
        >
          {res.endText}
        </IonText>
      )}
      {res.endIcon && (
        <Icon
          slot="end"
          color={res.endIconColor}
          className={`${res.endIcon} ${styles.endIcon}`}
        />
      )}
      {res.endTitleText && (
        <IonText
          slot="end"
          color={res.endTextColor}
          className={`${styles.callout} ${styles.marginText}`}
        >
          {res.endTitleText}
        </IonText>
      )}
      {res.endTextSelect && (
        <IonText
          slot="end"
          color={res.endtextSelectColor}
          className={`${styles.titleLight} ${styles.endTextSelect}`}
        >
          {res.endTextSelect}
        </IonText>
      )}
      {res.inputPlaceholder && (
        <IonInput
          autocapitalize="sentences"
          slot="end"
          maxlength={res.inputMaxLenght}
          placeholder={res.inputPlaceholder}
          onIonInput={res.actionInputText}
          className={`ion-text-wrap ${styles.block} ${styles.inputText}`}
        />
      )}
      {res.value && (
        <IonInput
          autocapitalize="sentences"
          slot="end"
          maxlength={res.inputMaxLenght}
          onIonInput={res.actionInputText}
          className={`ion-text-wrap ${styles.block} ${
            res.readonly ? styles.inputTextReadOnly : styles.inputText
          }`}
          color={res.readonly ? classes[color] : color}
          value={res.value}
          readonly={res.readonly}
        />
      )}
    </Item>
  );
};
