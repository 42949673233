import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { Button, TopBar } from '@acciona/ui-ionic-kit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewsStore } from '../../_redux/models/news.model';
import { useDispatch, useSelector } from 'react-redux';
import { FormatedText } from '../../components/Base/FormattedText';
import styles from './styles.module.scss';
import { useLocation } from 'react-router';
import { backToDetail } from '../../utils/functions';
import { useNewsCaptureLinksOnClick } from '../../hooks/useNewsCaptureLinksOnClick';

const NotificationDetail: React.FC<any> = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [bodyHasContent, setBodyHasContent] = useState(false);
  const { t } = useTranslation();
  const notice = useSelector((state: NewsStore) => state.news.notice);
  const location = useLocation().state as any;
  const backUrl = location?.backUrl ?? null;
  const idOrigin = location?.origin_uuid ?? null;
  const pathOrigin = location?.pathOrigin ?? null;

  const dispatch = useDispatch();

  const detailNoticeBack = () => {
    backToDetail(idOrigin, backUrl, dispatch, pathOrigin);
  };

  useNewsCaptureLinksOnClick('notice');

  useEffect(() => {
    notice && setTitle(notice?.title);
    notice && setBody(notice?.body);
    if (notice?.body?.length > 0) {
      setBodyHasContent(true);
    }
  }, [notice]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <div className={styles.headerIcon}>
              <Button
                onClick={detailNoticeBack}
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            </div>
          }
          title={title}
        />
      </IonHeader>
      <IonContent>
        <div className={`${styles.gridWeb} ${styles.cardDetailContain}`}>
          <div
            className={`${styles.cardContain} ${styles.title_1} ${styles.cardTitle}`}
          >
            {title}
          </div>
          <FormatedText
            className={`${styles.cardText}`}
            content={bodyHasContent ? body : t('lbl_empty_article')}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotificationDetail;
