import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Props, Marker, Workstation } from './types';
import { IonSpinner } from '@ionic/react';
import { useMapConfig } from '../../hooks/useMapConfig';
import { getCenter, getIconConfig } from './functions';
import { MapSelectorControls } from './MapSelectorControls';
import { MapLegendModal } from '../Base/MapLegendModal';
import { isEmpty } from '../../utils/functions';
import styles from './styles.module.scss';
import { Map } from '@acciona/ui-ionic-kit';

const getClassName = (paddingTop: number): string => {
  if (paddingTop === 240) {
    return `${styles.mapContainer} ${styles.margin240}`;
  } else if (paddingTop === 180) {
    return `${styles.mapContainer} ${styles.margin180}`;
  } else {
    return `${styles.mapContainer} ${styles.margin130}`;
  }
};

export const MapSelector: React.FC<Props> = props => {
  const {
    source,
    dataWorkstations,
    maximum,
    onChange,
    unselectWs,
    setUnselectWs,
    paddingTop,
    district,
    onUserDistrictFocus,
    myFloor: isUserFloor,
    building,
    floor,
    isTemporalVisit,
  } = props;

  const [countSelected, setCountSelected] = useState<number>(0);
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [showLegend, setShowLegend] = useState(false);
  const mapConfig = useMapConfig(source);

  const userDistrictWorkstations = useMemo(
    () => markers.filter(item => item.location.district === district),
    [markers, district],
  );

  const center = useMemo(
    () => getCenter(userDistrictWorkstations),
    [userDistrictWorkstations],
  );

  useEffect(() => {
    if (mapConfig.imageDimensions.height && dataWorkstations) {
      const selected = dataWorkstations.filter(
        (item: Workstation) => item.selected,
      );
      setCountSelected(selected.length);
      const nextMarkers = dataWorkstations.map(ws => ({
        ...ws,
        y: mapConfig.imageDimensions.height - ws.y,
        icon: getIconConfig(ws),
      }));
      setMarkers(nextMarkers);
    }
  }, [source, dataWorkstations, mapConfig.imageDimensions.height]);

  useEffect(() => {
    if (unselectWs) {
      setUnselectWs({ ...unselectWs, selected: true });
      handleSelectWorkstation({ ...unselectWs, selected: true });
    }
  }, [unselectWs]);

  const handleSelectWorkstation = (workstationSelected: any) => {
    if (maximum !== countSelected || !!workstationSelected.selected) {
      setCountSelected(
        workstationSelected.selected ? countSelected - 1 : countSelected + 1,
      );

      const updatedMarkers = markers.map((marker: any) => {
        return marker.spaceId === workstationSelected.spaceId
          ? {
              ...marker,
              selected: !workstationSelected.selected,
              icon: getIconConfig({
                ...marker,
                selected: !workstationSelected.selected,
              }),
            }
          : marker;
      });
      setMarkers(updatedMarkers);

      return (
        onChange &&
        onChange({
          ...workstationSelected,
          selected: !workstationSelected.selected,
        })
      );
    } else {
      return onChange && onChange(null);
    }
  };

  /**
   * If I click on "location":
   * 1) I am on my floor (there are available positions of my neighborhood) -> center in my neighborhood
   * 2) I am not on my floor -> loads my building/ floor
   * 3) I am on my floor (there are NO workstations available from my neighborhood) -> "location" button disabled
   */
  const handleDistrictFocus = () => {
    const isUserDist = userDistrictWorkstations.length > 0;
    onUserDistrictFocus(isUserFloor && isUserDist);
  };

  const mapProps = {
    container: {
      tap: false,
      center: center,
      maxZoom: 1.5,
      minZoom: -3,
      zoomSnap: 0.25,
      zoomControl: false,
      transparent: false,
    },
    markers: {
      points: markers,
      cluster: true,
      onClickMarker: handleSelectWorkstation,
    },
    image: {
      width: mapConfig.imageDimensions.width,
      height: mapConfig.imageDimensions.height,
      url: source,
    },
    defaultControls: false,
  };

  return (
    <>
      <div
        id="map-selector-container"
        ref={mapConfig.mapContainerRef}
        className={getClassName(paddingTop)}
      >
        {mapConfig.isReady ? (
          <>
            <Map {...mapProps}>
              <MapSelectorControls
                maxZoom={mapProps.container.maxZoom}
                minZoom={mapProps.container.minZoom}
                center={center}
                districtFocusHidden={isTemporalVisit}
                // If I am on my floor and there are NO workstations available from my neighborhood -> "location" button disabled
                districtFocusDisabled={
                  isUserFloor && !isEmpty(userDistrictWorkstations)
                }
                onDistrictFocus={handleDistrictFocus}
                onClickLegend={() => setShowLegend(true)}
              />
            </Map>

            {showLegend &&
              createPortal(
                <MapLegendModal
                  isOpen={showLegend}
                  onDidDismiss={() => setShowLegend(false)}
                  building={building}
                  floor={floor}
                />,
                document.body,
              )}
          </>
        ) : (
          <div className={`ion-text-center ${styles.spinnerContainer}`}>
            <IonSpinner />
          </div>
        )}
      </div>
    </>
  );
};
