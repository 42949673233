import { useEffect } from 'react';
import { IonHeader, IonContent, IonButton, IonIcon } from '@ionic/react';
import { Modal, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { RadioList } from '../../../../components/Base/RadioList';
import { Props } from './types';
import { useSelector } from 'react-redux';
import { WorkstationStore } from '../../../../_redux/models/workstation.model';
import { POLICIES_SCHEDULES } from '../../../../utils/constants';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { AppStore } from '../../../../_redux/models/app.model';
import { DateTime } from '../../../../components/DateTime/DateTime';
import { calcOneHourMoreOrLess } from '../../../../utils/dateTime';
import styles from './styles.module.scss';

const ReservationScheduleModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { campusTimeZone } = useSelector(
    (store: AppStore) => store.app.globalSettings,
  );
  const {
    configurations: { policies },
  } = useSelector((state: WorkstationStore) => state.workstation);
  const customScheduleHoraMin = policies.find(p => p.nombre === 'Personalizada')
    ? policies.find(p => p.nombre === 'Personalizada').fullHoraMin
    : '08:00';
  const customScheduleHoraMax = policies.find(p => p.nombre === 'Personalizada')
    ? policies.find(p => p.nombre === 'Personalizada').fullHoraMax
    : '20:00';
  const getScheduleOptions = () => {
    const scheduleType = [];
    policies &&
      policies.map(conf => {
        const confValue =
          POLICIES_SCHEDULES?.find(e => e.id == conf.nombre) || null;

        if (!confValue) return;

        switch (confValue.value) {
          case 'complete_day':
            scheduleType.push({
              name: t('complete_day'),
              value: 'Dia completo',
            });
            break;
          case 'morning_schedule':
            scheduleType.push({
              name: `${t('morning_schedule', {
                endHour: conf.fullHoraMax,
              })}`,
              value: 'Morning',
            });
            break;
          case 'afternoon_schedule':
            scheduleType.push({
              name: `${t('afternoon_schedule', {
                startHour: conf.fullHoraMin,
              })}`,
              value: 'Tarde',
            });
            break;
          case 'custom_schedule':
            scheduleType.push({
              name: `${t('custom_schedule')}`,
              value: 'Personalizada',
            });
        }
      });
    return scheduleType;
  };

  const handleSelectOption = value => {
    props.setValue(value);
  };

  const pickerButtons = document.getElementsByClassName('picker-button');
  const simulateClickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  });

  useEffect(() => {
    return () => {
      pickerButtons.length > 0 &&
        pickerButtons[0].dispatchEvent(simulateClickEvent);
    };
  }, []);

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={() => props.showModal(false)}
    >
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonButton
              className={styles.btnBack}
              fill="clear"
              onClick={() => props.showModal(false)}
            >
              <IonIcon className="icon icon-chevron-left" />
            </IonButton>
          }
          title={t('reservation_schedule')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.listModal}>
          <RadioList
            options={getScheduleOptions()}
            selectedOption={props.value}
            onChange={handleSelectOption}
          />
          {props.value === 'Personalizada' && (
            <>
              <DetailListItem
                mode="ios"
                lines="full"
                title={t('select_schedule')}
                readonly
              />
              <DetailListItem
                mode="ios"
                lines="none"
                titleLight={t('init_time')}
                className={styles.hourText}
                endText={
                  <DateTime
                    name="initTime"
                    className={styles.dateTime}
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    minuteValues="0"
                    min={customScheduleHoraMin}
                    max={calcOneHourMoreOrLess(
                      props.customSchedule.horaMax,
                      false,
                    )}
                    placeholder={t('workroom_placeholder')}
                    value={props.customSchedule.horaMin}
                    onIonChange={e =>
                      props.setCustomSchedule({
                        ...props.customSchedule,
                        horaMin: e.detail.value!,
                      })
                    }
                    cancelText={t('cancel_text')}
                    doneText={t('done_text')}
                  />
                }
              />
              <div className={styles.lineInsetSeparator}>
                <div></div>
              </div>
              <DetailListItem
                mode="ios"
                lines="none"
                titleLight={t('end_time')}
                className={styles.hourText}
                endText={
                  <DateTime
                    name="endTime"
                    className={styles.dateTime}
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    minuteValues="0"
                    min={calcOneHourMoreOrLess(
                      props.customSchedule.horaMin,
                      true,
                    )}
                    max={customScheduleHoraMax}
                    value={props.customSchedule.horaMax}
                    placeholder={t('workroom_placeholder')}
                    onIonChange={e =>
                      props.setCustomSchedule({
                        ...props.customSchedule,
                        horaMax: e.detail.value!,
                      })
                    }
                    disabled={props.customSchedule?.horaMin == null}
                    cancelText={t('cancel_text')}
                    doneText={t('done_text')}
                  />
                }
              />
              <div className={styles.lineInsetSeparator}>
                <div></div>
              </div>
            </>
          )}
        </div>
      </IonContent>
    </Modal>
  );
};
export default ReservationScheduleModal;
