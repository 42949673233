import { IonContent, IonHeader, IonLabel, IonPage } from '@ionic/react';
import { Button, TopBar } from '@acciona/ui-ionic-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import { useLocation } from 'react-router';
import { backToDetail } from '../../../utils/functions';

const PlainErrorCommunications: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation().state as any;
  const backUrl = location?.backUrl ?? null;
  const idOrigin = location?.origin_uuid ?? null;
  const type = location?.type ?? 'news';
  const pathOrigin = location?.pathOrigin ?? null;

  const dispatch = useDispatch();

  const detailNoticeBack = () => {
    backToDetail(idOrigin, backUrl, dispatch, pathOrigin);
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <div className={styles.headerIcon}>
              <Button
                onClick={detailNoticeBack}
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            </div>
          }
          title={'Error'}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.center}>
            <IonLabel className={styles.textSty}>
              {t(`${type}_not_found`)}
            </IonLabel>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PlainErrorCommunications;
