import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
} from '@ionic/react';
import { Toast, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { incidentActions } from '../../../_redux/actions';
import { IncidentStore } from '../../../_redux/models/incident.model';
import { useHistory } from 'react-router';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { AppStore } from '../../../_redux/models/app.model';
import { useLocationState } from '../../../hooks/useLocationState';

const ReportType: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useLocationState() as any;

  const { subcategories, isLoading, error } = useSelector(
    (store: IncidentStore) => store.incident,
  );

  const { localLanguage } = useSelector(
    (store: AppStore) => store.app.localSettings,
  );

  useEffect(() => {
    dispatch(incidentActions.getSubcategories(state.categoryId, localLanguage));
  }, []);

  const handleSelectType = type => {
    type.actionType === 1
      ? history.push(`/incidents/callphone/${type.recipient}`)
      : history.push('/incidents/report', {
          ...state,
          ...{
            actionType: type.actionType,
            type: type.name,
            incidenceId: type.usubcategory,
            categoryId: type.ucategory,
            descriptionPlaceholder: type?.exampleDescription,
            locationPlaceholder: type?.exampleLocation,
            category: type.categoryId,
            recipient: type?.recipient,
          },
        });
  };
  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('incident_report_header', {
            name: state?.categoryName?.toLowerCase(),
          })}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {subcategories &&
                subcategories.map(item => {
                  return (
                    <DetailListItem
                      className={styles.itemList}
                      key={item.incidenceId}
                      mode="ios"
                      lines="inset"
                      onClick={() => handleSelectType(item)}
                      button
                      titleLight={item.name}
                      footnote={item.description}
                      endIcon="icon icon-chevron-right"
                    />
                  );
                })}
            </DetailList>
            <IonLoading
              isOpen={isLoading}
              message={t('msg_loading')}
              duration={1500}
            />
          </div>
        </div>
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
      </IonContent>
    </IonPage>
  );
};

export default ReportType;
