import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonLabel,
  IonItem,
} from '@ionic/react';
import { Button, TopBar, ActionSheet } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import { useLocationState } from '../../../../hooks/useLocationState';

const NotAvailableWL: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { buildings, workroomTypes, searchFilters } = useSelector(
    (state: WorkroomStore) => state.workroom,
  );
  const state = useLocationState();
  const [date, setDate] = useState({
    date: new Date(),
    from: new Date(),
    to: new Date(),
  });

  useEffect(() => {
    state && setDate(state as any);
  }, [state]);

  const handleWorkroomsList = notificationLimit => {
    const buildingNames =
      searchFilters.buildings &&
      searchFilters.buildings
        .map(id => buildings.find(e => e.id === id).name)
        .toString();
    const spaceNames =
      searchFilters.spaceTypes &&
      searchFilters.spaceTypes
        .map(id => workroomTypes.find(e => e.id === id).name)
        .toString();

    const filters = {
      ...searchFilters,
      buildingNames: buildingNames,
      spaceNames: spaceNames,
    };
    dispatch(workroomActions.joinWaitingList(filters, notificationLimit));
  };

  const [showActionSheet, setShowActionSheet] = useState(false);
  const dataActionSheetButtons = [
    {
      cssClass: styles.btnActionSheetOptions,
      text: `24 ${t('actionSheetTime')}`,
      handler: () => handleWorkroomsList(24),
    },
    {
      cssClass: styles.btnActionSheetOptions,
      text: `48 ${t('actionSheetTime')}`,
      handler: () => handleWorkroomsList(48),
    },
    {
      cssClass: styles.btnActionSheetOptions,
      text: `72 ${t('actionSheetTime')}`,
      handler: () => handleWorkroomsList(72),
    },
    {
      cssClass: styles.btnActionSheetCancel,
      text: `${t('btn_calendar_parking_select')}`,
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      },
    },
  ];

  return (
    <IonPage>
      <IonHeader className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('select_workroom')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <IonItem lines="none" className={styles.headerDates}>
          <IonLabel className="ion-text-center">
            {`${dayjs(date.date).tz().format('DD MMM YYYY')} ,
  ${dayjs(date.from).tz().format('HH:mm')} -
  ${dayjs(date.to).tz().format('HH:mm')}                 
  ${
    searchFilters.capacity
      ? ', ' + t('lbl_people', { capacity: searchFilters.capacity })
      : ''
  }`}
          </IonLabel>
        </IonItem>
        <div className={styles.gridWeb}>
          <div className={styles.center}>
            <IonLabel className={styles.firstTextSty}>
              {t('not_available_workroom')}
            </IonLabel>
            <IonLabel className={styles.secTextSty}>
              {t('not_available_workroom_description')}
            </IonLabel>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              disabled
              onClick={() => setShowActionSheet(true)}
              expand="block"
            >
              {t('join_waiting_list')}
            </Button>
          </div>
        </div>
      </IonFooter>
      <ActionSheet
        cssClass={styles.actionsheetSty}
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        header={t('actionSheetTitle')}
        mode={'ios'}
        buttons={dataActionSheetButtons}
      ></ActionSheet>
    </IonPage>
  );
};

export default NotAvailableWL;
