import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RestaurantsStore } from '../../../../../../_redux/models/restaurants.model';
import { useLocationState } from '../../../../../../hooks/useLocationState';
import { ServiceDetail } from '../../../templates/ServiceDetail/ServiceDetail';

const RestaurantInformation: React.FC = () => {
  const { t } = useTranslation();
  const [restaurantInformation, setRestaurantInformation] = useState(null);
  const { restaurants } = useSelector(
    (store: RestaurantsStore) => store.restaurants,
  );

  const state = useLocationState() as any;
  useEffect(() => {
    state && setRestaurantInformation(restaurants.find(e => e.id === state.id));
  }, [state]);

  return (
    <ServiceDetail
      title={t('lbl_more_restaurants')}
      info={restaurantInformation}
    />
  );
};

export default RestaurantInformation;
