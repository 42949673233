import {
  NewsAction,
  News,
  Comment,
  TabPosition,
  socialStats,
  newsByCategories,
  NewsCategory,
} from '../models/news.model';
import { newsServices } from '../services/news/news.services';
import { NewsTypesEnum } from '../types/news.types.enum';
import { history } from '../../_helpers/history';
import { isEmpty } from '../../utils/functions';
import _ from 'lodash';

const newsRequest = (): NewsAction => {
  return {
    type: NewsTypesEnum.REQUEST,
  };
};

const newsRequestDetail = (): NewsAction => {
  return {
    type: NewsTypesEnum.REQUEST_DETAIL,
  };
};

const setNews = (news: News, comments: Comment[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWS,
    news: news,
    comments: comments,
  };
};

const setNotice = (notice: News): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NOTICE,
    notice: notice,
  };
};

const setNewslist = (Newslist: News[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWSLIST,
    newslist: Newslist,
  };
};
const setMoreNews = (moreNews: boolean): NewsAction => {
  return {
    type: NewsTypesEnum.SET_MORE_NEWS,
    moreNews: moreNews,
  };
};

const setNewslistHomeOther = (newslistOther: News[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWSLIST_HOME_OTHER,
    newslistOther: newslistOther,
  };
};

const setNewslistHomeCarrousel = (newslistCarrousel: News[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWSLIST_HOME_CARROUSEL,
    newslistCarrousel: newslistCarrousel,
  };
};
const setNewslistOther = (newslistOther: News[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWSLIST_OTHER,
    newslistOther: newslistOther,
  };
};

const setNewsSearch = (newsSearch: News[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWSLIST_SEARCH,
    newsSearch: newsSearch,
  };
};

const setNewslistCarrousel = (newslistCarrousel: News[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWSLIST_CARROUSEL,
    newslistCarrousel: newslistCarrousel,
  };
};

const setNewslistHighlights = (newslistHighlights: News[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWSLIST_HIGHLIGHTS,
    newslistHighlights: newslistHighlights,
  };
};

const setlikeNews = (id: string, like: boolean, likes: number): NewsAction => {
  return {
    type: NewsTypesEnum.SET_LIKE_NEWS,
    idNews: id,
    like: like,
    likes: likes,
  };
};

const setCommentNews = (commentsCount: number, newComment): NewsAction => {
  return {
    type: NewsTypesEnum.SET_COMMENT_NEWS,
    commentsCount: commentsCount,
    newComment: newComment,
  };
};

const setSessionId = (sessionId: string): NewsAction => {
  return {
    type: NewsTypesEnum.SET_SESSION_ID,
    sessionId: sessionId,
  };
};

const setTabPosition = (tabPosition: TabPosition): NewsAction => {
  return {
    type: NewsTypesEnum.SET_TAB_POSITION,
    tabPosition: tabPosition,
  };
};

const fetchNewsFailure = (error: string): any => {
  return {
    type: NewsTypesEnum.FAILURE_NEWS,
    error: error,
  };
};

const resetNewsData = (): NewsAction => {
  return {
    type: NewsTypesEnum.RESET_NEWS,
  };
};

const setSocialStats = (idNews: string, social: socialStats): NewsAction => {
  return {
    type: NewsTypesEnum.SET_SOCIAL_NEWS,
    idNews: idNews,
    socialStats: social,
  };
};

const setCategories = (categories: NewsCategory[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_CATEGORIES_NEWS,
    categories: categories,
  };
};

const setLoadedCategories = (categories: NewsCategory[]): NewsAction => {
  return {
    type: NewsTypesEnum.SET_LOADED_CATEGORIES,
    categories: categories,
  };
};

const setNewsByCategory = (
  newsByCategories: newsByCategories[],
): NewsAction => {
  return {
    type: NewsTypesEnum.SET_NEWS_CATEGORIES,
    newsByCategories: newsByCategories,
  };
};

const getNewslist = (pageNews: number, displayLoading: boolean) => {
  return async (dispatch: any, state: any): Promise<any> => {
    displayLoading && dispatch(newsRequest());
    try {
      const newslist = await newsServices.getNewslist(
        state().news.sessionId,
        pageNews,
        state().app.localSettings.localLanguage,
      );

      if (!isEmpty(newslist.news)) {
        if (pageNews === 1) {
          dispatch(setNewslist(newslist.news));
          dispatch(getNewsSocialStats(newslist.news));
        } else {
          const news = _.union(state().news.newslist, newslist.news);
          dispatch(setNewslist(news));
          dispatch(getNewsSocialStats(news));
        }
        dispatch(setMoreNews(newslist.moreNews));
      }
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const selectNews = (
  id: string,
  backUrl: string = null,
  playVideo: boolean = false,
  origin_uuid: string = null,
  pathOrigin: string = '/news/detail',
) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(newsRequestDetail());
    try {
      dispatch(setNews(null, []));

      const news: News = await newsServices.getNewsById(
        id,
        state().news.sessionId,
        state().app.localSettings.localLanguage,
      );
      dispatch(setNews(news, []));

      history.replace('/news/detail', {
        backUrl,
        origin_uuid,
        playVideo: playVideo,
        pathOrigin,
      });

      const comments: Comment[] = await newsServices.getNewsComments(
        news.id,
        state().news.sessionId,
      );

      dispatch(setNews(news, comments));

      dispatch(getNewsSocialStats([news]));
    } catch (e) {
      if (e.status === 404) {
        dispatch(setNews(null, []));
        history.replace('/news/NoPermission', {
          backUrl,
          origin_uuid,
          type: 'news',
          pathOrigin,
        });
      } else {
        dispatch(fetchNewsFailure(e.message));
      }
    }
  };
};

const selectNotice = (
  id: string,
  backUrl: string = null,
  origin_uuid: string = null,
  pathOrigin: string = '/notification-detail',
) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(newsRequest());
    try {
      const notice = await newsServices.getNoticeById(
        id,
        state().news.sessionId,
        state().app.localSettings.localLanguage,
      );

      dispatch(setNotice(notice.data));
      history.replace('/notification-detail', {
        backUrl,
        origin_uuid,
        pathOrigin,
      });
    } catch (e) {
      if (e.status === 404) {
        history.replace('/news/NoPermission', {
          backUrl,
          origin_uuid,
          type: 'notice',
          pathOrigin,
        });
      } else {
        dispatch(fetchNewsFailure(e.message));
      }
    }
  };
};

const selectedTabPosition = (tab: string, position: number) => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(setTabPosition({ tab: tab, position: position }));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const likeNews = (id: string) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(newsRequest());
    try {
      const like: boolean = await newsServices.setLikeNews(
        id,
        state().news.sessionId,
      );
      const likes: number = (
        await newsServices.getNewsSocial(id, state().news.sessionId)
      ).likes;
      dispatch(setlikeNews(id, like, likes));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const commentNews = (id: string, newComment: Comment) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(newsRequest());
    try {
      const comments: number = (
        await newsServices.getNewsSocial(id, state().news.sessionId)
      ).comments;

      dispatch(setCommentNews(comments, newComment));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const createNewComment = (idNew: string, comment: string, title: string) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(newsRequest());
    try {
      const newComment = await newsServices.createNewComment(
        idNew,
        comment,
        state().news.sessionId,
        title,
      );

      dispatch(commentNews(idNew, newComment));
      dispatch(getNewsSocialStats([state().news.news]));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const createReplyComment = (
  idNew: string,
  comment: string,
  uniqueId: string,
  title: string,
) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(newsRequest());
    try {
      const newComment = await newsServices.createReplyComment(
        idNew,
        comment,
        uniqueId,
        state().news.sessionId,
        title,
      );

      dispatch(commentNews(idNew, newComment));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getSessionId = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(newsRequest());
    try {
      const analytics = await newsServices.getAnalyticCode();
      !isEmpty(analytics) && dispatch(setSessionId(analytics.sessionId));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getNewsCarrousel = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    isEmpty(state().news.newslistCarrousel) && dispatch(newsRequest());
    try {
      const topCarrousel = state().app.globalSettings.newsIndexCarousel;
      const newsCarrousel: News[] = await newsServices.getNewslistByPannel(
        state().news.sessionId,
        ['Noticias_inicio_carrusel'],
        topCarrousel,
        state().app.localSettings.localLanguage,
        state().app.globalSettings.numberOfDays,
      );
      dispatch(setNewslistCarrousel(newsCarrousel));
      dispatch(getNewsSocialStats(newsCarrousel));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getNewsSocialStats = newsData => {
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      newsData?.map(async newsData => {
        const socialStat = await newsServices.getNewsSocial(
          newsData.id,
          state().news.sessionId,
        );
        const socialActions = (
          await newsServices.getNewsUserActions(
            newsData.id,
            state().news.sessionId,
          )
        )?.socialActions;
        const social = {
          ...socialStat,
          like: socialActions?.like,
          idNews: newsData.id,
        };
        dispatch(setSocialStats(newsData.id, social));
      });
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getNewsOther = (displayLoading: boolean) => {
  return async (dispatch: any, state: any): Promise<any> => {
    displayLoading && dispatch(newsRequest());
    try {
      const topOtherNews = state().app.globalSettings.newsIndexOtherNews;

      const newsOther: News[] = await newsServices.getNewslistByPannel(
        state().news.sessionId,
        ['Noticias_inicio_otrasnoticias'],
        topOtherNews,
        state().app.localSettings.localLanguage,
        state().app.globalSettings.numberOfDays,
      );

      dispatch(setNewslistOther(newsOther));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getNewsHighlights = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    isEmpty(state().news.newslistHighlights) && dispatch(newsRequest());
    try {
      const topHighlights = state().app.globalSettings.newsIndexOutstandingNews;

      const newsHighlights: News[] = await newsServices.getNewslistByPannel(
        state().news.sessionId,
        ['Noticias_inicio_noticiasdestacadas'],
        topHighlights,
        state().app.localSettings.localLanguage,
        state().app.globalSettings.numberOfDays,
      );

      dispatch(setNewslistHighlights(newsHighlights));
      dispatch(getNewsSocialStats(newsHighlights));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getDashborardCarrouselNews = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    isEmpty(state().news.home.newslistCarrousel) && dispatch(newsRequest());
    try {
      const topCarrousel = _.max([
        state().app.globalSettings.dashboardNewsCarouselMobile,
        state().app.globalSettings.dashboardNewsCarouselDesktop,
      ]);

      const newsCarrouselHome: News[] = await newsServices.getNewslistByPannel(
        state().news.sessionId,
        ['Dashboard_ noticiascarrusel'],
        topCarrousel,
        state().app.localSettings.localLanguage,
        state().app.globalSettings.numberOfDays,
        true,
      );

      dispatch(setNewslistHomeCarrousel(newsCarrouselHome));
      dispatch(getNewsSocialStats(newsCarrouselHome));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getDashborardOtherNews = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    isEmpty(state().news.home.newslistOther) && dispatch(newsRequest());
    try {
      const topOtherNews = state().app.globalSettings.dashboardOtherNews;
      const newsOtherHome: News[] = await newsServices.getNewslistByPannel(
        state().news.sessionId,
        ['Dashboard_ otrasnoticias'],
        topOtherNews,
        state().app.localSettings.localLanguage,
        state().app.globalSettings.numberOfDays,
      );

      dispatch(setNewslistHomeOther(newsOtherHome));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getNewsSearch = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(newsRequest());
    try {
      const topSearch = state().app.globalSettings.searcherIndexNews;

      const newsSearch: News[] = await newsServices.getNewslistByPannel(
        state().news.sessionId,
        ['Buscador_inicio_noticias'],
        topSearch,
        state().app.localSettings.localLanguage,
        state().app.globalSettings.numberOfDays,
      );
      dispatch(setNewsSearch(newsSearch));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getCategories = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      const categories: { id: string; name: string }[] =
        await newsServices.getCategories(
          state().news.sessionId,
          state().app.localSettings.localLanguage,
          state().app.globalSettings.numberOfDays,
        );
      dispatch(setLoadedCategories([]));
      dispatch(setCategories(categories));

      categories.forEach(e => dispatch(getNewsByCategory(e.id)));
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

const getNewsByCategory = (category: string, firstPage: boolean = true) => {
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      const categoryFiltered =
        state().news.newsByCategories.find(e => e.category === category) ??
        null;
      const page =
        categoryFiltered && !firstPage ? categoryFiltered.page + 1 : 1;

      const newsByCategory = await newsServices.getNewsByCategory(
        state().news.sessionId,
        page,
        state().app.localSettings.localLanguage,
        category,
      );

      await dispatch(
        setLoadedCategories([...state().news.isLoadedCategories, category]),
      );

      if (!isEmpty(newsByCategory.news)) {
        dispatch(getNewsSocialStats(newsByCategory.news));
        if (page === 1) {
          dispatch(
            setNewsByCategory([
              ...state().news.newsByCategories.filter(
                e => e.category !== category,
              ),
              { category: category, page: page, ...newsByCategory },
            ]),
          );
        } else {
          const news = _.union(
            state().news.newsByCategories.find(e => e.category === category)
              .news,
            newsByCategory.news,
          );
          dispatch(
            setNewsByCategory([
              ...state().news.newsByCategories.filter(
                e => e.category !== category,
              ),
              {
                category: category,
                page: page,
                news: news,
                moreNews: newsByCategory.moreNews,
              },
            ]),
          );
        }
      }
    } catch (e) {
      dispatch(fetchNewsFailure(e.message));
    }
  };
};

export const NewsActions = {
  setNews,
  getNewslist,
  selectNews,
  likeNews,
  createNewComment,
  createReplyComment,
  getSessionId,
  getNewsSearch,
  selectedTabPosition,
  getNewsCarrousel,
  getNewsOther,
  getNewsHighlights,
  getDashborardCarrouselNews,
  getDashborardOtherNews,
  resetNewsData,
  selectNotice,
  getCategories,
  getNewsByCategory,
  newsRequest,
};
