import styles from './styles.module.scss';

export const actionTypes = {
  1: 'incident_call',
  2: 'incident_mail',
  3: 'incident_servicenowTIC',
  4: 'incident_gmao',
  5: 'incident_servicenowServices',
};

export const classes = {
  secondary: styles.secondary,
  tertiary: styles.tertiary,
  success: styles.success,
  warning: styles.warning,
  light: styles.light,
  parking: styles.parking,
  room: styles.room,
  desk: styles.desk,
  septenary: styles.septenary,
};
