import React, { useState, useEffect } from 'react';
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonContent,
  IonFooter,
  useIonViewDidEnter,
  IonLoading,
  IonSpinner,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { TopBar, Button, Toast, Snackbar, Alert } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { MultipleDaysCalendar } from '@acciona/ui-ionic-kit';
import { parkingActions } from '../../../../_redux/actions/parking.actions';
import { ParkingStore } from '../../../../_redux/models/parking.model';
import styles from './styles.module.scss';
import { AppStore } from '../../../../_redux/models/app.model';
import { EWeekday, groupDatesByMonth } from '../../../../utils/dateTime';
import {
  ReservationList,
  ReservationStore,
} from '../../../../_redux/models/reservation.model';
import { Legend } from '../../../../components/Base/Legend';
import { LegendItem } from '../../../../components/Base/LegendItem';
import { useHistory } from 'react-router';
import { reservationActions } from '../../../../_redux/actions';
import _ from 'lodash';
import dayjs from 'dayjs';

const getMinAndMaxReleaseDates = (
  spaceId: number | string,
  permanentParkings: ReservationList[],
) => {
  const minDate = new Date(`${dayjs().tz().format('YYYY-MM-DD')}T00:00:00.000`);

  const space = permanentParkings.find(
    parking => parking?.spaceId.toString() === spaceId.toString(),
  );

  const maxDate = space?.endDate
    ? new Date(`${space.endDate}T23:59:59.999`)
    : new Date(
        dayjs()
          .tz()
          .add(2, 'week')
          .weekday(EWeekday.saturday)
          .utc()
          .startOf('day')
          .toISOString(),
      );

  return { minDate, maxDate };
};

const CalendarRelease: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [releasedDates, setReleasedDates] = useState([]);
  const [reserveDates, setReserveDates] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastHomeOffice, setShowToastHomeOffice] = useState(false);
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  const [showCalendar, setshowCalendar] = useState<boolean>(false);

  const { error, isLoading } = useSelector(
    (state: ParkingStore) => state.parking,
  );

  const { reservedParking, msg, permanentParkings } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const reservedHomeOfficeDays = useSelector(
    (state: ReservationStore) => state.reservation.homeOfficeDays,
  );
  const isLoadingReservation = useSelector(
    (state: ReservationStore) => state.reservation.isLoading,
  );

  const { minDate, maxDate } = getMinAndMaxReleaseDates(
    reservedParking?.spaceId,
    permanentParkings,
  );

  const modifiers = {
    releasedDay: (date: any) =>
      reservedParking?.releasedDates?.includes(
        dayjs(date).format('YYYY-MM-DD'),
      ) &&
      !reserveDates?.some((selectedDate: any) =>
        dayjs(selectedDate).utc().isSame(dayjs(date), 'day'),
      ) &&
      !dayjs(date).isBefore(dayjs().startOf('day')),
    releasedSelected: (date: any) =>
      (!dayjs(date).isBefore(dayjs().startOf('day')) &&
        releasedDates?.some((selectedDate: any) =>
          dayjs(selectedDate).utc().isSame(dayjs(date), 'day'),
        )) ||
      (!dayjs(date).isBefore(dayjs().startOf('day')) &&
        reservedParking?.releasedDates?.includes(
          dayjs(date).format('YYYY-MM-DD'),
        ) &&
        !reserveDates?.some((selectedDate: any) =>
          dayjs(selectedDate).utc().isSame(dayjs(date), 'day'),
        )),
    reserveSelected: (date: any) =>
      reserveDates?.some(
        (selectedDate: any) =>
          dayjs(selectedDate).utc().isSame(dayjs(date), 'day') &&
          !dayjs(date).isBefore(dayjs().startOf('day')),
      ),
    homeOfficeReleased: (date: any) =>
      reservedHomeOfficeDays.some(
        (selectedDate: any) =>
          dayjs(selectedDate).utc().isSame(dayjs(date), 'day') &&
          !releasedDates?.some((selectedDate: any) =>
            dayjs(selectedDate).utc().isSame(dayjs(date), 'day'),
          ) &&
          !(
            reservedParking?.releasedDates?.includes(
              dayjs(date).format('YYYY-MM-DD'),
            ) &&
            !reserveDates?.some((selectedDate: any) =>
              dayjs(selectedDate).utc().isSame(dayjs(date), 'day'),
            )
          ),
      ) && !dayjs(date).isBefore(dayjs().startOf('day')),
    today: (date: any) =>
      dayjs(date).isToday() &&
      !reservedParking?.releasedDates?.includes(
        dayjs(date).format('YYYY-MM-DD'),
      ) &&
      !releasedDates?.some((selectedDate: any) =>
        dayjs(selectedDate).utc().isSame(dayjs(date), 'day'),
      ),
    disabled: (date: any) =>
      dayjs(date).day() === 6 ||
      dayjs(date).day() === 0 ||
      dayjs(date).isBefore(dayjs().startOf('day')),
  };

  const handleSelectDate = dates => {
    setReleasedDates(
      dates.filter(
        d =>
          !reservedParking?.releasedDates?.includes(
            dayjs(d).format('YYYY-MM-DD'),
          ),
      ),
    );

    setReserveDates(
      dates.filter(
        d =>
          reservedParking?.releasedDates?.includes(
            dayjs(d).format('YYYY-MM-DD'),
          ) && !dayjs(d).isToday(),
      ),
    );
  };

  const handleDismissToast = () => {
    setShowToast(false);
    setShowToastHomeOffice(false);
    dispatch(parkingActions.resetErrorParking());
  };

  const getMessageAlert = () => {
    const liberate = t('lbl_place_liberate', {
      parking: reservedParking.code,
      days: groupDatesByMonth(
        releasedDates.map(e => dayjs(e).format('YYYY-MM-DD')),
        localLanguage,
        true,
      ),
    });
    let reserve = t('lbl_place_unliberate_question', {
      parking: reservedParking.code,
      days: groupDatesByMonth(
        reserveDates.map(e => dayjs(e).format('YYYY-MM-DD')),
        localLanguage,
        true,
      ),
    });
    const proceed = t('lbl_proceed');
    if (!_.isEmpty(releasedDates)) {
      reserve = reserve.charAt(0).toLowerCase() + reserve.slice(1);
    }
    return `${!_.isEmpty(releasedDates) ? `${liberate} ` : ''}${
      !_.isEmpty(reserveDates) ? reserve : ''
    } ${proceed}`;
  };

  const dataLegend = [
    { type: 'releasedDays', text: t('released_days') },
    { type: 'homeOffice', text: t('lbl_homeOffice') },
  ];

  useIonViewDidEnter(() => {
    !showCalendar && setshowCalendar(true);
  });

  useEffect(() => {
    error && setShowToast(true);
  }, [error]);

  const handleCancel = () => {
    history.push('/spaces/parking/detail');
  };

  const releasePlaces = () => {
    dispatch(
      reservationActions.setPermanentParkingReleasedDays(
        parseInt(reservedParking.id),
        reservedParking.name,
        releasedDates.map(e => dayjs(e).format('YYYY-MM-DD')),
        reserveDates.map(e => dayjs(e).format('YYYY-MM-DD')),
        reservedParking.type,
        reservedParking.idSede,
      ),
    );
  };

  const modifiersClass = {
    releasedDay: styles.released,
    releasedSelected: styles.releasedSelected,
    reserveSelected: styles.reserveSelected,
    homeOffice: styles.calendarHomeOffice,
    homeOfficeReleased: styles.homeOfficeReleased,
    today: styles.calendarToday,
  };

  return (
    <IonPage>
      <IonHeader className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/parking/retype"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('calendar_title')}
        />
        {releasedDates?.length +
          reservedParking?.releasedDates?.length -
          reserveDates?.length >
          0 && (
          <Snackbar
            type="info"
            text={t('lbl_date_selected', {
              number:
                releasedDates.length +
                reservedParking?.releasedDates.length -
                reserveDates.length,
              multiple:
                releasedDates.length +
                  reservedParking?.releasedDates.length -
                  reserveDates.length >
                1
                  ? 's'
                  : '',
            })}
            align="center"
          />
        )}
      </IonHeader>
      <IonContent fullscreen>
        <Toast
          isOpen={showToast}
          onDidDismiss={handleDismissToast}
          message={error}
          position="bottom"
          type="error"
        ></Toast>
        <Toast
          isOpen={showToastHomeOffice}
          onDidDismiss={handleDismissToast}
          message={t('msg_homeoffice')}
          position="bottom"
          type="homeOffice"
        />
        <div className={styles.gridWeb}>
          <div className={styles.calendarGrid}>
            {showCalendar && (
              <MultipleDaysCalendar
                minimumDate={minDate}
                maximumDate={maxDate}
                locale={localLanguage}
                moreModifiers={modifiers}
                moreModifiersClassNames={modifiersClass}
                onClickBlockedDay={() =>
                  dispatch(
                    parkingActions.fetchParkingFailure(t('msg_blocked_day')),
                  )
                }
                selectedDate={handleSelectDate}
                homeOfficeReservationAvailable={true}
              />
            )}
          </div>
          <IonLoading
            isOpen={isLoading || isLoadingReservation}
            message={t('msg_loading')}
            duration={2000}
          />
        </div>
      </IonContent>
      <IonFooter>
        <Legend className={styles.centerLegend}>
          {dataLegend.map((item, idx) => {
            return <LegendItem key={idx} type={item.type} text={item.text} />;
          })}
        </Legend>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              onClick={handleCancel}
              disabled={isLoading || isLoadingReservation}
            >
              {t('lbl_cancel')}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => setShowAlert(true)}
              disabled={!(releasedDates.length || reserveDates.length)}
            >
              {t('lbl_modify')}{' '}
              {(isLoading || isLoadingReservation) && (
                <IonSpinner
                  slot="end"
                  className={styles.color_primary}
                  name="lines-small"
                />
              )}
            </Button>
          </div>
        </div>
      </IonFooter>
      <Alert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('hdr_place_liberate')}
        message={getMessageAlert()}
        buttons={[
          { text: 'No', role: 'cancel', handler: () => setShowAlert(false) },
          { text: t('lbl_affirmative'), handler: () => releasePlaces() },
        ]}
        mode="ios"
      ></Alert>
      {msg && (
        <Toast
          isOpen={!!msg}
          onDidDismiss={() =>
            dispatch(reservationActions.resetMsgReservation())
          }
          message={msg.description}
          position="bottom"
          type={msg.type}
        ></Toast>
      )}
    </IonPage>
  );
};

export default CalendarRelease;
