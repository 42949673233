import { IonItem } from '@ionic/react';
import styles from '../styles.module.scss';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RadioList } from '../../../../components/Base/RadioList';
import {
  formatTimeSlot,
  formatWeekCompleteDate,
  getSlotTimeDiff,
} from './helper';
import { IAvailableCanteenSlotsData } from '../../../../_redux/models/canteen.model';

interface IProps {
  canteenData: IAvailableCanteenSlotsData;
  selectedSlot: number;
  setSelectedSlot: (value: number) => void;
}

const CanteenOptions = ({
  canteenData,
  selectedSlot,
  setSelectedSlot,
}: IProps) => {
  const { t } = useTranslation();
  const [radioOptions, setRadioOptions] = useState([]);

  const dateDiff = getSlotTimeDiff(canteenData.reservationDate);

  const date = formatWeekCompleteDate(canteenData.reservationDate);

  useEffect(() => {
    setRadioOptions(
      canteenData.availableCanteenSlots.map(({ startHour, endHour }, i) => {
        return {
          name: formatTimeSlot(startHour, endHour, canteenData.campusTimeZone),
          value: i,
        };
      }),
    );
  }, [canteenData.availableCanteenSlots, canteenData.campusTimeZone]);

  if (canteenData.availableCanteenSlots.length === 0) {
    return (
      <div className={styles.canteenSingleMessage}>
        <span>
          <Trans
            i18nKey={
              dateDiff < 0
                ? 'msg_no_slot_canteen_next_day'
                : 'msg_no_slot_canteen_today'
            }
            values={{
              ...date,
            }}
          />
        </span>
      </div>
    );
  } else if (radioOptions.length === 1) {
    let message = (
      <span>
        <Trans
          i18nKey={
            canteenData.isTheOnlySlot
              ? 'msg_only_one_canteen_slot_in_sede'
              : 'msg_only_one_canteen_slot_available'
          }
          values={{
            from: radioOptions[0].name.split(' - ')[0],
            to: radioOptions[0].name.split(' - ')[1],
          }}
        />
      </span>
    );

    if (dateDiff !== 0) {
      if (canteenData.reservable) {
        message = (
          <span>
            <Trans
              i18nKey={
                canteenData.isTheOnlySlot
                  ? 'msg_canteen_book_next_day_date'
                  : 'msg_only_one_canteen_slot_next_day'
              }
              values={{
                from: radioOptions[0].name.split(' - ')[0],
                to: radioOptions[0].name.split(' - ')[1],
                ...date,
              }}
            ></Trans>
          </span>
        );
      } else {
        message = (
          <span>
            <Trans
              i18nKey={
                canteenData.isTheOnlySlot
                  ? 'msg_canteen_no_reservable_book_next_day_date'
                  : 'msg_only_one_canteen_slot_next_day'
              }
              values={{
                from: radioOptions[0].name.split(' - ')[0],
                to: radioOptions[0].name.split(' - ')[1],
                ...date,
              }}
            ></Trans>
          </span>
        );
      }
    }

    return <div className={styles.canteenSingleMessage}>{message}</div>;
  } else {
    let topMessage = t('select_canteen_slot');
    if (dateDiff !== 0 && !canteenData.reservable)
      topMessage = t('msg_canteen_closed');
    else if (dateDiff === -1) topMessage = t('msg_canteen_book_tomorrow');
    else if (dateDiff < -1) topMessage = t('msg_canteen_book_next_day');

    let dateMessage = `${t('today')} (${date.weekday} ${date.day} ${
      date.month
    } ${date.year})`;
    if (dateDiff === -1)
      dateMessage = `${t('tomorrow')} (${date.weekday} ${date.day} ${
        date.month
      } ${date.year})`;
    else if (dateDiff < -1)
      dateMessage = `${date.weekday} ${date.day} ${date.month} ${date.year}`;

    return (
      <div className={styles.canteenMain}>
        <span className={styles.canteenMessage}>{topMessage}</span>
        <div className={styles.canteenOptions}>
          <IonItem className={styles.optionsTitle}>{dateMessage}</IonItem>
          <RadioList
            options={radioOptions}
            selectedOption={selectedSlot}
            onChange={setSelectedSlot}
          />
        </div>
      </div>
    );
  }
};

export default CanteenOptions;
