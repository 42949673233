import muleSoftInstance from '../../utils/httpCommon';

const getSystemSettings = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v2/configuration/system`;
  const resp = await muleSoftInstance.get(path);
  return {
    ...resp.data,
    lockerEmployeeKey: parseInt(resp.data?.lockerEmployeeKey),
  };
};

const getLinkConfig = async (id: number): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_SERVICES}/link?linkId=${id}`;
    const resp = await muleSoftInstance.get(path);

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getWelcomeText = async (isPlatformCapacitor: boolean): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/configurationHome`;
    const resp = await muleSoftInstance.get(path);

    const responseData = resp?.data?.translations;

    if (!responseData) {
      return null;
    }

    const translations = {
      desktop: {},
      mobile: {},
    };

    responseData.forEach(item => {
      translations.desktop[item.language] = item.welcomeWeb;
      translations.mobile[item.language] = item.welcomePhone;
    });

    return isPlatformCapacitor ? translations.mobile : translations.desktop;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getDashboardImage = async (
  isPlatformCapacitor: boolean,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/configurationHomeImage`;
    const resp = await muleSoftInstance.get(path);

    const responseData = resp?.data?.images;

    if (!responseData) {
      return null;
    }

    const images = {
      desktop: {},
      mobile: {},
    };

    responseData.forEach(item => {
      if (Object.keys(images).includes(item.platform)) {
        images[item.platform][item.language] = item.imageUrl;
      }
    });

    return isPlatformCapacitor ? images.mobile : images.desktop;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const systemServices = {
  getSystemSettings,
  getLinkConfig,
  getWelcomeText,
  getDashboardImage,
};
