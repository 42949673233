import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { Alert, Button, Modal, Snackbar, Toast } from '@acciona/ui-ionic-kit';
import { vehicleActions } from '../../../../_redux/actions/vehicles.actions';
import { Props } from './types';
import { AuthStore } from '../../../../_redux/models/auth.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { VehicleStore } from '../../../../_redux/models/vehicles.model';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import TransportZoneSnackBar from '../components/TransportZoneSnackBar';
import { PLUGGABLE_VEHICLE_OPTION_ENABLED } from '../../../../utils/constants';
import {
  getRandomVehicleId,
  isEmpty,
  someRegexValidation,
} from '../../../../utils/functions';
import {
  isPoolerMotoUser,
  isPoolerMicrocarUser,
  getAvailableVehicleTypes,
  vehicleIsPluggable,
} from '../../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';
import VehicleTypeModal from './components/VehicleTypeModal';

export const NewVehicleModal: React.FC<Props> = ({
  showModal,
  onCloseWithSave,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    vehicles,
    isLoading,
    attributes: vehicleAttributes,
    configuration: { fields: fieldsBySede, transportZoneText, platesTypes },
  } = useSelector((state: VehicleStore) => state.vehicle);

  const { user } = useSelector((state: AuthStore) => state.auth);
  const { permissions } = useSelector((state: UserStore) => state.user.user);
  const [showAlertFavorite, setShowAlertFavorite] = useState(false);
  const [isOtherFavVeh, setIsAnotherFavVeh] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [inputData, setInputData] = useState<any>();
  const [isPluggable, setIsPluggable] = useState(false);

  const [showVehicleTypeModal, setShowVehicleTypeModal] = useState(false);

  useEffect(() => {
    dispatch(vehicleActions.getListAttributes());
  }, []);

  useEffect(() => {
    vehicles.find(e => e.favourite === true) && setIsAnotherFavVeh(true);
  }, [vehicles]);

  const vehicleTypes = useMemo(() => {
    return getAvailableVehicleTypes(vehicleAttributes?.types, permissions);
  }, [vehicleAttributes?.types, permissions]);

  useEffect(() => {
    if (vehicleTypes.length === 1)
      setIsPluggable(vehicleIsPluggable(vehicleTypes[0].typeId));
  }, [vehicleTypes]);

  useEffect(() => {
    setInputData({
      vehicleId: getRandomVehicleId(), // Temporal, Back-end generates it
      employeeId: user.owner,
      brand: null,
      color: null,
      type: vehicleTypes.length === 1 ? vehicleTypes[0].typeId : null,
      plate: null,
      model: null,
      transportZone: null,
      environmentalBadge: null,
      defaultVehicle: true,
      favourite: false,
      plugin: fieldsBySede.includes('plugin') ? false : null,
    });
  }, [showModal]);

  const handleAddVehicle = () => {
    const regex =
      platesTypes.find(p => p.typeId === inputData.type)?.regex || [];
    if (someRegexValidation(inputData.plate, regex)) {
      validateOtherFavVeh();
    } else {
      setShowToast(true);
    }
  };

  const validateOtherFavVeh = () => {
    const isFavouriteSelected = inputData.favourite === true;

    if (isFavouriteSelected) {
      isOtherFavVeh ? setShowAlertFavorite(true) : addVehicle();
    } else {
      addVehicle();
    }
  };

  const addVehicle = async () => {
    dispatch(vehicleActions.addNewVehicle(inputData));
    onCloseWithSave();
  };

  const handleShowVehicleTypeModal = () => {
    setShowVehicleTypeModal(true);
  };

  const addVehicleIsDisabled = useMemo(
    () =>
      isLoading ||
      (isEmpty(inputData?.model) && fieldsBySede.includes('model')) ||
      (isEmpty(inputData?.color) && fieldsBySede.includes('color')) ||
      (inputData?.type === null && fieldsBySede.includes('type')) ||
      isEmpty(inputData?.plate) ||
      (inputData?.environmentalBadge === null &&
        fieldsBySede.includes('environmentalBadge')) ||
      (inputData?.transportZone === null &&
        fieldsBySede.includes('transportZone')),
    [inputData, isLoading],
  );

  const [isMotoUser, isMicrocarUser] = useMemo(() => {
    return [isPoolerMotoUser(permissions), isPoolerMicrocarUser(permissions)];
  }, [permissions]);

  return (
    <Modal mode="ios" isOpen={showModal} onDidDismiss={() => onClose()}>
      <IonPage className={styles.safeModal}>
        <IonToolbar mode="ios">
          <IonButton
            slot="end"
            fill={'clear'}
            onClick={() => {
              onClose();
            }}
          >
            <IonLabel>{t('close_text')}</IonLabel>
          </IonButton>
          <IonTitle>{t('lbl_parking_planned_vehicle')}</IonTitle>
        </IonToolbar>
        {(isMicrocarUser || isMotoUser) && (
          <Snackbar
            type="warning"
            text={
              isMicrocarUser && isMotoUser
                ? t('snackbar_pooler_moto_and microcar')
                : isMotoUser
                ? t('snackbar_pooler_moto')
                : t('snackbar_pooler_microcar')
            }
            icon="icon icon-incident"
            align="left"
          />
        )}
        {fieldsBySede.includes('transportZone') && transportZoneText && (
          <TransportZoneSnackBar
            text={transportZoneText.text}
            link={transportZoneText.link}
          />
        )}
        <IonContent>
          <div className={styles.listModal}>
            <DetailList>
              {fieldsBySede.includes('brand') && (
                <DetailListItem
                  lines="inset"
                  titleLight={t('lbl_vehicle_brand')}
                  titleColor="primary"
                  inputPlaceholder="Ej: Volkswagen"
                  actionInputText={e =>
                    setInputData({
                      ...inputData,
                      brand: e.detail.value,
                    })
                  }
                />
              )}
              {fieldsBySede.includes('model') && (
                <DetailListItem
                  lines="inset"
                  titleLight={t('lbl_vehicle_model')}
                  titleColor="primary"
                  inputPlaceholder="Ej: Touareg"
                  actionInputText={e =>
                    setInputData({
                      ...inputData,
                      model: e.detail.value,
                    })
                  }
                />
              )}
              {fieldsBySede.includes('color') && (
                <DetailListItem
                  lines="inset"
                  titleLight={t('lbl_vehicle_color')}
                  titleColor="primary"
                  inputPlaceholder={t('lbl_color_example')}
                  actionInputText={e =>
                    setInputData({
                      ...inputData,
                      color: e.detail.value,
                    })
                  }
                />
              )}
              {fieldsBySede.includes('type') && (
                <>
                  {vehicleTypes.length === 1 && (
                    <DetailListItem
                      lines="inset"
                      titleLight={t('lbl_vehicle_type')}
                      titleColor="primary"
                      value={vehicleTypes[0].description}
                      readonly
                    />
                  )}

                  {vehicleTypes.length > 1 && (
                    <DetailListItem
                      lines="inset"
                      titleLight={t('lbl_vehicle_type')}
                      titleColor="primary"
                      onClick={handleShowVehicleTypeModal}
                      endTextSelect={
                        vehicleTypes.find(v => v.typeId === inputData?.type)
                          ?.description
                      }
                      endIcon={inputData?.type ? '' : 'icon icon-chevron-right'}
                    />
                  )}
                </>
              )}

              <DetailListItem
                lines="inset"
                titleLight={t('lbl_vehicle_plate')}
                titleColor="primary"
                inputPlaceholder={
                  platesTypes?.find(p => p.typeId === inputData?.type)
                    ?.placeholder || ' '
                }
                actionInputText={e =>
                  setInputData({
                    ...inputData,
                    plate: e.detail.value.toUpperCase(),
                  })
                }
              />
              {fieldsBySede.includes('environmentalBadge') && (
                <DetailListItem
                  lines="inset"
                  titleLight={t('lbl_eco_label')}
                  titleColor="primary"
                  endTextSelect={
                    <IonSelect
                      cancelText={t('cancel_text')}
                      okText={t('ok_text')}
                      onIonChange={e =>
                        setInputData({
                          ...inputData,
                          environmentalBadge: e.detail.value,
                        })
                      }
                    >
                      {vehicleAttributes &&
                        vehicleAttributes.environmentalBadges.map(badge => {
                          return (
                            <IonSelectOption
                              key={badge.badgeId}
                              value={badge.badgeId}
                            >
                              {badge.description}
                            </IonSelectOption>
                          );
                        })}
                    </IonSelect>
                  }
                />
              )}
              {fieldsBySede.includes('transportZone') && (
                <DetailListItem
                  lines="inset"
                  titleLight={t('lbl_transport_zone')}
                  titleColor="primary"
                  endTextSelect={
                    <IonSelect
                      cancelText={t('cancel_text')}
                      okText={t('ok_text')}
                      onIonChange={e =>
                        setInputData({
                          ...inputData,
                          transportZone: e.detail.value,
                        })
                      }
                    >
                      {vehicleAttributes &&
                        vehicleAttributes.transportZones.map(zone => {
                          return (
                            <IonSelectOption
                              key={zone.zoneId}
                              value={zone.zoneId}
                            >
                              {zone.description}
                            </IonSelectOption>
                          );
                        })}
                    </IonSelect>
                  }
                />
              )}

              {PLUGGABLE_VEHICLE_OPTION_ENABLED &&
                isPluggable &&
                fieldsBySede.includes('plugin') && (
                  <DetailListItem
                    lines="inset"
                    titleLight={t('electric_charge')}
                    titleColor="primary"
                    endText={
                      <IonToggle
                        mode="ios"
                        onIonChange={e => {
                          setInputData({
                            ...inputData,
                            plugin: e.detail.checked,
                          });
                        }}
                      />
                    }
                  />
                )}

              <DetailListItem
                lines="inset"
                titleLight={t('lbl_favorite_vehicle')}
                titleColor="primary"
                endText={
                  <IonToggle
                    mode="ios"
                    onIonChange={e => {
                      setInputData({
                        ...inputData,
                        favourite: e.detail.checked,
                      });
                    }}
                  />
                }
              />
            </DetailList>
          </div>
          <Alert
            isOpen={showAlertFavorite}
            onDidDismiss={() => setShowAlertFavorite(false)}
            message={t('lbl_new_favorite_vehicle')}
            buttons={[
              { text: 'No', role: 'cancel' },
              {
                text: t('lbl_affirmative'),
                handler: () => addVehicle(),
              },
            ]}
            mode="ios"
          ></Alert>
          <VehicleTypeModal
            showModal={showVehicleTypeModal}
            vehicleTypes={vehicleTypes}
            inputData={inputData}
            setInputData={setInputData}
            setIsPluggable={setIsPluggable}
            onClose={() => {
              setShowVehicleTypeModal(false);
            }}
          />
        </IonContent>
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              <Button
                className={styles.large_btn}
                onClick={handleAddVehicle}
                disabled={addVehicleIsDisabled}
              >
                {t('lbl_parking_planned_vehicle')}
                {isLoading && (
                  <IonSpinner
                    slot="end"
                    className={styles.color_dark}
                    name="lines-small"
                  />
                )}
              </Button>
            </div>
          </div>
        </IonFooter>
        <Toast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={t('lbl_plate_error')}
          position="bottom"
          type="error"
        />
      </IonPage>
    </Modal>
  );
};
