import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  isPlatform,
  IonButton,
  IonLoading,
  IonIcon,
} from '@ionic/react';
import {
  TopBar,
  Snackbar,
  Item,
  Icon,
  Toast,
  ActionSheet,
  Alert,
  Button,
} from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { reservationActions } from '../../../../_redux/actions/reservations.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import { reservationServices } from '../../../../_redux/services';
import {
  Host,
  Workroom,
  WorkroomStore,
} from '../../../../_redux/models/workroom.model';
import { isEmpty } from '../../../../utils/functions';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { AppStore } from '../../../../_redux/models/app.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { MapReservation } from '../../../../components/MapReservation/MapReservation';
import styles from './styles.module.scss';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import dayjs from 'dayjs';
import { SafeIonImage } from '../../../../components/Base/SafeIonImg/SafeIonImg';

const WorkroomReservationDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertHost, setShowAlertHost] = useState(false);
  const [disableCheckIn, setBtnDisableCheckIn] = useState(true);
  const [showTimeLeftSnack, setShowTimeLeftSnack] = useState(false);
  const [showExtendSnack, setShowExtendSnack] = useState(false);
  const [showErrTimeRoom, setShowErrTimeRoom] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const { districtName, sedesList } = useSelector(
    (state: UserStore) => state.user.user,
  );
  const { reservedWorkroom, isLoading, msg, error } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const hosts: Host[] = useSelector(
    (state: WorkroomStore) => state.workroom.hosts,
  );
  const {
    immediateAttentionPhone,
    maxTimeExtendRoom,
    maxTimeCheckInRoom,
    availabilityExtendRoomWhenLeftMinutes,
  } = useSelector((state: AppStore) => state.app.globalSettings);

  const { isFromConfirmation, handleClose } = useReservationsHistory();

  const sedeSpace = sedesList.find(e => e.id == reservedWorkroom?.idSede);

  useEffect(() => {
    if (msg !== null) setShowToast(true);
  }, [msg]);

  useEffect(() => {
    dispatch(workroomActions.getAvailableHosts());

    const backFunction = ev => {
      ev.detail.register(10, () => {
        history.replace('/dashboard/reservations');
      });
    };
    document.addEventListener('ionBackButton', backFunction);

    return function cleanup() {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);

  useEffect(() => {
    let timerCheckin;
    let timerTmLeft;

    if (reservedWorkroom != undefined && !isEmpty(reservedWorkroom)) {
      const diffMsCheckin = dayjs(reservedWorkroom.date.reservationDateFrom)
        .tz(sedeSpace?.campusTimeZone)
        .diff(dayjs().tz(sedeSpace?.campusTimeZone).add(5, 'minute'));
      const diffMsTimeLeft = dayjs(reservedWorkroom.date.reservationDateTo)
        .tz(sedeSpace?.campusTimeZone)
        .diff(
          dayjs()
            .tz(sedeSpace?.campusTimeZone)
            .add(availabilityExtendRoomWhenLeftMinutes, 'minute'),
        );

      diffMsCheckin < 0
        ? setBtnDisableCheckIn(false)
        : (timerCheckin = setTimeout(
            () => setBtnDisableCheckIn(false),
            diffMsCheckin > 2147483647 ? 2147483647 : diffMsCheckin,
          ));

      diffMsTimeLeft < 0
        ? setShowTimeLeftSnack(true)
        : (timerTmLeft = setTimeout(
            () => setShowTimeLeftSnack(true),
            diffMsTimeLeft > 2147483647 ? 2147483647 : diffMsTimeLeft,
          ));
    }

    return () => {
      clearTimeout(timerCheckin);
      clearTimeout(timerTmLeft);
    };
  }, [reservedWorkroom]);

  const handlePlaceFavorite = e => {
    setChecked(e.detail.checked);
  };

  const deleteWorkRoomReserve = async () => {
    dispatch(reservationActions.cancelWorkroomReservation(reservedWorkroom));
  };

  const deleteWorkRoomReserveAlert = () => {
    setShowAlert(true);
  };

  const deleteWorkRoomHostAlert = () => {
    setShowAlertHost(true);
  };

  const deleteWorkRoomHost = () => {
    dispatch(
      reservationActions.deleteReservationHost(
        reservedWorkroom.host,
        reservedWorkroom.date.reservationId,
      ),
    );
  };

  const handleCheckOut = () => {
    dispatch(
      workroomActions.checkOutWorkroom(reservedWorkroom.date.reservationId),
    );
  };

  const handleExtendReservation = async () => {
    dispatch(reservationActions.reservationRequest());
    try {
      const wr: Workroom = await reservationServices.extendReservation(
        reservedWorkroom.id,
        reservedWorkroom.date.reservationId,
        dayjs(reservedWorkroom.date.reservationDateTo)
          .tz(sedeSpace?.campusTimeZone)
          .utc()
          .toISOString(),
        dayjs(reservedWorkroom.date.reservationDateTo)
          .tz(sedeSpace?.campusTimeZone)
          .utc()
          .add(maxTimeExtendRoom, 'minute')
          .toISOString(),
      );
      dispatch(reservationActions.setReservedWorkroom(wr));
      setShowTimeLeftSnack(false);
      setShowExtendSnack(true);
    } catch (error) {
      dispatch(reservationActions.resetLoading());
      setShowTimeLeftSnack(false);
      setShowErrTimeRoom(true);
    }
  };
  const handleAddHost = () => {
    history.push('/workroom/hostsearch', {
      preReservation: false,
    });
  };

  const handleDismissToast = () => {
    setShowToast(null);
    dispatch(reservationActions.resetMsgReservation());
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.toolbarGrid} ion-no-border`}
      >
        <TopBar
          primaryActions={
            isFromConfirmation ? (
              <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
            ) : (
              <IonBackButton
                defaultHref="/dashboard/reservations"
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            )
          }
          title={t('detail_reservation')}
          endActions={
            reservedWorkroom?.date.reservationStatus !== 'Finished' && (
              <IonButton onClick={deleteWorkRoomReserveAlert}>
                {t('delete_text')}
              </IonButton>
            )
          }
        />
      </IonHeader>
      <IonContent fullscreen>
        {reservedWorkroom?.roomUseType === 'Interno' && (
          <div className={styles.warningSnackbar}>
            <Snackbar
              type="warning"
              text={t('lbl_internal_use_workroom', {
                time: maxTimeCheckInRoom,
              })}
              icon="icon icon-person-lock"
              align="left"
            />
          </div>
        )}
        {reservedWorkroom?.date.reservationStatus === 'Approved' && (
          <div className={styles.warningSnackbar}>
            <Snackbar
              type="warning"
              text={t('lbl_snack_reserved_workroom_warning', {
                time: maxTimeCheckInRoom,
              })}
              icon="icon icon-incident"
              align="left"
            />
          </div>
        )}
        {reservedWorkroom?.organizer && (
          <Snackbar
            type="info"
            text={t('msg_organizer_snackbar', {
              name: reservedWorkroom.organizer.fullName,
            })}
            icon="icon icon-info"
            align="left"
          />
        )}
        {showExtendSnack && (
          <Snackbar
            type="success"
            text={t('msg_succcess_snackbar_extend')}
            icon="icon icon-check"
            align="left"
          />
        )}
        {showTimeLeftSnack &&
          reservedWorkroom?.date.reservationStatus === 'InProgress' && (
            <Snackbar
              type="warning"
              text={t('msg_warning_snackbar_timeRoom', {
                time: availabilityExtendRoomWhenLeftMinutes,
              })}
              icon="icon icon-last-minute"
              align="left"
            />
          )}
        {showErrTimeRoom && (
          <Snackbar
            type="error"
            text={t('msg_err_snackbar_timeRoom', {
              time: maxTimeExtendRoom,
            })}
            icon="icon icon-close"
            align="left"
          />
        )}
        {reservedWorkroom && !isEmpty(reservedWorkroom) && (
          <div>
            <DetailCard
              id="map-detail-card"
              onClickFavorite={e => handlePlaceFavorite(e)}
              favorite={checked}
              name="Sala"
              subName={reservedWorkroom.descriptionType}
              type="workroom"
              icon="icon icon-room"
              title={`${t('workroom')} ${reservedWorkroom.name}`}
              endContent={
                reservedWorkroom?.roomUseType === 'Interno' && (
                  <IonIcon
                    className={`icon icon-person-lock ${styles.iconInternalUseRoom}`}
                  />
                )
              }
            ></DetailCard>
            <div className={styles.gridWeb}>
              <div className={styles.listGrid}>
                <DetailList>
                  {['Checked', 'InProgress'].includes(
                    reservedWorkroom.date.reservationStatus,
                  ) && (
                    <DetailListItem
                      lines="inset"
                      detail={false}
                      onClick={() => setOpenActionSheet(true)}
                      startIcon="icon icon-phone"
                      title={t('lbl_immediate_attention')}
                      endIcon="icon icon-chevron-right"
                      titleColor="secondary"
                      startIconColor="secondary"
                    />
                  )}
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-booking"
                    startIconColor="primary"
                    title={`${dayjs(reservedWorkroom.date.reservationDateFrom)
                      .tz(sedeSpace?.campusTimeZone)
                      .format('ddd DD MMM YYYY')} ${
                      reservedWorkroom.date.homeOffice
                        ? '- ' + t('lbl_homeOffice')
                        : ''
                    }`}
                    description={`${dayjs(
                      reservedWorkroom.date.reservationDateFrom,
                    )
                      .tz(sedeSpace?.campusTimeZone)
                      .format('HH:mm')} - ${dayjs(
                      reservedWorkroom.date.reservationDateTo,
                    )
                      .tz(sedeSpace?.campusTimeZone)
                      .add(1, 'seconds')
                      .format('HH:mm')}`}
                    footnote={
                      reservedWorkroom.date.homeOffice && (
                        <>
                          {t('lbl_reservation_homeOffice_1', {
                            date: `${dayjs(
                              reservedWorkroom.date.reservationDateFrom,
                            )
                              .tz(sedeSpace?.campusTimeZone)
                              .format('D MMM')}`,
                          })}{' '}
                          {<b>{t('lbl_homeOffice')}. </b>}
                          {t('lbl_reservation_wr_homeOffice')}
                        </>
                      )
                    }
                  />
                  {!isEmpty(hosts) && (
                    <DetailListItem
                      className={styles.itemList}
                      lines="inset"
                      detail={false}
                      disabled={!isEmpty(reservedWorkroom.host)}
                      onClick={handleAddHost}
                      startIcon="icon icon-host"
                      startIconColor="primary"
                      titleLight={t('lbl_host')}
                      endIcon="icon icon-chevron-right"
                    />
                  )}
                  {!isEmpty(reservedWorkroom.host) && (
                    <DetailListItem
                      startIcon="icon icon-client"
                      title={reservedWorkroom.host.fullName}
                      description={reservedWorkroom.host.function}
                      endIconAction={`icon icon-delete`}
                      actionEndIcon={deleteWorkRoomHostAlert}
                    />
                  )}
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-occupation"
                    startIconColor="primary"
                    title={t('lbl_capacity')}
                    description={t('lbl_min_members', {
                      qty: reservedWorkroom.capacity,
                    })}
                  />
                  <div className={styles.separatorLine} />
                  <Item lines="full">
                    <span className={styles.headline}>
                      {t('lbl_information_workroom')}
                    </span>
                  </Item>
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-room"
                    startIconColor="primary"
                    title={`${t('workroom')} ${reservedWorkroom.name}`}
                    description={reservedWorkroom.descriptionType}
                  />
                  {reservedWorkroom.imageURL && (
                    <Item lines="none" className={styles.imgContainer}>
                      <SafeIonImage
                        className={styles.imgStyle}
                        src={reservedWorkroom.imageURL}
                        fallback="/assets/images/workroom1.jpg"
                      />
                    </Item>
                  )}
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-explore"
                    startIconColor="primary"
                    title={t('lbl_head_office')}
                    description={reservedWorkroom?.descriptionSede}
                  />
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-campus"
                    startIconColor="primary"
                    title={`${t('building_filter_workroom')} ${
                      reservedWorkroom.location?.buildingName
                    } , ${reservedWorkroom.location?.floorName}`}
                    description={
                      districtName === reservedWorkroom.location?.district ? (
                        <div className={styles.locatdivs}>
                          <Icon
                            color="primary"
                            className={`icon icon-neighbourhood ${styles.iconNeighborhood}`}
                          />
                          {t('lbl_your_district')}
                        </div>
                      ) : (
                        `${t('lbl_district')}: ${
                          reservedWorkroom.location?.district
                        }`
                      )
                    }
                  />
                </DetailList>
                {reservedWorkroom.mapImage && (
                  <MapReservation
                    x={reservedWorkroom.x}
                    y={reservedWorkroom.y}
                    mapImage={reservedWorkroom.mapImage}
                  />
                )}
              </div>
              <Alert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('header_delete_workroom_reserve')}
                message={t('msg_delete_workroom_reserve', {
                  workstation: reservedWorkroom.name,
                  days: `${dayjs(reservedWorkroom.date.reservationDateFrom)
                    .tz(sedeSpace?.campusTimeZone)
                    .format(
                      `D [${t('lbl_of_date')}] MMMM [${t('lbl_of_date')}] YYYY`,
                    )} ${t('lbl_from_date')} ${dayjs(
                    reservedWorkroom.date.reservationDateFrom,
                  )
                    .tz(sedeSpace?.campusTimeZone)
                    .format('HH:mm')} ${t('lbl_to_date')} ${dayjs(
                    reservedWorkroom.date.reservationDateTo,
                  )
                    .tz(sedeSpace?.campusTimeZone)
                    .add(1, 'minutes')
                    .format('HH:mm')}`,
                })}
                buttons={[
                  { text: 'No', role: 'cancel' },
                  {
                    text: t('lbl_affirmative'),
                    handler: () => deleteWorkRoomReserve(),
                  },
                ]}
                mode="ios"
              />
              <Alert
                isOpen={showAlertHost}
                onDidDismiss={() => setShowAlertHost(false)}
                header={t('header_delete_host_reserve')}
                message={t(
                  'header_delete_host_reserve_text',
                  reservedWorkroom.host && {
                    name: reservedWorkroom.host.fullName,
                  },
                )}
                buttons={[
                  { text: 'No', role: 'cancel' },
                  {
                    text: t('lbl_affirmative'),
                    handler: () => deleteWorkRoomHost(),
                  },
                ]}
                mode="ios"
              />
            </div>
          </div>
        )}
        {msg && (
          <Toast
            isOpen={showToast}
            onDidDismiss={handleDismissToast}
            message={msg.description}
            position="bottom"
            type={msg.type}
          />
        )}
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={2000}
        />
        <ActionSheet
          cssClass={styles.actionsheetSty}
          isOpen={openActionSheet}
          mode="ios"
          onDidDismiss={() => setOpenActionSheet(false)}
          buttons={[
            {
              cssClass: styles.btnActionSheetOptions,
              text: t('call_to', { number: immediateAttentionPhone }),
              handler: () => {
                window.open(`tel:${immediateAttentionPhone}`, '_self');
                setOpenActionSheet(false);
              },
            },
            {
              cssClass: styles.btnActionSheetCancel,
              text: `${t('cancel_text')}`,
              role: 'cancel',
              handler: () => {
                setOpenActionSheet(false);
              },
            },
          ]}
        />
      </IonContent>
      {isPlatform('capacitor') && (
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              {['Approved'].includes(
                reservedWorkroom?.date.reservationStatus,
              ) && (
                <Button
                  onClick={() => history.push('/workroom/checkin')}
                  disabled={disableCheckIn}
                >
                  {t('lbl_button_checkin')}
                </Button>
              )}
            </div>
            <div>
              {['InProgress'].includes(
                reservedWorkroom?.date.reservationStatus,
              ) && (
                <Button onClick={handleCheckOut}>
                  {t('lbl_button_checkout')}
                </Button>
              )}
            </div>
          </div>
          {showTimeLeftSnack &&
            ['InProgress'].includes(
              reservedWorkroom?.date.reservationStatus,
            ) && (
              <div className={styles.btnGroupWeb}>
                <div>
                  <Button color="lighter" onClick={handleExtendReservation}>
                    {t('lbl_button_addTime', { time: maxTimeExtendRoom })}
                  </Button>
                </div>
              </div>
            )}
        </IonFooter>
      )}
    </IonPage>
  );
};

export default WorkroomReservationDetail;
