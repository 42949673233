import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppStore, Locale } from '../../../_redux/models/app.model';
import { LegendItem } from '../LegendItem';
import { isEmpty } from '../../../utils/functions';
import { Modal } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { IFloorData, getBuildingFloorLegendData } from './helpers';

type Props = {
  isOpen: boolean;
  onDidDismiss: () => void;
  cssClass?: string;
  building: { name: string; id: string };
  floor: { name: string; id: string };
};

export const MapLegendModal: React.FC<Props> = ({
  building,
  floor,
  cssClass,
  onDidDismiss,
  isOpen,
}) => {
  const { t } = useTranslation();
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  const [legendData, setLegendData] = useState<IFloorData>({
    spaces: [],
    departments: [],
  });

  const updateLegendData = async (
    building: { name: string; id: string },
    floor: { name: string; id: string },
    language: Locale,
  ) => {
    setLegendData(await getBuildingFloorLegendData(building, floor, language));
  };

  useEffect(() => {
    updateLegendData(building, floor, localLanguage);
  }, [localLanguage, building, floor]);

  return (
    <Modal
      id="map-legend-modal"
      isOpen={isOpen}
      animated={false}
      cssClass={`${styles.modal} ${cssClass} ${
        isEmpty(legendData.departments) ? styles.withoutDepartments : ''
      }`}
      onDidDismiss={onDidDismiss}
    >
      <div className={styles.header}>{t('lbl_legend')}</div>
      <div className={styles.body}>
        <div className={styles.block}>
          {legendData.spaces.map(item => {
            return (
              <LegendItem
                key={item.id}
                type={item.type}
                text={item.description}
                className={styles.legendItem}
                circleClassName={styles.circle}
                captionClassName={styles.caption}
              />
            );
          })}
        </div>
        {!isEmpty(legendData.departments) && (
          <>
            <div className={styles.separator}></div>
            <div className={styles.block}>
              {legendData.departments.map(item => {
                return (
                  <p
                    key={item.id || item.abbr}
                    className={styles.departmentsList}
                  >
                    <span>{`${item.abbr} - `}</span>
                    {item.description}
                  </p>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className={styles.footer}>
        <button onClick={onDidDismiss}>{t('close_text')}</button>
      </div>
    </Modal>
  );
};
