import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBadge,
} from '@ionic/react';
import { Icon } from '@acciona/ui-ionic-kit';
import { NotificationsStore } from '../../../_redux/models/notifications.model';
import Can from '../../../components/Can/Can';
import { history } from '../../../_helpers/history';
import { appActions } from '../../../_redux/actions';
import { AppStore } from '../../../_redux/models/app.model';
import styles from './home.module.scss';

export const HomeHeader = ({ backgroundClass }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notificationsHome } = useSelector(
    (store: NotificationsStore) => store.notifications,
  );

  const { offlineNetwork, offlineServices } = useSelector(
    (state: AppStore) => state.app,
  );

  const offlineMode = offlineNetwork || offlineServices;

  const endButtons = useMemo(
    () => [
      {
        id: 1,
        functionality: 'search',
        ariaLabel: t('lbl_search_page'),
        className: styles.paddingBtn,
        icon: 'search',
        href: '/profile/search',
        accessAllowedInOfflineMode: false,
      },
      {
        id: 2,
        functionality: 'notifications',
        ariaLabel: t('lbl_notifications'),
        className: styles.paddingBtn,
        icon: 'notification',
        href: '/notifications',
        accessAllowedInOfflineMode: false,
        content:
          notificationsHome.length > 0 ? (
            <IonBadge className={styles.badgeNotification} color="secondary">
              {notificationsHome.length}
            </IonBadge>
          ) : null,
      },
      {
        id: 3,
        functionality: 'public',
        ariaLabel: t('profile'),
        icon: 'client',
        href: '/profile/home',
        accessAllowedInOfflineMode: true,
      },
    ],
    [notificationsHome],
  );

  return (
    <IonHeader
      className={`ion-no-border ${
        backgroundClass ? styles.headerWidthScroll : styles.headerWidth
      }`}
      slot="fixed"
    >
      <IonToolbar
        id="toolbar"
        className={`${
          backgroundClass
            ? styles.toolbarTopBtnsScrolled
            : styles.toolbarTopBtns
        }`}
      >
        <div className={`${styles.groupBtns} ${styles.gridHeader}`}>
          <IonButtons
            slot="start"
            className={styles.headerBtnFirst}
          ></IonButtons>
          <IonButtons slot="end" className={styles.headerEndBtns}>
            {endButtons.map(btn => (
              <Can
                key={btn.id}
                functionality={offlineMode ? 'offline' : btn.functionality}
              >
                <IonButton
                  fill="clear"
                  onClick={() => {
                    if (!offlineMode || btn.accessAllowedInOfflineMode) {
                      history.push(btn.href);
                    } else {
                      dispatch(appActions.setOfflineToastIsOpen(true));
                    }
                  }}
                  className={`${styles.topBtnSty} ${btn?.className ?? ''}`}
                >
                  {btn?.content ?? null}
                  <Icon
                    aria-label={btn.ariaLabel}
                    className={`icon icon-${btn.icon}  ${
                      backgroundClass
                        ? styles.topBtnColorScrolled
                        : styles.topBtnColor
                    }`}
                  />
                </IonButton>
              </Can>
            ))}
          </IonButtons>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};
