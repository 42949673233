const saveCookiePreference = preference => {
  localStorage.setItem('GaCookiesAccepted', preference);
};

const getCookiePreference = () => {
  return localStorage.getItem('GaCookiesAccepted');
};

const removeGaCookies = async () => {
  localStorage.removeItem('GaCookiesAccepted');
};

export const cookiesServices = {
  saveCookiePreference,
  getCookiePreference,
  removeGaCookies,
};
