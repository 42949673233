import { TopBar } from '@acciona/ui-ionic-kit';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Can from '../../../components/Can/Can';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { isEmpty, showPolicies } from '../../../utils/functions';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import { AppStore } from '../../../_redux/models/app.model';
import { appActions } from '../../../_redux/actions';
import styles from './styles.module.scss';
import { ParkingStore } from '../../../_redux/models/parking.model';
import { PARKING_PERMISSIONS } from '../../../utils/constants';

const ParkingMenu: React.FC = () => {
  const { t } = useTranslation();

  const { hasReservableDays: hasReservablePKDays } = useSelector(
    (state: ParkingStore) => state.parking,
  );

  const { permanentParkings } = useSelector(
    (store: ReservationStore) => store.reservation,
  );

  const {
    configurations: { policies },
  } = useSelector((state: ParkingStore) => state.parking);

  const { offlineServices, offlineNetwork, globalSettings } = useSelector(
    (state: AppStore) => state.app,
  );

  const offlineMode = offlineServices || offlineNetwork;

  const dispatch = useDispatch();

  const menuItems = [
    {
      id: 1,
      text: t('lbl_personal_vehicle_passengers'),
      routerLink: '/profile/user/vehicles-passengers',
      permissionCode: [
        PARKING_PERMISSIONS.parking,
        PARKING_PERMISSIONS.pooler_moto,
        PARKING_PERMISSIONS.pooler_microcar,
      ],
      show:
        isEmpty(permanentParkings) ||
        (!isEmpty(permanentParkings) && hasReservablePKDays),
      accessAllowedInOfflineMode: false,
    },
    {
      id: 2,
      text: t('lbl_personal_parking_preferences'),
      routerLink: '/profile/user/parking-preferences',
      permissionCode: [
        PARKING_PERMISSIONS.parking,
        PARKING_PERMISSIONS.pooler_moto,
        PARKING_PERMISSIONS.pooler_microcar,
      ],
      show:
        isEmpty(permanentParkings) ||
        (!isEmpty(permanentParkings) && hasReservablePKDays),
      accessAllowedInOfflineMode: false,
    },
    {
      id: 3,
      text: t('lbl_personal_parking_cutt-off'),
      routerLink: '/profile/user/parking-cut-off',
      permissionCode: [
        PARKING_PERMISSIONS.parking,
        PARKING_PERMISSIONS.pooler_moto,
        PARKING_PERMISSIONS.pooler_microcar,
      ],
      show: globalSettings.plannedReservation,
      accessAllowedInOfflineMode: false,
    },
  ];

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_personal_area_parking')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {menuItems
                .filter(menuItem => menuItem.show)
                .map(item => {
                  if (item.id === 2) {
                    if (showPolicies(policies)) {
                      return (
                        <Can key={item.id} functionality={item.permissionCode}>
                          <DetailListItem
                            className={styles.profileList}
                            mode="ios"
                            lines="inset"
                            type="button"
                            titleLight={item.text}
                            endIcon="icon icon-chevron-right"
                            {...(!offlineMode || item.accessAllowedInOfflineMode
                              ? { routerLink: item.routerLink }
                              : {
                                  onClick: () =>
                                    dispatch(
                                      appActions.setOfflineToastIsOpen(true),
                                    ),
                                })}
                          />
                        </Can>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <Can key={item.id} functionality={item.permissionCode}>
                        <DetailListItem
                          className={styles.profileList}
                          mode="ios"
                          lines="inset"
                          type="button"
                          titleLight={item.text}
                          endIcon="icon icon-chevron-right"
                          {...(!offlineMode || item.accessAllowedInOfflineMode
                            ? { routerLink: item.routerLink }
                            : {
                                onClick: () =>
                                  dispatch(
                                    appActions.setOfflineToastIsOpen(true),
                                  ),
                              })}
                        />
                      </Can>
                    );
                  }
                })}
            </DetailList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ParkingMenu;
