import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAvailableCanteenSlots,
  reserveCanteen,
} from '../../../_redux/services/canteen/canteen.services';
import { history } from '../../../_helpers/history';
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonPage,
} from '@ionic/react';

import styles from './styles.module.scss';
import { Button, Toast, TopBar } from '@acciona/ui-ionic-kit';
import { BackButton } from '../../../components/Base/BackButton';
import CanteenOptions from './components/CanteenOptions';
import { EToastMessages, checkInTime } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { reservationActions } from '../../../_redux/actions';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import { IAvailableCanteenSlotsData } from '../../../_redux/models/canteen.model';

const CanteenCalendar = () => {
  const { t } = useTranslation();
  const [waitingForRequest, setWaitingForRequest] = useState(false);
  const [canteenData, setCanteenData] = useState<
    IAvailableCanteenSlotsData | undefined
  >(undefined);
  const dispatch = useDispatch();
  const reservations = useSelector(
    (state: ReservationStore) => state.reservation.reservations,
  );

  const [toastData, setToastData] = useState<{
    show: boolean;
    message: EToastMessages | string;
  }>({
    show: false,
    message: EToastMessages.slotEnded,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [selectedSlot, setSelectedSlot] = useState<number>(0);

  const getData = async () => {
    setIsLoading(true);
    setCanteenData(undefined);
    const data = await getAvailableCanteenSlots();
    setIsLoading(false);
    if (!data) {
      return;
    }

    setSelectedSlot(0);
    setCanteenData({
      ...data,
    });
  };

  const handleReservationSuccess = (id: string) => {
    dispatch(reservationActions.getReservationCanteen(id, false));
    dispatch(reservationActions.getReservationsAndPermanentSpaces());
    history.replace('/confirmation', {
      title: 'successful_canteen_reservation',
      msg: 'canteen_successful_reservation_info',
      router: `/spaces/canteen/reservationdetail`,
      parmsRouter: { prevRouterConfirm: true },
    });
  };

  const handleSlotPassed = () => {
    setToastData(old => ({
      ...old,
      show: true,
      message: EToastMessages.slotEnded,
    }));
    getData();
  };

  const handlePreviousReservation = () => {
    setToastData(old => ({
      ...old,
      show: true,
      message: EToastMessages.previousReservation,
    }));
  };

  const handleSlotUnavailable = () => {
    setToastData(old => ({
      ...old,
      show: true,
      message: EToastMessages.slotUnavailable,
    }));
    getData();
  };

  const handleUnknownError = () => {
    setToastData(old => ({
      ...old,
      show: true,
      message: EToastMessages.unknownError,
    }));
  };

  const handleResponseStatus = (response: {
    status: number;
    data?: any;
  }): void => {
    if (response.status === 201) {
      handleReservationSuccess(response.data[0].id);
    } else if (response.status === 403) {
      handleSlotPassed();
    } else if (response.status === 409) {
      const prevReservation = reservations.find(r => r.useType === 'Canteen');
      if (prevReservation) {
        handlePreviousReservation();
      } else {
        handleSlotUnavailable();
      }
    } else {
      handleUnknownError();
    }
  };

  const handleSubmit = async () => {
    setWaitingForRequest(true);
    const data = canteenData.availableCanteenSlots[selectedSlot];

    if (!checkInTime(data.endHour, canteenData.validSlotMinusMinutes)) {
      setWaitingForRequest(false);
      return handleSlotPassed();
    }
    const response = await reserveCanteen(data);
    handleResponseStatus(response);
    setWaitingForRequest(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={<BackButton replace router="/dashboard/actions" />}
          title={t('canteen_title')}
        />
      </IonHeader>
      <IonContent>
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={0}
        />
        {canteenData && (
          <div className={styles.listGrid}>
            <CanteenOptions
              canteenData={canteenData}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
            />
          </div>
        )}
        {!isLoading && !canteenData && (
          <div className={styles.canteenSingleMessage}>
            {t(EToastMessages.unknownError)}
          </div>
        )}
      </IonContent>
      {canteenData && (
        <IonFooter className="ion-no-border">
          <div className={styles.btnGroupWeb}>
            <div>
              <Button
                onClick={handleSubmit}
                disabled={
                  canteenData.availableCanteenSlots.length === 0 ||
                  waitingForRequest
                }
              >
                {t('btn_canteen_book')}
              </Button>
            </div>
          </div>
        </IonFooter>
      )}

      <Toast
        isOpen={toastData.show}
        onDidDismiss={() => setToastData(old => ({ ...old, show: false }))}
        message={t(toastData.message)}
        position="bottom"
        type="error"
      />
    </IonPage>
  );
};
export default CanteenCalendar;
