import muleSoftInstance from '../../utils/httpCommon';

const getNotifications = async (lang: string, sessionId: string) => {
  try {
    const path = `${process.env.REACT_APP_API_NOTIFICATIONS}/alertMailBox`;

    const resp = await muleSoftInstance.get(path, {
      headers: {
        'accept-language': lang === 'es' ? 'es-ES' : 'en-US',
        'sessionId': sessionId,
      },
    });

    return resp.data.notifications;
  } catch (error) {
    throw new Error(error.message);
  }
};

const readNotification = async (id: string) => {
  try {
    const path = `${process.env.REACT_APP_API_NOTIFICATIONS}/alertMailBox/read?messageId=${id}`;

    const resp = await muleSoftInstance.post(path, {});
    return resp;
  } catch (error) {
    throw new Error(error.message);
  }
};

const readAllNotification = async () => {
  try {
    const path = `${process.env.REACT_APP_API_NOTIFICATIONS}/alertMailBox/readAll`;

    const resp = await muleSoftInstance.post(path, {});
    return resp;
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteNotification = async (id: string) => {
  try {
    const path = `${process.env.REACT_APP_API_NOTIFICATIONS}/alertMailBox?messageId=${id}`;

    const resp = await muleSoftInstance.delete(path);
    return resp;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const AlertMailBoxServices = {
  deleteNotification,
  readAllNotification,
  readNotification,
  getNotifications,
};
