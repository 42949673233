import { useEffect } from 'react';
import { Network } from '@capacitor/network';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStore } from '../_redux/models/auth.model';
import { appActions } from '../_redux/actions';
import { userActions } from '../_redux/actions';
import { AppStore } from '../_redux/models/app.model';

export const useNetworkStatus = () => {
  const { isAuth } = useSelector((state: AuthStore) => state.auth);
  const { offlineNetwork } = useSelector((state: AppStore) => state.app);

  const dispatch = useDispatch();
  useEffect(() => {
    Network.getStatus().then(status =>
      dispatch(appActions.setOfflineNetwork(!status.connected)),
    );
    Network.addListener('networkStatusChange', status => {
      dispatch(appActions.setOfflineNetwork(!status.connected));
      if (status.connected && isAuth && offlineNetwork) {
        dispatch(userActions.getUserConfig());
      }
    });
    return () => {
      Network.removeAllListeners();
    };
  }, [isAuth]);
};
