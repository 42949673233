import { ActionSheet, Alert, Menu, Toast } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../_redux/models/app.model';
import { VehicleStore } from '../../../_redux/models/vehicles.model';
import { useTranslation } from 'react-i18next';
import { Props } from './types';
import { menuController } from '@ionic/core/components';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Can from '../../Can/Can';
import { DetailList } from '../DetailList';
import { DetailListItem } from '../DetailListItem';
import {
  NotificationsActions,
  parkingActions,
  reservationActions,
  userActions,
  workroomActions,
  workstationActions,
} from '../../../_redux/actions';
import { UserStore } from '../../../_redux/models/user.model';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import {
  getCanteenReservActiveModalDescription,
  getCanteenReservationsSorted,
  isCanteenReservationActive,
  isEmpty,
} from '../../../utils/functions';
import { getAvailableVehicles } from '../../../utils/vehiclesAndParking';
import _ from 'lodash';
import { WorkstationStore } from '../../../_redux/models/workstation.model';
import { ParkingStore } from '../../../_redux/models/parking.model';
import { getParkingRoute } from '../../../pages/Spaces/helpers';
import { IonAlert, IonLoading } from '@ionic/react';
import { useCanReservationDeskForToday } from '../../../hooks/useCanReservationDeskForToday';
import dayjs from 'dayjs';
import { PARKING_PERMISSIONS } from '../../../utils/constants';

const ActionsMenuDesktop: React.FC<Props> = props => {
  const { canReservationDeskForToday, isDeskReservationForTodayActive } =
    useCanReservationDeskForToday();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [canteenModalDescValues, setCanteenModalDescValues] = useState({
    date: '',
    from: '',
    to: '',
  });
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const [canteenActive, setCanteenActive] = useState(null);
  const [openCanteenReservActiveModal, setOpenCanteenReservActiveModal] =
    useState(false);
  const [showAlert, setShowAlert] = useState({ state: false, msg: null });
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const raffle = useSelector((state: ParkingStore) => state.parking.raffle);
  const [showRaffle, setShowRaffle] = useState<boolean>(false);
  const {
    emergencyPhone,
    plannedReservation,
    lastMinuteReservation,
    canteenReservationLastHour,
    temporalVisitsDistrict,
    dayParkingPlannedTurningPoint,
  } = useSelector((state: AppStore) => state.app.globalSettings);
  const { vehicles } = useSelector((state: VehicleStore) => state.vehicle);
  const {
    permanentParkings,
    permanentDesks,
    reservations,
    configurationDeskReservationForToday,
  } = useSelector((store: ReservationStore) => store.reservation);
  const { user, error } = useSelector((state: UserStore) => state.user);
  const {
    district,
    hasValidDistrict,
    defaultSede,
    permissions,
    building,
    floor,
    deskReservationConfiguration,
  } = user;

  const {
    hasReservableDays: hasReservableWorkstationDays,
    configurations,
    isLoadingWorkstations,
    error: errorWorkstations,
  } = useSelector((state: WorkstationStore) => state.workstation);

  const { hasReservableDays: hasReservableParkingDays, plannedReservableDays } =
    useSelector((state: ParkingStore) => state.parking);

  const { sedesList, userIsRegisteredInDatabase } = useSelector(
    (state: UserStore) => state.user.user,
  );

  const thereAreAvailableVehicles = useMemo(
    () => !isEmpty(getAvailableVehicles(vehicles, permissions)),
    [vehicles, permissions],
  );

  const data = useMemo(
    () => [
      {
        text: t(
          isDeskReservationForTodayActive
            ? 'lbl_action_desk_forToday'
            : 'lbl_action_res_position',
        ),
        routerLink: '/workstation/calendar',
        icon: 'icon icon-seat',
        permissionCode: 'desks',
        show:
          isEmpty(permanentDesks.filter(p => p.idSede === defaultSede.id)) ||
          hasReservableWorkstationDays,
        showLine: true,
      },
      {
        text: t('lbl_action_res_parking'),
        routerLink: getParkingRoute(
          thereAreAvailableVehicles,
          plannedReservation,
          lastMinuteReservation,
        ),
        icon: 'icon icon-parking',
        permissionCode: [
          PARKING_PERMISSIONS.parking,
          PARKING_PERMISSIONS.pooler_moto,
          PARKING_PERMISSIONS.pooler_microcar,
        ],
        show:
          (plannedReservation || lastMinuteReservation) &&
          (isEmpty(
            permanentParkings.filter(p => p.idSede === defaultSede.id),
          ) ||
            hasReservableParkingDays),
        showLine: true,
      },
      {
        text: t('lbl_actions_res_restaurant'),
        icon: 'icon icon-plate',
        permissionCode: ['reservation_restaturant'],
        routerLink: '/canteen',
        show: true,
        showLine: true,
      },
      {
        text: t('lbl_action_res_room'),
        routerLink: '/workroom/filters',
        icon: 'icon icon-room',
        permissionCode: 'room',
        show: true,
        showLine:
          !permissions.includes('emergency') ||
          (!permissions.includes('incidents') &&
            !permissions.includes('emergency')) ||
          permissions.includes('incidents'),
      },
    ],
    [
      permanentParkings,
      permanentDesks,
      hasReservableParkingDays,
      thereAreAvailableVehicles,
      defaultSede.id,
      configurationDeskReservationForToday,
      isDeskReservationForTodayActive,
    ],
  );

  useEffect(() => {
    if (userIsRegisteredInDatabase) {
      dispatch(userActions.validateUserDistrict());
      dispatch(parkingActions.getPkConfigurations());
      dispatch(parkingActions.getPkReservableDays());
    }
  }, [userIsRegisteredInDatabase]);

  const goToCalendar = useCallback(() => {
    if (!district || _.isEmpty(district)) {
      setShowAlert({ state: true, msg: 'lbl_not_available_district' });
      return;
    }
    if (hasValidDistrict) {
      history.push('/workstation/calendar');
    } else {
      setShowAlert({ state: true, msg: 'lbl_not_valid_district' });
    }
  }, [district, hasValidDistrict]);

  const handleItemClick = async (e, router = null) => {
    switch (router) {
      case '/workroom/filters':
        dispatch(workroomActions.resetSearchFilters());
        break;
      case '/workstation/calendar':
        e.preventDefault();
        if (!isDeskReservationForTodayActive) {
          goToCalendar();
          break;
        }
        if (await canReservationDeskForToday()) {
          const dayCompletePolicy = configurations.policies.find(
            policy => policy.nombre === 'Dia completo',
          );
          dispatch(
            workstationActions.getLastMinuteSelectWs(
              [`${dayjs.tz().format('YYYY-MM-DD')}T00:00:00.000Z`],
              {
                isVisit: district === temporalVisitsDistrict,
                defaultBuilding: parseInt(building),
                defaultFloor: parseInt(floor),
              },
              dayCompletePolicy?.nombre,
              dayCompletePolicy?.fullHoraMin,
              dayCompletePolicy?.fullHoraMax,
              `${t('today')} | ${t('complete_day')} | ${t(
                deskReservationConfiguration.configurationName,
              )}`,
            ),
          );
        }
        break;
      case '/parking/calendarPlanned':
        e.preventDefault();
        if (raffle.inProgress) {
          setShowRaffle(true);
        } else {
          if (plannedReservableDays.length === 0) {
            dispatch(
              NotificationsActions.setGenericAlert(
                t('lbl_alert_noReservableDays_title'),
                t('lbl_alert_noReservableDays_desc', {
                  day: t(
                    `dateFormats.dayIndexedByNum.${dayParkingPlannedTurningPoint}`,
                  ).toLocaleLowerCase(),
                }),
              ),
            );
            return;
          }
          history.push(router);
        }
        break;
      case '/canteen':
        e.preventDefault();
        if (
          isCanteenReservationActive(reservations, canteenReservationLastHour)
        ) {
          setOpenCanteenReservActiveModal(true);
          const canteenReservations =
            getCanteenReservationsSorted(reservations);
          const canteenReserv =
            canteenReservations.length > 1
              ? canteenReservations[1]
              : canteenReservations[0];
          setCanteenActive(canteenReserv);
          const sedeSpace = sedesList.find(e => e.id == canteenReserv?.idSede);
          const newValues = getCanteenReservActiveModalDescription(
            canteenReserv,
            sedeSpace?.campusTimeZone,
          );
          setCanteenModalDescValues(newValues);
        } else {
          history.push(router);
        }

        break;
    }
    await menuController.close();
  };

  return (
    <Menu
      disabled={!isDesktop}
      side="start"
      contentId="main"
      type="overlay"
      title={t('tab_actions')}
      className={styles.sideMenu}
      onIonDidClose={() => props.onChangeState(false)}
    >
      <div className={styles.contentListActions}>
        <DetailList className={styles.listActions}>
          {data
            .filter(e => e.show)
            .map((item: any, index) => {
              return (
                <Can key={index} functionality={item.permissionCode}>
                  <DetailListItem
                    className={`${styles.itemSty} ${
                      location.pathname == item.routerLink
                        ? styles.itemStySecondary
                        : ''
                    }`}
                    key={index}
                    mode="ios"
                    lines={item.showLine ? 'inset' : 'none'}
                    routerLink={item.routerLink}
                    type="button"
                    onClick={e => handleItemClick(e, item.routerLink)}
                    startIcon={item.icon}
                    startIconColor="primary"
                    titleLight={item.text}
                    titleColor="primary"
                    endIcon=" icon icon-chevron-right"
                    endIconColor="primary"
                  />
                </Can>
              );
            })}
          <Can functionality="incidents">
            <DetailListItem
              className={`${styles.itemSty} ${
                location.pathname == '/incidents/categories'
                  ? styles.itemStySecondary
                  : ''
              }`}
              mode="ios"
              lines={permissions.includes('emergency') ? 'none' : 'inset'}
              routerLink={'/incidents/categories'}
              type="button"
              onClick={e => handleItemClick(e)}
              startIcon="icon icon-incident"
              startIconColor="primary"
              titleLight={t('lbl_action_res_incident')}
              titleColor="primary"
              endIcon=" icon icon-chevron-right"
              endIconColor="primary"
            />
          </Can>
          <Can functionality="emergency">
            <div className={styles.divisoryLine} />
            <DetailListItem
              className={styles.itemSty}
              mode="ios"
              lines="inset"
              type="button"
              onClick={() => setOpenActionSheet(true)}
              startIcon="icon icon-emergency"
              startIconColor="secondary"
              titleLight={t('lbl_action_emergencyCall')}
              titleColor="secondary"
              endIcon=" icon icon-chevron-right"
              endIconColor="secondary"
            />
          </Can>
        </DetailList>
        <ActionSheet
          cssClass={styles.actionsheetSty}
          isOpen={openActionSheet}
          mode="ios"
          onDidDismiss={() => setOpenActionSheet(false)}
          buttons={[
            {
              cssClass: styles.btnActionSheetOptions,
              text: t('call_to', { number: emergencyPhone }),
              handler: () => {
                window.open(`tel:${emergencyPhone}`, '_self');
                setOpenActionSheet(false);
              },
            },
            {
              cssClass: styles.btnActionSheetCancel,
              text: `${t('cancel_text')}`,
              role: 'cancel',
              handler: () => {
                setOpenActionSheet(false);
              },
            },
          ]}
        />
      </div>
      <Toast
        isOpen={showRaffle}
        message={t('lbl_raffle_inprogress')}
        position="bottom"
        type="info"
        onDidDismiss={() => setShowRaffle(false)}
      />
      <Alert
        header={t('btn_last_minute_workstation')}
        isOpen={showAlert.state}
        onDidDismiss={() => setShowAlert({ state: false, msg: null })}
        message={t(showAlert.msg)}
        buttons={[{ text: t('btn_understood'), role: 'cancel' }]}
        mode="ios"
      />
      <Toast
        isOpen={!!error || !!errorWorkstations}
        message={error || errorWorkstations}
        position="bottom"
        type="error"
      />
      <IonAlert
        mode="ios"
        header={t('canteenReservActiveModal.header')}
        message={t('canteenReservActiveModal.description', {
          date: canteenModalDescValues.date,
          from: canteenModalDescValues.from,
          to: canteenModalDescValues.to,
        })}
        isOpen={openCanteenReservActiveModal}
        backdropDismiss={false}
        buttons={[
          {
            text: t('cancel_text'),
            role: 'cancel',
            handler: () => {
              setOpenCanteenReservActiveModal(false);
            },
          },
          {
            text: t('canteenReservActiveModal.btn_routerToReserv'),
            cssClass: styles.canteenModalBtn,
            role: 'confirm',
            handler: () => {
              dispatch(
                reservationActions.getReservationCanteen(
                  canteenActive?.dates[0]?.reservationId,
                ),
              );
              setOpenCanteenReservActiveModal(false);
            },
          },
        ]}
      ></IonAlert>
      <IonLoading
        isOpen={isLoadingWorkstations}
        message={t('msg_loading')}
        duration={0}
      />
    </Menu>
  );
};

export default ActionsMenuDesktop;
