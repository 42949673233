import { useEffect, useMemo, useState } from 'react';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonLoading,
  IonText,
  IonToggle,
  IonAlert,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { TopBar, Button, Toast, Snackbar } from '@acciona/ui-ionic-kit';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import {
  WorkroomStore,
  SearchFilter,
} from '../../../../_redux/models/workroom.model';
import RoomTypeModal from '../RoomTypeModal';
import CapacityModal from '../CapacityModal';
import CalendarModal from '../CalendarModal';
import BuildingModal from '../BuildingModal';
import RoomUseTypeModal from '../RoomUseTypeModal';
import { isEmpty, openHtmlLink } from '../../../../utils/functions';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { AppStore } from '../../../../_redux/models/app.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { DateTime } from '../../../../components/DateTime/DateTime';
import { addMinutesAndRound } from './helpers';
import {
  getFirstEnabledDate,
  getIsToday,
  getNowTimeCampus,
} from '../../../../utils/dateTime';
import styles from './styles.module.scss';

const Filter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const minutesDifferenceCheck = 24;
  const {
    publicHolidays,
    user: { defaultSede },
    reservationPolicies,
  } = useSelector((state: UserStore) => state.user);
  const { error, isLoading, workroomTypes, buildings, reservNow } = useSelector(
    (state: WorkroomStore) => state.workroom,
  );

  const [showToast, setShowToast] = useState(false);
  const [showRoomType, setShowRoomType] = useState(false);
  const [showCapacity, setShowCapacity] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showBuilding, setShowBuilding] = useState(false);
  const [showIsFestiveModal, setShowIsFestiveModal] = useState(false);
  const [showRoomUseType, setShowRoomUseType] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { campusTimeZone } = useSelector(
    (store: AppStore) => store.app.globalSettings,
  );

  const firstEnabledDate = useMemo(
    () =>
      getFirstEnabledDate(
        publicHolidays,
        dayjs(
          `${getNowTimeCampus().format('YYYY-MM-DD')} 00:00:00`,
        ).toISOString(),
        [0, 6],
      ),
    [publicHolidays],
  );

  const getDateCalendar = (date: Date): string => {
    const formatedDate = dayjs(date).utcOffset(0, true).toISOString();
    return formatedDate;
  };

  const [filterData, setfilterData] = useState<SearchFilter>({
    date: getDateCalendar(firstEnabledDate),
    startHour: null,
    finalHour: null,
    capacity: null,
    spaceTypes: [],
    buildings: [],
    roomUseType: null,
  });

  const isToday = getIsToday(filterData.date);

  const documentReservation = useMemo(
    () =>
      reservationPolicies
        ? reservationPolicies.find(e => e.type === 'policyRoom')?.url
        : '',
    [reservationPolicies],
  );

  const [minHour, setMinHour] = useState<string>(
    addMinutesAndRound(getNowTimeCampus().format('HH:mm')),
  );
  const [maxHour, setMaxHour] = useState<string>(
    addMinutesAndRound(getNowTimeCampus().format('HH:mm'), 30),
  );

  useEffect(() => {
    error && setShowToast(true);
  }, [error]);

  useEffect(() => {
    setMinHour(
      isToday
        ? addMinutesAndRound(getNowTimeCampus().format('HH:mm'))
        : '00:00',
    );
  }, [filterData.date]);

  const getRoomUseTypeParameterString = (): 'Interno' | 'Externo' => {
    if (filterData.roomUseType === t('use_internal')) {
      return 'Interno';
    } else if (filterData.roomUseType === t('use_external')) {
      return 'Externo';
    } else {
      return null;
    }
  };

  const getRoomUseTypeDetailListText = () => {
    if (!filterData.roomUseType) {
      return t('use_workrooms_placeholder');
    } else if (filterData.roomUseType === 'Interno') {
      return t('use_internal');
    } else if (filterData.roomUseType === 'Externo') {
      return t('use_external');
    } else {
      return filterData.roomUseType;
    }
  };

  const handleWorkroomsList = () => {
    const start = dayjs(filterData.startHour).tz();
    const final = dayjs(filterData.finalHour).tz();
    const minutesDifference = final.diff(start, 'minutes');
    if (minutesDifference < minutesDifferenceCheck) {
      dispatch(
        workroomActions.fetchWorkroomFailure(t('lbl_invalid_time_difference')),
      );
      return;
    }

    if (
      dayjs(filterData.finalHour).isSameOrBefore(dayjs(filterData.startHour))
    ) {
      dispatch(workroomActions.fetchWorkroomFailure(t('lbl_invalid_time')));
    } else {
      dispatch(
        workroomActions.getAvailableWorkrooms(reservNow, {
          date: filterData.date,
          ...(filterData.startHour && {
            startHour: dayjs
              .tz(filterData.startHour, campusTimeZone)
              .toISOString(),
          }),
          ...(filterData.finalHour && {
            finalHour: dayjs
              .tz(filterData.finalHour, campusTimeZone)
              .toISOString(),
          }),
          capacity: filterData.capacity ?? null,
          ...(!isEmpty(filterData.spaceTypes) && {
            spaceTypes: filterData.spaceTypes,
          }),
          ...(!isEmpty(filterData.buildings) && {
            buildings: filterData.buildings,
          }),
          roomUseType: getRoomUseTypeParameterString(),
        }),
      );
    }
  };

  const handleDismissToast = () => {
    setShowToast(false);
    dispatch(workroomActions.resetErrorWorkroom());
  };

  const handleFilterDataChange = (key, value) => {
    setfilterData(oldValue => {
      return { ...oldValue, [key]: value };
    });
    if (key === 'startHour') {
      setMaxHour(dayjs(value).add(30, 'minutes').format('HH:mm'));
    }
  };
  const handleTimeReset = () => {
    setfilterData(prevState => {
      return {
        ...prevState,
        startHour: null,
        finalHour: null,
      };
    });
  };
  const handleSelectDate = () => {
    setShowCalendar(true);
  };
  const handleroomType = () => {
    setShowRoomType(true);
  };
  const handleCapacity = () => {
    setShowCapacity(true);
  };

  const handleBuilding = () => {
    setShowBuilding(true);
  };
  const handleReservNowActive = () => {
    const now = getNowTimeCampus().add(4, 'minute'); // time for user to complete the form
    setfilterData(prevState => {
      return {
        ...prevState,
        startHour: now.format('YYYY-MM-DDTHH:mm'),
        date: getDateCalendar(now.toDate()),
      };
    });
  };

  const handleReservNow = event => {
    const newValue = event.detail.checked;
    dispatch(workroomActions.setReservNow(newValue));
    if (newValue) {
      const today = dayjs();
      const isFestive = firstEnabledDate > today.toDate();
      if (isFestive) {
        setShowIsFestiveModal(true);
        return;
      }
      handleReservNowActive();
    } else {
      handleTimeReset();
    }
  };
  const handleOpenStartTimeSelector = () => {
    filterData &&
      setMinHour(
        isToday
          ? addMinutesAndRound(getNowTimeCampus().format('HH:mm'))
          : '00:00',
      );
  };

  const handleRoomUseType = () => {
    setShowRoomUseType(true);
  };

  const isDisplayWorkroomsButtonDisabled = useMemo(() => {
    const start = dayjs(filterData.startHour).tz();
    const final = dayjs(filterData.finalHour).tz();
    const milliSecondsFromStart = final.diff(start);

    return (
      !filterData.startHour ||
      !filterData.finalHour ||
      milliSecondsFromStart < 1000
    );
  }, [filterData.startHour, filterData.finalHour]);

  const updateDate = date => {
    setfilterData({
      ...filterData,
      date: getDateCalendar(date),
      startHour: '',
      finalHour: '',
    });
  };

  const resetFilterData = () => {
    dispatch(workroomActions.setReservNow(false));
    setfilterData({
      date: getDateCalendar(firstEnabledDate),
      startHour: null,
      finalHour: null,
      capacity: null,
      spaceTypes: [],
      buildings: [],
      roomUseType: null,
    });
  };
  return (
    <IonPage>
      <BuildingModal
        id="buildingModal"
        isOpen={showBuilding}
        showModal={setShowBuilding}
        value={filterData.buildings}
        setValue={buildingsSelected =>
          setfilterData({ ...filterData, buildings: buildingsSelected })
        }
      />
      <CalendarModal
        id="calendarModal"
        isOpen={showCalendar}
        setShowCalendar={setShowCalendar}
        setDate={updateDate}
        publicHolidays={publicHolidays}
        firstEnabledDate={firstEnabledDate}
      />
      <CapacityModal
        id="capacityModal"
        isOpen={showCapacity}
        showModal={setShowCapacity}
        value={filterData.capacity}
        setValue={capacity =>
          setfilterData({ ...filterData, capacity: capacity })
        }
      />
      <RoomUseTypeModal
        id="roomUseTypeModal"
        isOpen={showRoomUseType}
        showModal={setShowRoomUseType}
        value={filterData.roomUseType}
        setValue={useType =>
          setfilterData({ ...filterData, roomUseType: useType })
        }
      />
      <RoomTypeModal
        id="roomTypeModal"
        isOpen={showRoomType}
        showModal={setShowRoomType}
        value={filterData.spaceTypes}
        setValue={rTypes =>
          setfilterData({ ...filterData, spaceTypes: rTypes })
        }
      />
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('reserve_workroom')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <Toast
          isOpen={showToast}
          onDidDismiss={handleDismissToast}
          message={error}
          position="bottom"
          type="error"
        ></Toast>
        <div className={styles.listGrid}>
          <DetailList>
            <DetailListItem
              lines="inset"
              titleLight={t('reserv_now')}
              titleColor="primary"
              endText={
                <IonToggle
                  mode="ios"
                  className={styles.toggle}
                  checked={reservNow}
                  onIonChange={handleReservNow}
                />
              }
            />
            <DetailListItem
              mode="ios"
              lines="inset"
              onClick={handleBuilding}
              titleLight={t('building_filter_workroom')}
              endText={
                isEmpty(filterData.buildings) ||
                filterData.buildings.length === buildings.length
                  ? t('all')
                  : filterData.buildings
                      .map(id => {
                        return buildings.find(b => b.id == id).name;
                      })
                      .join(',')
              }
            />
            {!reservNow ? (
              <>
                <DetailListItem
                  mode="ios"
                  lines="inset"
                  titleLight={t('date')}
                  endText={dayjs(filterData.date).utc().format('DD MMM YYYY')}
                  onClick={handleSelectDate}
                />
                <DetailListItem
                  mode="ios"
                  lines="inset"
                  titleLight={t('init_time')}
                  endText={
                    <DateTime
                      name="initTime"
                      className={styles.dateTime}
                      display-format="HH:mm"
                      picker-format="HH:mm"
                      minuteValues="0,15,30,45"
                      min={minHour}
                      placeholder={t('any_time')}
                      value={filterData.startHour}
                      onIonFocus={handleOpenStartTimeSelector}
                      onIonChange={e =>
                        handleFilterDataChange('startHour', e.detail.value!)
                      }
                      cancelText={t('cancel_text')}
                      doneText={t('done_text')}
                    />
                  }
                />
              </>
            ) : (
              <></>
            )}
            <DetailListItem
              mode="ios"
              lines="inset"
              titleLight={t('end_time')}
              endText={
                <DateTime
                  name="endTime"
                  className={styles.dateTime}
                  display-format="HH:mm"
                  picker-format="HH:mm"
                  minuteValues="0,15,30,45"
                  min={maxHour || '00:00'}
                  value={filterData.finalHour}
                  placeholder={t('any_time')}
                  onIonChange={e =>
                    handleFilterDataChange('finalHour', e.detail.value!)
                  }
                  disabled={filterData.startHour == null}
                  cancelText={t('cancel_text')}
                  doneText={t('done_text')}
                />
              }
            />
            <DetailListItem
              mode="ios"
              lines="inset"
              onClick={handleCapacity}
              titleLight={t('capacity_workrooms')}
              endText={
                filterData.capacity == null
                  ? `${t('capacity_workrooms_placeholder')}`
                  : filterData.capacity
              }
              endTextColor={filterData.capacity == null ? 'medium' : 'primary'}
            />
            <DetailListItem
              mode="ios"
              lines="inset"
              onClick={handleRoomUseType}
              titleLight={t('use_workrooms')}
              endText={getRoomUseTypeDetailListText()}
              endTextColor={
                filterData.roomUseType == null ? 'medium' : 'primary'
              }
            />
            <DetailListItem
              mode="ios"
              lines="inset"
              onClick={handleroomType}
              titleLight={t('type_workroom')}
              endText={
                isEmpty(filterData.spaceTypes)
                  ? `${t('workroom_placeholder')}`
                  : filterData.spaceTypes
                      .map(id => {
                        return workroomTypes.find(b => b.id == id).name;
                      })
                      .join(',')
              }
              endTextColor={
                isEmpty(filterData.spaceTypes) ? 'medium' : 'primary'
              }
            />
          </DetailList>
        </div>
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={0}
        />
        <IonAlert
          mode="ios"
          header={t('filtersIsFestiveModal.header')}
          message={t('filtersIsFestiveModal.description', {
            date: '',
            from: '',
            to: '',
          })}
          isOpen={showIsFestiveModal}
          backdropDismiss={false}
          buttons={[
            {
              text: t('ok_text'),
              role: 'cancel',
              handler: () => {
                resetFilterData();
                setShowIsFestiveModal(false);
              },
            },
          ]}
        ></IonAlert>
      </IonContent>
      <IonFooter>
        {!isEmpty(documentReservation) && (
          <Snackbar
            type="white"
            text={
              <IonText className={styles.messageFooter}>
                {t('msg_you_are_booking')}
                <b>
                  {t('msg_this_office', {
                    name: defaultSede.description,
                  })}
                </b>
                {'. '}
                {t('msg_policies_workstation')}{' '}
                <a
                  className={styles.defaultLink}
                  onClick={() => openHtmlLink(documentReservation)}
                >
                  {t('lbl_policy_room')}
                </a>
              </IonText>
            }
            icon="icon icon-info"
            align="left"
          ></Snackbar>
        )}
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              onClick={handleWorkroomsList}
              disabled={isDisplayWorkroomsButtonDisabled}
            >
              {t('display_workrooms')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Filter;
