import { IonIcon, IonText } from '@ionic/react';
import dayjs from 'dayjs';
import i18n from '../../../../i18nextConf';
import styles from '../styles.module.scss';
import {
  getCanteenReservationHours,
  getRoomReservationHours,
  getUniqueValues,
  isEmpty,
} from '../../../../utils/functions';

export const getHeaderText = (
  typeOfReservation: string,
  space: any,
): string => {
  if (
    typeOfReservation === 'request' &&
    ![
      'Room-WaitingList',
      'Parking-Request',
      'Desk-Request',
      'PassengerInvitation',
    ].includes(space.useType)
  ) {
    return '';
  }

  switch (space.useType) {
    case 'Room-WaitingList':
    case 'Parking-Request':
    case 'PassengerInvitation':
    case 'Desk-Request':
      return i18n.t('space_unallocated');
    case 'Room':
      return `${i18n.t('workroom')} ${space.name}`;
    case 'Desk':
    case 'PermanentDesk':
      return `${i18n.t('workstation')} ${space.name}`;
    case 'Passenger':
      return i18n.t('passenger');
    case 'Parking':
    case 'PermanentParking':
      return `${i18n.t('parking')} ${space.name}`;
    case 'Locker':
      return `${i18n.t('lbl_locker')} ${space.name}`;
    case 'Canteen':
      return `${i18n.t('canteen')} ${space.districtName}`;
    default:
      return '';
  }
};

export const getTitleText = (
  userDistrict: string,
  space: any,
  timezone: string,
): string => {
  switch (space.useType) {
    case 'Room-WaitingList':
      return `${dayjs(space.dates[0].reservationDateFrom)
        .tz(timezone)
        .format('D MMM [|] HH:mm')} - ${dayjs(space.dates[0].reservationDateTo)
        .tz(timezone)
        .format('HH:mm')}`;
    case 'Desk':
    case 'PermanentDesk':
      return space.districtName;
    case 'Room':
      return userDistrict === space.district?.toString()
        ? ` | ${space.buildingName} ${space.floorName}`
        : `${space.districtName} | ${space.buildingName} ${
            space.floorName ?? ''
          }`;
    case 'Parking':
    case 'PermanentParking':
      return space.location;
    case 'Locker':
      return `${space.buildingName}, ${space.floorName}`;
    case 'Canteen':
      return `${space.buildingName} | ${space.floorName}`;
    case 'PassengerInvitation':
    case 'Passenger':
      return `${i18n.t('driver')}: ${space.driverName}`;
    default:
      return '';
  }
};

export const getSubtitleText = (
  space: any,
  raffleDay: any,
  timezone: string,
  visualization: string,
): string => {
  switch (space.useType) {
    case 'PassengerInvitation':
    case 'Parking-Request':
      return raffleDay
        ? i18n.t('lbl_info_raffle_parking', { date: raffleDay })
        : '';
    case 'Desk':
    case 'PermanentDesk':
      return space?.equipment ?? '';
    case 'Room':
      return getRoomReservationHours(
        space.dates[0].reservationDateFrom,
        space.dates[0].reservationDateTo,
        timezone,
        visualization,
      );
    case 'Canteen':
      return getCanteenReservationHours(
        space.dates[0].reservationDateFrom,
        space.dates[0].reservationDateTo,
        timezone,
        visualization,
      );
    case 'Room-WaitingList':
    case 'Parking':
    case 'PermanentParking':
    default:
      return '';
  }
};

export const getEndContent = space => {
  switch (space.useType) {
    case 'Desk':
      return (
        <IonIcon
          className={`icon icon-${getIconReservationType(
            space?.dates[0].reservationType,
          )}`}
        />
      );
    case 'Room':
      return (
        space?.roomUseType === 'Interno' && (
          <IonIcon
            className={`icon icon-person-lock ${styles.iconInternalUseRoom}`}
          />
        )
      );
    case 'Parking': {
      const isSameScheduleForAllDates = dates => {
        if (isEmpty(dates)) return false;
        const firstReservationType = dates[0]?.reservationType;
        return dates.every(
          date => date.reservationType === firstReservationType,
        );
      };
      const parkingReservationTypeIcon = isSameScheduleForAllDates(space?.dates)
        ? `icon icon-${getIconReservationType(
            space?.dates[0].reservationType,
          )} ${styles.finalItemIconSty}`
        : '';

      return (
        <div className={`${styles.containerIcons}`}>
          <IonIcon className={`icon icon-car  ${styles.itemIconSty}`}></IonIcon>
          <IonText className={`icon ${styles.itemTxtSty}`}>
            {space?.numVehicles}
          </IonText>

          <IonIcon
            className={`icon icon-occupation  ${styles.itemIconSty}`}
          ></IonIcon>
          <IonText className={` ${styles.itemTxtSty}`}>
            {space?.numCompanions}
          </IonText>
          <IonIcon className={parkingReservationTypeIcon} />
        </div>
      );
    }
    default:
      return '';
  }
};

export const getAbbrDays = (res: any[]): string => {
  if (!Array.isArray(res) || res === null) return '';

  const reservationDates = getUniqueValues(res, 'reservationDate');

  if (isEmpty(reservationDates)) {
    return '';
  }

  const days = reservationDates.map(element =>
    dayjs(element.reservationDate).format('DD MMM'),
  );
  return days.join(', ');
};

export const getSpaceDate = (date, spaceUseType, timezone: string = '') => {
  const isRoomOrCanteen = ['Room', 'Room-WaitingList', 'Canteen'].includes(
    spaceUseType,
  );
  const dateString = isRoomOrCanteen
    ? dayjs(
        `${dayjs(date.reservationDateFrom)
          .tz(timezone)
          .format('YYYY-MM-DD HH:mm:ss')}`,
      ).toISOString()
    : dayjs(`${date.reservationDate} 12:00:00`).toISOString();
  return new Date(dateString);
};

export const getIconReservationType = (type: string): string => {
  return iconReservationTypes[type];
};

export type IconReservationTypes = { [key: string]: string };
export const iconReservationTypes: IconReservationTypes = {
  'Tarde': 'afternoon',
  'Morning': 'morning',
  'Personalizada': 'hours',
  'Dia completo': 'recaderia',
};
