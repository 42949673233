import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonText,
} from '@ionic/react';
import { ActionSheet, Button, Icon, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { RouteComponentProps } from 'react-router';
import { useState } from 'react';

const CallphoneCenter: React.FC<RouteComponentProps<{ phone: string }>> = ({
  match,
}) => {
  const { t } = useTranslation();
  const [openActionSheet, setOpenActionSheet] = useState(false);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('incident_report_header_default')}
        />
      </IonHeader>
      <IonContent className="ion-text-center">
        <div className={styles.gridWeb}>
          <div className={styles.contain}>
            <div>
              <Icon className={`icon icon-lastminute-XL ${styles.icon}`} />
            </div>
            <div>
              <IonText className={styles.title_2}>
                {t('incident_report_urgent')}
              </IonText>
              <IonText className={styles.subhead}>
                {t('incident_report_call_text')}
              </IonText>
            </div>
          </div>
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={() => setOpenActionSheet(true)}>
              {t('incident_report_call')}
            </Button>
          </div>
        </div>
        <ActionSheet
          cssClass={styles.actionsheetSty}
          isOpen={openActionSheet}
          mode="ios"
          onDidDismiss={() => setOpenActionSheet(false)}
          buttons={[
            {
              cssClass: styles.btnActionSheetOptions,
              text: t('call_to', { number: match.params.phone }),
              handler: () => {
                window.open(`tel:${match.params.phone}`, '_self');
                setOpenActionSheet(false);
              },
            },
            {
              cssClass: styles.btnActionSheetCancel,
              text: `${t('cancel_text')}`,
              role: 'cancel',
              handler: () => {
                setOpenActionSheet(false);
              },
            },
          ]}
        />
      </IonFooter>
    </IonPage>
  );
};

export default CallphoneCenter;
