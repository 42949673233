import {
  Alert,
  Button,
  Chip,
  Icon,
  Snackbar,
  TopBar,
} from '@acciona/ui-ionic-kit';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserStore } from '../../../_redux/models/user.model';
import logoImage from '../../../assets/images/LogoSeos.png';
import { useEffect, useState } from 'react';
import { userActions } from '../../../_redux/actions/user.actions';
import HIDService from '../../../_redux/services/hid/hid.services';
import { tokenServices } from '../../../_redux/services/token/token.services';
import jwt_decode from 'jwt-decode';
import { useCheckDevicePermissions } from './../../../hooks/useCheckDevicePermissions';
import { BackButton } from '../../../components/Base/BackButton';
import { useHistory } from 'react-router';
import { useLocationState } from '../../../hooks/useLocationState';

const Card: React.FC = () => {
  const { t } = useTranslation();
  const userData = useSelector((state: UserStore) => state.user.user);
  const [cardNumber, setCardNumber] = useState(
    HIDService.keys.length > 0 ? HIDService.keys[0] : null,
  );
  const [active, setActive] = useState(!!cardNumber);
  const { registered } = useSelector((state: UserStore) => state.user.card);
  const [fullName, setFullName] = useState('');
  const [showActivated, setShowActivated] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useLocationState() as any;

  const {
    activationDisabled,
    locationActive,
    bluethoothActive,
    bluethoothPermission,
    locationPermission,
    messageType,
  } = useCheckDevicePermissions();

  let hidCardChangedSubscription;

  const getUserService = async () => {
    const tokenLocal: any = jwt_decode(await tokenServices.getLocalIdToken());
    setFullName(tokenLocal?.given_name + ' ' + tokenLocal?.family_name);
  };

  useEffect(() => {
    getUserService();
    dispatch(userActions.unSetInvitationCode());
    setTimeout(() => {
      checkCard();
      hidCardChangedSubscription = HIDService.cardChanged.subscribe(() => {
        checkCard();
      });
    }, 2000);
    document.addEventListener('ionBackButton', () =>
      history.replace('/dashboard/home'),
    );

    return () => {
      hidCardChangedSubscription?.unsubscribe();
      document.removeEventListener('ionBackButton', () =>
        history.replace('/dashboard/home'),
      );
    };
  }, []);

  useEffect(() => {
    state && setShowActivated(true);
  }, [location]);

  const activateCard = () => {
    setActive(true);
    dispatch(userActions.setCardActive());
  };

  const deactivateCard = () => {
    dispatch(userActions.setCardInActive());
    setActive(false);
    setCardNumber(null);
  };

  const checkCard = () => {
    setCardNumber(HIDService.keys.length > 0 ? HIDService.keys[0] : null);
    const cards = HIDService.card;
    const hasCards = cards.length > 0;

    if (hasCards && !active) {
      activateCard();
    } else if (!hasCards && registered) {
      if (cardNumber?.cardNumber?.length > 1) {
        activateCard();
      } else {
        deactivateCard();
      }
    }
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={<BackButton router={'/dashboard/home'} replace />}
          title={t('lbl_digital_card')}
        />
        {registered && (
          <Snackbar
            icon="icon icon-info"
            type="info"
            text={t('lbl_snack_card_info')}
            align="left"
          />
        )}
        {(!locationActive ||
          !bluethoothActive ||
          !bluethoothPermission ||
          !locationPermission) && (
          <div className={styles.warningSnackbar}>
            <Snackbar
              type="warning"
              text={t(`card_disabled${messageType}`)}
              icon="icon icon-incident"
              align="left"
            />
          </div>
        )}
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <IonCard
            className={
              active
                ? styles.activeCard
                : registered
                ? styles.noCard
                : styles.noActiveCard
            }
          >
            <IonCardHeader>
              <IonCardTitle>
                <Icon className={`${styles.cardIcon} icon icon-wallet`}></Icon>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className={styles.cardData}>
              <p className={styles.cardUserName}>{fullName}</p>
              <p className={styles.cardUserFunction}>{userData.function}</p>
              <p className={styles.cardUserFunction}>
                {cardNumber && cardNumber.cardNumber}
              </p>
            </IonCardContent>
          </IonCard>
          {!registered && (
            <div className={styles.infoContent}>
              <div>
                <Chip type="warning">
                  <IonLabel>{t('lbl_pending')}</IonLabel>
                </Chip>
              </div>
              <div className={`${styles.warningText} ${styles.subhead}`}>
                {t('card_warning')}
              </div>
            </div>
          )}
          {registered && !active && (
            <div className={styles.infoContent}>
              <div>
                <Chip type="warning">
                  <IonLabel>{t('lbl_pending')}</IonLabel>
                </Chip>
              </div>
              <div className={`${styles.warningText} ${styles.subhead}`}>
                {t('lbl_card_success_activation')}
              </div>
            </div>
          )}
          <div className={styles.sponsordiv}>
            <IonRow className="ion-justify-content-center">
              <IonText color="dark" className={`${styles.footnote} `}>
                {' '}
                {t('ofered_by')}
              </IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonImg src={logoImage} />
            </IonRow>
          </div>
        </div>
      </IonContent>
      <Alert
        isOpen={showActivated}
        onDidDismiss={() => setShowActivated(false)}
        header={t('account_validate_header')}
        message={t('account_validate_text')}
        buttons={[
          {
            text: t('ok_text'),
            role: 'cancel',
            handler: () => setShowActivated(false),
          },
        ]}
        mode="ios"
      ></Alert>
      {!registered && (
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              <Button
                routerLink="/profile/user/card/card-activation"
                disabled={activationDisabled}
              >
                {t('activate')}
              </Button>
            </div>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Card;
