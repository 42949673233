import styles from './styles.module.scss';

export const classes = {
  desk: `${styles.desk}`,
  parking: `${styles.parking}`,
  homeOffice: `${styles.homeOffice}`,
  reserved: `${styles.reserved}`,
  releasedDays: `${styles.releasedDays}`,
  workrooms: `${styles.workrooms}`,
  collaborativeUseWorkstation: `${styles.collaborativeUseWorkstation}`,
  operativeUseWorkstation: `${styles.operativeUseWorkstation}`,
  concentrationUseWorkstation: `${styles.concentrationUseWorkstation}`,
  anchorWorkstation: `${styles.anchorWorkstation}`,
};
