import { useState, useEffect, useRef } from 'react';

export const useDelayedRender = (milliseconds: number = 1000) => {
  const mounted = useRef(false);
  const [delayed, setDelayed] = useState(true);

  useEffect(() => {
    mounted.current = true;
    const timeout = setTimeout(() => {
      mounted.current && setDelayed(false);
    }, milliseconds);
    return () => {
      clearTimeout(timeout);
      mounted.current = false;
    };
  }, []);

  return { delayed };
};
