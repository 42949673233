import { IonText } from '@ionic/react';
import { UserData } from '../../_redux/models/user.model';
import styles from './styles.module.scss';

type Props = {
  user: UserData;
};

export const ProfileHeader: React.FC<Props> = ({ user }) => {
  return (
    <div className={styles.headerProfileContainer}>
      <div className={`${styles.headerProfile} ${styles.listGrid}`}>
        {user.name && user.firstSurname && (
          <div className={styles.headerPhotoProfile}>
            {(
              user.name.charAt(0) + user.firstSurname.charAt(0)
            ).toLocaleUpperCase()}
          </div>
        )}
        <div className={styles.headerDataProfile}>
          <IonText
            className={styles.nameProfile}
          >{`${user.name} ${user.firstSurname} ${user.secondSurname}`}</IonText>
          <IonText className={styles.footnote} color="dark">
            {user.employeeId ? `ID: ${user.employeeId}` : ''}
          </IonText>
        </div>
      </div>
    </div>
  );
};
