import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
} from '@ionic/react';
import { Alert, Button, Toast, TopBar } from '@acciona/ui-ionic-kit';
import { vehicleActions } from '../../../../_redux/actions/vehicles.actions';
import {
  Vehicle,
  VehicleStore,
} from '../../../../_redux/models/vehicles.model';
import { NewVehicleModal } from '../../../Profile/VehiclesPassengers/NewVehicleModal/NewVehicleModal';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { UserStore } from '../../../../_redux/models/user.model';
import { ENVIRONMENTAL_BADGES } from '../../../../utils/constants';
import { isEmpty } from '../../../../utils/functions';
import { getAvailableVehicles } from '../../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';
import { getVehicleIcon } from '../../../../utils/vehiclesAndParking';
import { getAllDatesMachWithVehicle } from '../helpers';

const ChangeVehicle: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector((state: UserStore) => state.user.user);
  const {
    isLoading,
    vehicle: defaultVehicle,
    vehicles: userVehicles,
  } = useSelector((state: VehicleStore) => state.vehicle);
  const msgState = useSelector((store: VehicleStore) => store.vehicle.msg);
  const [selected, setSelected] = useState<Vehicle>(null);
  const [newVehicle, setNewVehicle] = useState(true);
  const [showModalAddVehicle, setShowModalAddVehicle] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showAlertOtherVehicle, setShowAlertOtherVehicle] = useState(false);
  const location = useLocation().state as any;
  const lastMinute = location || null;

  useEffect(() => {
    if (isEmpty(userVehicles)) {
      dispatch(vehicleActions.getListVehicle());
    } else {
      setNewVehicle(true);
    }
  }, [userVehicles]);

  const availableVehicles = useMemo(
    () => getAvailableVehicles(userVehicles, permissions),
    [userVehicles, permissions],
  );

  useEffect(() => {
    const defaultVehicleData =
      availableVehicles.find(e => e.vehicleId === defaultVehicle.vehicleId) ??
      availableVehicles[0];
    setSelected(defaultVehicleData);
  }, [availableVehicles, defaultVehicle]);

  const handleChangeVehicle = async () => {
    if (lastMinute) {
      const allDatesMatchWithVehicle = getAllDatesMachWithVehicle(
        lastMinute.selectedDates,
        lastMinute.reserveVehicles,
        selected,
      );
      if (allDatesMatchWithVehicle) {
        if (
          defaultVehicle.type !== selected.type ||
          defaultVehicle.plugin !== selected.plugin ||
          newVehicle
        ) {
          const vehiclePrev = userVehicles.find(e => e.favourite);
          // 1) Set the selected vehicle in the VehicleStore.vehicle state.
          // 2) Temporarily set the selected vehicle as favorite vehicle.
          // 3) Resend the reservable parking search request *.
          // * IMPORTANT: the backend always executes this request for the favorite vehicle (that's why it is necessary to temporarily set a new favorite).
          // 4) Restore the original favorite vehicle
          dispatch(
            vehicleActions.changeVehicleLastMinute(
              selected,
              lastMinute.selectedDates,
              vehiclePrev,
              lastMinute.type,
              lastMinute.startHour,
              lastMinute.endHour,
              lastMinute.textHeader,
            ),
          );
        } else {
          dispatch(vehicleActions.modifyParkingVehicle(selected)); // set selected vehicle in VehicleStore.vehicle state
        }
      } else {
        // if !allDatesMatchWithVehicle
        setShowAlertOtherVehicle(true);
        return;
      }
    } else {
      // if is not lastMinute (is planned reservation)
      dispatch(vehicleActions.modifyParkingVehicle(selected)); // set selected vehicle in VehicleStore.vehicle state
    }
  };

  const handleCloseModal = () => {
    setShowModalAddVehicle(false);
    setShowToast(true);
  };

  const handleDismissToast = () => {
    setShowToast(false);
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/parking/reservationPlanned"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_parking_change_vehicle_title')}
        />
      </IonHeader>

      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <NewVehicleModal
              showModal={showModalAddVehicle}
              onClose={() => setShowModalAddVehicle(false)}
              onCloseWithSave={() => handleCloseModal()}
            />
            <DetailList>
              <IonRadioGroup
                value={selected}
                onIonChange={e => setSelected(e.detail.value)}
              >
                {availableVehicles?.map(v => (
                  <DetailListItem
                    key={v.plate}
                    mode="ios"
                    lines="inset"
                    className={styles.vehicleList}
                    startIcon={getVehicleIcon(v.type)}
                    titleLight={` ${v.brand} ${v.model} `}
                    titleColor="primary"
                    startChip={
                      v.environmentalBadge === ENVIRONMENTAL_BADGES.ECO && {
                        type: 'success',
                        text: 'ECO',
                      }
                    }
                    middleIcon={
                      v.favourite && `icon icon-star-filled  ${styles.star}`
                    }
                    footnote={v.plate}
                    endText={
                      <div className={styles.radioButton}>
                        <IonRadio
                          value={v}
                          slot="end"
                          color="primary"
                          mode="ios"
                          className={styles.radioIcon}
                        ></IonRadio>
                      </div>
                    }
                  ></DetailListItem>
                ))}
              </IonRadioGroup>
            </DetailList>
            {msgState && (
              <Toast
                isOpen={showToast}
                message={msgState.description}
                onDidDismiss={handleDismissToast}
                position="bottom"
                type={msgState.type}
              />
            )}
          </div>
        </div>
        <Alert
          isOpen={showAlertOtherVehicle}
          onDidDismiss={() => setShowAlertOtherVehicle(false)}
          header={t('alert_pk_other_vehicle.header')}
          message={t('alert_pk_other_vehicle.message')}
          buttons={[{ text: t('ok_text'), role: 'cancel' }]}
          mode="ios"
        ></Alert>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              onClick={() => setShowModalAddVehicle(true)}
              disabled={isLoading}
            >
              {t('btn_parking_add_vehicle')}
            </Button>
          </div>
          <div>
            <Button onClick={handleChangeVehicle} disabled={isLoading}>
              {t('lbl_parking_select_vehicle')}
              {isLoading && (
                <IonSpinner
                  slot="end"
                  className={styles.color_primary}
                  name="lines-small"
                />
              )}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default ChangeVehicle;
