import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { medicalServicesActions } from '../../../../../../_redux/actions';
import { MedicalServicesStore } from '../../../../../../_redux/models/medicalServices.model';
import { Cover } from '../../../templates/Cover/Cover';

const MedicalServicesList: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { medicalServices } = useSelector(
    (store: MedicalServicesStore) => store.medicalServices,
  );

  useEffect(() => {
    dispatch(medicalServicesActions.getMedicalServices());
  }, []);

  return (
    <Cover
      backgroundImageDesktop="./assets/images/medicalServices.png"
      backgroundImageMobile="./assets/images/medicalServicesMobile.png"
      title={t('lbl_more_medicalServices')}
      subtitle={t('lbl_more_medicalServices')}
      menuTitle={t('lbl_more_medicalServices_description')}
      menuIcon="doctor"
      menuItems={medicalServices}
      itemDetailUrl="/services/medicalservices/medicalServicesInformation"
    />
  );
};

export default MedicalServicesList;
