import React, { useState, useEffect, useMemo } from 'react';
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonContent,
  IonFooter,
  useIonViewDidEnter,
  IonLoading,
  IonSpinner,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TopBar,
  Button,
  Toast,
  Snackbar,
  Alert,
  WeekCalendarMultiselector,
} from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { AppStore } from '../../../../_redux/models/app.model';
import { getNumberOfWeeks } from '../../../../utils/functions';
import { groupDatesByMonth } from '../../../../utils/dateTime';
import {
  ReleasedDeskDays,
  ReservationStore,
} from '../../../../_redux/models/reservation.model';
import { Legend } from '../../../../components/Base/Legend';
import { LegendItem } from '../../../../components/Base/LegendItem';
// import { useHistory } from 'react-router';
import {
  reservationActions,
  workstationActions,
} from '../../../../_redux/actions';
import _ from 'lodash';
import { WorkstationStore } from '../../../../_redux/models/workstation.model';
import dayjs from 'dayjs';
import { UserStore } from '../../../../_redux/models/user.model';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import SettingsModal from '../SettingsModal';
import { POLICIES_SCHEDULES } from '../../../../utils/constants';
import { settingsReservations } from '../../types';

const CalendarRelease: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [releasedDates, setReleasedDates] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showModifyDuration, setShowModifyDuration] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastHomeOffice, setShowToastHomeOffice] = useState(false);
  const reservationsHistory = useReservationsHistory();
  const [settingsReservation, setSettingsReservation] =
    useState<settingsReservations>({
      schedule: 'complete_day',
      customStart: null,
      customEnd: null,
      config: 'SAME_DESK_CONFIGURATION',
    });
  const { history, locationState } = reservationsHistory;
  const {
    user: { favouriteDeskPolicy, deskReservationConfiguration },
    reservationPolicies,
  } = useSelector((state: UserStore) => state.user);

  const fromLiberateSlots = locationState.slots;

  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  const [minDate, setMinDate] = useState<Date>(
    new Date(dayjs(dayjs().tz().format('YYYY-MM-DD 00:00:00')).toISOString()),
  );
  const [maxDate, setMaxDate] = useState<Date>(
    new Date(dayjs(dayjs().tz().format('YYYY-MM-DD 23:59:00')).toISOString()),
  );
  const [showCalendar, setshowCalendar] = useState<boolean>(false);

  const { error, isLoading, reservableDays, configurations } = useSelector(
    (state: WorkstationStore) => state.workstation,
  );
  const { reservedWorkstation, msg } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const isLoadingReservation = useSelector(
    (state: ReservationStore) => state.reservation.isLoading,
  );

  const [numWeeks, setNumWeeks] = useState<number>(1);
  const wsReleasedDates = useMemo(
    () =>
      reservedWorkstation.releasedDatesTypes.map(
        releasedDate => releasedDate.date,
      ),
    [reservedWorkstation.releasedDatesTypes],
  );

  const handleSelectDate = dates => {
    if (
      !reservableDays.some(day => day === dayjs(dates).format('YYYY-MM-DD'))
    ) {
      return;
    }

    if (!wsReleasedDates.includes(dayjs(dates).format('YYYY-MM-DD'))) {
      if (!releasedDates.includes(dayjs(dates).format('YYYY-MM-DD'))) {
        setReleasedDates([...releasedDates, dayjs(dates).format('YYYY-MM-DD')]);
      } else {
        setReleasedDates(
          releasedDates.filter(d => d != dayjs(dates).format('YYYY-MM-DD')),
        );
      }
    }
  };

  const handleDismissToast = () => {
    setShowToast(false);
    setShowToastHomeOffice(false);
    dispatch(workstationActions.resetErrorWorkstation());
  };

  const getMessageAlert = () => {
    const liberate = t('lbl_desk_liberate', {
      desk: reservedWorkstation.code,
      days: groupDatesByMonth(
        releasedDates.map(e => dayjs(e).format('YYYY-MM-DD')),
        localLanguage,
        true,
      ),
    });
    const proceed = t('lbl_proceed');
    return `${!_.isEmpty(releasedDates) ? liberate : ''}
    } ${proceed}`;
  };

  const dataLegend = [
    { type: 'releasedDays', text: t('released_days') },
    { type: 'homeOffice', text: t('lbl_homeOffice') },
  ];

  useIonViewDidEnter(() => {
    !showCalendar && setshowCalendar(true);
  });
  useEffect(() => {
    error && setShowToast(true);
  }, [error]);

  const handleCancel = () => {
    history.push('/spaces/workstation/reservationdetail');
  };

  const releaseDesks = () => {
    const type = POLICIES_SCHEDULES.find(
      schedule => schedule.value === settingsReservation.schedule,
    );
    const startHour = configurations.policies.find(
      policy => policy.nombre === type.id,
    ).fullHoraMin;
    const endHour = configurations.policies.find(
      policy => policy.nombre === type.id,
    ).fullHoraMax;

    const dates = releasedDates.map(date => {
      return {
        date: dayjs(date).format('YYYY-MM-DD'),
        type: type.id,
        startHour:
          settingsReservation.schedule === 'custom_schedule'
            ? settingsReservation.customStart
            : startHour,
        endHour:
          settingsReservation.schedule === 'custom_schedule'
            ? settingsReservation.customEnd
            : endHour,
      };
    });
    const releasedDeskDays: ReleasedDeskDays = {
      spaceId: reservedWorkstation.id,
      type: type.id,
      dates: dates,
    };
    dispatch(
      reservationActions.setPermanentDeskReleasedDays(
        releasedDeskDays,
        reservedWorkstation.name,
      ),
    );
  };
  useEffect(() => {
    if (favouriteDeskPolicy && deskReservationConfiguration && configurations) {
      setSettingsReservation({
        ...settingsReservation,
        schedule: POLICIES_SCHEDULES?.find(
          e => e.id == favouriteDeskPolicy.nombre,
        ).value,
        customStart:
          favouriteDeskPolicy.nombre === 'Personalizada'
            ? favouriteDeskPolicy?.fullHoraMin
            : configurations.policies.find(p => p.nombre === 'Personalizada')
                ?.fullHoraMin ?? '00:00',
        customEnd:
          favouriteDeskPolicy.nombre === 'Personalizada'
            ? favouriteDeskPolicy?.fullHoraMax
            : configurations.policies.find(p => p.nombre === 'Personalizada')
                ?.fullHoraMax ?? '23:59',
        config: deskReservationConfiguration.configurationName,
      });
    }
  }, [favouriteDeskPolicy, deskReservationConfiguration, configurations]);

  useEffect(() => {
    if (!isLoading && !_.isEmpty(reservableDays)) {
      const dates = [...reservableDays].sort();
      setMinDate(new Date(_.first(dates)));
      setMaxDate(new Date(_.last(dates)));

      const firstDate = dayjs(_.first(dates)).startOf('day').toISOString();
      const lastDate = dayjs(_.last(dates)).endOf('day').toISOString();
      setNumWeeks(getNumberOfWeeks(firstDate, lastDate));

      const dateIni = dayjs(_.first(dates))
        .subtract(1, 'day')
        .startOf('day')
        .toISOString();
      const dateEnd = dayjs(_.last(dates))
        .add(1, 'day')
        .endOf('day')
        .toISOString();

      dispatch(
        reservationActions.getReservedDaysWsAndHomeOffice(dateIni, dateEnd),
      );
    }
  }, [reservableDays, isLoading]);

  useEffect(() => {
    dispatch(workstationActions.getReservableDays(true));
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/workstation/detail"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('calendar_title')}
        />
        {releasedDates?.length > 0 && (
          <Snackbar
            type="info"
            text={t('lbl_date_selected', {
              number: releasedDates.length,
              multiple: releasedDates.length !== 1 ? 's' : '',
            })}
            align="center"
          />
        )}
      </IonHeader>
      <IonContent fullscreen>
        <Toast
          isOpen={showToast}
          onDidDismiss={handleDismissToast}
          message={error}
          position="bottom"
          type="error"
        />
        <Toast
          isOpen={showToastHomeOffice}
          onDidDismiss={handleDismissToast}
          message={t('msg_homeoffice')}
          position="bottom"
          type="homeOffice"
        />
        <div className={styles.gridWeb}>
          <div className={styles.calendarGrid}>
            {showCalendar && (
              <>
                <WeekCalendarMultiselector
                  selected={releasedDates}
                  reserved={wsReleasedDates}
                  homeOffice={[]}
                  onChange={handleSelectDate}
                  locale={localLanguage}
                  weeks={numWeeks}
                  maxSelectedDays={20}
                  reservableDays={reservableDays}
                  minDate={minDate}
                  maxDate={maxDate}
                  classNames={{
                    notAvailable: styles.notAvailable,
                    homeOffice: styles.homeOffice,
                    reserved: styles.reserved,
                    selected: styles.selected,
                    today: styles.today,
                    available: styles.available,
                  }}
                />
              </>
            )}
          </div>
          <IonLoading
            isOpen={isLoading || isLoadingReservation}
            message={t('msg_loading')}
            duration={2000}
          />
        </div>
      </IonContent>
      <IonFooter>
        <Legend className={styles.centerLegend}>
          {dataLegend.map((item, idx) => {
            return <LegendItem key={idx} type={item.type} text={item.text} />;
          })}
        </Legend>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              onClick={handleCancel}
              disabled={isLoading || isLoadingReservation}
            >
              {t('lbl_cancel')}
            </Button>
          </div>
          <div>
            <Button
              onClick={() =>
                fromLiberateSlots
                  ? setShowModifyDuration(true)
                  : setShowAlert(true)
              }
              disabled={!releasedDates.length}
            >
              {t(fromLiberateSlots ? 'choose_duration' : 'lbl_modify')}{' '}
              {(isLoading || isLoadingReservation) && (
                <IonSpinner
                  slot="end"
                  className={styles.color_primary}
                  name="lines-small"
                />
              )}
            </Button>
          </div>
        </div>
      </IonFooter>
      {configurations && settingsReservation && (
        <SettingsModal
          title={t('lbl_select_liberate_schedule')}
          releasedDates={releasedDates}
          release
          id="release-workspace-modal"
          isOpen={showModifyDuration}
          onClose={() => setShowModifyDuration(false)}
          onSave={() => releaseDesks()}
          setOpenModal={setShowModifyDuration}
          setValue={value => setSettingsReservation(value)}
          settingsReservation={settingsReservation}
          configurations={configurations}
          value={settingsReservation.schedule}
          favouriteDeskPolicies={reservationPolicies}
        />
      )}

      <Alert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('hdr_desk_liberate')}
        message={getMessageAlert()}
        buttons={[
          { text: 'No', role: 'cancel', handler: () => setShowAlert(false) },
          { text: t('lbl_affirmative'), handler: () => releaseDesks() },
        ]}
        mode="ios"
      ></Alert>
      {msg && (
        <Toast
          isOpen={!!msg}
          onDidDismiss={() =>
            dispatch(reservationActions.resetMsgReservation())
          }
          message={msg.description}
          position="bottom"
          type={msg.type}
        ></Toast>
      )}
    </IonPage>
  );
};

export default CalendarRelease;
