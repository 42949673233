import { TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DetailCard } from '../../../components/Base/DetailCard';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { UserStore } from '../../../_redux/models/user.model';
import {
  ReservationStore,
  LocationData,
} from '../../../_redux/models/reservation.model';
import { WorkstationStore } from '../../../_redux/models/workstation.model';
import { AppStore } from '../../../_redux/models/app.model';
import { userActions, workstationActions } from '../../../_redux/actions';
import { MapWorkPlace } from './../../../components/MapWorkPlace/MapWorkPlace';
import styles from './styles.module.scss';

const WorkPlace: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkedDistrict, setCheckedDistrict] = useState(false);

  const { user, isLoading } = useSelector((state: UserStore) => state.user);

  const { permanentDesks: desksPermanents } = useSelector(
    (store: ReservationStore) => store.reservation,
  );

  const permanentDesks = desksPermanents.filter(
    p => p.idSede === user?.defaultSede.id,
  );

  const { mapWorkstations, workstations } = useSelector(
    (state: WorkstationStore) => state.workstation,
  );

  const { temporalVisitsDistrict } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  const hasPermanentDesk = permanentDesks.length > 0;
  const hasDesksOnWorkPlace = workstations.length > 0;

  const desks = hasPermanentDesk
    ? permanentDesks
    : workstations.filter(ws => ws.location.district === user.districtName);

  const mapImage = hasPermanentDesk
    ? permanentDesks[0].mapImage
    : mapWorkstations;

  const permanentDeskLocation =
    permanentDesks && (permanentDesks[0]?.location as LocationData);
  const buildingName =
    user?.buildingName ?? permanentDeskLocation?.buildingName;
  const floorName = user?.floorName ?? permanentDeskLocation?.floorName;

  useEffect(() => {
    dispatch(workstationActions.getAvailableWsByFloor(+user.floor));
  }, []);

  useEffect(() => {
    if (!checkedDistrict && !user.districtName && !user.district) {
      dispatch(userActions.getUser());
      setCheckedDistrict(true);
    }
  }, [user, checkedDistrict]);

  const hasValidDistrictName =
    user.districtName && user.district !== temporalVisitsDistrict;
  const hasValidDistrict =
    user.district && user.district !== temporalVisitsDistrict;
  const isVisitor = user.district && user.district === temporalVisitsDistrict;

  return (
    <IonPage>
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_personal_work_place')}
        />
      </IonHeader>
      <IonContent fullscreen>
        {hasValidDistrictName && hasValidDistrict && (
          <DetailCard
            name={t('workstation')}
            type="workplace"
            icon="icon icon-neighbourhood"
            subName={user.districtName}
          />
        )}
        {hasValidDistrict ? (
          <div className={styles.gridWeb}>
            <div className={styles.listGrid}>
              <DetailList>
                {buildingName && floorName && (
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-campus"
                    startIconColor="primary"
                    title={`${t(
                      'building_filter_workroom',
                    )} ${buildingName}, ${floorName}`}
                  />
                )}
                {hasPermanentDesk && (
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-seat"
                    startIconColor="primary"
                    title={`${t('permanent_desk_assigned')}`}
                    description={permanentDesks[0].name}
                  />
                )}
              </DetailList>
              {(hasPermanentDesk || hasDesksOnWorkPlace) && (
                <MapWorkPlace
                  hasPermanentDesk={hasPermanentDesk}
                  hasDistrict={!!user?.districtName}
                  points={desks}
                  mapImage={mapImage}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={styles.center}>
            <IonLabel className={`${styles.title_2} ${styles.paddingTitle}`}>
              {isVisitor
                ? t('not_available_zone_visitors')
                : t('not_available_zone')}
            </IonLabel>
          </div>
        )}
      </IonContent>
      <IonLoading isOpen={checkedDistrict && isLoading} />
    </IonPage>
  );
};

export default WorkPlace;
