import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonLoading,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import { workroomActions } from '../../../../_redux/actions';
import { didCameraPermission } from '../../../../utils/functions';

const ScanQRCode: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [showCamera, setShowCamera] = useState(false);
  const { isLoading, error } = useSelector(
    (state: WorkroomStore) => state.workroom,
  );

  useEffect(() => {
    const backFunction = ev => {
      ev.detail.register(5, () => {
        handleClose();
      });
    };
    checkPermission();
    document.addEventListener('ionBackButton', backFunction);
    return () => {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(workroomActions.resetErrorWorkroom());
      backWithError();
    }
  }, [error]);

  const checkPermission = async () => {
    const permission = await didCameraPermission();
    permission ? scanCode() : history.replace('/dashboard/actions');
  };

  const backWithError = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    history.replace('/dashboard/actions', {
      error: t('msg_err_scan_qr_code'),
    });
  };

  const scanCode = async () => {
    BarcodeScanner.hideBackground();
    setShowCamera(true);
    const data = await BarcodeScanner.startScan();
    try {
      const spaceCodeQr = JSON.parse(data.content).code;
      if (spaceCodeQr === undefined) {
        return backWithError();
      }
      if (data.hasContent) {
        setShowCamera(false);
        BarcodeScanner.showBackground();
        dispatch(workroomActions.getWorkroomDetail(spaceCodeQr));
      }
    } catch (error) {
      setShowCamera(false);
      BarcodeScanner.showBackground();
      history.replace('/dashboard/actions', {
        error: t('msg_err_scan_qr_code'),
      });
    }
  };

  const handleClose = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    history.replace('/dashboard/actions');
  };

  return (
    <IonPage>
      <IonHeader className={`ion-no-border`}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="secondary">
            <IonButton className={styles.icon} onClick={handleClose}>
              <IonIcon slot="icon-only" className="icon icon-close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={showCamera && styles.transparentContent}>
        <div className={styles.container}>
          <div className={styles.barcodeScannerAreaContainer}>
            <div className={`${styles.square} ${styles.surroundCover}`}>
              <div
                className={`${styles.barcodeScannerAreaOuter} ${styles.surroundCover}`}
              >
                <div className={styles.barcodeScannerAreaInner}></div>
              </div>
            </div>
          </div>
        </div>
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default ScanQRCode;
