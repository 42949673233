import { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
} from '@ionic/react';
import { ActionSheet, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { isEmpty } from '../../../../utils/functions';
import TodayReservation from './TodayReservation/TodayReservation';
import PersonalDataItem from '../../../../components/Base/PersonalDataItem/PersonalDataItem';
import Accordion from '../../../../components/Base/Accordion/Accordion';
import { UserInformation } from './types';
import styles from './styles.module.scss';
import { useLocationState } from '../../../../hooks/useLocationState';

const PersonDetail: React.FC = () => {
  const { t } = useTranslation();
  const state = useLocationState() as any;
  const [userInfo, setUserInfo] = useState<UserInformation>({
    personalData: [],
    todayReservation: {},
    todayReservations: [],
  });
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const [telephone, setTelephone] = useState<string>(null);

  const getTodayReservationsArray = todayReservations => {
    return _.isArray(todayReservations)
      ? todayReservations
      : new Array(todayReservations);
  };

  useEffect(() => {
    if (state && !isEmpty(state)) {
      setUserInfo({
        personalData: [
          {
            text: t('lbl_personal_company'),
            data: state.company,
          },
          {
            text: t('lbl_user_data_corpo_phone'),
            data: state.professionalMobile || null,
            onClick: () => setOpenActionSheet(true),
          },
          {
            text: t('lbl_user_data_corpo_email'),
            data: state.professionalEmail,
            link: state.professionalEmail
              ? `mailto:${state.professionalEmail}`
              : null,
          },
          {
            text: t('lbl_personal_country'),
            data: state.workCenterCountry,
          },
          {
            text: t('incident_location'),
            data: state.location,
          },
          {
            text: t('building_filter_workroom'),
            visible: !!state.buildingName,
            data: state.buildingName,
          },
          {
            text: t('lbl_floor'),
            visible: !!state.floorName,
            data: state.floorName,
          },
          {
            text: t('lbl_district'),
            visible: !!state.district,
            data: state.district,
          },
          {
            text: t('lbl_homeOffice_today'),
            visible: !!state.homeOfficeToday,
            data: state.homeOfficeToday
              ? t('lbl_affirmative')
              : t('lbl_negative'),
          },
        ],
        todayReservation: state?.todayReservation,

        todayReservations: state?.todayReservations
          ? getTodayReservationsArray(state?.todayReservations)
          : [],
      });
      !isEmpty(state.professionalMobile) &&
        setTelephone(state.professionalMobile);
    }
  }, [state]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              defaultHref="/profile/search"
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_profile_detail')}
        />
      </IonHeader>
      <IonContent>
        <div>
          <div className={styles.headerProfileContainer}>
            <div className={`${styles.headerProfile} ${styles.gridWeb}`}>
              <div className={styles.headerPhotoProfile}>
                {state?.fullName?.charAt(0)}
                {state?.fullName?.split(' ')[1][0]}
              </div>
              <div className={styles.headerDataProfile}>
                <IonText className={styles.nameProfile}>
                  {state?.fullName}
                </IonText>
              </div>
            </div>
          </div>

          <div className={styles.gridWeb}>
            <Accordion
              legend={
                <h2 className={styles.headline}>
                  {t('lbl_profile_personal_data')}
                </h2>
              }
              content={userInfo.personalData
                .filter(i => i.visible !== false)
                .map(
                  (item, index) =>
                    item.text && (
                      <PersonalDataItem
                        key={index}
                        title={item.text}
                        data={item.data}
                        link={item.link}
                        onClick={item.onClick}
                      />
                    ),
                )}
            />
            {!_.isEmpty(userInfo.todayReservations) && (
              <>
                <div className={styles.infoTitle}>
                  {t('lbl_job_reservations_today')}
                </div>
                {userInfo.todayReservations.map((e, index) => {
                  return (
                    <TodayReservation
                      key={index}
                      {...e}
                      useType={e?.type}
                      employeeIdSearched={state?.employeeId}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
        {telephone && (
          <ActionSheet
            cssClass={styles.actionsheetSty}
            isOpen={openActionSheet}
            mode="ios"
            onDidDismiss={() => setOpenActionSheet(false)}
            buttons={[
              {
                cssClass: styles.btnActionSheetOptions,
                text: t('call_to', { number: telephone }),
                handler: () => {
                  window.open(`tel:${telephone}`, '_self');
                  setOpenActionSheet(false);
                },
              },
              {
                cssClass: styles.btnActionSheetCancel,
                text: `${t('cancel_text')}`,
                role: 'cancel',
                handler: () => {
                  setOpenActionSheet(false);
                },
              },
            ]}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default PersonDetail;
