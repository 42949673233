import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonItem,
  IonFooter,
  IonSpinner,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import { TopBar, Button, DayCalendar, Toast } from '@acciona/ui-ionic-kit';
import { WorkstationStore } from '../../../../_redux/models/workstation.model';
import { AppStore } from '../../../../_redux/models/app.model';
import { ReservationWs } from '../../../../_redux/models/reservation.model';
import { UserStore } from '../../../../_redux/models/user.model';
import {
  NotificationsActions,
  workstationActions,
} from '../../../../_redux/actions';
import { ReservationCard } from '../../../../components/Base/ReservationCard';
import { BackButton } from '../../../../components/Base/BackButton';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';
import { getIconSchedule } from '../../helpers';
import { useCanReservationDeskForToday } from '../../../../hooks/useCanReservationDeskForToday';
import { useLocationState } from '../../../../hooks/useLocationState';

const LastMinute: React.FC = () => {
  const { t } = useTranslation();
  const { isDeskReservationForTodayActive } = useCanReservationDeskForToday();
  const dispatch = useDispatch();
  const history = useHistory();
  const [notSelectedDates, setNotSelectedDates] = useState<string[]>([]);
  const [showToastError, setShowToastError] = useState(false);
  const state = useLocationState() as any;
  const { preSelectedWs, error, msg, isLoading } = useSelector(
    (state: WorkstationStore) => state.workstation,
  );
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  const { districtName: userDistrict, defaultSede } = useSelector(
    (state: UserStore) => state.user.user,
  );

  const userLockerMode = useSelector(
    (store: AppStore) => store.app.globalSettings.lockerEmployeeKey,
  );
  useEffect(() => {
    error && setShowToastError(true);
  }, [error]);

  useEffect(() => {
    if (state && state.notSelectedDates) {
      setNotSelectedDates(state.notSelectedDates.sort());
    }
  }, [state]);

  const handleDismissToastError = () => {
    setShowToastError(false);
    dispatch(workstationActions.resetErrorWorkstation());
  };

  const handleReserveWorkstations = async () => {
    const wsReservation = [].concat(
      ...[],
      ...preSelectedWs.map(ws => {
        return ws.reservations.map(rDate => {
          const reservation: any = {
            spaces: [ws.spaceId],
            startDate: rDate.reservationDate,
            type: state.type,
            startHour: state.startHour,
            endHour: state.endHour,
          };
          if (state.isWorkstationModification) {
            reservation.oldReservationId =
              state.datesToId[rDate.reservationDate];
          }
          return reservation;
        });
      }),
    ) as ReservationWs;

    const config = {
      wsReservation: wsReservation,
      mapFlow: false,
      textHeader: state.textHeader,
      reservationDates: wsReservation.map(r => r.startDate),
      isModification: state.isWorkstationModification,
    };

    if (state.isWorkstationModification) {
      dispatch(
        NotificationsActions.setGenericAlertWithButtons(
          t('lbl_change_workstation'),
          t('text_change_workstation'),
          [
            {
              text: t('lbl_cancel'),
              role: 'dismiss',
              id: '0',
            },
            {
              text: t('lbl_affirmative'),
              id: '1',
              handler: () => {
                dispatch(workstationActions.reserveLastMinuteWs(config));
              },
            },
          ],
        ),
      );
    } else {
      dispatch(workstationActions.reserveLastMinuteWs(config));
    }
  };

  const handleLinkClick = (location, reservations, preSelectedId) => {
    history.push('/workstation/mapselectorlastminute', {
      building: location.building,
      floor: location.floor,
      selectedDates: reservations.map(r => r.reservationDate),
      inFlow: true,
      preSelectedId: preSelectedId,
      notSelectedDates: notSelectedDates,
      type: state.type,
      startHour: state.startHour,
      endHour: state.endHour,
      textHeader: state.textHeader,
      isWorkstationModification: state.isWorkstationModification,
      datesToId: state.datesToId,
    });
  };
  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <BackButton
              onClick={() => {
                if (state.isWorkstationModification) {
                  history.goBack();
                } else {
                  history.push(
                    isDeskReservationForTodayActive
                      ? '/dashboard/actions'
                      : '/workstation/calendar',
                  );
                }
              }}
            />
          }
          title={t('desk_reservation')}
        />
        <div className={styles.snackbar}>
          <IonItem className={styles.headerConfig}>
            <IonLabel>{state?.textHeader ? state.textHeader : ''}</IonLabel>
          </IonItem>
        </div>
      </IonHeader>

      <IonContent>
        <div className={styles.gridWeb}>
          {preSelectedWs && preSelectedWs.length > 1 ? (
            <DetailListItem
              lines="full"
              description={t('msg_lastminute_nowork_same')}
              descriptionColor="primary"
            />
          ) : null}
          <IonList>
            {preSelectedWs &&
              preSelectedWs.map(item => (
                <IonItem
                  key={item.spaceId}
                  lines="full"
                  className={styles.itemList}
                >
                  <ReservationCard
                    data={{
                      id: item.spaceId.toString(),
                      type: 'Desk',
                      header: item.name,
                      title: item.location.district,
                      lockerId: userLockerMode ? '' : item.lockerId,
                      descriptionSede: defaultSede.description,
                      link: item.availableDesk
                        ? t('lbl_available', { value: item.availableDesk })
                        : null,
                      neighborhood:
                        userDistrict === item?.location?.district?.toString(),
                      onLinkClick: () =>
                        handleLinkClick(
                          item.location,
                          item.reservations,
                          item.spaceId,
                        ),
                    }}
                    endContent={
                      <IonIcon
                        className={`icon icon-${getIconSchedule(state?.type)}`}
                      />
                    }
                    icon="icon icon-seat"
                    displayLockerId={userLockerMode ? false : true}
                    headerTranslucent={false}
                    infoSize="large"
                    mode="ios"
                  >
                    {_.orderBy(item.reservations, 'reservationDate').map(
                      (date: any, index: number) => (
                        <DayCalendar
                          locale={localLanguage}
                          key={index}
                          date={
                            new Date(
                              dayjs(
                                `${dayjs(date.reservationDate)
                                  .utc()
                                  .format('YYYY-MM-DD')} 00:00:00`,
                              ).toISOString(),
                            )
                          }
                          selected={false}
                          id={index.toString()}
                          disabled
                        />
                      ),
                    )}
                  </ReservationCard>
                </IonItem>
              ))}
            {!isEmpty(notSelectedDates) &&
              notSelectedDates.map((date, index) => (
                <IonItem key={index} lines="full" className={styles.itemList}>
                  <ReservationCard
                    data={{
                      id: 'nodataid',
                      type: 'notfound',
                      header: dayjs(date)
                        .utcOffset(0, true)
                        .format('ddd DD MMM'),
                      title: t('msg_no_workstation'),
                      subtitle: '',
                      neighborhood: false,
                    }}
                    icon={'icon icon-alert'}
                    infoSize="large"
                    headerTranslucent={false}
                  />
                </IonItem>
              ))}
          </IonList>
          <Toast
            isOpen={showToastError}
            onDidDismiss={handleDismissToastError}
            message={error}
            position="bottom"
            type="info"
          />
          <Toast
            onDidDismiss={() => dispatch(workstationActions.resetMsg())}
            isOpen={!!msg}
            message={msg}
            position="bottom"
            type="info"
          />
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              onClick={() => {
                if (state.isWorkstationModification) {
                  history.goBack();
                } else {
                  history.push('/dashboard/actions');
                }
              }}
              disabled={isLoading}
            >
              {t('lbl_cancel')}
            </Button>
          </div>
          <div>
            <Button onClick={handleReserveWorkstations} disabled={isLoading}>
              {t('btn_confirm_reservation')}
              {isLoading && (
                <IonSpinner
                  slot="end"
                  className={styles.color_primary}
                  name="lines-small"
                />
              )}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default LastMinute;
