import { useRef, useState } from 'react';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { Icon, TopBar } from '@acciona/ui-ionic-kit';
import { SheetModal } from '../../../../../components/Base/SheetModal';
import { DetailList } from '../../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../../components/Base/DetailListItem';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import { isEmpty } from '../../../../../utils/functions';
import styles from './styles.module.scss';

type Props = {
  backgroundImageDesktop: string;
  backgroundImageMobile?: string;
  title?: string;
  subtitle?: string;
  menuTitle?: string;
  menuIcon?: string;
  menuItems?: any[];
  itemDetailUrl?: string;
  onOpenMenu?: () => void;
  children?: React.ReactNode;
};

export const Cover = (props: Props) => {
  const {
    backgroundImageDesktop,
    backgroundImageMobile,
    title,
    subtitle,
    menuTitle,
    menuIcon,
    menuItems,
    itemDetailUrl,
    onOpenMenu,
    children,
  } = props;
  const history = useHistory();
  const contentPage = useRef<any>();
  const isMobile = useMediaQuery('(max-width: 376px)');
  const [openMenu, setOpenMenu] = useState(false);

  const backgroundImage =
    isMobile && backgroundImageMobile
      ? backgroundImageMobile
      : backgroundImageDesktop;

  const goToContentSection = () => {
    const viewportHeight = contentPage.current?.offsetHeight;
    contentPage.current?.scrollByPoint(0, viewportHeight - 0, 3000);
  };

  const handleShowMenu = () => {
    goToContentSection();
    setOpenMenu(true);
    onOpenMenu && onOpenMenu();
  };

  const onClickItemHandler = (itemId: number) => {
    history.push(itemDetailUrl, {
      id: itemId,
    });
  };

  return (
    <IonPage className={styles.page}>
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
        />
      </IonHeader>
      <IonContent
        className={styles.contentSty}
        scrollEvents={true}
        fullscreen
        ref={contentPage}
        onIonScroll={e => e.detail.scrollTop}
      >
        <div className={styles.imgContentWrapper}>
          <div className={styles.imgContent}>
            <div className={styles.imgOverlay}></div>
            {!isEmpty(backgroundImage) && <img src={backgroundImage} alt="" />}
          </div>
        </div>
        <div className={`${styles.fixedSectionContent} ${styles.paddingGrid}`}>
          <div className={styles.largeTitleBold}>{title}</div>
          <div
            className={`${styles.largeTitleLight} ${styles.largetittlePosition}`}
          >
            {subtitle}
          </div>
        </div>

        <SheetModal
          className={`${styles.sheetModalPosition} ${styles.paddingGrid}`}
        >
          <div className={styles.sheetModalContain}>
            <div className={styles.sheetModalContent}>
              <div
                onClick={handleShowMenu}
                className={`${
                  openMenu && styles.color_primary
                    ? styles.color_secondary
                    : styles.color_primary
                }`}
              >
                <Icon className={`icon icon-${menuIcon} icon-24`} />
                <div className={`${styles.subhead}`}>{menuTitle}</div>
              </div>
            </div>
            <div className={styles.separator} />
            <div>
              <DetailList>
                {!isEmpty(menuItems) &&
                  menuItems.map(item => (
                    <DetailListItem
                      type="button"
                      key={item.id}
                      lines="inset"
                      titleLight={item.name}
                      titleColor="primary"
                      endIcon="icon icon-chevron-right"
                      onClick={() => onClickItemHandler(item.id)}
                    />
                  ))}
              </DetailList>
            </div>
          </div>
        </SheetModal>
        {children}
      </IonContent>
    </IonPage>
  );
};
