import dayjs from 'dayjs';

export const hourToDateFormat = (
  time: string,
  format: string = 'YYYY-MM-DDTHH:mm:ss',
): string => {
  const [hours, minutes] = time.split(':').map(v => +v);
  return dayjs()
    .set('hours', hours)
    .set('minutes', minutes)
    .set('seconds', 0)
    .format(format);
};

export const extractHourFromDate = (dateString: string): string => {
  const match = dateString.match(/T([0-9]{2}:[0-9]{2}):/);
  if (match) return match[1];
  return '';
};

export const addHoursToDate = (
  dateString: string,
  deltaHours: number,
): string => {
  const currentDate = dayjs(dateString);
  return currentDate
    .set('hours', currentDate.hour() + deltaHours)
    .format('YYYY-MM-DDTHH:mm:ss');
};
