import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CallbackMobile: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent>
        <IonLoading isOpen={true} message={t('msg_loading')} duration={0} />
      </IonContent>
    </IonPage>
  );
};

export default CallbackMobile;
