import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewsStore } from '../../../../../_redux/models/news.model';
import { NewsActions } from '../../../../../_redux/actions/news.actions';
import styles from './styles.module.scss';
import { NewsSecondaryCard } from '../../../../../components/Base/NewsSecondaryCard';
import useDataNews from '../../../../../hooks/useDataNews';
import { Button } from '@acciona/ui-ionic-kit';

export type Props = {
  handleSelected: Function;
  category: string;
};

const ListNewsCatPagination: React.FC<Props> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, isLoadedCategories } = useSelector(
    (store: NewsStore) => store.news,
  );

  const { newsByCategories } = useSelector((store: NewsStore) => store.news);

  const newsBy =
    newsByCategories.find(e => e.category === props.category) ?? null;
  const moreNews = newsBy?.moreNews ?? false;
  const newsCard = useDataNews(newsBy?.news) ?? null;
  const handleLoadNews = () => {
    dispatch(NewsActions.newsRequest());
    dispatch(NewsActions.getNewsByCategory(props.category, false));
  };

  return (
    <>
      {newsCard && (
        <>
          <IonGrid className={styles.gridWeb}>
            <IonRow className={styles.listCards}>
              {newsCard.map(data => {
                return (
                  <IonCol
                    id={data.id}
                    key={data.id}
                    size="12"
                    sizeSm="6"
                    sizeMd="6"
                    sizeLg="6"
                    sizeXl="4"
                    className={styles.colAlignCenter}
                  >
                    <NewsSecondaryCard
                      data={data}
                      onClick={() => props.handleSelected(data.nav_id)}
                      mode="ios"
                    />
                  </IonCol>
                );
              })}
            </IonRow>
            {isLoading ||
            (isLoadedCategories &&
              !isLoadedCategories.includes(props.category)) ? (
              <div className="ion-text-center">
                <IonSpinner name="dots"></IonSpinner>
              </div>
            ) : (
              moreNews && (
                <div className={`${styles.buttonMoreContainer}`}>
                  <Button
                    id="buttonMoreNews"
                    className={styles.moreNews}
                    color="lighter"
                    onClick={handleLoadNews}
                  >
                    {t('lbl_load_more_news')}
                  </Button>
                </div>
              )
            )}
          </IonGrid>
        </>
      )}
    </>
  );
};

export default ListNewsCatPagination;
