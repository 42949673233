import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../_helpers/history';
import { NewsActions } from '../_redux/actions';
import { NewsStore } from '../_redux/models/news.model';

export const useNewsCaptureLinksOnClick = (type: string) => {
  const dispatch = useDispatch();
  const news = useSelector((store: NewsStore) => store.news.news);
  const notice = useSelector((state: NewsStore) => state.news.notice);
  const linkNoticeAndNewsRedirect = (
    href: string,
    uuid: string,
    origin: string,
    pathOrigin: string,
  ) => {
    const action = href.split('#')[1] ?? 'news';

    action === 'notice'
      ? dispatch(
          NewsActions.selectNotice(
            uuid,
            history.location.pathname,
            origin,
            pathOrigin,
          ),
        )
      : dispatch(
          NewsActions.selectNews(
            uuid,
            history.location.pathname,
            false,
            origin,
            pathOrigin,
          ),
        );
  };

  useEffect(() => {
    if (type === 'notification') return;

    const idOrigin = type === 'news' ? news.nav_id : notice.nav_id;
    if (idOrigin) {
      const handleDocumentClick = event => {
        const regex =
          /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
        const target = event.target;
        const link = target.closest('a');

        if (link?.href) {
          const matches = link.href.match(regex) ?? null;
          const uuid = matches ? matches[0] : null;
          if (matches) {
            event.preventDefault();
            linkNoticeAndNewsRedirect(
              link.href,
              uuid,
              idOrigin,
              type === 'news' ? '/news/detail' : '/notification-detail',
            );
          }
        }
      };

      document.addEventListener('click', handleDocumentClick);
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }
  }, [news, notice]);
};
