import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocationState } from '../../../../hooks/useLocationState';

import { workstationActions } from '../../../../_redux/actions';

import {
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
} from '@ionic/react';
import { Button, TopBar } from '@acciona/ui-ionic-kit';
import { BackButton } from '../../../../components/Base/BackButton';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';

import {
  convertDateToWrittenFormat,
  getIsToday,
} from '../../../../utils/dateTime';

import styles from './styles.module.scss';

type CheckBoxOption = {
  id: string;
  status: boolean;
  name: string;
};

const WsModifyDatesSelector = () => {
  const { t } = useTranslation();

  const initialInputState: CheckBoxOption[] = [
    { id: 'all', status: true, name: t('select_all') },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useLocationState();
  const [inputDates, setInputDates] =
    useState<CheckBoxOption[]>(initialInputState);

  const handleInput = (id: string, value: boolean): void => {
    setInputDates(oldState => {
      // Do not uncheck the clicked
      // when all are checked
      if (oldState.every(i => i.status) && id !== 'all') {
        return oldState.map(i => {
          if (i.id === id) {
            return { ...i, status: true };
          }
          return { ...i, status: false };
        });
      }
      // toggle the clicked
      // and check all if all is clicked
      return oldState.map(i => {
        if (i.id === id || id === 'all') {
          return { ...i, status: value };
        }
        return i;
      });
    });
  };

  const updateHeaderText = (previusHeader: string, filteredDates) => {
    const splittedHeader = previusHeader.split(' | ');
    splittedHeader[0] =
      filteredDates.length === 1
        ? getIsToday(filteredDates[0])
          ? t('today')
          : t('day_label', { number: filteredDates.length })
        : t('day_label', { number: filteredDates.length, multiple: 's' });
    return splittedHeader.join(' | ');
  };

  const handleConfirm = () => {
    const filteredDates = state.selectedDates?.filter((date: string) => {
      return inputDates.find(inputDate => {
        return inputDate.id === date;
      }).status;
    });

    dispatch(
      workstationActions.getLastMinuteSelectWs(
        filteredDates,
        state.config,
        state.typeReservation,
        state.startHour,
        state.endHour,
        updateHeaderText(state.header, filteredDates),
        state.deskReservationPolicy,
      ),
    );
  };

  useEffect(() => {
    if (!state.selectedDates || state.selectedDates?.length < 1) return;
    setInputDates(() => {
      return [
        ...initialInputState,
        ...state.selectedDates.map((date: string) => {
          return { id: date, status: true, name: date };
        }),
      ];
    });
  }, [state.selectedDates]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={<BackButton onClick={history.goBack} />}
          title={t('lbl_select_dates')}
        ></TopBar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={`${styles.listGrid}`}>
            <IonItem lines="none" className={styles.text}>
              <span>{t('msg_select_ws_to_modify')}</span>
            </IonItem>
            <IonItem>
              <b>{t('dates')}</b>
            </IonItem>
            <DetailList>
              {inputDates.map(({ id, status, name }) => {
                return (
                  <DetailListItem
                    key={id}
                    lines="inset"
                    type="button"
                    className={styles.workstationContainer}
                  >
                    <IonCheckbox
                      slot={'start'}
                      mode="ios"
                      onIonChange={(e: any) => {
                        handleInput(id, e.target.checked);
                      }}
                      checked={status}
                      className={styles.checkbox}
                    />

                    <IonLabel>
                      <div className={styles.title}>
                        {id === 'all' ? name : convertDateToWrittenFormat(name)}
                      </div>
                      {id !== 'all' && (
                        <div className={styles.subtitle}>
                          {state.header?.split(' | ')[1]}
                        </div>
                      )}
                    </IonLabel>
                  </DetailListItem>
                );
              })}
            </DetailList>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              disabled={inputDates.every(i => !i.status)}
              onClick={handleConfirm}
            >
              {t('btn_confirm_selection')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default WsModifyDatesSelector;
