import {
  WorkstationAction,
  Workstation,
  Floor,
  WorkstationRequest,
  Configurations,
} from '../models/workstation.model';
import { workstationServices } from '../services/workstation/workstation.services';
import { WorkstationTypesEnum } from '../types/workstation.types.enum';
import { reservationActions } from './reservations.actions';
import { history } from '../../_helpers/history';
import {
  getMsgWorkstation,
  getReservableDaysConsideringPermanency,
  isEmpty,
} from '../../utils/functions';
import i18n from '../../i18nextConf';
import { workroomServices } from '../services';
import { Building } from '../models/workroom.model';
import { Raffle, ReservationWs } from '../models/reservation.model';
import _ from 'lodash';
import { UserWorkPreferences } from '../models/user.model';
import { userActions } from './user.actions';

const workstationRequest = (): WorkstationAction => {
  return {
    type: WorkstationTypesEnum.REQUEST,
  };
};

const resetWorkstations = (): WorkstationAction => {
  return {
    type: WorkstationTypesEnum.RESET_WORKSTATIONS,
  };
};

const setWorkstation = (workstation: Workstation): WorkstationAction => {
  return {
    type: WorkstationTypesEnum.SET_WORKSTATION,
    workstation: workstation,
  };
};

const setMsg = (msg: string): WorkstationAction => {
  return {
    type: WorkstationTypesEnum.SET_MESSAGE,
    msg: msg,
  };
};

const resetMsg = (): WorkstationAction => ({
  type: WorkstationTypesEnum.RESET_MESSAGE,
});

const resetErrorWorkstation = (): WorkstationAction => ({
  type: WorkstationTypesEnum.RESET_ERROR,
});

const resetLoading = (): WorkstationAction => {
  return {
    type: WorkstationTypesEnum.RESET_LOADING,
  };
};
const setLoadingWorkstations = (): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_ISLOADING_WORKSTATIONS,
});
const resetLoadingWorkstations = (): WorkstationAction => ({
  type: WorkstationTypesEnum.RESET_ISLOADING_WORKSTATIONS,
});

const setFavWorkstations = (
  workstations: Workstation[],
): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_FAV_WORKSTATIONS,
  favWorkstations: workstations,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const addFavWorkstations = (workstation: Workstation): WorkstationAction => ({
  type: WorkstationTypesEnum.ADD_FAV_WORKSTATION,
  workstation: workstation,
});

const deleteFavWorkstations = (
  workstation: Workstation,
): WorkstationAction => ({
  type: WorkstationTypesEnum.DELETE_FAV_WORKSTATION,
  workstation: workstation,
});

const setMapWorkstations = (mapImage: string): WorkstationAction => {
  return {
    type: WorkstationTypesEnum.SET_MAP_FLOOR,
    mapWorkstations: mapImage,
  };
};

const setWorkstations = (workstations: Workstation[]): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_WORKSTATIONS,
  workstations: workstations,
});

const setPreSelectedWs = (preSelectedWs: Workstation[]): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_PRESELECTED_WORKSTATIONS,
  preSelectedWs: preSelectedWs,
});

const setFloors = (floors: Floor[]): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_FLOORS,
  floors: floors,
});

const setBuildings = (buildings: Building[]): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_BUILDINGS,
  buildings: buildings,
});

const setRaffleState = (raffle: Raffle): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_RAFFLE_STATE,
  raffle: raffle,
});

const setVisualConfig = (config: string): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_VISUAL_CONFIG,
  config: config,
});

const setReservableDays = (
  reservableDays: string[],
  hasReservableDays: boolean,
): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_RESERVABLE_DAYS,
  reservableDays: reservableDays,
  hasReservableDays: hasReservableDays,
});

const setWsConfigurations = (
  configurations: Configurations,
): WorkstationAction => ({
  type: WorkstationTypesEnum.SET_WORKSTATION_CONFIGURATION,
  configurations: configurations,
});

const reserveLastMinuteWs = (config: {
  wsReservation: ReservationWs;
  mapFlow: boolean;
  textHeader: string;
  reservationDates: string[];
  isModification?: boolean;
}) => {
  const {
    wsReservation,
    mapFlow,
    textHeader,
    reservationDates,
    isModification,
  } = config;

  if (_.isEmpty(reservationDates)) {
    throw new Error(i18n.t('error_default'));
  }

  const dates = reservationDates.map(d => d.substring(0, 10));

  const newReservations = wsReservation.filter(r =>
    dates.includes(r.startDate.substring(0, 10)),
  );

  const datesSelected = newReservations.map(item => item.startDate);
  const type = newReservations[0]?.type ?? '';
  const startHour = newReservations[0]?.startHour ?? '';
  const endHour = newReservations[0]?.endHour ?? '';

  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstation = isModification
        ? await workstationServices.modifyWs(wsReservation)
        : await workstationServices.reserveLastMinuteWs(wsReservation);
      dispatch(resetLoading());
      if (workstation === null) {
        history.push('/workstation/noAvailable', {
          title: 'reservation_last_minute',
          header: `${mapFlow ? 'occupied_ws_map' : 'occupied_ws'}`,
          description: 'not_available_des_ws',
          router: isModification
            ? '/dashboard/reservations'
            : '/workstation/calendar',
        });
      } else if (workstation.length === 0) {
        if (mapFlow) {
          history.push('/workstation/noAvailable', {
            title: 'reservation_last_minute',
            header: 'occupied_ws_in_map',
            description: 'occupied_ws_overtaken_map',
            router: isModification
              ? '/dashboard/reservations'
              : '/workstation/calendar',
            datesSelected: datesSelected,
            type: type,
            startHour: startHour,
            endHour: endHour,
            textHeader: textHeader,
          });
        } else {
          dispatch(
            getLastMinuteSelectWs(
              datesSelected,
              undefined, // config
              type,
              startHour,
              endHour,
              textHeader,
            ),
          );
          dispatch(
            setMsg(
              `${i18n.t(
                wsReservation.length > 1
                  ? 'wr_new_asignation_sing'
                  : 'wr_new_asignation_pl',
              )}`,
            ),
          );
        }
      } else {
        const spaces = [];
        workstation.map(re => {
          re.spaces.map(sp => {
            spaces.push(sp.id);
          });
        });

        const isPlural = _.uniq(spaces).length > 1;
        _.uniq(spaces).length === 1 &&
          dispatch(
            reservationActions.getReservationWorkstationFromLastMinute(
              spaces[0],
            ),
          );
        const lockerEmployeeKey =
          state().app.globalSettings?.lockerEmployeeKey ?? '';

        const title = `success_workstation_${isPlural ? 'plural' : 'singular'}${
          isModification ? '_mod' : ''
        }`;

        history.replace('/workstation/confirmation', {
          title,
          msg: getMsgWorkstation(
            workstation[0]?.spaces,
            lockerEmployeeKey.toString(),
          ),
          router:
            _.uniq(spaces).length === 1
              ? '/spaces/workstation/reservationdetail'
              : '/dashboard/reservations',
          parmsRouter: {
            prevRouterConfirm: true,
            datesSelected: datesSelected,
            type: type,
            startHour: startHour,
            endHour: endHour,
          },
        });
      }
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const fetchWorkstationFailure = (error: string): any => {
  return {
    type: WorkstationTypesEnum.FAILURE_WORKSTATION,
    error: error,
  };
};

const getLastMinuteRamdonWs = (datesSelected: string[]) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstations: Workstation[] =
        await workstationServices.getLastMinuteRamdonWs(datesSelected);
      dispatch(resetLoading());
      if (isEmpty(workstations)) {
        history.push('/workstation/noAvailable', {
          dates: datesSelected,
          title: 'reservation_last_minute',
          header: 'not_available_header_ws',
          description: 'not_available_des_ws',
        });
      } else {
        //Group by id and filter dates with no workstations available
        const wr = _.cloneDeep(workstations).reduce((acc: any, obj) => {
          const index = acc.findIndex(space => space.spaceId === obj.spaceId);
          if (index < 0) {
            acc.push(obj);
          } else {
            acc[index].reservations.push(obj.reservations[0]);
          }
          if (datesSelected.includes(obj.reservations[0].reservationDate)) {
            datesSelected = datesSelected.filter(
              dt => dt !== obj.reservations[0].reservationDate,
            );
          }
          return acc;
        }, []);
        if (wr.length === 1) {
          dispatch(
            reservationActions.getReservationWorkstationFromLastMinute(
              wr[0].spaceId,
            ),
          );
          history.replace('/workstation/confirmation', {
            title: 'success_workstation_singular',
            workstations: workstations,
            router: '/spaces/workstation/reservationdetail',
            parmsRouter: {
              prevRouterConfirm: true,
              type: wr[0].date[0].type,
              startHour: wr[0].date[0].startHour,
              endHour: wr[0].date[0].endHour,
            },
          });
        } else {
          dispatch(setPreSelectedWs(wr));
          history.push('/workstation/lastminute', {
            notSelectedDates: datesSelected,
          });
        }
      }
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const lastMinuteFlow = (
  workstations: any,
  datesSelected: string[],
  dispatch: any,
  type: string,
  startHour: string,
  endHour: string,
  textHeader: string,
  isWorkstationModification: boolean = false,
  datesToId: { [key: string]: string } = {},
) => {
  const wr = _.cloneDeep(workstations.desks).reduce((acc: any, obj) => {
    if (datesSelected.includes(obj.reservations.reservationDate)) {
      datesSelected = datesSelected.filter(
        dt => dt !== obj.reservations.reservationDate,
      );
    }
    const index = acc.findIndex(space => space.spaceId === obj.spaceId);
    if (index === -1) {
      obj.reservations = [obj.reservations];
      acc.push(obj);
    } else {
      acc[index].reservations.push(obj.reservations);
    }
    return acc;
  }, []);
  dispatch(setPreSelectedWs(wr));
  history.push('/workstation/lastminute', {
    notSelectedDates: datesSelected,
    type,
    startHour,
    endHour,
    textHeader: textHeader,
    isWorkstationModification,
    datesToId,
  });
};

const mapSelectorFlow = (
  workstations: any,
  datesSelected: string[],
  dispatch: any,
  defaultConfig: {
    building: number;
    floor: number;
  } = {
    building: null,
    floor: null,
  },
  type: string,
  startHour: string,
  endHour: string,
  textHeader: string,
  isWorkstationModification: boolean = false,
  datesToId: { [key: string]: string } = {},
) => {
  dispatch(
    setMapWorkstations(`data:image/jpeg;base64,${workstations.mapImage}`),
  );
  dispatch(setWorkstations(workstations.desks));
  dispatch(setVisualConfig(workstations.config));

  history.push('/workstation/mapselectorlastminute', {
    building: defaultConfig.building ?? workstations.desks[0].location.building,
    floor: defaultConfig.floor ?? workstations.desks[0].location.floor,
    selectedDates: datesSelected,
    inFlow: false,
    type: type,
    startHour: startHour,
    endHour: endHour,
    textHeader: textHeader,
    isWorkstationModification,
    datesToId,
  });
};

const getVisitsWorkstations = (
  datesSelected: string[],
  defaultBuilding: number,
  defaultFloor: number,
  desksInSameFloorForAllDays: boolean,
  type: string,
  startHour: string,
  endHour: string,
  textHeader: string,
  isWorkstationModification: boolean = false,
  datesToId: { [key: string]: string } = {},
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstationsInUserFloor =
        await workstationServices.getAvailableByFloorAndDates(
          datesSelected,
          defaultFloor,
          defaultBuilding,
          type,
          startHour,
          endHour,
        );

      dispatch(resetLoading());

      // If the desks available are not in the same floor for all the days,
      // no desks shown on the floor, because these desks are not available every day.
      const workstations = desksInSameFloorForAllDays
        ? workstationsInUserFloor
        : {
            ...workstationsInUserFloor,
            desks: [],
          };
      mapSelectorFlow(
        workstations,
        datesSelected,
        dispatch,
        {
          building: defaultBuilding,
          floor: defaultFloor,
        },
        type,
        startHour,
        endHour,
        textHeader,
        isWorkstationModification,
        datesToId,
      );
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getLastMinuteSelectWs = (
  datesSelected: string[],
  config: {
    isVisit: boolean;
    defaultBuilding: number;
    defaultFloor: number;
    isWorkstationModification?: boolean;
    datesToId?: { [key: string]: string };
  } = {
    isVisit: false,
    defaultBuilding: null,
    defaultFloor: null,
    isWorkstationModification: false,
    datesToId: {},
  },
  type: string,
  startHour: string,
  endHour: string,
  textHeader: string,
  deskConfiguration: string = null,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(setLoadingWorkstations());
    dispatch(workstationRequest());
    try {
      const workstations = await workstationServices.getLastMinuteSelectWs(
        datesSelected,
        type,
        startHour,
        endHour,
        deskConfiguration,
        config.isWorkstationModification,
      );
      dispatch(resetLoading());

      if (isEmpty(workstations.desks)) {
        // There is no desk available for any day on the entire Campus
        history.push('/workstation/noAvailable', {
          dates: datesSelected,
          title: 'reservation_last_minute',
          header: 'not_available_header_ws',
          description: 'not_available_des_ws',
        });
      } else if (workstations.mapImage === undefined) {
        // There is no desk on the user's floor
        if (!config.isVisit) {
          // Suggest desks
          lastMinuteFlow(
            workstations,
            datesSelected,
            dispatch,
            type,
            startHour,
            endHour,
            textHeader,
            config.isWorkstationModification,
            config.datesToId,
          );
        } else if (config.isVisit) {
          if (workstations.desks.length === datesSelected.length) {
            // If there are desks available for every day (anywhere on Campus)
            // retrieve the desks and the visits district map

            // This check is needed because backend does not provide available dates
            // in /v1/desk/reservation/availableSpaces service
            const desksInSameFloorForAllDays = workstations.desks.every(
              workstation =>
                workstation.spaceId === workstations.desks[0].spaceId,
            );

            dispatch(
              getVisitsWorkstations(
                datesSelected,
                config.defaultBuilding,
                config.defaultFloor,
                desksInSameFloorForAllDays,
                type,
                startHour,
                endHour,
                textHeader,
                config.isWorkstationModification,
                config.datesToId,
              ),
            );
          } else {
            // If there are no desks available throughout the Campus for all days
            // suggest desks for the days when desks are available
            lastMinuteFlow(
              workstations,
              datesSelected,
              dispatch,
              type,
              startHour,
              endHour,
              textHeader,
              config.isWorkstationModification,
              config.datesToId,
            );
          }
        }
      } else {
        mapSelectorFlow(
          workstations,
          datesSelected,
          dispatch,
          undefined,
          type,
          startHour,
          endHour,
          textHeader,
          config.isWorkstationModification,
          config.datesToId,
        );
      }
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    } finally {
      setTimeout(() => {
        dispatch(resetLoadingWorkstations());
      }, 1000);
    }
  };
};

const reservePlannedWs = (datesSelected: string[], spaces: number[] = []) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstationRaffles: WorkstationRequest[] =
        await workstationServices.reservePlannedWs(datesSelected, spaces);

      if (workstationRaffles != null) {
        dispatch(reservationActions.setReservedRafflesWr(workstationRaffles));
        history.replace('/workstation/confirmation', {
          title: 'sucess_workstation_planned',
          msg: 'lbl_date_selected_planned',
          router: '/spaces/workstation/requestdetail',
          parmsRouter: { prevRouterConfirm: true },
        });
      } else {
        history.push('/workstation/noAvailable', {
          dates: datesSelected,
          title: 'reservation_last_minute',
          header: 'not_available_header_ws',
          description: 'not_available_des_ws',
        });
      }
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const setFavorite = (workstation: Workstation) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      await workstationServices.setFavorite(workstation.spaceId);

      dispatch(
        reservationActions.setReservedWorkstation({
          ...workstation,
          favorite: true,
        }),
      );
      dispatch(resetLoading());
      dispatch(
        reservationActions.msgReservation({
          type: 'success',
          description: `${i18n.t('add_desk_favourite_success', {
            desk: workstation.code,
          })}`,
        }),
      );
    } catch (e) {
      //TODO: need to check error type
      dispatch(
        reservationActions.msgReservation({
          type: 'error',
          description: `${i18n.t('wr_favorite_limit')}`,
        }),
      );
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const updateFavorites = (workstationIds: number[]) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      await workstationServices.updateFavorites(workstationIds);

      history.replace('/workstation/confirmation', {
        title: 'success_update_favorites',
        router: '/profile/user/favworkstations',
        parmsRouter: { prevRouterConfirm: true },
      });
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const deleteFavorite = (workstation: Workstation) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      await workstationServices.deleteFavorite(workstation.spaceId);
      dispatch(deleteFavWorkstations(workstation));
      dispatch(
        reservationActions.setReservedWorkstation({
          ...workstation,
          favorite: false,
        }),
      );
      dispatch(
        reservationActions.msgReservation({
          type: 'success',
          description: `${i18n.t('delete_desk_favourite_success', {
            desk: workstation.code,
          })}`,
        }),
      );
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getFavoriteWorkstations = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstations: Workstation[] =
        await workstationServices.getFavoriteWorkstations();

      dispatch(setFavWorkstations(workstations));
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getBuildings = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const buildings = await workroomServices.getListBuildings();
      dispatch(setBuildings(buildings));
    } catch (e) {
      dispatch(fetchWorkstationFailure(`${i18n.t('lbl_building_error')}`));
    }
  };
};

const getFloors = (buildingId: number) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const floors: Floor[] = await workstationServices.getFloors(buildingId);
      dispatch(setFloors(floors));
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getAvailableWsByFloor = (floorId: number) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstations = await workstationServices.getAvailableWsByFloor(
        floorId,
      );
      dispatch(
        setMapWorkstations(`data:image/jpeg;base64,${workstations.mapImage}`),
      );
      dispatch(setWorkstations(workstations.desks));
    } catch (e) {
      dispatch(fetchWorkstationFailure(`${i18n.t('lbl_floor_error')}`));
    }
  };
};

const loadMapByBuilding = (
  buildingId: number,
  floorId: number = null,
  datesSelected: string[] = [],
  type: string = '',
  startHour: string = '',
  endHour: string = '',
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      dispatch(setWorkstations([]));
      dispatch(setMapWorkstations(null));
      const floors: Floor[] = await workstationServices.getFloors(buildingId);
      //Load the second floor array (0 floor)
      floorId = floorId === null ? floors[0].id : floorId;
      dispatch(setFloors(floors));
      !datesSelected.length
        ? dispatch(getAvailableWsByFloor(floorId))
        : dispatch(
            getAvailableByFloorAndDates(
              datesSelected,
              buildingId,
              floorId,
              type,
              startHour,
              endHour,
            ),
          );
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getAvailableByFloorAndDates = (
  datesSelected: string[],
  buildingId: number,
  floorId: number,
  type: string,
  startHour: string,
  endHour: string,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstations =
        await workstationServices.getAvailableByFloorAndDates(
          datesSelected,
          floorId,
          buildingId,
          type,
          startHour,
          endHour,
        );
      dispatch(
        setMapWorkstations(`data:image/jpeg;base64,${workstations.mapImage}`),
      );
      dispatch(setVisualConfig(workstations.config));
      dispatch(setWorkstations(workstations.desks));
    } catch (e) {
      dispatch(fetchWorkstationFailure(`${i18n.t('lbl_floor_error')}`));
    }
  };
};

const loadMapByBuildingPlanned = (
  buildingId: number,
  floorId: number = null,
  datesSelected: string[],
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const floors: Floor[] = await workstationServices.getFloors(buildingId);
      //Load the second floor array (0 floor)
      floorId = floorId === null ? floors[0].id : floorId;
      dispatch(setFloors(floors));
      dispatch(
        getAvailableByFloorAndDatesPlanned(datesSelected, buildingId, floorId),
      );
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getAvailableByFloorAndDatesPlanned = (
  datesSelected: string[],
  buildingId: number,
  floorId: number,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const workstations =
        await workstationServices.getAvailableByFloorAndDatesPlanned(
          datesSelected,
          floorId,
          buildingId,
        );
      dispatch(
        setMapWorkstations(`data:image/jpeg;base64,${workstations.mapImage}`),
      );
      dispatch(setWorkstations(workstations.desks));
    } catch (e) {
      dispatch(fetchWorkstationFailure(`${i18n.t('lbl_floor_error')}`));
    }
  };
};

const resetDataMap = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(setWorkstations([]));
    dispatch(setMapWorkstations(null));
  };
};

const getRaffle = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const raffleState = await workstationServices.getRaffle();
      dispatch(setRaffleState(raffleState));
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getReservableDays = (releaseMode: boolean = false) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const reservableDays = await workstationServices.getReservableDays();
      const permanentDesks = state()?.reservation?.permanentDesks ?? [];
      const defaultSedeId = state()?.user?.user?.defaultSede?.id ?? 0;

      const reservableConsideringPermanency =
        getReservableDaysConsideringPermanency(
          reservableDays,
          permanentDesks,
          defaultSedeId,
        );

      const hasReservableDays =
        isEmpty(permanentDesks) ||
        !isEmpty(reservableConsideringPermanency.after);

      if (releaseMode) {
        dispatch(
          setReservableDays(
            reservableConsideringPermanency.before,
            hasReservableDays,
          ),
        );
      } else {
        dispatch(
          setReservableDays(
            reservableConsideringPermanency.after,
            hasReservableDays,
          ),
        );
      }
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

const getWsConfigurations = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(workstationRequest());
    try {
      const configuration: Configurations =
        await workstationServices.getWsConfigurations();
      //check favourite
      let favPolicy = state().user.user.favouriteDeskPolicy;
      let favConf = state().user.user.deskReservationConfiguration;
      let modifyFavs = false;
      const existFavPolicy =
        configuration.policies.find(e => e.id === favPolicy.id) || null;
      const existFavConf =
        configuration.deskReservationConfigurations.find(deskReservation => {
          return (
            deskReservation.configurationName === favConf.configurationName
          );
        }) || null;
      if (!existFavPolicy) {
        if (!isEmpty(configuration.policies))
          favPolicy = configuration.policies[0];
        modifyFavs = true;
      }

      if (!existFavConf) {
        if (!isEmpty(configuration.deskReservationConfigurations))
          favConf = configuration.deskReservationConfigurations[0];
        modifyFavs = true;
      }

      if (modifyFavs) {
        const newWorkstationPreferences: UserWorkPreferences = {
          favouriteDeskPolicy: favPolicy,
          deskReservationConfiguration: favConf,
          deskFavouriteTypes: [],
        };
        dispatch(
          userActions.saveUserDeskPreferences(newWorkstationPreferences),
        );
      }

      dispatch(setWsConfigurations(configuration));
    } catch (e) {
      dispatch(fetchWorkstationFailure(e.message));
    }
  };
};

export const workstationActions = {
  setWorkstation,
  setBuildings,
  setPreSelectedWs,
  reserveLastMinuteWs,
  fetchWorkstationFailure,
  getLastMinuteRamdonWs,
  getLastMinuteSelectWs,
  getVisitsWorkstations,
  reservePlannedWs,
  resetErrorWorkstation,
  setFavorite,
  deleteFavorite,
  getFavoriteWorkstations,
  getBuildings,
  getFloors,
  getAvailableWsByFloor,
  updateFavorites,
  loadMapByBuilding,
  getAvailableByFloorAndDates,
  loadMapByBuildingPlanned,
  getAvailableByFloorAndDatesPlanned,
  resetDataMap,
  setFloors,
  getRaffle,
  resetMsg,
  getReservableDays,
  getWsConfigurations,
  setReservableDays,
  resetWorkstations,
  setWorkstations,
  setLoadingWorkstations,
  resetLoadingWorkstations,
};
