import styles from './styles.module.scss';
export const classes = {
  workstation: `${styles.divIcon} ${styles.workstation}`,
  parking: `${styles.divIcon} ${styles.parking}`,
  locker: `${styles.divIcon} ${styles.locker}`,
  parkingRequest: `${styles.divIcon} ${styles.parkingRequest}`,
  passengerInvitation: `${styles.divIcon} ${styles.parkingRequest}`,
  workroom: `${styles.divIcon} ${styles.workroom}`,
  workplace: `${styles.divIcon} ${styles.workplace}`,
  permanentWorkstation: `${styles.divIcon} ${styles.workstation}`,
  permanentParking: `${styles.divIcon} ${styles.parking}`,
  canteen: `${styles.divIcon} ${styles.canteen}`,
  passenger: `${styles.divIcon} ${styles.parking}`,
};
