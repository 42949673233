import { useDispatch, useSelector } from 'react-redux';
import { NotificationsActions } from '../_redux/actions';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { passengersServices } from '../_redux/services/passengers/passengers.services';
import {
  EInvitationStatus,
  IPassegerInvitation,
} from '../_redux/services/passengers/types';
import { parkingServices } from '../_redux/services';
import { UserStore } from '../_redux/models/user.model';
import dayjs from 'dayjs';
import { getNowTimeCampus, setWeekdayAndTime } from '../utils/dateTime';

const getInvitationDates = async (
  driverId: string,
): Promise<IPassegerInvitation['dates']> => {
  const invitation = await passengersServices.getPassengerInvitationByDriver(
    driverId,
  );
  const invitationDates = invitation.dates.filter(d => !d.accepted);
  return invitationDates;
};

const isAcceptedInvitationForDay = (
  invitationDates: IPassegerInvitation['dates'],
): { allDatesOccupied: boolean; someDatesOccupied: boolean } => {
  const allDatesOccupied = invitationDates.every(date => !date.isUserFree);
  const someDatesOccupied =
    !allDatesOccupied && invitationDates.some(date => !date.isUserFree);
  return {
    allDatesOccupied,
    someDatesOccupied,
  };
};

export const useRaffleWard = () => {
  const sedeId = useSelector(
    (state: UserStore) => state.user.user.defaultSede.id,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRaffleInProgress = (redirect = false): void => {
    dispatch(
      NotificationsActions.setGenericAlertWithButtons(
        t('lbl_passenger_invitation.header.raffle_ongoing'),
        t('lbl_passenger_invitation.desc.raffle_ongoing'),
        [
          {
            text: t('ok_text'),
            role: 'confirm',
            handler: () => {
              redirect && history.replace('/notifications', {});
            },
          },
        ],
      ),
    );
  };

  const handleRaffleFinished = (redirect = false): void => {
    dispatch(
      NotificationsActions.setGenericAlertWithButtons(
        t('lbl_passenger_invitation.header.raffle_finished'),
        t('lbl_passenger_invitation.desc.raffle_finished'),
        [
          {
            text: t('ok_text'),
            role: 'confirm',
            handler: () => {
              redirect && history.replace('/notifications', {});
            },
          },
        ],
      ),
    );
  };

  return async (
    notificationDate: string,
    redirect = false,
  ): Promise<boolean> => {
    const { inProgress, startHour, weekDay } = await parkingServices.getRaffle(
      sedeId,
    );
    if (inProgress) {
      handleRaffleInProgress(redirect);
      return false;
    }

    const nowDateTime = getNowTimeCampus();
    const notificationDateTime = dayjs(notificationDate);
    const raffleDateTime = setWeekdayAndTime(getNowTimeCampus(), {
      time: startHour,
      weekday: weekDay + 1, // TODO: remove when back fixes weekdays
    });

    // check if the notification was created
    // before the raffle dateTime
    const isNotificationPreviousToRaffle =
      notificationDateTime.isBefore(raffleDateTime);

    const raffleHasPassed = raffleDateTime.isBefore(nowDateTime);

    // show raffle has finished modal when notification
    // was previous to raffle and raffle has ended
    if (isNotificationPreviousToRaffle && raffleHasPassed) {
      handleRaffleFinished(redirect);
      return false;
    }
    return true;
  };
};

export const usePassengerInvitation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAllDatesOccupied = (): void => {
    dispatch(
      NotificationsActions.setGenericAlert(
        t('lbl_passenger_invitation.header.all_days_occupied'),
        t('lbl_passenger_invitation.desc.all_days_occupied'),
      ),
    );
  };

  const handleSomeDatesOccupied = (
    driverId: string,
    redirect: boolean = true,
  ): void => {
    dispatch(
      NotificationsActions.setGenericAlertWithButtons(
        t('lbl_passenger_invitation.header.some_days_occupied'),
        t('lbl_passenger_invitation.desc.some_days_occupied'),
        [
          {
            text: t('continue_text'),
            role: 'confirm',
            handler: () => {
              if (redirect) {
                history.push('/parking/passengerInvitation', {
                  driverId,
                });
              }
            },
          },
        ],
      ),
    );
  };

  const handleCheckError = (): void => {
    dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
  };

  const handleUserRejectedInvitation = (): void => {
    dispatch(
      NotificationsActions.setGenericAlert(
        t('lbl_passenger_invitation.header.user_reject'),
        t('lbl_passenger_invitation.desc.user_reject'),
      ),
    );
  };

  const handleNoActionRequired = (driverId: string): void => {
    dispatch(
      NotificationsActions.setGenericAlertWithButtons(
        t('lbl_passenger_invitation.header.no_action_required'),
        t('lbl_passenger_invitation.desc.no_action_required'),
        [
          {
            text: t('close_text'),
            role: 'cancel',
            handler: () => {},
          },
          {
            text: t('canteenReservActiveModal.btn_routerToReserv'),
            role: 'confirm',
            handler: async () => {
              history.replace('/parking/passengerInvitationDetail', {
                driverId,
                isFromConfirmation: true,
              });
            },
          },
        ],
      ),
    );
  };

  const handleInvitationRemoved = (): void => {
    dispatch(
      NotificationsActions.setGenericAlert(
        t('lbl_passenger_invitation.header.invitation_removed'),
        t('lbl_passenger_invitation.desc.invitation_removed'),
      ),
    );
  };

  const handleRequestRemoved = (): void => {
    dispatch(
      NotificationsActions.setGenericAlert(
        t('lbl_passenger_invitation.header.request_removed'),
        t('lbl_passenger_invitation.desc.request_removed'),
      ),
    );
  };

  const checkUserAvailability = async (
    driverId: string,
    redirectOnSomeDates: boolean = true,
  ): Promise<boolean> => {
    const invitationDates = await getInvitationDates(driverId);

    if (invitationDates.length === 0) {
      handleRequestRemoved();
      return false;
    }

    const { allDatesOccupied, someDatesOccupied } =
      isAcceptedInvitationForDay(invitationDates);

    if (allDatesOccupied) {
      handleAllDatesOccupied();
      return false;
    }
    if (someDatesOccupied) {
      handleSomeDatesOccupied(driverId, redirectOnSomeDates);
      // returns always false, unless the redirect is true
      // which only happens when the hook is called after the push
      // notification, when you need the hook to return true in
      // this case so it does not redirect the user to "/"
      return !redirectOnSomeDates;
    }
    return true;
  };

  const checkRaffleStatus = useRaffleWard();

  const checkInvitationStatus = async (driverId: string): Promise<boolean> => {
    const invitationStatus: string =
      await passengersServices.getPassengerInvitationStatus(driverId);
    switch (invitationStatus) {
      case EInvitationStatus.Rejected:
        handleUserRejectedInvitation();
        return false;
      case EInvitationStatus.Handled:
        handleNoActionRequired(driverId);
        return false;
      case EInvitationStatus.InvitationRemoved:
        handleInvitationRemoved();
        return false;
      case EInvitationStatus.RequestRemoved:
        handleRequestRemoved();
        return false;
      default:
        return true;
    }
  };

  const passengerInvitationWard = async (
    driverId: string | undefined,
    notificationDateTime: string,
    redirectOnSomeDates: boolean = true,
  ): Promise<boolean> => {
    if (driverId === undefined) return true;

    try {
      const raffleStatus = await checkRaffleStatus(notificationDateTime);
      if (!raffleStatus) return false;
      const invitationStatus = await checkInvitationStatus(driverId);
      if (!invitationStatus) return false;
      const userAvailability = await checkUserAvailability(
        driverId,
        redirectOnSomeDates,
      );
      if (!userAvailability) return false;
      return true;
    } catch (err) {
      handleCheckError();
      return false;
    }
  };

  return passengerInvitationWard;
};
