import muleSoftInstance from '../../utils/httpCommon';
import { ROUTES } from './mockData';

const getShuttles = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_SERVICES}/shuttles`;
  try {
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getRoutes = async (): Promise<any> => {
  //const path = `${process.env.REACT_APP_API_HABITAT}/v1/howtoget/routes`;
  try {
    //const resp = await muleSoftInstance.get(path);
    //return resp.data;
    return ROUTES;
  } catch (err) {
    throw new Error(err);
  }
};

export const shuttlesAndRoutesServices = {
  getShuttles,
  getRoutes,
};
