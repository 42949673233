import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isPlatform,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSpinner,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _ from 'lodash';
/* Redux */
import { NewsActions } from '../../../_redux/actions/news.actions';
import { reservationActions } from '../../../_redux/actions/reservations.actions';
import { NewsStore } from '../../../_redux/models/news.model';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import { UserStore } from '../../../_redux/models/user.model';
import { AppStore } from '../../../_redux/models/app.model';
/* Custom hooks */
import useDataNews from '../../../hooks/useDataNews';
/* Types */
import { filterTypes } from './types';
/* Components */
import { DashboardReservCard } from '../../../components/Base/DashboardReservCard';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { ListCard } from '../../../components/Base/ListCard';
import { NewsCarrousel } from '../../../components/Base/NewsCarrousel';
import { NewsSecondaryCard } from '../../../components/Base/NewsSecondaryCard';
import Can from '../../../components/Can/Can';
/* Helpers */
import { isEmpty } from '../../../utils/functions';
import { history } from '../../../_helpers/history';
import { getEndContent } from '../Reservation/ListReservation/helpers';
import {
  getReservCardHeaderText,
  getReservCardSubtitleText,
  getReservCardTitleText,
  getTodayReservations,
} from './helpers';
/* Styles */
import styles from './home.module.scss';
import {
  RESERVATION_PERMISSIONS,
  USER_LOCKER_MODE,
} from '../../../utils/constants';

export const ScrollSection = ({ backgroundClass }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newslistCarrousel, newslistOther } = useSelector(
    (store: NewsStore) => store.news.home,
  );
  const { isLoading } = useSelector((store: NewsStore) => store.news);

  const {
    district: userDistrict,
    permissionAllHeadOffices,
    sedesList,
  } = useSelector((state: UserStore) => state.user.user);

  const hasReservationPermission = _.some(
    permissionAllHeadOffices,
    obj => _.intersection(obj.permissions, RESERVATION_PERMISSIONS).length > 0,
  );

  const { globalSettings } = useSelector((store: AppStore) => store.app);
  const getuserLockerMode = idSede => {
    return (
      sedesList.find(e => e.id == idSede)?.lockerEmployeeKey ??
      USER_LOCKER_MODE.DESK_LOCKER
    );
  };
  const filterType = filterTypes;
  const contentFeaturedNews = useRef<any>();
  const newsCardCarrousel = useDataNews(newslistCarrousel);
  const newsCardOther = useDataNews(newslistOther);

  const todayReservations = useSelector((store: ReservationStore) =>
    getTodayReservations(store.reservation.reservations),
  );
  const permanentSpaces = useSelector((store: ReservationStore) =>
    [
      ...store.reservation.permanentDesks,
      ...store.reservation.permanentParkings,
      ...store.reservation.reservations.filter(
        reservation => reservation.useType === 'Locker',
      ),
    ]
      .map(r => {
        r.releasedDates = r?.releasedDates ?? [];
        if (
          r.releasedDates.filter(d => dayjs(d).tz().isSame(dayjs().tz(), 'day'))
            .length == 0
        ) {
          return {
            ...r,
          };
        }
      })
      .filter(notUndefined => notUndefined !== undefined),
  );

  const handleSelected = id => {
    dispatch(NewsActions.selectNews(id, history.location.pathname));
  };

  const handleSelectedReservation = reserve => {
    switch (reserve.useType) {
      case 'Desk':
        dispatch(
          reservationActions.getReservationWorkstation(
            reserve.id,
            reserve.dates[0]?.reservationType,
            reserve.dates[0]?.startHour,
            reserve.dates[0]?.endHour,
          ),
        );
        break;
      case 'Room':
        dispatch(
          reservationActions.getReservationWorkroom(
            reserve.dates[0].reservationId,
          ),
        );
        break;
      case 'Canteen':
        dispatch(
          reservationActions.getReservationCanteen(
            reserve.dates[0].reservationId,
          ),
        );
        break;
      case 'Parking':
        dispatch(
          reservationActions.getReservationParking(
            reserve.id,
            reserve.dates[0]?.reservationType,
            reserve.dates[0]?.startHour,
            reserve.dates[0]?.endHour,
          ),
        );
        break;
      case 'Room-WaitingList':
        dispatch(reservationActions.getWaitingListWr(reserve.id));
        break;
      case 'Parking-Request':
        // dispatch(reservationActions.getReservationParking(reserve.id));
        break;
      case 'Desk-Request':
        // dispatch(reservationActions.getReservationParking(reserve.id));
        break;
      case 'Locker':
        dispatch(reservationActions.getReservationLocker(reserve.id));
        break;
      case 'Passenger':
        history.push('/parking/passengerDetail', {
          driver: reserve.driverName,
          descriptionSede: reserve.descriptionSede,
          dates: reserve.dates,
        });
        break;
      default:
        break;
    }
  };

  const raffleDay = dayjs()
    .tz()
    .day(5)
    .format(`DD [${t('lbl_of_date')}] MMMM`);

  return (
    <div
      className={`${styles.scrollSectionContent} ${
        isPlatform('android') && isPlatform('capacitor')
          ? styles.scrollSectionContentAndroid
          : ''
      }`}
    >
      <IonGrid
        ref={contentFeaturedNews}
        className={`${styles.gridSectionContent} ${
          backgroundClass ? styles.scrolled : ''
        }`}
      >
        <Can functionality="news">
          <div className={styles.newsHeader}>
            <DetailListItem
              mode="ios"
              lines="none"
              routerLink="/dashboard/news"
              className={`${styles.gridWebHome} ${styles.toolbarNews} ${styles.noHover}`}
              titleClass={styles.titlePaddings}
              endIcon="icon icon-arrow"
              endIconColor="secondary"
              title={t('dashboard_reports')}
            />
          </div>
          <div className={styles.isMobile}>
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner name="dots"></IonSpinner>
              </div>
            )}
            <div className={styles.newsSlidesContent}>
              {newsCardCarrousel && !isEmpty(newsCardCarrousel) && (
                <NewsCarrousel
                  data={_.take(
                    newsCardCarrousel,
                    globalSettings.dashboardNewsCarouselMobile,
                  )}
                  slideClass={styles.slidesContent}
                  imageClass={styles.imgCard}
                  skeletonClass={styles.skeleton}
                  overlayClass={styles.imgOverlayCarousel}
                  onClick={e => handleSelected(e)}
                  dashboardClass
                />
              )}
            </div>
          </div>
          <div className={styles.isWeb}>
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner name="dots"></IonSpinner>
              </div>
            )}
            <div className={styles.newsCardsContent}>
              {newsCardCarrousel && !isEmpty(newsCardCarrousel) ? (
                <IonGrid className={styles.gridWebHome}>
                  <IonRow className={styles.listCards}>
                    {_.take(
                      newsCardCarrousel,
                      globalSettings.dashboardNewsCarouselDesktop,
                    ).map((data, index) => {
                      return (
                        <IonCol
                          key={index}
                          size="12"
                          sizeSm="6"
                          sizeMd="6"
                          sizeLg="6"
                          sizeXl="4"
                          className={styles.colAlignCenter}
                        >
                          <ListCard
                            className={styles.listCardHome}
                            data={data}
                            onClick={() => handleSelected(data.nav_id)}
                            isNews={true}
                          />
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonGrid>
              ) : null}
            </div>
          </div>
        </Can>
      </IonGrid>
      {hasReservationPermission && (
        <IonGrid className={styles.todayReservations}>
          <div className={styles.gridWebHome}>
            <DetailListItem
              mode="ios"
              lines="none"
              routerLink="/dashboard/reservations"
              type="button"
              className={`${styles.toolbarTodayReservations} ${styles.noHover}`}
              endIcon="icon icon-arrow"
              endIconColor="secondary"
              title={t('dashboard_reservations')}
              titleClass={styles.titlePaddings}
              description={
                todayReservations &&
                _.isEmpty(todayReservations) &&
                _.isEmpty(permanentSpaces) &&
                t('msg_no_reservations_today')
              }
            />
            <div className={styles.listReservationsDashboard}>
              {(todayReservations || permanentSpaces) &&
                [...todayReservations, ...permanentSpaces].map(
                  (space, index) => {
                    return (
                      <React.Fragment key={index}>
                        <IonItem
                          className={styles.reservationItemSty}
                          lines="none"
                        >
                          <DashboardReservCard
                            headerTranslucent={false}
                            data={{
                              id: space.id.toString(),
                              type: space.useType,
                              header: getReservCardHeaderText(space),
                              title: getReservCardTitleText(
                                userDistrict,
                                space,
                              ),
                              subtitle: getReservCardSubtitleText(
                                space,
                                raffleDay,
                                !isEmpty(sedesList)
                                  ? sedesList.find(e => e.id == space?.idSede)
                                      ?.campusTimeZone
                                  : '',
                              ),
                              neighborhood:
                                userDistrict === space.district?.toString(),
                              idSede: space.idSede,
                              lockerId:
                                getuserLockerMode(space.idSede) ===
                                USER_LOCKER_MODE.DESK_LOCKER
                                  ? space.lockerId
                                  : '',
                              descriptionSede: space.descriptionSede,
                              floorName: space.floorName,
                              buildingName: space.buildingName,
                              // reservationType: ['Desk', 'Parking'].includes(
                              //   space.useType,
                              // )
                              //   ? getIconReservationType(
                              //       space.dates[0].reservationType,
                              //     )
                              //   : '',
                            }}
                            icon={
                              filterType.find(
                                filter => filter.id === space.useType,
                              ).icon
                            }
                            onClick={() => handleSelectedReservation(space)}
                            endContent={getEndContent(space)}
                          ></DashboardReservCard>
                        </IonItem>
                      </React.Fragment>
                    );
                  },
                )}
            </div>
          </div>
        </IonGrid>
      )}

      <Can functionality="news">
        <IonGrid className={styles.todayNews}>
          <div className={styles.gridWebHome}>
            <DetailListItem
              mode="ios"
              lines="none"
              routerLink="/dashboard/news"
              type="button"
              className={`${styles.toolbarTodayNews} ${styles.noHover}`}
              endIcon="icon icon-arrow"
              endIconColor="secondary"
              title={t('dashboard_news')}
              titleClass={styles.titlePaddings}
            />
          </div>
          <IonGrid className={`${styles.gridWebHome}`}>
            {isLoading && (
              <div className="ion-text-center">
                <IonSpinner name="dots"></IonSpinner>
              </div>
            )}
            <IonRow className={styles.news}>
              {newsCardOther &&
                !isEmpty(newsCardOther) &&
                newsCardOther.map(data => {
                  return (
                    <IonCol
                      key={data.id}
                      size="12"
                      sizeSm="12"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXl="4"
                      className={styles.colAlignCenter}
                    >
                      <NewsSecondaryCard
                        className={styles.newsSecondaryCardHome}
                        data={data}
                        onClick={() => handleSelected(data.nav_id)}
                        mode="ios"
                        displaySocial={false}
                      />
                    </IonCol>
                  );
                })}
            </IonRow>
          </IonGrid>
        </IonGrid>
      </Can>
    </div>
  );
};
