import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonToggle,
} from '@ionic/react';
import { Alert, Button, Toast, TopBar } from '@acciona/ui-ionic-kit';
import { vehicleActions } from '../../../../../_redux/actions/vehicles.actions';
import { VehicleStore } from '../../../../../_redux/models/vehicles.model';
import { AuthStore } from '../../../../../_redux/models/auth.model';
import { DetailListItem } from '../../../../../components/Base/DetailListItem';
import { DetailList } from '../../../../../components/Base/DetailList';
import TransportZoneSnackBar from '../../components/TransportZoneSnackBar';
import { reservationActions } from '../../../../../_redux/actions';
import { PLUGGABLE_VEHICLE_OPTION_ENABLED } from '../../../../../utils/constants';
import { vehicleIsPluggable } from '../../../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';

const VehicleDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicles = useSelector((state: VehicleStore) => state.vehicle.vehicles);
  const user = useSelector((state: AuthStore) => state.auth.user);
  const vehAttributes = useSelector(
    (state: VehicleStore) => state.vehicle.attributes,
  );
  const {
    configuration: { fields: fieldsBySede, transportZoneText },
  } = useSelector((state: VehicleStore) => state.vehicle);
  const msgState = useSelector((store: VehicleStore) => store.vehicle.msg);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertFavorite, setShowAlertFavorite] = useState(false);
  const [isOtherFavVeh, setIsOtherFavVeh] = useState(false);
  const [contentChange, setContentChange] = useState(false);
  const detail: any = useLocation().state || {};
  const detailVehicle = detail.item || {};
  const [showToast, setShowToast] = useState(false);

  const [updateVehicle, setUpdateVehicle] = useState({
    vehicleId: null,
    employeeId: user.owner,
    brand: null,
    model: null,
    color: null,
    type: null,
    plate: null,
    transportZone: null,
    environmentalBadge: null,
    defaultVehicle: true,
    favourite: false,
    plugin: fieldsBySede.includes('plugin') ? false : null,
  });

  useEffect(() => {
    setUpdateVehicle(detailVehicle);
    dispatch(vehicleActions.getListAttributes());
  }, []);

  useEffect(() => {
    vehicles.find(
      e => e.favourite === true && e.vehicleId != detailVehicle.vehicleId,
    ) && setIsOtherFavVeh(true);
  }, [vehicles]);

  const handleShowModalDelete = () => {
    setShowAlert(true);
  };
  const deleteVehicle = async () => {
    dispatch(
      vehicleActions.deleteListVehicle(detailVehicle.vehicleId, vehicles),
    );
    setShowToast(true);
  };

  const handleShowModalUpdate = () => {
    if (updateVehicle.favourite === false) {
      handleUpdateVehicle();
    } else {
      isOtherFavVeh ? setShowAlertFavorite(true) : handleUpdateVehicle();
    }
  };

  const handleUpdateVehicle = async () => {
    dispatch(
      vehicleActions.modifyVehicle({
        ...updateVehicle,
      }),
    );
  };

  const handleDismissToast = () => {
    setShowToast(false);
    dispatch(reservationActions.resetMsgReservation());
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              defaultHref="/profile/user/vehicles-passengers"
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={detailVehicle.brand}
        />
      </IonHeader>
      {fieldsBySede.includes('transportZone') &&
        transportZoneText &&
        detailVehicle.transportZone && (
          <TransportZoneSnackBar
            text={transportZoneText.text}
            link={transportZoneText.link}
          />
        )}
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {fieldsBySede.includes('brand') && detailVehicle.brand && (
                <DetailListItem
                  titleLight={t('lbl_vehicle_brand')}
                  titleColor="primary"
                  value={detailVehicle.brand}
                  readonly
                />
              )}
              {fieldsBySede.includes('model') && detailVehicle.model && (
                <DetailListItem
                  titleLight={t('lbl_vehicle_model')}
                  titleColor="primary"
                  value={detailVehicle.model}
                  readonly
                />
              )}
              {fieldsBySede.includes('color') && detailVehicle.color && (
                <DetailListItem
                  titleLight={t('lbl_vehicle_color')}
                  titleColor="primary"
                  value={detailVehicle.color}
                  readonly
                />
              )}
              {fieldsBySede.includes('type') && detailVehicle.type && (
                <DetailListItem
                  titleLight={t('lbl_vehicle_type')}
                  titleColor="primary"
                  readonly
                  value={
                    vehAttributes.types.find(
                      e => e.typeId === detailVehicle.type,
                    )?.description
                  }
                />
              )}
              {fieldsBySede.includes('plate') && detailVehicle.plate && (
                <DetailListItem
                  titleLight={t('lbl_vehicle_plate')}
                  titleColor="primary"
                  value={detailVehicle.plate}
                  readonly
                />
              )}
              {fieldsBySede.includes('environmentalBadge') &&
                detailVehicle.environmentalBadge && (
                  <DetailListItem
                    titleLight={t('lbl_eco_label')}
                    titleColor="primary"
                    readonly
                    value={
                      vehAttributes.environmentalBadges.find(
                        e => e.badgeId === detailVehicle.environmentalBadge,
                      )?.description
                    }
                  />
                )}
              {fieldsBySede.includes('transportZone') &&
                detailVehicle.transportZone && (
                  <DetailListItem
                    titleLight={t('lbl_transport_zone')}
                    titleColor="primary"
                    readonly
                    value={
                      vehAttributes.transportZones.find(
                        e => e.zoneId === detailVehicle.transportZone,
                      )?.description
                    }
                  />
                )}
              {PLUGGABLE_VEHICLE_OPTION_ENABLED &&
                vehicleIsPluggable(detailVehicle.type) &&
                fieldsBySede.includes('plugin') &&
                'plugin' in detailVehicle && (
                  <DetailListItem
                    titleLight={t('electric_charge')}
                    titleColor="primary"
                    readonly
                    endText={
                      <IonToggle
                        mode="ios"
                        className={styles.ionToggle}
                        checked={updateVehicle.plugin}
                        onIonChange={e => {
                          setUpdateVehicle({
                            ...updateVehicle,
                            plugin: e.detail.checked,
                          });
                          setContentChange(true);
                        }}
                      />
                    }
                  />
                )}
              <DetailListItem
                titleLight={t('lbl_favorite_vehicle')}
                titleColor="primary"
                readonly
                endText={
                  <IonToggle
                    mode="ios"
                    className={styles.ionToggle}
                    checked={updateVehicle.favourite ? true : false}
                    onIonChange={e => {
                      setUpdateVehicle({
                        ...updateVehicle,
                        favourite: e.detail.checked,
                      });
                      setContentChange(true);
                    }}
                  />
                }
              />
            </DetailList>
            <>
              <Alert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('lbl_delete_vehicle')}
                message={t('lbl_delete_vehicle_confirmation')}
                buttons={[
                  { text: 'No', role: 'cancel' },
                  {
                    text: t('lbl_affirmative'),
                    handler: () => deleteVehicle(),
                  },
                ]}
                mode="ios"
              ></Alert>
            </>
            <>
              <Alert
                isOpen={showAlertFavorite}
                onDidDismiss={() => setShowAlertFavorite(false)}
                message={t('lbl_new_favorite_vehicle')}
                buttons={[
                  { text: 'No', role: 'cancel' },
                  {
                    text: t('lbl_affirmative'),
                    handler: () => handleUpdateVehicle(),
                  },
                ]}
                mode="ios"
              ></Alert>
            </>
          </div>
        </div>
        {msgState && (
          <Toast
            isOpen={showToast}
            message={msgState.description}
            onDidDismiss={handleDismissToast}
            position="bottom"
            type={msgState.type}
          />
        )}
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button color="lighter" onClick={handleShowModalDelete}>
              {t('lbl_delete_vehicle')}
            </Button>
          </div>
          <div>
            <Button
              disabled={contentChange ? false : true}
              onClick={handleShowModalUpdate}
            >
              {t('lbl_save_changes')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default VehicleDetail;
