import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../_redux/models/app.model';

const useFormattedCurrency = () => {
  const { localSettings } = useSelector((state: AppStore) => state.app);
  const [currency, setCurrency] = useState('es-ES');

  useEffect(() => {
    const isSpanish = localSettings.localLanguage === 'es';
    isSpanish ? setCurrency('es-ES') : setCurrency('en-US');
  }, [localSettings.localLanguage]);

  const formatCurrency = useCallback(
    number => {
      const formattedNum = new Intl.NumberFormat(currency, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
      return formattedNum;
    },
    [currency],
  );

  return formatCurrency;
};

export default useFormattedCurrency;
