import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Item } from '@acciona/ui-ionic-kit';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';

export const RequestDetailVehicles = ({ vehicles }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const stateRequestDetail: any = useLocation().state || {};

  return (
    <>
      {!isEmpty(vehicles) && (
        <>
          <DetailListItem
            className={`${styles.itemList} ${styles.itemChevron} ${styles.itemHeader}`}
            lines="none"
            detail={false}
            onClick={() =>
              history.push(
                '/spaces/parking/detail/vehiclesrequest',
                stateRequestDetail,
              )
            }
            startIcon="icon icon-car"
            startIconColor="primary"
            title={`${vehicles.length} ${t('associated_vehicles', {
              multiple: vehicles.length > 1 ? 's' : '',
            }).toLowerCase()}`}
            endIcon="icon icon-chevron-right"
          />
          <Item lines="none">
            <div className={styles.vehicleList}>
              {vehicles.map(vehicle => {
                return (
                  <p
                    key={vehicle.plate}
                    color={'primary'}
                    className={`${styles.description} ion-text-wrap ${styles.subhead} ${styles.block}`}
                  >
                    {`${vehicle.brand} ${vehicle.model} | ${vehicle.plate} `}
                  </p>
                );
              })}
            </div>
          </Item>
        </>
      )}
    </>
  );
};
