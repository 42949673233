import { IonContent, IonHeader, IonPage, IonBackButton } from '@ionic/react';
import { TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { NotificationsStore } from '../../_redux/models/notifications.model';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

const NotificationUnauthorized: React.FC<any> = () => {
  const { t } = useTranslation();
  const title = useSelector(
    (state: NotificationsStore) =>
      state.notifications.notificationUnauthorizedTitle,
  );

  return (
    <IonPage>
      <IonHeader mode="ios">
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={title}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.centeredLabel}>
          <p className={styles.title_2}>{t('lbl_noAccess_Notification')}</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotificationUnauthorized;
