import { Button, Snackbar, Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonSearchbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStore } from '../../../../_redux/models/auth.model';
import { isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';
import { Host, WorkroomStore } from '../../../../_redux/models/workroom.model';
import {
  reservationActions,
  workroomActions,
} from '../../../../_redux/actions';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { useHistory } from 'react-router';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { useLocationState } from '../../../../hooks/useLocationState';

const HostSearch: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [hostName, setHostName] = useState('');
  const [filteredSearch, setFilteredSearch] = useState([]);
  const [selectedHost, setSelectedHost] = useState<Host>();
  const state = useLocationState() as any;
  const hosts: Host[] = useSelector(
    (state: WorkroomStore) => state.workroom.hosts,
  );
  const myUserId = useSelector((state: AuthStore) => state.auth.user.owner);
  const { reservedWorkroom, error } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const diacriticalReplace = string => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  useEffect(() => {
    const tempSearchResult = hosts.filter(
      ele =>
        diacriticalReplace(ele.fullName)
          .toLowerCase()
          .includes(diacriticalReplace(hostName).toLowerCase()) &&
        ele.employeeId !== parseInt(myUserId),
    );
    setFilteredSearch(tempSearchResult);
  }, [hostName]);

  const handleSelectHost = host => {
    setSelectedHost(host);
    setFilteredSearch(
      filteredSearch.filter(val => val.employeeId !== host.employeeId),
    );
    setHostName('');
  };

  const deleteHost = () => {
    setSelectedHost(null);
    setHostName('');
  };

  const handleAddHost = () => {
    if (state.preReservation) {
      dispatch(workroomActions.setPreselectedHost(selectedHost));
    } else {
      dispatch(
        reservationActions.addReservationHost(
          selectedHost,
          reservedWorkroom.date.reservationId,
        ),
      );
    }
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/spaces/workroom/reservationdetail"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_add_host')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <IonSearchbar
            autocapitalize="none"
            value={hostName}
            onIonInput={e => setHostName(e.detail.value!)}
            className={styles.searchBar}
            animated={true}
            autocomplete="on"
            cancelButtonText="cancel-button-text"
            placeholder={t('plholder_search_hosts')}
            disabled={!!selectedHost}
          />
          <Snackbar
            icon="icon icon-info"
            type="info"
            text={t('lbl_host_info')}
            align="left"
          />
          {hostName.length >= 1 &&
            filteredSearch.map((item, index) => {
              return (
                <DetailListItem
                  className={selectedHost ? styles.colorList : ''}
                  key={index}
                  mode="ios"
                  lines="inset"
                  type="button"
                  onClick={() => handleSelectHost(item)}
                  startIcon="icon icon-client"
                  startIconColor="primary"
                  titleLight={item.fullName}
                  description={item.function}
                />
              );
            })}
          {!isEmpty(selectedHost) && (
            <DetailListItem lines="none" title={t('lbl_selected_host')} />
          )}
          {!isEmpty(selectedHost) && (
            <DetailListItem
              key={selectedHost.employeeId}
              mode="ios"
              lines="inset"
              type="button"
              startIcon="icon icon-client"
              startIconColor="primary"
              titleLight={selectedHost.fullName}
              description={selectedHost.function}
              endIconAction="icon icon-delete"
              actionEndIcon={() => deleteHost()}
            />
          )}
        </div>
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
      </IonContent>
      {!isEmpty(selectedHost) && (
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              <Button onClick={handleAddHost}>{t('lbl_add_host')}</Button>
            </div>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default HostSearch;
