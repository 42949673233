import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_redux/actions';
import { AppStore } from '../_redux/models/app.model';
import { AuthStore } from '../_redux/models/auth.model';

export const useOfflineServicesCheck = (interval: number = 60000) => {
  const { isAuth } = useSelector((state: AuthStore) => state.auth);
  const { offlineServices, offlineNetwork } = useSelector(
    (state: AppStore) => state.app,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!offlineNetwork && offlineServices && isAuth) {
        dispatch(userActions.getUserConfig());
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [offlineNetwork, offlineServices, isAuth]);
};
