import i18n from '../../i18nextConf';
import { MORE_PERMISSIONS } from '../../utils/constants';

export const routesWithoutSplitPane = ['/login', '/signout', '/callback'];

export const getNavTabsDesktopConfig = (hasReservationPermission: boolean) => {
  const navTabsHeader = [
    {
      id: 1,
      tab: 'home',
      text: i18n.t('tab_home'),
      icon: 'icon icon-dashboard',
      href: '/dashboard/home',
      permissionCode: 'public',
      show: true,
      accessAllowedInOfflineMode: true,
    },
    {
      id: 2,
      tab: 'reservations',
      text: i18n.t('tab_reservations_menu'),
      icon: 'icon icon-booking',
      href: '/dashboard/reservations',
      permissionCode: 'public',
      show: hasReservationPermission,
      accessAllowedInOfflineMode: true,
    },
    {
      id: 3,
      tab: 'news',
      text: i18n.t('tab_news'),
      icon: 'icon icon-news',
      href: '/dashboard/news',
      permissionCode: 'news',
      show: true,
      accessAllowedInOfflineMode: false,
    },
    {
      id: 4,
      tab: 'more',
      text: i18n.t('tab_more'),
      icon: 'icon icon-more',
      href: '/dashboard/more',
      permissionCode: MORE_PERMISSIONS,
      show: true,
      accessAllowedInOfflineMode: false,
    },
  ];

  const navTabsFooter = [
    {
      id: 1,
      tab: 'actions',
      text: i18n.t('tab_actions'),
      icon: 'icon icon-actions',
      centralButton: true,
      href:
        location.pathname == '/dashboard/actions'
          ? '/dashboard/home'
          : '/dashboard/actions',
      permissionCode: 'public',
      show: true,
      accessAllowedInOfflineMode: false,
    },
  ];

  return {
    navTabsHeader,
    navTabsFooter,
  };
};
