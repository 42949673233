import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonLabel,
  IonBackButton,
} from '@ionic/react';
import { TopBar, Button, Toast } from '@acciona/ui-ionic-kit';
import { NewVehicleModal } from '../../../Profile/VehiclesPassengers/NewVehicleModal/NewVehicleModal';
import { VehicleStore } from '../../../../_redux/models/vehicles.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { reservationActions } from '../../../../_redux/actions';
import { isEmpty } from '../../../../utils/functions';
import { getAvailableVehicles } from '../../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';
import { getParkingRoute } from '../../helpers';
import { AppStore } from '../../../../_redux/models/app.model';

const NotAvailableVehicle: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { vehicles, msg } = useSelector((store: VehicleStore) => store.vehicle);
  const { permissions } = useSelector((state: UserStore) => state.user.user);
  const [showModalAddVehicle, setShowModalAddVehicle] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const { plannedReservation, lastMinuteReservation } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  useEffect(() => {
    !isEmpty(getAvailableVehicles(vehicles, permissions)) &&
      history.replace(
        getParkingRoute(
          true, // User have Vehicles if enter here
          plannedReservation,
          lastMinuteReservation,
        ),
      );
  }, [vehicles]);

  const handleCloseModal = () => {
    setShowModalAddVehicle(false);
    setShowToast(true);
  };

  const handleDismissToast = () => {
    setShowToast(false);
    dispatch(reservationActions.resetMsgReservation());
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/actionsr"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('reservation_title')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={`${styles.gridWeb} ${styles.contentAlign}`}>
          <NewVehicleModal
            showModal={showModalAddVehicle}
            onClose={() => setShowModalAddVehicle(false)}
            onCloseWithSave={() => handleCloseModal()}
          />
          <IonLabel className={'ion-text-center ' + styles.textAvailable}>
            {t('not_available_vehicle')}
          </IonLabel>
        </div>
        {msg && (
          <Toast
            isOpen={showToast}
            message={msg.description}
            onDidDismiss={handleDismissToast}
            position="bottom"
            type={msg.type}
          />
        )}
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={() => setShowModalAddVehicle(true)}>
              {t('btn_parking_add_vehicle')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default NotAvailableVehicle;
