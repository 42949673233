import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonLabel,
  IonRow,
  IonCol,
  IonBackButton,
} from '@ionic/react';
import styles from './styles.module.scss';
import { TopBar, Button, Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocationState } from '../../../../hooks/useLocationState';

const CanceledPlace: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const state = useLocationState() as any;
  const body = state?.body;
  const gotoHowToGet = () => {
    history.push('/services/howtoget/list');
  };

  return (
    <IonPage>
      <IonHeader className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('place_availability')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <IonRow className="ion-justify-content-center">
          <IonCol className={styles.center}>
            <IonLabel className={styles.title_2}>
              {body ?? t('sorry_place_canceled')}
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol className={styles.center_subtext}>
            <IonLabel className={styles.subhead}>
              {t('can_explore_alternative')}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={() => gotoHowToGet()}>
              <Icon className="icon icon-campus" />
              {t('lbl_more_how_to_get')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default CanceledPlace;
