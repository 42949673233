export enum appTypesEnum {
  REQUEST = 'FETCH_APP_REQUEST',
  FAILURE_REQUEST = 'FAILURE_REQUEST',
  SET_THEME = 'SET_THEME',
  SET_GLOBAL_SETTINGS = 'SET_GLOBAL_SETTINGS',
  SET_LOCAL_SETTINGS = 'SET_LOCAL_SETTINGS',
  SET_WELCOME_TEXT = 'SET_WELCOME_TEXT',
  SET_DASHBOARD_IMAGE = 'SET_DASHBOARD_IMAGE',
  SET_COOKIE_PREFERENCES = 'SET_COOKIE_PREFERENCES',
  SET_OFFLINE_SERVICES = 'SET_OFFLINE_SERVICES',
  SET_OFFLINE_ALERT_HAS_BEEN_SHOWN = 'SET_OFFLINE_ALERT_HAS_BEEN_SHOWN',
  SET_OFFLINE_NETWORK = 'SET_OFFLINE_NETWORK',
  SET_OFFLINE_TOAST_IS_OPEN = 'SET_OFFLINE_TOAST_IS_OPEN',
  SET_NO_RESERVATION_PERMISSION_TOAST_IS_OPEN = 'SET_NO_RESERVATION_PERMISSION_TOAST_IS_OPEN',
  RESET_APP_STATE = 'RESET_APP_STATE',
}
