import { isPlatform } from '@ionic/react';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

const getItem = async (key: string) => {
  if (isPlatform('capacitor')) {
    let returnValue;
    try {
      const value = await SecureStoragePlugin.get({ key });
      returnValue = value?.value ?? null;
    } catch (error) {
      return undefined;
    }
    return JSON.parse(returnValue);
  } else {
    return JSON.parse(localStorage.getItem(key));
  }
};

const setItem = async (key: string, value: any) => {
  if (isPlatform('capacitor')) {
    const stringValue = JSON.stringify(value);
    await SecureStoragePlugin.set({ key, value: stringValue });
    return;
  } else {
    return localStorage.setItem(key, JSON.stringify(value));
  }
};

const removeItem = async (key: string) => {
  if (isPlatform('capacitor')) {
    try {
      await SecureStoragePlugin.remove({ key });
    } catch (error) {
      console.log(error);
    }
  } else {
    localStorage.removeItem(key);
  }
};

export const StorageService = {
  getItem,
  setItem,
  removeItem,
};
