import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertInput } from '@ionic/react';
import { Alert, Icon } from '@acciona/ui-ionic-kit';
import {
  Configurations,
  ParkingReleaseItem,
  ParkingReservation,
  ParkingStore,
  ScheduleReservation,
} from '../../../../_redux/models/parking.model';
import { isEmpty } from '../../../../utils/functions';
import { addHours, formatDayAndMonth } from '../../../../utils/dateTime';
import { POLICIES_SCHEDULES_INDEXED_BY_KEY } from '../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../_redux/models/app.model';
import { parkingActions } from '../../../../_redux/actions';
import {
  getMinEndHour,
  getAvailableOptions,
  getReleaseOptions,
  getReservationTypeText,
} from './helpers';
import { DateTime } from '../../../../components/DateTime/DateTime';
import styles from './styles.module.scss';
import dayjs from 'dayjs';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { UserStore } from '../../../../_redux/models/user.model';

type Props = {
  item?: ParkingReservation;
  onModify?: (item: ParkingReleaseItem) => void;
  canReleaseBySchedule?: boolean;
};

export const ModifyParkingReservationItem = (props: Props) => {
  const { item, onModify, canReleaseBySchedule = false } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { campusTimeZone } = useSelector(
    (store: AppStore) => store.app.globalSettings,
  );

  const configurations: Configurations = useSelector(
    (state: ParkingStore) => state.parking.configurations,
  );
  const { reservedParking } = useSelector(
    (state: ReservationStore) => state.reservation,
  );
  const { defaultSede } = useSelector((state: UserStore) => state.user.user);

  const [showReleaseAlert, setShowReleaseAlert] = useState(false);
  const [selectedReleaseOption, setSelectedReleaseOption] = useState('');
  const [minStartHour, setMinStartHour] = useState<string>('00:00');
  const [maxStartHour, setMaxStartHour] = useState<string>('23:59');
  const [minEndHour, setMinEndHour] = useState<string>('00:00');
  const [maxEndHour, setMaxEndHour] = useState<string>('23:59');
  const [startHour, setStartHour] = useState<string>();
  const [endHour, setEndHour] = useState<string>();
  const [policies, setPolicies] = useState<ScheduleReservation[]>([]);

  useEffect(() => {
    dispatch(parkingActions.getPkConfigurations());
  }, []);

  useEffect(() => {
    if (configurations) {
      setPolicies(configurations.policies);
    }
  }, [configurations]);

  useEffect(() => {
    if (['Morning', 'Tarde', 'Personalizada'].includes(item.reservationType)) {
      setMinStartHour(item.startHour);
      setMaxStartHour(addHours(item.endHour, -1));
      setMinEndHour(addHours(item.startHour, 1));
      setMaxEndHour(item.endHour);
    }
  }, [item.reservationType]);

  useEffect(() => {
    if (selectedReleaseOption) {
      onModify({
        reservationId: item.reservationId,
        reservationType:
          POLICIES_SCHEDULES_INDEXED_BY_KEY[selectedReleaseOption],
        startHour: startHour,
        endHour: endHour,
        deleted: false,
      });
    }
  }, [selectedReleaseOption]);

  useEffect(() => {
    if (selectedReleaseOption && startHour) {
      setMinEndHour(prev => getMinEndHour(prev, startHour.substring(11, 16)));

      onModify({
        reservationId: item.reservationId,
        reservationType:
          POLICIES_SCHEDULES_INDEXED_BY_KEY[selectedReleaseOption],
        startHour: startHour,
        endHour: endHour,
        deleted: false,
      });
    }
  }, [selectedReleaseOption, startHour]);

  useEffect(() => {
    if (selectedReleaseOption && endHour) {
      onModify({
        reservationId: item.reservationId,
        reservationType:
          POLICIES_SCHEDULES_INDEXED_BY_KEY[selectedReleaseOption],
        startHour: startHour,
        endHour: endHour,
        deleted: false,
      });
    }
  }, [selectedReleaseOption, endHour]);

  const availableOptions = useMemo(
    () =>
      getAvailableOptions(
        policies,
        item?.reservationType,
        campusTimeZone,
        item?.from,
      ),
    [policies, item?.reservationType, campusTimeZone, item?.reservationDate],
  );

  const releaseOptions: AlertInput[] = useMemo(
    () => getReleaseOptions(policies),
    [policies],
  );

  const deleteReservationHandler = () => {
    onModify({
      reservationId: item.reservationId,
      reservationType: item.reservationType,
      deleted: true,
      startHour: item?.startHour || '00:00',
      endHour: item?.endHour || '00:00',
    });
  };

  const modifyReservationHandler = (reservationType: string) => {
    setSelectedReleaseOption(reservationType);
  };

  return (
    <>
      {!canReleaseBySchedule || isEmpty(availableOptions) ? (
        <div className={`${styles.modifyParkingRequestItem}`}>
          <div className={styles.resume}>
            <div>
              <h3>
                {formatDayAndMonth(
                  `${dayjs(item?.from).utc().format('YYYY-MM-DD')} 00:00:00`,
                )}
              </h3>
              <Icon
                className="icon icon-delete"
                onClick={deleteReservationHandler}
              />
            </div>
            <p>{getReservationTypeText(item)}</p>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.modifyParkingRequestItem} ${styles.extraPaddingTop}`}
        >
          <div className={styles.resume}>
            <div>
              <h3>
                {formatDayAndMonth(
                  `${dayjs(item?.from).utc().format('YYYY-MM-DD')} 00:00:00`,
                )}
              </h3>
              <Icon
                className="icon icon-delete"
                onClick={deleteReservationHandler}
              />
            </div>
            <p>{getReservationTypeText(item)}</p>
          </div>

          {reservedParking?.idSede === defaultSede.id && (
            <div className={styles.liberate}>
              <p>{t('lbl_to_liberate')}</p>
              <div className={styles.releaseOption}>
                <p
                  className={
                    selectedReleaseOption === '' ? styles.placeholder : ''
                  }
                >
                  {selectedReleaseOption !== ''
                    ? releaseOptions.find(
                        option => option.value === selectedReleaseOption,
                      )?.label ?? ''
                    : t('workroom_placeholder')}{' '}
                </p>
                <Icon
                  className="icon icon-24 icon-chevron-down"
                  onClick={() => setShowReleaseAlert(true)}
                />
              </div>
            </div>
          )}
          {selectedReleaseOption === 'custom_schedule' && (
            <>
              <div className={styles.schedule}>
                <p>{t('init_time')}</p>
                <DateTime
                  name="date1"
                  className={styles.dateTime}
                  display-format="HH:mm"
                  picker-format="HH:mm"
                  minuteValues="0"
                  min={minStartHour}
                  max={maxStartHour}
                  placeholder={t('workroom_placeholder')}
                  value={startHour}
                  onIonChange={e => setStartHour(e.detail.value!)}
                  cancelText={t('cancel_text')}
                  doneText={t('done_text')}
                  displayTimezone={campusTimeZone}
                />
              </div>
              <div className={styles.schedule}>
                <p>{t('end_time')}</p>
                <DateTime
                  name="date2"
                  className={styles.dateTime}
                  display-format="HH:mm"
                  picker-format="HH:mm"
                  minuteValues="0"
                  min={minEndHour}
                  max={maxEndHour}
                  value={endHour}
                  placeholder={t('workroom_placeholder')}
                  onIonChange={e => setEndHour(e.detail.value!)}
                  disabled={startHour == null}
                  cancelText={t('cancel_text')}
                  doneText={t('done_text')}
                  displayTimezone={campusTimeZone}
                />
              </div>
            </>
          )}
        </div>
      )}

      <Alert
        mode="ios"
        isOpen={showReleaseAlert}
        onDidDismiss={() => setShowReleaseAlert(false)}
        header={t('select_action')}
        buttons={[
          {
            text: t('cancel_text'),
            role: 'cancel',
            handler: () => setShowReleaseAlert(false),
          },
          {
            text: t('ok_text'),
            role: 'accept',
            handler: modifyReservationHandler,
          },
        ]}
        inputs={releaseOptions
          .filter(option => availableOptions.includes(option?.value))
          .map(item => ({
            ...item,
            checked: selectedReleaseOption === item?.value,
          }))}
      />
    </>
  );
};
