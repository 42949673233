import {
  MedicalServicesAction,
  MedicalServicesState,
} from '../models/medicalServices.model';
import { medicalServicesTypesEnum } from '../types/medicalServices.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  medicalServices: [],
};

export const medicalServicesReducer = (
  state: MedicalServicesState = initialState,
  action: MedicalServicesAction,
): MedicalServicesState => {
  switch (action.type) {
    case medicalServicesTypesEnum.MEDICAL_SERVICES_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case medicalServicesTypesEnum.SET_MEDICAL_SERVICES:
      return {
        ...state,
        isLoading: false,
        error: null,
        medicalServices: action.medicalServices,
      };
    case medicalServicesTypesEnum.MEDICAL_SERVICES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
