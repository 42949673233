import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moreActions } from '../_redux/actions';
import { UserStore } from '../../../../_redux/models/user.model';
import { AppStore } from '../../../../_redux/models/app.model';
import { AuthStore } from '../../../../_redux/models/auth.model';

export const useGetMoreMenuConfig = () => {
  const dispatch = useDispatch();
  const { defaultSede } = useSelector((store: UserStore) => store.user.user);
  const { localLanguage } = useSelector(
    (store: AppStore) => store.app.localSettings,
  );
  const { isAuth } = useSelector((store: AuthStore) => store.auth);

  useEffect(() => {
    if (!isAuth) return;
    dispatch(moreActions.getMenuConfig());
  }, [defaultSede.id, localLanguage, isAuth]);
};
