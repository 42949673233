import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import {
  IonRadioGroup,
  IonItem,
  IonRow,
  IonCol,
  IonLabel,
  IonRadio,
  IonCheckbox,
} from '@ionic/react';
import _ from 'lodash';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { Props } from './types';
import { Sede } from '../../../../_redux/models/user.model';
import styles from './styles.module.scss';

const getSedeDescription = (id: string, sedeOptions: Sede[]) => {
  return sedeOptions.find(option => option?.id.toString() === id)?.description;
};

const getSedesString = (selectedSedes: string[], sedeOptions: Sede[]) => {
  if (selectedSedes.length === 0) {
    return '';
  } else if (selectedSedes.length < 2) {
    return selectedSedes
      .map(s => getSedeDescription(s, sedeOptions))
      .join(', ');
  } else {
    return <>{getSedeDescription(selectedSedes[0], sedeOptions)}</>;
  }
};

const AccordionFilter: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    defaultSede,
    selectedView,
    selectedSedes,
    onChangeViewType,
    onChangeSede,
    sedeOptions,
    viewOptions,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const viewDescriptions = {
    perDay: t('per_day'),
    all: t('all_reservations'),
  };

  const selectedSedesString = useMemo(
    () => getSedesString(selectedSedes, sedeOptions),
    [selectedSedes, sedeOptions],
  );

  const orderedOptions = useMemo(() => {
    const otherSedes = _.orderBy(
      sedeOptions.filter(sede => sede.id !== defaultSede.id),
      'description',
    );
    return [defaultSede, ...otherSedes];
  }, [sedeOptions, defaultSede]);

  return (
    <>
      <div className={styles.separator}></div>
      <div className={`${styles.accordionContainer} ${styles.bgColorGrey}`}>
        <div className={styles.snackbarFilters}>
          <div
            className={`${styles.containerFilter}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className={`${styles.caption_1} ${styles.texto}`}>
              {`${t('visualization')} ${viewDescriptions[selectedView]}`} |{' '}
              {selectedSedesString}
            </div>

            <div>
              {selectedSedes.length > 1 && (
                <div>
                  <span className={styles.moreSedesChip}>
                    +{selectedSedes.length - 1}
                  </span>
                </div>
              )}
              <div>
                <Icon
                  className={`icon icon-24 ${
                    isOpen ? 'icon-chevron-up' : 'icon-chevron-down'
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content} aria-expanded={isOpen}>
          <div className={styles.options}>
            {orderedOptions.length > 1 && (
              <>
                <div className={styles.filterOptHeadContainer}>
                  <div className={`${styles.filterOptionsHeader}`}>
                    {t('lbl_head_office')}
                  </div>
                </div>

                {orderedOptions &&
                  orderedOptions.map((sede, index, arr) => {
                    const isChecked = selectedSedes.includes(
                      sede.id.toString(),
                    );
                    const isLastitem = index + 1 < arr.length;

                    return (
                      <div key={sede.id}>
                        <DetailListItem
                          type="button"
                          className={styles.filterOptions}
                        >
                          <IonCheckbox
                            slot={'start'}
                            value={sede.id.toString()}
                            onIonChange={() => onChangeSede(sede.id)}
                            checked={isChecked}
                            mode="ios"
                            aria-label={`sede-filter-options-${sede.id.toString()}`}
                          />
                          <IonLabel>
                            <div className={styles.row}>{sede.description}</div>
                          </IonLabel>
                        </DetailListItem>
                        {isLastitem ? (
                          <div className={styles.lightLine}></div>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </>
            )}
            <div className={styles.filterOptHeadContainer}>
              <div
                className={`${styles.gridWeb} ${styles.filterOptionsHeader}`}
              >
                {t('visualization')}
              </div>
            </div>
            <IonRadioGroup
              value={selectedView}
              onIonChange={e => onChangeViewType(e.detail.value)}
              allowEmptySelection={false}
            >
              {viewOptions.map((option, index, arr) => {
                const isLastitem = index + 1 < arr.length;
                return (
                  <div key={option}>
                    <IonItem className={styles.item} lines="none">
                      <IonRow>
                        <IonCol>
                          <IonLabel className={styles.name}>
                            {viewDescriptions[option]}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRadio
                        value={option}
                        slot="end"
                        color="dark"
                        mode="ios"
                        aria-label={`selected-view-${option}`}
                      ></IonRadio>
                    </IonItem>

                    {isLastitem ? <div className={styles.lightLine}></div> : ''}
                  </div>
                );
              })}
            </IonRadioGroup>
            <div className={styles.filterFooter}></div>
          </div>
        </div>
      </div>
      <div
        className={styles.overlay}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      ></div>
    </>
  );
};

export default AccordionFilter;
