import {
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { RadioList } from '../../../../components/Base/RadioList';
import { Modal } from '@acciona/ui-ionic-kit';
import { Props } from './types';
import styles from './styles.module.scss';

const BuildingModal: React.FC<Props> = props => {
  const { t } = useTranslation();

  const data = props.buildings.map(bl => {
    return { name: bl.name, value: bl.id.toString() };
  });

  const handleSelected = buildingId => {
    props.setSelectedBuilding(props.buildings.find(b => b.id == buildingId));
    props.setOpenModal(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={() => props.setOpenModal(false)}
    >
      <IonHeader>
        <IonToolbar>
          <IonButton fill="clear" onClick={() => props.setOpenModal(false)}>
            <IonIcon className="icon icon-chevron-left" />
            <IonTitle className={styles.title}>
              {t('builidng_workroom')}
            </IonTitle>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <RadioList
          options={data}
          selectedOption={props.value}
          onChange={handleSelected}
        />
      </IonContent>
      <IonFooter></IonFooter>
    </Modal>
  );
};
export default BuildingModal;
