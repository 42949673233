import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useLocationState = (saveOnUndefined = false) => {
  const location = useLocation();
  const [locationState, setLocationState] = useState<any>(location.state);

  useEffect(() => {
    if (!location?.state && !saveOnUndefined) {
      return;
    }
    setLocationState(location.state as Object);
  }, [location?.state]);

  return locationState;
};
