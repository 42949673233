import { useTranslation } from 'react-i18next';
import { LinksList } from '../../../templates/LinksList/LinksList';

const List: React.FC = () => {
  const { t } = useTranslation();

  const links = [
    {
      id: 1,
      text: t('lbl_shuttles'),
      url: '/services/howtoget/routesandshuttle',
      icon: 'bus',
    },
  ];

  return <LinksList pageTitle={t('lbl_more_how_to_get')} links={links} />;
};

export default List;
