import styles from './styles.module.scss';

export type LegendProps = {
  className?: string;
};

export const Legend: React.FC<any> = props => {
  const { className, ...res } = props;
  return (
    <div className={`${className} ${styles.container}`}>{res.children}</div>
  );
};
