import React from 'react';
import styles from './styles.module.scss';

type Props = {
  title?: string;
  data?: string;
  link?: string;
  onClick?: (e?: any) => void;
};

const PersonalDataItem: React.FC<Props> = ({ title, data, link, onClick }) => {
  let dataElement;

  if (data && link) {
    dataElement = (
      <a href={link} className={styles.footnote}>
        {data}
      </a>
    );
  } else if (data && onClick) {
    dataElement = (
      <p className={`${styles.footnote}`} onClick={onClick}>
        {data}
      </p>
    );
  } else {
    dataElement = <p className={styles.footnote}>{data || '-'}</p>;
  }

  return (
    <>
      <div className={styles.item}>
        <h3 className={styles.title}>{title}</h3>
        {dataElement}
      </div>
      <div className={styles.separator}></div>
    </>
  );
};

export default PersonalDataItem;
