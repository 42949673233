import { FC } from 'react';
import { ReleasedDeskDaysDate } from '../../../../../_redux/models/reservation.model';
import { getSlotString } from '../../../../../utils/functions';
import { formatDayAndMonth } from '../../../../../utils/dateTime';
import _ from 'lodash';
import styles from '../styles.module.scss';

interface Props {
  releasedDays: ReleasedDeskDaysDate[];
}
export const ReleasedDaysSummary: FC<Props> = ({ releasedDays }) => {
  return (
    <div className={styles.releasedDaysSummaryItem}>
      {_.orderBy(releasedDays, 'date').map((day, i) => (
        <p key={`released_${i}`}>
          {/* {groupDatesByMonth([day.date], 'es', true)} - {day.type}
           */}
          {formatDayAndMonth(day.date)}
          {' - '}
          {getSlotString(day)}
        </p>
      ))}
    </div>
  );
};
