import { combineReducers } from 'redux';

import { authReducer } from './auth.reducer';
import { reservationsReducer } from './reservations.reducer';
import { appReducer } from './app.reducer';
import { workstationReducer } from './workstation.reducer';
import { workroomReducer } from './workroom.reducer';
import { parkingReducer } from './parking.reducer';
import { newsReducer } from './news.reducer';
import { vehicleReducer } from './vehicle.reducer';
import { userReducer } from './user.reducer';
import { passengersReducer } from './passengers.reducer';
import { notificationsReducer } from './notifications.reducer';
import { searchReducer } from './search.reducer';
import { incidentReducer } from './incident.reducer';
import { shuttlesAndRoutesReducer } from './shuttlesAndRoutes.reducer';
import { restaurantsReducer } from './restaurants.reducer';
import { medicalServicesReducer } from './medicalServices.reducer';
import { moreReducer } from '../../pages/Dashboard/More/_redux/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  reservation: reservationsReducer,
  app: appReducer,
  workstation: workstationReducer,
  workroom: workroomReducer,
  parking: parkingReducer,
  news: newsReducer,
  vehicle: vehicleReducer,
  user: userReducer,
  passengers: passengersReducer,
  notifications: notificationsReducer,
  search: searchReducer,
  incident: incidentReducer,
  shuttles: shuttlesAndRoutesReducer,
  restaurants: restaurantsReducer,
  medicalServices: medicalServicesReducer,
  more: moreReducer,
});

export default rootReducer;
