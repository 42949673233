import {
  IonAlert,
  IonBackButton,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonInput,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
  IonRow,
  IonThumbnail,
} from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import {
  ActionSheet,
  Button,
  Icon,
  Item,
  List,
  Textarea,
  TopBar,
} from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18nextConf';
import { useCallback, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { incidentActions } from '../../../_redux/actions';
import { photoServices } from '../../../_redux/services/photo/photo.services';
import { docServices } from '../../../_redux/services/doc/doc.services';
import {
  didCameraPermission,
  isEmpty,
  toBase64,
} from '../../../utils/functions';
import { UserStore } from '../../../_redux/models/user.model';
import {
  ALLOWED_FILE_FORMATS,
  ALLOWED_IMAGE_FORMATS,
} from '../../../utils/constants';
import {
  Building,
  IncidentStore,
  LocationInfo,
} from '../../../_redux/models/incident.model';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import IncidentBuildingModal from './IncidentBuildingModal';
import IncidentFloorModal from './IncidentFloorModal';
import { useLocationState } from '../../../hooks/useLocationState';

const getSelectedBuildingFloors = (
  buildingsAndFloors: Building[],
  buildingNameSelected: string,
) => {
  const building = buildingsAndFloors.find(
    build => build.buildingName === buildingNameSelected,
  );
  const floors =
    building?.floors && building.floors.length > 0
      ? building.floors
      : [{ floorName: i18n.t('lbl_floors_notAvailable') }];
  return floors;
};

const IncidentForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useLocationState() as any;
  const maxFileSize = 3145728;

  const [location, setTextLocation] = useState<string>();
  const [description, setTextDescription] = useState<string>();
  const [images, setImages] = useState<string[]>([]);
  const [docs, setDocs] = useState<any>([]);
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const [maxSizeError, setMaxSizeError] = useState(null);
  const [wrongFormatError, setWrongFormatError] = useState<string[] | null>(
    null,
  );
  const [openDocsActionSheet, setOpenDocsActionSheet] = useState(false);
  const isNative = Capacitor.isNativePlatform();
  const imgInputRef = useRef(null);
  const [showIncidentBuildingModal, setShowIncidentBuildingModal] =
    useState(false);
  const [showIncidentFloorModal, setShowIncidentFloorModal] = useState(false);
  const { name, firstSurname, buildingName, floorName } = useSelector(
    (state: UserStore) => state.user.user,
  );
  const [buildingNameSelected, setBuildingNameSelected] =
    useState(buildingName);
  const [floorNameSelected, setFloorNameSelected] = useState(floorName);

  const { isLoading, error, buildingsAndFloors } = useSelector(
    (store: IncidentStore) => store.incident,
  );

  const handleReportIncident = () => {
    const building = buildingsAndFloors.find(
      b => b.buildingName === buildingNameSelected,
    );
    const floor = building?.floors.find(f => f.floorName === floorNameSelected);
    const locationInfo: LocationInfo = {
      building: building?.building ?? null,
      buildingName: building?.buildingName ?? '',
      floor: floor?.floor ?? null,
      floorName: floor?.floorName ?? '',
    };

    dispatch(
      incidentActions.reportIncident({
        location,
        locationInfo,
        description,
        images,
        docs,
        userName: `${name} ${firstSurname}`,
        ...state,
      }),
    );
  };

  const addPhotoToGallery = () => {
    photoServices
      .addNewToGallery()
      .then(async data => setImages(photos => [...photos, data.webPath]));
  };

  const addPickPhotoToGallery = () => {
    photoServices.addPhotoFormGallery().then(async data => {
      data.photos.forEach(data => {
        setImages(photos => [...photos, data.webPath]);
      });
    });
  };

  const handleDeletePhoto = img => {
    setImages(images.filter(item => item !== img));
  };

  const addPickDocToDocsDirectory = () => {
    docServices.addDocFromDocsDirectory().then(async data => {
      if (data.files.some(file => file.size > maxFileSize)) {
        return setMaxSizeError(t('incident_error_max_size'));
      }
      if (
        data.files.some(file => {
          const extension = file.name.split('.').slice(1).join('.');
          return !ALLOWED_FILE_FORMATS.includes(extension.toLowerCase());
        })
      ) {
        return setWrongFormatError(ALLOWED_FILE_FORMATS);
      }
      setDocs(docs => [
        ...docs,
        ...data.files.map(file => ({
          nombreFichero: file.name,
          fichero: file.data.replaceAll('\n', ''),
          descripcion: 'Fichero adjunto',
          mimeType: file.mimeType,
        })),
      ]);
    });
  };

  const handleDeleteDoc = doc => {
    setDocs(docs.filter(item => item !== doc));
  };

  const removeImage = useCallback(
    (src: string) => {
      setImages(images.filter(image => image !== src));
    },
    [images],
  );

  const handleImagesChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(evt.target.files);
    const allowedFiles = files.filter(file =>
      ALLOWED_IMAGE_FORMATS.some(format => file.type.includes(format)),
    );
    if (files.length !== allowedFiles.length) {
      setWrongFormatError(ALLOWED_IMAGE_FORMATS);
      return;
    }
    if (files.some(file => file.size > maxFileSize)) {
      setMaxSizeError(t('incident_error_max_size'));
      return;
    }
    const base64Files = files.map(file => toBase64(file));
    Promise.all(base64Files).then((resolvedFiles: string[]) => {
      setImages(images => [...images, ...resolvedFiles]);
    });
  };

  const handleOpenOptions = async () => {
    if (isNative) {
      await didCameraPermission();
      setOpenActionSheet(true);
    }
  };
  const handleBuildingModal = () => {
    setShowIncidentBuildingModal(true);
  };
  const handleFloorModal = () => {
    setShowIncidentFloorModal(true);
  };
  const updateBuilding = newBuilding => {
    setBuildingNameSelected(newBuilding);
    updateFloor('');
  };
  const updateFloor = newFloor => {
    setFloorNameSelected(newFloor);
  };
  const isButtonEnabled = () => {
    return !location || !description || !floorNameSelected;
  };

  const selectedBuildingFloors = useMemo(
    () => getSelectedBuildingFloors(buildingsAndFloors, buildingNameSelected),
    [buildingsAndFloors, buildingNameSelected],
  );

  return (
    <IonPage>
      <IncidentBuildingModal
        id="incidentBuildingModal"
        isOpen={showIncidentBuildingModal}
        showModal={setShowIncidentBuildingModal}
        value={buildingNameSelected}
        setValue={updateBuilding}
        buildingList={buildingsAndFloors}
      />
      <IncidentFloorModal
        id="incidentFloorModal"
        isOpen={showIncidentFloorModal}
        showModal={setShowIncidentFloorModal}
        value={floorNameSelected}
        setValue={updateFloor}
        floorList={selectedBuildingFloors}
      />
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t(state.type)}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <List className={styles.incidentFormList}>
            <Item mode="ios" lines="full">
              <IonLabel>{t('incident_type')}</IonLabel>
              <IonNote color={'dark'} slot="end">
                {state.type}
              </IonNote>
            </Item>
            <Item mode="ios" lines="full">
              <IonLabel>{t('incident_center')}</IonLabel>
              <IonNote color={'dark'} slot="end">
                {t('incident_acciona')}
              </IonNote>
            </Item>
            {(state.actionType === 3 || state.actionType === 5) && (
              <>
                <DetailListItem
                  className={styles.detailListItem}
                  mode="ios"
                  lines="full"
                  onClick={handleBuildingModal}
                  titleLight={t('lbl_building')}
                  endText={buildingNameSelected}
                  endIcon={'icon-chevron-right'}
                  endTextClass={styles.noPaddingText}
                  disabled={isEmpty(buildingsAndFloors)}
                />
                <DetailListItem
                  className={styles.detailListItem}
                  mode="ios"
                  lines="full"
                  onClick={handleFloorModal}
                  titleLight={t('lbl_floor')}
                  endText={floorNameSelected}
                  endIcon={'icon-chevron-right'}
                  endTextClass={styles.noPaddingText}
                  disabled={isEmpty(buildingsAndFloors)}
                />
              </>
            )}
            <Item lines="inset">
              <Textarea
                autocapitalize="sentences"
                label={t('incident_description')}
                labelPlacement="stacked"
                aria-labelledby={t('incident_description')}
                value={description}
                className={styles.textArea}
                placeholder={
                  state.descriptionPlaceholder
                    ? state.descriptionPlaceholder
                    : t('incident_description_placeholder')
                }
                onIonInput={e => setTextDescription(e.detail.value!)}
              ></Textarea>
            </Item>
            <Item lines="inset">
              <IonInput
                autocapitalize="sentences"
                label={t('incident_location')}
                labelPlacement="stacked"
                aria-labelledby={t('incident_location')}
                value={location}
                className={styles.textArea}
                placeholder={
                  state.locationPlaceholder
                    ? state.locationPlaceholder
                    : t('incident_location_placeholder')
                }
                class="ion-text-left"
                onIonInput={e => setTextLocation(e.detail.value!)}
              ></IonInput>
            </Item>
            <IonItemDivider
              className={`${styles.divider} ${styles.elementFullWidth}`}
            />
            <Item lines="none">
              <IonLabel className={styles.add}>
                <h3>
                  {images && !isEmpty(images)
                    ? t('incident_photo_add', {
                        number: images.length,
                      })
                    : t('incident_photo')}
                </h3>
                <p>{t('incident_format_image')}</p>
                <p>{t('incident_max_size_document')}</p>
              </IonLabel>
            </Item>
            {images && !isEmpty(images) && (
              <Item lines="none">
                <div className={styles.container}>
                  {images.map((pho, index) => (
                    <div key={index} className={styles.item}>
                      <IonThumbnail className={styles.thumbnail} slot="end">
                        <Icon
                          onClick={() => handleDeletePhoto(pho)}
                          className={`icon icon-close  ${styles.iconClose}`}
                        />
                        <IonImg
                          className={styles.image}
                          src={pho}
                          onIonError={evt => {
                            const target = evt.target as HTMLIonImgElement;
                            removeImage(target.src);
                          }}
                        ></IonImg>
                      </IonThumbnail>
                    </div>
                  ))}
                </div>
              </Item>
            )}
            <Item lines="none">
              <IonRow className="ion-justify-content-left">
                <IonCol size="auto" className={styles.documentCol}>
                  <Button
                    onClick={() =>
                      isNative
                        ? handleOpenOptions()
                        : imgInputRef.current.click()
                    }
                    className={styles.small_btn}
                    color="outline"
                  >
                    {t('incident_photo_btn')}
                    <Icon slot="start" className={'icon icon-plus'} />
                    {!isNative && (
                      <input
                        ref={imgInputRef}
                        type="file"
                        id="imgInput"
                        name="imgInput"
                        accept={ALLOWED_IMAGE_FORMATS.map(
                          format => `.${format}`,
                        ).join(',')}
                        onChange={handleImagesChange}
                        multiple
                        hidden
                      />
                    )}
                  </Button>
                </IonCol>
              </IonRow>
            </Item>

            <IonItemDivider className={styles.divider}></IonItemDivider>
            <Item lines="none">
              <IonLabel className={styles.add}>
                <h3>
                  {docs && !isEmpty(docs)
                    ? t('incident_documents_add', {
                        number: docs.length,
                      })
                    : t('incident_documents')}
                </h3>
                <IonLabel className="ion-text-wrap">
                  <p>
                    {t('incident_allowed_formats', {
                      formats: ALLOWED_FILE_FORMATS.join(', '),
                    })}
                  </p>
                  <p>{t('incident_max_size_document')}</p>
                </IonLabel>
              </IonLabel>
            </Item>
            {docs && !isEmpty(docs) && (
              <Item lines="none">
                <div className={styles.containerDoc}>
                  {docs.map((doc, index) => (
                    <div key={index} className={styles.item}>
                      <IonThumbnail className={styles.thumbnailDoc} slot="end">
                        <Icon
                          onClick={() => handleDeleteDoc(doc)}
                          className={`icon icon-close  ${styles.iconCloseDoc}`}
                        />
                        {doc.nombreFichero}
                      </IonThumbnail>
                    </div>
                  ))}
                </div>
              </Item>
            )}
            <Item lines="none">
              <IonRow className="ion-justify-content-left">
                <IonCol size="auto" className={styles.documentCol}>
                  <Button
                    onClick={() =>
                      isNative
                        ? setOpenDocsActionSheet(true)
                        : addPickDocToDocsDirectory()
                    }
                    className={styles.small_btn}
                    color="outline"
                  >
                    {t('incident_documents_btn')}
                    <Icon slot="start" className={'icon icon-Attached'} />
                  </Button>
                </IonCol>
              </IonRow>
            </Item>
          </List>
        </div>
        <IonAlert
          isOpen={!!error || !!maxSizeError}
          onDidDismiss={() => setMaxSizeError(null)}
          mode="ios"
          header={t('incident_size_not_allowed_title')}
          message={error || maxSizeError}
          buttons={[t('ok_text')]}
        />
        <IonAlert
          isOpen={Boolean(wrongFormatError?.length)}
          onDidDismiss={() => setWrongFormatError(null)}
          mode="ios"
          header={t('incident_format_not_allowed_title')}
          message={t('incident_allowed_formats', {
            formats: wrongFormatError?.join(', '),
          })}
          buttons={[t('ok_text')]}
        />
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={0}
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleReportIncident} disabled={isButtonEnabled()}>
              {t('incident_send')}
            </Button>
          </div>
        </div>
      </IonFooter>
      <ActionSheet
        cssClass={styles.actionsheetSty}
        backdropDismiss={false}
        isOpen={openActionSheet}
        header=""
        mode="ios"
        buttons={[
          {
            text: t('add_photo'),
            handler: () => {
              addPhotoToGallery();
              setOpenActionSheet(false);
            },
          },
          {
            text: `${t('select_photo')}`,
            handler: () => {
              addPickPhotoToGallery();
              setOpenActionSheet(false);
            },
          },
          {
            text: `${t('cancel_text')}`,
            role: 'cancel',
            handler: () => {
              setOpenActionSheet(false);
            },
          },
        ]}
        onDidDismiss={() => setOpenActionSheet(false)}
      />
      <ActionSheet
        cssClass={styles.actionsheetSty}
        backdropDismiss={false}
        isOpen={openDocsActionSheet}
        header=""
        mode="ios"
        buttons={[
          {
            text: `Seleccionar documento`,
            handler: () => {
              addPickDocToDocsDirectory();
              setOpenDocsActionSheet(false);
            },
          },
          {
            text: `${t('cancel_text')}`,
            role: 'cancel',
            handler: () => {
              setOpenDocsActionSheet(false);
            },
          },
        ]}
        onDidDismiss={() => setOpenDocsActionSheet(false)}
      />
    </IonPage>
  );
};

export default IncidentForm;
