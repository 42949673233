import { DayCalendar } from '@acciona/ui-ionic-kit';
import { IonItem } from '@ionic/react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../_redux/models/app.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { ReservationCard } from '../../../../components/Base/ReservationCard';
import {
  getHeaderText,
  getTitleText,
  getSubtitleText,
  getEndContent,
  getSpaceDate,
} from './helpers';
import { filterTypes } from '../../Home/types';
import { getSortedUniqueValues } from '../../../../utils/functions';
import { USER_LOCKER_MODE } from '../../../../utils/constants';
import styles from '../styles.module.scss';

export const ReservationItem = ({
  space,
  isLastItem,
  reservationType,
  handleSelectedReservation,
  visualization,
  raffleDay,
}) => {
  const { district: userDistrict, sedesList } = useSelector(
    (state: UserStore) => state.user.user,
  );

  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );
  const sedeList = useSelector((store: UserStore) => store.user.user.sedesList);

  const getuserLockerMode = idSede => {
    return (
      sedeList.find(e => e.id == idSede)?.lockerEmployeeKey ??
      USER_LOCKER_MODE.DESK_LOCKER
    );
  };

  const filterUseType = filterTypes.find(
    filter => filter?.id === space.useType,
  );
  const icon = filterUseType && filterUseType?.icon;

  const isPermanent = ['PermanentParking', 'PermanentDesk', 'Locker'].includes(
    space.useType,
  );
  const sedeSpace = sedesList.find(e => e.id == space?.idSede);

  return (
    <IonItem
      className={`${styles.spaces} ${isPermanent && styles.permanent}`}
      lines={isLastItem ? 'none' : 'full'}
      button
      detail={false}
      mode="md"
    >
      <ReservationCard
        headerTranslucent={false}
        data={{
          id: space.id?.toString(),
          type: space.useType,
          header: getHeaderText(reservationType, space),
          title: getTitleText(userDistrict, space, sedeSpace.campusTimeZone),
          descriptionSede:
            reservationType === 'reservation' ? space?.descriptionSede : '',
          subtitle: getSubtitleText(
            space,
            raffleDay,
            sedeSpace.campusTimeZone,
            visualization,
          ),
          neighborhood: userDistrict === space.district?.toString(),
          host: space.host?.fullName,
          lockerId:
            getuserLockerMode(space.idSede) === USER_LOCKER_MODE.DESK_LOCKER
              ? space.lockerId
              : '',
          pluggable: space.pluggable,
          visualization: visualization,
          floorName: space.floorName,
          buildingName: space.buildingName,
        }}
        icon={icon}
        onClick={() => handleSelectedReservation(space)}
        displayLockerId={
          getuserLockerMode(space.idSede) === USER_LOCKER_MODE.DESK_LOCKER
        }
        endContent={getEndContent(space)}
      >
        {visualization === 'all' &&
          space.dates &&
          getSortedUniqueValues(space.dates, 'reservationDate').map(
            (date: any, index: number) =>
              !['Room', 'Canteen'].includes(space.useType) && (
                <DayCalendar
                  locale={localLanguage}
                  key={index}
                  date={getSpaceDate(
                    date,
                    space.useType,
                    sedeSpace.campusTimeZone,
                  )}
                  selected={false}
                  id={index.toString()}
                  disabled
                />
              ),
          )}
      </ReservationCard>
    </IonItem>
  );
};
