import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonLoading,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import { workroomActions } from '../../../../_redux/actions/workroom.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { reservationActions } from '../../../../_redux/actions';
import { WorkroomStore } from '../../../../_redux/models/workroom.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { didCameraPermission } from '../../../../utils/functions';

const CheckIn: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showCamera, setShowCamera] = useState(false);
  const workroom = useSelector(
    (state: ReservationStore) => state.reservation.reservedWorkroom,
  );

  const { isLoading } = useSelector((state: WorkroomStore) => state.workroom);
  const { employeeId } = useSelector((state: UserStore) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const backFunction = ev => {
      ev.detail.register(5, () => {
        handleClose();
      });
    };

    checkPermission();
    document.addEventListener('ionBackButton', backFunction);
    return () => {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);

  const checkPermission = async () => {
    const permission = await didCameraPermission();
    permission
      ? scanCode()
      : history.replace('/spaces/workroom/reservationdetail');
  };
  const scanCode = async () => {
    BarcodeScanner.hideBackground();
    setShowCamera(true);
    const data = await BarcodeScanner.startScan();
    try {
      const spaceCodeQr = JSON.parse(data.content).code;
      if (data.hasContent) {
        setShowCamera(false);
        if (workroom.code != spaceCodeQr) {
          throw new Error();
        } else {
          setShowCamera(false);
          BarcodeScanner.showBackground();
          dispatch(
            workroomActions.checkInWorkroom(
              parseInt(workroom.id),
              workroom.date.reservationId,
              workroom?.organizer?.employeeId.toString() === employeeId,
            ),
          );
        }
      }
    } catch (error) {
      dispatch(
        reservationActions.msgReservation({
          type: 'error',
          description: t('msg_checkin_error_room'),
        }),
      );
      alert(t('msg_checkin_error_room'));
      history.replace('/dashboard/reservations');
    }
  };

  const handleClose = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    history.replace('/spaces/workroom/reservationdetail');
  };

  return (
    <IonPage>
      <IonHeader className={`ion-no-border`}>
        <IonToolbar className={styles.toolbar}>
          <IonButtons slot="secondary">
            <IonButton className={styles.icon} onClick={handleClose}>
              <IonIcon slot="icon-only" className="icon icon-close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={showCamera && styles.transparentContent}>
        <div className={styles.container}>
          <div className={styles.barcodeScannerAreaContainer}>
            <div className={`${styles.square} ${styles.surroundCover}`}>
              <div
                className={`${styles.barcodeScannerAreaOuter} ${styles.surroundCover}`}
              >
                <div className={styles.barcodeScannerAreaInner}></div>
              </div>
            </div>
          </div>
        </div>
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export default CheckIn;
