import { FC, useMemo } from 'react';
import { Props } from './types';
import { Alert } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { Sede } from '../../../../../_redux/models/user.model';
import styles from './styles.module.scss';

const getSedeOptions = (sedes: Sede[], defaultSede: Sede) => {
  const sortedSedes = sedes
    .sort((a, b) => a.description.localeCompare(b.description))
    .sort((a, b) =>
      a.id === defaultSede.id ? -1 : b.id === defaultSede.id ? 1 : 0,
    );

  const options = sortedSedes.map(sede => ({
    label: sede.description,
    type: 'radio' as 'radio',
    value: sede.id,
    checked: sede.id === defaultSede.id,
  }));

  return options;
};

export const ChangeHeadOfficeModal: FC<Props> = ({
  handleSelectedHeadOffice,
  isOpen,
  onDidDismiss,
  sedes,
  defaultSede,
}) => {
  const { t } = useTranslation();

  const sedeOptions = useMemo(
    () => getSedeOptions(sedes, defaultSede),
    [sedes, defaultSede],
  );

  return (
    <Alert
      mode="ios"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      header={t('lbl_head_office_change')}
      inputs={sedeOptions}
      buttons={[
        { text: t('cancel_text'), role: 'cancel' },
        {
          text: t('ok_text'),
          handler: (value: number) => handleSelectedHeadOffice(value),
          cssClass: styles.bold,
        },
      ]}
    />
  );
};
