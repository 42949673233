import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const useReservationsHistory = () => {
  const history = useHistory();
  const locationState: any = history?.location?.state || undefined;
  const isFromConfirmation: boolean =
    locationState && locationState?.prevRouterConfirm
      ? locationState.prevRouterConfirm
      : false;
  const typeReservation =
    locationState && locationState?.type ? locationState.type : null;
  const startHour =
    locationState && locationState?.startHour ? locationState.startHour : null;
  const endHour =
    locationState && locationState?.endHour ? locationState.endHour : null;
  const datesSelected = locationState?.datesSelected
    ? locationState.datesSelected
    : null;

  const handleClose = () => {
    history.push('/dashboard/reservations');
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        if (isFromConfirmation) {
          history.push('/dashboard/actions');
        } else {
          history.push('/dashboard/reservations');
        }
      }
    };
  }, [history]);

  return {
    history,
    locationState,
    typeReservation,
    startHour,
    endHour,
    datesSelected,
    isFromConfirmation,
    handleClose,
  };
};
