import { IonContent, IonLoading, IonPage, isPlatform } from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { authActions } from '../../../_redux/actions/auth.actions';
import { AppStore } from '../../../_redux/models/app.model';
import { AuthStore } from '../../../_redux/models/auth.model';
import { UserStore } from '../../../_redux/models/user.model';
import { userActions } from '../../../_redux/actions/user.actions';

const Callback: React.FC<any> = () => {
  const query = new URLSearchParams(useLocation().search);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuth, isLoading: isLoadingAuth } = useSelector(
    (state: AuthStore) => state.auth,
  );
  const { offlineServices } = useSelector((state: AppStore) => state.app);
  const { userIsRegisteredInDatabase } = useSelector(
    (state: UserStore) => state.user.user,
  );
  const history = useHistory();

  useEffect(() => {
    if (!isPlatform('capacitor')) {
      if (query.get('code')) {
        const code = query.get('code');
        dispatch(authActions.getToken(code, false, true));
      }
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(userActions.getUserHeadOffice());
    }
  }, [isAuth]);

  useEffect(() => {
    if (userIsRegisteredInDatabase) {
      dispatch(userActions.getUserConfig(true));
    }
  }, [userIsRegisteredInDatabase]);

  useEffect(() => {
    if (offlineServices && isAuth) {
      history.push('/dashboard/home');
    }
  }, [offlineServices, isAuth]);

  return (
    <IonPage>
      <IonContent>
        <IonLoading isOpen={isLoadingAuth} message={t('msg_loading')} />
      </IonContent>
    </IonPage>
  );
};

export default Callback;
