import { TopBar } from '@acciona/ui-ionic-kit';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';

import { useTranslation } from 'react-i18next';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { DetailList } from '../../../../components/Base/DetailList';
import { useLocationState } from '../../../../hooks/useLocationState';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import Dates from '../PassengerInvitationDetail/components/Dates/Dates';

import { formatDateToWeekDayAndMonth } from '../../../../utils/dateTime';

import styles from './styles.module.scss';

const PassengerDetail = () => {
  const { t } = useTranslation();
  const { driver, descriptionSede, dates } = useLocationState();
  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/reservations"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('reservation_detail')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <DetailCard
          title={t('passenger')}
          subName={t('lbl_parking')}
          type="passenger"
          icon="icon icon-occupation"
        />
        <div className={styles.gridWebReservations}>
          <div className={styles.listGrid}>
            <DetailList>
              {/* FECHA */}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-calendar"
                startIconColor="primary"
                title={
                  dates.length === 1
                    ? formatDateToWeekDayAndMonth(dates[0].reservationDate, {
                        fullday: true,
                      })
                    : t('lbl_multiple_days')
                }
                description={
                  dates.length > 1 && (
                    <Dates
                      dates={dates.map((d: { reservationDate: string }) => ({
                        id: d.reservationDate,
                        date: d.reservationDate,
                        isUserFree: true,
                        accepted: true,
                      }))}
                      detail
                    />
                  )
                }
                readonly
              />
              {/* SEDE */}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-explore"
                startIconColor="primary"
                title={t('lbl_head_office')}
                description={descriptionSede}
                readonly
              />
              {/* DRIVER */}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-car"
                startIconColor="primary"
                title={t('lbl_driver')}
                description={driver}
                readonly
              />
            </DetailList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PassengerDetail;
