import { IonText } from '@ionic/react';
import { Icon, Item } from '@acciona/ui-ionic-kit';
import { Props } from './types';

import styles from './styles.module.scss';
import './styles.module.scss';

export const VehicleDetailList: React.FC<Props> = props => {
  const { header, brand, description, routerLink, icon, endIconTop } = props;

  return (
    <Item
      lines="none"
      className={`${styles.item}`}
      mode="ios"
      type="button"
      routerLink={routerLink}
    >
      <Icon slot="start" className={`${icon} ${styles.startIcon}`} />
      <div className={`ion-text-wrap ${styles.columnList}`}>
        <IonText className={`ion-text-wrap ${styles.block}`}>{header}</IonText>
        <IonText className={`ion-text-wrap ${styles.block}`}>{brand}</IonText>
        <IonText
          className={`${styles.footnote} ion-text-wrap ${styles.color_dark} ${styles.block}`}
        >
          {' '}
          {description}{' '}
        </IonText>
      </div>
      <Icon slot="end" className={`${endIconTop} ${styles.endIconTop}`} />
    </Item>
  );
};
