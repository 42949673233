import { Dispatch } from 'redux';
import i18n from '../i18nextConf';
import _ from 'lodash';
import { history } from '../_helpers/history';
import {
  NewsActions,
  NotificationsActions,
  reservationActions,
} from '../_redux/actions';
import { Notification } from '../_redux/models/notifications.model';
import { isEmpty, openHtmlLink, parseJsonArray } from './functions';

export const redirectToNotificationDetail = (
  notification: Notification,
  dispatch,
  isFromPushNotification: boolean = false,
) => {
  const { link, informationType, idNav, title, body } = notification;

  switch (link) {
    case 'reservationDetail':
      if (!idNav) return;
      // eslint-disable-next-line no-case-declarations
      const [
        spaceId,
        type = undefined,
        startHour = undefined,
        endHour = undefined,
      ] = idNav.split('#');

      switch (informationType) {
        case 'Desk':
          dispatch(
            reservationActions.getReservationWorkstation(
              spaceId,
              type,
              startHour,
              endHour,
            ),
          );
          break;
        case 'Parking':
          dispatch(
            reservationActions.getReservationParking(
              spaceId,
              type,
              startHour,
              endHour,
            ),
          );
          break;
        case 'Room':
          dispatch(reservationActions.getReservationWorkroom(idNav));
          break;
      }
      break;
    case 'reservationList':
      history.push('/dashboard/reservations');
      break;
    case 'notifyList':
      history.replace('/notifications', { filterType: 'notification' });
      break;
    case 'notifyDetail':
      dispatch(
        NotificationsActions.setNotificationDetail({
          title: title,
          body: body,
        }),
      );
      history.push('/appnotification-detail');
      break;
    case 'reservationCancel':
      switch (informationType) {
        case 'Desk':
          dispatch(reservationActions.getReservationDeleteWorkstation(idNav));
          break;
        case 'Parking':
          dispatch(reservationActions.getReservationDeleteParking(idNav));
          break;
        default:
          history.push('/notifications');
          break;
      }
      break;
    // case 'preReservationDetail':
    //   break;
    case 'noticeList':
      history.push('/notifications');
      break;
    case 'newsTogo':
      dispatch(NewsActions.selectNews(idNav, history.location.pathname));
      break;
    case 'cardActivation':
      history.push('/profile/user/card/');
      break;
    case 'notAssignedParking':
      history.push('/parking/NotAvailablePlace/', {
        notAssignedParking: true,
        header: `${i18n.t('not_available_parking')}`,
      });
      break;
    case 'noticeTogo':
      dispatch(NewsActions.selectNotice(idNav));
      break;
    case 'cancelParkingReleased':
      history.replace('/parking/CanceledPlace/', { body: body });
      break;
    case 'cancelDeskReleased':
      history.replace('/workstation/CandeledDesk/');
      break;
    case 'passengerInvitation':
      history.push('/parking/passengerInvitation', {
        driverId: notification.driverId,
        notificationDate: notification.createData,
        isFromPushNotification,
      });
      break;
    default:
      history.replace('/notifications', { filterType: 'notice' });
  }
};

/**
 * Redirects from a notification to its corresponding destination. 
 * 
 * Destinations in order of precedence:
    - If _linkPush_ field is filled: a URL to an external web page.
    - If _section_ field is filled: application section.
    - Notification detail.
 *
 * If the user does not have access permissions: redirection to an error page.
 * @param notification
 * @param dispatch 
 * @param userPermissions 
 * @param plannedReservationActive
 * 
 */
export const redirectNotify = (
  notification: Notification,
  dispatch: Dispatch<any>,
  userPermissions: string[] = [],
  plannedReservationActive: boolean,
  isFromPushNotification: boolean = false,
) => {
  const redirectToUnauthorized = () => {
    dispatch(
      NotificationsActions.setNotificationsUnauthorized(notification.title),
    );
    history.replace('/notification-unauthorized');
  };

  const permissionsRequired = notification?.permissionsRequired
    ? parseJsonArray(notification?.permissionsRequired)
    : [];

  const userHasPermissions =
    isEmpty(permissionsRequired) ||
    userPermissions.some(permission =>
      permissionsRequired.includes(permission),
    );

  if (!userHasPermissions) {
    redirectToUnauthorized();
  } else if (!_.isEmpty(notification.linkPush)) {
    openHtmlLink(notification.linkPush);
  } else if (!_.isEmpty(notification.section)) {
    if (
      notification.section === '/profile/user/parking-cut-off' &&
      !plannedReservationActive
    ) {
      redirectToUnauthorized();
      return;
    }
    history.push(notification.section);
  } else if (!_.isEmpty(notification.link)) {
    redirectToNotificationDetail(
      notification,
      dispatch,
      isFromPushNotification,
    );
  }
};
