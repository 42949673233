import { TopBar } from '@acciona/ui-ionic-kit';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserStore } from '../../../_redux/models/user.model';
import { isEmpty } from '../../../utils/functions';
import styles from './styles.module.scss';
import PersonalDataItem from '../../../components/Base/PersonalDataItem/PersonalDataItem';

const UserDetail: React.FC = () => {
  const { t } = useTranslation();

  const userData = useSelector((state: UserStore) => state.user.user);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    userData &&
      !isEmpty(userData) &&
      setUserInfo([
        {
          text: t('lbl_user_data_name'),
          data: userData.name,
        },
        {
          text: t('lbl_user_data_names'),
          data: `${userData.firstSurname} ${userData.secondSurname}`,
        },
        {
          text: t('lbl_id_employee'),
          data: userData.employeeId,
        },
        {
          text: t('lbl_personal_company'),
          data: userData.company,
        },
        {
          text: t('lbl_user_data_corpo_phone'),
          data: userData.professionalMobile,
        },
        {
          text: t('lbl_user_data_corpo_email'),
          data: userData.professionalEmail,
        },
      ]);
  }, [userData]);

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        className={`${styles.header} ${styles.toolbarGrid}`}
      >
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              defaultHref="/profile/user"
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_personal_data')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {userInfo.map(
              (item, index) =>
                item.text && (
                  <PersonalDataItem
                    key={index}
                    title={item.text}
                    data={item.data}
                  />
                ),
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UserDetail;
