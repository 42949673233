import { history } from '../../_helpers/history';
import {
  ParkingAction,
  Parking,
  ParkingRequest,
  Companions,
  Configurations,
  UserParkingPreferences,
} from '../models/parking.model';
import { Vehicle } from '../models/vehicles.model';
import { parkingServices } from '../services/parking/parking.services';
import { ParkingTypesEnum } from '../types/parking.types.enum';
import { reservationActions } from './reservations.actions';
import { vehicleActions } from './vehicles.actions';
import { passengersActions } from './passengers.actions';
import {
  getReservableDaysPKAfterPermanency,
  getReservableDaysPlannedPKAfterPermanency,
  isEmpty,
} from '../../utils/functions';
import { PassengerData } from '../models/passengers.model';
import { Raffle } from '../models/reservation.model';
import _ from 'lodash';
import i18n from '../../i18nextConf';
import { userActions } from './user.actions';
import { getDateString } from '../../utils/dateTime';

const parkingRequest = (): ParkingAction => {
  return {
    type: ParkingTypesEnum.REQUEST,
  };
};

const setParking = (Parking: Parking): ParkingAction => {
  return {
    type: ParkingTypesEnum.SET_PARKING,
    parking: Parking,
  };
};

const setParkings = (parkings: Parking[]): ParkingAction => ({
  type: ParkingTypesEnum.SET_PARKINGS,
  parkings: parkings,
});

const setRaffleState = (raffle: Raffle): ParkingAction => ({
  type: ParkingTypesEnum.SET_RAFFLE_STATE,
  raffle: raffle,
});

const setReservableDays = (
  reservableDays: string[],
  plannedReservableDays: string[],
  hasReservableDays: boolean,
): ParkingAction => ({
  type: ParkingTypesEnum.SET_PK_RESERVABLE_DAYS,
  reservableDays: reservableDays,
  plannedReservableDays: plannedReservableDays,
  hasReservableDays: hasReservableDays,
});

const resetLoading = (): ParkingAction => {
  return {
    type: ParkingTypesEnum.RESET_LOADING,
  };
};

const fetchParkingFailure = (error: string): any => {
  return {
    type: ParkingTypesEnum.FAILURE_PARKING,
    error: error,
  };
};

const setPkConfigurations = (
  configurations: Configurations,
): ParkingAction => ({
  type: ParkingTypesEnum.SET_PARKING_CONFIGURATION,
  configurations: configurations,
});

const searchParkingLastMinute = (
  selectedDate,
  priorPlacesPropoposal = null,
  vehiclePrev = null,
  type: string,
  startHour: string,
  endHour: string,
  textHeader: string,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(parkingRequest());
    try {
      const parkings: Parking[] = await parkingServices.searchReservableParking(
        selectedDate,
        type,
        startHour,
        endHour,
      );

      if (vehiclePrev) {
        dispatch(vehicleActions.changeFavouriteVehicle(vehiclePrev));
      }

      if (!isEmpty(parkings)) {
        // Check if there is a previous search and display the message with the difference
        if (priorPlacesPropoposal) {
          const suggestedDaysAvailable = parkings
            .map(place => place.dates)
            .flat();
          const isAllDaysAvailable = selectedDate.every(day => {
            return suggestedDaysAvailable.includes(day);
          });

          if (isAllDaysAvailable) {
            // System suggested different places
            const arrPriorPlaces = priorPlacesPropoposal.map(
              pl => pl.idParking,
            );
            const arrSearchPlaces = parkings.map(pl => pl.idParking);
            const difference = arrSearchPlaces.filter(
              id => !arrPriorPlaces.includes(id),
            );

            const dateDiff = parkings.find(e => e.idParking === difference[0]);

            if (!isEmpty(dateDiff)) {
              // If the system assigns one or more different place
              const dayToShow = dateDiff.dates[0];
              dispatch(
                passengersActions.setMsgPassengers({
                  type: 'info',
                  description:
                    difference.length === 1
                      ? getDateString(dayToShow as unknown as string)
                      : i18n.t('new_availables_parking_ahead'),
                }),
              );
            } else {
              dispatch(
                passengersActions.setMsgPassengers({
                  type: 'error',
                  description: i18n.t('not_available_parking_ahead'),
                }),
              );
            }
          } else {
            // If no place is available for one or more days
            dispatch(
              passengersActions.setMsgPassengers({
                type: 'error',
                description: i18n.t('not_availables_parking_ahead'),
              }),
            );
          }
        } else {
          history.push('/parking/lastminute', {
            selectedDates: selectedDate,
            textHeader: textHeader,
            type: type,
            startHour: startHour,
            endHour: endHour,
          });
        }
        dispatch(setParkings(parkings));
        dispatch(vehicleActions.setVehicleDefault(parkings[0].vehicle));
      } else {
        dispatch(resetLoading());
        history.push('/parking/NotAvailablePlace', {
          selectedDates: selectedDate,
          header: `${i18n.t('not_available_parking')}`,
        });
      }
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

const createParkingReserve = (
  parking: Parking[],
  companions: PassengerData[],
  vehicle: Vehicle,
  selectedDates: Date[],
  type: string,
  startHour: string,
  endHour: string,
  textHeader: string,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(parkingRequest());

    try {
      const parkingsReserved: any = await parkingServices.createParkingReserve(
        parking,
        companions,
        vehicle,
        type,
        startHour,
        endHour,
      );

      if (parkingsReserved === null) {
        //If there is no place
        dispatch(resetLoading());
        history.replace('/parking/NotAvailablePlace', {
          header: `${i18n.t('not_available_parking_ahead')}`,
        });
      } else if (isEmpty(parkingsReserved)) {
        //If the system assigns different places or no place is available for one or more days
        dispatch(
          searchParkingLastMinute(
            selectedDates,
            parking,
            null,
            type,
            startHour,
            endHour,
            textHeader,
          ),
        );
      } else {
        //If the system assigns the same places - OK
        const spaces = [];
        parkingsReserved?.reservations.map(re => {
          re.spaces.map(sp => {
            spaces.push(sp.id);
          });
        });

        dispatch(
          reservationActions.getReservationParkingDetail(parking[0].idParking),
        ).then(() => {
          dispatch(resetLoading());
          history.replace('/parking/confirmation', {
            title: 'sucess_parking',
            code: '',
            msg: '',
            router:
              _.uniq(spaces).length === 1
                ? '/spaces/parking/detail'
                : '/dashboard/reservations',
            parmsRouter: {
              prevRouterConfirm: true,
              datesSelected: selectedDates,
              type,
              startHour,
              endHour,
            },
            type,
            startHour,
            endHour,
            textHeader,
          });
        });

        dispatch(passengersActions.resetParkingCompanions());
      }
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

const createParkingReservePlanned = (
  dates: Date[],
  vehicle: Vehicle,
  companions: PassengerData[],
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(parkingRequest());
    try {
      const NewParkingRaffle: ParkingRequest[] =
        await parkingServices.createParkingReservePlanned(
          dates,
          vehicle,
          companions,
        );
      dispatch(reservationActions.setReservedRafflesPk(NewParkingRaffle));
      dispatch(resetLoading());
      history.replace('/parking/confirmation', {
        title: 'sucess_workstation_planned',
        msg: 'lbl_date_selected_planned_parking',
        router: '/spaces/parking/requestdetail',
        parmsRouter: {
          prevRouterConfirm: true,
          idSede: NewParkingRaffle[0].idSede,
        },
      });

      dispatch(passengersActions.resetParkingCompanions());
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

const deleteComnpanionRaffleReserve = (
  employeeId: number,
  requestId: number,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(parkingRequest());
    try {
      await parkingServices.deleteComnpanionRaffleReserve(
        employeeId,
        requestId,
      );
      dispatch(reservationActions.deleteRaffleCompanion(employeeId, requestId));
      dispatch(resetLoading());
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

const addNewComnpanionsRaffleReserve = (
  companions: Companions[],
  reserveId: number,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(parkingRequest());
    try {
      await parkingServices.addNewComnpanionsRaffleReserve(
        companions,
        reserveId,
      );
      dispatch(reservationActions.addRaffleCompanion(companions, reserveId));
      history.push('/spaces/parking/requestdetail', { reserveId });
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

const getRaffle = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(parkingRequest());
    try {
      const raffleState = await parkingServices.getRaffle(
        state().user.user.defaultSede.id,
      );
      dispatch(setRaffleState(raffleState));
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

const resetErrorParking = (): ParkingAction => ({
  type: ParkingTypesEnum.RESET_ERROR,
});

const getPkConfigurations = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(parkingRequest());
    try {
      const configuration: Configurations =
        await parkingServices.getPkConfigurations();
      let favPolicy = state().user.user.favouriteParkingPolicy;
      let modifyFavs = false;
      const existFavPolicy =
        configuration.policies.find(e => e.id === favPolicy?.id) || null;
      if (!existFavPolicy) {
        if (!isEmpty(configuration.policies))
          favPolicy = configuration.policies[0];
        modifyFavs = true;
      }
      if (modifyFavs) {
        const newWorkstationPreferences: UserParkingPreferences = {
          favouriteParkingPolicy: favPolicy,
        };
        dispatch(
          userActions.saveUserParkingPreferences(newWorkstationPreferences),
        );
      }

      dispatch(setPkConfigurations(configuration));
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

const getPkReservableDays = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      const permanentPk = state()?.reservation?.permanentParkings ?? [];
      const defaultSedeId = state()?.user?.user?.defaultSede?.id ?? 0;
      const publicHolidays = state()?.user?.publicHolidays ?? [];
      const dayParkingPlannedTurningPoint =
        state()?.app?.globalSettings?.dayParkingPlannedTurningPoint ?? 0;
      const hourParkingPlannedTurningPoint =
        state()?.app?.globalSettings?.hourParkingPlannedTurningPoint ?? 0;
      const parkingPlannedWeeksExtend =
        state()?.app?.globalSettings?.parkingPlannedWeeksExtend ?? 0;
      const raffle = state()?.parking?.raffle ?? {
        startHour: '12:30',
        inProgress: false,
        weekDay: 5,
      };

      const plannedReservation =
        state()?.app?.globalSettings?.plannedReservation;
      const lastMinuteReservation =
        state()?.app?.globalSettings?.lastMinuteReservation;

      const reservable = lastMinuteReservation
        ? getReservableDaysPKAfterPermanency(
            publicHolidays,
            permanentPk,
            raffle,
            defaultSedeId,
          )
        : [];

      const reservablePlanned = plannedReservation
        ? getReservableDaysPlannedPKAfterPermanency(
            publicHolidays,
            permanentPk,
            raffle,
            defaultSedeId,
            dayParkingPlannedTurningPoint,
            hourParkingPlannedTurningPoint,
            parkingPlannedWeeksExtend,
          )
        : [];

      const hasReservableDays =
        isEmpty(permanentPk) ||
        !isEmpty(reservable) ||
        !isEmpty(reservablePlanned);

      dispatch(
        setReservableDays(reservable, reservablePlanned, hasReservableDays),
      );
    } catch (e) {
      dispatch(fetchParkingFailure(e.message));
    }
  };
};

export const parkingActions = {
  setParking,
  setParkings,
  createParkingReserve,
  searchParkingLastMinute,
  resetErrorParking,
  createParkingReservePlanned,
  deleteComnpanionRaffleReserve,
  addNewComnpanionsRaffleReserve,
  getRaffle,
  fetchParkingFailure,
  getPkConfigurations,
  getPkReservableDays,
};
