import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

import FirebaseAnalyticsContext from '../context/FirebaseAnalyticsContext';
import { pathnameToLog } from '../analytics/constants';

const cleanUpPathname = (pathname: string): string => {
  if (pathname.includes('/incidents/callphone')) {
    return '/incidents/callphone';
  }
  return pathname;
};

export const useFirebaseAnalytics = (watch: boolean = false) => {
  const contextContent = useContext(FirebaseAnalyticsContext);
  const { logScreenView } = contextContent;
  const pathname = cleanUpPathname(useLocation().pathname);

  const logToFirebase = (key: string) => {
    const tag = pathnameToLog[key] || {
      name: key,
      shouldLog: true,
    };

    const ignorePattern = new RegExp(
      /^(\/medicalservices|\/howtoget|\/restaurants)/,
    );

    if (key.search(ignorePattern) > -1) {
      // if pathname starts with '/medicalservices', '/howtoget' or '/restaurants'
      // is required for retrocompatibility
      return;
    }

    if (!tag.shouldLog) return;
    logScreenView(tag.name);
  };

  useEffect(() => {
    if (!watch) return;
    logToFirebase(pathname);
  }, [watch, pathname]);

  return logToFirebase;
};
