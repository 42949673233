import dayjs from 'dayjs';
import i18n from '../../../../i18nextConf';

export const formatTimeSlot = (
  startHour: string,
  endHour: string,
  timeZone: string,
): string => {
  const start = dayjs(startHour).tz(timeZone);
  const end = dayjs(endHour).tz(timeZone);
  const startTime = {
    hour: start.hour().toString(),
    minute: start.minute().toString(),
  };
  const endTime = {
    hour: end.hour().toString(),
    minute: end.minute().toString(),
  };

  if (startTime.minute.length === 1) startTime.minute = '0' + startTime.minute;
  if (endTime.minute.length === 1) endTime.minute = '0' + endTime.minute;
  return `${startTime.hour}:${startTime.minute} - ${endTime.hour}:${endTime.minute}`;
};

export const getSlotTimeDiff = (slotDate: string): number => {
  if (slotDate === undefined) return 0;
  const todayDate = dayjs(dayjs().format('YYYY-MM-DD'));
  const slotsDate = dayjs(dayjs(slotDate.split('T')[0]).format('YYYY-MM-DD'));

  return todayDate.diff(slotsDate, 'day');
};

const dateHelper = {
  month: {
    0: 'dateFormats.month.Jan',
    1: 'dateFormats.month.Feb',
    2: 'dateFormats.month.Mar',
    3: 'dateFormats.month.Apr',
    4: 'dateFormats.month.May',
    5: 'dateFormats.month.Jun',
    6: 'dateFormats.month.Jul',
    7: 'dateFormats.month.Aug',
    8: 'dateFormats.month.Sep',
    9: 'dateFormats.month.Oct',
    10: 'dateFormats.month.Nov',
    11: 'dateFormats.month.Dec',
  },
  weekday: {
    1: 'dateFormats.day.Mon',
    2: 'dateFormats.day.Tus',
    3: 'dateFormats.day.Wed',
    4: 'dateFormats.day.Thu',
    5: 'dateFormats.day.Fri',
    6: 'dateFormats.day.Sat',
    0: 'dateFormats.day.Sun',
  },
};

export const formatWeekCompleteDate = (date: string): any => {
  const dateObject = dayjs(date);
  return {
    year: dateObject.year(),
    month: i18n.t(dateHelper.month[dateObject.month()]),
    day: dateObject.date(),
    weekday: i18n.t(dateHelper.weekday[dateObject.weekday()]),
  };
};
