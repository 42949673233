import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonButton,
  IonBackButton,
} from '@ionic/react';
import { TopBar, Alert, Toast } from '@acciona/ui-ionic-kit';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { useHistory, useLocation } from 'react-router';
import dayjs from 'dayjs';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { RequestDetailVehicles } from './RequestDetailVehicles';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import { ParkingReleasedDays } from '../../../../components/ParkingReleasedDays/ParkingReleasedDays';
import { reservationActions } from '../../../../_redux/actions';
import { getDatesToReleaseText, isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';
import {
  ParkingRequest,
  ParkingStore,
} from '../../../../_redux/models/parking.model';

const ParkingRequestDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const stateRequestDetail: any = useLocation().state || {};
  const [datesRequest, setDates] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const parkingRaffles = useSelector(
    (state: ReservationStore) => state.reservation.parkingRaffles,
  );

  const parkingRaffleCompanions = useSelector(
    (state: ReservationStore) => state.reservation.parkingRaffleCompanions,
  );

  const msgRequest = useSelector(
    (state: ReservationStore) => state.reservation.msg,
  );
  const { raffle } = useSelector((state: ParkingStore) => state.parking);

  const idSede =
    stateRequestDetail?.idSede ||
    parkingRaffles.find(
      r => r.dates[0].requestId === stateRequestDetail?.reserveId,
    )?.idSede ||
    {};

  const { isFromConfirmation, handleClose } = useReservationsHistory();

  const currentParkingRaffles = useMemo(() => {
    return parkingRaffles.filter(e => e.idSede === idSede);
  }, [parkingRaffles]);

  useEffect(() => {
    const vehicleArray = [];
    currentParkingRaffles.forEach(date => {
      if (!vehicleArray.find(i => i.plate === date.vehicle.plate)) {
        vehicleArray.push(date.vehicle);
      }
    });

    setVehicles(vehicleArray);

    setDates(
      currentParkingRaffles
        .map(request => {
          return request.dates.map(date => ({
            id: date.requestId,
            status: false,
            date: new Date(
              dayjs(
                `${dayjs(date.from).utc().format('YYYY-MM-DD')} 12:00:00`,
              ).toISOString(),
            ),
            homeOffice: date.homeOffice,
          }));
        })
        .flat(),
    );
  }, [parkingRaffles]);

  const handleDismissToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    msgRequest && setShowToast(true);
  }, [msgRequest, parkingRaffles]);

  const handleGoModifyParkingRequestPage = () => {
    history.push({
      pathname: '/parking/modifyRequest',
      state: {
        datesRequest: datesRequest,
        currentParkingRaffles: currentParkingRaffles,
      },
    });
  };

  const daysToReleaseText = useMemo(() => {
    return getDatesToReleaseText(
      datesRequest.map(date => ({
        ...date,
        status: true,
      })),
    );
  }, [datesRequest]);

  const cancelParkingRequestByDay = async () => {
    const deletedIds = datesRequest.map(d => d.id);

    dispatch(
      reservationActions.cancelParkingRequest(
        currentParkingRaffles,
        deletedIds,
        datesRequest,
        () => history.replace('/dashboard/reservations'),
      ),
    );
  };
  const hasSomeCompanionPendingRequest = (
    raffles: ParkingRequest[],
  ): boolean => {
    return raffles.some(raffle => {
      return raffle.dates.some(date => {
        return date.companions.some(companion => companion.status === false);
      });
    });
  };
  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            isFromConfirmation ? (
              <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
            ) : (
              <IonBackButton
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
                defaultHref="/dashboard/reservations"
              />
            )
          }
          endActions={
            <IonButton onClick={() => setShowAlert(true)}>
              {t('delete_text')}
            </IonButton>
          }
          title={t('reservation_detail')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <DetailCard
          name="Plaza"
          subName={t('parking')}
          type="parkingRequest"
          icon="icon icon-parking"
          pending
        />
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {!isEmpty(datesRequest) && (
              <ParkingReleasedDays
                onClick={handleGoModifyParkingRequestPage}
                days={datesRequest}
                type="parking"
                canModify={datesRequest.length > 1}
              />
            )}
            <DetailListItem
              className={styles.itemList}
              startIcon="icon icon-explore"
              startIconColor="primary"
              title={t('lbl_head_office')}
              description={currentParkingRaffles[0]?.descriptionSede}
            />
          </div>
        </div>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              <DetailListItem
                lines="inset"
                title={t('lbl_personal_vehicle_passengers')}
                className={styles.itemHeader}
              />
              <RequestDetailVehicles vehicles={vehicles} />
              <DetailListItem
                className={`${styles.itemList} ${styles.itemChevron}`}
                lines="none"
                onClick={() =>
                  history.push('/spaces/parking/detail/passengersrequest')
                }
                detail={false}
                startIcon="icon icon-occupation"
                startIconColor="primary"
                title={
                  isEmpty(parkingRaffleCompanions)
                    ? t('lbl_no_companions')
                    : `${parkingRaffleCompanions.length} ${t(
                        'associated_companions',
                        {
                          multiple:
                            parkingRaffleCompanions.length > 1 ? 's' : '',
                        },
                      )}`
                }
                endIconAction="icon icon-chevron-right"
                pending={hasSomeCompanionPendingRequest(parkingRaffles)}
              />

              <DetailList>
                {!isEmpty(parkingRaffleCompanions) &&
                  parkingRaffleCompanions.map(item => {
                    return (
                      <DetailListItem
                        companion
                        key={item.employeeId}
                        startIcon={`icon icon-client`}
                        startIconColor="primary"
                        titleLight={item.fullName}
                        footnote={item.function}
                      />
                    );
                  })}
              </DetailList>

              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-campus"
                title={t('lbl_pending_assing_location')}
                description={
                  raffle.weekDay !== null
                    ? t('lbl_info_raffle_parking', {
                        date: dayjs()
                          .utc()
                          .weekday(raffle.weekDay)
                          .format(`dddd D [${t('lbl_of_date')}] MMMM`),
                      })
                    : ''
                }
              />
            </DetailList>
          </div>
        </div>
      </IonContent>
      {msgRequest && (
        <Toast
          isOpen={showToast}
          onDidDismiss={() => handleDismissToast()}
          message={msgRequest.description}
          position="bottom"
          type={msgRequest.type}
        />
      )}
      <Alert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('header_delete_parking_reserve')}
        message={t('msg_delete_parking_reserve', {
          parking: currentParkingRaffles[0]?.name,
          days: daysToReleaseText,
        })}
        buttons={[
          { text: 'No', role: 'cancel' },
          {
            text: t('lbl_affirmative'),
            handler: cancelParkingRequestByDay,
          },
        ]}
        mode="ios"
      />
    </IonPage>
  );
};

export default ParkingRequestDetail;
