import {
  IonBackButton,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
} from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { ActionSheet, FilterChip, TopBar } from '@acciona/ui-ionic-kit';
import { isEmpty } from '../../utils/functions';
import {
  redirectNotify,
  redirectToNotificationDetail,
} from '../../utils/notifications';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { useDispatch, useSelector } from 'react-redux';
import { NotificationsActions } from '../../_redux/actions/notifications.actions';
import { classes } from './classes';
import { AppStore } from '../../_redux/models/app.model';
import _ from 'lodash';
import { UserStore } from '../../_redux/models/user.model';
import { NotificationsStore } from '../../_redux/models/notifications.model';
import { usePassengerInvitation } from '../../hooks/usePassengerInvitation';
import { useLocationState } from '../../hooks/useLocationState';
const localizedFormat = require('dayjs/plugin/localizedFormat');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(duration);

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const state = useLocationState() as any;
  const {
    globalSettings: { plannedReservation },
    localSettings: { localLanguage },
  } = useSelector((state: AppStore) => state.app);
  const dispatch = useDispatch();

  const notificationList = [
    ...useSelector(
      (store: NotificationsStore) => store.notifications.notifications,
    ),
  ];

  const permissions = useSelector(
    (state: UserStore) => state.user.user.permissions,
  );

  const passengerInvitationWard = usePassengerInvitation();

  const filterTypes = [
    {
      id: 'notice',
      text: `${t('lbl_notices')}`,
      checked: true,
    },
    {
      id: 'notification',
      text: `${t('lbl_notifications')}`,
      checked: false,
    },
  ];
  const [filterType, setFilterType] = useState(filterTypes);
  const [selectedFilter, setSelectedFilter] = useState('notice');
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const [notificationButtons, setNotificationButtons] = useState<any>([]);

  const handleSelectNotification = (id: string) => {
    setOpenActionSheet(true);
    const buttonsActions = [
      {
        cssClass: styles.btnActionSheetCancel,
        text: `${t('cancel_text')}`,
        role: 'cancel',
        handler: () => {
          setOpenActionSheet(false);
        },
      },
    ];

    !isEmpty(notificationList.filter(d => d.messageId === id)) &&
      !_.first(notificationList.filter(d => d.messageId === id)).read &&
      buttonsActions.push({
        cssClass: styles.btnActionSheetOptions,
        text: t('lbl_mark_read'),
        role: null,
        handler: () => {
          dispatch(NotificationsActions.notificationRead(id));
          setOpenActionSheet(false);
        },
      });

    if (selectedFilter != 'notice') {
      buttonsActions.push({
        cssClass: styles.btnActionSheetOptions,
        text: `${t('delete_text')}`,
        role: null,
        handler: () => {
          dispatch(NotificationsActions.notificationDelete(id));
          setOpenActionSheet(false);
        },
      });
    }
    setNotificationButtons(buttonsActions);
  };
  const handleReadAll = () => {
    dispatch(NotificationsActions.notificationReadAll());
  };
  const handleTypeFilterChange = data => {
    const { id } = data;
    setSelectedFilter(id);
    setFilterType(
      [...filterType].map(object => {
        if (object.id !== id) {
          return {
            ...object,
            checked: false,
          };
        } else if (object.checked === false) {
          return {
            ...object,
            checked: true,
          };
        } else {
          return { ...object };
        }
      }),
    );
  };

  const handleSelecNotification = async notification => {
    dispatch(NotificationsActions.notificationRead(notification.messageId));

    if (notification.link === 'passengerInvitation') {
      const invitationCheck = await passengerInvitationWard(
        notification.driverId,
        notification.createData,
      );
      if (!invitationCheck) return;
    }

    if (!isEmpty(notification.idNav)) {
      redirectNotify(notification, dispatch, permissions, plannedReservation);
    } else {
      redirectToNotificationDetail(notification, dispatch);
    }
  };

  const checkDeskIcon = (icon: string): string => {
    //TODO remove when icon-desk exist on acciona-kit
    return icon === 'desk' ? 'seat' : icon;
  };
  const bodyNofiticacion = (index, notification) => (
    <div
      key={index}
      className={`${styles.card} ${
        !notification.read ? styles.unreadCard : ''
      } `}
    >
      <div className={styles.gridWeb}>
        <div>
          <div className={styles.cardContain}>
            <div onClick={() => handleSelecNotification(notification)}>
              <div className={styles.groupLeft}>
                <div
                  className={`${styles.divIcon} ${
                    classes[notification.informationType]
                  }`}
                >
                  <IonIcon
                    className={`icon icon-${checkDeskIcon(notification.icon)} ${
                      styles.iconFont
                    }`}
                  />
                </div>
                <div className={styles.verticalCenter}>
                  <span
                    className={`${styles.footnote} ${styles.cardText} ${
                      !notification.read && styles.noRead
                    }`}
                  >
                    {notification.title}
                  </span>

                  <span className={`${styles.footnote} ${styles.cardSubtext} `}>
                    {dayjs
                      .duration(
                        dayjs().diff(dayjs(notification.createData), 'minutes'),
                        'minutes',
                      )
                      .locale(localLanguage)
                      .humanize()}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.iconsContent}>
                <IonButton
                  fill="clear"
                  onClick={() =>
                    handleSelectNotification(notification.messageId)
                  }
                >
                  <IonIcon
                    className={`icon icon-more ${styles.handHover} ${styles.iconFontBlack}`}
                  ></IonIcon>
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (!_.isUndefined(state) && !_.isUndefined(state.filterType)) {
      handleTypeFilterChange({ id: state.filterType });
    }

    !_.isEmpty(notificationList) &&
      localStorage.setItem(
        'notificationDate',
        _.first(_.orderBy(notificationList, 'createData', 'desc')).createData,
      );
  }, [state]);

  const filteredNotifications = useMemo(() => {
    if (isEmpty(notificationList)) {
      return [];
    }
    return notificationList.filter(d =>
      selectedFilter === 'notice' ? d.notice : !d.notice,
    );
  }, [notificationList, selectedFilter]);

  // FILTERS
  const todayNotifications = notification =>
    dayjs(notification.createData).isToday();

  const notTodayNotifications = notification =>
    !dayjs(notification.createData).isToday();

  const lastWeekNotifications = notification =>
    dayjs(notification.createData).isAfter(dayjs().subtract(1, 'week')) &&
    dayjs(notification.createData).isBefore(dayjs(), 'day');

  const lastMonthNotifications = notification =>
    dayjs(notification.createData).isAfter(dayjs().subtract(1, 'month')) &&
    dayjs(notification.createData).isBefore(dayjs().subtract(1, 'week'));

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_notices_and_notifications')}
        />
      </IonHeader>
      <IonContent>
        {!isEmpty(notificationList) && (
          <div>
            <IonItem className={styles.filters} lines="full">
              <div className={styles.gridscroll}>
                {filterType.map((item, index) => (
                  <FilterChip
                    key={index}
                    id={item.id}
                    text={`${item.text} (${
                      item.id === 'notification'
                        ? notificationList.filter(n => !n.notice && !n.read)
                            .length
                        : notificationList.filter(n => n.notice && !n.read)
                            .length
                    })`}
                    checked={item.checked}
                    onChange={handleTypeFilterChange}
                  />
                ))}
              </div>
            </IonItem>
            {!isEmpty(filteredNotifications) && (
              <div className={styles.gridWeb}>
                <div className={styles.title}>
                  <div className={styles.todayText}>
                    {notificationList &&
                    !isEmpty(filteredNotifications.filter(todayNotifications))
                      ? t('lbl_today')
                      : ''}
                  </div>
                  {!isEmpty(filteredNotifications) ? (
                    <div onClick={handleReadAll}>
                      <span className={styles.descriptionTextLink}>
                        {t('mark_read')}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        )}
        {isEmpty(filteredNotifications.filter(todayNotifications))
          ? null
          : _.orderBy(
              filteredNotifications.filter(todayNotifications),
              'createData',
              'desc',
            ).map((n, index) => {
              return bodyNofiticacion(index, n);
            })}

        {selectedFilter === 'notice' ? (
          <>
            {isEmpty(
              filteredNotifications.filter(notTodayNotifications),
            ) ? null : (
              <>
                <div className={styles.gridWeb}>
                  <div className={styles.title}>{t('lbl_other_notice')}</div>
                </div>
                {_.orderBy(
                  filteredNotifications.filter(notTodayNotifications),
                  'createData',
                  'desc',
                ).map((n, index) => bodyNofiticacion(index, n))}
              </>
            )}
          </>
        ) : (
          <>
            {isEmpty(
              filteredNotifications.filter(lastWeekNotifications),
            ) ? null : (
              <>
                <div className={styles.gridWeb}>
                  <div className={styles.title}>{t('lbl_last_week')}</div>
                </div>
                <>
                  {_.orderBy(
                    filteredNotifications.filter(lastWeekNotifications),
                    'createData',
                    'desc',
                  ).map((n, index) => bodyNofiticacion(index, n))}
                </>
              </>
            )}

            {isEmpty(
              filteredNotifications.filter(lastMonthNotifications),
            ) ? null : (
              <>
                <div className={styles.gridWeb}>
                  <div className={styles.title}>{t('lbl_last_month')}</div>
                </div>
                <>
                  {_.orderBy(
                    filteredNotifications.filter(lastMonthNotifications),
                    'createData',
                    'desc',
                  ).map((n, index) => bodyNofiticacion(index, n))}
                </>
              </>
            )}
          </>
        )}

        <ActionSheet
          cssClass={styles.actionsheetSty}
          isOpen={openActionSheet}
          mode="ios"
          onDidDismiss={() => setOpenActionSheet(false)}
          buttons={notificationButtons}
        />
        {!isEmpty(notificationList) && isEmpty(filteredNotifications) && (
          <div className={styles.contentNoFound}>
            <div>
              <h2>
                {t('notif_notfound', {
                  type:
                    selectedFilter === 'notice'
                      ? t('lbl_notices').toLowerCase()
                      : t('lbl_notifications').toLowerCase(),
                })}
              </h2>
            </div>
          </div>
        )}
        {isEmpty(notificationList) && (
          <div className={styles.notificationsNotFound}>
            <div>
              <h2>{t('lbl_noticeAndNotifications_notfound')}</h2>
              <p>{t('lbl_notif_upToDate')}</p>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
