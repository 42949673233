import { Locale } from '../../../_redux/models/app.model';

const spaceTypes = [
  {
    id: 1,
    type: 'workrooms',
    description: {
      es: 'Salas',
      en: 'Workrooms',
      pt: 'Salas',
    },
  },
  {
    id: 2,
    type: 'collaborativeUseWorkstation',
    description: {
      es: 'Puesto uso colaborativo',
      en: 'Collaborative use workstation',
      pt: 'Estação de trabalho de uso colaborativo',
    },
  },
  {
    id: 3,
    type: 'operativeUseWorkstation',
    description: {
      es: 'Puesto uso operativo',
      en: 'Operative use workstation',
      pt: 'Estação de trabalho de uso operacional',
    },
  },
  {
    id: 4,
    type: 'concentrationUseWorkstation',
    description: {
      es: 'Puesto uso concentración',
      en: 'Concentration use workstation',
      pt: 'Estação de trabalho de uso de concentração',
    },
  },
  {
    id: 5,
    type: 'anchorWorkstation',
    description: {
      es: 'Puesto ancla',
      en: 'Anchor workstation',
      pt: 'Estação de trabalho âncora',
    },
  },
];

export const getSpaceTypes = (language: Locale) =>
  spaceTypes.map(s => ({
    id: s.id,
    type: s.type,
    description: s.description[language],
  }));
