import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonLabel,
  IonFooter,
} from '@ionic/react';
import { Button, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { BackButton } from '../../../components/Base/BackButton';
import { workstationActions } from '../../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { UserStore } from '../../../_redux/models/user.model';
import { AppStore } from '../../../_redux/models/app.model';
import { useLocationState } from '../../../hooks/useLocationState';

const NotAvailable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useLocationState() as any;

  const { district, building, floor } = useSelector(
    (state: UserStore) => state.user.user,
  );

  const { temporalVisitsDistrict } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  const [info, setInfo] = useState({
    title: '',
    header: '',
    description: '',
    dates: null,
  });

  useEffect(() => {
    setInfo({
      title: t(state.title),
      header: t(state.header),
      description: t(state.description),
      dates: state.dates,
    });
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        state.datesSelected &&
          dispatch(
            workstationActions.getLastMinuteSelectWs(
              state.datesSelected,
              undefined,
              state.type,
              state.startHour,
              state.endHour,
              state.textHeader,
            ),
          );
      }
    };
  }, [history]);

  const handleLastMinuteSelectWs = () => {
    dispatch(
      workstationActions.getLastMinuteSelectWs(
        state.datesSelected,
        {
          isVisit: district === temporalVisitsDistrict,
          defaultBuilding: parseInt(building),
          defaultFloor: parseInt(floor),
        },
        state.type,
        state.startHour,
        state.endHour,
        state.textHeader,
      ),
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <TopBar
          primaryActions={
            state.router ? (
              <BackButton router={state.router} />
            ) : (
              <IonBackButton
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            )
          }
          title={info.title}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.center}>
            <IonLabel className={styles.firstTextSty}>{info.header}</IonLabel>
            <IonLabel className={styles.secTextSty}>
              {info.description}
            </IonLabel>
          </div>
        </div>
      </IonContent>
      {state.datesSelected && (
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              <Button onClick={handleLastMinuteSelectWs} expand="block">
                {t('btn_ws_select_map')}
              </Button>
            </div>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default NotAvailable;
