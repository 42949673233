import { SearchAction, SearchState } from '../models/search.model';
import { searchTypesEnum } from '../types/search.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  search: null,
  lastRequestTime: null,
  isLoadingDelete: false,
};

export const searchReducer = (
  state: SearchState = initialState,
  action: SearchAction,
): SearchState => {
  switch (action.type) {
    case searchTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        isLoadingDelete: true,
      };
    case searchTypesEnum.SET_SEARCH:
      return {
        ...state,
        ...(action.requestTime > state.lastRequestTime
          ? {
              isLoading: false,
              search: action.search,
              lastRequestTime: action.requestTime,
            }
          : {}),
      };
    case searchTypesEnum.RESET_SEARCH:
      return {
        ...initialState,
      };
    case searchTypesEnum.FAILURE_USER:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isLoadingDelete: false,
      };
    case searchTypesEnum.RESET_LOADING:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
