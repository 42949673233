import { Vehicle } from '../../models/vehicles.model';
import muleSoftInstance from '../../utils/httpCommon';
import i18n from '../../../i18nextConf';

const setVehicle = async (vehicle: Vehicle): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/vehicle`;
  try {
    const res = await muleSoftInstance.post(path, { ...vehicle });

    return res.data;
  } catch (error) {
    if (error.status === 409) {
      throw new Error(i18n.t('lbl_vehicle_already_registered'));
    } else {
      throw new Error(error.message);
    }
  }
};

const getListVehicles = async () => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/vehicles`;
  try {
    const res: Vehicle[] = (await muleSoftInstance.get(path)).data;
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

const modifyVehicle = async (vehicle: Vehicle) => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/vehicle/modify/${vehicle.vehicleId}`;
  try {
    const payload: { [key: string]: boolean } = {
      favourite: vehicle?.favourite ?? false,
    };
    if (vehicle.plugin !== null && vehicle.plugin !== undefined) {
      payload.plugin = vehicle.plugin;
    }

    const res = await muleSoftInstance.put(path, payload);
    return res.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteVehicle = async (vehicleId, vehicles): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/vehicle`;
  try {
    const res = await muleSoftInstance.delete(path, {
      data: {
        vehicleId: parseInt(vehicleId),
      },
    });
    if (res.data.vehicleId !== undefined && res.data.vehicleId == vehicleId) {
      return vehicles.filter(e => e.vehicleId == vehicleId)[0];
    } else {
      return vehicles;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

const getListAttributes = async () => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/vehicle/listAttributes`;
  try {
    const res = (await muleSoftInstance.get(path)).data;
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};
const getFieldsBySede = async () => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/vehicle/configuration`;
  try {
    const res = (await muleSoftInstance.get(path)).data;
    return res;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const vehicleServices = {
  setVehicle,
  getListVehicles,
  deleteVehicle,
  modifyVehicle,
  getListAttributes,
  getFieldsBySede,
};
