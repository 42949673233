import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { Raffle } from '../../../../_redux/models/reservation.model';
import { getNumberOfWeeks, isEmpty } from '../../../../utils/functions';
import {
  checkIfDateIsDisabled,
  EWeekday,
  getDaysFromTo,
} from '../../../../utils/dateTime';

const startOfWeek = (weeks: number): Dayjs =>
  dayjs().tz().add(weeks, 'week').weekday(EWeekday.sunday).utc().startOf('day');

const endOfWeek = (weeks: number): Dayjs =>
  dayjs()
    .tz()
    .add(weeks, 'week')
    .weekday(EWeekday.saturday)
    .utcOffset(0, true)
    .endOf('day');

export const getMinAndMaxDates = (
  raffle: Raffle,
  dayParkingPlannedTurningPoint: number,
  hourParkingPlannedTurningPoint: string,
  parkingPlannedWeeksExtend: number,
) => {
  const today = dayjs().tz();

  let minDate: Dayjs;
  let maxDate: Dayjs;

  const raffleStart = dayjs()
    .tz()
    .weekday(raffle.weekDay)
    ['hour'](parseInt(raffle?.startHour?.slice(0, 2)))
    ['minute'](parseInt(raffle?.startHour?.slice(3)))
    ['second'](0);

  const turningPoint = dayjs()
    .tz()
    .weekday(dayParkingPlannedTurningPoint)
    ['hour'](parseInt(hourParkingPlannedTurningPoint?.slice(0, 2)))
    ['minute'](parseInt(hourParkingPlannedTurningPoint?.slice(3)))
    ['second'](0);

  /* ----------------------------------------------------- */
  /* --- If the turning point is set before the raffle --- */
  /* ----------------------------------------------------- */

  if (turningPoint.isBefore(raffleStart)) {
    // Before the turning point
    if (today.isBetween(startOfWeek(0), turningPoint)) {
      minDate = startOfWeek(1);
      maxDate = endOfWeek(parkingPlannedWeeksExtend - 1); // Because first week is "0"
    }

    // After the turning point, before the raffle
    if (today.isBetween(turningPoint, raffleStart)) {
      minDate = startOfWeek(1);
      maxDate = endOfWeek(parkingPlannedWeeksExtend);
    }

    // After the raffle
    if (today.isAfter(raffleStart)) {
      minDate = startOfWeek(2);
      maxDate = endOfWeek(parkingPlannedWeeksExtend);
    }
  }

  /* ----------------------------------------------------- */
  /* --- If the turning point is set after the raffle ---- */
  /* ----------------------------------------------------- */

  if (turningPoint.isAfter(raffleStart)) {
    // After the turning point
    if (today.isAfter(turningPoint)) {
      minDate = startOfWeek(2);
      maxDate = endOfWeek(parkingPlannedWeeksExtend + 1);
    } else if (
      // Before the raffle (and before the turning point)
      today.isBefore(raffleStart)
    ) {
      minDate = startOfWeek(1);
      maxDate = endOfWeek(parkingPlannedWeeksExtend);
    } else if (today.isAfter(raffleStart) && today.isBefore(turningPoint)) {
      // After the raffle, before the turning point
      minDate = startOfWeek(2);
      maxDate = endOfWeek(parkingPlannedWeeksExtend);
    }
  }

  return {
    minDate,
    maxDate,
    raffleStart,
    turningPoint,
    todayWeekday: today.weekday(),
  };
};

export const getParkingReservationsAndRequestDates = (
  reservations: any[],
  idSede: number,
) => {
  if (isEmpty(reservations) || !idSede) {
    return [];
  }

  const dates = [];

  reservations.forEach(reservation => {
    if (
      ['Parking', 'Parking-Request'].includes(reservation?.useType) &&
      reservation?.idSede === idSede
    ) {
      reservation.dates.forEach(date => {
        dates.push(date.reservationDate);
      });
    }
  });

  return dates;
};

export const getParkingRequestNumberOfWeeks = (reservableDays: string[]) => {
  const firstDate = dayjs(_.first(reservableDays)).startOf('day').toISOString();
  const lastDate = dayjs(_.last(reservableDays)).endOf('day').toISOString();

  return getNumberOfWeeks(firstDate, lastDate);
};

export const getParkingRequestCalendarConfig = (
  minDate: Date,
  maxDate: Date,
  publicHolidays: string[],
): { reservableDays: string[]; numWeeks: number } => {
  const dateMin = dayjs(minDate);
  const dateMax = dayjs(maxDate);

  const reservableDaysResult = getDaysFromTo(dateMin, dateMax);
  const reservableDays = [];

  reservableDaysResult.forEach(date => {
    const dateIsDisabled = checkIfDateIsDisabled(
      dayjs(date).toDate(),
      publicHolidays,
      [0, 6],
    );

    if (!dateIsDisabled) {
      reservableDays.push(dayjs(date).format('YYYY-MM-DD'));
    }
  });

  const numWeeks = getParkingRequestNumberOfWeeks([
    dayjs().format('YYYY-MM-DD'),
    ...reservableDaysResult,
  ]);

  return { reservableDays, numWeeks };
};
