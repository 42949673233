import { Icon } from '@acciona/ui-ionic-kit';
import { useState } from 'react';
import { Props } from './types';
import styles from './styles.module.scss';

const Accordion: React.FC<Props> = props => {
  const { content, legend, className, contentClassName } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={`${styles.legend} ${className}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {legend}
        {content && (
          <Icon
            className={`icon icon-24 ${
              isOpen ? 'icon-chevron-up' : 'icon-chevron-down'
            }`}
          />
        )}
      </div>
      <div
        className={`${styles.content} ${contentClassName ?? ''}`}
        aria-expanded={isOpen}
      >
        {content}
      </div>
    </>
  );
};

export default Accordion;
