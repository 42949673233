import { Button } from '@acciona/ui-ionic-kit';
import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import _ from 'lodash';
import { userActions } from '../../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { UserStore } from '../../../_redux/models/user.model';

const PrivacyPolicies: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const legalDocuments = useSelector((store: UserStore) => store.user.policies);

  const acceptTerms = () => {
    dispatch(userActions.acceptPolicies());
  };

  return (
    <IonPage>
      <IonContent>
        <div className={styles.header}>
          <h1>{t('lbl_terms_header')}</h1>
        </div>
        <div className={styles.content}>
          <p>
            {t('msg_terms')}
            <ExternalLink
              link={
                _.first(
                  legalDocuments.filter(
                    doc => doc.docType === 'termsConditions',
                  ),
                ).url
              }
            >
              <span className={styles.link}>{t('termsConditions')}</span>
            </ExternalLink>{' '}
            ,{' '}
            <ExternalLink
              link={
                _.first(
                  legalDocuments.filter(
                    doc => doc.docType === 'dataProtection',
                  ),
                ).url
              }
            >
              <span className={styles.link}>{t('dataProtection')}</span>
            </ExternalLink>{' '}
            {t('and')}
            <ExternalLink
              link={
                _.first(
                  legalDocuments.filter(doc => doc.docType === 'cookiePolicy'),
                ).url
              }
            >
              <span className={styles.link}>{t('cookiePolicy')}</span>
            </ExternalLink>
          </p>
        </div>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={acceptTerms}>{t('btn_accept_terms')}</Button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicies;
