import { ParkingReleaseItem } from '../../../../_redux/models/parking.model';
import { isEmpty } from '../../../../utils/functions';

export const updateReservationsToModify = (
  prevReservations: ParkingReleaseItem[],
  newReservation: ParkingReleaseItem,
) => {
  if (isEmpty(prevReservations)) {
    return [newReservation];
  }

  const prevReservationsIds = prevReservations.map(item => item.reservationId);

  if (!prevReservationsIds.includes(newReservation.reservationId)) {
    return [...prevReservations, newReservation];
  } else {
    return prevReservations.map(item =>
      item.reservationId === newReservation.reservationId
        ? newReservation
        : item,
    );
  }
};

export const checkSaveIsDisabled = (
  reservationsToModify: ParkingReleaseItem[],
) => {
  if (isEmpty(reservationsToModify)) {
    return true;
  }

  const isValid = (reservation: ParkingReleaseItem) => {
    if (
      reservation.reservationType !== 'Personalizada' ||
      reservation.deleted
    ) {
      return true;
    } else if (
      reservation.reservationType === 'Personalizada' &&
      !!reservation.startHour &&
      !!reservation.endHour
    ) {
      return true;
    } else {
      return false;
    }
  };

  return reservationsToModify.some(r => !isValid(r));
};

export const getDeletedReservationsIds = (
  reservationsToModify: ParkingReleaseItem[],
) => {
  return reservationsToModify
    .filter(reservation => reservation.deleted)
    .map(item => item.reservationId);
};
