import { Parking } from '../_redux/models/parking.model';
import { Vehicle, VehicleAttributeType } from '../_redux/models/vehicles.model';
import { PARKING_PERMISSIONS, VEHICLE_TYPES } from './constants';
import { isEmpty } from './functions';

/**
 * Check if the vehicle has the conditions required for a pooler moto parking space
 * @param {Vehicle} vehicle
 * @returns {boolean}
 */
const isPoolerMotoVehicle = (vehicle: Vehicle) =>
  [VEHICLE_TYPES.motorcycle, VEHICLE_TYPES.motoSilence].includes(vehicle.type);

/**
 * Check if the vehicle has the conditions required for a pooler microcar parking space
 * @param {Vehicle} vehicle
 * @returns {boolean}
 */
const isPoolerMicrocarVehicle = (vehicle: Vehicle) =>
  VEHICLE_TYPES.microcar === vehicle.type;
/**
 * Check if the vehicle has the conditions required for a car parking space
 * @param {Vehicle} vehicle
 * @returns {boolean}
 */
const isCarVehicle = (vehicle: Vehicle) => VEHICLE_TYPES.car === vehicle.type;

/**
 * Check if the vehicle type is car
 * @param {Vehicle} vehicle
 * @returns {boolean}
 */
const isCarVehicleType = (vehicleType: VehicleAttributeType) =>
  VEHICLE_TYPES.car === vehicleType?.typeId;

/**
 * Check if the vehicle type is motorcycle or motoSilence
 * @param {Vehicle} vehicle
 * @returns {boolean}
 */
const isPoolerMotoVehicleType = (vehicleType: VehicleAttributeType) =>
  [VEHICLE_TYPES.motorcycle, VEHICLE_TYPES.motoSilence].includes(
    vehicleType?.typeId,
  );

/**
 * Check if the vehicle type is microcar
 * @param {Vehicle} vehicle
 * @returns {boolean}
 */
const isPoolerMicrocarVehicleType = (vehicleType: VehicleAttributeType) =>
  VEHICLE_TYPES.microcar === vehicleType?.typeId;

/**
 * Check if the user satisfies the requirements for pooler_moto permission
 * @param {string[]} permissions - user permissions
 * @returns {boolean}
 */
export const isPoolerMotoUser = (permissions: string[]): boolean => {
  return (
    !permissions.includes(PARKING_PERMISSIONS.parking) &&
    permissions.includes(PARKING_PERMISSIONS.pooler_moto)
  );
};

/**
 * Check if the user satisfies the requirements for pooler microcar permission
 * @param {string[]} permissions - user permissions
 * @returns {boolean}
 */
export const isPoolerMicrocarUser = (permissions: string[]): boolean => {
  return (
    !permissions.includes(PARKING_PERMISSIONS.parking) &&
    permissions.includes(PARKING_PERMISSIONS.pooler_microcar)
  );
};

/**
 * Checks if a vehicle is pluggable.
 * @param {number} vehicleType - The type of the vehicle.
 * @returns {boolean} - True if the vehicle is pluggable, false otherwise.
 */
export const vehicleIsPluggable = (vehicleType: number) => {
  return [VEHICLE_TYPES.car, VEHICLE_TYPES.microcar].includes(vehicleType);
};

/**
 * Returns object indexed by the parking id, witch value is the parking type id in Habitat
 * @param {any} parkingTypes
 * @returns {Object}
 */
const getHabitatParkingTypes = (parkingTypes: any) => {
  if (!parkingTypes) {
    return null;
  }

  const types = {};

  parkingTypes.forEach(type => {
    types[type.typeId] = type.habitatTypeParkingId;
  });

  return types;
};

/**
 * Filter a list of parking spaces and return only those that match the type of parking space of the suggested vehicle.
 * @param {Parking[]} parkings
 * @param {any} parkingTypes
 * @param {Vehicle} suggestedVehicle
 * @returns {Parking[]}
 */
export const getAvailableParkings = (
  parkings: Parking[],
  parkingTypes: any,
  suggestedVehicle: Vehicle,
) => {
  const habitatParkingTypes = getHabitatParkingTypes(parkingTypes);

  if (isEmpty(parkings) || !habitatParkingTypes[suggestedVehicle.type]) {
    return [];
  }

  return parkings.filter(
    parking => parking?.type === habitatParkingTypes[suggestedVehicle.type],
  );
};

const getSuggestedVehicleAllPermissions = (
  userVehicles: Vehicle[],
  favoriteVehicle: Vehicle,
  defaultVehicle: Vehicle,
) => {
  if (favoriteVehicle) return favoriteVehicle;
  if (defaultVehicle) return defaultVehicle;
  return userVehicles[0];
};

const getSuggestedVehicleParkingAndMoto = (
  userVehicles: Vehicle[],
  favoriteVehicle: Vehicle,
  defaultVehicle: Vehicle,
) => {
  if (favoriteVehicle && !isPoolerMicrocarVehicle(favoriteVehicle))
    return favoriteVehicle;
  if (defaultVehicle && !isPoolerMicrocarVehicle(defaultVehicle))
    return defaultVehicle;
  return userVehicles.find(v => !isPoolerMicrocarVehicle(v));
};

const getSuggestedVehicleMotoAndMicrocar = (
  userVehicles: Vehicle[],
  favoriteVehicle: Vehicle,
  defaultVehicle: Vehicle,
) => {
  if (favoriteVehicle && !isCarVehicle(favoriteVehicle)) return favoriteVehicle;
  if (defaultVehicle && !isCarVehicle(defaultVehicle)) return defaultVehicle;
  return userVehicles.find(v => !isCarVehicle(v));
};

const getSuggestedVehicleMoto = (
  userVehicles: Vehicle[],
  favoriteVehicle: Vehicle,
  defaultVehicle: Vehicle,
) => {
  if (favoriteVehicle && isPoolerMotoVehicle(favoriteVehicle))
    return favoriteVehicle;
  if (defaultVehicle && isPoolerMotoVehicle(defaultVehicle))
    return defaultVehicle;
  return userVehicles.find(v => isPoolerMotoVehicle(v));
};

const getSuggestedVehicleMicrocar = (
  userVehicles: Vehicle[],
  favoriteVehicle: Vehicle,
  defaultVehicle: Vehicle,
) => {
  if (favoriteVehicle && isPoolerMicrocarVehicle(favoriteVehicle))
    return favoriteVehicle;
  if (defaultVehicle && isPoolerMicrocarVehicle(defaultVehicle))
    return defaultVehicle;
  return userVehicles.find(v => isPoolerMicrocarVehicle(v));
};

/**
 * Returns user's favorite or default vehicle based on permissions
 * @param {Vehicle[]} userVehicles
 * @param {string[]} permissions
 * @returns {Vehicle[]}
 */
export const getSuggestedVehicle = (
  userVehicles: Vehicle[],
  permissions: string[],
  selectedVehicle?: Vehicle,
): Vehicle => {
  const favoriteVehicle =
    selectedVehicle ?? userVehicles.find(v => v.favourite);
  const defaultVehicle = userVehicles.find(v => v.defaultVehicle);

  const hasParkingPermission = permissions.includes(
    PARKING_PERMISSIONS.parking,
  );
  const hasPoolerMotoPermission = permissions.includes(
    PARKING_PERMISSIONS.pooler_moto,
  );
  const hasPoolerMicrocarPermission = permissions.includes(
    PARKING_PERMISSIONS.pooler_microcar,
  );
  if (hasParkingPermission && hasPoolerMicrocarPermission) {
    // PARKING (+ MOTO) + MICROCAR
    return getSuggestedVehicleAllPermissions(
      userVehicles,
      favoriteVehicle,
      defaultVehicle,
    );
  }
  if (hasParkingPermission) {
    // PARKING (+ MOTO)
    return getSuggestedVehicleParkingAndMoto(
      userVehicles,
      favoriteVehicle,
      defaultVehicle,
    );
  }
  if (hasPoolerMotoPermission && hasPoolerMicrocarPermission) {
    //  MOTO + MICROCAR
    return getSuggestedVehicleMotoAndMicrocar(
      userVehicles,
      favoriteVehicle,
      defaultVehicle,
    );
  }
  if (hasPoolerMotoPermission) {
    // MOTO
    return getSuggestedVehicleMoto(
      userVehicles,
      favoriteVehicle,
      defaultVehicle,
    );
  }
  // MICROCAR
  return getSuggestedVehicleMicrocar(
    userVehicles,
    favoriteVehicle,
    defaultVehicle,
  );
};

/**
 * Return icon's CSS class of the vehicle type
 * @param {string} vehicleType - numeric id of the vehicle type
 * @returns CSS class of the icon
 */
export const getVehicleIcon = (vehicleType: number) => {
  if (vehicleType === VEHICLE_TYPES.car) {
    return 'icon icon-car';
  } else if (vehicleType === VEHICLE_TYPES.motorcycle) {
    return 'icon icon-moto';
  } else if (vehicleType === VEHICLE_TYPES.motoSilence) {
    return 'icon icon-moto_silence';
  } else if (vehicleType === VEHICLE_TYPES.microcar) {
    return 'icon icon-microcar';
  } else {
    return '';
  }
};

/**
 * Filter vehicles and vehicle types by permissions
 * @param itemList
 * @param permissions
 * @param isVehicleType
 * @returns filtered vehicles or vehicle types
 */
const filterVehiclesAndVehicleTypesByPermission = ({
  itemList,
  permissions,
  isVehicleType,
}: {
  itemList: (Vehicle | VehicleAttributeType)[];
  permissions: string[];
  isVehicleType: boolean;
}) => {
  if (!itemList) {
    return [];
  }

  // object of check functions to be able to check for
  // vehicle types and vehicles
  const checkFunctions = {
    parking: isVehicleType ? isCarVehicleType : isCarVehicle,
    pooler_moto: isVehicleType ? isPoolerMotoVehicleType : isPoolerMotoVehicle,
    pooler_microcar: isVehicleType
      ? isPoolerMicrocarVehicleType
      : isPoolerMicrocarVehicle,
  };

  const hasParkingPermission = permissions.includes(
    PARKING_PERMISSIONS.parking,
  );
  const hasMotoPermission = permissions.includes(
    PARKING_PERMISSIONS.pooler_moto,
  );
  const hasMicrocarPermission = permissions.includes(
    PARKING_PERMISSIONS.pooler_microcar,
  );

  itemList = itemList.filter(v => {
    const microcarFilter =
      hasMicrocarPermission && checkFunctions['pooler_microcar'](v as any);
    const motoFilter =
      (hasParkingPermission || hasMotoPermission) &&
      checkFunctions['pooler_moto'](v as any);
    const carFilter =
      hasParkingPermission && checkFunctions['parking'](v as any);
    return microcarFilter || motoFilter || carFilter;
  });
  return itemList;
};

/**
 * Get available vehicles based on permissions
 * @param vehicles
 * @param permissions
 * @returns vehicles array on permissions
 */
export const getAvailableVehicles = (
  vehicles: Vehicle[],
  permissions: string[],
) => {
  return filterVehiclesAndVehicleTypesByPermission({
    itemList: vehicles,
    permissions: permissions,
    isVehicleType: false,
  }) as Vehicle[];
};

/**
 * Get available vehicle types based on permissions
 * @param vehicleTypes
 * @param permissions
 * @returns vehicle types array on permissions
 */
export const getAvailableVehicleTypes = (
  vehicleTypes: VehicleAttributeType[],
  permissions: string[],
): VehicleAttributeType[] => {
  return filterVehiclesAndVehicleTypesByPermission({
    itemList: vehicleTypes,
    permissions,
    isVehicleType: true,
  }) as VehicleAttributeType[];
};
