import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { restaurantsActions } from '../../../../../../_redux/actions';
import { RestaurantsStore } from '../../../../../../_redux/models/restaurants.model';
import { Cover } from '../../../templates/Cover/Cover';

const RestaurantList: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { restaurants } = useSelector(
    (store: RestaurantsStore) => store.restaurants,
  );

  useEffect(() => {
    dispatch(restaurantsActions.getRestaurants());
  }, []);

  return (
    <Cover
      backgroundImageDesktop="./assets/images/restaurants.png"
      backgroundImageMobile="./assets/images/restaurantsMobile.png"
      title={t('lbl_more_restaurants')}
      subtitle={t('lbl_more_restaurants_description')}
      menuTitle={t('lbl_restaurants')}
      menuIcon="plate"
      menuItems={restaurants}
      itemDetailUrl="/services/restaurants/restaurantInformation"
    />
  );
};

export default RestaurantList;
