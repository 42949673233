import { TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserStore } from '../../../_redux/models/user.model';
import Can from '../../../components/Can/Can';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { isEmpty } from '../../../utils/functions';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import { AppStore } from '../../../_redux/models/app.model';
import { appActions } from '../../../_redux/actions';
import styles from './styles.module.scss';
import { ParkingStore } from '../../../_redux/models/parking.model';
import { PARKING_PERMISSIONS } from '../../../utils/constants';

const User: React.FC = () => {
  const { t } = useTranslation();

  const cardRegistered = useSelector(
    (state: UserStore) => state.user.card.registered,
  );

  const { hasReservableDays: hasReservablePKDays } = useSelector(
    (state: ParkingStore) => state.parking,
  );

  const { permanentParkings } = useSelector(
    (store: ReservationStore) => store.reservation,
  );

  const { offlineServices, offlineNetwork } = useSelector(
    (state: AppStore) => state.app,
  );

  const offlineMode = offlineServices || offlineNetwork;

  const dispatch = useDispatch();

  type ChipType = {
    type?: 'success' | 'info' | 'warning' | 'error' | 'light' | 'soft';
    text?: string;
  };

  const menuItems = [
    {
      id: 1,
      text: t('lbl_personal_data'),
      routerLink: '/profile/user/detail',
      permissionCode: 'public',
      show: true,
      accessAllowedInOfflineMode: false,
    },
    {
      id: 2,
      text: t('lbl_digital_card'),
      routerLink: '/profile/user/card',
      permissionCode: 'public',
      startChip:
        cardRegistered !== true &&
        ({
          type: 'warning',
          text: t('lbl_pending'),
        } as ChipType),
      show: isPlatform('capacitor'),
      accessAllowedInOfflineMode: isPlatform('capacitor') && !offlineNetwork,
    },
    {
      id: 3,
      text: t('lbl_personal_area_parking'),
      routerLink: '/profile/user/parking-menu',
      permissionCode: [
        PARKING_PERMISSIONS.parking,
        PARKING_PERMISSIONS.pooler_moto,
        PARKING_PERMISSIONS.pooler_microcar,
      ],
      show:
        isEmpty(permanentParkings) ||
        (!isEmpty(permanentParkings) && hasReservablePKDays),
      accessAllowedInOfflineMode: false,
    },
    {
      id: 4,
      text: t('lbl_personal_area_work_place'),
      routerLink: '/profile/user/work-place-menu',
      permissionCode: 'public',
      show: true,
      accessAllowedInOfflineMode: false,
    },
  ];

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_profile_personal_data')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {menuItems
                .filter(menuItem => menuItem.show)
                .map(item => (
                  <Can key={item.id} functionality={item.permissionCode}>
                    <DetailListItem
                      className={styles.profileList}
                      mode="ios"
                      lines="inset"
                      type="button"
                      titleLight={item.text}
                      endIcon="icon icon-chevron-right"
                      startChip={item?.startChip}
                      {...(!offlineMode || item.accessAllowedInOfflineMode
                        ? { routerLink: item.routerLink }
                        : {
                            onClick: () =>
                              dispatch(appActions.setOfflineToastIsOpen(true)),
                          })}
                    />
                  </Can>
                ))}
            </DetailList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default User;
