import { useMemo } from 'react';
import { IonRouterOutlet, useIonViewDidLeave } from '@ionic/react';
import { Redirect, Route, useLocation } from 'react-router';
import NavBar from '../../components/Base/NavBar';
import Home from './Home/Home';
import ActionsMenuMobile from './ActionsMenuMobile/ActionsMenuMobile';
import More from './More/More';
import Reservation from './Reservation/Reservation';
import News from './News/ListNews/News';
import { useTranslation } from 'react-i18next';
import { UserStore } from '../../_redux/models/user.model';
import { AppStore } from '../../_redux/models/app.model';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { handleBackMinimizeButton } from '../../utils/functions';
import CanRoute from '../../components/CanRoute/CanRoute';
import {
  MORE_PERMISSIONS,
  RESERVATION_PERMISSIONS,
} from '../../utils/constants';
import { MoreStore } from './More/_redux/models';
import { isMoreButtonVisible } from './More/utils/helpers';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const permissionData = useSelector(
    (state: UserStore) => state.user.user.permissions,
  );
  const { offlineNetwork, offlineServices } = useSelector(
    (state: AppStore) => state.app,
  );
  const { user } = useSelector((state: UserStore) => state.user);
  const { config: moreMenuConfig } = useSelector(
    (store: MoreStore) => store.more,
  );
  const { permissionAllHeadOffices } = user;

  const offlineMode = offlineNetwork || offlineServices;

  const hasReservationPermission = _.some(
    permissionAllHeadOffices,
    obj => _.intersection(obj.permissions, RESERVATION_PERMISSIONS).length > 0,
  );

  const routes = useMemo(
    () =>
      [
        {
          id: 1,
          tab: 'home',
          text: t('tab_home'),
          icon: 'icon icon-dashboard',
          href: '/dashboard/home',
          permissionCode: 'public',
          accessAllowedInOfflineMode: true,
        },
        {
          id: 2,
          tab: 'reservations',
          text: t('tab_reservations_menu'),
          icon: 'icon icon-booking',
          href: '/dashboard/reservations',
          permissionCode: 'public',
          noPermission: !offlineMode && !hasReservationPermission,
          accessAllowedInOfflineMode: true,
        },
        {
          id: 3,
          tab: 'actions',
          text: t('tab_actions'),
          icon: 'icon icon-actions',
          centralButton: true,
          href: '/dashboard/actions',
          permissionCode: 'public',
          accessAllowedInOfflineMode: false,
        },
        {
          id: 4,
          tab: 'news',
          text: t('tab_news'),
          icon: 'icon icon-news',
          href: '/dashboard/news',
          permissionCode: 'news',
          accessAllowedInOfflineMode: false,
        },
        {
          id: 5,
          tab: 'more',
          text: t('tab_more'),
          icon: 'icon icon-more',
          href: '/dashboard/more',
          permissionCode: 'public',
          disabled:
            !isMoreButtonVisible(moreMenuConfig, user.permissions) ||
            (!offlineMode &&
              !MORE_PERMISSIONS.some(permission =>
                permissionData.includes(permission),
              )),
          accessAllowedInOfflineMode: false,
        },
      ].filter(
        e =>
          offlineMode ||
          e.permissionCode === 'public' ||
          _.includes(permissionData, e.permissionCode),
      ),
    [permissionData, offlineMode, location.pathname, moreMenuConfig],
  );

  useIonViewDidLeave(() => {
    document.removeEventListener('ionBackButton', handleBackMinimizeButton);
  });

  return (
    <NavBar
      navTabs={routes}
      routing={
        <IonRouterOutlet animated={false}>
          <Route path="/dashboard/home">
            <Home />
          </Route>
          <Route exact path="/dashboard/reservations">
            <Reservation />
          </Route>
          <Route exact path="/dashboard/actions">
            <ActionsMenuMobile />
          </Route>
          <Route exact path="/dashboard/news">
            <CanRoute functionality="news">
              <News />
            </CanRoute>
          </Route>
          <Route exact path="/dashboard/more">
            <CanRoute functionality={MORE_PERMISSIONS}>
              <More />
            </CanRoute>
          </Route>
          <Route exact path="/dashboard">
            <Redirect to="/dashboard/home" />
          </Route>
        </IonRouterOutlet>
      }
    />
  );
};

export default Dashboard;
