import { useEffect, useState } from 'react';
import { isPlatform, useIonAlert } from '@ionic/react';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import { Geolocation } from '@capacitor/geolocation';
import { App as AppCapacitor } from '@capacitor/app';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export const useCheckDevicePermissions = () => {
  const { t } = useTranslation();
  const [bluethoothActive, setBluethoothActive] = useState(true);
  const [locationActive, setLocationActive] = useState(null);
  const [bluethoothPermission, setBluethoothPermission] = useState(null);
  const [locationPermission, setLocationPermission] = useState(null);
  const [firstCheckPermission, setFirstCheckPermission] = useState(true);
  const [messageType, setMessageType] = useState('');
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    let msgType = '';
    if (!bluethoothPermission && !locationPermission) {
      setMessageType('_permission_all');
      msgType = '_permission_all';
    } else if (!locationPermission) {
      setMessageType('_permission_loc');
      msgType = '_permission_loc';
    } else if (!bluethoothPermission) {
      setMessageType('_permission_bt');
      msgType = '_permission_bt';
    } else if (!locationActive && !bluethoothActive) {
      setMessageType('_all');
      msgType = '_all';
    } else if (!bluethoothActive) {
      setMessageType('_bt');
      msgType = '_bt';
    } else if (!locationActive) {
      setMessageType('_loc');
      msgType = '_loc';
    }

    if (
      msgType != '' &&
      firstCheckPermission &&
      !_.isNull(bluethoothPermission) &&
      !_.isNull(locationPermission) &&
      !_.isNull(locationActive) &&
      !_.isNull(bluethoothActive)
    ) {
      setFirstCheckPermission(false);
      presentAlert({
        header: t(`card_alert_permissions.header${msgType}`),
        message: t(`card_alert_permissions.body${msgType}`),
        buttons: [
          {
            text: t('cancel_text'),
            role: 'Cerrar',
            handler: () => setFirstCheckPermission(false),
          },
          {
            text: t('go_to_settings'),
            handler: () => goToSettings(msgType),
          },
        ],
        onDidDismiss: () => setFirstCheckPermission(false),
        mode: 'ios',
      });
    }
  }, [
    bluethoothPermission,
    locationPermission,
    locationActive,
    bluethoothActive,
  ]);

  useEffect(() => {
    getLocationPermission();
    getBluetoothPermission();
    Diagnostic.registerLocationStateChangeHandler(status => {
      if (
        (isPlatform('android') &&
          status !== Diagnostic.locationMode.LOCATION_OFF) ||
        (isPlatform('ios') &&
          (status === Diagnostic.permissionStatus.GRANTED ||
            status === Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE))
      ) {
        setLocationActive(true);
      } else {
        setLocationActive(false);
      }
    });

    Diagnostic.registerBluetoothStateChangeHandler(state => {
      if (state === Diagnostic.bluetoothState.POWERED_ON) {
        setBluethoothActive(true);
      } else if (
        state === Diagnostic.bluetoothState.POWERING_OFF ||
        state === Diagnostic.bluetoothState.POWERED_OFF ||
        state === Diagnostic.bluetoothState.UNAUTHORIZED
      ) {
        setBluethoothActive(false);
      }
    });
    AppCapacitor.addListener('resume', async () => {
      getLocationPermission();
      getBluetoothPermission();
    });

    return () => {
      AppCapacitor.removeAllListeners();
    };
  }, []);

  const getLocationPermission = () => {
    Diagnostic.getLocationAuthorizationStatus().then(statuses => {
      if (
        statuses === Diagnostic.permissionStatus.GRANTED ||
        statuses === Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
      ) {
        setLocationPermission(true);
      } else {
        setLocationPermission(false);
      }
      getCurrentPosition();
    });
  };

  const getCurrentPosition = async () => {
    Geolocation.getCurrentPosition()
      .then(() => {
        setLocationActive(true);
      })
      .catch(() => {
        setLocationActive(false);
      });
  };

  const getBluetoothPermission = () => {
    if (isPlatform('android')) {
      Diagnostic.getBluetoothAuthorizationStatuses().then(statuses => {
        let isPermission = true;
        for (const permission in statuses) {
          if (
            statuses[permission] === Diagnostic.permissionStatus.GRANTED ||
            statuses[permission] ===
              Diagnostic.permissionStatus.NOT_REQUESTED ||
            statuses[permission] === 'not_determined'
          ) {
            isPermission = true;
          } else {
            isPermission = false;
            break;
          }
        }

        setBluethoothPermission(isPermission);
        getBluetoothStatus();
      });
    } else {
      Diagnostic.getBluetoothAuthorizationStatus().then(status => {
        setBluethoothPermission(
          status === Diagnostic.permissionStatus.GRANTED ||
            status === Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE,
        );
        getBluetoothStatus();
      });
    }
  };

  const getBluetoothStatus = async () => {
    Diagnostic.getBluetoothState().then(state => {
      if (Diagnostic.bluetoothState.POWERED_ON === state) {
        setBluethoothActive(true);
      }
      if (
        state === Diagnostic.bluetoothState.POWERING_OFF ||
        state === Diagnostic.bluetoothState.POWERED_OFF ||
        state === Diagnostic.bluetoothState.UNAUTHORIZED
      ) {
        setBluethoothActive(false);
      }
    });
  };

  const goToSettings = redirection => {
    if (
      redirection === '_permission_all' ||
      redirection === '_permission_loc' ||
      redirection === '_permission_bt'
    ) {
      Diagnostic.switchToSettings().then(() => {
        setFirstCheckPermission(false);
      });
      return;
    }

    if (redirection === '_bt' || redirection === '_all') {
      setFirstCheckPermission(false);
      Diagnostic.switchToBluetoothSettings();
      return;
    }
    if (redirection === '_loc') {
      setFirstCheckPermission(false);
      Diagnostic.switchToLocationSettings();
      return;
    }
  };

  const activationDisabled =
    !locationActive ||
    !bluethoothActive ||
    !bluethoothPermission ||
    !locationPermission;

  return {
    activationDisabled,
    locationActive,
    bluethoothActive,
    bluethoothPermission,
    locationPermission,
    messageType,
  };
};
