import { ICutOffMark } from '../../../_redux/services/parking/types';

export const formatCutOffMark = (
  mark: ICutOffMark['score'],
  lang: string,
): string => {
  if (mark < 0) return '-';
  return new Intl.NumberFormat(lang, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(mark)
    .toString();
};

enum EVehicleIcons {
  car = 'icon-car',
  moto = 'icon-moto',
  microcar = 'icon-microcar',
}

export const fallBackIcon = EVehicleIcons.car;

export const tagToIcon: { [key: string]: string } = {
  'Car': EVehicleIcons.car,
  'Moto': EVehicleIcons.moto,
  'Moto silence': EVehicleIcons.moto,
  'Microcar': EVehicleIcons.microcar,
};

export const tagToLiteral: { [key: string]: string } = {
  'Car': 'lbl_car',
  'Moto': 'lbl_bike',
  'Moto silence': 'lbl_moto_silence',
  'Microcar': 'lbl_microcar',
};
