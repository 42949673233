import { App } from '@capacitor/app';
import { origo, OrigoKeysKey } from '@capacitor/origo';
import { isPlatform } from '@ionic/react';
import { doorAction } from '../../../pages/Auth/Hid/const';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';

import {
  BehaviorSubject,
  interval,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import { authServices } from '../auth/auth.services';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';

class HIDService {
  private static KEYS_REFRESH_INTERVAL = 30000;
  public static card: OrigoKeysKey[] = [];
  public static cardChanged = new BehaviorSubject([]);
  public static appStateChanged = new Subject();
  private static code: string;
  private static initialChecksDone = false;
  private static applicationActive = true;
  public static keys: OrigoKeysKey[] = [];
  public static keyRefreshSubscription: Subscription;

  static handleAccessClicked: (
    hidRegistrationCode?: string,
  ) => Promise<doorAction> = async (hidRegistrationCode: string = null) => {
    HIDService.code = hidRegistrationCode;
    const initialization = await HIDService.handleInitializationFlow();
    console.log('AFTER HANDLE INITIALIZATION FLOW');
    HIDService.refreshCards();
    return initialization;
  };

  private static handleInitializationFlow: () => Promise<doorAction> =
    async () => {
      let permisionGranted = true;
      if (!HIDService.applicationActive) {
        return Promise.reject(doorAction.failure);
      }
      const initialized = await origo.initialized();
      if (!initialized.hasBLEPermission && !HIDService.initialChecksDone) {
        await HIDService.hidHandlePermissionsRequest();
        HIDService.initialChecksDone = true;
        return Promise.reject(doorAction.none);
      } else {
        HIDService.initialChecksDone = true;
      }

      if (isPlatform('capacitor') && isPlatform('android')) {
        const permissionLoc = await AndroidPermissions.checkPermission(
          AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
        );
        permisionGranted = permissionLoc.hasPermission;
      }
      console.log('AFTER INITIAL CHECKS');

      if (!permisionGranted) {
        return Promise.reject(doorAction.failureLocation);
      } else if (!initialized.internetAccess) {
        return Promise.reject(doorAction.failureInit);
      } else if (!initialized.hardwareSupportBLE) {
        return Promise.reject(doorAction.failureBluethothIncompatible);
      } else if (!initialized.isBLEEnabled) {
        return Promise.reject(doorAction.failureBluethothDisabled);
      } else if (HIDService.initialChecksDone) {
        return HIDService.handleSetup();
      } else {
        return Promise.reject(doorAction.failureInit);
      }
    };

  private static hidHandlePermissionsRequest = async () => {
    if (!HIDService.applicationActive) {
      return;
    }
    if (isPlatform('capacitor') && isPlatform('android')) {
      const permissionLoc = await AndroidPermissions.checkPermission(
        AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
      );
      if (!permissionLoc.hasPermission) {
        await AndroidPermissions.requestPermissions([
          AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
          AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
          AndroidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION,
          AndroidPermissions.PERMISSION.BLUETOOTH_SCAN,
          AndroidPermissions.PERMISSION.BLUETOOTH_ADVERTISE,
          AndroidPermissions.PERMISSION.BLUETOOTH_CONNECT,
        ]);
        /**
         * For Android 11+ / API 30+), make an initial request with mode set to cordova.plugins.diagnostic.locationAuthorizationMode.ALWAYS will NOT present the user with a dialog at all and immediately returns a DENIED result.
You must request mode=WHEN_IN_USE before requesting mode=ALWAYS
The successCallback is invoked in response to the user's choice in the permission dialog and is passed the resulting authorization status.
When the plugin is running on/built with Android 10+ / API 29+, you can request background location permission by specifying the mode argument as cordova.plugins.diagnostic.locationAuthorizationMode.ALWAYS.
If the build SDK/device version is <= Android 9 / API 28, granting location permission implicitly grants background location permission.
When the plugin is running on/built with Android 12+ / API 31+, you can specify requested location accuracy using the accuracy parameter.
If the build SDK/device version is <= Android 11 / API 30, FULL accuracy is implicitly granted.
         */
        //We have already requested COARSE_LOCATION on the previous line so we request now FINE_LOCATION
        await Diagnostic.requestLocationAuthorization(
          Diagnostic.locationAuthorizationMode.ALWAYS,
          Diagnostic.locationMode.HIGH_ACCURACY,
        );
        HIDService.initialChecksDone = true;

        return;
      }
    } else {
      HIDService.initialChecksDone = true;
      return;
    }
  };

  private static handleSetup: () => Promise<doorAction> = async () => {
    if (!HIDService.applicationActive) {
      return Promise.reject(doorAction.failure);
    }
    let initialized = await origo.initialized();
    if (!initialized.initialized) {
      await HIDService.handleInit();
      initialized = await origo.initialized();
      console.log('AFTER HANDLE INIT');
    }

    if (initialized.initialized) {
      const setupDone = await origo.isEndpointSetUpComplete();
      if (setupDone.setupDone && !HIDService.hasCode()) {
        await origo.endpointUpdate();
        HIDService.keys = (await origo.cardList()).cards;
        HIDService.card = HIDService.keys;
        HIDService.cardChanged.next(HIDService.card);
        return Promise.resolve(doorAction.succes);
      } else {
        if (HIDService.code && HIDService.code.length > 0) {
          await origo.endpointSetup({ code: HIDService.code });
          await origo.endpointUpdate();
          const setupDone = await origo.isEndpointSetUpComplete();
          if (setupDone.setupDone) {
            HIDService.keys = (await origo.cardList()).cards;
            HIDService.card = HIDService.keys;
            HIDService.cardChanged.next(HIDService.card);
            return Promise.resolve(doorAction.succes);
          } else {
            return Promise.reject(doorAction.failureEndpoint);
          }
        } else {
          return Promise.reject(doorAction.failureEndpoint);
        }
      }
    } else {
      return Promise.reject(doorAction.failureEndpoint);
    }
  };

  private static handleInit = async (): Promise<any> => {
    if (!HIDService.applicationActive) {
      return Promise.reject();
    }
    const ACCIONA_ORIGO_LOCK_SERVICE_CODE = 2;
    const ACCIONA_ORIGO_APPID = 'HID-ACCIONA-MASH';
    return await origo.initialize({
      appId: ACCIONA_ORIGO_APPID,
      appIdDescription: ACCIONA_ORIGO_APPID,
      lockServiceCode: ACCIONA_ORIGO_LOCK_SERVICE_CODE,
      emailLogs: '',
    });
  };

  private static getCards: () => Promise<OrigoKeysKey[]> = async () => {
    if (!HIDService.applicationActive) {
      return Promise.resolve([]);
    }
    try {
      const setup = await HIDService.handleSetup();
      if (setup == doorAction.succes) {
        const cards = await origo.cardList();
        return Promise.resolve(cards.cards);
      }
    } catch (error) {
      return Promise.resolve([]);
    }
  };

  private static refreshCards = () => {
    console.log('REFRESH CARDS CALLED');
    const keysCheckerInterval = interval(HIDService.KEYS_REFRESH_INTERVAL);
    HIDService.keyRefreshSubscription = keysCheckerInterval.subscribe(
      async () => {
        const cards = await HIDService.getCards();
        console.log('REFRESH CARDS ', cards, cards.length);
        if (HIDService.card.length != cards.length) {
          HIDService.card = cards;
          console.log('INSIDE IF');
          HIDService.cardChanged.next(HIDService.card);
        }
      },
    );
  };

  public static popupEventDispatcher = new ReplaySubject();

  public static stopScanning = async () => {
    await origo.setShouldScan({ shouldScan: false });
    await origo.stopScanning();
  };

  public static startScanning = async () => {
    try {
      await origo.setShouldScan({ shouldScan: true });
      const authenticated = await authServices.checkToken();
      if (authenticated.token) {
        origo.startScanning();
      } else {
        origo.stopScanning();
      }
    } catch (err) {
      origo.stopScanning();
      throw new Error(err);
    }
  };

  private static hasCode = () => {
    return HIDService.code && HIDService.code.length > 0;
  };

  private static _initialzator = (() => {
    origo.addListener('origoKeysDidConnect', async () => {
      try {
        const authenticated = await authServices.checkToken();

        if (authenticated.token) {
          //origo.startScanning();
        } else {
          origo.stopScanning();
        }
      } catch (error) {
        console.log('error');
      }

      // ejemplo
      if (HIDService.applicationActive) {
        HIDService.popupEventDispatcher.next({
          title: 'Tarjeta Leida',
          message: 'origo_reading_ok',
          type: 'success',
          handler: () => {},
        });
      }
    });

    App.addListener('appStateChange', async ({ isActive }) => {
      HIDService.applicationActive = isActive;
      HIDService.appStateChanged.next(true);

      if (isActive) {
        HIDService.startScanning();
      } else {
        // origo.stopScanning();
      }
    });

    origo.addListener('origoKeysDidFailToConnect', () => {
      if (HIDService.applicationActive) {
        HIDService.popupEventDispatcher.next({
          title: 'Error leyendo tarjeta',
          message: 'origo_reading_ko',
          type: 'error',
          handler: () => {},
        });
      }
    });
  })();
}

export default HIDService;
