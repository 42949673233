import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import Confirmation from '../Shared/Success/Confirmation';
import CallphoneCenter from './CallphoneCenter/CallphoneCenter';
import CategorySelection from './CategorySelection/CategorySelection';
import IncidentForm from './IncidentForm/IncidentForm';
import ReportType from './ReportType/ReportType';

const Incidents: React.FC<any> = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/incidents/categories">
          <CategorySelection />
        </Route>
        <Route
          exact
          path="/incidents/callphone/:phone"
          component={CallphoneCenter}
        />
        <Route exact path="/incidents/reporttype">
          <ReportType />
        </Route>
        <Route exact path="/incidents/report">
          <IncidentForm />
        </Route>
        <Route exact path="/incidents">
          <Redirect to="/incidents/categories" />
        </Route>
        <Route exact path="/incidents/confirmation">
          <Confirmation />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Incidents;
