import { useMemo } from 'react';
import { Icon, Item } from '@acciona/ui-ionic-kit';
import { IonNote, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getDatesHomeOfficeText, isEmpty } from '../../utils/functions';
import { groupDatesByMonth, getSortDate } from '../../utils/dateTime';
import { useSelector } from 'react-redux';
import { AppStore } from '../../_redux/models/app.model';
import Accordion from '../Base/Accordion/Accordion';
import { DaysSummary } from '../DaysSummary/DaysSummary';
import styles from './styles.module.scss';
import { ParkingReservation } from '../../_redux/models/parking.model';

type Props = {
  days: any[];
  onClick?: (e?: any) => void;
  type?: 'parking' | 'desk';
  canModify: boolean;
  modifyDisabled?: boolean;
  reservations?: ParkingReservation[];
};

export const ParkingReleasedDays: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );
  const uniqueDays = useMemo(() => {
    if (isEmpty(props.days)) {
      return [];
    }
    const days = props.days.map(item => getSortDate(item.date));
    return _.uniq(days);
  }, [props.days]);

  const daysString = useMemo(() => {
    if (uniqueDays.length === 0) {
      return '';
    } else if (uniqueDays.length < 6) {
      return groupDatesByMonth(uniqueDays, localLanguage, false);
    } else {
      return `${uniqueDays.length} ${t('txt_days')}`;
    }
  }, [uniqueDays]);
  const homeOfficeDays = props.days.filter(day => day.homeOffice);

  return (
    <Item lines="none" className={`${styles.item}`}>
      <Icon
        slot="start"
        color={'primary'}
        className={`icon icon-booking ${styles.startIcon}`}
      />

      <div className={`ion-text-wrap ${styles.columnList}`}>
        <div className={styles.header}>
          <IonText
            className={`${styles.headline} ion-text-wrap ${styles.block}`}
          >
            {uniqueDays.length > 1 ? t('lbl_multiple_days') : daysString}
          </IonText>
          {props.canModify && (
            <IonNote
              slot="end"
              className={`${styles.subhead} ${styles.color_secondary} ${styles.marginNote}`}
            >
              {props.modifyDisabled ? (
                <span className={styles.blockedButton}>{t('lbl_modify')}</span>
              ) : (
                <span className={styles.activeButton} onClick={props.onClick}>
                  {t('lbl_modify')}
                </span>
              )}
            </IonNote>
          )}
        </div>
        <Accordion
          className={styles.accordion}
          legend={
            <IonText
              className={`ion-text-wrap ${styles.subhead} ${styles.block} ${styles.color_dark}`}
            >
              {daysString}
            </IonText>
          }
          content={
            <IonText
              className={`ion-text-wrap ${styles.block} ${styles.footnote}`}
            >
              <DaysSummary
                className={styles.daysSummary}
                days={props.days}
                reservations={props.reservations}
              />
              <span className={styles.homeOfficeWarning}>
                {!isEmpty(homeOfficeDays) && (
                  <>
                    {getDatesHomeOfficeText(homeOfficeDays, localLanguage)}
                    <b>{t('lbl_homeOffice')}.</b>
                  </>
                )}

                {props.canModify && (
                  <>
                    <br />
                    {t(`not_forget_release_${props.type}`)}
                  </>
                )}
              </span>
            </IonText>
          }
        />
      </div>
    </Item>
  );
};
