import jwtDecode from 'jwt-decode';
import { Token, User } from '../../models/auth.model';
import muleSoftInstance from '../../utils/httpCommon';
import { tokenServices } from '../token/token.services';

const getCurrentSessionUser = async (
  code: string,
  isPhone: boolean,
): Promise<{
  token: Token;
  user: User;
}> => {
  try {
    const path = isPhone
      ? `grant_type=authorization_code&redirect_uri=${process.env.REACT_APP_COGNITO_APP_REDIRECT_URI}&client_id=${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}&code=${code}`
      : `grant_type=authorization_code&redirect_uri=${process.env.REACT_APP_COGNITO_WEB_REDIRECT_URI}&client_id=${process.env.REACT_APP_COGNITO_WEB_CLIENT_ID}&code=${code}`;
    // return;
    const options = {
      method: 'post',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: path,
      url: process.env.REACT_APP_COGNITO_WEB_TOKEN,
    };

    const resp = await muleSoftInstance.call(options);
    const decoded = jwtDecode<any>(resp.data.id_token); // Returns with the JwtPayload type

    const userData = {
      id: decoded.identities[0].userId,
      email: decoded.email,
      name: decoded.name,
      groups: decoded.groups,
      owner: decoded['custom:employeeid'],
    };

    return { token: resp.data, user: userData };
  } catch (err) {
    throw new Error(err);
  }
};

const refreshToken = async (isPhone: boolean): Promise<any> => {
  try {
    const localRefresToken = await tokenServices.getLocalRefreshToken();
    const path = `grant_type=refresh_token&client_id=${
      isPhone
        ? process.env.REACT_APP_COGNITO_APP_CLIENT_ID
        : process.env.REACT_APP_COGNITO_WEB_CLIENT_ID
    }&refresh_token=${localRefresToken}`;
    const options = {
      method: 'post',
      data: path,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      url: process.env.REACT_APP_COGNITO_WEB_TOKEN,
    };

    const resp = await muleSoftInstance.call(options);

    return {
      id_token: resp.data.id_token,
      access_token: resp.data.access_token,
    };
  } catch (err) {
    throw new Error(err);
  }
};

const checkToken = async (): Promise<any> => {
  try {
    const token = await tokenServices.getAccount();
    const user = await tokenServices.getUser();
    if (token && user) {
      return { token: token, user: user };
    } else {
      throw new Error('User not found');
    }
  } catch (err) {
    throw new Error(err);
  }
};

const registerUserHabitat = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/reservationUser/register`;
  try {
    const resp = await muleSoftInstance.post(path, {
      office: 'Office',
      area: 'Area',
      pushId: 'string',
    });
    return resp.data;
  } catch (err) {
    if ([409].includes(err.status)) {
      console.log('Usuario ya registrado en Habitat');
    } else {
      throw new Error(err);
    }
  }
};

export const authServices = {
  refreshToken,
  getCurrentSessionUser,
  checkToken,
  registerUserHabitat,
};
