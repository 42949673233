import { useCheckUserPermissions } from './../../hooks/useCheckUserPermissions';

interface Props {
  functionality: string | string[]; // At least one of this permissions is required
  children: React.ReactNode;
}

const Can: React.FC<Props> = ({ children, functionality }) => {
  const { hasPermission } = useCheckUserPermissions();

  return hasPermission(functionality) || functionality === 'offline' ? (
    <>{children}</>
  ) : (
    <></>
  );
};
export default Can;
