import { useEffect, useMemo, useRef, useState } from 'react';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Alert, Button, Snackbar, TopBar } from '@acciona/ui-ionic-kit';
import { reservationActions } from '../../../../_redux/actions';
import { ParkingRequest } from '../../../../_redux/models/parking.model';
import { history } from '../../../../_helpers/history';
import { getDatesToReleaseText, isEmpty } from '../../../../utils/functions';
import { ModifyParkingRequestItem } from '../ModifyParkingRequestItem/ModifyParkingRequestItem';
import styles from './styles.module.scss';
import _ from 'lodash';

type ParkingRequestLocationState = {
  datesRequest: any[];
  currentParkingRaffles: ParkingRequest[];
};

const ModifyParkingRequest: React.FC = () => {
  const { t } = useTranslation();
  const contentPage = useRef<any>();
  const dispatch = useDispatch();
  const location = useLocation().state as ParkingRequestLocationState;
  const [currentParkingRaffles, setCurrentParkingRaffles] = useState([]);
  const [datesRequest, setDatesRequest] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const daysToReleaseText = useMemo(() => {
    return getDatesToReleaseText(
      datesRequest.map(date => ({
        ...date,
        status: deletedIds.includes(date.id),
      })),
    );
  }, [datesRequest, deletedIds]);

  useEffect(() => {
    if (location?.currentParkingRaffles) {
      setCurrentParkingRaffles(location?.currentParkingRaffles);
    }
  }, [location?.currentParkingRaffles]);

  useEffect(() => {
    if (location?.datesRequest) {
      setDatesRequest(location?.datesRequest);
    }
  }, [location?.datesRequest]);

  const deleteItem = item => {
    setDeletedIds(prev => [...prev, item.id]);
  };

  const cancelParkingRequestByDay = async () => {
    const allDeleted = currentParkingRaffles.every(parkingRaffle =>
      isEmpty(
        parkingRaffle.dates.filter(d => !deletedIds.includes(d.requestId)),
      ),
    );

    dispatch(
      reservationActions.cancelParkingRequest(
        currentParkingRaffles,
        deletedIds,
        datesRequest,
        () =>
          allDeleted
            ? history.replace('/dashboard/reservations')
            : history.goBack(),
      ),
    );
  };

  const handleDelete = () => {
    setShowAlert(true);
  };

  const confirmDelete = () => {
    cancelParkingRequestByDay();
  };

  const resetDates = () => {
    setDeletedIds([]);
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        className={`ion-no-border ${styles.backgroundLight} ${styles.toolbarGrid}`}
      >
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_modify_request')}
        />

        <Snackbar
          type="info"
          text={t('lbl_date_selected', {
            number: datesRequest.length,
            multiple: datesRequest.length > 1 ? 's' : '',
          })}
          align="center"
        />
      </IonHeader>
      <IonContent ref={contentPage}>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {!isEmpty(datesRequest) &&
              _.orderBy(datesRequest, 'date')
                .filter(date => !deletedIds.includes(date.id))
                .map(item => (
                  <ModifyParkingRequestItem
                    key={item.id}
                    onDelete={() => deleteItem(item)}
                    date={item.date.toISOString()}
                  />
                ))}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button color="lighter" onClick={handleCancel}>
              {t('cancel_text')}
            </Button>
          </div>
          <div>
            <Button onClick={handleDelete} disabled={isEmpty(deletedIds)}>
              {t('lbl_save_changes')}
            </Button>
          </div>
        </div>
      </IonFooter>
      <Alert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('title_delete_parking_request', {
          days: daysToReleaseText,
        }).replace('de el día', 'del día')}
        message={t('txt_are_you_sure')}
        buttons={[
          { text: 'No', role: 'cancel', handler: resetDates },
          {
            text: t('lbl_affirmative'),
            handler: confirmDelete,
          },
        ]}
        mode="ios"
      />
    </IonPage>
  );
};

export default ModifyParkingRequest;
