import { FilterChip, Snackbar, TopBar } from '@acciona/ui-ionic-kit';

import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
} from '@ionic/react';
import { TextWithInterpolatedAnchor } from '../../../components/TextWithInterpolatedAnchor/TextWithInterpolatedAnchor';
import { SpinnerFullSize } from '../../../components/Base/SpinnerFullSize/SpinnerFullSize';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../i18nextConf';

import { parkingServices } from '../../../_redux/services';
import { NotificationsActions, userActions } from '../../../_redux/actions';

import { formatDateToWeekDayAndMonth } from '../../../utils/dateTime';
import {
  fallBackIcon,
  formatCutOffMark,
  tagToIcon,
  tagToLiteral,
} from './helpers';

import { IVehicleCutOffs } from '../../../_redux/services/parking/types';

import styles from './styles.module.scss';
import { UserStore } from '../../../_redux/models/user.model';
import { PARKING_PERMISSIONS } from '../../../utils/constants';

const ParkingCutOff = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    reservationPolicies,
    user: { permissions },
  } = useSelector((state: UserStore) => state.user);
  const [cutOffMarks, setCutOffMarks] = useState<IVehicleCutOffs[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const hasMicrocarPermission = permissions.includes(
    PARKING_PERMISSIONS.pooler_microcar,
  );
  const updateCutOffMarks = async (): Promise<void> => {
    try {
      const newCutOffMarks = await parkingServices.getCutOffMarks();
      if (newCutOffMarks.length === 0) {
        // Request worked but no data
        // so user has no permission
        dispatch(NotificationsActions.setGenericErrorToast(t('error_403')));
        history.replace('/profile/user/parking-menu');
      } else {
        setCutOffMarks(
          newCutOffMarks
            .sort((a, b) => (a.type > b.type ? 1 : -1))
            .filter(m => m.type !== 'Microcar' || hasMicrocarPermission),
        );
        setSelectedFilter(0);
      }
    } catch (err) {
      dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
      history.replace('/profile/user/parking-menu');
    }
  };

  const handleSetSelectedFilter = ({ id }): void => {
    setSelectedFilter(+id);
  };

  useEffect(() => {
    setSelectedFilter(0);
    updateCutOffMarks();
  }, []);

  useEffect(() => {
    dispatch(userActions.getReservationPolicies());
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_personal_parking_cutt-off')}
        />
      </IonHeader>

      <IonContent>
        <Snackbar
          type="info"
          text={
            <TextWithInterpolatedAnchor
              text={t('cut-off_marks_help', {
                interpolation: { prefix: '>', suffix: '<' },
              })}
              link={
                reservationPolicies?.find(e => e.type === 'policyParking')
                  ?.url || ''
              }
              anchorClassName={styles.link}
            />
          }
          icon="icon icon-info"
          align="left"
        />
        {cutOffMarks?.length === 0 && <SpinnerFullSize />}
        {cutOffMarks?.length > 0 && (
          <div className={styles.gridWeb}>
            <div className={styles.listGrid}>
              <IonItem className={styles.chips} lines="full">
                {cutOffMarks.map((m, i) => {
                  return (
                    <FilterChip
                      key={m.type}
                      text={t(tagToLiteral[m.type])}
                      icon={tagToIcon[m.type] ?? fallBackIcon}
                      id={i.toString()}
                      checked={selectedFilter === i}
                      onChange={handleSetSelectedFilter}
                    />
                  );
                })}
              </IonItem>
              <IonItem lines="none">
                <IonList>
                  {cutOffMarks[selectedFilter]?.dates.map(({ date, score }) => {
                    return (
                      <p key={date} className={styles.markRow}>
                        <b className={styles.date}>
                          {formatDateToWeekDayAndMonth(date)}
                        </b>
                        : {formatCutOffMark(score, i18n.language)}
                      </p>
                    );
                  })}
                </IonList>
              </IonItem>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ParkingCutOff;
