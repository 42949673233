import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { doorAction } from './const';
import { closeCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const useHandleAccess = (cards, action) => {
  const { t } = useTranslation();
  const succesMsg = t('hid.success');
  const failMsg = t('hid.fail');
  const welcomeMessage = t('hid.welcome');
  let welcomeFailMessage = t('hid.welcome_fail');
  const [msg, setMsg] = useState({
    msg: '',
    icon: closeCircleOutline,
    welcome: '',
  });
  const history = useHistory();

  const handleClose = () => {
    history.replace('/dashboard/home');
  };

  const handleOpen = () => {
    history.replace('/CodeInvitation/card', cards);
  };
  const setSuccessMessage = () => {
    setMsg({
      msg: succesMsg,
      icon: checkmarkCircleOutline,
      welcome: welcomeMessage,
    });
  };
  const setFailMessage = () => {
    setMsg({
      msg: failMsg,
      icon: closeCircleOutline,
      welcome: welcomeFailMessage,
    });
  };

  useEffect(() => {
    switch (action) {
      case doorAction.succes:
        setSuccessMessage();
        setTimeout(() => {
          handleOpen();
        }, 5000);
        break;
      case doorAction.failure:
        setFailMessage();
        setTimeout(() => {
          handleClose();
        }, 5000);
        break;
      case doorAction.failureInit:
        welcomeFailMessage = t('hid.failureInit');
        setFailMessage();
        setTimeout(() => {
          handleClose();
        }, 5000);
        break;
      case doorAction.failureEndpoint:
        welcomeFailMessage = t('hid.failureEndpoint');
        setFailMessage();
        setTimeout(() => {
          handleClose();
        }, 5000);
        break;
      case doorAction.failureBluethothDisabled:
        welcomeFailMessage = t('hid.failureBluetoothDisable');
        setFailMessage();
        setTimeout(() => {
          handleClose();
        }, 5000);
        break;
      case doorAction.failureLocation:
        welcomeFailMessage = t('hid.failureLocation');
        setFailMessage();
        setTimeout(() => {
          handleClose();
        }, 5000);
        break;
      case doorAction.failureBluethothIncompatible:
        welcomeFailMessage = t('hid.failureBluetoothIncompatible');
        setFailMessage();
        setTimeout(() => {
          handleClose();
        }, 5000);
        break;
      default:
        break;
    }
    if (action == doorAction.succes) {
      setSuccessMessage();
      console.log('Inside succes', action);
      setTimeout(() => {
        handleOpen();
      }, 5000);
    } else if (action == doorAction.failure) {
      setFailMessage();
    }
  }, []);

  return msg;
};

export default useHandleAccess;
