import {
  IonCol,
  IonGrid,
  IonItemDivider,
  IonLoading,
  IonRow,
} from '@ionic/react';

import { Props, Suggested } from './types';
import { useTranslation } from 'react-i18next';

import { isEmpty } from '../../../../utils/functions';
import { NewsSecondaryCard } from '../../../../components/Base/NewsSecondaryCard';
import _ from 'lodash';

import styles from './styles.module.scss';
import { NewsActions, searchActions } from '../../../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import useDataNews from '../../../../hooks/useDataNews';
import { NewsStore } from '../../../../_redux/models/news.model';
import { SearchStore } from '../../../../_redux/models/search.model';
import { useEffect, useState } from 'react';
import { searchServices } from '../../../../_redux/services';
import { useHistory } from 'react-router';
import { SearchItem } from './SearchItem/SearchItem';
import { Alert } from '@acciona/ui-ionic-kit';
import { SearchHeader } from './SearchHeader/SearchHeader';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { AppStore } from '../../../../_redux/models/app.model';

const SearchDashboard: React.FC<Props> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newsSearch, isLoading } = useSelector(
    (store: NewsStore) => store.news,
  );
  const { recentlySearches, popularSearches } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  const { isLoadingDelete } = useSelector((store: SearchStore) => store.search);
  const newsCard = useDataNews(newsSearch);
  const [showAlert, setShowAlert] = useState(false);
  const [suggesteds, setSuggesteds] = useState<Suggested>();
  const [wordToDelete, setWordToDelete] = useState('');
  const history = useHistory();
  const handleSelected = id => {
    dispatch(NewsActions.selectNews(id, history.location.pathname));
  };

  const getSuggestedSearch = async () => {
    const suggestResult = await searchServices.getSuggested();
    setSuggesteds(suggestResult);
  };

  const deleteSearchHandler = async () => {
    dispatch(searchActions.deleteSearchHistory());
    getSuggestedSearch();
  };

  const deleteSearchItemHandler = async (historyItem: string) => {
    setWordToDelete(historyItem);
    dispatch(searchActions.deleteSearchItem(historyItem));
    getSuggestedSearch();
  };

  useEffect(() => {
    dispatch(NewsActions.getNewsSearch());
    getSuggestedSearch();
  }, []);

  useEffect(() => {
    if (!isLoadingDelete) {
      setWordToDelete('');
    }
  }, [isLoadingDelete]);

  return (
    <>
      {suggesteds &&
      !isEmpty(suggesteds) &&
      !isEmpty(_.filter(suggesteds, v => _.has(v, 'lastSearches'))) &&
      !isEmpty(
        _.first(_.filter(suggesteds, v => _.has(v, 'lastSearches')))
          .lastSearches,
      ) ? (
        <div className={`${styles.gridWeb}`}>
          <SearchHeader
            title={t('lbl_lastSearches')}
            titleColor="primary"
            subtitleColor={styles.subheader}
            subtitle={t('lbl_clear_history')}
            lines="none"
            actionSubtitle={() => setShowAlert(true)}
            loading={wordToDelete === '' && isLoadingDelete}
            className={styles.header}
          />
          {_.first(_.filter(suggesteds, v => _.has(v, 'lastSearches')))
            .lastSearches.slice(0, recentlySearches)
            .map((result, index) => (
              <SearchItem
                key={index}
                startIcon="icon icon-search"
                startIconColor="medium"
                title={result.searchWords}
                titleColor="dark"
                endIcon={`icon icon-close`}
                endIconColor="primary"
                ariaEndIcon={t('lbl-remove-search-aria', {
                  item: result.searchWords,
                })}
                actionItem={() =>
                  props.setSuggestedSearchWord(result.searchWords)
                }
                actionEndIcon={() =>
                  deleteSearchItemHandler(result.searchWords)
                }
                loading={
                  (result.searchWords === wordToDelete ||
                    wordToDelete === '') &&
                  isLoadingDelete
                }
              />
            ))}
          <IonItemDivider className={styles.divisoryLine} />
        </div>
      ) : null}
      {suggesteds &&
      !isEmpty(suggesteds) &&
      !isEmpty(_.filter(suggesteds, v => _.has(v, 'popularSearches'))) &&
      !isEmpty(
        _.first(_.filter(suggesteds, v => _.has(v, 'popularSearches')))
          .popularSearches,
      ) ? (
        <div className={`${styles.gridWeb}`}>
          <SearchHeader
            title={t('lbl_popular')}
            titleColor="#ff0000"
            lines="none"
            className={styles.header}
          />
          {_.first(_.filter(suggesteds, v => _.has(v, 'popularSearches')))
            .popularSearches.slice(0, popularSearches)
            .map((result, index) => (
              <SearchItem
                key={index}
                startIcon="icon icon-search"
                startIconColor="medium"
                title={result.searchWords}
                titleColor="dark"
                endIcon={`icon icon-arrow ${styles.iconArrow}`}
                endIconColor="secondary"
                onClick={() => props.setSuggestedSearchWord(result.searchWords)}
              />
            ))}
        </div>
      ) : null}
      {!isEmpty(newsCard) && (
        <IonGrid className={styles.gridWeb}>
          <IonItemDivider className={styles.divisoryLine} />
          <DetailListItem title={t('tab_news')} titleColor="primary" />
          <IonRow>
            {newsCard &&
              newsCard.map(data => {
                return (
                  <IonCol
                    key={data.id}
                    size="12"
                    sizeSm="6"
                    sizeMd="6"
                    sizeLg="6"
                    sizeXl="4"
                    className={styles.colAlignCenter}
                  >
                    <NewsSecondaryCard
                      data={data}
                      onClick={() => handleSelected(data.nav_id)}
                      mode="ios"
                      displaySocial={false}
                    />
                  </IonCol>
                );
              })}
          </IonRow>
        </IonGrid>
      )}

      {showAlert && (
        <Alert
          isOpen={showAlert}
          header={t('lbl_clear_history')}
          message={t('msg_remove_history_confirmation')}
          buttons={[
            {
              text: t('lbl_no'),
              role: 'cancel',
              handler: () => setShowAlert(false),
            },
            {
              text: t('lbl_yes'),
              handler: deleteSearchHandler,
            },
          ]}
          mode="ios"
        />
      )}

      <IonLoading
        isOpen={isLoading}
        message={t('msg_loading')}
        duration={8000}
      />
    </>
  );
};

export default SearchDashboard;
