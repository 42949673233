import { Restaurant, RestaurantsAction } from '../models/restaurants.model';
import { restaurantsServices } from '../services/restaurants/restaurants.services';
import { restaurantsTypesEnum } from '../types/restaurants.types.enum';

const restaurantsRequest = (): RestaurantsAction => {
  return {
    type: restaurantsTypesEnum.RESTAURANTS_REQUEST,
  };
};

const fetchRestaurantsFailure = (error: string): RestaurantsAction => {
  return {
    type: restaurantsTypesEnum.RESTAURANTS_FAILURE,
    error,
  };
};

const setRestaurants = (restaurants: Restaurant[]): RestaurantsAction => ({
  type: restaurantsTypesEnum.SET_RESTAURANTS,
  restaurants: restaurants,
});

const getRestaurants = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(restaurantsRequest());
    try {
      const restaurants = await restaurantsServices.getRestaurants();
      dispatch(setRestaurants(restaurants));
    } catch (e) {
      dispatch(fetchRestaurantsFailure(e.message));
    }
  };
};

export const restaurantsActions = {
  getRestaurants,
};
