import { useEffect, useMemo } from 'react';
import {
  IonHeader,
  IonContent,
  IonFooter,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Modal, Snackbar, TopBar } from '@acciona/ui-ionic-kit';
import { RadioList } from '../../../../components/Base/RadioList';
import { AppStore } from '../../../../_redux/models/app.model';
import { Props } from './types';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { DateTime } from '../../../../components/DateTime/DateTime';
import { POLICIES_SCHEDULES } from '../../../../utils/constants';
import { showPolicies } from '../../../../utils/functions';
import { calcOneHourMoreOrLess } from '../../../../utils/dateTime';
import styles from './styles.module.scss';

const SettingsModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { campusTimeZone } = useSelector(
    (store: AppStore) => store.app.globalSettings,
  );
  const customScheduleHoraMin = props.configurations.policies.find(
    p => p.nombre === 'Personalizada',
  )
    ? props.configurations.policies.find(p => p.nombre === 'Personalizada')
        ?.fullHoraMin
    : '08:00';
  const customScheduleHoraMax = props.configurations.policies.find(
    p => p.nombre === 'Personalizada',
  )
    ? props.configurations.policies.find(p => p.nombre === 'Personalizada')
        ?.fullHoraMax
    : '20:00';
  const handleSelectedSchedule = type => {
    props.setValue({
      ...props.settingsReservation,
      schedule: type,
    });
  };

  const handleSelectedConfig = config => {
    props.setValue({
      ...props.settingsReservation,
      config: config,
    });
  };

  const cnfValue = [
    {
      name: t('SAME_DESK_CONFIGURATION'),
      value: 'SAME_DESK_CONFIGURATION',
    },
    {
      name: t('SAME_ZONE_CONFIGURATION'),
      value: 'SAME_ZONE_CONFIGURATION',
    },
  ];

  const disabled = useMemo(() => {
    if (
      props.settingsReservation.schedule === 'custom_schedule' &&
      (props.settingsReservation?.customStart == null ||
        props.settingsReservation?.customEnd == null)
    ) {
      return true;
    }
    return false;
  }, [props.settingsReservation]);
  const getPreferencesOptions = () => {
    const resultArray = cnfValue.filter(cnfItem => {
      return props.configurations.deskReservationConfigurations.find(
        desk => desk.configurationName === cnfItem.value,
      );
    });
    return resultArray;
  };
  const getScheduleOptions = () => {
    const scheduleType = [];
    const policies = props?.configurations?.policies;

    if (!policies) {
      return [];
    }
    _.sortBy(policies, 'id').forEach(conf => {
      const confValue =
        POLICIES_SCHEDULES?.find(e => e.id == conf.nombre) || null;

      if (!confValue) return;

      switch (confValue.value) {
        case 'complete_day':
          scheduleType.push({
            name: t('complete_day'),
            value: 'complete_day',
          });
          break;
        case 'morning_schedule':
          scheduleType.push({
            name: `${t('morning_schedule', { endHour: conf?.fullHoraMax })}`,
            value: 'morning_schedule',
          });
          break;
        case 'afternoon_schedule':
          scheduleType.push({
            name: `${t('afternoon_schedule', {
              startHour: conf?.fullHoraMin,
            })}`,
            value: 'afternoon_schedule',
          });
          break;
        case 'custom_schedule':
          scheduleType.push({
            name: `${t('custom_schedule')}`,
            value: 'custom_schedule',
          });
      }
    });
    return scheduleType;
  };

  const closeModal = () => {
    props.setOpenModal(false);
    props.onClose();
  };
  const pickerButtons = document.getElementsByClassName('picker-button');
  const simulateClickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  });

  useEffect(() => {
    return () => {
      pickerButtons?.length > 0 &&
        pickerButtons[0].dispatchEvent(simulateClickEvent);
    };
  }, []);

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={() => closeModal()}
      cssClass={styles.modalSty}
    >
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonButton className={styles.button} onClick={closeModal}>
              <IonIcon
                slot="icon-only"
                className={`${styles.icon} icon icon-chevron-left`}
              />
            </IonButton>
          }
          title={props.title}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.listModal}>
          <DetailList className={styles.list}>
            {!props.release && showPolicies(props.configurations.policies) && (
              <DetailListItem
                lines="full"
                title={t('reservation_schedule')}
                readonly
              />
            )}
            {props.release && props.releasedDates?.length > 0 && (
              <Snackbar
                type="info"
                text={t('lbl_date_selected', {
                  number: props.releasedDates.length,
                  multiple: props.releasedDates.length !== 1 ? 's' : '',
                })}
                align="center"
              />
            )}
            {(props.release || showPolicies(props.configurations.policies)) && (
              <RadioList
                options={getScheduleOptions()}
                selectedOption={props.settingsReservation.schedule}
                onChange={handleSelectedSchedule}
              />
            )}

            {props.settingsReservation &&
              props.settingsReservation.schedule === 'custom_schedule' && (
                <>
                  <DetailListItem
                    lines="full"
                    title={t('select_schedule')}
                    readonly
                  />
                  <DetailListItem
                    mode="ios"
                    lines="none"
                    titleLight={t('init_time')}
                    className={styles.hourText}
                    endText={
                      <DateTime
                        name="initTime"
                        className={styles.dateTime}
                        display-format="HH:mm"
                        picker-format="HH:mm"
                        minuteValues="0"
                        min={customScheduleHoraMin}
                        max={calcOneHourMoreOrLess(
                          props.settingsReservation?.customEnd,
                          false,
                        )}
                        placeholder={t('workroom_placeholder')}
                        value={props.settingsReservation?.customStart}
                        onIonChange={e =>
                          props.setValue({
                            ...props.settingsReservation,
                            customStart: e.detail.value!,
                          })
                        }
                        cancelText={t('cancel_text')}
                        doneText={t('done_text')}
                        displayTimezone={campusTimeZone}
                      />
                    }
                  />
                  <div className={styles.lineInsetSeparator}>
                    <div></div>
                  </div>
                  <DetailListItem
                    mode="ios"
                    lines="none"
                    titleLight={t('end_time')}
                    className={styles.hourText}
                    endText={
                      <DateTime
                        name="endTime"
                        className={styles.dateTime}
                        display-format="HH:mm"
                        picker-format="HH:mm"
                        minuteValues="0"
                        min={calcOneHourMoreOrLess(
                          props.settingsReservation.customStart,
                          true,
                        )}
                        max={customScheduleHoraMax}
                        value={props.settingsReservation?.customEnd}
                        placeholder={t('workroom_placeholder')}
                        onIonChange={e =>
                          props.setValue({
                            ...props.settingsReservation,
                            customEnd: e.detail.value!,
                          })
                        }
                        disabled={
                          props.settingsReservation?.customStart == null
                        }
                        cancelText={t('cancel_text')}
                        doneText={t('done_text')}
                      />
                    }
                  />
                  <div className={styles.lineInsetSeparator}>
                    <div></div>
                  </div>
                </>
              )}
          </DetailList>
          {!props.release &&
            props.configurations?.deskReservationConfigurations?.length > 1 && (
              <DetailList>
                <DetailListItem lines="full" title={t('preference')} readonly />
                <RadioList
                  options={getPreferencesOptions()}
                  selectedOption={props.settingsReservation.config}
                  onChange={handleSelectedConfig}
                />
              </DetailList>
            )}
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        {props.release && (
          <div className={styles.btnGroupWeb}>
            <div>
              <Button color="lighter" onClick={() => props.onClose()}>
                {t('cancel_text')}
              </Button>
            </div>
            <div>
              <Button
                onClick={() => props.onSave && props.onSave()}
                disabled={disabled}
              >
                {t('liberate_desk_in_selected_shedule')}
              </Button>
            </div>
          </div>
        )}
      </IonFooter>
    </Modal>
  );
};
export default SettingsModal;
