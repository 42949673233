/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { AuthStore } from '../_redux/models/auth.model';
import { useSelector } from 'react-redux';
import { isPlatform } from '@ionic/react';
import {
  InAppBrowser,
  InAppBrowserObject,
} from '@awesome-cordova-plugins/in-app-browser';
import { useTranslation } from 'react-i18next';

export const useVideoPlayer = (videoUrl: string) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [playError, setPlayError] = useState('');
  const { t } = useTranslation();
  const authState = useSelector((state: AuthStore) => state.auth);
  const externalBrowserRef = useRef<InAppBrowserObject | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (externalBrowserRef.current) {
        externalBrowserRef.current.close();
        externalBrowserRef.current = null;
      }
    };
  }, []);

  const extractUrl = (url: string): string => {
    const index = url.indexOf('/callback');
    if (index !== -1) {
      return url.substring(0, index);
    }
    return url;
  };

  // const baseUrl = extractUrl(process.env.REACT_APP_COGNITO_WEB_REDIRECT_URI);
  const baseUrl = 'https://dev-campus.acciona.es';
  // https://dev-campus.acciona.es/news/video/133547307
  // https://video.ibm.com/embed/recorded/133547307

  const openExternalVideoPlayer = async (videoId: string): Promise<void> => {
    const options = `location=no,closebuttoncaption=OK,clearcache=yes,hidden=yes`;
    const destinationUrl = `${baseUrl}/news/video/${videoId}`;
    const user = authState.user;

    const account = {
      access_token: authState.accessToken,
      expires_in: authState.expiresIn,
      id_token: authState.idToken,
      refresh_token: authState.refreshToken,
      token_type: 'Bearer',
    };

    const accountString = JSON.stringify(account);
    const userString = JSON.stringify(user);
    const gaCookiesAcceptedString = JSON.stringify(true);
    const code = `
      localStorage.setItem('account', '${accountString}');
      localStorage.setItem('user', '${userString}');
      localStorage.setItem('GaCookiesAccepted', '${gaCookiesAcceptedString}');
      localStorage.setItem('destinationUrl', '${destinationUrl}');
    `;

    const externalBrowser = InAppBrowser.create(
      destinationUrl,
      '_blank',
      options,
    );

    externalBrowserRef.current = externalBrowser;

    const closeBrowser = () => {
      if (externalBrowserRef.current) {
        externalBrowserRef.current.close();
        externalBrowserRef.current = null;
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };

    const handleLoadError = () => {
      cleanup();
      setIsLoading(false);
    };

    const handleLoadStart = (event: any) => {
      setIsLoading(true);
      let isFirstLoad = true;
      if (isFirstLoad) {
        externalBrowser.executeScript({ code });
        isFirstLoad = false;
      }

      if (event.url.includes('exit')) {
        setIsLoading(false);
        cleanup();
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleLoadStop = (event: any) => {
      externalBrowser.show();
      setIsLoading(false);
      // if (event.url.includes(destinationUrl)) {
      //   try {
      //     externalBrowser.show();
      //     setIsLoading(false);
      //     if (timerRef.current) {
      //       clearTimeout(timerRef.current);
      //       timerRef.current = null;
      //     }
      //   } catch {
      //     setPlayError(t('error_500'));
      //     cleanup();
      //   }
      // } else {
      //   externalBrowser.hide();
      // }
    };

    const loadErrorSubscription = externalBrowser
      .on('loaderror')
      .subscribe(handleLoadError);
    const loadStartSubscription = externalBrowser
      .on('loadstart')
      .subscribe(handleLoadStart);
    const loadStopSubscription = externalBrowser
      .on('loadstop')
      .subscribe(handleLoadStop);

    const cleanup = () => {
      loadErrorSubscription.unsubscribe();
      loadStartSubscription.unsubscribe();
      loadStopSubscription.unsubscribe();
      setIsLoading(false);
      closeBrowser();
    };

    externalBrowser.on('exit').subscribe(cleanup);
  };

  const playVideo = async () => {
    if (isPlatform('capacitor')) {
      const videoId = videoUrl.split('/').reverse()[0];
      // const options = `location=yes,closebuttoncaption=X,clearcache=yes,hidenavigationbuttons=yes,hideurlbar=yes`;
      // InAppBrowser.create(
      //   `https://video.ibm.com/recorded/${videoId}`,
      //   '_blank',
      //   options,
      // );
      setIsLoading(true);
      await openExternalVideoPlayer(videoId);
    } else {
      setShowVideo(true);
    }
  };

  const closeVideo = () => {
    setIsLoading(false);
    setShowVideo(false);
  };

  return {
    showVideo,
    playVideo,
    closeVideo,
    isLoading,
    playError,
    openExternalVideoPlayer,
  };
};
