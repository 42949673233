import { history } from '../../_helpers/history';
import type { AuthAction, User } from '../models/auth.model';
import { authServices } from '../services';
import HIDService from '../services/hid/hid.services';
import { tokenServices } from '../services/token/token.services';
import { authTypesEnum } from '../types';

const authRequest = (): any => {
  return {
    type: authTypesEnum.REQUEST,
  };
};

const authReset = (): any => {
  return {
    type: authTypesEnum.RESET,
  };
};

const authSuccess = (
  idToken: string,
  accessToken: string,
  refreshToken: string,
  expiresIn: number,
  user: User,
): AuthAction => {
  return {
    type: authTypesEnum.AUTH_SUCCESS,
    idToken: idToken,
    accessToken: accessToken,
    refreshToken: refreshToken,
    expiresIn: expiresIn,
    user: user,
  };
};

const authSuccessRefreshToken = (
  idToken: string,
  accessToken: string,
): AuthAction => {
  return {
    type: authTypesEnum.REFRESH_TOKEN,
    idToken: idToken,
    accessToken: accessToken,
  };
};

const authFailure = (error: string): AuthAction => {
  return {
    type: authTypesEnum.FAILURE,
    error: error,
  };
};

const logout = (): AuthAction => {
  try {
    tokenServices.removeAccount();
    HIDService.stopScanning();
    HIDService.keyRefreshSubscription.unsubscribe();
    history.replace('/login');
  } catch (error) {
    history.replace('/login');
  }
  return {
    type: authTypesEnum.LOGOUT,
  };
};

/**
 * Gets a new token o checks local/secure storage token.
 * Redirects to login in case of error.
 * @param {string} code - Authentication code
 * @param {boolean} isPhone - Device is an iPhone
 * @param {boolean} getNewToken - true: gets a new token | false: checks stored token
 * @returns
 */
const getToken = (
  code: string = '',
  isPhone: boolean = false,
  getNewToken: boolean = false,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const getTokenAndUser = async () => {
        const { token, user } = getNewToken
          ? await authServices.getCurrentSessionUser(code, isPhone)
          : await authServices.checkToken();
        return { token, user };
      };

      const { token, user } = await getTokenAndUser();

      await dispatch(
        authSuccess(
          token.id_token,
          token.access_token,
          token.refresh_token,
          token.expires_in,
          user,
        ),
      );
      await tokenServices.setAccount(token, user);
    } catch (error) {
      history.replace('/login');
      dispatch(authFailure(error.message));
      dispatch(authReset());
    }
  };
};

const setIsLoading = (isLoading: boolean): AuthAction => {
  return {
    type: authTypesEnum.SET_IS_LOADING,
    isLoading: isLoading,
  };
};

export const authActions = {
  authSuccess,
  authRequest,
  authFailure,
  authReset,
  logout,
  getToken,
  authSuccessRefreshToken,
  setIsLoading,
};
