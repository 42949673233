import { useMap } from 'react-leaflet';
import { IonFabButton, IonIcon } from '@ionic/react';
import { disableClickEventPropagation } from '../../utils/map';
import styles from './styles.module.scss';

type Props = {
  minZoom: number;
  cssClass?: string;
};

export const ZoomOutControl: React.FC<Props> = (props: Props) => {
  const map = useMap();

  const handleZoomOut = () => {
    disableClickEventPropagation('zoom-out-control');
    if (map.getZoom() > props.minZoom) {
      map.setView(map.getCenter(), map.getZoom() - 1);
    }
  };

  return (
    <IonFabButton
      id="zoom-out-control"
      className={`${styles.fabBtn} ${props.cssClass ?? ''}`}
      color="primary"
      onClick={handleZoomOut}
    >
      <IonIcon className="icon icon-minus" />
    </IonFabButton>
  );
};
