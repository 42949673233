import { Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

export const PermanentIcon: React.FC<{ isListReservation?: boolean }> = ({
  isListReservation = false,
}) => {
  const white = '#ffffff';
  const grey = '#f5f5f5';
  return (
    <div
      className={styles.permanent}
      style={{ backgroundColor: isListReservation ? grey : white }}
    >
      <Icon className="icon icon-pin" />
    </div>
  );
};
