import { IonSpinner } from '@ionic/react';
import styles from './styles.module.scss';

export const SpinnerFullSize = () => {
  return (
    <div className={styles.spinnerContainer}>
      <IonSpinner />
    </div>
  );
};
