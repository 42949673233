import L from 'leaflet';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import { isPlatform } from '@ionic/react';

export const disableClickEventPropagation = buttonId => {
  const button = L.DomUtil.get(buttonId);
  button && L.DomEvent.disableClickPropagation(button);
};

export const togglePseudoFullscreen = isFullscreen => {
  const container = L.DomUtil.get('map-container');
  let display = 'block';
  const navBarDesktop = L.DomUtil.get('nav-bar-desktop');
  const detailHeader = document.getElementById('map-detail-header');
  const detailCard = document.getElementById('map-detail-card');
  const allNotMapElements = document.querySelectorAll(
    'body *:not(#map-container *)',
  );

  if (!isFullscreen) {
    // Enter fullscreen mode
    if (container) {
      L.DomUtil.addClass(container, 'leaflet-pseudo-fullscreen');
      L.DomUtil.addClass(container, 'custom-map-fullscreen-safe-area');
    }
    display = 'none';
    allNotMapElements.forEach(e => {
      if (!e.querySelector('#map-container *')) {
        e.style.visibility = 'hidden';
      }
    });
  } else {
    // Exit fullscreen mode
    if (container) {
      L.DomUtil.removeClass(container, 'leaflet-pseudo-fullscreen');
      L.DomUtil.removeClass(container, 'custom-map-fullscreen-safe-area');
    }
    display = 'block';
    allNotMapElements.forEach(e => {
      if (!e.querySelector('#map-container *')) {
        e.style.visibility = 'visible';
      }
    });
  }

  if (navBarDesktop) {
    if (!isFullscreen || isPlatform('capacitor')) {
      navBarDesktop.style.display = 'none';
    } else {
      navBarDesktop.style.display = display;
    }
  }

  if (detailCard) {
    detailCard.style.display = display;
  }
  if (detailHeader) {
    detailHeader.style.display = display;
  }
};

export const addFullscreenControl = (map, options = {}) => {
  // create a fullscreen button and add it to the map
  L.control
    .fullscreen({
      position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
      title: 'Full Screen', // change the title of the button, default Full Screen
      titleCancel: 'Exit Full Screen', // change the title of the button when fullscreen is on, default Exit Full Screen
      content: null, // change the content of the button, can be HTML, default null
      forceSeparateButton: false, // force separate button to detach from zoom buttons, default false
      forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
      fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
      ...options,
    })
    .addTo(map);
};

export const isFullscreen = map => {
  return map.isFullscreen();
};

export const toggleFullscreen = map => {
  map.toggleFullscreen(); // Either go fullscreen, or cancel the existing fullscreen.
};
