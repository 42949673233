import dayjs from 'dayjs';
import i18n from '../../../../i18nextConf';
import { IPassegerInvitation } from '../../../../_redux/services/passengers/types';
import { convertListToText } from '../../../../utils/functions';

export const initialData: IPassegerInvitation = {
  driver: '',
  dates: [],
  sede: {
    idSede: 1,
    descriptionSede: '',
  },
};

export const datesList = (
  dates: { date: string }[],
  separator: string,
  lastSeparator: string,
) => {
  const processedDates = dates.reduce((acc, curr) => {
    const [year, month, day] = curr.date.split('-');
    const key = year + '-' + month;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(+day);
    return acc;
  }, {});

  const stringsByMonth = Object.keys(processedDates)
    .sort((a, b) => {
      return a > b ? 1 : -1;
    })
    .map(key => {
      const monthNumber = key.split('-')[1];
      const days = processedDates[key];
      return `${convertListToText(days, separator, lastSeparator)} ${i18n.t(
        'lbl_of_date',
      )}${dayjs(`2024-${monthNumber}-01`).format('MMMM')}`;
    });

  return convertListToText(stringsByMonth, separator, lastSeparator);
};
