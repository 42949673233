import { TopBar } from '@acciona/ui-ionic-kit';
import { IonBackButton, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Can from '../../../components/Can/Can';
import { DetailList } from '../../../components/Base/DetailList';
import { DetailListItem } from '../../../components/Base/DetailListItem';
import { AppStore } from '../../../_redux/models/app.model';
import { appActions } from '../../../_redux/actions';
import styles from './styles.module.scss';
import { WorkstationStore } from '../../../_redux/models/workstation.model';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import { isEmpty, showPolicies } from '../../../utils/functions';

const WorkPlaceMenu: React.FC = () => {
  const { t } = useTranslation();

  const { configurations, hasReservableDays } =
    useSelector((state: WorkstationStore) => state.workstation) || {};

  const { offlineServices, offlineNetwork } = useSelector(
    (state: AppStore) => state.app,
  );

  const { permanentDesks } = useSelector(
    (store: ReservationStore) => store.reservation,
  );

  const policies = configurations?.policies ?? [];
  const deskReservationConfigurations =
    configurations?.deskReservationConfigurations ?? [];

  const offlineMode = offlineServices || offlineNetwork;

  const dispatch = useDispatch();

  const menuItems = [
    {
      id: 1,
      text: t('lbl_personal_favorite_spots'),
      routerLink: '/profile/user/favworkstations',
      permissionCode: 'desks',
      show: isEmpty(permanentDesks) || hasReservableDays,
      accessAllowedInOfflineMode: false,
    },
    {
      id: 2,
      text: t('lbl_personal_work_place_preferences'),
      routerLink: '/profile/user/work-preferences',
      permissionCode: 'desks',
      show:
        isEmpty(permanentDesks) ||
        (!isEmpty(permanentDesks) && hasReservableDays),
      accessAllowedInOfflineMode: false,
    },
    {
      id: 3,
      text: t('lbl_personal_work_place'),
      routerLink: '/profile/user/work-place',
      permissionCode: 'public',
      show: true,
      accessAllowedInOfflineMode: false,
    },
  ];

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_personal_area_work_place')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              {menuItems
                .filter(menuItem => menuItem.show)
                .map(item => {
                  if (item.id === 2) {
                    if (
                      policies &&
                      deskReservationConfigurations &&
                      (showPolicies(policies) ||
                        deskReservationConfigurations.length > 1)
                    ) {
                      return (
                        <Can key={item.id} functionality={item.permissionCode}>
                          <DetailListItem
                            className={styles.profileList}
                            mode="ios"
                            lines="inset"
                            type="button"
                            titleLight={item.text}
                            endIcon="icon icon-chevron-right"
                            {...(!offlineMode || item.accessAllowedInOfflineMode
                              ? { routerLink: item.routerLink }
                              : {
                                  onClick: () =>
                                    dispatch(
                                      appActions.setOfflineToastIsOpen(true),
                                    ),
                                })}
                          />
                        </Can>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <Can key={item.id} functionality={item.permissionCode}>
                        <DetailListItem
                          className={styles.profileList}
                          mode="ios"
                          lines="inset"
                          type="button"
                          titleLight={item.text}
                          endIcon="icon icon-chevron-right"
                          {...(!offlineMode || item.accessAllowedInOfflineMode
                            ? { routerLink: item.routerLink }
                            : {
                                onClick: () =>
                                  dispatch(
                                    appActions.setOfflineToastIsOpen(true),
                                  ),
                              })}
                        />
                      </Can>
                    );
                  }
                })}
            </DetailList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WorkPlaceMenu;
