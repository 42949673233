import { isPlatform } from '@ionic/react';
import { authActions } from '../actions';
import { tokenServices } from '../services/token/token.services';
import { getErrorMessage } from './helpers';
import muleSoftInstance from './httpCommon';
import { authServices } from '../services';

export const errorInterceptor = store => {
  const { dispatch } = store;
  let retries = 0;
  const maxRetries = 4;

  muleSoftInstance.interceptors.response.use(
    res => {
      try {
        res.data.map(value => {
          let transformedValue = value;
          if (typeof value === 'string') {
            transformedValue = decodeURI(value);
          }
          return transformedValue;
        });
        return Promise.resolve(res);
      } catch (error) {
        return Promise.resolve(res);
      }
    },
    async (err, originalConfig) => {
      if (originalConfig.url !== '/auth/signin' && err) {
        if (err.status === 500 && retries < maxRetries) {
          retries++;
          return muleSoftInstance.call(originalConfig);
        }

        if (err.status === 401 && retries < maxRetries) {
          try {
            const { access_token, id_token } = await authServices.refreshToken(
              isPlatform('capacitor'),
            );

            dispatch(
              authActions.authSuccessRefreshToken(id_token, access_token),
            );

            await tokenServices.updateLocalTokens(id_token, access_token);

            originalConfig.headers.Authorization = `Bearer ${id_token}`;
            retries++;
            return muleSoftInstance.call(originalConfig);
          } catch (_error) {
            dispatch(authActions.authReset());
            return Promise.reject(_error);
          }
        } else {
          err.message = getErrorMessage(err.status, err?.data?.error?.message);
        }
      }
      retries = 0;

      return Promise.reject(err);
    },
  );
};
