import { AlertButton } from '@ionic/react';
import i18n from '../../i18nextConf';
import { isEmpty } from '../../utils/functions';
import {
  NotificationsAction,
  Notification,
  NotificationDetail,
  NotificationsState,
} from '../models/notifications.model';
import { AlertMailBoxServices } from '../services/notifications/alertmailbox.services';
import { NotificationsTypesEnum } from '../types/notifications.types.enum';
import dayjs from 'dayjs';

const notificationRequest = (): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.REQUEST,
  };
};
const fetchNotificationsFailure = (error: string): any => {
  return {
    type: NotificationsTypesEnum.FAILURE_NOTIFICATIONS,
    error: error,
  };
};

const setNoticeToast = (noticeToastMessage: string): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_NOTICE_MESSAGE,
    noticeToastMessage: noticeToastMessage,
  };
};

const resetNoticeToast = (): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.RESET_NOTICE_MESSAGE,
  };
};

const setNotifications = (
  Notifications: Notification[],
): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_NOTIFICATIONS,
    notifications: Notifications,
  };
};

const subscribeFirebase = (): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SUBSCRIBE_FIREBASE,
  };
};

const setNotificationsHome = (
  Notifications: Notification[],
): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_NOTIFICATIONS_HOME,
    notifications: Notifications,
  };
};
const setNotices = (Notifications: Notification[]): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_NOTICES,
    notifications: Notifications,
  };
};
const setNotificationRead = (id: string): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_NOTIFICATION_READ,
    id: id,
  };
};
const setAllNotificationsRead = (): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_ALL_NOTIFICATIONS_READ,
  };
};
const delNotification = (id: string): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.DEL_NOTIFICATION,
    id: id,
  };
};
const setGenericToast = (
  message: string,
  type: NotificationsState['genericToast']['type'],
): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_GENERIC_TOAST,
    genericToast: {
      message,
      type,
      isOpen: true,
    },
  };
};

const setGenericSuccessToast = (message: string) => {
  return setGenericToast(message, 'success');
};

const setGenericInfoToast = (message: string) => {
  return setGenericToast(message, 'info');
};

const setGenericErrorToast = (message: string) => {
  return setGenericToast(message, 'error');
};

const setGenericWarningToast = (message: string) => {
  return setGenericToast(message, 'warning');
};

const dismissGenericToast = (): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_GENERIC_TOAST,
    genericToast: {
      message: '',
      type: 'info',
      isOpen: false,
    },
  };
};
const setGenericAlert = (
  header: string,
  message: string,
): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_GENERIC_ALERT,
    genericAlert: {
      header,
      message,
      isOpen: true,
      buttons: [
        {
          text: i18n.t('ok_text'),
          role: 'cancel',
          handler: () => {
            dismissGenericAlert();
          },
        },
      ],
    },
  };
};
const setGenericAlertWithButtons = (
  header: string,
  message: string,
  buttons: AlertButton[],
): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_GENERIC_ALERT,
    genericAlert: {
      header,
      message,
      isOpen: true,
      buttons: buttons,
    },
  };
};

const dismissGenericAlert = (): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_GENERIC_ALERT,
    genericAlert: {
      header: '',
      message: '',
      isOpen: false,
      buttons: [],
    },
  };
};
const getNotifications = () => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(notificationRequest());
    try {
      const notifications: Notification[] =
        await AlertMailBoxServices.getNotifications(
          state().app.localSettings.localLanguage,
          state().news.sessionId,
        );

      if (!isEmpty(notifications)) {
        // add condition to show passenger invitations as
        // notices in home page ==> n.link === 'passengerInvitation' && !n.read
        const notice = notifications.filter(
          n =>
            ((n.link === 'passengerInvitation' && !n.read) || n.notice) &&
            dayjs(n.dateExpiry).tz().isAfter(dayjs().tz()),
        );
        const notificationList = notifications.filter(
          n => dayjs(n.dateExpiry).isAfter(dayjs()) && n.visible,
        );
        const dateStored =
          localStorage.getItem('notificationDate') ?? dayjs('2000-01-01');
        const notificationsHome = notifications.filter(
          n =>
            !n.read &&
            dayjs(n.createData).isAfter(dateStored) &&
            dayjs(n.dateExpiry).isAfter(dayjs()) &&
            n.visible,
        );
        dispatch(setNotifications(notificationList));
        dispatch(setNotices(notice));
        dispatch(setNotificationsHome(notificationsHome));
      }
    } catch (e) {
      dispatch(fetchNotificationsFailure(e.message));
    }
  };
};

const notificationRead = (id: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(notificationRequest());
    try {
      await AlertMailBoxServices.readNotification(id);

      dispatch(setNotificationRead(id));
    } catch (e) {
      dispatch(fetchNotificationsFailure(e.message));
    }
  };
};

const notificationDelete = (id: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(notificationRequest());
    try {
      await AlertMailBoxServices.deleteNotification(id);
      dispatch(delNotification(id));
    } catch (e) {
      dispatch(fetchNotificationsFailure(e.message));
    }
  };
};

const notificationReadAll = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(notificationRequest());

    AlertMailBoxServices.readAllNotification()
      .then(() => {
        dispatch(setAllNotificationsRead());
      })
      .catch(e => {
        dispatch(fetchNotificationsFailure(e.message));
      });
  };
};

const addCardNotification = (cardNotification: Notification) => {
  return async (dispatch: any, state): Promise<any> => {
    dispatch(notificationRequest());
    try {
      const notifications: Notification[] =
        await AlertMailBoxServices.getNotifications(
          state().app.localSettings.localLanguage,
          state().news.sessionId,
        );
      !state().user.card.registered && notifications.push(cardNotification);
      if (!isEmpty(notifications)) {
        const notice = notifications.filter(
          n => n.notice && dayjs(n.dateExpiry).tz().isAfter(dayjs().tz()),
        );
        const notificationList = notifications.filter(
          n => dayjs(n.dateExpiry).isAfter(dayjs()) && n.visible,
        );
        const dateStored =
          localStorage.getItem('notificationDate') ?? dayjs('2000-01-01');
        const notificationsHome = notifications.filter(
          n =>
            !n.read &&
            dayjs(n.createData).isAfter(dateStored) &&
            dayjs(n.dateExpiry).isAfter(dayjs()) &&
            n.visible,
        );
        dispatch(setNotifications(notificationList));
        dispatch(setNotices(notice));
        dispatch(setNotificationsHome(notificationsHome));
      }
    } catch (e) {
      dispatch(fetchNotificationsFailure(e.message));
    }
  };
};
const setNotificationsUnauthorized = (title: string): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_NOTIFICATION_UNAUTHORIZED,
    title: title,
  };
};
const setNotificationDetail = (
  notification: NotificationDetail,
): NotificationsAction => {
  return {
    type: NotificationsTypesEnum.SET_NOTIFICATION_DETAIL,
    notificationDetail: notification,
  };
};
export const NotificationsActions = {
  setNotifications,
  notificationRequest,
  fetchNotificationsFailure,
  getNotifications,
  notificationRead,
  notificationDelete,
  notificationReadAll,
  addCardNotification,
  setNotificationsUnauthorized,
  setNoticeToast,
  resetNoticeToast,
  subscribeFirebase,
  setNotificationDetail,
  setGenericErrorToast,
  setGenericInfoToast,
  setGenericSuccessToast,
  setGenericWarningToast,
  dismissGenericToast,
  setGenericAlert,
  setGenericAlertWithButtons,
  dismissGenericAlert,
};
