export enum WorkstationTypesEnum {
  REQUEST = 'FETCH_WORKSTATION_REQUEST',
  SET_WORKSTATION = 'SET_WORKSTATION',
  SET_MAP_FLOOR = 'SET_MAP_FLOOR',
  SET_PRESELECTED_WORKSTATIONS = 'SET_PRESELECTED_WORKSTATIONS',
  FAILURE_WORKSTATION = 'FAILURE_WORKSTATION',
  SET_WORKSTATIONS = 'SET_WORKSTATIONS',
  SET_FAV_WORKSTATIONS = 'SET_FAV_WORKSTATIONS',
  DELETE_FAV_WORKSTATION = 'DELETE_FAV_WORKSTATION',
  ADD_FAV_WORKSTATION = 'ADD_FAV_WORKSTATION',
  RESET_ERROR = 'RESET_ERROR',
  RESET_WORKSTATIONS = 'RESET_WORKSTATIONS',
  SET_FLOORS = 'SET_FLOORS',
  SET_BUILDINGS = 'SET_BUILDINGS',
  SET_RAFFLE_STATE = 'SET_RAFFLE_STATE',
  SET_MESSAGE = 'SET_MESSAGE',
  RESET_MESSAGE = 'RESET_MESSAGE',
  SET_VISUAL_CONFIG = 'SET_VISUAL_CONFIG',
  RESET_LOADING = 'RESET_LOADING',
  SET_RESERVABLE_DAYS = 'SET_RESERVABLE_DAYS',
  SET_WORKSTATION_CONFIGURATION = 'SET_WORKSTATION_CONFIGURATION',
  SET_ISLOADING_WORKSTATIONS = 'SET_ISLOADING_WORKSTATIONS',
  RESET_ISLOADING_WORKSTATIONS = 'RESET_ISLOADING_WORKSTATIONS',
}
