import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonButton,
} from '@ionic/react';
import { TopBar } from '@acciona/ui-ionic-kit';
import dayjs from 'dayjs';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { DetailList } from '../../../../components/Base/DetailList';
import styles from './styles.module.scss';
import { getVehicleIcon } from '../../../../utils/vehiclesAndParking';

const VehicleByRequest: React.FC = () => {
  const { t } = useTranslation();
  const stateRequestDetail: any = useLocation().state || {};

  const history = useHistory();

  const [vehicles, setVehicles] = useState([]);

  const { parkingRaffles } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const handleClose = () => {
    history.replace('/spaces/parking/requestdetail', stateRequestDetail);
  };

  useEffect(() => {
    const vehicleArray = [];
    parkingRaffles?.forEach(item => {
      if (!vehicleArray.find(i => i.plate === item.vehicle.plate)) {
        item.dates.forEach(date => {
          vehicleArray.push(date);
        });
      }
    });
    setVehicles(vehicleArray);
  }, []);

  return (
    <IonPage>
      <div className={styles.headerContainer}>
        <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
          <TopBar
            primaryActions={
              <IonButton
                onClick={handleClose}
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            }
            title={t('associated_vehicles', {
              multiple: 's',
            })}
          />
        </IonHeader>
        <div className={`${styles.narrowLine} ${styles.linePosition}`}></div>
      </div>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {vehicles?.map(item => {
              return (
                <DetailList key={dayjs(item.from).format('DD MMM')}>
                  <DetailListItem
                    key={dayjs(item.from).format('DD MMM')}
                    lines="full"
                    title={dayjs(item.from).format('DD MMM')}
                  />
                  <DetailListItem
                    lines="none"
                    startIcon={getVehicleIcon(item.vehicle.type)}
                    startIconColor="primary"
                    title={`${item.vehicle.brand} ${item.vehicle.model}`}
                    description={item.vehicle.plate}
                  />
                </DetailList>
              );
            })}
          </div>
        </div>
      </IonContent>
      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default VehicleByRequest;
