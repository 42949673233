import { IonText } from '@ionic/react';
import { Icon, Item } from '@acciona/ui-ionic-kit';
import { Props } from './types';
import styles from './styles.module.scss';

export const DetailSearchPerson: React.FC<Props> = props => {
  const { className, onClick, description, footnote } = props;

  return (
    <Item
      lines="inset"
      className={`${styles.item} ${className}`}
      mode="ios"
      type="button"
      onClick={onClick}
    >
      <Icon slot="start" className={`icon icon-client ${styles.startIcon}`} />

      <div className={`ion-text-wrap ${styles.columnList}`}>
        <IonText
          className={`${styles.description} ion-text-wrap ${styles.subhead} ${styles.block}`}
        >
          {description}
        </IonText>
        <IonText
          className={`${styles.footnote} ion-text-wrap ${styles.color_dark} ${styles.block}`}
        >
          {footnote}
        </IonText>
      </div>
    </Item>
  );
};
