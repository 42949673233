import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { isEmpty } from '../../../utils/functions';
import { ListCarousel } from '../ListCarousel';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { NewsImg } from '../NewsImg';
import 'swiper/css';
import 'swiper/css/pagination';
import PlayImage from '../../../assets/images/play-button.svg';
import { useHistory } from 'react-router';
import { NewsActions } from '../../../_redux/actions';
import { useDispatch } from 'react-redux';
import CategoryChip from '../../../pages/Dashboard/News/components/CategoryChip';

export type Props = {
  data?: any;
  slideClass?: string;
  imageClass?: string;
  skeletonClass?: string;
  fullImg?: boolean;
  overlayClass?: string;
  onClick?: (e?: any) => void;
  dashboardClass?: boolean;
};
export const NewsCarrousel: React.FC<Props> = props => {
  const { data, dashboardClass, ...res } = props;
  const [slides, setSlides] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const playVideo = id => {
    dispatch(NewsActions.selectNews(id, history.location.pathname, true));
  };

  useEffect(() => {
    if (data && !_.isEmpty(data)) {
      setSlides(
        data.map((d, index) => {
          return (
            <SwiperSlide key={index} className={res.slideClass}>
              <div
                className={`${styles.gridWeb} ${
                  res.fullImg && styles.wrapSlide
                }`}
              >
                {!isEmpty(d.category) && (
                  <div
                    className={
                      dashboardClass
                        ? styles.responsiveChipHeaderDashboard
                        : styles.responsiveChipHeader
                    }
                  >
                    <CategoryChip
                      category={d?.category}
                      category_id={
                        d?.extended_props?.category_id || d?.category_id
                      }
                    />
                  </div>
                )}
                <div className={styles.imageContainer}>
                  {!isEmpty(d.videoUrl) && (
                    <button
                      className={styles.videoButton}
                      onClick={() => playVideo(d.nav_id)}
                    >
                      <img src={PlayImage} alt="" />
                    </button>
                  )}

                  {d.enableOverlay || !isEmpty(d.videoUrl) ? (
                    <div className={props.overlayClass} />
                  ) : null}

                  <NewsImg
                    image_id={d.imageURL}
                    className={res.imageClass}
                    classSkeleton={res.skeletonClass}
                  />
                </div>
                <ListCarousel
                  data={{ ...d, imageURL: '' }}
                  mode="ios"
                  onClick={() => res.onClick(d.nav_id)}
                  dashboardClass={props.dashboardClass}
                />
              </div>
            </SwiperSlide>
          );
        }),
      );
    }

    return;
  }, [data]);

  return (
    <>
      {slides && !_.isEmpty(slides) && (
        <Swiper
          pagination={true}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 5500,
            disableOnInteraction: true,
          }}
          className={styles.swipperContent}
        >
          {slides}
        </Swiper>
      )}
    </>
  );
};
