import { Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18nextConf';
import styles from './styles.module.scss';
import { useContext, useState } from 'react';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { useDispatch } from 'react-redux';
import { appActions, NewsActions } from '../../../../_redux/actions';
import { Locale } from '../../../../_redux/models/app.model';
import FirebaseAnalyticsContext from '../../../../context/FirebaseAnalyticsContext';

const Language: React.FC = () => {
  const { setProperty } = useContext(FirebaseAnalyticsContext);
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const changeLanguage = async (language: string) => {
    i18n.changeLanguage(language);
    dispatch(appActions.setLanguage(language as Locale));
    setProperty('language', language);
    setShowToast(true);
    dispatch(NewsActions.resetNewsData());
    dispatch(NewsActions.getSessionId());
    dispatch(NewsActions.getDashborardCarrouselNews());
    dispatch(NewsActions.getDashborardOtherNews());
  };

  const handleDismissToast = () => {
    setShowToast(false);
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/home"
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_language')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              <DetailListItem
                lines="inset"
                titleLight={t('lbl_language')}
                className={styles.languageItem}
                endTextSelect={
                  <IonSelect
                    interfaceOptions={{
                      header: t('lbl_language'),

                      translucent: true,
                    }}
                    mode="ios"
                    okText={t('ok_text')}
                    cancelText={t('cancel_text')}
                    value={
                      i18n.language === 'es'
                        ? 'es'
                        : i18n.language === 'en'
                        ? 'en'
                        : i18n.language === 'pt'
                        ? 'pt'
                        : 'es'
                    }
                    onIonChange={e => changeLanguage(e.detail.value)}
                  >
                    <IonSelectOption value="es">
                      {t('lbl_spanish_language')}
                    </IonSelectOption>
                    <IonSelectOption value="en">
                      {t('lbl_english_language')}
                    </IonSelectOption>
                    <IonSelectOption value="pt">
                      {t('lbl_portuguese_language')}
                    </IonSelectOption>
                  </IonSelect>
                }
              />
            </DetailList>
            <Toast
              isOpen={showToast}
              onDidDismiss={() => handleDismissToast()}
              message={t('lbl_language_change')}
              position="bottom"
              type="success"
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Language;
