import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { IonFabButton, IonIcon, isPlatform } from '@ionic/react';
import {
  addFullscreenControl,
  disableClickEventPropagation,
  togglePseudoFullscreen,
} from '../../utils/map';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import styles from './styles.module.scss';

type Props = {
  cssClass?: string;
  pseudoFullscreen?: boolean;
};

export const FullscreenControl: React.FC<Props> = ({
  cssClass = '',
  pseudoFullscreen = true,
}: Props) => {
  const map = useMap();
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    return () => {
      isFullscreen && togglePseudoFullscreen(true); // Exit fullscreen mode
    };
  }, [isFullscreen]);

  useEffect(() => {
    if (
      !pseudoFullscreen &&
      !document.querySelector('.leaflet-control-fullscreen-button')
    ) {
      addFullscreenControl(map, {
        forcePseudoFullscreen: isPlatform('ios'),
      });
    }
  }, [pseudoFullscreen]);

  const handleFullScreen = () => {
    disableClickEventPropagation('fullscreen-control');
    if (isPlatform('ios') || pseudoFullscreen) {
      togglePseudoFullscreen(isFullscreen);
      setIsFullscreen(!isFullscreen);
    } else {
      const button: HTMLElement | null = document.querySelector(
        '.leaflet-control-fullscreen-button',
      );
      button && button.click();
    }
  };

  return (
    <IonFabButton
      id="fullscreen-control"
      className={`${styles.fabBtn} ${cssClass}`}
      color="primary"
      onClick={handleFullScreen}
    >
      <IonIcon
        className={`icon ${isFullscreen ? 'icon-smallsize' : 'icon-fullsize'}`}
      />
    </IonFabButton>
  );
};
