import { useDispatch, useSelector } from 'react-redux';
import { ReservationStore } from '../_redux/models/reservation.model';
import { useEffect, useState } from 'react';
import {
  NotificationsActions,
  reservationActions,
  workstationActions,
} from '../_redux/actions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AppStore } from '../_redux/models/app.model';
import { reservationServices } from '../_redux/services';
import { getIsToday } from '../utils/dateTime';

export const useCanReservationDeskForToday = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const today = dayjs().tz();
  const [isDeskReservationForTodayActive, setIsDeskReservationForTodayActive] =
    useState(false);

  const {
    reservations,
    configurationDeskReservationForToday,
    reservedWorkstation,
  } = useSelector((store: ReservationStore) => store.reservation);
  const { isPresenceMandatory, homeOfficeReservationAvailable } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  useEffect(() => {
    setIsDeskReservationForTodayActive(
      configurationDeskReservationForToday?.onlyCompleteDay &&
        configurationDeskReservationForToday?.numberOpeningDays === 0,
    );
  }, [
    configurationDeskReservationForToday?.numberOpeningDays,
    configurationDeskReservationForToday?.onlyCompleteDay,
  ]);

  const canReservationDeskForToday = async () => {
    if (isUserInHomeOffice()) return false;
    if (isDeskReservationActiveToday()) return false;
    if (isUserInVacation()) return false;
    if (isNotDayReservable()) return false;
    // Lenel last validation
    if (!(await isUserInCampus())) return false;
    return true;
  };
  const isUserInHomeOffice = () => {
    if (homeOfficeReservationAvailable) return false;
    if (configurationDeskReservationForToday.homeOfficeToday) {
      dispatch(
        NotificationsActions.setGenericAlert(
          t('alert_home_office_title'),
          t('alert_home_office_message'),
        ),
      );
      return true;
    }
    return false;
  };

  const isDeskReservationActiveToday = () => {
    const reservation = reservations.find(
      r =>
        r.useType === 'Desk' &&
        r.dates[0].reservationDate === today.format('YYYY-MM-DD'),
    );

    const reservationForTodayInRedux = reservedWorkstation?.reservations.find(
      reservation => getIsToday(reservation?.reservationDate),
    )
      ? reservedWorkstation
      : undefined;

    if (!reservation && !reservationForTodayInRedux) {
      return false;
    }

    dispatch(
      NotificationsActions.setGenericAlertWithButtons(
        t('alert_desk_active_title'),
        t('alert_desk_active_message'),
        [
          {
            text: t('cancel_text'),
            role: 'cancel',
            handler: () => {},
          },
          {
            text: t('canteenReservActiveModal.btn_routerToReserv'),
            role: 'confirm',
            handler: async () => {
              dispatch(
                reservationActions.getReservationWorkstation(
                  reservationForTodayInRedux?.spaceId ?? reservation.id,
                ),
              );
            },
          },
        ],
      ),
    );
    return true;
  };

  const isUserInVacation = () => {
    if (configurationDeskReservationForToday.publicHolidayToday) {
      dispatch(
        NotificationsActions.setGenericAlert(
          t('alert_vacation_title'),
          t('alert_vacation_message'),
        ),
      );
      return true;
    }
    return false;
  };

  const isNotDayReservable = () => {
    if (
      configurationDeskReservationForToday.deskDisabledDays.includes(
        today.weekday(),
      )
    ) {
      dispatch(
        NotificationsActions.setGenericAlert(
          t('alert_not_day_reservable_title'),
          t('alert_not_day_reservable_message'),
        ),
      );
      return true;
    }
    return false;
  };

  const isUserInCampus = async () => {
    if (!isPresenceMandatory) return true;
    try {
      dispatch(workstationActions.setLoadingWorkstations());
      const isUserInCampuesState =
        await reservationServices.getIfUserIsInCampus();
      dispatch(reservationActions.setIsUserInCampus(isUserInCampuesState));
      if (!isUserInCampuesState) {
        dispatch(workstationActions.resetLoadingWorkstations());
        dispatch(
          NotificationsActions.setGenericAlert(
            t('alert_user_not_in_campus'),
            t('alert_user_not_in_campus_message'),
          ),
        );
        return false;
      }
      return true;
    } catch (e) {
      dispatch(workstationActions.resetLoadingWorkstations());
      dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
      return false;
    }
  };
  return { canReservationDeskForToday, isDeskReservationForTodayActive };
};
