import { useCallback } from 'react';
import { Props } from './types';
import { Button, Icon } from '@acciona/ui-ionic-kit';
import { IonLabel, IonText } from '@ionic/react';
import { useHistory, useLocation } from 'react-router-dom';
import { menuController } from '@ionic/core/components';
import Can from '../../Can/Can';
import {
  appActions,
  userActions,
  workstationActions,
} from '../../../_redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../_redux/models/app.model';
import { MoreStore } from '../../../pages/Dashboard/More/_redux/models';
import { isMoreButtonVisible } from '../../../pages/Dashboard/More/utils/helpers';
import { UserStore } from '../../../_redux/models/user.model';
import styles from './styles.module.scss';

const NavBarDesktop: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { offlineNetwork, offlineServices } = useSelector(
    (state: AppStore) => state.app,
  );

  const { permissions } = useSelector((store: UserStore) => store.user.user);
  const { config: moreMenuConfig } = useSelector(
    (store: MoreStore) => store.more,
  );

  const toggleMenu = useCallback(async () => {
    const offlineMode = offlineNetwork || offlineServices;
    if (offlineMode) {
      dispatch(appActions.setOfflineToastIsOpen(true));
    } else {
      if (!props.isMenuClose) {
        dispatch(userActions.getUserPermissions());
        dispatch(appActions.getAppSettings());
        dispatch(userActions.getReservationPolicies());
        dispatch(workstationActions.getWsConfigurations());
      }
      props.onChangeState(!props.isMenuClose);
      await menuController.toggle();
    }
  }, [offlineNetwork, offlineServices]);

  const handleOnClick = useCallback(
    (href: string, accessAllowedInOfflineMode: boolean) => {
      menuController.close();
      const offlineMode = offlineNetwork || offlineServices;
      if (offlineMode && !accessAllowedInOfflineMode) {
        dispatch(appActions.setOfflineToastIsOpen(true));
      } else {
        history.replace(href);
      }
    },
    [offlineNetwork, offlineServices],
  );

  return (
    <div id="nav-bar-desktop" className={styles.menuContain}>
      <div className={styles.containBtn}>
        <div className={styles.containHeaderBtns}>
          {props.navTabsHeader
            .filter(navTab => {
              if (navTab.href === '/dashboard/more') {
                return isMoreButtonVisible(moreMenuConfig, permissions);
              }
              return navTab.show;
            })
            .map(navTab => (
              <Can
                key={navTab.id}
                functionality={
                  offlineNetwork || offlineServices
                    ? 'offline'
                    : navTab.permissionCode
                }
              >
                <Button
                  className={styles.btnheader}
                  color="tertiary"
                  disabled={navTab.disabled}
                  onClick={() =>
                    handleOnClick(
                      navTab.href,
                      navTab.accessAllowedInOfflineMode,
                    )
                  }
                >
                  <div
                    className={`
                    ${styles.bntsty} ${
                      location.pathname == navTab.href
                        ? styles.btn_menu_selected
                        : styles.bntsty
                    }
                  `}
                  >
                    <Icon
                      className={`${navTab.icon} ${styles.iconSty}`}
                      icon={navTab.icon}
                    />
                    <IonText className={styles.typography}>
                      {navTab.text}
                    </IonText>
                  </div>
                </Button>
              </Can>
            ))}
        </div>
        <div className={styles.containFooterBtn}>
          {props.navTabsFooter
            .filter(tab => tab.show)
            .map(navTab => (
              <Button
                key={navTab.id}
                onClick={() => toggleMenu()}
                className={styles.btnfooter}
                color="secondary"
              >
                <Icon
                  color="tertiary"
                  className={`icon ${
                    props.isMenuClose == true ? 'icon-minus' : 'icon-plus'
                  } ${styles.centralIcon}`}
                />
                <IonLabel className={styles.screenReadersOnly}>
                  {navTab.text}
                </IonLabel>
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
};
export default NavBarDesktop;
