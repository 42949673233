import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';

export const NeighborhoodContent = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      {data.neighborhood ? (
        <>
          <div className={styles.iconContainer}>
            <Icon
              color="primary"
              className={`icon icon-neighbourhood ${styles.iconInfo}`}
            />
          </div>
          {t('lbl_your_district')}
        </>
      ) : (
        `${t('lbl_district')}: ${data.title}`
      )}
    </>
  );
};
