import { ReservationWs } from '../../models/reservation.model';
import muleSoftInstance from '../../utils/httpCommon';

const getLastMinuteRamdonWs = async (datesSelected: string[]): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservations/createAnyDeskReservation`;
    const params = {
      dates: datesSelected,
    };
    const resp = await muleSoftInstance.post(path, params);

    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getLastMinuteSelectWs = async (
  datesSelected: string[],
  type: string,
  startHour: string,
  endHour: string,
  deskConfiguration: string,
  modify: boolean = false,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/lastMinute/select`;
    const params = {
      dates: datesSelected.join(),
      type: type,
      startHour: startHour,
      endHour: endHour,
      deskConfiguration: deskConfiguration,
      modify,
    };
    const resp = await muleSoftInstance.get(path, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const formatWorkstation = (wsReservation: ReservationWs) => {
  wsReservation.forEach(ws => {
    ws.endDate = ws.startDate;
    ws.name = '';
    ws.description = '';
  });
  return wsReservation;
};

const modifyWs = async (wsReservation: ReservationWs): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservations/modify`;
    wsReservation = formatWorkstation(wsReservation);
    const resp = await muleSoftInstance.put(url, wsReservation);
    return resp.data;
  } catch (err) {
    if (err.status === 409) {
      return [];
    } else if (err.status === 404) {
      return null;
    } else {
      throw new Error(err);
    }
  }
};

const reserveLastMinuteWs = async (
  wsReservation: ReservationWs,
): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservationUser/reservations/createMultipleDaysReservation`;
    wsReservation = formatWorkstation(wsReservation);
    const resp = await muleSoftInstance.post(url, wsReservation);
    return resp.data;
  } catch (err) {
    if (err.status === 409) {
      return [];
    } else if (err.status === 404) {
      return null;
    } else {
      throw new Error(err);
    }
  }
};

const getAvailableByFloorAndDates = async (
  datesSelected: string[],
  floorId: number,
  buildingId: number,
  type: string,
  startHour: string,
  endHour: string,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservation/availableSpaces`;

    const params = {
      dates: datesSelected.join(),
      floorId: floorId,
      buildingId: buildingId,
      type: type,
      startHour: startHour,
      endHour: endHour,
    };

    const resp = await muleSoftInstance.get(path, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getAvailableByFloorAndDatesPlanned = async (
  datesSelected: string[],
  floorId: number,
  buildingId: number,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservation/planned/availableSpaces`;

    const params = {
      dates: datesSelected.join(),
      floorId: floorId,
      buildingId: buildingId,
    };

    const resp = await muleSoftInstance.get(path, { params: params });

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const reservePlannedWs = async (
  dates: string[],
  spaces: number[],
): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservations/planned`;
    const params = {
      dates: dates,
      spaces: spaces,
    };
    const resp = await muleSoftInstance.post(url, params);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const setFavorite = async (spaceId: number): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/favorite/addFavoritePlace`;
    const params = {
      spaceId: spaceId,
    };
    const resp = await muleSoftInstance.post(url, params);

    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteFavorite = async (spaceId: number): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/favorite/deleteFavoritePlace`;
    const params = {
      spaceId: spaceId,
    };
    const resp = await muleSoftInstance.delete(url, { data: params });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getFavoriteWorkstations = async (): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/favoriteDesks`;

    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getFloors = async (buildingId: number): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/esite/habitat/v2/floors`;
    const params = {
      building: buildingId,
    };
    const resp = await muleSoftInstance.get(url, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getAvailableWsByFloor = async (floorId: number): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/floor`;
    const params = {
      floorId: floorId,
    };
    const resp = await muleSoftInstance.get(path, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const updateFavorites = async (workstationIds: number[]): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/favorite/addListFavoritesDesk`;
    const params = {
      spaceIds: workstationIds,
    };
    const resp = await muleSoftInstance.put(url, params);

    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getRaffle = async (): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/lottery/isActive`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getReservableDays = async (): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/desk/getReservableDays`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getWsConfigurations = async (): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/configuration/desk/reservations`;
    const resp = await muleSoftInstance.get(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const workstationServices = {
  reserveLastMinuteWs,
  modifyWs,
  reservePlannedWs,
  getLastMinuteRamdonWs,
  getLastMinuteSelectWs,
  getAvailableByFloorAndDates,
  getFavoriteWorkstations,
  getAvailableByFloorAndDatesPlanned,
  setFavorite,
  deleteFavorite,
  getFloors,
  getAvailableWsByFloor,
  updateFavorites,
  getRaffle,
  getReservableDays,
  getWsConfigurations,
};
