import styles from './styles.module.scss';
import { Props } from './types';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  Chip,
  SocialToolBarItem,
} from '@acciona/ui-ionic-kit';
import { IonGrid, IonImg, IonItem, IonRow } from '@ionic/react';
import PlayImage from '../../../assets/images/play-button.svg';
import { isEmpty } from '../../../utils/functions';
import VideoPlayer from '../VideoPlayer';
import { useVideoPlayer } from '../../../hooks/useVideoPlayer';
export const ListCarousel: React.FC<Props> = props => {
  const { data, headerTranslucent, ...res } = props;
  const { showVideo, playVideo, closeVideo } = useVideoPlayer(data.videoUrl);

  const getActionItem = (actionItem, index) => {
    switch (actionItem.type) {
      case 'icon':
        return (
          <SocialToolBarItem
            key={'Carrousel' + index}
            icon={actionItem.icon}
            onClick={evt => {
              evt.stopPropagation();
              return actionItem.onClick();
            }}
            className={`${styles.cardIcon} ${styles[actionItem.slot]}`}
            text={actionItem.text}
            slot={actionItem.slot}
          />
        );

      default:
        return (
          <div
            key={'Carrousel' + index}
            className={styles.cardLabel}
            slot={actionItem.slot}
          >
            {actionItem.icon}
          </div>
        );
    }
  };

  const getActionIcons = actions => {
    return actions
      .filter(action => action.visible)
      .map((item, index) => getActionItem(item, index));
  };

  return (
    <Card className={styles.cardWrapper} {...res}>
      <IonGrid className={`${styles.noPadding}`}>
        {data.imageURL && (
          <div className={styles.centerImgButton} onClick={res.onClick}>
            <span className={styles.chipPosition}>
              {data.label && <Chip type="soft">{data.label}</Chip>}
              {/* TODO: waiting UX validation to remove */}
              {/* {!isEmpty(data.videoUrl) && (
              <Chip type="info" className="icon icon-play-XXS" />
            )} */}
            </span>
            {!isEmpty(data.videoUrl) && (
              <button className={styles.videoButton} onClick={playVideo}>
                <img src={PlayImage} alt="" />
              </button>
            )}
            <VideoPlayer
              videoUrl={data.videoUrl}
              onClose={closeVideo}
              isOpen={showVideo}
            />
            {data.enableOverlay || !isEmpty(data.videoUrl) ? (
              <div className={styles.imgOverlay} />
            ) : null}
            {data.imageURL && <IonImg src={data.imageURL} />}
          </div>
        )}
        <CardHeader
          className={styles.cardHeader}
          translucent={headerTranslucent}
        >
          <CardTitle
            className={`${styles.cardTitle} ${
              props.dashboardClass ? styles.sizeDashboard : ''
            }`}
          >
            {data.title}
          </CardTitle>
        </CardHeader>
        <CardContent mode={props.mode} className={styles.cardDescription}>
          {data.description}
        </CardContent>
      </IonGrid>
      <IonGrid className={`${styles.noPaddingClickable}`}>
        <IonRow className={styles.rowActions}>
          <IonItem className={styles.cardActions}>
            {data.actions && getActionIcons(data.actions)}
          </IonItem>
        </IonRow>
      </IonGrid>
    </Card>
  );
};
