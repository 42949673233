import styles from './styles.module.scss';
export const classes = {
  default: `${styles.default}`,
  News: `${styles.default}`,
  Desk: `${styles.workstation}`,
  Parking: `${styles.parking}`,
  Room: `${styles.workroom}`,
  Card: `${styles.default}`,
  Corporate: `${styles.default}`,
  Communication: `${styles.communication}`,
  Features: `${styles.notice}`,
  Security: `${styles.notice}`,
  MedicalServices: `${styles.notice}`,
};
