import { Toast } from '@acciona/ui-ionic-kit';
import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useExternalLink } from './../../hooks/useExternalLink';
import styles from './styles.module.scss';

/**
 * ExternalLink Component
 *
 * Allows opening a link in a browser within the app. It is also useful for opening PDF documents.
 *
 * @param {string} link - The link to be opened in the browser.
 *
 * @example
 * <ExternalLink link="http://www.africau.edu/images/default/sample.pdf">
 *    Test Link
 * </ExternalLink>
 */
const ExternalLink: React.FC<{ link: string }> = ({ link, children }) => {
  const { downloading, error, openLink } = useExternalLink();
  const { t } = useTranslation();

  return (
    <>
      <span className={styles.displayWrapper} onClick={() => openLink(link)}>
        {children}
      </span>
      <Toast isOpen={!!error} message={error} position="bottom" type="error" />
      <IonLoading
        isOpen={downloading}
        message={t('msg_loading')}
        duration={2000}
      />
    </>
  );
};

export default ExternalLink;
