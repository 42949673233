import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from '../reducers';

export const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    collapsed: true,
  });
  middlewares.push(logger);
}

const store = createStore(
  reducers,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middlewares)),
);

export default store;
