import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonButton,
  IonIcon,
  isPlatform,
} from '@ionic/react';
import { TopBar, Icon, Button } from '@acciona/ui-ionic-kit';
import { UserStore } from '../../../../_redux/models/user.model';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { MapReservation } from '../../../../components/MapReservation/MapReservation';
import styles from './styles.module.scss';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { App } from '@capacitor/app';

const LockerDetail: React.FC = () => {
  const { t } = useTranslation();

  const { districtName } = useSelector((state: UserStore) => state.user.user);
  const { reservedLocker, reservations } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const { history, isFromConfirmation, handleClose } = useReservationsHistory();

  const redirectToSalto = async () => {
    let appInstalled = false;
    const handleAppStateChange = state => {
      if (!state.isActive) {
        appInstalled = true;
      }
    };
    const subscription = await App.addListener(
      'appStateChange',
      handleAppStateChange,
    );
    window.location.href = reservedLocker.deepLink;
    setTimeout(() => {
      subscription.remove();
      if (!appInstalled) {
        history.push('/spaces/locker/downloadApp');
      }
    }, 1000);
  };
  const getLockerName = () => {
    return reservations.find(r => r.id === reservedLocker?.spaceId)?.name ?? '';
  };
  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.toolbarGrid} ion-no-border`}
      >
        <TopBar
          primaryActions={
            isFromConfirmation ? (
              <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
            ) : (
              <IonButton
                className={styles.button}
                onClick={() => history.push('/dashboard/reservations')}
              >
                <IonIcon
                  slot="icon-only"
                  className={`${styles.icon} icon icon-chevron-left`}
                />
              </IonButton>
            )
          }
          title={t('detail_reservation')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <DetailCard
          id="map-detail-card"
          name={t('locker')}
          subName={getLockerName()}
          type={'locker'}
          icon="icon icon-lock"
        ></DetailCard>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <DetailList>
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-campus"
                startIconColor="primary"
                title={`${t('building_filter_workroom')} ${
                  reservedLocker.location?.buildingName
                } , ${reservedLocker.location?.floorName}`}
                description={
                  districtName === reservedLocker.location?.district ? (
                    <div className={styles.locatdivs}>
                      <Icon
                        color="primary"
                        className={`icon icon-neighbourhood ${styles.iconNeighborhood} icon-20`}
                      />
                      {t('lbl_your_district')}
                    </div>
                  ) : (
                    `${t('lbl_district')}: ${reservedLocker.location?.district}`
                  )
                }
                readonly
              />
            </DetailList>
            <DetailListItem
              className={styles.itemList}
              startIcon="icon icon-explore"
              startIconColor="primary"
              title={t('lbl_head_office')}
              description={reservedLocker?.descriptionSede}
              readonly
            />
            {reservedLocker.mapImage && (
              <>
                <div className={styles.separatorList} />
                <MapReservation
                  x={reservedLocker.x}
                  y={reservedLocker.y}
                  mapImage={reservedLocker.mapImage}
                />
              </>
            )}
          </div>
        </div>
      </IonContent>
      {isPlatform('capacitor') && reservedLocker?.deepLink && (
        <IonFooter>
          <div className={styles.btnGroupWeb}>
            <div>
              <Button onClick={redirectToSalto}>
                <Icon className="icon icon-lock" />
                <span className={styles.deeplink}>{t('goToSalto')}</span>
              </Button>
            </div>
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default LockerDetail;
