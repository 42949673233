import { useState } from 'react';
import { IonPage, IonSplitPane } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import _ from 'lodash';
import { vehicleActions } from '../../_redux/actions/vehicles.actions';
import ActionsMenuDesktop from '../Base/ActionsMenuDesktop/ActionsMenuDesktop';
import NavBarDesktop from '../Base/NavBarDesktop';
import { UserStore } from '../../_redux/models/user.model';
import { AuthStore } from '../../_redux/models/auth.model';
import { getNavTabsDesktopConfig, routesWithoutSplitPane } from './helpers';
import { RESERVATION_PERMISSIONS } from '../../utils/constants';
import styles from './styles.module.scss';

const SplitPane: React.FC = ({ children }) => {
  const location = useLocation();
  const [isMenuClose, setIsMenuClose] = useState(false);
  const dispatch = useDispatch();
  const { permissionAllHeadOffices, userIsRegisteredInDatabase } = useSelector(
    (state: UserStore) => state.user.user,
  );
  const isAuth = useSelector((state: AuthStore) => state.auth.isAuth);

  const hasReservationPermission = _.some(
    permissionAllHeadOffices,
    obj => _.intersection(obj.permissions, RESERVATION_PERMISSIONS).length > 0,
  );

  const onToggleMenuHandler = (isOpen: boolean) => {
    setIsMenuClose(isOpen);
    isOpen && dispatch(vehicleActions.getListVehicle());
  };

  const { navTabsHeader, navTabsFooter } = getNavTabsDesktopConfig(
    hasReservationPermission,
  );

  return (
    <IonSplitPane
      className={styles.centervert}
      contentId="main"
      when="(min-width: 1024px)"
      disabled={
        userIsRegisteredInDatabase === false ||
        routesWithoutSplitPane.some(route => location.pathname.includes(route))
      }
    >
      <NavBarDesktop
        isMenuClose={isMenuClose}
        onChangeState={onToggleMenuHandler}
        navTabsHeader={navTabsHeader}
        navTabsFooter={navTabsFooter}
      />
      <IonPage id="main">
        {children}
        {isAuth && <ActionsMenuDesktop onChangeState={onToggleMenuHandler} />}
      </IonPage>
    </IonSplitPane>
  );
};

export default SplitPane;
