import { ParkingAction, ParkingState } from '../models/parking.model';
import { ParkingTypesEnum } from '../types/parking.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  parking: null,
  parkings: [],
  raffle: { startHour: null, inProgress: false, weekDay: null },
  configurations: null,
  reservableDays: [],
  plannedReservableDays: [],
  hasReservableDays: false,
};

export const parkingReducer = (
  state: ParkingState = initialState,
  action: ParkingAction,
): ParkingState => {
  switch (action.type) {
    case ParkingTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case ParkingTypesEnum.SET_PARKING:
      return {
        ...state,
        parking: action.parking,
        isLoading: false,
      };
    case ParkingTypesEnum.FAILURE_PARKING:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case ParkingTypesEnum.SET_PARKINGS:
      return {
        ...state,
        parkings: action.parkings,
        isLoading: false,
      };
    case ParkingTypesEnum.SET_RAFFLE_STATE:
      return {
        ...state,
        raffle: {
          ...action.raffle,
          weekDay:
            action.raffle.weekDay !== null ? action.raffle.weekDay + 1 : null,
        }, // Add one day to correct back numering
        isLoading: false,
      };
    case ParkingTypesEnum.SET_PARKING_CONFIGURATION:
      return {
        ...state,
        configurations: action.configurations,
        isLoading: false,
      };
    case ParkingTypesEnum.SET_PK_RESERVABLE_DAYS:
      return {
        ...state,
        reservableDays: action.reservableDays,
        plannedReservableDays: action.plannedReservableDays,
        hasReservableDays: action.hasReservableDays,
        isLoading: false,
      };
    case ParkingTypesEnum.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case ParkingTypesEnum.MSG_PARKING:
      return {
        ...state,
        error: null,
        msg: action.msg,
      };
    case ParkingTypesEnum.RESET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ParkingTypesEnum.RESET_PARKINGS:
      return initialState;
    default:
      return state;
  }
};
