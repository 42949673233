import {
  ShuttlesAction,
  ShuttlesState,
} from '../models/shuttlesAndRoutes.model';
import { shuttlesAndRoutesTypesEnum } from '../types';

const initialState = {
  error: null,
  isLoading: false,
  shuttles: [],
};

export const shuttlesAndRoutesReducer = (
  state: ShuttlesState = initialState,
  action: ShuttlesAction,
): ShuttlesState => {
  switch (action.type) {
    case shuttlesAndRoutesTypesEnum.SHUTTLES_REQUEST:
    case shuttlesAndRoutesTypesEnum.ROUTES_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case shuttlesAndRoutesTypesEnum.SET_SHUTTLES:
    case shuttlesAndRoutesTypesEnum.SET_ROUTES:
      return {
        ...state,
        isLoading: false,
        error: null,
        shuttles: action.shuttles,
      };
    case shuttlesAndRoutesTypesEnum.FAILURE_SHUTTLES:
    case shuttlesAndRoutesTypesEnum.FAILURE_ROUTES:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
