import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../../../_redux/models/app.model';
import {
  Shuttle,
  ShuttlesAndRoutesStore,
} from '../../../../../../_redux/models/shuttlesAndRoutes.model';
import { useLocationState } from '../../../../../../hooks/useLocationState';
import { ShuttleDetail } from '../../../templates/ShuttleDetail/ShuttleDetail';

const ShuttleInformation: React.FC = () => {
  const state = useLocationState();
  const [shuttle, setShuttle] = useState<Shuttle>();
  const { shuttles, isLoading, error } = useSelector(
    (store: ShuttlesAndRoutesStore) => store.shuttles,
  );
  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );
  useEffect(() => {
    if (state?.id) {
      const shuttleData = shuttles.find(e => e.id === state.id);
      setShuttle(shuttleData);
    }
  }, [state]);

  const shuttleName =
    shuttle?.translations?.find(e => e.language == localLanguage)?.name ??
    shuttle?.name ??
    '';
  const shuttleSchedule =
    shuttle?.translations?.find(e => e.language == localLanguage)?.schedule ??
    shuttle?.schedule ??
    '';

  return (
    <ShuttleDetail
      shuttleName={shuttleName}
      shuttleSchedule={shuttleSchedule}
      error={error}
      isLoading={isLoading}
    />
  );
};

export default ShuttleInformation;
