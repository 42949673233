import muleSoftInstance from '../../utils/httpCommon';
import _ from 'lodash';

const search = async (searchWords: string): Promise<any> => {
  try {
    searchWords = searchWords.replaceAll(' ', '%20');
    const path = `${
      process.env.REACT_APP_API_HABITAT
    }/v1/new/search?searchWords=${encodeURI(searchWords)}`;

    const resp = await muleSoftInstance.get(path);

    const results = _.has(resp.data, 'news')
      ? {
          ...resp.data,
          news: resp.data['news'].map(news => {
            return { ...news, extended_props: { publishingPanels: null } };
          }),
        }
      : resp.data;
    return results;
  } catch (error) {
    throw new TypeError(error.message);
  }
};

const getSuggested = async (): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/search/suggested`;
    const resp = await muleSoftInstance.get(path);

    return resp.data;
  } catch (error) {
    throw new TypeError(error.message);
  }
};

const getSearchPersonDetail = async (id: number) => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/new/search/employee?employeeId=${id}`;
  try {
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteHistoryItem = async (historyItem: string): Promise<any> => {
  try {
    const path = `${
      process.env.REACT_APP_API_HABITAT
    }/v1/search/searchByUser?searchWords=${encodeURI(historyItem)}`;
    const resp = await muleSoftInstance.delete(path);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteHistory = async (): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/search/allSearchsByUser`;
    const resp = await muleSoftInstance.delete(path);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const searchServices = {
  search,
  getSuggested,
  getSearchPersonDetail,
  deleteHistoryItem,
  deleteHistory,
};
