import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocationState } from '../../../../../../hooks/useLocationState';
import { MedicalServicesStore } from '../../../../../../_redux/models/medicalServices.model';
import { ServiceDetail } from '../../../templates/ServiceDetail/ServiceDetail';

const MedicalServicesInformation: React.FC = () => {
  const { t } = useTranslation();
  const [medicalServicesInformation, setMedicalServicesInformation] =
    useState(null);
  const { medicalServices } = useSelector(
    (store: MedicalServicesStore) => store.medicalServices,
  );

  const state = useLocationState() as any;
  useEffect(() => {
    state &&
      setMedicalServicesInformation(
        medicalServices.find(e => e.id === state.id),
      );
  }, [state]);

  return (
    <ServiceDetail
      title={t('lbl_more_medicalServices')}
      info={medicalServicesInformation}
    />
  );
};

export default MedicalServicesInformation;
