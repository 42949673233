import {
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonNote,
  IonPage,
  IonText,
  isPlatform,
} from '@ionic/react';
import { Button, TopBar, Toast, Icon, Item, List } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkstationStore } from '../../../../_redux/models/workstation.model';
import {
  NotificationsActions,
  workstationActions,
} from '../../../../_redux/actions';
import { useHistory } from 'react-router';
import { isEmpty } from '../../../../utils/functions';
import BuildingModal from '../BuildingModal';
import FloorModal from '../FloorModal';
import { filterTypes } from './filterTypes';
import { UserStore } from '../../../../_redux/models/user.model';
import { BackButton } from '../../../../components/Base/BackButton';
import { SheetModal } from '../../../../components/Base/SheetModal';
import { AppStore } from '../../../../_redux/models/app.model';
import { MapSelector } from '../../../../components/MapSelector';
import { ReservationWs } from '../../../../_redux/models/reservation.model';
import { FilterChips } from './FilterChips';
let scrolling: any;
import styles from './styles.module.scss';
import { useCanReservationDeskForToday } from '../../../../hooks/useCanReservationDeskForToday';
import { useLocationState } from '../../../../hooks/useLocationState';
import { FilterType } from '../../../Dashboard/Home/types';

function getCheckedFilterId(filters: FilterType[]) {
  const checkedFilter = filters.find(filter => filter.checked === true);
  return checkedFilter ? checkedFilter.id : 'all';
}
const MapSelectorLastMinute: React.FC = () => {
  const { t } = useTranslation();
  const { isDeskReservationForTodayActive } = useCanReservationDeskForToday();
  const history = useHistory();
  const dispatch = useDispatch();
  const [workstations, setWr] = useState(null);
  const [mapWr, setMapWr] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedWs, setSelectedWs] = useState(null);
  const [msgError, setMsgError] = useState(null);
  const [maxLimit, setMaxLimit] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [isModalOpen, setOpenModal] = useState(false);
  const [isModalFloorOpen, setModalFloorOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [userDistrictError, setUserDistrictError] = useState(false);
  const [numFav, setNumFav] = useState(0);
  const [numHist, setNumHist] = useState(0);
  const [showChips, setShowChips] = useState(false);
  const [filterTypesState, setFilterTypesState] = useState(filterTypes);
  const [unselectWs, setUnselectWs] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [loadFromOtherFloor, setLoadFromOtherFloor] = useState(false);
  const [districtFocus, setDistrictFocus] = useState(false);
  const defaultFloor = 0;
  const staticLimit = 1;
  const wsData = useSelector((state: WorkstationStore) => state.workstation);
  const {
    district,
    districtName,
    building = '0',
    floor = '0',
  } = useSelector((state: UserStore) => state.user.user);

  const { lastMinuteReservationsConfiguration = 2, temporalVisitsDistrict } =
    useSelector((state: AppStore) => state.app.globalSettings);

  const state = useLocationState() as {
    building: number;
    floor: number;
    inFlow: boolean;
    selectedDates: string[];
    notSelectedDates: string[];
    preSelectedId?: number;
    type?: string;
    startHour?: string;
    endHour?: string;
    textHeader?: string;
    isWorkstationModification?: boolean;
    datesToId?: { [key: string]: string };
  };

  useEffect(() => {
    dispatch(workstationActions.getBuildings());
    state.inFlow
      ? dispatch(
          workstationActions.loadMapByBuilding(
            state.building,
            state.floor,
            state.selectedDates,
            state.type,
            state.startHour,
            state.endHour,
          ),
        )
      : dispatch(workstationActions.getFloors(state.building));

    return () => {
      setFirstLoad(true);
      dispatch(workstationActions.setBuildings([]));
      dispatch(workstationActions.setFloors(null));
    };
  }, [state]);

  useEffect(() => {
    !isEmpty(wsData.buildings) &&
      setSelectedBuilding(
        wsData.buildings.find(bl => bl.id === state.building),
      );
  }, [wsData.buildings]);

  useEffect(() => {
    if (!isEmpty(wsData.floors)) {
      firstLoad || districtFocus
        ? setSelectedFloor(wsData.floors.find(f => f.id == state.floor))
        : setSelectedFloor(wsData.floors[defaultFloor]);
      setFirstLoad(false);
      setDistrictFocus(false);
    }
  }, [wsData.floors]);

  useEffect(() => {
    let countSelFloor = 0;
    const favouritesLength =
      wsData?.workstations?.filter(ws => ws.favorite).length ?? 0;
    const historicLength =
      wsData?.workstations?.filter(ws => ws.isHistoric).length ?? 0;
    setNumFav(favouritesLength);
    setNumHist(historicLength);
    setShowChips(favouritesLength > 0 || historicLength > 0);

    wsData.workstations.forEach((wr: any) => {
      if (selectedWs?.spaceId === wr.spaceId) {
        wr.selected = true;
        countSelFloor += 1;
      } else {
        wr.selected = false;
      }
    });

    setWr(wsData.workstations);
    const selected = selectedWs ? 1 : 0;
    setMaxLimit(staticLimit - selected + countSelFloor);
    if (loadFromOtherFloor) {
      setLoadFromOtherFloor(false);
      if (
        !wsData.workstations.find(
          item => item.location.district === districtName,
        )
      ) {
        setUserDistrictError(true);
      }
    }
    // Update workstations on map with the last chip selected by the user
    const lastChekedFilterId = getCheckedFilterId(filterTypesState);
    handleSpaceFilterChange({ id: lastChekedFilterId });
  }, [wsData.workstations]);

  useEffect(() => {
    setMapWr(wsData.mapWorkstations);
  }, [wsData.mapWorkstations]);

  useEffect(() => {
    setMsgError(wsData.error);
    wsData.error && setShowToast(true);
  }, [wsData.error]);

  const handleConfirmSelection = () => {
    if (state.inFlow) {
      const selWs = workstations.find(
        item => item.spaceId === selectedWs.spaceId,
      );
      const updatedItems = wsData.preSelectedWs.map(el => {
        if (el.spaceId === state.preSelectedId) {
          selWs.availableDesk = el.availableDesk;
          selWs.reservations = el.reservations;
          return selWs;
        } else {
          return el;
        }
      });
      dispatch(workstationActions.setPreSelectedWs(updatedItems));
      history.push('/workstation/lastminute', {
        notSelectedDates: state.notSelectedDates,
        type: state.type,
        startHour: state.startHour,
        endHour: state.endHour,
        textHeader: state.textHeader,
        isWorkstationModification: state.isWorkstationModification,
        datesToId: state.datesToId,
      });
    } else {
      const wsReservation: any = state.selectedDates.map(date => {
        const parsedReservation: any = {
          spaces: [selectedWs.spaceId],
          startDate: date,
          type: state.type,
          startHour: state.startHour,
          endHour: state.endHour,
        };
        if (state.isWorkstationModification) {
          parsedReservation.oldReservationId = state.datesToId[date];
        }
        return parsedReservation;
      });

      const config = {
        wsReservation: wsReservation as ReservationWs,
        mapFlow: true,
        textHeader: state.textHeader,
        reservationDates: state.selectedDates,
        isModification: state.isWorkstationModification,
      };

      if (state.isWorkstationModification) {
        config.wsReservation = config.wsReservation.map(r => {
          return { ...r, oldReservationId: state.datesToId[r.startDate] };
        }) as ReservationWs;
        dispatch(
          NotificationsActions.setGenericAlertWithButtons(
            t('lbl_change_workstation'),
            t('text_change_workstation'),
            [
              {
                text: t('lbl_cancel'),
                role: 'dismiss',
                id: '0',
              },
              {
                text: t('lbl_affirmative'),
                id: '1',
                handler: () => {
                  dispatch(workstationActions.reserveLastMinuteWs(config));
                },
              },
            ],
          ),
        );
      } else {
        dispatch(workstationActions.reserveLastMinuteWs(config));
      }
    }
  };

  const handleSelectedWs = wr => {
    setUnselectWs(null);
    if (wr) {
      wr.selected
        ? setSelectedWs(workstations.find(item => item.spaceId === wr.spaceId))
        : setSelectedWs(null);
    } else {
      setMsgError(t('max_limited_allowed'));
      setShowToast(true);
    }
  };
  const handleSelectBuilding = buildingsSelected => {
    setSelectedBuilding(buildingsSelected);
    dispatch(
      workstationActions.loadMapByBuilding(
        buildingsSelected.id,
        null,
        state.selectedDates,
        state.type,
        state.startHour,
        state.endHour,
      ),
    );
  };

  const handleSelectFloor = floor => {
    dispatch(workstationActions.resetDataMap());
    setSelectedFloor(floor);
    dispatch(
      workstationActions.getAvailableByFloorAndDates(
        state.selectedDates,
        selectedBuilding.id,
        floor.id,
        state.type,
        state.startHour,
        state.endHour,
      ),
    );
    handleSpaceFilterChange({ id: 'all' });
  };

  const handleDismissToast = () => {
    setShowToast(false);
    dispatch(workstationActions.resetErrorWorkstation());
  };

  const handleSpaceFilterChange = ({ id }) => {
    let countSelFloor = 0;
    switch (id) {
      case 'favorite': {
        const favorite = wsData.workstations.filter(ws => ws.favorite);
        favorite.forEach((wr: any) => {
          if (selectedWs?.spaceId === wr.spaceId) {
            wr.selected = true;
            countSelFloor += 1;
          } else {
            wr.selected = false;
          }
        });
        setWr(favorite);
        break;
      }
      case 'historic': {
        const historic = wsData.workstations.filter(ws => ws.isHistoric);
        historic.forEach((wr: any) => {
          if (selectedWs?.spaceId === wr.spaceId) {
            wr.selected = true;
            countSelFloor += 1;
          } else {
            wr.selected = false;
          }
        });
        setWr(historic);
        break;
      }
      default: {
        wsData.workstations.forEach((wr: any) => {
          if (selectedWs?.spaceId === wr.spaceId) {
            wr.selected = true;
            countSelFloor += 1;
          } else {
            wr.selected = false;
          }
        });
        setWr(wsData.workstations);
      }
    }
    setMaxLimit(staticLimit - (selectedWs ? 1 : 0) + countSelFloor);
    setFilterTypesState(prev =>
      prev.map(filterT => ({
        ...filterT,
        checked: filterT.id === id,
      })),
    );
  };

  const handleOnScroll = () => {
    clearTimeout(scrolling);
    setIsScrolling(true);
    scrolling = setTimeout(() => {
      setIsScrolling(false);
    }, 500);
  };

  const handleOnUserDistrictFocus = isFocused => {
    if (!isFocused && floor != selectedFloor?.id) {
      setDistrictFocus(true);
      dispatch(
        workstationActions.loadMapByBuilding(
          parseInt(building),
          parseInt(floor),
          state.selectedDates,
          state.type,
          state.startHour,
          state.endHour,
        ),
      );
      setSelectedBuilding(
        wsData.buildings.find(bl => bl.id === parseInt(building)),
      );
      setLoadFromOtherFloor(true);
    } else {
      setUserDistrictError(!isFocused);
    }
  };

  const changeBuildingPermission =
    lastMinuteReservationsConfiguration === 4 || wsData.config === 'none';

  const changeFloorPermission =
    [3, 4].includes(lastMinuteReservationsConfiguration) ||
    wsData.config === 'none';

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-selector-header"
        className={`ion-no-border ${styles.headerSty} ${styles.toolbarGrid}`}
      >
        <div
          className={`${styles.toolbarSty} ${
            isScrolling ? styles.hiddenHeader : styles.showHeader
          }`}
        >
          <TopBar
            primaryActions={
              <BackButton
                onClick={() => {
                  if (state.isWorkstationModification) {
                    history.goBack();
                  } else {
                    history.push(
                      isDeskReservationForTodayActive
                        ? '/dashboard/actions'
                        : '/workstation/calendar',
                    );
                  }
                }}
              />
            }
            title={
              state.isWorkstationModification
                ? t('btn_modify_my_workstation')
                : t('btn_calendar_workstation_select')
            }
          />
        </div>
        <div className={styles.snackbarMapSelector}>
          <IonItem className={styles.headerConfig}>
            <IonLabel>{state.textHeader ? state.textHeader : ''}</IonLabel>
          </IonItem>
        </div>
        <div className={styles.buildingItem}>
          <IonItem
            mode="ios"
            lines="inset"
            onClick={() => setOpenModal(true)}
            type="button"
            disabled={!changeBuildingPermission}
          >
            <IonLabel>{t('building_filter_workroom')}</IonLabel>
            <IonNote
              className={styles.buidlingName}
              color={'primary'}
              slot="end"
            >
              {selectedBuilding && selectedBuilding.name}
            </IonNote>
            <Icon
              slot="end"
              className={`icon icon-chevron-right ${styles.iconItem}`}
            />
          </IonItem>
          {wsData.floors && (
            <IonItem
              mode="ios"
              lines={showChips ? 'inset' : 'none'}
              onClick={() => setModalFloorOpen(true)}
              type="button"
              disabled={!changeFloorPermission}
            >
              <IonLabel>{t('lbl_floor')}</IonLabel>
              <IonNote
                className={styles.buidlingName}
                color={'primary'}
                slot="end"
              >
                {selectedFloor && selectedFloor.name}
              </IonNote>
              <Icon
                slot="end"
                className={`icon icon-chevron-right ${styles.iconItem}`}
              />
            </IonItem>
          )}
          {showChips && (
            <FilterChips
              filterTypes={filterTypesState}
              allLength={wsData?.workstations?.length ?? 0}
              favoritesLength={numFav}
              historicLength={numHist}
              onChange={handleSpaceFilterChange}
            />
          )}
        </div>
      </IonHeader>
      <IonContent className={styles.contentSty} fullscreen>
        <IonLoading
          isOpen={wsData.isLoading}
          message={t('msg_loading')}
          duration={0}
        />

        {workstations && mapWr && (
          <div id="map-selector-map" className={styles.fixedSectionContent}>
            <div className={styles.mapContainer}>
              <MapSelector
                key={mapWr}
                onScroll={handleOnScroll}
                source={mapWr}
                dataWorkstations={workstations}
                maximum={maxLimit}
                district={districtName}
                onChange={handleSelectedWs}
                onUserDistrictFocus={handleOnUserDistrictFocus}
                unselectWs={unselectWs}
                selectedWs={selectedWs}
                setUnselectWs={setUnselectWs}
                myFloor={floor == selectedFloor?.id}
                paddingTop={showChips ? 240 : 180}
                building={selectedBuilding || { name: '', id: '' }}
                floor={selectedFloor || { name: '', id: '' }}
                isTemporalVisit={district === temporalVisitsDistrict}
              />
            </div>
          </div>
        )}
        <SheetModal
          id="map-selector-sheet-modal"
          lineScroll
          className={
            isPlatform('ios')
              ? `${styles.sheetModalPosition} ${styles.sheet}`
              : styles.sheetModalPosition
          }
        >
          <div className={styles.sheetModalContain}>
            <Item lines="none">
              <IonLabel>
                <IonText className={styles.callout}>
                  {t('selected_seat')}
                </IonText>
              </IonLabel>
              <IonLabel
                slot="end"
                className={`ion-text-wrap  ${styles.actionsLabel}`}
              >
                <IonText className={styles.callout}>{`${
                  selectedWs ? 1 : 0
                } / ${staticLimit}`}</IonText>
              </IonLabel>
            </Item>
            <List>
              {selectedWs && (
                <Item lines="full" text-wrap="break">
                  <IonLabel className={`ion-text-wrap`}>
                    <div className={styles.callout}>
                      {t('lbl_workstation', { workstation: selectedWs.name })}
                    </div>
                    <div className={styles.subtitle}>
                      <Icon
                        className={`icon icon-equipments icon-24  ${styles.iconInfo}`}
                      />
                      <h3 className={`${styles.callout}`}>
                        {selectedWs.equipment}
                      </h3>
                    </div>
                  </IonLabel>
                  <IonLabel
                    className={`ion-text-wrap  ${styles.actionsLabel}`}
                    slot="end"
                  >
                    <div
                      className={`${styles.removeLabel} ${styles.callout}`}
                      onClick={() => setUnselectWs(selectedWs)}
                    >
                      {t('lbl_remove')}
                    </div>
                    {districtName === selectedWs.location.district ? (
                      <div className={styles.subtitle}>
                        <Icon
                          className={`icon icon-neighbourhood icon-24  ${styles.iconInfo}`}
                        />
                        <h3 className={styles.callout}>
                          {t('lbl_your_district')}
                        </h3>
                      </div>
                    ) : (
                      <div className={styles.subtitle}>
                        <h3>{selectedWs.location.district}</h3>
                      </div>
                    )}
                  </IonLabel>
                </Item>
              )}
            </List>
          </div>
        </SheetModal>
        <Toast
          isOpen={showToast}
          onDidDismiss={() => handleDismissToast()}
          message={msgError}
          duration={3000}
          position="bottom"
          type="error"
        />
        <Toast
          isOpen={!!userDistrictError}
          onDidDismiss={() => setUserDistrictError(false)}
          message={t('lbl_no_available_district')}
          duration={3000}
          position="bottom"
          type="error"
        />
        <BuildingModal
          id="buildingModal"
          isOpen={isModalOpen}
          setOpenModal={setOpenModal}
          buildings={wsData.buildings}
          setSelectedBuilding={handleSelectBuilding}
          value={selectedBuilding?.id.toString()}
        />
        <FloorModal
          id="floorModal"
          isOpen={isModalFloorOpen}
          setOpenModal={setModalFloorOpen}
          floors={wsData.floors}
          setSelectedFloor={floor => handleSelectFloor(floor)}
          value={selectedFloor?.id.toString()}
        ></FloorModal>
      </IonContent>
      <IonFooter id="map-selector-footer">
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              onClick={handleConfirmSelection}
              color="primary"
              disabled={!selectedWs}
            >
              {t('btn_confirm_reservation')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default MapSelectorLastMinute;
