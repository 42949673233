import { IonHeader, IonContent, IonButton, IonIcon } from '@ionic/react';

import { Modal, TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { RadioList } from '../../../../components/Base/RadioList';
import { Props } from './types';
import styles from './styles.module.scss';

const PreferencesTypeModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const cnfValue = [
    {
      name: t('SAME_DESK_CONFIGURATION'),
      value: 'SAME_DESK_CONFIGURATION',
    },
    {
      name: t('SAME_ZONE_CONFIGURATION'),
      value: 'SAME_ZONE_CONFIGURATION',
    },
  ];
  const handleSelectOption = value => {
    props.setValue(value);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={() => props.showModal(false)}
    >
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonButton
              className={styles.btnBack}
              fill="clear"
              onClick={() => props.showModal(false)}
            >
              <IonIcon className="icon icon-chevron-left" />
            </IonButton>
          }
          title={t('preference')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.listModal}>
          <RadioList
            options={cnfValue}
            selectedOption={props.value}
            onChange={handleSelectOption}
          />
          <div className={`${styles.hintText}`}>
            {t('preferences_modal_hint')}
          </div>
        </div>
      </IonContent>
    </Modal>
  );
};
export default PreferencesTypeModal;
