import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

const addNewToGallery = async () => {
  const image = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source: CameraSource.Camera,
    quality: 25,
  });
  if (image) {
    return image;
  }
};

const addPhotoFormGallery = async () => {
  return Camera.pickImages({
    quality: 20,
  });
};

export const photoServices = {
  addNewToGallery,
  addPhotoFormGallery,
};
