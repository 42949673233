import muleSoftInstance from '../../utils/httpCommon';

const getMedicalServices = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_SERVICES}/medicalServices`;
  try {
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const medicalServicesServices = {
  getMedicalServices,
};
