import {
  NotificationsAction,
  NotificationsState,
} from '../models/notifications.model';
import { NotificationsTypesEnum } from '../types/notifications.types.enum';

const initialState: NotificationsState = {
  error: null,
  isLoading: false,
  notifications: [],
  notificationUnauthorizedTitle: '',
  notices: [],
  notificationsHome: [],
  noticeToastMessage: null,
  isSubscribed: false,
  notificationDetail: null,
  genericToast: {
    message: '',
    type: 'info',
    isOpen: false,
  },
  genericAlert: {
    header: '',
    message: '',
    isOpen: false,
    buttons: [],
  },
};

export const notificationsReducer = (
  state: NotificationsState = initialState,
  action: NotificationsAction,
): NotificationsState => {
  switch (action.type) {
    case NotificationsTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        notificationUnauthorizedTitle: '',
      };
    case NotificationsTypesEnum.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
        isLoading: false,
      };
    case NotificationsTypesEnum.SET_NOTICES:
      return {
        ...state,
        notices: action.notifications,
        isLoading: false,
      };
    case NotificationsTypesEnum.SET_NOTIFICATIONS_HOME:
      return {
        ...state,
        notificationsHome: action.notifications,
        isLoading: false,
      };
    case NotificationsTypesEnum.SET_NOTIFICATION_READ:
      return {
        ...state,
        notifications: state.notifications.map(n => {
          if (action.id === n.messageId) {
            return {
              ...n,
              read: true,
            };
          } else {
            return n;
          }
        }),
        isLoading: false,
      };

    case NotificationsTypesEnum.SET_ALL_NOTIFICATIONS_READ:
      return {
        ...state,
        notifications: state.notifications.map(notification => ({
          ...notification,
          read: true,
        })),
        isLoading: false,
      };

    case NotificationsTypesEnum.DEL_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          n => action.id != n.messageId,
        ),
        isLoading: false,
      };

    case NotificationsTypesEnum.FAILURE_NOTIFICATIONS:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case NotificationsTypesEnum.SET_NOTIFICATION_UNAUTHORIZED:
      return {
        ...state,
        notificationUnauthorizedTitle: action.title,
      };
    case NotificationsTypesEnum.SET_NOTICE_MESSAGE:
      return {
        ...state,
        noticeToastMessage: action.noticeToastMessage,
      };
    case NotificationsTypesEnum.RESET_NOTICE_MESSAGE:
      return {
        ...state,
        noticeToastMessage: null,
      };
    case NotificationsTypesEnum.SUBSCRIBE_FIREBASE:
      return {
        ...state,
        isSubscribed: true,
      };
    case NotificationsTypesEnum.SET_NOTIFICATION_DETAIL:
      return {
        ...state,
        notificationDetail: action.notificationDetail,
      };
    case NotificationsTypesEnum.SET_GENERIC_TOAST:
      return {
        ...state,
        genericToast: action.genericToast,
      };
    case NotificationsTypesEnum.SET_GENERIC_ALERT:
      return {
        ...state,
        genericAlert: action.genericAlert,
      };
    default:
      return state;
  }
};
