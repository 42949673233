export enum passengersTypesEnum {
  REQUEST = 'FETCH_RESERVATON_REQUEST',
  FAILURE_PASSENGERS = 'FAILURE_PASSENGERS',
  RESET_MESSAGE = 'RESET_MESSAGE',
  SET_AVAILABLE_PASSENGERS = 'SET_AVAILABLE_PASSENGERS',
  SET_DEFAULT_PASSENGERS = 'SET_DEFAULT_PASSENGERS',
  SET_PASSENGERS = 'SET_PASSENGERS',
  ADD_PASSENGERS = 'ADD_PASSENGERS',
  DELETE_PASSENGER = 'DELETE_PASSENGER',
  SET_DEFAULT_PARKING_COMPANIONS = 'SET_DEFAULT_PARKING_COMPANIONS',
  DELETE_PARKING_COMPANION = 'DELETE_PARKING_COMPANION',
  ADD_PARKING_COMPANION = 'ADD_PARKING_COMPANION',
  RESET_PARKING_COMPANIONS = 'RESET_PARKING_COMPANIONS',
  MSG_PASSENGERS = 'MSG_PASSENGERS',
}
