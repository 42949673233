export enum authTypesEnum {
  REQUEST = 'FETCH_AUTH_REQUEST',
  AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS',
  CURRENT_SESSION_SUCCESS = 'FETCH_CURRENT_SESSION_SUCCESS',
  FAILURE = 'FETCH_AUTH_FAILURE',
  RESET = 'RESET_AUTH_DATA',
  LOGOUT = 'FETCH_AUTH_LOGOUT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  SET_IS_LOADING = 'SET_IS_LOADING',
}
