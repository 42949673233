import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TopBar, SearchBar, Button } from '@acciona/ui-ionic-kit';
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonContent,
  IonFooter,
  IonSpinner,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../../../_redux/actions/user.actions';
import { UserStore } from '../../../../_redux/models/user.model';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { RadioList } from '../../../../components/Base/RadioList';
import { includesQueryString, isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';

const HeadOffice = () => {
  const { user, isLoading } = useSelector((store: UserStore) => store.user);
  const { defaultSede, sedesList } = user;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [selectedOption, setSelectedOption] = useState(defaultSede?.id);

  const handleSearchChange = e => {
    setSearchText(e.detail.value);
  };

  const sedes = useMemo(() => {
    return sedesList
      .filter(sede => includesQueryString(sede.description, searchText))
      .map(s => ({ value: s.id, name: s.description }));
  }, [searchText]);

  const handleSelectOption = selected => {
    setSelectedOption(selected);
  };

  const handleConfirmChange = () => {
    const nextSede = sedesList.find(sede => sede.id === selectedOption);
    dispatch(userActions.updateUserDefaultHeadOffice(nextSede));
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/dashboard/home"
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_my_head_office')}
        />
      </IonHeader>
      <IonContent>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {sedesList.length > 1 ? (
              <>
                <SearchBar
                  autoCapitalize="sentences"
                  onIonInput={handleSearchChange}
                  className={styles.searchBar}
                  cancelButtonText="cancel-button-text"
                  placeholder={t('search_head_office_placeholder')}
                  disabled={isEmpty(sedesList)}
                  debounce={800}
                />
                <h3 className={styles.sedesHeader}>
                  {t('title_available_head_offices')}
                </h3>
                <div className={styles.separator}></div>
                <RadioList
                  options={sedes}
                  selectedOption={selectedOption}
                  onChange={handleSelectOption}
                  whiteSpaceNormal
                />
              </>
            ) : (
              <>
                <div className={styles.separatorMobile}></div>
                <DetailList>
                  <DetailListItem
                    mode="ios"
                    lines="none"
                    titleLight={t('lbl_head_office')}
                    endText={defaultSede?.description}
                    endTextColor="dark"
                    className={styles.onlyOneSede}
                  />
                  <div className={styles.separator}></div>
                </DetailList>
              </>
            )}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        {sedesList.length > 1 && (
          <div className={styles.btnGroupWeb}>
            <div>
              <Button
                onClick={handleConfirmChange}
                color="primary"
                disabled={selectedOption === defaultSede.id || isLoading}
              >
                {t('btn_confirm_selection')}
                {isLoading && (
                  <IonSpinner
                    slot="end"
                    className={styles.color_primary}
                    name="lines-small"
                  />
                )}
              </Button>
            </div>
          </div>
        )}
      </IonFooter>
    </IonPage>
  );
};

export default HeadOffice;
