import { MenuConfig } from '../_redux/models';

export const isMoreButtonVisible = (
  menuConfig: MenuConfig,
  permissions: string[],
): boolean => {
  return menuConfig.some(section => {
    const visibleItems = section.items.filter(item => {
      return permissions.includes(item.permission);
    });
    return visibleItems.length > 0;
  });
};
