import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthStore } from '../../_redux/models/auth.model';
import { AppStore } from '../../_redux/models/app.model';

interface OfflineRouteProps extends RouteProps {
  children?: any;
}
const OfflineRoute: React.FC<OfflineRouteProps> = ({ children, ...rest }) => {
  const isAuth = useSelector((state: AuthStore) => state.auth.isAuth);
  const { offlineServices } = useSelector((state: AppStore) => state.app);

  return (
    <Route {...rest}>
      {isAuth || offlineServices ? children : <Redirect to="/login" />}
    </Route>
  );
};
export default OfflineRoute;
