import { AlertInput } from '@ionic/react';
import {
  ParkingReservation,
  ScheduleReservation,
} from '../../../../_redux/models/parking.model';
import i18n from '../../../../i18nextConf';
import {
  POLICIES_SCHEDULES_INDEXED_BY_DESCRIPTION,
  POLICIES_SCHEDULES_INDEXED_BY_KEY,
} from '../../../../utils/constants';
import { isEmpty } from '../../../../utils/functions';
import { addHours, isToday } from '../../../../utils/dateTime';
import dayjs from 'dayjs';
import { createDateUTC } from '../../helpers';

export const scheduleLiterals = {
  'Dia completo': i18n.t('complete_day'),
  'Morning': i18n.t('morning_schedule_no_hours'),
  'Tarde': i18n.t('afternoon_schedule_no_hours'),
  'Personalizada': i18n.t('custom_schedule'),
};

export const getAvailableOptions = (
  policies: ScheduleReservation[],
  reservationType: string,
  campusTimeZone: string = '',
  date: string,
) => {
  if (isEmpty(policies)) {
    return [];
  }

  const type = POLICIES_SCHEDULES_INDEXED_BY_DESCRIPTION[reservationType] ?? '';

  const sedeAvailableOptions = policies.map(
    policy => POLICIES_SCHEDULES_INDEXED_BY_DESCRIPTION[policy.nombre],
  );

  if (type === 'complete_day') {
    if (!isToday(date)) {
      return sedeAvailableOptions.filter(option => option !== 'complete_day');
    } else {
      return sedeAvailableOptions
        .filter(option => option !== 'complete_day')
        .map(op => {
          const MaxHour = policies.find(
            p => p.nombre === POLICIES_SCHEDULES_INDEXED_BY_KEY[op],
          )?.fullHoraMax;

          const endReservationHour =
            createDateUTC(
              dayjs().tz(campusTimeZone).format('YYYY-MM-DD'),
              MaxHour,
              campusTimeZone,
            ) || new Date();
          const todayHour =
            createDateUTC(
              dayjs().tz(campusTimeZone).format('YYYY-MM-DD'),
              dayjs().tz(campusTimeZone).format('HH:mm'),
              campusTimeZone,
            ) || new Date();
          if (todayHour < endReservationHour) {
            return op;
          }
        });
    }
  } else if (
    ['morning_schedule', 'afternoon_schedule', 'custom_schedule'].includes(
      type,
    ) &&
    sedeAvailableOptions.includes('custom_schedule')
  ) {
    return ['custom_schedule'];
  } else {
    return [];
  }
};

export const getMinEndHour = (minEndHour: string, startHour: string) => {
  if (!minEndHour) {
    return '23:59';
  }

  if (!startHour) {
    return minEndHour;
  }

  const hourStart = startHour.split(':')[0];
  const hourMin = minEndHour.split(':')[0];

  if (hourMin <= hourStart) {
    return addHours(startHour, 1);
  }

  return minEndHour;
};

export const getReleaseOptions = (
  policies: ScheduleReservation[],
): AlertInput[] => {
  return [
    {
      type: 'radio',
      label: i18n.t('complete_day'),
      value: 'complete_day',
    },
    {
      type: 'radio',
      label: `${i18n.t('morning_schedule', {
        endHour: policies.find(
          p =>
            p.nombre === POLICIES_SCHEDULES_INDEXED_BY_KEY['morning_schedule'],
        )?.fullHoraMax,
      })}`,
      value: 'morning_schedule',
    },
    {
      type: 'radio',
      label: `${i18n.t('afternoon_schedule', {
        startHour: policies.find(
          p =>
            p.nombre ===
            POLICIES_SCHEDULES_INDEXED_BY_KEY['afternoon_schedule'],
        )?.fullHoraMin,
      })}`,
      value: 'afternoon_schedule',
    },
    {
      type: 'radio',
      label: i18n.t('custom_schedule'),
      value: 'custom_schedule',
    },
  ];
};

export const getReservationTypeText = (item: ParkingReservation) => {
  if (
    item?.reservationType === POLICIES_SCHEDULES_INDEXED_BY_KEY.custom_schedule
  ) {
    return `${item?.startHour} ${i18n.t('lbl_to_date')} ${item?.endHour}h`;
  } else {
    return scheduleLiterals[item?.reservationType] ?? '';
  }
};
