import { PassengersAction, PassengersState } from '../models/passengers.model';
import { passengersTypesEnum } from '../types/passengers.types.enum';

const initialState = {
  error: null,
  isLoading: false,
  msg: null,
  passengersSelected: [],
  availablePassengers: [],
  defaultPassengers: [],
  parkingCompanions: [],
};

export const passengersReducer = (
  state: PassengersState = initialState,
  action: PassengersAction,
): PassengersState => {
  switch (action.type) {
    case passengersTypesEnum.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case passengersTypesEnum.SET_AVAILABLE_PASSENGERS:
      return {
        ...state,
        availablePassengers: action.availablePassengers,
        isLoading: false,
      };
    case passengersTypesEnum.SET_DEFAULT_PASSENGERS:
      return {
        ...state,
        defaultPassengers: action.defaultPassengers,
        isLoading: false,
      };
    case passengersTypesEnum.SET_DEFAULT_PARKING_COMPANIONS:
      return {
        ...state,
        parkingCompanions: action.parkingCompanions,
        isLoading: false,
      };

    case passengersTypesEnum.ADD_PASSENGERS:
      return {
        ...state,
        defaultPassengers: [
          ...state.defaultPassengers,
          ...action.defaultPassengers,
        ],
        isLoading: false,
      };
    case passengersTypesEnum.SET_PASSENGERS:
      return {
        ...state,
        isLoading: false,
        error: null,
        passengersSelected: action.passengersSelected,
      };
    case passengersTypesEnum.MSG_PASSENGERS:
      return {
        ...state,
        error: null,
        msg: action.msg,
        isLoading: false,
      };
    case passengersTypesEnum.ADD_PARKING_COMPANION:
      return {
        ...state,
        parkingCompanions: [
          ...state.parkingCompanions,
          ...action.parkingCompanions,
        ],
        isLoading: false,
      };

    case passengersTypesEnum.DELETE_PARKING_COMPANION:
      return {
        ...state,
        parkingCompanions: state.parkingCompanions.filter(e => {
          return action.passenger.employeeId !== e.employeeId;
        }),
      };
    case passengersTypesEnum.FAILURE_PASSENGERS:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case passengersTypesEnum.RESET_MESSAGE:
      return {
        ...state,
        msg: null,
      };
    case passengersTypesEnum.RESET_PARKING_COMPANIONS:
      return {
        ...state,
        defaultPassengers: null,
        passengersSelected: null,
        parkingCompanions: null,
      };

    default:
      return state;
  }
};
