import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonIcon,
  IonTitle,
} from '@ionic/react';

import { Modal } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { RadioList } from '../../../../components/Base/RadioList';
import { Props } from './types';
import styles from './styles.module.scss';

const RoomUseTypeModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const roomUseTypes = [
    {
      name: t('use_internal'),
      value: t('use_internal'),
    },
    {
      name: t('use_external'),
      value: t('use_external'),
    },
  ];
  const handleSelectOption = value => {
    props.setValue(value);
    props.showModal(false);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      id={props.id}
      onDidDismiss={() => props.showModal(false)}
    >
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButton
            className={styles.btnBack}
            fill="clear"
            onClick={() => props.showModal(false)}
          >
            <IonIcon className="icon icon-chevron-left" />
            <IonTitle className={styles.title}>
              {t('use_workrooms_select')}
            </IonTitle>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.listModal}>
          <div className={`${styles.body} ${styles.bodyMargin}`}>
            {t('msg_use_workrooms')}
          </div>
          <RadioList
            options={roomUseTypes}
            selectedOption={props.value}
            onChange={handleSelectOption}
            allowEmptySelection
          />
        </div>
      </IonContent>
    </Modal>
  );
};
export default RoomUseTypeModal;
