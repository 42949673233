import { IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Item, List } from '@acciona/ui-ionic-kit';
import { Vehicle } from '../../../../_redux/models/vehicles.model';
import { Companions } from '../../../../_redux/models/parking.model';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { isEmpty } from '../../../../utils/functions';
import styles from './styles.module.scss';

type Props = {
  vehicles: Vehicle[];
  companions: Companions[];
};

export const VehiclesAndPassengersBlock: React.FC<Props> = ({
  vehicles,
  companions,
}) => {
  const { t } = useTranslation();

  const { history } = useReservationsHistory();

  return (
    <>
      <DetailListItem
        lines="inset"
        title={t('lbl_personal_vehicle_passengers')}
        readonly
      />
      {!vehicles || isEmpty(vehicles) ? null : (
        <>
          <DetailListItem
            className={`${styles.itemList} ${styles.itemChevron}`}
            lines="none"
            detail={false}
            onClick={() => history.push('/spaces/parking/detail/vehicles')}
            startIcon="icon icon-car"
            startIconColor="primary"
            title={`${vehicles.length} ${t('associated_vehicles', {
              multiple: vehicles.length > 1 ? 's' : '',
            }).toLowerCase()}`}
            endIcon="icon icon-chevron-right"
            endIconColor="primary"
          />
          <Item lines="none">
            <div className={styles.vehicleList}>
              {vehicles.map(v => {
                return (
                  <IonText
                    key={v.brand}
                    color={'primary'}
                    className={`${styles.description} ion-text-wrap ${styles.subhead} ${styles.block}`}
                  >
                    {`${v.brand} ${v.model} | ${v.plate} `}
                  </IonText>
                );
              })}
            </div>
          </Item>
        </>
      )}
      <DetailListItem
        className={`${styles.itemList} ${styles.itemChevron}`}
        lines="none"
        onClick={() =>
          !isEmpty(companions)
            ? history.push('/spaces/parking/detail/passengers')
            : null
        }
        detail={false}
        startIcon="icon icon-occupation"
        startIconColor="primary"
        title={
          isEmpty(companions)
            ? t('lbl_no_companions')
            : `${companions.length} ${t('associated_companions', {
                multiple: companions.length > 1 ? 's' : '',
              })}`
        }
        endIcon="icon icon-chevron-right"
        endIconColor="primary"
      />
      <List className={styles.listCompanion}>
        {isEmpty(companions)
          ? null
          : companions.map((item, index) => {
              return (
                <DetailListItem
                  companion
                  key={index}
                  startIcon="icon icon-client"
                  startIconColor="primary"
                  titleLight={item.fullName}
                  description={item.function}
                />
              );
            })}
      </List>
    </>
  );
};
