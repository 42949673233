export const pathnameToLog: Record<
  string,
  {
    name?: string;
    shouldLog: boolean;
  }
> = {
  // DASHBOARD
  '/dashboard/home': {
    name: 'Dashboard',
    shouldLog: true,
  },
  '/dashboard/actions': {
    name: 'Actions',
    shouldLog: true,
  },
  '/dashboard/reservations': {
    name: 'Mis reservas',
    shouldLog: true,
  },
  '/dashboard/news': {
    name: 'Noticias',
    shouldLog: true,
  },
  '/dashboard/news/comments': {
    name: 'Noticias',
    shouldLog: true,
  },
  '/dashboard/more': {
    name: 'Otros',
    shouldLog: true,
  },
  // PROFILE
  '/profile/home': {
    name: 'Perfil',
    shouldLog: true,
  },
  '/profile/search': {
    name: 'Buscador',
    shouldLog: true,
  },
  '/profile/user/detail': {
    name: 'Perfil > Datos personales',
    shouldLog: true,
  },
  '/profile/user/card/card-activation': {
    name: 'Perfil > Datos personales > Tarjeta digital > Activación',
    shouldLog: true,
  },
  '/profile/user/favworkstations': {
    name: 'Perfil > Datos personales > Mi zona zona de trabajo y puestos > Puestos favoritos',
    shouldLog: true,
  },
  '/profile/user/favworkstations/mapSelector': {
    name: 'Perfil > Datos personales > Mi zona zona de trabajo y puestos > Zona de trabajo',
    shouldLog: true,
  },
  '/profile/user/work-place': {
    name: 'Perfil > Datos personales > Mi zona y puestos de trabajo',
    shouldLog: true,
  },
  '/profile/user/work-preferences': {
    name: 'Perfil > Datos personales > Mi zona zona de trabajo y puestos > Preferencias',
    shouldLog: true,
  },
  '/profile/user/vehicles-passengers/passengers-detail': {
    name: 'Perfil > Datos personales > Mi parking',
    shouldLog: true,
  },
  '/profile/user/vehicles-passengers/vehicle-detail': {
    name: 'Perfil > Datos personales > Mi parking > Mi vehículo y acompañantes',
    shouldLog: true,
  },
  '/profile/user/vehicles-passengers': {
    name: 'Perfil > Datos personales > Mi parking > Mi vehículo y acompañantes',
    shouldLog: true,
  },
  '/profile/user/parking-menu': {
    name: 'Perfil > Datos personales > Mi parking',
    shouldLog: true,
  },
  '/profile/settings': {
    name: 'Perfil > Configuración',
    shouldLog: true,
  },
  '/profile/settings/head-office': {
    name: 'Perfil > Datos personales > Mi sede',
    shouldLog: true,
  },
  '/profile/settings/language': {
    name: 'Perfil > Configuración > Idioma',
    shouldLog: true,
  },
  '/profile/incidence': {
    name: 'Perfil > Mis incidencias',
    shouldLog: true,
  },
  '/profile/wallet': {
    name: 'Perfil > Wallet',
    shouldLog: true,
  },
  '/profile/legal': {
    name: 'Mi perfil > Legal',
    shouldLog: true,
  },

  // CODE INVITATION
  '/codeInvitation': {
    name: 'Perfil > Datos personales > Tarjeta digital > Código',
    shouldLog: true,
  },

  // WORKSTATION

  '/workstation/calendar': {
    name: 'Acciones > Reservar puesto > Calendario',
    shouldLog: true,
  },
  '/workstation/calendarRelease': {
    name: 'Mis reservas > Puesto > Liberación > Calendario',
    shouldLog: true,
  },
  '/workstation/CandeledDesk': {
    name: 'Mis reservas > Puesto > Cancelación reserva',
    shouldLog: true,
  },
  '/workstation/lastminute': {
    name: 'Acciones > Reservar puesto > Calendario',
    shouldLog: true,
  },
  '/workstation/mapselectorlastminute': {
    name: 'Acciones > Reservar puesto > Mapa',
    shouldLog: true,
  },
  '/workstation/modifyRelease': {
    name: 'Mis reservas > Puesto > Liberación',
    shouldLog: true,
  },

  //CARD
  '/card': {
    name: 'Perfil > Datos personales > Tarjeta digital',
    shouldLog: true,
  },

  // SPACES WORKSTATION
  '/spaces/workstation/reservationdetail': {
    name: 'Mis reservas > Puesto > Detalle',
    shouldLog: true,
  },
  // SPACES LOCKER
  '/spaces/locker/downloadApp': {
    name: 'Mis reservas > Taquilla > Descarga App taquillas',
    shouldLog: true,
  },
  // SPACES PARKING
  '/spaces/parking/requestdetail': {
    name: 'Mis reservas > Parking pendiente > Detalle',
    shouldLog: true,
  },
  '/spaces/parking/detail': {
    name: 'Mis reservas > Parking > Detalle',
    shouldLog: true,
  },
  '/spaces/parking/detail/vehiclesrequest': {
    name: 'Mis reservas > Parking > Detalle > acompañantes',
    shouldLog: true,
  },
  '/spaces/parking/detail/passengersrequest': {
    name: 'Mis reservas > Parking > Acompañantes',
    shouldLog: true,
  },
  // SPACES WORKROOM
  '/spaces/workroom/prereservationdetail': {
    name: 'Acciones > Reserva sala > Confirmación reserva',
    shouldLog: true,
  },
  '/spaces/workroom/reservationdetail': {
    name: 'Mis reservas > Salas > Detalle',
    shouldLog: true,
  },
  '/spaces/workroom/waitingdetail': {
    name: 'Acciones > Reservar sala > Lista de espera',
    shouldLog: true,
  },
  // SPACES CANTEEN
  '/spaces/canteen/reservationdetail': {
    name: 'Acciones > Reserva comedor > Detalle',
    shouldLog: true,
  },

  // WORKROOM
  '/workroom/list': {
    name: 'Acciones > Reservar sala > Listado',
    shouldLog: true,
  },
  '/workroom/noAvailable': {
    name: 'Acciones > Reservar sala > Sala no disponible',
    shouldLog: true,
  },
  '/workroom/hostsearch': {
    name: 'Acciones > Reservar sala > Buscar anfitrión',
    shouldLog: true,
  },
  '/workroom/checkin': {
    name: 'Mis reservas reservas > Sala > checkin',
    shouldLog: true,
  },
  '/workroom/filters': {
    name: 'Acciones > Reserva sala',
    shouldLog: true,
  },
  '/workroom/scanqrcode': {
    name: 'Mis reservas reservas > Sala > checkin',
    shouldLog: true,
  },

  // PARKING
  '/parking/lastminute': {
    name: 'Acciones > Reservar parking > Lastminute',
    shouldLog: true,
  },
  '/parking/noAvailable': {
    name: 'Acciones > Reservar puesto > No disponible plaza',
    shouldLog: true,
  },
  '/parking/NotAvailablePlace': {
    name: 'Acciones > Reservar parking > No hay plazas disponibles',
    shouldLog: true,
  },
  '/parking/noAvailableVehicle': {
    name: 'Acciones > Reservar parking > No disponible vehículo',
    shouldLog: true,
  },
  '/parking/calendar': {
    name: 'Acciones > Reservar parking > Lastminute > Calendario',
    shouldLog: true,
  },
  '/parking/calendarPlanned': {
    name: 'Acciones > Reservar parking > Planificada > Calendario',
    shouldLog: true,
  },
  '/parking/calendarRelease': {
    name: 'Mis reservas > Parking > Liberar plaza',
    shouldLog: true,
  },
  '/parking/changeVehicle': {
    name: 'Acciones > Reservar parking > Cambio de vehículo',
    shouldLog: true,
  },
  '/parking/reservationPlanned': {
    name: 'Mis reservas > Parking > Liberación > Calendario',
    shouldLog: true,
  },
  '/parking/patype': {
    name: 'Acciones > Reservar parking > Seleccionar tipo de reserva',
    shouldLog: true,
  },
  '/parking/CanceledPlace': {
    name: 'Mis reservas > Parking > Cancelación reserva',
    shouldLog: true,
  },

  // NOTIFICATIONS
  '/notifications': {
    name: 'Campana > Notificaciones y avisos',
    shouldLog: true,
  },

  // HOW TO GET
  '/services/howtoget': {
    shouldLog: false,
  },
  '/services/howtoget/list': {
    name: 'Otros > Cómo llegar',
    shouldLog: true,
  },
  '/services/howtoget/routesandshuttle': {
    name: 'Otros > Rutas y lanzaderas',
    shouldLog: true,
  },
  '/services/howtoget/shuttleinfotmation': {
    name: 'Otros > Rutas y lanzaderas > Lanzaderas',
    shouldLog: true,
  },

  // OTROS
  '#N/D_1': {
    name: 'Otros > Cómo llegar',
    shouldLog: true,
  },
  '#N/D_2': {
    name: 'Acciones > Incidencias ',
    shouldLog: true,
  },

  // INCIDENTS
  '/incidents/categories': {
    name: 'Acciones > Incidencias ',
    shouldLog: true,
  },
  '/incidents/report': {
    name: 'Acciones > Incidencias ',
    shouldLog: true,
  },
  '/incidents/reporttype': {
    name: 'Acciones > Incidencias ',
    shouldLog: true,
  },
  '/incidents/callphone': {
    name: 'Mis reservas > Salas > Teléfono incidencia',
    shouldLog: true,
  },

  // MEDICAL SERVICES
  '/services/medicalservices': {
    shouldLog: false,
  },
  '/services/medicalservices/medicalServicesInformation': {
    name: 'Otros > Servicio médico',
    shouldLog: true,
  },
  '/services/medicalservices/medicalServicesList': {
    name: 'Otros > Servicio médico',
    shouldLog: true,
  },

  // NEWS
  '/news/detail': {
    name: 'Noticias',
    shouldLog: true,
  },

  // RESTAURANTS
  '/services/restaurants': {
    shouldLog: false,
  },
  '/services/restaurants/restaurantInformation': {
    name: 'Otros > Detalle restaurante',
    shouldLog: true,
  },
  '/services/restaurants/restaurantList': {
    name: 'Otros > Listado restaurantes',
    shouldLog: true,
  },

  '/deeplink': {
    name: 'Perfil > Datos personales > Tarjeta digital > Código',
    shouldLog: true,
  },

  '/login': {
    name: 'Login',
    shouldLog: true,
  },
};
