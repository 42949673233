import { StorageService } from '../storage/storage.service';
let token = undefined;

const getLocalRefreshToken = async (): Promise<string> => {
  const account = await getAccount();
  return account?.refresh_token as string;
};

const getLocalAccessToken = async (): Promise<string> => {
  const account = await getAccount();
  return account?.access_token as string;
};
const getLocalIdToken = async (): Promise<string> => {
  const account = await getAccount();
  return account?.id_token as string;
};

const updateLocalTokens = async (idToken, accessToken) => {
  const account = await getAccount();
  account.access_token = accessToken;
  account.id_token = idToken;
  await StorageService.setItem('account', account);
};

const getAccount = async () => {
  if (token == undefined) {
    const localToken = await StorageService.getItem('account');
    token = localToken;
  }
  return token;
};

const getUser = async () => {
  return await StorageService.getItem('user');
};

const getSedeEmployee = async () => {
  return await StorageService.getItem('sedeEmployee');
};

const setAccount = async (localToken, user) => {
  token = localToken;
  await StorageService.setItem('account', localToken);
  await StorageService.setItem('user', user);
};

const removeAccount = async () => {
  token = undefined;
  await StorageService.removeItem('account');
  await StorageService.removeItem('user');
};

export const tokenServices = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getLocalIdToken,
  updateLocalTokens,
  getAccount,
  getUser,
  getSedeEmployee,
  setAccount,
  removeAccount,
};
