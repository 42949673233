import { FileSystemService } from '../fileSystem/fileSystem.service';

import {
  DocumentViewer,
  DocumentViewerOptions,
} from '@awesome-cordova-plugins/document-viewer';
import { isPlatform } from '@ionic/react';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';

const openFileByRemoteUrl = async (url: string, fileName: string) => {
  try {
    const localPath = await FileSystemService.downloadFileAndReturnLocalPath(
      url,
      fileName,
    );
    const documentType = 'application/pdf';
    const options: DocumentViewerOptions = {
      title: fileName,
    };
    if (isPlatform('android')) {
      await openAndroidFile(fileName);
    } else {
      DocumentViewer.viewDocument(localPath, documentType, options);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const openAndroidFile = async (fileName: string) => {
  const filePath = await FileSystemService.fileExistInLocalStore(fileName);
  await FileOpener.open(filePath, 'application/pdf');
};
export const FileReaderService = {
  openFileByRemoteUrl,
};
