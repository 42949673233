import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonLabel,
  IonRow,
  IonCol,
  IonFooter,
  IonSpinner,
  IonText,
  IonIcon,
} from '@ionic/react';
import {
  TopBar,
  Button,
  DayCalendar,
  Alert,
  Toast,
} from '@acciona/ui-ionic-kit';
import { ParkingStore } from '../../../../_redux/models/parking.model';
import {
  Vehicle,
  VehicleStore,
} from '../../../../_redux/models/vehicles.model';
import {
  PassengerData,
  PassengersStore,
} from '../../../../_redux/models/passengers.model';
import { AppStore } from '../../../../_redux/models/app.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { parkingActions, reservationActions } from '../../../../_redux/actions';
import { passengersActions } from '../../../../_redux/actions/passengers.actions';
import { NewVehicleModal } from '../../../Profile/VehiclesPassengers/NewVehicleModal/NewVehicleModal';
import { ReservationCard } from '../../../../components/Base/ReservationCard';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { DetailList } from '../../../../components/Base/DetailList';
import { isEmpty } from '../../../../utils/functions';
import i18n from '../../../../i18nextConf';
import {
  getSuggestedVehicle,
  getVehicleIcon,
} from '../../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';
import { getIconSchedule } from '../../helpers';
import _ from 'lodash';
import { useLocationState } from '../../../../hooks/useLocationState';
import { getAllDatesMachWithVehicle } from '../helpers';

const LastMinuteParking: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { vehicle: selectedVehicle, vehicles: userVehicles } = useSelector(
    (state: VehicleStore) => state.vehicle,
  );
  const { parkings, error, isLoading } = useSelector(
    (state: ParkingStore) => state.parking,
  );
  const { permissions } = useSelector((state: UserStore) => state.user.user);
  const [showModalAddVehicle, setShowModalAddVehicle] = useState(false);
  const [showAlertOtherVehicle, setShowAlertOtherVehicle] = useState(false);
  const history = useHistory();
  const state = useLocationState();
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [suggestedVehicle, setSuggestedVehicle] = useState<Vehicle>(null);
  const [dataVehicle, setDataVehicle] = useState([]);
  const [dataCompanions, setDataCompanions] = useState([]);
  const [parkingCompanionsList, setParkingCompanionsList] = useState<
    PassengerData[]
  >([]);
  const [showAlert, setShowAlert] = useState(false);
  const vehAttributes = useSelector(
    (state: VehicleStore) => state.vehicle.attributes,
  );
  const {
    defaultPassengers,
    parkingCompanions,
    msg: msgState,
  } = useSelector((state: PassengersStore) => state.passengers);

  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );
  const { defaultSede } = useSelector((store: UserStore) => store.user.user);
  useEffect(() => {
    if (selectedVehicle) {
      setSuggestedVehicle(
        getSuggestedVehicle(userVehicles, permissions, selectedVehicle),
      );
    }
  }, [selectedVehicle, userVehicles, permissions]);

  useEffect(() => {
    suggestedVehicle &&
      setDataVehicle([
        {
          text: `${t('lbl_selected_vehicle')} ${suggestedVehicle.brand} ${
            suggestedVehicle.model
          } - ${suggestedVehicle.plate}`,
          description: t('lbl_parking_change_vehicle'),
          routerLink: {
            pathname: '/parking/changeVehicle',
            state: {
              ...(state as Record<string, any>),
              reserveVehicles: !_.isEmpty(parkings)
                ? parkings[0].reserveVehicles
                : [],
            },
          },
          icon: getVehicleIcon(suggestedVehicle.type),
        },
      ]);
  }, [suggestedVehicle, parkings, state]);

  useEffect(() => {
    setDataCompanions([
      {
        text: isEmpty(parkingCompanionsList)
          ? t('lbl_select_companions')
          : t('lbl_companions_seleccted'),
        description: t('win_acciona_points_carpool'),
        routerLink: '/parking/companions',
        icon: 'icon icon-occupation',
      },
    ]);
  }, [parkingCompanionsList]);

  useEffect(() => {
    state && setSelectedDates(state.selectedDates);
  }, [state]);

  useEffect(() => {
    const parkingDates = parkings?.map(parking => parking.dates).flat();
    const allDatesAllowed = selectedDates?.every(date =>
      parkingDates.includes(date),
    );
    if (!allDatesAllowed && selectedDates?.length && parkings?.length) {
      dispatch(
        passengersActions.setMsgPassengers({
          type: 'error',
          description: i18n.t('not_availables_parking_ahead'),
        }),
      );
    }
  }, [parkings, selectedDates]);

  useEffect(() => {
    dispatch(passengersActions.getDefaultParkingCompanions());
  }, []);

  useEffect(() => {
    parkingCompanions && setParkingCompanionsList(parkingCompanions);
  }, [parkingCompanions]);

  const handleDeleteCompanion = (item: PassengerData) => {
    dispatch(passengersActions.deleteParkingCompanion(item));
    dispatch(
      passengersActions.setMsgPassengers({
        type: 'success',
        description: `${t('lbl_delete_passenger_toast')} `,
      }),
    );
  };

  const handleDismissToast = () => {
    dispatch(reservationActions.resetMsgReservation());
  };

  const handleCreateParking = async () => {
    const allDatesMatchWithVehicle = getAllDatesMachWithVehicle(
      selectedDates,
      parkings[0].reserveVehicles,
      suggestedVehicle,
    );
    if (allDatesMatchWithVehicle) {
      if (isEmpty(parkingCompanionsList)) {
        createParking();
      } else {
        JSON.stringify(parkingCompanionsList) ===
        JSON.stringify(defaultPassengers)
          ? createParking()
          : setShowAlert(true);
      }
    } else {
      setShowAlertOtherVehicle(true);
      return;
    }
  };

  const createParking = async () => {
    dispatch(
      parkingActions.createParkingReserve(
        parkings,
        parkingCompanionsList,
        suggestedVehicle,
        selectedDates,
        state.type,
        state.startHour,
        state.endHour,
        state.textHeader,
      ),
    );
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/parking/calendar"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('reservation_last_minute')}
        />
      </IonHeader>
      <div className={styles.gridWeb}>
        {parkings && vehAttributes && parkings.length > 1 ? (
          <DetailListItem
            description={t('msg_lastminute_nopark_same')}
            descriptionColor="primary"
          />
        ) : null}
      </div>
      <div className={styles.snackbar}>
        <IonText>{state?.textHeader ?? ''}</IonText>
      </div>

      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <NewVehicleModal
            showModal={showModalAddVehicle}
            onClose={() => setShowModalAddVehicle(false)}
            onCloseWithSave={() => setShowModalAddVehicle(false)}
          />

          <DetailList>
            {isEmpty(parkings) ? (
              <>
                <IonRow className="ion-justify-content-center">
                  <IonCol className={styles.center}>
                    <IonLabel className={styles.title_2}>
                      {t('not_available_parking')}
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              parkings?.map((item, index, arr) => {
                return (
                  <DetailListItem
                    key={item.idParking}
                    className={styles.listContain}
                    lines={index + 1 === arr.length ? 'none' : 'inset'}
                  >
                    <ReservationCard
                      data={{
                        id: item.idParking.toString(),
                        type: 'Parking',
                        header: item.name,
                        title: item.location,
                        descriptionSede: defaultSede.description,
                        subtitle: '',
                        link: '',
                        pluggable: item.pluggable,
                        neighborhood: false,
                      }}
                      infoSize="large"
                      headerTranslucent={false}
                      endContent={
                        <IonIcon
                          className={`icon icon-${getIconSchedule(
                            state?.type,
                          )}`}
                        />
                      }
                      icon="icon icon-parking"
                    >
                      {item?.dates?.map((date, index) => {
                        return (
                          <DayCalendar
                            locale={localLanguage}
                            key={index}
                            date={
                              new Date(
                                dayjs(
                                  `${date
                                    .toString()
                                    .substring(0, 10)} 12:00:00`,
                                ).toISOString(),
                              )
                            }
                            selected={false}
                            id={index.toString()}
                            disabled
                          ></DayCalendar>
                        );
                      })}
                    </ReservationCard>
                  </DetailListItem>
                );
              })
            )}

            {vehAttributes &&
              !isEmpty(parkings) &&
              selectedDates?.map((dateFilter, index) => {
                if (
                  vehAttributes &&
                  isEmpty(
                    parkings
                      ?.map(parking => {
                        if (
                          !isEmpty(
                            parking.dates.filter(day =>
                              dayjs(day).tz().isSame(dateFilter, 'day'),
                            ),
                          )
                        ) {
                          return true;
                        }
                      })
                      .filter(res => res),
                  )
                ) {
                  return (
                    <DetailListItem key={index}>
                      <ReservationCard
                        data={{
                          id: 'nodataid',
                          type: 'notfound',
                          header: dayjs(dateFilter).format('ddd DD MMM'),
                          title: t('msg_no_parking'),
                          subtitle: '',
                          neighborhood: false,
                        }}
                        infoSize="large"
                        icon={'icon icon-alert'}
                        headerTranslucent={false}
                      />
                    </DetailListItem>
                  );
                }
              })}
            <div className={styles.lineSeparation}></div>
            <DetailList>
              <DetailListItem
                title={t('lbl_personal_vehicle_passengers')}
                lines="full"
              />
              {dataVehicle?.map((item, index) => {
                return (
                  <DetailListItem
                    key={index}
                    mode="ios"
                    lines="inset"
                    onClick={() => history.push(item.routerLink)}
                    type="button"
                    disabled={item.disabled}
                    startIcon={item.icon}
                    startIconColor="primary"
                    titleLight={item.text}
                    endIconTop="icon icon-chevron-right"
                    endIconColor="primary"
                    footnote={item.description}
                  />
                );
              })}
              {dataCompanions?.map((item, index) => {
                return (
                  <DetailListItem
                    key={index}
                    mode="ios"
                    routerLink={item.routerLink}
                    type="button"
                    disabled={item.disabled}
                    startIcon={item.icon}
                    startIconColor="primary"
                    titleLight={item.text}
                    endIconTop="icon icon-chevron-right"
                    endIconColor="primary"
                    footnote={item.description}
                  />
                );
              })}
            </DetailList>
            <DetailList>
              {parkingCompanionsList?.map(companion => {
                return (
                  <DetailListItem
                    companion
                    key={companion.employeeId}
                    mode="ios"
                    type="button"
                    startIcon="icon icon-client"
                    startIconColor="primary"
                    titleLight={companion.fullName}
                    footnote={companion.function}
                    footnoteColor="dark"
                    endIconAction="icon icon-delete"
                    actionEndIcon={() => handleDeleteCompanion(companion)}
                  />
                );
              })}
            </DetailList>
          </DetailList>
          <Alert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={t('lbl_companions_header')}
            message={t('lbl_companions_notification')}
            buttons={[
              { text: t('cancel_text'), role: 'cancel' },
              {
                text: t('ok_text'),
                handler: () => createParking(),
              },
            ]}
            mode="ios"
          ></Alert>
          <Alert
            isOpen={showAlertOtherVehicle}
            onDidDismiss={() => setShowAlertOtherVehicle(false)}
            header={t('alert_pk_other_vehicle.header')}
            message={t('alert_pk_other_vehicle.message')}
            buttons={[{ text: t('ok_text'), role: 'cancel' }]}
            mode="ios"
          ></Alert>
        </div>
        <Toast
          isOpen={!!error}
          onDidDismiss={() => dispatch(parkingActions.resetErrorParking())}
          message={error}
          position="bottom"
          type="error"
        />
        {msgState && (
          <Toast
            isOpen={!!msgState}
            message={msgState.description}
            onDidDismiss={handleDismissToast}
            position="bottom"
            type={msgState.type}
          />
        )}
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              onClick={() => history.push('/dashboard/actions')}
              disabled={isLoading}
            >
              {t('lbl_cancel')}
            </Button>
          </div>
          <div>
            {vehAttributes && (
              <Button
                onClick={handleCreateParking}
                disabled={isEmpty(parkings) || isLoading}
              >
                {t('btn_last_minute_parking')}
                {isLoading && (
                  <IonSpinner
                    slot="end"
                    className={styles.color_primary}
                    name="lines-small"
                  />
                )}
              </Button>
            )}
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default LastMinuteParking;
