import { useHistory } from 'react-router-dom';
import { IonPage, IonHeader, IonBackButton, IonContent } from '@ionic/react';
import { TopBar } from '@acciona/ui-ionic-kit';
import { DetailListItem } from '../../../../../components/Base/DetailListItem';
import styles from './styles.module.scss';

type Props = {
  pageTitle: string;
  links: {
    id: number;
    text: string;
    url: string;
    icon: string;
  }[];
};

export const LinksList = (props: Props) => {
  const { pageTitle, links = [] } = props;
  const history = useHistory();
  const goToRoute = (route: string) => {
    history.push(route, {
      parmsRouter: { prevRouterConfirm: false },
    });
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={pageTitle}
        />
      </IonHeader>
      <IonContent fullscreen className={styles.content}>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {links.map(link => (
              <DetailListItem
                button
                key={link.id}
                startIcon={`icon icon-${link.icon}`}
                startIconColor="primary"
                title={link.text}
                titleColor="primary"
                onClick={() => goToRoute(link.url)}
              />
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
