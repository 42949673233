import { IonIcon, IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
import { Props } from './types';

export const BackButton: React.FC<Props> = props => {
  const history = useHistory();
  return (
    <IonButton
      className={styles.button}
      onClick={
        props.onClick
          ? props.onClick
          : () =>
              props.replace
                ? history.replace(props.router)
                : history.push(props.router)
      }
    >
      <IonIcon
        slot="icon-only"
        className={`${styles.icon} icon icon-chevron-left`}
      />
    </IonButton>
  );
};
