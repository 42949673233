import { Button, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonContent,
  IonPage,
  IonFooter,
  IonInput,
  IonButton,
  IonHeader,
  IonLoading,
} from '@ionic/react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { doorAction } from './const';
import HIDService from '../../../_redux/services/hid/hid.services';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../_redux/actions/user.actions';
import { UserStore } from '../../../_redux/models/user.model';
import { DetailList } from '../../../components/Base/DetailList';
import { NotificationsActions } from '../../../_redux/actions';

const CodeInvitation: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const maxLengthCode = 19;
  const { card } = useSelector((store: UserStore) => store.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const invitationCardCode = useSelector((store: UserStore) => {
    if (!store.user.card.active && store.user.invitationCode.included) {
      return store.user.invitationCode.code;
    } else {
      return '';
    }
  });
  const [cardCode, setCardCode] = useState<string>('');
  const mounted = useRef(true);

  const resetLoading = () => {
    if (mounted.current) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (card.active) {
      resetLoading();
      history.replace('/profile/user/card', { fromInvitation: true });
    }
  }, [card.active]);

  useEffect(() => {
    if (invitationCardCode !== '' && !card?.active) {
      setIsLoading(true);
      setCardCode(invitationCardCode);
    }
  }, [invitationCardCode]);

  useEffect(() => {
    if (cardCode !== '') {
      resetLoading();
    }
  }, [cardCode]);

  const handleActivateCode = async () => {
    setIsLoading(true);
    try {
      await HIDService.handleAccessClicked(cardCode);
      const cards = HIDService.card;
      dispatch(userActions.unSetInvitationCode());
      resetLoading();
      history.replace('/dashboard/home', {
        state: doorAction.succes,
        cards: cards,
      });
      dispatch(userActions.setCardRegistered());
      dispatch(userActions.setCardRegisteredFromInvitationCode());
      cards.length > 0 && dispatch(userActions.setCardActive());
      dispatch(NotificationsActions.getNotifications());
    } catch (error) {
      const doorError = error as doorAction;
      if (doorError == doorAction.none) {
        return;
      }
      dispatch(userActions.unSetInvitationCode());
      dispatch(userActions.setCardUnRegistered());
      dispatch(userActions.setCardRegisteredFromInvitationCode());
      resetLoading();
      history.replace('/CodeInvitation/door', {
        state: doorError,
      });
    }
  };

  const handleCancel = () => {
    dispatch(userActions.setCardUnRegistered());
    dispatch(userActions.unSetInvitationCode());
    history.replace('/dashboard/home');
  };

  return (
    <IonPage>
      <IonHeader>
        <TopBar
          primaryActions={
            <IonButton onClick={handleCancel}>{t('cancel_text')}</IonButton>
          }
          title={t('lbl_validate_card')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <DetailList>
            <div className={styles.wellcomeText}>
              <p>{t('welcome_card_code')}</p>
              <p>
                {cardCode.length !== maxLengthCode
                  ? t('enter_card_code')
                  : t('validate_card_code')}
              </p>
            </div>
            <IonInput
              autocapitalize="sentences"
              className={styles.inputCode}
              placeholder={cardCode}
              maxlength={maxLengthCode}
              onIonInput={e => setCardCode(e.detail.value!)}
              value={cardCode}
            />
            <hr className={styles.inputSeparator}></hr>
          </DetailList>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button onClick={handleActivateCode}>{t('activate')}</Button>
          </div>
        </div>
      </IonFooter>
      <IonLoading
        isOpen={isLoading}
        message={`${t('msg_loading')} ..`}
        duration={10000}
        onDidDismiss={resetLoading}
      />
    </IonPage>
  );
};

export default CodeInvitation;
