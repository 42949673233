import dayjs from 'dayjs';
import {
  Raffle,
  ReservationList,
  reservedDay,
} from '../../../../_redux/models/reservation.model';
import { EWeekday } from '../../../../utils/dateTime';

export const getMinAndMaxDatesLastMinute = (raffle: Raffle) => {
  const minimumDate = dayjs().tz().startOf('day').utcOffset(0, true);
  let maximumDate: dayjs.Dayjs;
  const startOfCurrentWeek = dayjs()
    .tz()
    .weekday(EWeekday.sunday)
    .startOf('day');
  const raffleStart = dayjs()
    .tz()
    .weekday(raffle.weekDay)
    ['hour'](parseInt(raffle?.startHour?.slice(0, 2)))
    ['minute'](parseInt(raffle?.startHour?.slice(3)))
    ['second'](0);
  const currentWeekEndHourOfRaffleDay = dayjs()
    .tz()
    .weekday(raffle.weekDay)
    .utcOffset(0, true)
    .endOf('day');
  const nextWeekEndHourOfRaffleDay = dayjs()
    .tz()
    .add(1, 'week')
    .weekday(raffle.weekDay)
    .utcOffset(0, true)
    .endOf('day');

  if (raffle.inProgress) {
    maximumDate = dayjs().tz().utcOffset(0, true);
  } else if (dayjs().tz().isBetween(startOfCurrentWeek, raffleStart)) {
    maximumDate = currentWeekEndHourOfRaffleDay;
  } else {
    maximumDate = nextWeekEndHourOfRaffleDay;
  }
  return { minimumDate, maximumDate };
};

export const getReservationSede = (
  reservation: reservedDay,
  reservationsByOwner: ReservationList[],
): string => {
  const sede = reservationsByOwner.find(r => r.code === reservation.spaceCode);
  return sede ? sede.descriptionSede : '';
};
