import { IonItem } from '@ionic/react';
import { FilterChip } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const FilterChips = ({
  filterTypes,
  allLength,
  favoritesLength,
  historicLength,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <IonItem className={styles.filters} lines="none">
      <div className={styles.gridscroll}>
        {filterTypes.map(item => {
          return item.id === 'all' ? (
            <FilterChip
              key={item.id}
              id={item.id}
              text={t(item.text, {
                value: allLength,
              })}
              checked={item.checked}
              onChange={onChange}
            />
          ) : item.id === 'favorite' && favoritesLength > 0 ? (
            <FilterChip
              key={item.id}
              id={item.id}
              text={t(item.text, {
                value: favoritesLength,
              })}
              checked={item.checked}
              onChange={onChange}
            />
          ) : item.id === 'historic' && historicLength > 0 ? (
            <FilterChip
              key={item.id}
              id={item.id}
              text={t(item.text, {
                value: historicLength,
              })}
              checked={item.checked}
              onChange={onChange}
            />
          ) : null;
        })}
      </div>
    </IonItem>
  );
};
