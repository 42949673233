import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
} from '@ionic/react';
import { Toast, TopBar } from '@acciona/ui-ionic-kit';
import { SheetModal } from '../../../../../components/Base/SheetModal';
import { DetailList } from '../../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../../components/Base/DetailListItem';
import styles from './styles.module.scss';

type Props = {
  shuttleName: string;
  shuttleSchedule: string;
  error: string;
  isLoading: boolean;
};

export const ShuttleDetail = (props: Props) => {
  const { shuttleName, shuttleSchedule, error, isLoading } = props;
  const { t } = useTranslation();
  const contentPage = useRef<any>();

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_more_how_to_get')}
        />
      </IonHeader>
      <IonContent
        className={styles.contentSty}
        scrollEvents={true}
        fullscreen
        ref={contentPage}
        onIonScroll={e => e.detail.scrollTop}
      >
        <div className={styles.imgContentWrapper}>
          <div className={styles.imgContent}>
            <div className={styles.imgOverlay}></div>
          </div>
        </div>
        <div className={styles.fixedSectionContent}></div>
        <SheetModal className={styles.sheetModalPosition}>
          <div className={styles.sheetModalContain}>
            <div className={styles.shuttlesInformation}>
              <div className={styles.listGrid}>
                <DetailList>
                  <DetailListItem
                    lines="none"
                    startIcon="icon icon-bus"
                    startIconColor="primary"
                    title={shuttleName}
                    titleColor="primary"
                    readonly
                  />
                  <DetailListItem
                    lines="none"
                    startIcon="icon icon-last-minute"
                    startIconColor="primary"
                    title={t('lbl_schedules')}
                    titleColor="primary"
                    formatedText={shuttleSchedule}
                    readonly
                  />
                </DetailList>
              </div>
            </div>
          </div>
        </SheetModal>
        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={6000}
        />
      </IonContent>
    </IonPage>
  );
};
