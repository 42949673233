import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../_redux/actions';

const Logout: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(authActions.logout());
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonLoading isOpen={true} message={t('msg_loading')} duration={0} />
      </IonContent>
    </IonPage>
  );
};

export default Logout;
