import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import MedicalServicesList from './MedicalServicesList/MedicalServicesList';
import MedicalServicesInformation from './MedicalServicesInformation/MedicalServicesInformation';

const MedicalServices: React.FC<any> = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/services/medicalservices/medicalServicesList">
          <MedicalServicesList />
        </Route>
        <Route
          exact
          path="/services/medicalservices/medicalservicesInformation"
        >
          <MedicalServicesInformation />
        </Route>
        <Route exact path="/services/medicalservices">
          <Redirect to="/services/medicalservices/medicalServicesList" />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default MedicalServices;
