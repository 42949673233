import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  IonBackButton,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonPage,
} from '@ionic/react';
import { Button, TopBar } from '@acciona/ui-ionic-kit';
import { useHistory, useLocation } from 'react-router';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import Dates from './components/Dates/Dates';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsActions } from '../../../../_redux/actions';

import Personalize from './components/Personalize/Personalize';
import { ParkingStore } from '../../../../_redux/models/parking.model';
import { passengersServices } from '../../../../_redux/services/passengers/passengers.services';
import {
  IPassegerInvitation,
  IPassengerResponse,
} from '../../../../_redux/services/passengers/types';
import {
  EWeekday,
  convertDateToWrittenFormat,
} from '../../../../utils/dateTime';
import { initialData } from './helpers';

import styles from './styles.module.scss';
import {
  usePassengerInvitation,
  useRaffleWard,
} from '../../../../hooks/usePassengerInvitation';
import { passengersActions } from '../../../../_redux/actions/passengers.actions';

dayjs.extend(weekday);

interface IProps {
  detail?: boolean;
}

const PassengerInvitationDetail = ({ detail = false }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const raffleWard = useRaffleWard();
  const invitationWard = usePassengerInvitation();

  const { weekDay: raffleWeekDay } = useSelector((state: ParkingStore) => {
    return state.parking?.raffle || { weekDay: EWeekday.friday };
  });

  const [isLoading, setIsLoading] = useState(true);

  const {
    driverId,
    notificationDate,
    isFromConfirmation,
    isFromPushNotification,
  } = useLocation().state as {
    driverId: string;
    isFromConfirmation?: boolean;
    isFromPushNotification?: boolean;
    notificationDate?: string;
  };
  const [invitationData, setInvitationData] =
    useState<IPassegerInvitation>(initialData);

  const [isSingleDate, setIsSingleDate] = useState(true);

  const [input, setInput] = useState<IPassengerResponse[]>([]);
  const [showPersonalize, setShowPersonalize] = useState(false);

  const showDates = useMemo(
    () =>
      invitationData.dates.filter(d => {
        return detail ? d.accepted : true;
      }),
    [invitationData.dates],
  );

  const updateInvitationData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const resp = await passengersServices.getPassengerInvitationByDriver(
        driverId,
      );
      setIsLoading(false);
      resp.dates = resp.dates.sort((a, b) => {
        return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
      });
      setInvitationData(resp);
    } catch (err) {
      setIsLoading(false);
      dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
      history.replace('/notifications');
    }
  };

  const handleReject = async (): Promise<void> => {
    const ward = await raffleWard(notificationDate, true);
    if (!ward) {
      return;
    }
    if (isSingleDate) {
      const dateToReject =
        invitationData.dates.find(d => !d.accepted && d.isUserFree)?.date ?? '';

      const message = t('passenger_single_invitation_reject', {
        driver: invitationData.driver,
        date: convertDateToWrittenFormat(dateToReject),
      });
      dispatch(
        NotificationsActions.setGenericAlertWithButtons(
          t('reject_invitation'),
          message,
          [
            {
              text: t('cancel_text'),
              role: 'cancel',
            },
            {
              text: t('lbl_affirmative'),
              role: 'confirm',
              handler: handleConfirmReject,
            },
          ],
        ),
      );
    } else {
      setShowPersonalize(true);
    }
  };

  const handleAccept = async (): Promise<void> => {
    const ward = await raffleWard(notificationDate, true);
    if (!ward) {
      return;
    }
    if (isSingleDate) {
      const dateToAccept =
        invitationData.dates.find(d => !d.accepted && d.isUserFree)?.date ?? '';

      const message = t('passenger_single_invitation_accept', {
        driver: invitationData.driver,
        date: convertDateToWrittenFormat(dateToAccept),
      });
      dispatch(
        NotificationsActions.setGenericAlertWithButtons(
          t('accept_invitation'),
          message,
          [
            {
              text: t('cancel_text'),
              role: 'cancel',
            },
            {
              text: t('lbl_affirmative'),
              role: 'confirm',
              handler: handleConfirmAccept,
            },
          ],
        ),
      );
    } else {
      handleConfirmAccept();
    }
  };

  const redirectToDetail = (): void => {
    dispatch(
      NotificationsActions.setGenericSuccessToast(
        t(
          isSingleDate
            ? 'successfully_accepted_passenger_invitation'
            : 'successfully_accepted_all_passenger_invitation',
        ),
      ),
    );
    history.replace('/parking/passengerInvitationDetail', {
      driverId,
      isFromConfirmation: true,
    });
  };

  const handleConfirmAccept = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const resp = await passengersServices.answerPassengerInvitation(
        input.map(r => {
          return {
            id: r.id,
            status: true,
          };
        }),
      );
      setIsLoading(false);
      if (resp?.status === 200) {
        return redirectToDetail();
      } else {
        dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
    }
  };

  const handleConfirmCustom = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const ward = await raffleWard(notificationDate, true);
      if (!ward) {
        setIsLoading(false);
        return;
      }
      const resp = await passengersServices.answerPassengerInvitation(input);
      setIsLoading(false);
      if (resp.status === 200) {
        const text = input.every(i => i.status)
          ? 'successfully_accepted_all_passenger_invitation'
          : 'successfully_accepted_some_passenger_invitation';
        dispatch(NotificationsActions.setGenericSuccessToast(await t(text)));
        history.replace('/parking/passengerInvitationDetail', {
          driverId,
          isFromConfirmation: true,
        });
        return;
      } else {
        dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
    }
  };

  const handleConfirmReject = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const ward = await raffleWard(notificationDate, true);
      if (!ward) {
        setIsLoading(false);
        return;
      }
      const resp = await passengersServices.answerPassengerInvitation(
        input.map(r => {
          return {
            id: r.id,
            status: false,
          };
        }),
      );
      setIsLoading(false);
      if (resp.status === 200) {
        dispatch(
          NotificationsActions.setGenericSuccessToast(
            await t(
              isSingleDate
                ? 'successfully_rejected_passenger_invitation'
                : 'successfully_rejected_all_passenger_invitation',
            ),
          ),
        );
        history.replace('/notifications');
      } else {
        dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(NotificationsActions.setGenericErrorToast(t('error_default')));
    }
  };

  const handleInput = (id: string, value: boolean): void => {
    setInput(oldState => {
      // Do not uncheck the clicked
      // when all are checked
      if (oldState.every(i => i.status) && id !== 'all') {
        return oldState.map(i => {
          if (i.id === id) {
            return { ...i, status: true };
          }
          return { ...i, status: false };
        });
      }
      // toggle the clicked
      // and check all if all is clicked
      return oldState.map(i => {
        if (i.id === id || id === 'all') {
          return { ...i, status: value };
        }
        return i;
      });
    });
  };

  const handleDeleteInvitation = () => {
    dispatch(
      NotificationsActions.setGenericAlertWithButtons(
        t('header_delete_request'),
        t('msg_delete_passenger_invitation'),
        [
          {
            text: t('lbl_no'),
            role: 'cancel',
          },
          {
            text: t('lbl_affirmative'),
            role: 'confirm',
            handler: handleConfirmDeleteInvitation,
          },
        ],
      ),
    );
  };

  const handleConfirmDeleteInvitation = () => {
    dispatch(
      passengersActions.deleteAcceptedPassengerInvitation(
        invitationData.dates.map(d => d.id),
      ),
    );
  };

  const handleClose = (): void => {
    history.push('/dashboard/reservations');
  };

  useEffect(() => {
    updateInvitationData();
  }, [driverId]);

  useEffect(() => {
    detail
      ? setIsSingleDate(
          invitationData.dates.filter(d => d.accepted).length === 1,
        )
      : setIsSingleDate(
          invitationData.dates.filter(d => !d.accepted && d.isUserFree)
            .length === 1,
        );
  }, [invitationData.dates]);

  useEffect(() => {
    if (!invitationData) return;
    const datesToInput = invitationData.dates
      .filter(d => {
        return !d.accepted && d.isUserFree;
      })
      .map(d => {
        return {
          id: '' + d.id,
          status: false,
          name: d.date,
        };
      });
    setInput(datesToInput);
  }, [invitationData.dates]);

  useEffect(() => {
    // Execute ward only if redirections comes from
    // push notification and we are not showing the detail
    if (detail) return;
    if (!isFromPushNotification) return;
    const checkInvitationStatus = async () => {
      setIsLoading(true);
      const ward = await invitationWard(driverId, notificationDate, false);
      setIsLoading(false);
      if (!ward) {
        history.replace('/');
      }
    };

    checkInvitationStatus();
  }, [driverId, isFromPushNotification]);

  return (
    <>
      <IonLoading isOpen={isLoading} message={t('msg_loading')} duration={0} />
      <Personalize
        dates={invitationData.dates.filter(d => !d.accepted)}
        isOpen={showPersonalize}
        handleClose={() => setShowPersonalize(false)}
        driver={invitationData.driver || ''}
        input={input}
        handleInput={handleInput}
        isLoading={isLoading}
        handleConfirmCustom={handleConfirmCustom}
        handleConfirmReject={handleConfirmReject}
      />
      <IonPage>
        <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
          <TopBar
            primaryActions={
              isFromConfirmation ? (
                <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
              ) : (
                <IonBackButton
                  defaultHref="/dashboard/reservations"
                  text=""
                  className={`${styles.topBarIcon} icon icon-chevron-left`}
                />
              )
            }
            endActions={
              detail && (
                <IonButton onClick={() => handleDeleteInvitation()}>
                  {t('delete_text')}
                </IonButton>
              )
            }
            title={detail ? t('request_detail') : t('invitation_detail')}
          />
        </IonHeader>
        <IonContent fullscreen>
          <DetailCard
            name={t('passenger')}
            subName=""
            type="passengerInvitation"
            icon="icon icon-occupation"
            pending
          />
          <div className={`${styles.gridWeb} ${styles.passengerContainer}`}>
            <div className={`${styles.listGrid} ${styles.marginTop}`}>
              <DetailListItem
                className={`${styles.itemList} ${styles.dates}`}
                startIcon="icon icon-calendar"
                startIconColor="primary"
                title={
                  showDates.length === 1
                    ? dayjs(showDates[0].date).format('dddd DD MMM')
                    : t('lbl_multiple_days')
                }
                description={
                  showDates.length > 1 && (
                    <Dates dates={showDates} detail={detail} />
                  )
                }
              />
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-explore"
                startIconColor="primary"
                title={t('lbl_head_office')}
                description={invitationData.sede?.descriptionSede || ''}
              />
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-car"
                startIconColor="primary"
                title={t('lbl_driver')}
                description={invitationData.driver}
              />
              <div className={styles.separatorMap} />
              <div className={styles.marginTop}>
                <DetailListItem
                  className={styles.itemList}
                  startIcon="icon icon-campus"
                  title={t('lbl_pending_assing_location')}
                  description={
                    raffleWeekDay !== null
                      ? t('lbl_info_raffle_parking', {
                          date: dayjs()
                            .weekday(raffleWeekDay)
                            .tz()
                            .format(`dddd D [${t('lbl_of_date')}] MMMM`),
                        })
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        </IonContent>
        {!detail && (
          <IonFooter>
            <div className={styles.btnGroupWeb}>
              <div>
                <Button
                  color="lighter"
                  onClick={handleReject}
                  disabled={isLoading}
                >
                  {t(
                    isSingleDate
                      ? 'reject_invitation'
                      : 'lbl_customize_passenger_invitation',
                  )}
                </Button>
              </div>
              <div>
                <Button disabled={isLoading} onClick={handleAccept}>
                  {t(
                    isSingleDate
                      ? 'accept_invitation'
                      : 'accept_all_invitation',
                  )}
                </Button>
              </div>
            </div>
          </IonFooter>
        )}
      </IonPage>
    </>
  );
};

export default PassengerInvitationDetail;
