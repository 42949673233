import { isPlatform } from '@ionic/react';
import {
  AppAction,
  Locale,
  localSettings,
  SystemSettings,
  Visualization,
  WelcomeText,
  DashboardImage,
  CookiePreferences,
} from '../models/app.model';
import { systemServices } from '../services';
import { cookiesServices } from '../services/cookies/cookies.services';
import { userServices } from '../services/user/user.services';
import { appTypesEnum } from '../types';
import { userActions } from './user.actions';
import {
  FALLBACK_DASHBOARD_IMAGE,
  FALLBACK_WELCOME_TEXT,
} from '../../utils/constants';

const appRequest = (): AppAction => {
  return {
    type: appTypesEnum.REQUEST,
  };
};

const appStateReset = (): AppAction => {
  return {
    type: appTypesEnum.RESET_APP_STATE,
  };
};

const setOfflineServices = (offline: boolean): AppAction => {
  return {
    type: appTypesEnum.SET_OFFLINE_SERVICES,
    offlineServices: offline,
  };
};

const setOfflineAlertHasBeenShown = (): AppAction => {
  return {
    type: appTypesEnum.SET_OFFLINE_ALERT_HAS_BEEN_SHOWN,
  };
};

const setOfflineNetwork = (offline: boolean): AppAction => {
  return {
    type: appTypesEnum.SET_OFFLINE_NETWORK,
    offlineNetwork: offline,
  };
};

const setOfflineToastIsOpen = (isOpen: boolean): AppAction => {
  return {
    type: appTypesEnum.SET_OFFLINE_TOAST_IS_OPEN,
    offlineToastIsOpen: isOpen,
  };
};

const setNoReservationPermissionToastIsOpen = (isOpen: boolean): AppAction => {
  return {
    type: appTypesEnum.SET_NO_RESERVATION_PERMISSION_TOAST_IS_OPEN,
    noReservationPermissionToastIsOpen: isOpen,
  };
};

const fetchAppFailure = (error: string): AppAction => {
  return {
    type: appTypesEnum.FAILURE_REQUEST,
    error: error,
  };
};

const setAppTheme = (appTheme: string): any => {
  return { type: appTypesEnum.SET_THEME, theme: appTheme };
};

const setGlobalSettings = (systemSettings: SystemSettings): any => {
  return {
    type: appTypesEnum.SET_GLOBAL_SETTINGS,
    globalSettings: systemSettings,
  };
};

const setWelcomeText = (welcomeData: WelcomeText): any => {
  return {
    type: appTypesEnum.SET_WELCOME_TEXT,
    welcomeText: welcomeData,
  };
};

const setDashboardImage = (dashboardImage: DashboardImage): any => {
  return {
    type: appTypesEnum.SET_DASHBOARD_IMAGE,
    dashboardImage: dashboardImage,
  };
};

const setLocalSettings = (localSettings: localSettings): any => {
  return {
    type: appTypesEnum.SET_LOCAL_SETTINGS,
    localSettings: localSettings,
  };
};

const setCookiePreferences = (cookiePreferences: CookiePreferences): any => {
  return {
    type: appTypesEnum.SET_COOKIE_PREFERENCES,
    cookiePreferences: cookiePreferences,
  };
};

const getAppSettings = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(appRequest());

      const theme = localStorage.getItem('appTheme');
      if (theme) dispatch(setAppTheme(theme));

      const systemSettings: SystemSettings =
        await systemServices.getSystemSettings();
      dispatch(setGlobalSettings(systemSettings));
    } catch (e) {
      dispatch(fetchAppFailure(e.message));
      throw e;
    }
  };
};

const getLocalSettings = () => {
  const localLanguage = localStorage.getItem('i18nextLng') as Locale;
  let viewReservations = localStorage.getItem(
    'viewReservations',
  ) as Visualization;

  const localStoredSedeFilterReservations = localStorage.getItem(
    'sedeFilterReservations',
  );
  const sedeFilterReservations = localStoredSedeFilterReservations
    ? JSON.parse(localStoredSedeFilterReservations)
    : [];

  if (viewReservations === null) {
    localStorage.setItem('viewReservations', 'perDay');
    viewReservations = 'perDay';
  }

  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(
        setLocalSettings({
          localLanguage,
          viewReservations,
          sedeFilterReservations,
        }),
      );
    } catch (e) {
      dispatch(fetchAppFailure(e.message));
    }
  };
};

const setViewReservations = (view: Visualization) => {
  localStorage.setItem('viewReservations', view);

  return async (dispatch: any, state: any): Promise<any> => {
    try {
      dispatch(
        setLocalSettings({
          ...state().app.localSettings,
          viewReservations: view,
        }),
      );
    } catch (e) {
      dispatch(fetchAppFailure(e.message));
    }
  };
};

const setSedeFilterReservations = (sedesList: string[]) => {
  localStorage.setItem('sedeFilterReservations', JSON.stringify(sedesList));

  return async (dispatch: any, state: any): Promise<any> => {
    try {
      dispatch(
        setLocalSettings({
          ...state().app.localSettings,
          sedeFilterReservations: sedesList,
        }),
      );
    } catch (e) {
      dispatch(fetchAppFailure(e.message));
    }
  };
};

const setLanguage = (language: Locale) => {
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      await userServices.setUserLanguage(language);
      dispatch(
        setLocalSettings({
          ...state().app.localSettings,
          localLanguage: language,
        }),
      );
      dispatch(userActions.setReservationPolicies(null));
      dispatch(userActions.setPolicies(null));
    } catch (e) {
      dispatch(fetchAppFailure(e.message));
    }
  };
};

const setCacheWelcomeSetup = (dispatch, offlineNetwork: boolean = false) => {
  const cachedWelcomeText =
    JSON.parse(localStorage.getItem('welcomeText')) || FALLBACK_WELCOME_TEXT;
  const cachedDashboardImage =
    JSON.parse(localStorage.getItem('dashboardImage') || null) ??
    FALLBACK_DASHBOARD_IMAGE;

  dispatch(setWelcomeText(cachedWelcomeText));
  dispatch(
    setDashboardImage(
      offlineNetwork ? FALLBACK_DASHBOARD_IMAGE : cachedDashboardImage,
    ),
  );
};

const getWelcomeSetup = () => {
  const isPlatformCapacitor = isPlatform('capacitor');
  return async (dispatch: any, state: any): Promise<any> => {
    try {
      dispatch(appRequest());
      const dashboardImage = await systemServices.getDashboardImage(
        isPlatformCapacitor,
      );
      dispatch(setDashboardImage(dashboardImage));
      const welcomeText = await systemServices.getWelcomeText(
        isPlatformCapacitor,
      );
      dispatch(setWelcomeText(welcomeText));
      localStorage.setItem('welcomeText', JSON.stringify(welcomeText));
      localStorage.setItem('dashboardImage', JSON.stringify(dashboardImage));
    } catch (e) {
      setCacheWelcomeSetup(dispatch, state().app.offlineNetwork);
      dispatch(fetchAppFailure(e.message));
    }
  };
};

const loadCookiePreferences = (cookiesAuth: boolean) => {
  return async (dispatch: any): Promise<any> => {
    try {
      cookiesServices.saveCookiePreference(cookiesAuth);
      dispatch(setCookiePreferences({ ga_: cookiesAuth }));
    } catch (e) {
      dispatch(fetchAppFailure(e.message));
    }
  };
};

export const appActions = {
  setOfflineServices,
  setOfflineNetwork,
  setOfflineToastIsOpen,
  setOfflineAlertHasBeenShown,
  setAppTheme,
  getAppSettings,
  getLocalSettings,
  setViewReservations,
  setSedeFilterReservations,
  setLanguage,
  getWelcomeSetup,
  loadCookiePreferences,
  setCookiePreferences,
  setLocalSettings,
  appStateReset,
  setNoReservationPermissionToastIsOpen,
};
