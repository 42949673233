import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import Calendar from './CalendarLastMinute/Calendar';
import LastMinute from './LastMinute/Lastminute';
import Confirmation from '../../Shared/Success/Confirmation';
import NotAvailable from '../../Shared/NotAvailable/NotAvailable';
import MapSelectorLastMinute from './MapSelectorLastMinute/MapSelectorLastMinute';
import CalendarRelease from './CalendarRelease/CalendarRelease';
import CanceledDesk from './CanceledDesk/CanceledDesk';
import ModifyRelease from './ModifyRelease/ModifyRelease';
import WsModifyDatesSelector from './WsModifyDatesSelector/WsModifyDatesSelector';

const Workstation: React.FC<any> = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/workstation/calendar">
          <Calendar />
        </Route>
        <Route exact path="/workstation/lastminute">
          <LastMinute />
        </Route>
        <Route exact path="/workstation/confirmation">
          <Confirmation />
        </Route>
        <Route exact path="/workstation/modifyDatesSelector">
          <WsModifyDatesSelector />
        </Route>
        <Route exact path="/workstation/mapselectorlastminute">
          <MapSelectorLastMinute />
        </Route>
        <Route exact path="/workstation/noAvailable">
          <NotAvailable />
        </Route>
        <Route exact path="/workstation">
          <Redirect to="/workstation/calendar" />
        </Route>
        <Route exact path="/workstation/calendarRelease">
          <CalendarRelease />
        </Route>
        <Route exact path="/workstation/CandeledDesk">
          <CanceledDesk />
        </Route>
        <Route exact path="/workstation/modifyRelease">
          <ModifyRelease />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Workstation;
