import { useEffect, useMemo, useRef, useState } from 'react';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Snackbar, TopBar } from '@acciona/ui-ionic-kit';
import {
  ParkingReleaseItem,
  ParkingReservation,
} from '../../../../_redux/models/parking.model';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { reservationActions } from '../../../../_redux/actions';
import { history } from '../../../../_helpers/history';
import { isEmpty } from '../../../../utils/functions';
import { ModifyParkingReservationItem } from '../ModifyParkingReservationItem/ModifyParkingReservationItem';
import {
  checkSaveIsDisabled,
  getDeletedReservationsIds,
  updateReservationsToModify,
} from './helpers';
import styles from './styles.module.scss';

const ModifyParkingReservation: React.FC = () => {
  const { t } = useTranslation();
  const contentPage = useRef<any>();
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const [initialReservations, setInitialReservations] = useState<
    ParkingReservation[]
  >([]);
  const [reservationsToModify, setReservationsToModify] = useState<any[]>([]);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const { reservedParking } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  useEffect(() => {
    if (isMounted.current) {
      setInitialReservations(reservedParking.reservations);
    }
    return () => {
      isMounted.current = false;
    };
  }, [reservedParking.reservations]);

  const saveIsDisabled = useMemo(
    () => checkSaveIsDisabled(reservationsToModify),
    [reservationsToModify],
  );

  const deletedReservationsIds = useMemo(
    () => getDeletedReservationsIds(reservationsToModify),
    [reservationsToModify],
  );

  const onModifyHandler = (item: ParkingReleaseItem) => {
    setReservationsToModify(prev => updateReservationsToModify(prev, item));
  };

  const handleConfirmModify = () => {
    setShowConfirmAlert(true);
  };

  const confirmModify = () => {
    dispatch(reservationActions.modifyParkingReservation(reservationsToModify));
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleCloseAlert = () => {
    if (isMounted.current) {
      setShowConfirmAlert(false);
    }
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        className={`ion-no-border ${styles.backgroundLight} ${styles.toolbarGrid}`}
      >
        <TopBar
          primaryActions={
            <IonBackButton
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_modify_schedule')}
        />

        <Snackbar
          type="info"
          text={t('lbl_date_selected', {
            number: !isEmpty(initialReservations)
              ? initialReservations.length
              : 0,
            multiple:
              !isEmpty(initialReservations) && initialReservations.length > 1
                ? 's'
                : '',
          })}
          align="center"
        />
      </IonHeader>
      <IonContent ref={contentPage}>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {!isEmpty(initialReservations) &&
              initialReservations
                .filter(
                  reservation =>
                    !deletedReservationsIds.includes(reservation.reservationId),
                )
                .map(item => (
                  <ModifyParkingReservationItem
                    item={item}
                    key={item.reservationId}
                    onModify={onModifyHandler}
                    canReleaseBySchedule
                  />
                ))}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button color="lighter" onClick={handleCancel}>
              {t('cancel_text')}
            </Button>
          </div>
          <div>
            <Button onClick={handleConfirmModify} disabled={saveIsDisabled}>
              {t('lbl_save_changes')}
            </Button>
          </div>
        </div>
      </IonFooter>

      <Alert
        isOpen={showConfirmAlert}
        onDidDismiss={handleCloseAlert}
        header={t('header_modify_parking_reserve')}
        message={t('msg_modify_parking_reserve')}
        buttons={[
          { text: 'No', role: 'cancel', handler: handleCancel },
          {
            text: t('lbl_affirmative'),
            handler: confirmModify,
          },
        ]}
        mode="ios"
      />
    </IonPage>
  );
};

export default ModifyParkingReservation;
