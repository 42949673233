import { Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import Detail from './Detail/Detail';
import PlainErrorCommunications from '../../Shared/PlainErrorCommunications/PlainErrorCommunications';
import ExternalVideoPlayer from './ExternalVideoPlayer/ExternalVideoPlayer';

const NewsD: React.FC<any> = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/news/detail">
          <Detail />
        </Route>
        <Route exact path="/news/NoPermission">
          <PlainErrorCommunications />
        </Route>
        <Route exact path="/news/video/:videoId">
          <ExternalVideoPlayer />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default NewsD;
