import React from 'react';
import styles from './styles.module.scss';
import { SheetModalProps } from './types';

export const SheetModal: React.FC<SheetModalProps> = props => {
  return (
    <div
      id={props.id}
      className={`${styles.scrollSectionContent} ${props.className}`}
    >
      {props.lineScroll && <div className={styles.lineScrollSty} />}
      {props.children}
    </div>
  );
};
