export enum NewsTypesEnum {
  REQUEST = 'FETCH_NEWS_REQUEST',
  REQUEST_DETAIL = 'REQUEST_DETAIL',
  SET_NEWS = 'SET_NEWS',
  SET_NEWSLIST = 'SET_NEWSLIST',
  FAILURE_NEWS = 'FAILURE_NEWS',
  SET_LIKE_NEWS = 'SET_LIKE_NEWS',
  SET_IMG_NEWS = 'SET_IMG_NEWS',
  SET_COMMENT_NEWS = 'SET_COMMENT_NEWS',
  SET_SESSION_ID = 'SET_SESSION_ID',
  SET_NEWSLIST_CARROUSEL = 'SET_NEWSLIST_CARROUSEL',
  SET_NEWSLIST_OTHER = 'SET_NEWSLIST_OTHER',
  SET_NEWSLIST_HIGHLIGHTS = 'SET_NEWSLIST_HIGHLIGHTS',
  SET_NEWSLIST_HOME_CARROUSEL = 'SET_NEWSLIST_HOME_CARROUSEL',
  SET_NEWSLIST_HOME_OTHER = 'SET_NEWSLIST_HOME_OTHER',
  SET_NEWSLIST_SEARCH = 'SET_NEWSLIST_SEARCH',
  SET_MORE_NEWS = 'SET_MORE_NEWS',
  SET_TAB_POSITION = 'SET_TAB_POSITION',
  RESET_NEWS = 'RESET_NEWS_DATA',
  SET_NOTICE = 'SET_NOTICE',
  SET_SOCIAL_NEWS = 'SET_SOCIAL_NEWS',
  SET_CATEGORIES_NEWS = 'SET_CATEGORIES_NEWS',
  SET_NEWS_CATEGORIES = 'SET_NEWS_CATEGORIES',
  SET_LOADED_CATEGORIES = 'SET_LOADING_CATEGORIES',
}
