import { IonLabel } from '@ionic/react';
import { Icon, Item } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _ from 'lodash';
import { actionTypes, classes } from './IncidentTypes';
import styles from './styles.module.scss';

export const IncidentItem = ({ filterType, incident }) => {
  const { t } = useTranslation();

  const colorClass =
    filterType.find(
      filter => filter.categoryId === incident?.incidence?.categoryId,
    )?.color ?? '';
  const iconClass =
    filterType.find(
      filter => filter.categoryId === incident.incidence.categoryId,
    )?.icon ?? '';

  return (
    <Item lines="full">
      <div slot="start" className={`${styles.divInfo} ${classes[colorClass]}`}>
        <Icon className={`icon icon-${iconClass} ${styles.iconFont}`} />
      </div>
      <IonLabel className={styles.label}>
        <h2>
          <b>{incident.incidence.name}</b>
        </h2>
        <h3>{t(actionTypes[incident.actionType])}</h3>
        <h3 className={styles.date}>
          {_.capitalize(
            dayjs(incident.date)
              .utcOffset(0, true)
              .format('ddd DD MMM YYYY, HH:mm')
              .toUpperCase(),
          )}
        </h3>
      </IonLabel>
    </Item>
  );
};
