import type {
  SearchFilter,
  Workroom,
  WorkroomAction,
  WorkRoomType,
  Building,
  WaitingList,
  Host,
  WorkroomDetail,
  WorkroomDetailReservation,
} from '../models/workroom.model';
import { workroomServices } from '../services';
import { workroomTypesEnum } from '../types/workroom.types.enum';
import { history } from '../../_helpers/history';
import { reservationActions } from './reservations.actions';
import { isEmpty } from '../../utils/functions';
import i18n from '../../i18nextConf';
import dayjs from 'dayjs';

const workroomRequest = (): WorkroomAction => {
  return {
    type: workroomTypesEnum.REQUEST,
  };
};

const resetLoading = (): WorkroomAction => {
  return {
    type: workroomTypesEnum.RESET_LOADING,
  };
};

const fetchWorkroomFailure = (error: string): WorkroomAction => {
  return {
    type: workroomTypesEnum.FAILURE_WORKROOM,
    error: error,
  };
};

const setWorkrooms = (workrooms: Workroom[]): WorkroomAction => ({
  type: workroomTypesEnum.SET_WORKROOMS,
  workrooms: workrooms,
});

const setWorkroom = (workroom: Workroom): WorkroomAction => ({
  type: workroomTypesEnum.SET_WORKROOM,
  workroomSelected: workroom,
});

const resetErrorWorkroom = (): WorkroomAction => ({
  type: workroomTypesEnum.RESET_ERROR,
});

const setWorkroomTypes = (workroomTypes: WorkRoomType[]): WorkroomAction => ({
  type: workroomTypesEnum.SET_WORKROOMTYPES,
  workroomTypes: workroomTypes,
});

const setBuildings = (buildings: Building[]): WorkroomAction => ({
  type: workroomTypesEnum.SET_BUILDINGS,
  buildings: buildings,
});

const setSearchFilters = (searchFilters: SearchFilter): WorkroomAction => ({
  type: workroomTypesEnum.SET_SEARCH_FILTERS,
  searchFilters: searchFilters,
});
const setReservNow = (reservNow: boolean): WorkroomAction => ({
  type: workroomTypesEnum.SET_RESERVNOW,
  reservNow: reservNow,
});

const resetSearchFilters = (): WorkroomAction => ({
  type: workroomTypesEnum.RESET_SEARCH_FILTERS,
});

const setWaitingListWr = (waitingListWr: WaitingList): WorkroomAction => ({
  type: workroomTypesEnum.SET_WAITING_LIST,
  waitingListWr: waitingListWr,
});

const setHosts = (hosts: Host[]): WorkroomAction => ({
  type: workroomTypesEnum.SET_HOSTS,
  hosts: hosts,
});

const setPreselectedHost = (host: Host): WorkroomAction => ({
  type: workroomTypesEnum.SET_PRESELECTED_HOST,
  preselectedHost: host,
});

const setWorkroomDetailReservation = (
  workroomDetailReservation: WorkroomDetailReservation,
): WorkroomAction => ({
  type: workroomTypesEnum.SET_WORKROOM_DETAIL_RESERVATION,
  workroomDetailReservation,
});

const setWorkroomDetail = (workroomDetail: WorkroomDetail): WorkroomAction => ({
  type: workroomTypesEnum.SET_WORKROOM_DETAIL,
  workroomDetail: workroomDetail,
});

const deletePreselectedHost = (): WorkroomAction => ({
  type: workroomTypesEnum.DELETE_PRESELECTED_HOST,
});

const getTypeRooms = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const typesRoom = await workroomServices.getTypeRooms();
      dispatch(setWorkroomTypes(typesRoom));
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

const getBuildings = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const buildings = await workroomServices.getListBuildings();
      dispatch(setBuildings(buildings));
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

const getAvailableWorkrooms = (
  reservNow: boolean,
  searchFilters: SearchFilter,
) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(workroomRequest());
    dispatch(setReservNow(reservNow));
    dispatch(setSearchFilters(searchFilters));
    try {
      dayjs.tz.setDefault(state().app.globalSettings.campusTimeZone);
      const start = dayjs(searchFilters.startHour)
        .tz()
        .utcOffset(0, true)
        .toISOString();

      const startDateTime = dayjs(
        `${dayjs(searchFilters.date).utc().format('YYYY-MM-DD')} ${dayjs(start)
          .utc()
          .format('HH')}:${dayjs(start).utc().format('mm')}:00`,
      )
        .utcOffset(0, true)
        .toISOString();

      const end = dayjs(searchFilters.finalHour)
        .tz()
        .utcOffset(0, true)
        .toISOString();
      const endDateTime = dayjs(
        `${dayjs(searchFilters.date).utc().format('YYYY-MM-DD')} ${dayjs(end)
          .utc()
          .format('HH')}:${dayjs(end).utc().format('mm')}:00`,
      )
        .utcOffset(0, true)
        .toISOString();

      const formatDate = date => {
        return dayjs(date)
          .tz()
          .set('hour', dayjs(date).utc(false).get('hour'))
          .set('day', dayjs(date).utc(false).get('day'))
          .set('month', dayjs(date).utc(false).get('month'))
          .set('year', dayjs(date).utc(false).get('year'))
          .toISOString();
      };

      searchFilters = {
        ...searchFilters,
        date: formatDate(startDateTime),
        startHour: formatDate(startDateTime),
        finalHour: formatDate(endDateTime),
      };

      const workrooms: Workroom[] =
        await workroomServices.fetchAvailableWorkrooms(searchFilters);

      if (!isEmpty(workrooms)) {
        dispatch(setWorkrooms(workrooms));
        //TODO: get filters data from redux store, not from history (is not neccessary)
        history.push('/workroom/list', {
          date: searchFilters.date,
          from: searchFilters.startHour,
          to: searchFilters.finalHour,
        });
      } else {
        dispatch(resetLoading());
        history.push('/workroom/noAvailable', {
          date: searchFilters.date,
          from: searchFilters.startHour,
          to: searchFilters.finalHour,
        });
      }
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
      dispatch(setReservNow(false));
    }
  };
};

const joinWaitingList = (waitingListFilters, notificationLimit) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const waitingWorkroom = await workroomServices.joinWaitingList(
        waitingListFilters,
        notificationLimit,
      );

      dispatch(setWaitingListWr(waitingWorkroom));

      history.push('/workroom/confirmation', {
        title: 'successful_waitingList',
        msg: 'waitingList_info',
        router: '/spaces/workroom/waitingdetail',
        parmsRouter: { prevRouterConfirm: true },
      });
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

const reserveWorkroom = (spaceId, date, startHour, endHour, hostUserId) => {
  return async (dispatch: any, state: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const newWorkroom = await workroomServices.reserveWorkroom(
        spaceId,
        date,
        startHour,
        endHour,
        hostUserId,
      );

      dispatch(reservationActions.setReservedWorkroom(newWorkroom));
      dispatch(deletePreselectedHost());
      dispatch(reservationActions.addReservation(newWorkroom));
      dispatch(setReservNow(false));
      history.replace('/workroom/confirmation', {
        title: 'successful_reservation',
        msg: i18n.t('reservation_info', {
          time: state().app.globalSettings.maxTimeCheckInRoom,
        }),
        router: '/spaces/workroom/reservationdetail',
        parmsRouter: { prevRouterConfirm: true },
      });
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

const checkInWorkroom = (
  spaceIdQr: number,
  reservationId: number,
  owner: boolean,
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      owner
        ? await workroomServices.checkinWorkroom(spaceIdQr, reservationId)
        : await workroomServices.checkinWorkroomQR(spaceIdQr, reservationId);
      reservationActions.getReservationWorkroom(reservationId);
      history.replace('/workstation/confirmation', {
        title: 'msg_checkin_success',
        router: '/dashboard/reservations',
      });
    } catch (e) {
      dispatch(
        reservationActions.msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      history.push('/spaces/workroom/reservationdetail');
    }
  };
};

const checkOutWorkroom = (reservationId: number) => {
  return async (dispatch: any): Promise<any> => {
    try {
      await workroomServices.checkOutWorkroom(reservationId);
      reservationActions.getReservationWorkroom(reservationId);

      history.replace('/workstation/confirmation', {
        msg: 'msg_checkout_success',
        router: '/dashboard/reservations',
      });
    } catch (e) {
      dispatch(
        reservationActions.msgReservation({
          type: 'error',
          description: e.message,
        }),
      );
      history.push('/dashboard/reservations');
    }
  };
};

const getAvailableHosts = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const hosts = await workroomServices.getAvailableHosts();
      dispatch(setHosts(hosts));
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

const getWorkroomDetail = (code: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const workroomDetail = await workroomServices.getWorkroomDetail(code);
      dispatch(setWorkroomDetail(workroomDetail));
      const workroomDetailReservation =
        await workroomServices.getWorkroomDetailReservation(workroomDetail.id);
      dispatch(setWorkroomDetailReservation(workroomDetailReservation));

      history.push('/spaces/workroom/detail');
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

const getSearchWorkroomDetail = (code: string) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const workroomDetail = await workroomServices.getWorkroomDetail(code);
      dispatch(setWorkroomDetail(workroomDetail));
      history.push('/spaces/workroom/workroomdetail', {
        search: true,
        code: code,
      });
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

const getReservableWorkroomDetail = (wr: Workroom) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(workroomRequest());
    try {
      const workroomDetail = await workroomServices.getReservableWorkroomDetail(
        wr.id,
      );
      dispatch(
        setWorkroom({
          ...workroomDetail,
          preReservationDate: wr.preReservationDate,
          imageLink: wr.imageLink,
        }),
      );

      history.push('/workroom/prereservationdetail');
    } catch (e) {
      dispatch(fetchWorkroomFailure(e.message));
    }
  };
};

export const workroomActions = {
  getAvailableWorkrooms,
  reserveWorkroom,
  resetErrorWorkroom,
  checkInWorkroom,
  checkOutWorkroom,
  getTypeRooms,
  getBuildings,
  setWorkroom,
  setWaitingListWr,
  joinWaitingList,
  getAvailableHosts,
  setPreselectedHost,
  deletePreselectedHost,
  fetchWorkroomFailure,
  resetSearchFilters,
  getWorkroomDetail,
  getReservableWorkroomDetail,
  getSearchWorkroomDetail,
  setReservNow,
};
