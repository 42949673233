import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthStore } from '../../_redux/models/auth.model';

interface PrivateRouteProps extends RouteProps {
  children?: any;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const isAuth = useSelector((state: AuthStore) => state.auth.isAuth);

  return (
    <Route {...rest}>{isAuth ? children : <Redirect to="/login" />}</Route>
  );
};
export default PrivateRoute;
