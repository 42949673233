import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewsActions } from '../_redux/actions';
import { isEmpty } from '../utils/functions';
import dayjs from 'dayjs';
import styles from '../pages/Dashboard/News/ListNews/styles.module.scss';
import { useHistory } from 'react-router';
import { IonSkeletonText } from '@ionic/react';
import { NewsStore } from '../_redux/models/news.model';
import _ from 'lodash';
import CategoryChip from '../pages/Dashboard/News/components/CategoryChip';

const useDataNews = newslist => {
  const [returnList, setReturnList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleComment = id => {
    dispatch(NewsActions.selectNews(id, history.location.pathname));
  };
  const socialStatsList = useSelector(
    (store: NewsStore) => store.news.socialStats,
  );

  useEffect(() => {
    const resultData = [];
    !isEmpty(newslist) &&
      newslist.map(data => {
        const dataSocialStats = socialStatsList.find(e => e.idNews === data.id);

        const icons = _.has(dataSocialStats, 'like')
          ? [
              {
                id: 1,
                visible: false,
                type: 'icon',
                icon: `icon  ${
                  dataSocialStats.bookmarks
                    ? 'icon-save-filled'
                    : 'icon-save-line'
                } ${styles.color_secondary}`,
                slot: 'end',
                onClick: () => console.log('Guardar'),
              },
              {
                id: 2,
                visible: true,
                type: 'icon',
                icon: `icon ${
                  dataSocialStats.like ? 'icon-like-filled' : 'icon-like-line'
                } ${styles.color_primary}`,
                slot: 'start',
                text: dataSocialStats.likes,
                onClick: () => dispatch(NewsActions.likeNews(data.id)),
              },
              {
                id: 4,
                visible: true,
                type: 'icon',
                icon: `icon icon-comment ${styles.color_primary}`,
                slot: 'start',
                text: dataSocialStats.comments,
                onClick: () => handleComment(data.id),
              },
            ]
          : [
              {
                id: 1,
                visible: false,
                type: 'icon',
                icon: `icon icon-save-line ${styles.color_disabled}`,
                slot: 'end',
                onClick: () => void 0,
              },
              {
                id: 2,
                visible: true,
                type: 'icon',
                icon: `icon icon-like-line ${styles.color_disabled}`,
                slot: 'start',
                text: (
                  <IonSkeletonText animated className={styles.skeletonSocial} />
                ),
                onClick: () => void 0,
              },
              {
                id: 4,
                visible: true,
                type: 'icon',
                icon: `icon icon-comment ${styles.color_disabled}`,
                slot: 'start',
                text: (
                  <IonSkeletonText animated className={styles.skeletonSocial} />
                ),
                onClick: () => void 0,
              },
            ];
        resultData.push({
          id: data.id,
          nav_id: data.nav_id,
          title: data.title,
          description: `${
            isEmpty(data.author) ? '' : `${data.author} |`
          }  ${dayjs(data.publishing_date || data.publishingDate).format(
            'DD/MM/YYYY',
          )}`,
          label: data?.category && (
            <CategoryChip
              category={data?.category}
              category_id={
                data?.extended_props?.category_id || data.category_id
              }
            />
          ),
          category: data.category,
          category_id: data?.extended_props?.category_id || data.category_id,
          imageURL: data.image_id,
          actions: icons,
          saved: data.socialStats ? data.socialStats.bookmarks : false,
          publishingPanels: data.extended_props.publishingPanels,
          enableOverlay: data.extended_props.enableOverlay,
          videoUrl: data.extended_props.videoUrl,
        });
      });
    setReturnList(resultData);
    return () => {
      setReturnList([]);
    };
  }, [newslist, socialStatsList]);

  return returnList;
};

export default useDataNews;
