import { Snackbar } from '@acciona/ui-ionic-kit';
import { TextWithInterpolatedAnchor } from '../../../../components/TextWithInterpolatedAnchor/TextWithInterpolatedAnchor';

type TProps = {
  text: string;
  link: string;
};

const TransportZoneSnackBar: React.FC<TProps> = ({ text, link }) => {
  return (
    <Snackbar
      type="info"
      text={<TextWithInterpolatedAnchor text={text} link={link} />}
      icon="icon icon-info"
      align="left"
    />
  );
};

export default TransportZoneSnackBar;
