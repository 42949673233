import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { Button, TopBar } from '@acciona/ui-ionic-kit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormatedText } from '../../components/Base/FormattedText';
import styles from './styles.module.scss';
import { history } from '../../_helpers/history';
import { NotificationsStore } from '../../_redux/models/notifications.model';

const AppNotificationDetail: React.FC<any> = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [bodyHasContent, setBodyHasContent] = useState(false);
  const { t } = useTranslation();
  const notification = useSelector(
    (state: NotificationsStore) => state.notifications.notificationDetail,
  );

  const detailnotificationBack = () => {
    history.push(`/dashboard/home`);
  };

  useEffect(() => {
    notification && setTitle(notification?.title);
    notification && setBody(notification?.body);
    if (notification?.body?.length > 0) {
      setBodyHasContent(true);
    }
  }, [notification]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.toolbarGrid}>
        <TopBar
          primaryActions={
            <div className={styles.headerIcon}>
              <Button
                onClick={detailnotificationBack}
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            </div>
          }
          title={title}
        />
      </IonHeader>
      <IonContent>
        <div className={`${styles.gridWeb} ${styles.cardDetailContain}`}>
          <div
            className={`${styles.cardContain} ${styles.title_1} ${styles.cardTitle}`}
          >
            {title}
          </div>
          <FormatedText
            className={`${styles.cardText}`}
            content={bodyHasContent ? body : t('lbl_empty_article')}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AppNotificationDetail;
