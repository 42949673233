import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonButton,
  IonLoading,
} from '@ionic/react';
import { TopBar, Toast, Alert } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { reservationActions } from '../../../../_redux/actions/reservations.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { capitalizeFirstLetter, isEmpty } from '../../../../utils/functions';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { UserStore } from '../../../../_redux/models/user.model';
import styles from './styles.module.scss';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import dayjs from 'dayjs';

export const CanteenReservationDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const { sedesList } = useSelector((state: UserStore) => state.user.user);
  const { reservedCanteen, isLoading, error } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const { isFromConfirmation, handleClose } = useReservationsHistory();

  const sedeSpace = sedesList.find(e => e.id == reservedCanteen?.idSede);

  useEffect(() => {
    const backFunction = ev => {
      ev.detail.register(10, () => {
        history.replace('/dashboard/reservations');
      });
    };
    document.addEventListener('ionBackButton', backFunction);

    return function cleanup() {
      document.removeEventListener('ionBackButton', backFunction);
    };
  }, []);

  const deleteCanteenReserve = async () => {
    dispatch(reservationActions.cancelCanteenReservation(reservedCanteen));
  };

  const openDeleteCanteenReserveAlert = () => {
    setShowAlert(true);
  };

  return (
    <IonPage>
      <IonHeader
        mode="ios"
        id="map-detail-header"
        className={`${styles.toolbarGrid} ion-no-border`}
      >
        <TopBar
          primaryActions={
            isFromConfirmation ? (
              <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
            ) : (
              <IonBackButton
                defaultHref="/dashboard/reservations"
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            )
          }
          title={t('detail_reservation')}
          endActions={
            <IonButton onClick={openDeleteCanteenReserveAlert}>
              {t('delete_text')}
            </IonButton>
          }
        />
      </IonHeader>
      <IonContent fullscreen>
        {reservedCanteen && !isEmpty(reservedCanteen) && (
          <div>
            <DetailCard
              id="map-detail-card"
              name="Comedor"
              subName={t('canteen')}
              type="canteen"
              icon="icon icon-plate"
              title={`${reservedCanteen.location.district}`}
            ></DetailCard>
            <div className={styles.gridWeb}>
              <div className={styles.listGrid}>
                <DetailList>
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-booking"
                    startIconColor="primary"
                    title={capitalizeFirstLetter(
                      `${dayjs(reservedCanteen.date.reservationDateFrom)
                        .tz(sedeSpace?.campusTimeZone)
                        .format('ddd DD MMM YYYY')}`,
                    )}
                  />

                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-recaderia"
                    startIconColor="primary"
                    title={t('reservation_schedule')}
                    description={`${dayjs(
                      reservedCanteen.date.reservationDateFrom,
                    )
                      .tz(sedeSpace?.campusTimeZone)
                      .format('HH:mm')} - ${dayjs(
                      reservedCanteen.date.reservationDateTo,
                    )
                      .tz(sedeSpace?.campusTimeZone)
                      .add(1, 'seconds')
                      .format('HH:mm')}`}
                  />
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-campus"
                    startIconColor="primary"
                    title={` ${reservedCanteen.location?.buildingName} , ${reservedCanteen.location?.floorName}`}
                  />
                  <DetailListItem
                    className={styles.itemList}
                    startIcon="icon icon-explore"
                    startIconColor="primary"
                    title={t('lbl_head_office')}
                    description={reservedCanteen?.descriptionSede}
                  />
                </DetailList>
                <div className={styles.canteenFootnote}>
                  <p>{t('canteen_detail_footnote')}</p>
                </div>
              </div>
              <Alert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={t('header_delete_workstation_reserve')}
                message={t('msg_delete_canteen_reserve', {
                  from: `${dayjs(reservedCanteen.date.reservationDateFrom)
                    .tz(sedeSpace?.campusTimeZone)
                    .format('HH:mm')}`,
                  to: `${dayjs(reservedCanteen.date.reservationDateTo)
                    .tz(sedeSpace?.campusTimeZone)
                    .add(1, 'minutes')
                    .format('HH:mm')}`,
                })}
                buttons={[
                  { text: t('cancel_text'), role: 'cancel' },
                  {
                    text: t('lbl_affirmative'),
                    handler: () => deleteCanteenReserve(),
                  },
                ]}
                mode="ios"
              />
            </div>
          </div>
        )}

        <Toast
          isOpen={!!error}
          message={error}
          position="bottom"
          type="error"
        />
        <IonLoading
          isOpen={isLoading}
          message={t('msg_loading')}
          duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};
