import React from 'react';
import { Icon } from '@acciona/ui-ionic-kit';
import { PermanentIcon } from '../../../../../components/Base/PermanentIcon';
import { reservationActions } from '../../../../../_redux/actions';
import { useDispatch } from 'react-redux';
import { TodayReservation as Props } from '../types';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { IonIcon } from '@ionic/react';
import { getIconReservationType } from '../../../../Dashboard/Reservation/ListReservation/helpers';

const TodayReservation: React.FC<Props> = props => {
  const {
    employeeIdSearched,
    spaceId,
    useType,
    name,
    building,
    floor,
    text,
    descriptionSede,
    reservationType,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSelectedReservation = (spaceId, useType) => {
    (useType === 'habitat' || useType === 'permanent') &&
      dispatch(
        reservationActions.getReservationNoOwnerWorkstation(
          spaceId,
          employeeIdSearched,
          useType === 'permanent',
          undefined,
        ),
      );
  };

  return (
    <>
      <div
        onClick={() => handleSelectedReservation(spaceId, useType)}
        className={useType != 'any' ? styles.borderBottom : ''}
      >
        <div className={styles.cardContain}>
          {useType != 'any' && (
            <div className={styles.favIconContain}>
              <div
                className={
                  `${styles.divIcon}` &&
                  ['permanent', 'habitat'].includes(useType)
                    ? `${styles.workroom}`
                    : `${styles.homeOffice}`
                }
              >
                <Icon className={`icon icon-seat ${styles.iconFont}`} />
                {useType === 'permanent' && <PermanentIcon />}
              </div>
            </div>
          )}
          {useType != 'any' ? (
            <div className={styles.verticalCenter}>
              <p className={styles.workstationName}>
                {name}
                <IonIcon
                  className={`icon icon-${getIconReservationType(
                    reservationType,
                  )}`}
                />
              </p>
              {useType === 'homeOffice' ? (
                <p className={styles.workstationText}>{text}</p>
              ) : (
                <p
                  className={styles.workstationText}
                >{`${building}, ${floor}`}</p>
              )}
              <p className={styles.workstationText}>
                {!_.isEmpty(descriptionSede) &&
                  `${t('lbl_head_office')} ${descriptionSede}`}
              </p>
            </div>
          ) : (
            <p className={styles.anyText}>{text}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default TodayReservation;
