import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonSpinner,
} from '@ionic/react';
import { TopBar, Button, Alert, Toast } from '@acciona/ui-ionic-kit';
import {
  Vehicle,
  VehicleStore,
} from '../../../../_redux/models/vehicles.model';
import {
  PassengersStore,
  PassengerData,
} from '../../../../_redux/models/passengers.model';
import { ParkingStore } from '../../../../_redux/models/parking.model';
import { UserStore } from '../../../../_redux/models/user.model';
import { reservationActions, parkingActions } from '../../../../_redux/actions';
import { passengersActions } from '../../../../_redux/actions/passengers.actions';
import { NewVehicleModal } from '../../../Profile/VehiclesPassengers/NewVehicleModal/NewVehicleModal';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { VehicleDetailList } from '../../../../components/Base/VehicleDetailList';
import { isEmpty } from '../../../../utils/functions';
import {
  getSuggestedVehicle,
  getVehicleIcon,
} from '../../../../utils/vehiclesAndParking';
import styles from './styles.module.scss';
import { useLocationState } from '../../../../hooks/useLocationState';

const ReservationPlanned: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModalAddVehicle, setShowModalAddVehicle] = useState(false);
  const [dataVehicle, setDataVehicle] = useState([]);
  const [dataCompanions, setDataCompanions] = useState([]);
  const { vehicle: selectedVehicle, vehicles: userVehicles } = useSelector(
    (state: VehicleStore) => state.vehicle,
  );
  const { defaultPassengers, parkingCompanions } = useSelector(
    (state: PassengersStore) => state.passengers,
  );
  const { isLoading, error } = useSelector(
    (state: ParkingStore) => state.parking,
  );
  const { permissions } = useSelector((state: UserStore) => state.user.user);
  const msgState = useSelector(
    (store: PassengersStore) => store.passengers.msg,
  );
  const [parkingCompanionsList, setParkingCompanionsList] = useState<
    PassengerData[]
  >([]);
  const [selectDates, setSelectDates] = useState();
  const [suggestedVehicle, setSuggestedVehicle] = useState<Vehicle>(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (selectedVehicle) {
      setSuggestedVehicle(
        getSuggestedVehicle(userVehicles, permissions, selectedVehicle),
      );
    }
  }, [selectedVehicle, userVehicles, permissions]);

  const state = useLocationState();

  useEffect(() => {
    if (state !== undefined) {
      setSelectDates(state.selectDates);
    }
  }, [state]);

  const handleConfirmReservation = () => {
    if (isEmpty(parkingCompanionsList)) {
      confirmReservation();
    } else {
      JSON.stringify(parkingCompanionsList) ===
      JSON.stringify(defaultPassengers)
        ? confirmReservation()
        : setShowAlert(true);
    }
  };

  const confirmReservation = async () => {
    dispatch(
      parkingActions.createParkingReservePlanned(
        selectDates,
        suggestedVehicle,
        parkingCompanionsList,
      ),
    );
  };

  useEffect(() => {
    dispatch(passengersActions.getDefaultParkingCompanions());
  }, []);

  useEffect(() => {
    parkingCompanions && setParkingCompanionsList(parkingCompanions);
  }, [parkingCompanions]);

  const handleDeleteCompanion = (item: PassengerData) => {
    dispatch(passengersActions.deleteParkingCompanion(item));
    dispatch(
      passengersActions.setMsgPassengers({
        type: 'success',
        description: `${t('lbl_delete_passenger_toast')} `,
      }),
    );
  };

  const handleDismissToast = () => {
    dispatch(reservationActions.resetMsgReservation());
  };

  useEffect(() => {
    if (suggestedVehicle) {
      const { brand = '', model = '', plate = '' } = suggestedVehicle;

      setDataVehicle([
        {
          header: `${t('lbl_selected_vehicle')}`,
          brand: `${brand} ${model} - ${plate}`,
          description: t('lbl_parking_change_vehicle'),
          routerLink: '/parking/changeVehicle',
          icon: getVehicleIcon(suggestedVehicle.type),
        },
      ]);
    }
  }, [suggestedVehicle]);

  useEffect(() => {
    setDataCompanions([
      {
        id: 1,
        text: isEmpty(parkingCompanionsList)
          ? t('lbl_select_companions')
          : t('lbl_companions_seleccted'),
        description: t('win_acciona_points_carpool'),
        routerLink: '/parking/companions',
        icon: 'icon icon-occupation',
      },
    ]);
  }, [parkingCompanionsList]);

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/parking/calendarPlanned"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('reservation_parking_planned')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            <NewVehicleModal
              showModal={showModalAddVehicle}
              onClose={() => setShowModalAddVehicle(false)}
              onCloseWithSave={() => setShowModalAddVehicle(false)}
            />
            <DetailList>
              <DetailListItem
                lines="full"
                title={t('lbl_personal_vehicle_passengers')}
              />
              {dataVehicle.map((item, index) => {
                return (
                  <VehicleDetailList
                    header={item.header}
                    brand={item.brand}
                    key={index}
                    mode="ios"
                    description={item.description}
                    routerLink="/parking/changeVehicle"
                    type="button"
                    disabled={item.disabled}
                    icon={item.icon}
                    className={styles.itemList}
                    endIconTop="icon icon-chevron-right"
                  />
                );
              })}
              <div className={styles.lineSeparation}></div>
              {dataCompanions.map(item => {
                return (
                  <DetailListItem
                    className={styles.itemList}
                    key={item.id}
                    mode="ios"
                    lines="inset"
                    routerLink={item.routerLink}
                    type="button"
                    disabled={item.disabled}
                    startIcon={item.icon}
                    endIconTop="icon icon-chevron-right"
                    startIconColor="primary"
                    titleLight={item.text}
                    footnote={item.description}
                  />
                );
              })}
              {parkingCompanionsList.map(item => {
                return (
                  <DetailListItem
                    companion
                    key={item.employeeId}
                    mode="ios"
                    type="button"
                    startIcon="icon icon-client"
                    titleLight={item.fullName}
                    footnote={item.function}
                    endIconAction="icon icon-delete"
                    actionEndIcon={() => handleDeleteCompanion(item)}
                  />
                );
              })}
            </DetailList>
          </div>
          <Alert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={t('lbl_companions_header')}
            message={t('lbl_companions_notification')}
            buttons={[
              { text: t('cancel_text'), role: 'cancel' },
              {
                text: t('ok_text'),
                handler: () => confirmReservation(),
              },
            ]}
            mode="ios"
          ></Alert>
        </div>
        {msgState && (
          <Toast
            isOpen={!!msgState}
            message={msgState.description}
            onDidDismiss={handleDismissToast}
            position="bottom"
            type={msgState.type}
          />
        )}
      </IonContent>
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              color="lighter"
              routerLink="/dashboard/actions"
              disabled={!suggestedVehicle || isLoading}
            >
              {t('btn_calendar_parking_select')}
            </Button>
          </div>
          <div>
            <Button
              onClick={handleConfirmReservation}
              disabled={!suggestedVehicle || isLoading}
            >
              {t('btn_last_minute_parking')}
              {isLoading && (
                <IonSpinner
                  slot="end"
                  className={styles.color_primary}
                  name="lines-small"
                />
              )}
            </Button>
          </div>
        </div>
      </IonFooter>
      <Toast
        isOpen={!!error}
        onDidDismiss={() => dispatch(parkingActions.resetErrorParking())}
        message={error}
        position="bottom"
        type="error"
      ></Toast>
    </IonPage>
  );
};

export default ReservationPlanned;
