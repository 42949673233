import styles from './styles.module.scss';
import { Props } from './types';
import {
  Card,
  CardHeader,
  CardSubtitle,
  CardTitle,
  CardContent,
  SocialToolBarItem,
} from '@acciona/ui-ionic-kit';
import { IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import PlayImage from '../../../assets/images/play-button.svg';
import { isEmpty } from '../../../utils/functions';
import { NewsImg } from '../NewsImg';
import CategoryChip from '../../../pages/Dashboard/News/components/CategoryChip';
import VideoPlayer from '../VideoPlayer';
import { useVideoPlayer } from '../../../hooks/useVideoPlayer';

export const NewsSecondaryCard: React.FC<Props> = props => {
  const { data, headerTranslucent, displaySocial, className, ...res } = props;
  const { showVideo, playVideo, closeVideo } = useVideoPlayer(data.videoUrl);

  return (
    <Card className={`${styles.cardWrapper} ${className}`} key={data.id}>
      <IonGrid className={styles.noPaddingClickable} onClick={res.onClick}>
        <IonRow className={styles.responsiveRow}>
          <div className={styles.content}>
            <CardHeader
              className={styles.cardHeader}
              translucent={headerTranslucent}
            >
              <CardSubtitle mode={res.mode} className={styles.cardHSubtitle}>
                {data?.category && (
                  <CategoryChip
                    category_id={data?.category_id}
                    category={data?.category}
                  />
                )}
                {/* TODO: waiting UX validation to remove */}
                {/* {data.label && (
                  <Chip type="info" className="icon icon-play-XXS"></Chip>
                )} */}
              </CardSubtitle>
              <CardTitle
                className={`${styles.subhead} ${styles.color_primary}`}
              >
                <div dangerouslySetInnerHTML={{ __html: data.title }}></div>
              </CardTitle>
            </CardHeader>
            <CardContent
              mode={res.mode}
              className={`${styles.subhead} ${styles.cardDescription} ${styles.color_dark}`}
            >
              {data.description}
            </CardContent>
          </div>
          <div className={styles.img}>
            <div className={styles.centerImgButton}>
              {!isEmpty(data.videoUrl) && (
                <button className={styles.videoButton} onClick={playVideo}>
                  <img src={PlayImage} alt="" />
                </button>
              )}
              <VideoPlayer
                videoUrl={data.videoUrl}
                onClose={closeVideo}
                isOpen={showVideo}
              />
              {data.enableOverlay || !isEmpty(data.videoUrl) ? (
                <div className={styles.imgOverlay} />
              ) : null}
              <NewsImg
                image_id={data.imageURL}
                classSkeleton={styles.skeleton}
              />
            </div>
          </div>
        </IonRow>
      </IonGrid>
      {displaySocial === false ? (
        ''
      ) : (
        <IonGrid className={styles.noPadding}>
          <IonRow className={styles.rowActions}>
            <IonItem className={styles.cardActions}>
              {data.actions &&
                data.actions
                  .filter(action => action.visible)
                  .map((item, index) =>
                    item.type == 'icon' ? (
                      <SocialToolBarItem
                        key={'Secondary' + index}
                        icon={item.icon}
                        onClick={item.onClick}
                        className={styles.cardIcon}
                        text={item.text}
                        slot={item.slot}
                      />
                    ) : (
                      <IonLabel
                        key={index}
                        className={styles.cardLabel}
                        slot={item.slot}
                      >
                        {item.icon}
                      </IonLabel>
                    ),
                  )}
            </IonItem>
          </IonRow>
        </IonGrid>
      )}
    </Card>
  );
};
