import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  IonPage,
  IonHeader,
  IonBackButton,
  IonContent,
  IonFooter,
  useIonViewDidEnter,
  IonLoading,
  IonText,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { parkingActions, reservationActions } from '../../../../_redux/actions';
import {
  TopBar,
  Button,
  Toast,
  Snackbar,
  WeekCalendarMultiselector,
} from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import { ParkingStore } from '../../../../_redux/models/parking.model';
import styles from './styles.module.scss';
import { useHistory } from 'react-router';
import { AppStore } from '../../../../_redux/models/app.model';
import { isEmpty, openHtmlLink } from '../../../../utils/functions';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { Legend } from '../../../../components/Base/Legend';
import { LegendItem } from '../../../../components/Base/LegendItem';
import { UserStore } from '../../../../_redux/models/user.model';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);
import { getMinAndMaxDates, getParkingRequestNumberOfWeeks } from './helpers';

const CalendarPlanned: React.FC = () => {
  const mounted = useRef(true);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [reservedDays, setReservedDays] = useState<string[]>([]);
  const [minDate, setMinDate] = useState<Date>(null);
  const [maxDate, setMaxDate] = useState<Date>(null);
  const [showToastErrorParking, setShowToastErrorParking] = useState(false);
  const [msgErrorReservation, setMsgErrorReservation] = useState('');
  const [showToastHomeOffice, setShowToastHomeOffice] = useState(false);
  const [showCalendar, setshowCalendar] = useState<boolean>(false);
  const [calendarIsDisabled, setCalendarIsDisabled] = useState(false);
  const [reservableDays, setReservablesDays] = useState<string[]>([]);
  const [numWeeks, setNumWeeks] = useState<number>(1);

  const { localLanguage } = useSelector(
    (state: AppStore) => state.app.localSettings,
  );

  const { reservationPolicies } = useSelector((state: UserStore) => state.user);
  const {
    raffle,
    error: errorParking,
    isLoading: isLoadingParking,
    plannedReservableDays,
  } = useSelector((state: ParkingStore) => state.parking);

  const {
    homeOfficeDays,
    isLoading: isLoadingReservation,
    error: errorReservation,
    reservedParkingDays,
  } = useSelector((state: ReservationStore) => state.reservation);

  const {
    parkingPlannedWeeksExtend = 2,
    dayParkingPlannedTurningPoint = 0,
    hourParkingPlannedTurningPoint = '12:00',
    homeOfficeReservationAvailable,
  } = useSelector((state: AppStore) => state.app.globalSettings);

  useIonViewDidEnter(() => {
    !showCalendar && setshowCalendar(true);
  });

  const documentReservation = useMemo(
    () =>
      reservationPolicies
        ? reservationPolicies.find(e => e.type === 'policyParking')?.url
        : '',
    [reservationPolicies],
  );

  useEffect(() => {
    dispatch(parkingActions.getRaffle());
  }, []);

  useEffect(() => {
    errorParking && setShowToastErrorParking(true);
  }, [errorParking]);

  useEffect(() => {
    if (errorReservation) {
      setMsgErrorReservation(errorReservation);
      setCalendarIsDisabled(true);
    }
    return () => {
      dispatch(reservationActions.resetErrorReservations());
    };
  }, [errorReservation]);

  useEffect(() => {
    setReservedDays(reservedParkingDays.map(d => d.date));
  }, [reservedParkingDays]);

  useEffect(() => {
    if (mounted.current) {
      if (raffle.inProgress) {
        history.goBack();
      } else {
        const { minDate: min, maxDate: max } = getMinAndMaxDates(
          raffle,
          dayParkingPlannedTurningPoint,
          hourParkingPlannedTurningPoint,
          parkingPlannedWeeksExtend,
        );

        setMinDate(
          new Date(dayjs(min.format('YYYY-MM-DD 00:00:00')).toISOString()),
        );
        setMaxDate(
          new Date(dayjs(max.format('YYYY-MM-DD 23:59:00')).toISOString()),
        );
        dispatch(
          reservationActions.getReservedDaysPkAndHomeOffice(
            min.toISOString(),
            max.toISOString(),
          ),
        );
      }
    }
    return () => {
      mounted.current = false;
    };
  }, [
    raffle,
    dayParkingPlannedTurningPoint,
    hourParkingPlannedTurningPoint,
    parkingPlannedWeeksExtend,
  ]);

  useEffect(() => {
    setReservablesDays(plannedReservableDays);
    const numWeeks = getParkingRequestNumberOfWeeks([
      dayjs().format('YYYY-MM-DD'),
      ...plannedReservableDays,
    ]);
    setNumWeeks(numWeeks);
  }, [plannedReservableDays]);

  const handleSelectAnyParking = () => {
    history.push('/parking/reservationPlanned', {
      selectDates: selectedDates,
    });
  };

  const handleDismissToast = () => {
    dispatch(parkingActions.resetErrorParking());
    setShowToastErrorParking(false);
    setShowToastHomeOffice(false);
  };

  const handleDismissToastReservation = () => {
    setMsgErrorReservation('');
  };

  const handleSelectDate = date => {
    const newDate = dayjs(date).format('YYYY-MM-DD');

    if (![...reservedDays, ...reservableDays].includes(newDate)) {
      return;
    }

    if (homeOfficeDays.includes(newDate) && !reservedDays.includes(newDate)) {
      setShowToastHomeOffice(true);
      if (!homeOfficeReservationAvailable) return;

      setSelectedDates([...selectedDates, newDate]);
    }

    if (reservedDays.includes(newDate)) {
      setMsgErrorReservation(t('msg_blocked_day'));
    } else if (selectedDates.includes(newDate)) {
      setSelectedDates(selectedDates.filter(d => d !== newDate));
    } else {
      setSelectedDates([...selectedDates, newDate]);
    }
  };

  const dataLegend = [
    { type: 'parking', text: t('lbl_res_parking') },
    { type: 'homeOffice', text: t('lbl_homeOffice') },
  ];

  return (
    <IonPage>
      <IonHeader mode="ios" className={`ion-no-border ${styles.toolbarGrid}`}>
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/parking/retype"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('calendar_title')}
        />
        {!isEmpty(documentReservation) && (
          <Snackbar
            type="info"
            text={
              <IonText>
                {`${t('lbl_planned_parking_calendar_snackbar_1')} ${t(
                  'lbl_planned_parking_calendar_snackbar_2',
                )} `}
                <a
                  className={styles.defaultLink}
                  onClick={() => openHtmlLink(documentReservation)}
                >
                  {t('lbl_planned_parking_calendar_snackbar_3')}
                </a>
              </IonText>
            }
            icon="icon icon-info"
            align="left"
          />
        )}

        {selectedDates.length > 0 && (
          <Snackbar
            type="info"
            text={t('lbl_date_selected', {
              number: selectedDates.length,
              multiple: selectedDates.length > 1 ? 's' : '',
            })}
            align="center"
          />
        )}
      </IonHeader>
      <IonContent fullscreen>
        <Toast
          isOpen={!!msgErrorReservation}
          onDidDismiss={handleDismissToastReservation}
          message={msgErrorReservation}
          position="bottom"
          type="error"
        />
        <Toast
          isOpen={showToastErrorParking}
          onDidDismiss={handleDismissToast}
          message={errorParking}
          position="bottom"
          type="error"
        />
        <Toast
          isOpen={showToastHomeOffice}
          onDidDismiss={handleDismissToast}
          message={
            homeOfficeReservationAvailable
              ? t('msg_homeoffice')
              : t('msg_not_reservation_homeoffice')
          }
          position="bottom"
          type={homeOfficeReservationAvailable ? 'homeOffice' : 'error'}
        />
        <div className={styles.gridWeb}>
          <div
            className={`${styles.parkingCalendar} ${styles.calendarGrid} ${
              isLoadingParking || isLoadingReservation || calendarIsDisabled
                ? styles.calendarDisabled
                : ''
            }`}
          >
            {showCalendar && (
              <>
                <WeekCalendarMultiselector
                  selected={selectedDates}
                  homeOffice={homeOfficeDays.filter(
                    day => !reservedDays.includes(day),
                  )}
                  onChange={handleSelectDate}
                  locale={localLanguage}
                  weeks={numWeeks}
                  maxSelectedDays={20}
                  reservableDays={reservableDays}
                  reserved={reservedDays}
                  minDate={minDate}
                  maxDate={maxDate}
                  classNames={{
                    notAvailable: styles.notAvailable,
                    homeOffice: styles.homeOffice,
                    reserved: styles.reservedNonSelectable,
                    reservedNonSelectable: styles.reservedNonSelectable,
                    selected: styles.selected,
                    today: styles.today,
                    available: styles.available,
                  }}
                />
                <div className={styles.messageCalendar}>
                  {t('message_only_reservable')}
                </div>
              </>
            )}
          </div>
        </div>
        <IonLoading
          isOpen={isLoadingParking || isLoadingReservation}
          message={t('msg_loading')}
          duration={2000}
        />
      </IonContent>
      <IonFooter>
        <Legend className={styles.centerLegend}>
          {dataLegend.map((item, idx) => {
            return <LegendItem key={idx} type={item.type} text={item.text} />;
          })}
        </Legend>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button
              onClick={handleSelectAnyParking}
              disabled={calendarIsDisabled || !selectedDates.length}
            >
              {t('btn_calendar_parking_planned')}
            </Button>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default CalendarPlanned;
