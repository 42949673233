/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import {
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
  isPlatform,
} from '@ionic/react';
import { TopBar } from '@acciona/ui-ionic-kit';
import { App as AppCapacitor } from '@capacitor/app';
import { useEffect } from 'react';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import logoGooglePlayStore from '../../../../assets/images/google_play_logo.svg';
import logoAppStore from '../../../../assets/images/app_store_logo.svg';
import logoAcciona from '../../../../assets/images/campus_acciona.svg';

const LockerDownloadApp = () => {
  const { t } = useTranslation();
  const { history } = useReservationsHistory();
  useEffect(() => {
    AppCapacitor.addListener('resume', async () => {
      history.replace('/dashboard/home');
    });
    return () => {
      AppCapacitor.removeAllListeners();
    };
  }, []);

  return (
    <IonPage>
      {isPlatform('ios') && (
        <IonHeader mode="ios" className={styles.toolbarGrid}>
          <TopBar
            primaryActions={
              <IonBackButton
                text=""
                className={`${styles.topBarIcon} ${styles.iconTopBar} icon icon-chevron-left`}
              />
            }
            title={t('downloadApp.title')}
          />
        </IonHeader>
      )}

      <IonContent>
        <div className={styles.lockerDownloadAppContainer}>
          <img className={styles.logoAcciona} src={logoAcciona} alt="" />
          <div className={styles.boxInfo}>
            <h1>{t('downloadApp.title')}</h1>
            <p>{t('downloadApp.text')}</p>
            <p>{t('downloadApp.available')}</p>
            <div className={styles.storeIcons}>
              {isPlatform('android') && (
                <a
                  href="https://play.google.com/store/apps/details?id=com.saltosystems.justin"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img alt="Google Play" src={logoGooglePlayStore} />
                </a>
              )}
              {isPlatform('ios') && (
                <a
                  href="https://apps.apple.com/es/app/justin-mobile/id960998088"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img alt="App Store" src={logoAppStore} />
                </a>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LockerDownloadApp;
