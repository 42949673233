import { useMemo } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonFooter,
  IonBackButton,
} from '@ionic/react';
import { TopBar } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { DetailList } from '../../../../components/Base/DetailList';
import { isEmpty } from '../../../../utils/functions';
import { parkingActions } from '../../../../_redux/actions';
import styles from './styles.module.scss';

const PassengersByRequest: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const parkingRaffles = useSelector(
    (state: ReservationStore) => state.reservation.parkingRaffles,
  );

  const reservationRequests = useMemo(
    () => parkingRaffles.map(date => date.dates[0]),
    [parkingRaffles],
  );

  const handleGoCompanionsPage = requestId => {
    history.push({
      pathname: '/parking/companions',
      state: {
        originRaffle: true,
        requestId: requestId,
        companionsCounter: reservationRequests.find(
          request => request.requestId === requestId,
        ).companions.length,
      },
    });
  };

  const handleDeleteCompanion = (companion, requestId) => {
    dispatch(
      parkingActions.deleteComnpanionRaffleReserve(companion, requestId),
    );
  };

  return (
    <IonPage>
      <div className={styles.headerContainer}>
        <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
          <TopBar
            primaryActions={
              <IonBackButton
                defaultHref="/spaces/parking/detail"
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            }
            title={t('associated_companions', {
              multiple: 's',
            })}
          />
        </IonHeader>
        <div className={`${styles.narrowLine} ${styles.linePosition}`}></div>
      </div>
      <IonContent fullscreen>
        <div className={styles.gridWeb}>
          <div className={styles.listGrid}>
            {reservationRequests?.map(reservation => {
              return (
                <DetailList key={dayjs(reservation?.from).format('DD MMM')}>
                  <DetailListItem
                    lines="full"
                    title={dayjs(reservation?.from).format('DD MMM')}
                  />

                  {isEmpty(reservation?.companions) ? (
                    <DetailListItem
                      className={styles.itemList}
                      lines="none"
                      onClick={() =>
                        handleGoCompanionsPage(reservation.requestId)
                      }
                      startIcon="icon icon-occupation"
                      startIconColor="primary"
                      title={t('add_companions')}
                      endIcon="icon icon-chevron-right"
                      endIconColor="primary"
                      footnote={t('lbl_earn_points')}
                    />
                  ) : (
                    <>
                      {reservation?.companions.map(companion => (
                        <DetailListItem
                          companion
                          key={companion.employeeId}
                          startIcon="icon icon-client"
                          startIconColor="primary"
                          titleLight={companion.fullName}
                          footnote={companion.function}
                          endIconAction="icon icon-delete"
                          actionEndIcon={() =>
                            handleDeleteCompanion(
                              companion.employeeId,
                              reservation.requestId,
                            )
                          }
                          pending={!companion.status}
                        />
                      ))}
                      <DetailListItem
                        className={styles.itemList}
                        lines="none"
                        onClick={() =>
                          handleGoCompanionsPage(reservation.requestId)
                        }
                        startIcon="icon icon-occupation"
                        startIconColor="primary"
                        footnote={t('lbl_earn_points')}
                        title={t('lbl_select_more_companions')}
                        endIcon="icon icon-chevron-right"
                        endIconColor="primary"
                      />
                    </>
                  )}
                </DetailList>
              );
            })}
          </div>
        </div>
      </IonContent>
      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default PassengersByRequest;
