export enum ParkingTypesEnum {
  REQUEST = 'FETCH_PARKING_REQUEST',
  SET_PARKING = 'SET_PARKING',
  FAILURE_PARKING = 'FAILURE_PARKING',
  SET_PARKINGS = 'SET_PARKINGS',
  RESET_ERROR = 'RESET_ERROR',
  RESET_PARKINGS = 'RESET_PARKINGS',
  MSG_PARKING = 'MSG_PARKING',
  SET_RAFFLE_STATE = 'SET_RAFFLE_STATE',
  RESET_LOADING = 'RESET_LOADING',
  SET_PARKING_CONFIGURATION = 'SET_PARKING_CONFIGURATION',
  SET_PK_RESERVABLE_DAYS = 'SET_PK_RESERVABLE_DAYS',
}
