import { LegendProps } from './types';
import { classes } from './classes';
import styles from './styles.module.scss';

export const LegendItem: React.FC<LegendProps> = ({
  type = 'desk',
  className,
  ...props
}) => {
  return (
    <div className={`${className} ${styles.legendItemSty}`}>
      <div
        className={`${styles.circle} ${classes[type]} ${props.circleClassName}`}
      />
      <div className={`${styles.caption_1} ${props.captionClassName}`}>
        {props.text}
      </div>
    </div>
  );
};
