import { useState } from 'react';
import { datesList } from '../../helpers';
import styles from './styles.module.scss';
import { Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { IPassegerInvitation } from '../../../../../../_redux/services/passengers/types';
import { convertDateToWrittenFormat } from '../../../../../../utils/dateTime';

interface IProps {
  dates: IPassegerInvitation['dates'];
  detail: boolean;
}

const Dates = ({ dates, detail }: IProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(v => !v);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.title} onClick={handleToggleExpand}>
        <span>
          {datesList(
            dates.sort((a, b) => {
              return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
            }),
            ', ',
            ` ${t('and')}`,
          )}
        </span>
        {!detail && (
          <Icon
            className={`icon icon-24 ${
              isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'
            } ${styles.chevron}`}
          />
        )}
      </div>
      {!detail && (
        <div className={`${styles.accordion} ${isExpanded && styles.expanded}`}>
          <div>
            {dates.map(d => {
              return (
                <p key={d.id}>
                  <span
                    style={{
                      textDecoration: d.isUserFree ? '' : 'line-through',
                    }}
                  >
                    {convertDateToWrittenFormat(d.date)}
                  </span>
                  {' - '}
                  <span>
                    {t(d.isUserFree ? 'complete_day' : 'lbl_not_available')}
                  </span>
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dates;
