import { useState } from 'react';
import { IonRow, IonCol, useIonViewDidEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Icon, WeekCalendar, PickerCalendar } from '@acciona/ui-ionic-kit';
import { useDelayedRender } from '../../../../hooks/useDelayedRender';
import { Props } from './types';
import { LegendItem } from '../../../../components/Base/LegendItem';
import { Legend } from '../../../../components/Base/Legend';
import styles from './styles.module.scss';
import './calendar.scss';
import { checkIfDateIsDisabled } from '../../../../utils/dateTime';
import { useSelector } from 'react-redux';
import { UserStore } from '../../../../_redux/models/user.model';
import { AppStore } from '../../../../_redux/models/app.model';

export const CalendarAccordion: React.FC<Props> = props => {
  const {
    selectedDate,
    reservedDays,
    homeOfficeDays,
    handleDaySelected,
    localLanguage,
    minDate,
    dataLegend,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { delayed } = useDelayedRender(500);
  const deskDisableDays = useSelector(
    (store: AppStore) => store.app.globalSettings.deskDisabledDays,
  );

  const modifiersClass = {
    reservedDays: styles.calendarReserved,
    homeOffice: styles.calendarHomeOffice,
    today: styles.calendarToday,
  };

  const { publicHolidays } = useSelector((state: UserStore) => state.user);
  const moreModifiers = {
    disabled: (date: any) =>
      checkIfDateIsDisabled(date, publicHolidays, deskDisableDays),
  };

  useIonViewDidEnter(() => {
    setIsOpen(false);
  });

  return (
    <>
      <div className={styles.accordion_title} aria-expanded={isOpen}>
        <IonRow>
          <IonCol size="12">
            <div className="weekCalendar">
              <WeekCalendar
                selected={selectedDate}
                reserved={reservedDays}
                homeOffice={homeOfficeDays}
                onChange={handleDaySelected}
                locale={localLanguage}
              />
            </div>
          </IonCol>
        </IonRow>
      </div>
      <div className={styles.accordion_content} aria-expanded={!isOpen}>
        <div className="pickerCalendar">
          {!delayed && (
            <PickerCalendar
              key={isOpen.toString()}
              minimumDate={minDate}
              date={selectedDate}
              selectedDate={handleDaySelected}
              locale={localLanguage}
              reserved={reservedDays}
              homeOffice={homeOfficeDays}
              moreModifiers={moreModifiers}
              moreModifiersClassNames={modifiersClass}
            />
          )}
        </div>
      </div>
      <div className={styles.accordion_legend}>
        <div className={styles.calendarLegend}>
          <Legend className={styles.legend}>
            {dataLegend.map((item, idx) => {
              return (
                <LegendItem
                  className={styles.itemLegend}
                  key={idx}
                  type={item.type}
                  text={item.text}
                />
              );
            })}
          </Legend>
          <div
            className={styles.calendarDropdown}
            onClick={() => setIsOpen(v => !v)}
          >
            {!isOpen ? (
              <>
                <div className={styles.caption_1}>{t('lbl_see_more')}</div>
                <Icon className={`icon icon-chevron-down icon-24`} />
              </>
            ) : (
              <>
                <div className={styles.caption_1}>{t('lbl_see_less')}</div>
                <Icon className={`icon icon-chevron-up icon-24`} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
