import { useRef, useState, useEffect } from 'react';

const getInitialZoom = (originalImageWidth: number, containerWidth: number) => {
  const getBaseLog = (x, y) => Math.log(y) / Math.log(x);
  const calculatedZoom = getBaseLog(2, containerWidth / originalImageWidth);
  return Math.floor(calculatedZoom * 4) / 4;
};

const getMapHeight = (
  imageHeight: number,
  imageWidth: number,
  containerWidth: number,
) => {
  return (imageHeight * containerWidth) / imageWidth;
};

export const useMapConfig = (mapImageUrl: string) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [mapHeight, setMapHeight] = useState(0);
  const [mapWidth, setMapWidth] = useState(0);
  const [initialZoom, setInitialZoom] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const setHeight = (img: HTMLImageElement) => {
    if (!img) return;

    const imgDimensions = {
      height: img.height,
      width: img.width,
    };

    const mapWidthPx = mapContainerRef.current.clientWidth;
    const mapHeightPx = getMapHeight(img.height, img.width, mapWidthPx);
    const initZoom = getInitialZoom(img.width, mapWidthPx);

    setImageDimensions(imgDimensions);
    setMapHeight(mapHeightPx);
    setMapWidth(mapWidthPx);
    setInitialZoom(initZoom);
  };

  useEffect(() => {
    if (
      imageDimensions.height > 0 &&
      imageDimensions.width > 0 &&
      mapHeight > 0
    ) {
      setIsReady(true);
    }
  }, [imageDimensions, mapHeight, initialZoom]);

  useEffect(() => {
    if (mapImageUrl && mapContainerRef.current && mounted) {
      const img = new Image();
      img.src = mapImageUrl;

      img.onerror = (err: string) => {
        console.error(err);
        return;
      };
      if (img.complete && img.height > 0) {
        setHeight(img);
      }

      img.onload = () => {
        setHeight(img);
      };
    }
  }, [mapImageUrl, mapContainerRef.current, mounted]);

  return {
    isReady,
    imageDimensions,
    mapContainerRef,
    mapHeight,
    mapWidth,
    initialZoom,
  };
};
