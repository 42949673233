import { Alert, Button, Snackbar, Toast, TopBar } from '@acciona/ui-ionic-kit';
import {
  IonBackButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HIDService from '../../../../_redux/services/hid/hid.services';
import { doorAction } from '../../../Auth/Hid/const';
import { userActions } from '../../../../_redux/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { UserStore } from '../../../../_redux/models/user.model';
import { useKeyboardListener } from './../../../../hooks/useKeyboardListener';
import { AppStore } from '../../../../_redux/models/app.model';
import { StorageService } from '../../../../_redux/services/storage/storage.service';
import dayjs from 'dayjs';
import _ from 'lodash';

const CardActivation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const maxLengthCode = 19;
  const { msg, card } = useSelector((store: UserStore) => store.user);
  const invitationCardCode = useSelector((store: UserStore) => {
    if (store.user.invitationCode.included) {
      return store.user.invitationCode.code;
    }
    return '';
  });
  const { keyboardIsVisible } = useKeyboardListener();
  const [cardCode, setCardCode] = useState<string>(invitationCardCode);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestTime, setRequestTime] = useState('');
  const { newActivationCodeMinutes } = useSelector(
    (state: AppStore) => state.app.globalSettings,
  );

  const mounted = useRef(true);

  useEffect(() => {
    if (card.codeRequestDate) {
      setRequestTime(card.codeRequestDate);
    } else {
      StorageService.getItem('codeRequestDate')
        .then(res => {
          setRequestTime(res);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          resetLoading();
        });
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (requestTime) {
      checkCardStatus();
      dispatch(userActions.setCodeRequestDate(requestTime));
      StorageService.setItem('codeRequestDate', requestTime);
    }
  }, [requestTime]);

  const resetLoading = () => {
    if (mounted.current) {
      setIsLoading(false);
    }
  };

  const handleActivateCode = async () => {
    setIsLoading(true);
    try {
      await HIDService.handleAccessClicked(cardCode);
      const cards = HIDService.card;
      resetLoading();
      history.replace('/dashboard/home', {
        state: doorAction.succes,
        cards: cards,
      });
      dispatch(userActions.setCardRegistered());
      cards.length > 0 && dispatch(userActions.setCardActive());
    } catch (error) {
      const doorError = error as doorAction;
      if (doorError == doorAction.none) {
        return;
      }
      resetLoading();
      history.replace('/CodeInvitation/door', {
        state: doorError,
      });
    }
  };

  const checkCardStatus = () => {
    if (requestTime) {
      const now = dayjs().utc().toISOString();
      const dateDifference = dayjs(now).diff(dayjs(requestTime), 'minute');
      if (dateDifference >= newActivationCodeMinutes) {
        setRequestTime('');
        dispatch(userActions.setCodeRequestDate(''));
        StorageService.removeItem('codeRequestDate');
        dispatch(userActions.setActivationCodeRequested(false));
        return false;
      } else {
        dispatch(userActions.setActivationCodeRequested(true));
        return true;
      }
    }
    dispatch(userActions.setActivationCodeRequested(false));
    return false;
  };

  const handleActivationCodeRequest = async () => {
    const isRequested = checkCardStatus();
    if (isRequested) {
      setShowAlert(true);
    } else {
      setRequestTime(dayjs().utc().toISOString());
      dispatch(userActions.sendEmailNewCardCode());
    }
  };

  return (
    <IonPage>
      <IonHeader mode="ios">
        <TopBar
          primaryActions={
            <IonBackButton
              defaultHref="/profile/home"
              text=""
              className={`${styles.topBarIcon} icon icon-chevron-left`}
            />
          }
          title={t('lbl_validate_card')}
        />
        {card.activationCodeRequested && (
          <div className={styles.warningSnackbar}>
            <Snackbar
              type="warning"
              text={t('lbl_code_validation_reminder', {
                minutes: newActivationCodeMinutes,
              })}
              icon="icon icon-incident"
              align="left"
            />
          </div>
        )}
      </IonHeader>
      <IonContent fullscreen>
        <div className={styles.wellcomeText}>
          <p>{t('welcome_card_code')}</p>
          <p>
            {cardCode.length !== maxLengthCode
              ? t('enter_card_code')
              : t('validate_card_code')}
          </p>
        </div>
        <IonInput
          autocapitalize="sentences"
          value={cardCode}
          className={styles.inputCode}
          placeholder="0000-1111-2222-3333"
          maxlength={maxLengthCode}
          onIonInput={e => setCardCode(e.detail.value!)}
        />
        <hr className={styles.inputSeparator}></hr>
      </IonContent>
      {!keyboardIsVisible && (
        <Snackbar
          icon="icon icon-info"
          type="white"
          text={
            <IonText>
              {t('new_card_code.1')}{' '}
              <a
                className={styles.defaultLink}
                onClick={handleActivationCodeRequest}
              >
                {t('push_here')}
              </a>{' '}
              {t('new_card_code.2')}
            </IonText>
          }
          align="left"
        />
      )}
      <IonFooter>
        <div className={styles.btnGroupWeb}>
          <div>
            <Button disabled={_.isEmpty(cardCode)} onClick={handleActivateCode}>
              {t('activate')}
            </Button>
          </div>
        </div>
      </IonFooter>

      <Toast
        isOpen={!!msg}
        message={msg?.description}
        onDidDismiss={() => dispatch(userActions.resetMsgUser())}
        position="bottom"
        type={msg?.type}
      />
      <IonLoading
        isOpen={isLoading}
        message={`${t('msg_loading')} .`}
        duration={10000}
        onDidDismiss={resetLoading}
      />
      {showAlert && (
        <Alert
          isOpen={showAlert}
          header={t('lbl_code_already_requested')}
          message={t('msg_code_requested', {
            minutes: newActivationCodeMinutes,
          })}
          buttons={[
            {
              text: t('cancel_text'),
              role: 'cancel',
              handler: () => setShowAlert(false),
            },
            {
              text: t('ok_text'),
              handler: () => setShowAlert(false),
            },
          ]}
          mode="ios"
        />
      )}
    </IonPage>
  );
};

export default CardActivation;
