export interface IPassegerInvitation {
  dates: {
    id: string;
    date: string;
    isUserFree: boolean;
    accepted: boolean;
  }[];
  sede: {
    idSede: number;
    descriptionSede: string;
  };
  driver: string;
}

export interface IPassengerResponse {
  id: number | string;
  status: boolean;
  name?: string;
}

export enum EInvitationStatus {
  Rejected = 'user-reject',
  Handled = 'no-action-required',
  InvitationRemoved = 'invitation-removed',
  RequestRemoved = 'request-removed',
  Ok = 'ok',
}
