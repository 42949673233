import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonButton,
  IonBackButton,
} from '@ionic/react';
import { TopBar, Alert, Toast } from '@acciona/ui-ionic-kit';
import { ReservationStore } from '../../../../_redux/models/reservation.model';
import { Vehicle } from '../../../../_redux/models/vehicles.model';
import { Companions } from '../../../../_redux/models/parking.model';
import { reservationActions } from '../../../../_redux/actions';
import { useReservationsHistory } from '../../../../hooks/useReservationsHistory';
import { DetailCard } from '../../../../components/Base/DetailCard';
import { ReleasedDays } from '../../../../components/ReleasedDays/ReleasedDays';
import { DetailList } from '../../../../components/Base/DetailList';
import { DetailListItem } from '../../../../components/Base/DetailListItem';
import { VehiclesAndPassengersBlock } from './VehiclesAndPassengersBlock';
import { getDatesToReleaseText, isEmpty } from '../../../../utils/functions';
import { parkingServices } from '../../../../_redux/services';
import styles from './styles.module.scss';
import { getFilteredReservations, getParkingReservations } from '../../helpers';
import { ParkingReleasedDays } from '../../../../components/ParkingReleasedDays/ParkingReleasedDays';
import { UserStore } from '../../../../_redux/models/user.model';

const ParkingReservationDetail: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [datesReservation, setDatesReservation] = useState([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [companions, setCompanions] = useState<Companions[]>([]);
  const [checked, setChecked] = useState(false);
  const [releasedDays, setReleasedDays] = useState([]);
  const [raffleIsActive, setRaffleIsActive] = useState(true);

  const {
    history,
    locationState,
    datesSelected,
    typeReservation,
    startHour,
    endHour,
    isFromConfirmation,
    handleClose,
  } = useReservationsHistory();

  const { reservedParking, msg } = useSelector(
    (state: ReservationStore) => state.reservation,
  );

  const { defaultSede } = useSelector((state: UserStore) => state.user.user);

  const cancelParking = useCallback(async () => {
    const datesToCancel = datesReservation;
    const todayDate = new Date().toISOString().split('T')[0];

    if (isEmpty(datesToCancel)) {
      return;
    }

    const ids = datesReservation.map(d => d.id);

    const reservationFiltered = getFilteredReservations(
      reservedParking.reservations,
      datesSelected,
      typeReservation,
      startHour,
      endHour,
    );

    const reservedParkingFiltered = {
      ...reservedParking,
      reservations: reservationFiltered,
    };

    const isTodayReservationCancel = reservedParkingFiltered.reservations.some(
      reservation =>
        ids.includes(reservation.reservationId) &&
        reservation.from.includes(todayDate),
    );
    const matchingReservations = isTodayReservationCancel
      ? reservedParking.reservations.filter(reservation =>
          reservation.from.includes(todayDate),
        )
      : [];
    const haveMoreReservationsToday = _.isEmpty(matchingReservations)
      ? false
      : !matchingReservations.every(reservation =>
          ids.includes(reservation.reservationId),
        );
    dispatch(
      reservationActions.cancelParkingReservation(
        reservedParkingFiltered,
        ids,
        haveMoreReservationsToday,
      ),
    );
  }, [datesReservation, reservedParking]);

  const handlePlaceFavorite = e => {
    setChecked(e.detail.checked);
  };

  const deleteTodayParking = () => {
    const updatedDays = reservedParking.reservations
      ?.map(date => ({
        id: date.reservationId,
        status: false,
        date: new Date(
          dayjs(
            `${dayjs(date.from).utc().format('YYYY-MM-DD')} 00:00:00`,
          ).toISOString(),
        ),
      }))
      .map(item => {
        return dayjs(item.date).utc().isToday()
          ? { ...item, ...{ status: true } }
          : item;
      });

    setDatesReservation(updatedDays);
    !_.isEmpty(updatedDays.filter(e => e.status)) && setShowAlert(true);
  };

  const isPermanentParking = reservedParking?.useType === 'PermanentParking';

  useEffect(() => {
    if (!isPermanentParking) {
      setDatesReservation(getParkingReservations(reservedParking.reservations));
    }

    const vehicleArray = [];
    reservedParking?.reservations?.map(date => {
      if (date?.vehicle) {
        if (!vehicleArray.find(i => i.plate === date?.vehicle?.plate))
          vehicleArray.push(date.vehicle);
      }
    });
    setVehicles(vehicleArray);

    const companions = [];
    reservedParking?.reservations?.map(date => {
      date?.companions &&
        date?.companions.map(c => {
          if (!companions.find(i => i.fullName === c.fullName))
            companions.push(c);
        });
    });
    setCompanions(companions);
  }, [reservedParking?.reservations]);

  useEffect(() => {
    !_.isUndefined(locationState) &&
      !_.isUndefined(locationState.deleteToday) &&
      locationState.deleteToday &&
      deleteTodayParking();
  }, []);

  useEffect(() => {
    setReleasedDays(reservedParking?.releasedDates ?? []);
  }, [reservedParking?.releasedDates]);

  useEffect(() => {
    reservedParking?.idSede &&
      parkingServices
        .getRaffle(reservedParking?.idSede)
        .then(result => {
          setRaffleIsActive(result.inProgress);
        })
        .catch(error => {
          console.log(error);
        });
  }, [reservedParking?.idSede]);

  const handleReleaseDaysOnClick = () => {
    history.push('/parking/calendarRelease');
  };

  const daysToReleaseText = useMemo(
    () => getDatesToReleaseText(datesReservation),
    [datesReservation],
  );

  const handleGoModifyParkingReservationPage = () => {
    history.push({
      pathname: '/parking/modifyReservation',
    });
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={`${styles.toolbarGrid} ion-no-border`}>
        <TopBar
          primaryActions={
            isFromConfirmation ? (
              <IonButton onClick={handleClose}>{t('close_text')}</IonButton>
            ) : (
              <IonBackButton
                defaultHref="/dashboard/reservations"
                text=""
                className={`${styles.topBarIcon} icon icon-chevron-left`}
              />
            )
          }
          endActions={
            !isPermanentParking && !raffleIsActive ? (
              <IonButton onClick={() => setShowAlert(true)}>
                {t('delete_text')}
              </IonButton>
            ) : null
          }
          title={t('reservation_detail')}
        />
      </IonHeader>
      <IonContent fullscreen>
        <DetailCard
          className={styles.detailCard}
          onClickFavorite={e => handlePlaceFavorite(e)}
          favorite={checked}
          name="Plaza"
          subName={reservedParking?.name}
          type={
            reservedParking?.useType === 'PermanentParking'
              ? 'permanentParking'
              : 'parking'
          }
          icon="icon icon-parking"
        />
        <div className={styles.gridWebReservations}>
          <div className={styles.listGrid}>
            <DetailList>
              {isPermanentParking && (
                <>
                  <ReleasedDays
                    releasedDays={releasedDays}
                    onClick={handleReleaseDaysOnClick}
                    type="parking"
                    canModify={reservedParking?.idSede === defaultSede.id}
                    modifyDisabled={raffleIsActive}
                  />
                  <div className={styles.separatorList} />
                </>
              )}
              {!isEmpty(reservedParking?.reservations) && (
                <ParkingReleasedDays
                  onClick={handleGoModifyParkingReservationPage}
                  days={datesReservation}
                  reservations={reservedParking.reservations}
                  type="parking"
                  canModify
                />
              )}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-explore"
                startIconColor="primary"
                title={t('lbl_head_office')}
                description={reservedParking?.descriptionSede}
                readonly
              />
              {!isPermanentParking && (
                <VehiclesAndPassengersBlock
                  vehicles={vehicles}
                  companions={companions}
                />
              )}
              <DetailListItem
                className={styles.itemList}
                startIcon="icon icon-campus"
                startIconColor="primary"
                title={reservedParking?.location}
                titleClass={styles.titleDetail}
                readonly
              />
            </DetailList>
          </div>
        </div>
      </IonContent>
      {msg && (
        <Toast
          isOpen={!!msg}
          onDidDismiss={() =>
            dispatch(reservationActions.resetMsgReservation())
          }
          message={msg.description}
          position="bottom"
          type={msg.type}
        />
      )}
      <Alert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={t('header_delete_parking_reserve')}
        message={t('msg_delete_parking_reserve', {
          parking: reservedParking?.name,
          days: daysToReleaseText,
        })}
        buttons={[
          { text: 'No', role: 'cancel' },
          {
            text: t('lbl_affirmative'),
            handler: cancelParking,
          },
        ]}
        mode="ios"
      />
    </IonPage>
  );
};

export default ParkingReservationDetail;
