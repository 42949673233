import React from 'react';
import { Alert, Icon, Notification, Toast } from '@acciona/ui-ionic-kit';
import {
  IonButton,
  IonContent,
  IonPage,
  isPlatform,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  appActions,
  parkingActions,
  reservationActions,
  workstationActions,
} from '../../../_redux/actions';
import { useTranslation } from 'react-i18next';
import styles from './home.module.scss';
import { useCallback, useEffect, useRef } from 'react';
import { history } from '../../../_helpers/history';
import { useState } from 'react';
import { NewsActions } from '../../../_redux/actions';
import { handleBackMinimizeButton, isEmpty } from '../../../utils/functions';
import { redirectNotify } from '../../../utils/notifications';
import { NotificationsActions } from '../../../_redux/actions/notifications.actions';
import { NotificationsStore } from '../../../_redux/models/notifications.model';
import HIDService from '../../../_redux/services/hid/hid.services';
import { userActions } from '../../../_redux/actions/user.actions';
import { UserStore } from '../../../_redux/models/user.model';
import NotificationsModal from './components/NotificationsModal';
import _ from 'lodash';
import { AppStore } from '../../../_redux/models/app.model';
import CookiesModal from '../../../components/CookiesModal';
import { cookiesServices } from '../../../_redux/services/cookies/cookies.services';
import { Notification as NotificationModel } from '../../../_redux/models/notifications.model';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { ChangeHeadOfficeModal } from './components/ChangeHeadOfficeModal';
import { ScrollSection } from './ScrollSection';
import { HomeHeader } from './HomeHeader';
import { useNotificationService } from '../../../hooks/useNotficationService';
import {
  FALLBACK_DASHBOARD_IMAGE,
  FALLBACK_WELCOME_TEXT,
} from '../../../utils/constants';
import { ReservationStore } from '../../../_redux/models/reservation.model';
import { EWeekday } from '../../../utils/dateTime';
import { useFirebaseAnalytics } from '../../../hooks/useFirebaseAnalytics';

const styleNotification = {
  Parking: 'quaternary',
  Room: 'quinary',
  Desk: 'primary',
  News: 'tertiary',
  Corporate: 'tertiary',
  Info: 'warning',
  Card: 'warning',
  Communication: 'secondary',
  Features: 'primary',
  Security: 'primary',
  MedicalServices: 'primary',
};

const Dashboard: React.FC = () => {
  let appStateChangeSubscription = new Subscription();
  let lastOrigoCheck = new Date().getTime() - 60 * 1000;
  const permissions = useSelector(
    (state: UserStore) => state.user.user.permissions,
  );
  const logToFirebase = useFirebaseAnalytics();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contentPage = useRef<any>();
  const [backgroundClass, setBackgroundClass] = useState(false);
  const [showListNotifications, setShowListNotifications] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [showSedeModal, setShowSedeModal] = useState(false);
  const [origoInitError, setOrigoInitError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const hasCode = useSelector(
    (store: UserStore) => store.user.invitationCode.included,
  );
  const { defaultSede, sedesList } = useSelector(
    (store: UserStore) => store.user.user,
  );
  const { registered, fromInvitationCode, firstTimeActive } = useSelector(
    (store: UserStore) => store.user.card,
  );
  const { msg } = useSelector((store: UserStore) => store.user);

  const {
    globalSettings,
    error,
    localSettings,
    welcomeText,
    dashboardImage,
    offlineAlertHasBeenShown,
    offlineNetwork,
    offlineServices,
  } = useSelector((store: AppStore) => store.app);

  const offlineMode = offlineNetwork || offlineServices;
  const { isSubscribed } = useSelector(
    (store: NotificationsStore) => store.notifications,
  );

  const notices = useSelector(
    (store: NotificationsStore) => store.notifications.notices,
  );

  const { permanentDesks, permanentParkings } = useSelector(
    (store: ReservationStore) => store.reservation,
  );

  const { notificationService } = useNotificationService();

  useEffect(() => {
    if (cookiesServices.getCookiePreference() == undefined) {
      setShowCookiesModal(true);
    }
    appStateChangeSubscription = HIDService.appStateChanged.subscribe(() => {
      if (isPlatform('capacitor')) {
        checkOrigoStatus();
      }
    });
    dispatch(parkingActions.getRaffle());
    return () => {
      appStateChangeSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if ((offlineNetwork || offlineServices) && !offlineAlertHasBeenShown) {
      setShowAlert(true);
    }
  }, [offlineNetwork, offlineServices, offlineAlertHasBeenShown]);

  const addCardNotActiveInfo = () => {
    if (!isPlatform('capacitor')) {
      return;
    }

    const cardNotActiveInfo = {
      body: t('msg_card_activation'),
      createData: dayjs().tz().format('YYYY-MM-DD HH:mm'),
      dateExpiry: dayjs()
        .tz()
        .weekday(EWeekday.sunday)
        .add(1, 'week')
        .format('YYYY-MM-DD HH:mm'),
      icon: 'info',
      idNav: 'card',
      informationType: 'Card',
      messageId: 'cardActivationId',
      notice: true,
      notification: true,
      read: false,
      title: t('msg_card_activation'),
      visible: true,
      link: 'cardActivation',
    };

    dispatch(NotificationsActions.addCardNotification(cardNotActiveInfo));
  };

  const checkOrigoStatus = async () => {
    const now = new Date().getTime();
    const timePassed = now - lastOrigoCheck;
    const minimumAllowedTimeInMS = 30 * 1000;
    if (timePassed < minimumAllowedTimeInMS) {
      return;
    }
    lastOrigoCheck = new Date().getTime();
    try {
      await HIDService.handleAccessClicked(null);
      await HIDService.startScanning();
      dispatch(userActions.setCardRegistered());
      setTimeout(async () => {
        const cards = HIDService.card;
        if (cards.length > 0) {
          dispatch(userActions.setCardActive());
          if (firstTimeActive) {
            setShowToast(true);
          }
        } else {
          dispatch(userActions.setCardInActive());
        }
      }, 2000);

      dispatch(NotificationsActions.getNotifications());
    } catch (error) {
      lastOrigoCheck = new Date().getTime() - 60 * 1000;
      dispatch(userActions.setCardInActive());
      addCardNotActiveInfo();

      if (!origoInitError) {
        setTimeout(async () => {
          setOrigoInitError(true);
          try {
            await HIDService.handleAccessClicked(null);
            await HIDService.startScanning();
            dispatch(userActions.setCardRegistered());
          } catch (error) {
            console.log(error);
          }
        }, 1500);
      }
    }
  };

  useIonViewDidEnter(() => {
    document.addEventListener('ionBackButton', handleBackMinimizeButton);
    dispatch(appActions.getWelcomeSetup());
    isPlatform('capacitor')
      ? checkOrigoStatus()
      : dispatch(NotificationsActions.getNotifications());
    setBackgroundClass(false);
    contentPage.current?.scrollToTop();
    if (!_.isNull(globalSettings.dashboardNewsCarouselMobile)) {
      dispatch(NewsActions.getDashborardCarrouselNews());
      dispatch(NewsActions.getDashborardOtherNews());
    }
    dispatch(reservationActions.getReservationsAndPermanentSpaces());
    dispatch(userActions.getPublicHolidays());
    dispatch(reservationActions.getConfigurationDeskReservationForToday());
  });

  useEffect(() => {
    if (!isEmpty(permanentDesks))
      dispatch(workstationActions.getReservableDays());
  }, [permanentDesks]);

  useEffect(() => {
    dispatch(parkingActions.getPkReservableDays());
  }, [permanentParkings]);

  useIonViewWillLeave(() => {
    document.removeEventListener('ionBackButton', handleBackMinimizeButton);
    setShowListNotifications(false);
  });

  useEffect(() => {
    fromInvitationCode && setShowToast(true);
  }, [fromInvitationCode]);

  useEffect(() => {
    if (hasCode) {
      logToFirebase('/deeplink');
      history.replace('/CodeInvitation');
    }
  }, [hasCode]);

  useEffect(() => {
    const destinationUrl = localStorage.getItem('destinationUrl');
    if (destinationUrl) {
      history.replace(destinationUrl);
      localStorage.removeItem('destinationUrl');
    }
  }, []);

  useEffect(() => {
    if (!isSubscribed) {
      notificationService.notificationReceivedEmmiter.subscribe({
        // Toast in the Home section (open application)
        next(data) {
          dispatch(NotificationsActions.setNoticeToast(data.body));
          dispatch(NotificationsActions.getNotifications());
        },
        error(msg) {
          console.log('Error Getting Location: ', msg);
        },
      });

      notificationService.notificationactionEmmiter.subscribe({
        // Push notification (closed application)
        next(data) {
          if (!_.isUndefined(data.notification.data.link)) {
            dispatch(NotificationsActions.getNotifications());
            redirectNotify(
              data.notification.data as NotificationModel,
              dispatch,
              permissions,
              globalSettings.plannedReservation,
              true,
            );
          }
        },
        error(msg) {
          console.log('Error Getting Location: ', msg);
        },
      });

      dispatch(NotificationsActions.subscribeFirebase());
    }
  }, [isSubscribed]);

  const goToContentSection = () => {
    const viewportHeight = contentPage.current?.offsetHeight;
    contentPage.current?.scrollByPoint(0, viewportHeight - 56, 1000);
  };

  const changeHeader = e => {
    const viewportHeight = contentPage.current.offsetHeight;
    e >= viewportHeight - 66
      ? setBackgroundClass(true)
      : setBackgroundClass(false);
  };

  const handleShowNotifications = async () => {
    setShowListNotifications(true);
  };

  const handleDismissToast = () => {
    setShowToast(false);
    firstTimeActive && dispatch(userActions.resetSetCardActiveFromInvitation());
    registered && dispatch(userActions.resetSetCardFromInvitation());
  };
  const handleDismissMsgToast = useCallback(() => {
    dispatch(userActions.resetMsgUser());
  }, []);

  const handleClickNotify = (notification: NotificationModel) => {
    dispatch(NotificationsActions.notificationRead(notification.messageId));
    setShowListNotifications(false);
    redirectNotify(
      notification,
      dispatch,
      permissions,
      globalSettings.plannedReservation,
      true,
    );
  };

  const openHeadofficeModal = useCallback(() => {
    setShowSedeModal(true);
  }, [sedesList, defaultSede]);

  const handleSelectedHeadOffice = useCallback(
    (id: number) => {
      const selectedSede = sedesList.find(sede => sede.id === id);
      if (id !== defaultSede.id && selectedSede) {
        dispatch(userActions.updateUserDefaultHeadOffice(selectedSede));
      }
    },
    [sedesList, defaultSede],
  );

  const displaySedeName = !offlineMode && sedesList && sedesList.length > 1;

  const handleCloseOfflineAlert = () => {
    setShowAlert(false);
    if (!offlineAlertHasBeenShown) {
      dispatch(appActions.setOfflineAlertHasBeenShown());
    }
  };

  return (
    <IonPage>
      <IonContent
        ref={contentPage}
        id="content"
        fullscreen
        className={styles.contentSty}
        scrollEvents={true}
        onIonScroll={e => {
          changeHeader(e.detail.scrollTop);
        }}
      >
        {showListNotifications && (
          <NotificationsModal
            isOpen={showListNotifications}
            onDidDismiss={() => setShowListNotifications(false)}
            handleSelectedNotification={notification =>
              handleClickNotify(notification)
            }
            notifications={notices}
            contentHeight={contentPage.current?.offsetHeight}
            setShowListNotifications={setShowListNotifications}
            styleNotification={styleNotification}
          />
        )}
        {!offlineMode && displaySedeName && (
          <ChangeHeadOfficeModal
            defaultSede={defaultSede}
            isOpen={showSedeModal}
            onDidDismiss={() => setShowSedeModal(false)}
            handleSelectedHeadOffice={handleSelectedHeadOffice}
            sedes={sedesList}
          />
        )}
        <HomeHeader backgroundClass={backgroundClass} />
        <div className={styles.imgContentWrapper}>
          <div className={styles.imgContent}>
            <div className={styles.imgOverlay}></div>
            <img
              src={
                (dashboardImage &&
                  dashboardImage[localSettings?.localLanguage]) ??
                FALLBACK_DASHBOARD_IMAGE.es
              }
              alt=""
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/assets/images/homeBackground.jpg';
              }}
            />
          </div>
          <div className={styles.fixedSectionContent}>
            <div
              className={`${styles.fixedCenterContent} ${styles.gridHeader} ${
                isPlatform('capacitor') && isPlatform('ios')
                  ? styles.iosExtraMargin
                  : ''
              }`}
            >
              <div>
                {displaySedeName && (
                  <h2 className={styles.sede} onClick={openHeadofficeModal}>
                    {t('msg_this_office', {
                      name: defaultSede.description.toUpperCase(),
                    })}

                    <i className="icon icon-chevron-right"></i>
                  </h2>
                )}
                {!isPlatform('capacitor') && (
                  <img
                    src="../assets/images/campus_acciona.svg"
                    alt="logo Campus ACCIONA"
                    className={styles.logoWelcome}
                  />
                )}
                <p id="lblWelcome" className={styles.lblWelcome}>
                  {(welcomeText && welcomeText[localSettings?.localLanguage]) ??
                    FALLBACK_WELCOME_TEXT.es}
                </p>
              </div>
              {!offlineMode && (
                <div className={styles.btnScrollDown}>
                  <IonButton
                    fill="clear"
                    id="scrollBtn"
                    onClick={
                      offlineNetwork
                        ? () => history.push('/dashboard/offline')
                        : () => goToContentSection()
                    }
                    className={styles.itemIconSty}
                  >
                    <Icon
                      className={`icon icon-scrolldown  ${styles.btnScrollDownSty}`}
                    />
                  </IonButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.notificationContainer}>
          <div className={styles.centerNotification}>
            {offlineMode ? (
              <Notification
                hidden={false}
                text={
                  offlineNetwork
                    ? t('notification_offline_network')
                    : t('notification_offline_services')
                }
                model="warning"
                icon="icon icon-info"
                className={styles.notificationToast}
              />
            ) : (
              <>
                {notices && notices.length > 1 && !showListNotifications && (
                  <Notification
                    hidden={false}
                    text={t('lbl_have_notice', {
                      notices: notices.length,
                    })}
                    model="warning"
                    icon="icon icon-info"
                    onClick={() => handleShowNotifications()}
                    className="name"
                  />
                )}
                {notices && notices.length === 1 && (
                  <Notification
                    hidden={false}
                    text={notices[0].title}
                    model={styleNotification[notices[0].informationType]}
                    icon={`icon icon-${notices[0].icon}`}
                    onClick={() => handleClickNotify(_.first(notices))}
                    className="name"
                  />
                )}
              </>
            )}
          </div>
        </div>
        {!offlineMode && <ScrollSection backgroundClass={backgroundClass} />}
        <CookiesModal
          isOpen={showCookiesModal}
          onClose={() => setShowCookiesModal(false)}
        />
        <Toast
          isOpen={showToast && !offlineMode}
          onDidDismiss={() => handleDismissToast()}
          message={
            registered
              ? t('lbl_card_success_activation')
              : firstTimeActive
              ? t('lbl_card_activated')
              : t('alert_card_activation')
          }
          duration={9000}
          position="bottom"
          type={registered || firstTimeActive ? 'success' : 'warning'}
        />
        <Toast
          isOpen={!!error && !offlineMode}
          message={error}
          position="bottom"
          type="error"
        />
        <Toast
          isOpen={!!msg && !offlineMode}
          message={msg?.description}
          position="bottom"
          type={msg?.type}
          duration={3000}
          onDidDismiss={handleDismissMsgToast}
        />
        <Alert
          isOpen={showAlert}
          onDidDismiss={handleCloseOfflineAlert}
          header={
            offlineNetwork
              ? t('alert_offline_network_header')
              : t('alert_offline_services_header')
          }
          message={
            offlineNetwork
              ? t('alert_offline_network_message')
              : t('alert_offline_services_message')
          }
          buttons={[
            {
              text: t('ok_text'),
              handler: handleCloseOfflineAlert,
            },
          ]}
          mode="ios"
          backdropDismiss={false}
        />
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
