import styles from './styles.module.scss';
export const classes = {
  Desk: `${styles.divIcon} ${styles.workstation}`,
  Parking: `${styles.divIcon} ${styles.parkingReservation}`,
  PermanentDesk: `${styles.divIcon} ${styles.workstation}`,
  PermanentParking: `${styles.divIcon} ${styles.parkingReservation}`,
  Room: `${styles.divIcon} ${styles.room}`,
  ParkingRequest: `${styles.divIcon} ${styles.parking}`,
  DeskRequest: `${styles.divIcon} ${styles.workstation}`,
  RoomWaitingList: `${styles.divIcon} ${styles.room}`,
  notfound: `${styles.divIcon} ${styles.notfound}`,
  Locker: `${styles.divIcon} ${styles.locker}`,
  Canteen: `${styles.divIcon} ${styles.canteen}`,
  Passenger: `${styles.divIcon} ${styles.parkingReservation}`,
};
