import { Redirect, Route } from 'react-router';
import { IonPage, IonRouterOutlet } from '@ionic/react';
import Confirmation from '../../Shared/Success/Confirmation';
import Filter from './Filter/Filter';
import ListWorkrooms from './ListWorkrooms/ListWorkrooms';
import CheckIn from './CheckIn/CheckIn';
import NotAvailableWL from './NotAvailableWL/NotAvailableWL';
import HostSearch from './HostSearch/HostSearch';
import ScanQRCode from './ScanQRCode/ScanQRCode';
import WorkroomPreReservationDetail from './WorkroomPreReservationDetail/WorkroomPreReservationDetail';

const Workroom: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        <Route exact path="/workroom">
          <Redirect to="/workroom/filters" />
        </Route>
        <Route path="/workroom/filters" exact>
          <Filter />
        </Route>
        <Route path="/workroom/list" exact>
          <ListWorkrooms />
        </Route>
        <Route path="/workroom/prereservationdetail" exact>
          <WorkroomPreReservationDetail />
        </Route>
        <Route path="/workroom/confirmation" exact>
          <Confirmation />
        </Route>
        <Route path="/workroom/checkin" exact>
          <CheckIn />
        </Route>
        <Route path="/workroom/noAvailable" exact>
          <NotAvailableWL />
        </Route>
        <Route exact path="/workroom/hostsearch">
          <HostSearch />
        </Route>
        <Route path="/workroom/scanqrcode" exact>
          <ScanQRCode />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Workroom;
