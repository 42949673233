import type {
  ReservationAction,
  ReservationState,
} from '../models/reservation.model';
import { reservationsTypesEnum } from '../types';
import _ from 'lodash';

const initialState: ReservationState = {
  error: null,
  msg: null,
  isLoading: false,
  reservedWorkstation: null,
  reservedWorkroom: null,
  reservedCanteen: null,
  reservedLocker: null,
  reservedParking: null,
  reservations: [],
  workstationRaffles: [],
  parkingRaffles: [],
  parkingRaffleCompanions: [],
  reservedWorkstationDays: [],
  reservedParkingDays: [],
  homeOfficeDays: [],
  permanentParkings: [],
  permanentDesks: [],
  lastUpdate: '',
  reservationsServiceIsOffline: false,
  configurationDeskReservationForToday: null,
  isUserInCampus: false,
};

const startLoading = (state: ReservationState): ReservationState => {
  return {
    ...state,
    isLoading: true,
    error: null,
    msg: null,
  };
};

const stopLoading = (state: ReservationState) => ({
  ...state,
  error: null,
  isLoading: false,
});

const setError = (state: ReservationState, action: ReservationAction) => ({
  ...state,
  isLoading: false,
  error: action.error,
  reservationsServiceIsOffline: action.reservationsServiceIsOffline ?? false,
});

const getUpdatedParkingRaffleCompanions = (raffles: any[]) => {
  const companions = raffles
    .map(raffle => raffle?.dates[0].companions)
    .filter(Boolean)
    .flat();
  return _.uniqBy(companions, 'employeeId');
};

export const reservationsReducer = (
  state: ReservationState = initialState,
  action: ReservationAction,
): ReservationState => {
  switch (action.type) {
    case reservationsTypesEnum.REQUEST:
      return startLoading(state);

    case reservationsTypesEnum.FAILURE_RESERVATIONS:
      return setError(state, action);
    case reservationsTypesEnum.SET_LAST_UPDATE:
      return {
        ...state,
        lastUpdate: action.lastUpdate,
      };
    case reservationsTypesEnum.SET_WORKROOM_RESERVATION:
      return {
        ...stopLoading(state),
        reservedWorkroom: action.reservedWorkroom,
      };
    case reservationsTypesEnum.SET_CANTEEN_RESERVATION:
      return {
        ...stopLoading(state),
        reservedCanteen: action.reservedCanteen,
      };
    case reservationsTypesEnum.SET_WORKSTATION_RESERVATION:
      return {
        ...stopLoading(state),
        reservedWorkstation: action.reservedWorkstation,
      };
    case reservationsTypesEnum.SET_LOCKER_RESERVATION:
      return {
        ...stopLoading(state),
        reservedLocker: action.reservedLocker,
      };
    case reservationsTypesEnum.SET_PARKING_RESERVATION:
      return {
        ...stopLoading(state),
        reservedParking: action.reservedParking,
      };
    case reservationsTypesEnum.SET_RESERVATIONS:
      return {
        ...stopLoading(state),
        reservations: action.reservations,
        reservationsServiceIsOffline: false,
      };
    case reservationsTypesEnum.SET_HOMEOFFICE:
      return {
        ...stopLoading(state),
        homeOfficeDays: action.homeOfficeDays,
      };
    case reservationsTypesEnum.SET_WORKSTATION_RAFFLES:
      return {
        ...stopLoading(state),
        workstationRaffles: action.workstationRaffles,
      };
    case reservationsTypesEnum.SET_PARKING_RAFFLES:
      return {
        ...stopLoading(state),
        parkingRaffles: action.parkingRaffles,
        parkingRaffleCompanions: getUpdatedParkingRaffleCompanions(
          action.parkingRaffles,
        ),
      };
    case reservationsTypesEnum.SET_DATES_WS_RESERVED:
      return {
        ...stopLoading(state),
        reservedWorkstationDays: action.reservedWorkstationDays,
      };
    case reservationsTypesEnum.SET_DATES_PK_RESERVED:
      return {
        ...stopLoading(state),
        reservedParkingDays: action.reservedParkingDays,
      };
    case reservationsTypesEnum.SET_PERMANENT_PARKINGS:
      return {
        ...stopLoading(state),
        permanentParkings: action.permanentParkings,
      };
    case reservationsTypesEnum.SET_PERMANENT_DESKS:
      return {
        ...stopLoading(state),
        permanentDesks: action.permanentDesks,
      };
    case reservationsTypesEnum.MSG_RESERVATION:
      return {
        ...state,
        error: null,
        msg: action.msg,
      };
    case reservationsTypesEnum.SET_PERMANENT_PARKING_RELEASED_DAYS:
      return {
        ...state,
        permanentParkings: state.permanentParkings.map(parking =>
          parking.id === action.spaceId
            ? {
                ...parking,
                releasedDays: action.releasedDays,
              }
            : parking,
        ),
      };
    case reservationsTypesEnum.SET_DATES_WS_RESERVED_AND_HOMEOFFICE:
      return {
        ...stopLoading(state),
        homeOfficeDays: action.homeOfficeDays,
        reservedWorkstationDays: action.reservedWorkstationDays,
      };
    case reservationsTypesEnum.SET_DATES_PK_RESERVED_AND_HOMEOFFICE:
      return {
        ...stopLoading(state),
        homeOfficeDays: action.homeOfficeDays,
        reservedParkingDays: action.reservedParkingDays,
      };
    case reservationsTypesEnum.DELETE_PK_RAFFLE_COMPANION: {
      const nextParkingRaffles = state.parkingRaffles.map(e => {
        if (e.dates[0].requestId === action.requestId) {
          return {
            ...e,
            dates: [
              {
                ...e.dates[0],
                companions: e.dates[0].companions.filter(
                  comp => action.companionId !== parseInt(comp.employeeId),
                ),
              },
            ],
          };
        } else {
          return { ...e };
        }
      });

      return {
        ...state,
        isLoading: false,
        error: null,
        parkingRaffles: nextParkingRaffles,
        parkingRaffleCompanions:
          getUpdatedParkingRaffleCompanions(nextParkingRaffles),
      };
    }
    case reservationsTypesEnum.ADD_PK_RAFFLE_COMPANIONS: {
      const nextParkingRaffles = state.parkingRaffles.map(raffle => {
        if (raffle.dates[0].requestId === action.requestId) {
          return {
            ...raffle,
            dates: [
              {
                ...raffle.dates[0],
                companions: _.uniqBy(
                  [...raffle.dates[0].companions, ...action.companions],
                  'employeeId',
                ),
              },
            ],
          };
        } else {
          return { ...raffle };
        }
      });

      return {
        ...state,
        isLoading: false,
        error: null,
        parkingRaffles: nextParkingRaffles,
        parkingRaffleCompanions:
          getUpdatedParkingRaffleCompanions(nextParkingRaffles),
      };
    }
    case reservationsTypesEnum.CANCEL_RESERVED_PARKING:
      return {
        ...stopLoading(state),
        reservedParking: action.reservedParking,
      };
    case reservationsTypesEnum.CANCEL_RESERVED_WORKSTATION:
      return {
        ...stopLoading(state),
        reservedWorkstation: action.reservedWorkstation,
      };
    case reservationsTypesEnum.CANCEL_RESERVED_WORKROOM:
      return {
        ...stopLoading(state),
        reservedWorkroom: null,
        reservations: state.reservations.filter(
          e => e.id.toString() !== action.reservedWorkroom.id,
        ),
      };
    case reservationsTypesEnum.RESET_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case reservationsTypesEnum.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case reservationsTypesEnum.RESET_MESSAGE:
      return {
        ...state,
        msg: null,
      };
    case reservationsTypesEnum.SET_CONFIGURATION_DESK_RESERVATIONFORTODAY:
      return {
        ...state,
        configurationDeskReservationForToday:
          action.configurationDeskReservationForToday,
      };
    case reservationsTypesEnum.SET_IS_USER_IN_CAMPUS:
      return {
        ...state,
        isUserInCampus: action.isUserInCampus,
      };
    default:
      return state;
  }
};
