import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Props } from './types';
import { Card } from '@acciona/ui-ionic-kit';
import { ReservationCardHeader } from './ReservationCardHeader';
import { ReservationCardIcon } from './ReservationCardIcon';
import { ReservationCardContent } from './ReservationCardContent';
import styles from './styles.module.scss';

export const ReservationCard: React.FC<Props> = props => {
  const {
    onClick,
    data,
    headerTranslucent,
    icon,
    infoSize,
    displayLockerId,
    children,
    ...res
  } = props;

  return (
    <Card className={styles.positionCardWrapper} {...res} onClick={onClick}>
      <IonGrid className={styles.generalStyleCard}>
        <IonRow>
          <ReservationCardIcon
            type={data.type}
            icon={icon}
            infoSize={infoSize}
            label={data.label}
          />
          <IonCol className={styles.colCard}>
            <ReservationCardHeader
              data={data}
              headerTranslucent={headerTranslucent}
              onDelete={res.onDelete}
              endContent={res.endContent}
            />
            <ReservationCardContent
              data={data}
              displayLockerId={displayLockerId}
            />
            {children && (
              <IonRow>
                <div className={styles.contentDayPicker}>{children}</div>
              </IonRow>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </Card>
  );
};
