import { isEmpty } from '../../../utils/functions';
import { Parking, ParkingReleaseItem } from '../../models/parking.model';
import muleSoftInstance from '../../utils/httpCommon';
import { groupDetailByTypes } from './helpers';

const getReservations = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/space/reservationsByOwner`;

  // reservationsByOwner cannot be extendend anymore in backend
  // because it will produce a timeout error calling too many times
  // to habitat api. So we add a second endpoint to retrieve the
  // passenger accepted and assigned reservations
  const passengerPath = `${process.env.REACT_APP_API_HABITAT}/v1/space/companionReservationsByOwner`;

  const [resp, passengerResp] = await Promise.all([
    muleSoftInstance.get(path),
    muleSoftInstance.get(passengerPath),
  ]);

  const joinedData = [...resp.data, ...passengerResp.data];
  return groupDetailByTypes(joinedData);
};

const cancelParkingReservation = async (idsReservation: Object) => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/reservations/cancel`;
    const resp = await muleSoftInstance.post(path, idsReservation, {
      headers: {
        'content-type': 'application/json',
      },
    });

    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const modifyParkingReservation = async (
  reservationsToModify: ParkingReleaseItem[],
): Promise<Parking | []> => {
  try {
    const modifiedReservations = reservationsToModify.map(item => ({
      reservationId: item.reservationId,
      reservationType: item.reservationType,
      ...(item.reservationType === 'Personalizada'
        ? {
            startHour: item.startHour.substring(11, 16),
            endHour: item.endHour.substring(11, 16),
          }
        : {}),
      deleted: item?.deleted ?? false,
    }));

    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/reservations/cancel`;
    const resp = await muleSoftInstance.put(path, modifiedReservations);

    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const cancelWorkstationReservation = async (
  idsReservations: number[],
  deletingToday: boolean = false,
) => {
  try {
    const path = deletingToday
      ? `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservations/finish`
      : `${process.env.REACT_APP_API_HABITAT}/v1/desk/reservations/cancel`;

    const resp = await muleSoftInstance.post(path, idsReservations);

    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const cancelWorkstationRequest = async (raffleIds: number[]) => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/requests/deleteRequests`;
    const res = await muleSoftInstance.delete(path, { data: raffleIds });
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

const cancelParkingRequest = async (requestIds: number[]) => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/requests/deleteRequests`;
    const res = await muleSoftInstance.delete(path, { data: requestIds });
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

const extendReservation = async (
  id: string,
  reservationId: number,
  endDate: string,
  newDate: string,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservations/extend`;
    const params = {
      reservationId: reservationId,
      spaceId: id,
      endDate: endDate,
      newDate: newDate,
    };
    const resp = await muleSoftInstance.post(path, params);

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const cancelWrReservation = async (id: number): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservations/cancel/${id}`;

    const resp = await muleSoftInstance.post(path, {});
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};
const cancelCanteenReservation = async (id: number): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/canteen/reservations/cancel`;
    const resp = await muleSoftInstance.post(path, [id]);
    return resp.data;
  } catch (err) {
    throw new Error(err);
  }
};

const getWrReservation = async (reservationId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/detail`;
    const params = {
      reservationId: reservationId,
    };
    const resp = await muleSoftInstance.get(path, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const getCanteenReservation = async (reservationId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/canteen/detail`;
    const params = {
      reservationId: reservationId,
    };
    const resp = await muleSoftInstance.get(path, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getWsReservation = async (spaceId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/detail?spaceId=${spaceId}`;
    const resp = await muleSoftInstance.get(path);

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const getLockerReservation = async (spaceId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/locker/detail?spaceId=${spaceId}`;
    const resp = await muleSoftInstance.get(path);

    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getWsReservationSearch = async (
  spaceId: number,
  employeeIdSeached: string,
  permanent: boolean,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/detail/search?spaceId=${spaceId}&isPermanent=${permanent}&employeeIdSearched=${employeeIdSeached}`;
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getPkReservation = async (spaceId: number): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/detail?spaceId=${spaceId}`;
    const resp = await muleSoftInstance.get(path);
    if (isEmpty(resp.data)) {
      throw { message: 'Ya no existe la reserva seleccionada' };
    }
    const dataVal = { ...resp.data[0], reservations: resp.data[0].dates };

    return dataVal;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getWaitingListWr = async (reservationId): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservations/detail?waitingListId=${reservationId}`;
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getParkingRequest = async (): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v2/parking/plannedReservations`;
    const resp = await muleSoftInstance.get(path);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getReservedDaysWs = async (startDate, endDate): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v2/desk/reservedDays`;
    const params = {
      startDate: startDate,
      endDate: endDate,
    };
    const resp = await muleSoftInstance.get(url, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getReservedDaysHomeOffice = async (startDate, endDate): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/homeOffice/days`;
    const params = {
      startDate: startDate,
      endDate: endDate,
    };
    const resp = await muleSoftInstance.get(url, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getReservedDaysPk = async (startDate, endDate): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v2/parking/reservedDays`;
    const params = {
      startDate: startDate,
      endDate: endDate,
    };
    const resp = await muleSoftInstance.get(url, { params: params });
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const addReservationHost = async (host, reservationId): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/room/hosts/add`;
    const params = {
      hostId: host.employeeId,
      reservationId: reservationId,
    };
    const resp = await muleSoftInstance.post(url, params);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const deleteReservationHost = async (reservationId): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HABITAT}/v1/room/reservations/deleteHost/${reservationId}`;

    const resp = await muleSoftInstance.delete(url);
    return resp.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getPermanentParkings = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/permanency`;
  const resp = await muleSoftInstance.get(path);
  return resp.data;
};

const setPermanentParkingReleasedDays = async (
  spaceId: number,
  dates: string[],
  type: number,
  idSede: number,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v1/parking/permanency/released`;
    const params = {
      spaceId,
      dates,
      type,
    };
    const resp = await muleSoftInstance.post(path, params, {
      headers: {
        Sedeemployee: idSede.toString(),
      },
    });
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const setPermanentDeskReleasedDays = async (
  spaceId: number,
  dates: { date: string; startHour: string; endHour: string; type: string }[],
  type: string,
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v2/desk/permanency/released`;
    const params = {
      spaceId,
      dates,
      type,
    };
    const resp = await muleSoftInstance.post(path, params);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const modifyPermanentDeskReleasedDays = async (
  spaceId: number,
  dates: { date: string; startHour: string; endHour: string; type: string }[],
): Promise<any> => {
  try {
    const path = `${process.env.REACT_APP_API_HABITAT}/v2/desk/permanency/released`;
    const params = {
      spaceId,
      dates,
    };
    const resp = await muleSoftInstance.put(path, params);
    return resp.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getPermanentDesks = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/desk/permanency`;
  const resp = await muleSoftInstance.get(path);
  return resp.data;
};

const getConfigurationDeskReservationForToday = async (): Promise<any> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/configuration/desk/reservationForToday`;
  const resp = await muleSoftInstance.get(path);
  return resp.data;
};

const getIfUserIsInCampus = async (): Promise<boolean> => {
  const path = `${process.env.REACT_APP_API_HABITAT}/v1/presence/confirm`;
  const resp = await muleSoftInstance.get(path);
  return resp.data.userInCampus;
};

export const reservationServices = {
  getReservations,
  getWrReservation,
  getWsReservation,
  getWaitingListWr,
  getPkReservation,
  getParkingRequest,
  extendReservation,
  cancelWrReservation,
  cancelParkingReservation,
  modifyParkingReservation,
  cancelWorkstationReservation,
  cancelWorkstationRequest,
  cancelParkingRequest,
  getReservedDaysWs,
  getReservedDaysHomeOffice,
  getReservedDaysPk,
  addReservationHost,
  deleteReservationHost,
  getPermanentParkings,
  getPermanentDesks,
  setPermanentParkingReleasedDays,
  getWsReservationSearch,
  setPermanentDeskReleasedDays,
  modifyPermanentDeskReleasedDays,
  getLockerReservation,
  getCanteenReservation,
  cancelCanteenReservation,
  getConfigurationDeskReservationForToday,
  getIfUserIsInCampus,
};
