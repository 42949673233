import { Button, Item, Modal, Snackbar } from '@acciona/ui-ionic-kit';
import {
  IonContent,
  IonIcon,
  IonPage,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { VEHICLE_TYPES } from '../../../../../utils/constants';
import { useState } from 'react';
import { getVehicleIcon } from '../../../../../utils/vehiclesAndParking';
import { VehicleAttributeType } from '../../../../../_redux/models/vehicles.model';

type Props = {
  showModal: boolean;
  vehicleTypes: VehicleAttributeType[];
  inputData: { type: number };
  setInputData: (param: (state: { type: number }) => { type: number }) => void;
  setIsPluggable: (param: boolean) => void;
  onClose: () => void;
};

const VehicleTypeModal: React.FC<Props> = ({
  showModal,
  vehicleTypes,
  inputData,
  setInputData,
  setIsPluggable,
  onClose,
}) => {
  const { t } = useTranslation();
  const [selectedVehicle, setSelectedVehicle] = useState<number | undefined>(
    inputData.type,
  );

  const handleAccept = () => {
    if (selectedVehicle === undefined) return;
    setInputData(prevState => {
      return { ...prevState, type: selectedVehicle };
    });
    setIsPluggable(
      [VEHICLE_TYPES.car, VEHICLE_TYPES.microcar].includes(selectedVehicle),
    );
    onClose();
  };

  const showMicrocarSnackbar: boolean =
    vehicleTypes.find(({ typeId }) => typeId === VEHICLE_TYPES.microcar) !==
    undefined;

  return (
    <Modal
      className={`${styles.vehicleTypeModal}`}
      isOpen={showModal}
      onDidDismiss={onClose}
      style={{
        '--height': `${182 + vehicleTypes.length * 36}px`,
      }}
    >
      <IonPage>
        <IonContent>
          <div
            className={`${styles.vehicleTypeBody} ${
              !showMicrocarSnackbar && styles.shorterModal
            }`}
          >
            <div>
              {showMicrocarSnackbar && (
                <Snackbar
                  type="info"
                  text={t('wr_microcar_parking_size')}
                  icon="icon icon-info"
                  align="left"
                />
              )}
              <div className={styles.detailListCarType}>
                <IonRadioGroup
                  onIonChange={e => {
                    setSelectedVehicle(e.detail.value);
                  }}
                  value={selectedVehicle}
                >
                  {vehicleTypes.map(v => {
                    return (
                      <Item key={v.typeId} lines="none">
                        <IonRadio
                          mode="md"
                          value={v.typeId}
                          aria-label={v.description}
                          labelPlacement="end"
                        >
                          <div className={styles.vehicleLabel}>
                            <IonIcon
                              className={`${getVehicleIcon(v.typeId)}`}
                            />
                            <span>{v.description}</span>
                          </div>
                        </IonRadio>
                      </Item>
                    );
                  })}
                </IonRadioGroup>
              </div>
            </div>
            <div className={styles.footer}>
              <Button fill="clear" className={styles.button} onClick={onClose}>
                {t('lbl_cancel').toUpperCase()}
              </Button>
              <Button
                disabled={selectedVehicle === undefined}
                fill="clear"
                onClick={handleAccept}
              >
                {t('ok_text').toUpperCase()}
              </Button>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </Modal>
  );
};

export default VehicleTypeModal;
